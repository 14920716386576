import { axios, useQuery } from '@onc/service';

export type BCLabel = {
  name: string;
  label_text: string;
  community: string;
  svg_url: string;
};

type Project = {
  bc_labels: BCLabel[];
};

const axiosInstance = axios.create({
  baseURL: 'https://sandbox.localcontextshub.org',
});

const fetchLabels = (projectId: string): Promise<BCLabel[]> =>
  axiosInstance
    .get<undefined, { data: Project }>(`api/v1/projects/${projectId}`)
    .then(({ data: { bc_labels: bcLabels } }) => bcLabels);

export const useFetchLabels = (projectId?: string) =>
  useQuery<BCLabel[]>(
    ['fetchLabels', projectId],
    () => fetchLabels(projectId),
    {
      enabled: Boolean(projectId),
      retry: 0,
    }
  );
