/* eslint-disable react/prop-types */
import { Moment } from 'moment';
import AudioPlayerWidgetFormContents from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidgetFormContents';
import { type Location } from 'domain/AppComponents/Dashboard/DataSourceTypes.types';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import type { Device } from 'domain/AppComponents/Dashboard/data-source-widget/DataSourceSelectionWidgetFilters';

export type AudioPlayerWidgetConfigType = {
  audioFormat: string;
  clipSelectorValue: 'latestClip' | 'timeRange';
  dataSourceType: 'location' | 'device';
  device: Device;
  endDate?: Moment;
  isBroadcasting: boolean;
  locations: Location;
  loopPlayback: boolean;
  startDate?: Moment;
  showTitle: boolean;
  title: string;
};

const AudioPlayerWidgetConfig: ConfigFields<AudioPlayerWidgetConfigType> = ({
  formMethods,
}) => {
  const { watch } = formMethods;
  const formValue = watch();

  const {
    audioFormat,
    clipSelectorValue,
    dataSourceType,
    device,
    endDate,
    isBroadcasting,
    locations,
    loopPlayback,
    startDate,
    showTitle,
    title,
  } = formValue;

  return (
    <>
      <AudioPlayerWidgetFormContents
        audioFormat={audioFormat}
        clipSelectorValue={clipSelectorValue}
        dataSourceType={dataSourceType}
        device={device}
        endDate={endDate}
        isBroadcasting={isBroadcasting}
        locations={locations}
        loopPlayback={loopPlayback}
        startDate={startDate}
        showTitle={showTitle}
        title={title}
      />
    </>
  );
};

export default AudioPlayerWidgetConfig;
