import { useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import LatestReadingsWidgetConfigContent, {
  type LatestReadingsWidgetFormType,
} from 'domain/Widgets/Config/LatestReadingsWidgetConfigContent';
import Form from 'library/CompositeComponents/form/Form';

type LatestReadingsWidgetConfigProps = LatestReadingsWidgetFormType & {
  onSave: (values: any) => void;
  onCancel: () => void;
};

const LatestReadingsWidgetConfig = ({
  onSave,
  onCancel,
  ...formDefaultValues
}: LatestReadingsWidgetConfigProps) => {
  const formMethods = useForm<LatestReadingsWidgetFormType>({
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });

  return (
    <Dialog open fullWidth>
      <DialogTitle>Configure Latest Readings Widget</DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSave}>
        <DialogContent>
          <LatestReadingsWidgetConfigContent formMethods={formMethods} />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onCancel} />
          <SaveButton />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default LatestReadingsWidgetConfig;
