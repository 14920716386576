import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import Environment from '@onc/environment';
import { Box, Link } from 'base-components';
import useRowsFromSources from 'domain/AppComponents/charts/hooks/useRowsFromSources';
import TraceColourCell from './TraceColourCell';
import type {
  ChartQuality,
  ScalarDataChartDeploymentSource,
  ScalarDataChartLocationSource,
  TraceControlMap,
} from './types/TimeSeriesScalarDataChart.types';

export type LegendProps = {
  dataSources: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  >;
  isMinMaxEnabled: boolean;
  chartQuality?: ChartQuality;
  isResampled?: boolean;
  toggleSource?: (
    dataSourceId: string,
    traceType: 'average' | 'minMax'
  ) => void;
  traceControlMap?: TraceControlMap;
};

export type TraceLegendRowData = {
  id: string;
  sourceName: string;
  traceColour: string;
  dateFrom: string;
  dateTo: string;
  deviceName: string;
  deviceId: number;
  sourceIdentifier: string;
};

const TraceLegend = ({
  dataSources,
  isMinMaxEnabled,
  chartQuality = 'raw',
  isResampled = false,
  toggleSource = () => {},
  traceControlMap = new Map(),
}: LegendProps): JSX.Element => {
  const columns: GridColDef[] = [
    {
      field: 'sourceName',
      headerName: 'Source Name',
      width: 400,
      rowSpanValueGetter: (_, row) => row.sourceIdentifier,
    },
    {
      field: 'traceColour',
      headerName: 'Trace Control',
      width: 200,
      rowSpanValueGetter: (_, row) => row.sourceIdentifier,
      renderCell: (params) => {
        const isAverageTraceEnabled = traceControlMap.get(
          params.row.sourceIdentifier
        )?.isAverageEnabled;
        const isMinMaxTraceDisabled = traceControlMap.get(
          params.row.sourceIdentifier
        )?.isMinMaxEnabled;

        return (
          <TraceColourCell
            colour={params.row.traceColour}
            chartQuality={chartQuality}
            isMinMaxEnabled={isMinMaxEnabled}
            isResampled={isResampled}
            toggleSource={toggleSource}
            isAverageTraceEnabled={isAverageTraceEnabled}
            isMinMaxTraceDisabled={isMinMaxTraceDisabled}
            sourceIdentifier={params.row.sourceIdentifier}
          />
        );
      },
    },
    {
      field: 'deviceName',
      headerName: 'Device',
      width: 200,
      renderCell: (params) => (
        <Link
          href={`${Environment.getDmasUrl()}/DeviceListing?deviceId=${params.row.deviceId}`}
          target="_blank"
        >
          {`${params.row.deviceName} - ${params.row.deviceId}`}
        </Link>
      ),
    },
    {
      field: 'dateFrom',
      headerName: 'Date From',
      width: 200,
      rowSpanValueGetter: () => null,
    },
    {
      field: 'dateTo',
      headerName: 'Date To',
      width: 200,
      rowSpanValueGetter: () => null,
    },
  ];

  const rows = useRowsFromSources(dataSources);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        unstable_rowSpanning
        columns={columns}
        rows={rows}
        density="compact"
        showCellVerticalBorder
        showColumnVerticalBorder
        disableRowSelectionOnClick
        hideFooter
        disableVirtualization
      />
    </Box>
  );
};

export default TraceLegend;
