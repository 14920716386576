import { DmasUser } from '@onc/domain-services';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

export interface DeviceWorkflow {
  deviceId: number;
  deviceProcessGroupId: number;
  processGroupName: string;
  processName: string;
  phaseName: string;
  phaseTaskName: string;
  status: string;
  jiraIssueId: string;
  comment: string;
  modifyBy: DmasUser;
  modifyDate: string;
}

class DeviceWorkflowBulkUpdateService {
  static getAll = async (filters) =>
    get('DeviceWorkflowBulkUpdateService', {
      operation: 5,
      ...filters,
    }).then((response) => parseDmasAPIResponse(response));

  static update = async (params) =>
    get('DeviceWorkflowBulkUpdateService', {
      operation: 2,
      ...params,
    });
}

export default DeviceWorkflowBulkUpdateService;
