import { VFC, ReactElement } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SaveButton } from '@onc/composite-components';
import { Grid, StatelessTable } from 'base-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemRoot: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

interface OrganizationAgreementSiteDeviceRow {
  id: string;
  siteDeviceId: number;
  site: JSX.Element;
  device: string;
  dateFrom: Date;
  dateTo: Date;
}

type SiteDeviceSearchTableProps = {
  searchResultRows: OrganizationAgreementSiteDeviceRow[];
  selectedSiteDevices: any;
  onSelectionChange: (selection: string[]) => void;
  onSave: () => void;
};

const SiteDeviceSearchTable: VFC<SiteDeviceSearchTableProps> = (
  props: SiteDeviceSearchTableProps
): ReactElement => {
  const {
    searchResultRows,
    selectedSiteDevices,
    onSelectionChange: updateSelection,
    onSave,
  } = props;
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} classes={{ root: classes.gridItemRoot }}>
        <StatelessTable
          rows={searchResultRows}
          columns={[
            {
              name: 'siteDeviceId',
              title: 'Site Device ID',
            },
            {
              name: 'site',
              title: 'Site',
            },
            {
              name: 'device',
              title: 'Device',
            },
            {
              name: 'formattedDateFrom',
              title: 'Date From',
            },
            {
              name: 'formattedDateTo',
              title: 'Date To',
            },
          ]}
          sort={{ sorting: [{ columnName: 'siteDeviceId', direction: 'asc' }] }}
          selection={{
            selectByRowClick: true,
            highlightRow: false,
            showSelectionColumn: true,
            onChange: updateSelection,
          }}
          paging={{ pageSize: 6 }}
        />
      </Grid>
      <Grid item xs={12} classes={{ root: classes.gridItemRoot }}>
        <SaveButton
          disabled={selectedSiteDevices.length === 0}
          onClick={onSave}
        />
      </Grid>
    </Grid>
  );
};

export { SiteDeviceSearchTable, type OrganizationAgreementSiteDeviceRow };
