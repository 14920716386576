/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Refresh } from '@onc/icons';
import { IconButton, Divider, Typography } from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import { ShowFilterButton } from 'domain/AppComponents/IconButtons';

import TableAnnotation from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import VirtualAnnotationList, {
  ListAnnotation,
  serviceToListAnnotation,
} from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import SeaTubeLogContext from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import AnnotationReviewService from 'domain/services/AnnotationReviewService';
import AnnotationService, {
  ServiceAnnotationV3,
  useSeaTubeAnnotations,
} from 'domain/services/AnnotationService';
import SeaTubePermissionsService from 'domain/services/SeaTubePermissionsService';
import AnnotationListFilterDialog from 'library/CompositeComponents/annotation-list/AnnotationListFilterDialog';
import { VideoInterval } from 'library/CompositeComponents/video/SeamlessVideoUtil';
import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useLocalStorage, useSessionStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItemCheckbox,
} from '../../library/CompositeComponents/dashboard/Widget';
import QueryParameterContext, {
  QueryParameters,
} from '../Apps/menu/QueryParameterContext';

const AUTO_REFRESH_INTERVAL_MILLIS = 10000;

const EMPTY_FILTER = {
  cruises: [],
  dives: [],
  modifiers: [],
  creators: [],
  comment: '',
  organizationId: 0,
};

export type AnnotationListConfig = {
  autoRefresh: boolean;
  sortMostRecent: boolean;
  displayFullTimestamp: boolean;
  displayPositionalData: boolean;
  displayModifiedBy: boolean;
  displayCopyLink: boolean;
  displayEditIcon: boolean;
  displayDeleteIcon: boolean;
  displayVoteIcons: boolean;
  displayCreatedBy: boolean;
  displayTaxonAttributes: boolean;
  highlightToBeReviewed: boolean;
  hidePublicAnnotations?: boolean;
};

type Option = {
  value: number;
  label: string;
};

const AnnotationListWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId, hasPrimaryFocus } = props;

  const { isLiveMode } = useContext(QueryParameterContext);

  // ----- Context Hooks ----- //
  const { dive, cruise, logType } = useContext(SeaTubeLogContext);
  const params = useContext<QueryParameters>(QueryParameterContext);
  const logId = dive ? dive.diveId : cruise.cruiseId;
  const { diveId } = dive;
  const { annotationId, time } = params;

  // ----- State Values ----- //
  const [annotations, setAnnotations] =
    useState<ServiceAnnotationV3[]>(undefined);
  const [deleteAnnotationId, setDeleteAnnotationId] = useState(null);
  const [scrollToIndex, setScrollToIndex] = useState<number>(-1);
  const [filter, setFilter] = useState<any>({});
  const [filterState, setFilterState] = useState<any>(EMPTY_FILTER);
  const [showFilter, setShowFilter] = useState(false);
  const [creators, setCreators] = useState<Option[]>([]);
  const [modifiers, setModifiers] = useState<Option[]>([]);
  const [config, setConfig] = useLocalStorage<AnnotationListConfig>(
    `annotation-list-display-config${isLiveMode ? '-live' : ''}`,
    {
      autoRefresh: isLiveMode,
      displayFullTimestamp: false,
      sortMostRecent: isLiveMode,
      displayPositionalData: false,
      displayModifiedBy: false,
      displayCopyLink: true,
      displayEditIcon: true,
      displayDeleteIcon: true,
      displayVoteIcons: true,
      displayCreatedBy: false,
      displayTaxonAttributes: false,
      highlightToBeReviewed: false,
    }
  );

  const memoizedConfig = useMemo<AnnotationListConfig>(() => config, [config]);

  // ----- Custom Hooks ----- //
  const { onInfo, onError } = useSnackbars();

  const [triggerRefresh, setTriggerRefresh] = useBroadcast(
    dashboardId,
    BroadcastChannel.RefreshAnnotations,
    false,
    id
  );

  const [videoIntervals] = useBroadcast<VideoInterval[]>(
    dashboardId,
    BroadcastChannel.VideoIntervals,
    null,
    id
  );

  const [savedEditAnnotation] = useSessionStorage<TableAnnotation>(
    `${logType}-${logId}-edit-annotation`,
    null
  );

  const [editAnnotation, setEditAnnotation] = useBroadcast<TableAnnotation>(
    dashboardId,
    BroadcastChannel.Annotation,
    savedEditAnnotation,
    id
  );

  const [lastEditedAnnotationId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.LastEditedAnnotationId,
    undefined,
    id
  );

  const [currentTimestamp, setCurrentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    time,
    id
  );
  const [, setSeekToTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.SeekToTimestamp,
    undefined,
    id
  );

  const [, setSelectedAnnotationId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.SelectedAnnotationId,
    Number(annotationId),
    id
  );

  const {
    data: annotationPayload,
    invalidateQuery: fetchAnnotations,
    isFetching,
  } = useSeaTubeAnnotations({
    diveIds: diveId,
    filter: JSON.stringify(AnnotationUtil.buildAnnotationFilter(filter)),
    sortAnnotationsByMostRecent: config.sortMostRecent,
  });

  const [, loadingDelete, deleteAnnotation] = useWebService({
    method: AnnotationService.deleteAnnotationForSeaTube,
    onError,
  });

  const [permissions, loadingPermissions, fetchPermissions] = useWebService({
    method: SeaTubePermissionsService.getUserPermissions,
    onError,
    parser: SeaTubePermissionsService.buildDiveLogPermissions,
  });

  const listAnnototations = useMemo(
    () => annotations?.map((anno) => serviceToListAnnotation(anno)),
    [annotations]
  );

  // ----- useEffect ----- //

  useEffect(() => {
    if (dive) {
      fetchPermissions({ diveId });
    }
  }, [dive]);

  useEffect(() => {
    if (triggerRefresh) {
      fetchAnnotations();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (config.autoRefresh) {
        fetchAnnotations();
      }
    }, AUTO_REFRESH_INTERVAL_MILLIS);

    return () => clearInterval(interval);
  }, [config.autoRefresh, config.sortMostRecent]);

  useEffect(() => {
    if (!annotationPayload) return;

    const { annotations: updatedAnnotations } = annotationPayload;
    setAnnotations(updatedAnnotations);
    const creatorOptions = [];
    const modifierOptions = [];
    updatedAnnotations?.forEach((annotation) => {
      const { createdBy, modifiedBy, createdDate, modifiedDate } = annotation;
      const creatorExists = creatorOptions.find(
        (person) => person.value === createdBy.userId
      );
      const modifierExists = modifierOptions.find(
        (person) => person.value === modifiedBy.userId
      );

      if (!creatorExists) {
        creatorOptions.push({
          label: `${createdBy.firstName} ${createdBy.lastName}`,
          value: createdBy.userId,
        });
      }

      if (!modifierExists && createdDate !== modifiedDate) {
        modifierOptions.push({
          label: `${modifiedBy.firstName} ${modifiedBy.lastName}`,
          value: modifiedBy.userId,
        });
      }
    });
    setModifiers(
      modifierOptions.sort((a, b) => a.label.localeCompare(b.label))
    );
    setCreators(creatorOptions.sort((a, b) => a.label.localeCompare(b.label)));
  }, [annotationPayload]);

  useEffect(() => {
    if (isLiveMode) return;
    if (!currentTimestamp) {
      setSelectedAnnotationId(null);
      return;
    }
    const index = AnnotationUtil.selectClosestAnnotationToTimestamp(
      annotations,
      moment.utc(currentTimestamp),
      config.sortMostRecent,
      setSelectedAnnotationId
    );
    setScrollToIndex(index);
    if (index && index < annotations.length) {
      setSelectedAnnotationId(annotations[index].annotationId);
    }
  }, [currentTimestamp]);

  // ----- Custom Components ----- //
  const RefreshButton = (
    <IconButton onClick={fetchAnnotations} aria-label="Refresh">
      <Refresh />
    </IconButton>
  );

  // ----- Functions ----- //
  const handleChange = (property: keyof AnnotationListConfig) => () =>
    setConfig({ ...config, [property]: !config[property] });

  const handleDelete = () => {
    deleteAnnotation(600, diveId, deleteAnnotationId).then((response) => {
      if (response === null) return;
      fetchAnnotations();
      setDeleteAnnotationId(null);
      onInfo('Annotation deleted successfully');
    });
  };

  const handleClick = useCallback((annotation: ListAnnotation) => {
    setSeekToTimestamp(annotation.startDate);
    setCurrentTimestamp(annotation.startDate);
    setSelectedAnnotationId(annotation.annotationId);
  }, []);

  const handleClickDelete = useCallback((anno: ListAnnotation) => {
    setDeleteAnnotationId(anno.annotationId);
  }, []);

  const handleEdit = useCallback((anno: ListAnnotation) => {
    setEditAnnotation(anno ? new TableAnnotation(anno) : null);
  }, []);

  const handleCopyLink = useCallback(
    (annotation: ListAnnotation) => {
      const url = `${Environment.getLinkUrl()}/app/dive-logs/${diveId}?annotationId=${annotation.annotationId}&time=${annotation.startDate}`;
      navigator.clipboard.writeText(`${url}`).then(
        () => {
          onInfo('Link Copied to Clipboard');
        },
        () => {
          onError('Unable to copy link');
        }
      );
    },
    [diveId]
  );

  const updateAnnotationState = (anno: ServiceAnnotationV3) => {
    setAnnotations(
      annotations?.map((annotation) =>
        annotation.annotationId === anno.annotationId ? anno : annotation
      )
    );
  };

  const handleAnnotationReviewClick = useCallback(
    async (annoId: number, value: 'up' | 'down' | undefined) => {
      const currAnnotation = annotations?.find(
        (annotation) => annotation.annotationId === annoId
      );

      if (!currAnnotation || !currAnnotation.taxons[0]) {
        onError('Invalid annotation data');
        return;
      }

      const { annotationReview, taxonId } = currAnnotation.taxons[0];

      try {
        if (!annotationReview) {
          const response = await AnnotationReviewService.createAnnotationReview(
            annoId,
            taxonId,
            value
          );
          currAnnotation.taxons[0].annotationReview = response;
        } else if (!value) {
          await AnnotationReviewService.deleteAnnotationReview(
            annotationReview.annotationReviewId
          );
          currAnnotation.taxons[0].annotationReview = undefined;
        } else {
          const response = await AnnotationReviewService.updateAnnotationReview(
            annotationReview.annotationReviewId,
            annoId,
            taxonId,
            value
          );
          currAnnotation.taxons[0].annotationReview = response;
        }
        updateAnnotationState(currAnnotation);
      } catch (error: any) {
        onError(error?.message || 'Unknown error occurred');
      }
    },
    [annotations]
  );

  // ----- Rendering ----- //

  const renderSectionHeader = (title: string) => (
    <Typography
      variant="subtitle2"
      color="textSecondary"
      sx={{ paddingLeft: '16px', paddingBottom: '8px' }}
    >
      {title}
    </Typography>
  );

  const renderCheckbox = (key: keyof AnnotationListConfig, label: string) => (
    <WidgetMenuItemCheckbox
      key={key}
      label={label}
      checked={config[key]}
      onClick={handleChange(key)}
    />
  );

  const renderContent = () => (
    <VirtualAnnotationList
      annotations={listAnnototations}
      editAnnotationId={editAnnotation?.id}
      currentTimestamp={currentTimestamp}
      isLiveMode={isLiveMode}
      lastEditedAnnotationId={lastEditedAnnotationId}
      memoizedConfig={memoizedConfig}
      permissions={permissions}
      scrollToIndex={hasPrimaryFocus ? undefined : scrollToIndex}
      videoIntervals={videoIntervals}
      onClick={handleClick}
      onClickDelete={handleClickDelete}
      onCopyLink={handleCopyLink}
      onEdit={handleEdit}
      onAnnotationReviewClick={handleAnnotationReviewClick}
    />
  );

  return (
    <Widget
      key={id}
      title="Annotation List"
      loading={isFetching || loadingPermissions}
      actionComponents={[
        RefreshButton,
        <ShowFilterButton onClick={() => setShowFilter(true)} />,
        <Typography variant="body2" color="textSecondary">
          {annotations?.length || '0'} of{' '}
          {annotationPayload?.unfilteredTotal || '0'}
        </Typography>,
      ]}
      MenuItems={[
        renderSectionHeader('List Options'),
        renderCheckbox('sortMostRecent', 'Sort Most Recent'),
        renderCheckbox('autoRefresh', 'Auto Refresh'),
        <Divider />,
        renderSectionHeader('Display Options'),
        renderCheckbox('displayFullTimestamp', 'Full Timestamp'),
        renderCheckbox('displayCopyLink', 'Copy Link'),
        renderCheckbox('displayEditIcon', 'Edit'),
        renderCheckbox('displayDeleteIcon', 'Delete'),
        renderCheckbox('displayVoteIcons', 'Vote'),
        renderCheckbox('displayCreatedBy', 'Created By'),
        renderCheckbox('displayModifiedBy', 'Modified By'),
        renderCheckbox('displayPositionalData', 'Positional Data'),
        renderCheckbox('displayTaxonAttributes', 'Taxon Attributes'),
        renderCheckbox('highlightToBeReviewed', 'To Be Reviewed'),
        <Divider />,
      ]}
      {...props}
    >
      <DeleteDialog
        open={!!deleteAnnotationId}
        title="Delete Annotation?"
        onCancel={() => setDeleteAnnotationId(false)}
        onDelete={handleDelete}
        loading={loadingDelete}
        message="Are you sure you want to permanently delete this annotation?"
      />
      <AnnotationListFilterDialog
        filter={filterState}
        filterShowing={showFilter}
        creatorOptions={creators}
        modifierOptions={modifiers}
        resourceTypeId={SeaTubeResourceTypes.DIVE}
        resourceId={diveId}
        expeditionId={dive ? dive.cruiseId : undefined}
        isLoggedIn
        onFilterChange={(e) => setFilterState(e.target.value)}
        onApplyFilter={() => {
          setFilter(filterState);
          setShowFilter(false);
        }}
        onFilterClose={() => setShowFilter(false)}
        onReset={() => {
          setFilterState(EMPTY_FILTER);
          setFilter({});
        }}
      />
      {renderContent()}
    </Widget>
  );
};

AnnotationListWidget.widgetKey = 'annotation-list';
AnnotationListWidget.widgetTitle = 'Annotation List';
AnnotationListWidget.defaultDataGrid = {
  i: 'annotation-list',
  x: 0,
  y: 0,
  w: 3,
  h: 18,
};

export default AnnotationListWidget;
