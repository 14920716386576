import PropTypes from 'prop-types';

import { ContainedButton } from '@onc/composite-components';
import { Tooltip } from 'base-components';

const SeaTubeExportButton = ({
  isLoggedIn,
  exportType,
  disabled,
  onClick = undefined,
}) => (
  <Tooltip
    id="login-tooltip"
    title={`You must be logged in to export ${exportType}!`}
    disableHoverListener={isLoggedIn}
    disableTouchListener
    disableFocusListener
  >
    <span>
      <ContainedButton
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={!isLoggedIn || disabled}
        translationKey="seatube.export"
      />
    </span>
  </Tooltip>
);

SeaTubeExportButton.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  exportType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default SeaTubeExportButton;
