import { ChangeEvent, Component, FocusEventHandler } from 'react';
import { MenuItem, OutlinedInputProps } from '@mui/material';
import MaterialTextField from '@mui/material/TextField';

export interface DropdownOption {
  key: string;
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface Props {
  name?: string;
  options?: DropdownOption[];
  id?: string;
  value?: string | number | any[] | null;
  label?: string;
  helperText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  error?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: OutlinedInputProps['inputProps'];
  InputProps?: object;
  sx?: object;
}

class Dropdown extends Component<Props> {
  static defaultProps = {
    name: undefined,
    onChange: undefined,
    id: undefined,
    label: undefined,
    value: '',
    options: undefined,
    helperText: undefined,
    fullWidth: undefined,
    disabled: undefined,
    required: false,
    className: undefined,
    error: false,
    onBlur: undefined,
    inputProps: undefined,
    InputProps: undefined,
    sx: undefined,
  };

  renderOptions = () => {
    const { options } = this.props;
    if (options) {
      return options.map((option) => (
        <MenuItem
          key={option.key || option.label}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </MenuItem>
      ));
    }
    return undefined;
  };

  render() {
    const {
      fullWidth,
      disabled,
      required,
      label,
      helperText,
      id,
      value,
      onChange,
      name,
      className,
      error,
      onBlur,
      inputProps,
      InputProps,
      sx,
    } = this.props;

    return (
      <MaterialTextField
        InputLabelProps={{ shrink: !!value || value === 0 }}
        id={id}
        label={label}
        helperText={helperText}
        value={value}
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
        select
        onChange={onChange}
        name={name}
        className={className}
        error={error}
        onBlur={onBlur}
        inputProps={inputProps}
        InputProps={InputProps}
        sx={sx}
      >
        {this.renderOptions()}
      </MaterialTextField>
    );
  }
}

export default Dropdown;
