import { useState } from 'react';
import { MoreVert } from '@onc/icons';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from 'base-components';

import AnnotationReviewButtons from './AnnotationReviewButtons';

type Option = {
  label: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  icon: React.ComponentType<{ fontSize?: 'small' | 'default' | 'large' }>;
};

type AnnotationActionsProps = {
  options: Option[];
  annotationId?: number;
  reviewValue?: string;
  onReviewClick?: () => void;
};

const AnnotationActions = ({
  options,
  annotationId = undefined,
  reviewValue = undefined,
  onReviewClick = undefined,
}: AnnotationActionsProps) => {
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleOpenMore = (event: React.MouseEvent<HTMLElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorPosition({
      top: rect.bottom,
      left: rect.right,
    });
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPosition(null);
    event.preventDefault();
    event.stopPropagation();
  };

  const renderIconButtons = () =>
    options.map((option) => (
      <IconButton onClick={option.onClick} aria-label={option.label}>
        <option.icon fontSize="small" />
      </IconButton>
    ));

  const renderThreeDot = () => (
    <>
      <IconButton
        onClick={handleOpenMore}
        aria-label="More"
        aria-controls={anchorPosition ? `more-menu-${annotationId}` : undefined}
        aria-haspopup="true"
        aria-expanded={anchorPosition ? 'true' : undefined}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        id={`more-menu-${annotationId}`}
        open={Boolean(anchorPosition)}
        onClose={handleCloseMore}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition
            ? { top: anchorPosition.top, left: anchorPosition.left }
            : undefined
        }
        keepMounted
        style={{ position: 'absolute' }} // Ensure absolute positioning
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(e) => {
              option.onClick(e);
              handleCloseMore(e);
            }}
          >
            <ListItemIcon>
              <option.icon />
            </ListItemIcon>
            <Typography variant="inherit">{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  return (
    <>
      {onReviewClick && (
        <AnnotationReviewButtons
          onReviewClick={onReviewClick}
          annotationId={annotationId}
          reviewValue={reviewValue}
        />
      )}
      {options.length > 2 ? renderThreeDot() : renderIconButtons()}
    </>
  );
};

export default AnnotationActions;
