import { get, parseDmasAPIResponse } from 'util/WebRequest';

class ActionService {
  static getAllActions() {
    return get('ActionListingService').then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}

export default ActionService;
