import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { ContainedButton, TextButton } from '@onc/composite-components';
import { Grid } from 'base-components';
import MultiEmailField from 'domain/AppComponents/Form/Fields/MultiEmailField';
import Form from 'library/CompositeComponents/form/Form';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    entryFieldsContainer: {
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
    },
    emailEntryField: {
      marginLeft: theme.spacing(1),
      width: '98%',
    },
    formContainer: {
      marginLeft: theme.spacing(1),
      height: 'fit-content',
    },
    shareButton: {
      marginTop: theme.spacing(1),
    },
  })
);

type ShareSimpleForm = {
  email: string;
  sharePermission: string;
};

type ShareSimpleProps = {
  onCancel: () => void;
  onShare: (values: any) => Promise<void>;
  classes: {
    entryFieldsContainer: string;
    formButtons: string;
  };
  advancedLinkButton?: JSX.Element;
};

const ShareSimple = ({
  onCancel,
  onShare,
  classes,
  advancedLinkButton = undefined,
}: ShareSimpleProps) => {
  const allClasses = { ...useStyles(), ...classes };

  const [emailsProp, setEmails] = useState([]);

  const handleShare = (permission, values) => {
    for (let i = 0; i < values.length; i += 1) {
      if (values[i]) {
        onShare({ accountName: values[i], sharePermission: permission });
      }
    }
  };

  const formMethods = useForm<ShareSimpleForm>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      sharePermission: 'RO',
    },
  });

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={allClasses.formContainer}
    >
      <Grid item sm={12}>
        <Form
          onSubmit={(permission) =>
            handleShare(permission.sharePermission, emailsProp)
          }
          formMethods={formMethods}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            className={allClasses.entryFieldsContainer}
          >
            <Grid item sm={7} className={allClasses.emailEntryField}>
              <MultiEmailField
                handleChange={setEmails}
                emailItems={emailsProp || []}
              />
            </Grid>
            <Grid item sm>
              <FormDropdown
                name="sharePermission"
                label="Permission"
                options={[
                  { key: '1', value: 'RO', label: 'Can View' },
                  { key: '2', value: 'RW', label: 'Can Edit' },
                ]}
              />
            </Grid>
            <Grid item sm className={allClasses.shareButton}>
              <ContainedButton
                translationKey="common.buttons.share"
                type="submit"
              />
            </Grid>
          </Grid>
          <div className={allClasses.formButtons}>
            <TextButton
              translationKey="common.buttons.close"
              onClick={onCancel}
            />
          </div>
        </Form>
        <Grid item sm>
          {advancedLinkButton}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShareSimple;
