import { useQuery, useMutation } from '@onc/service';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

export const fetchProjectId = (
  organizationAgreementId: number
): Promise<string> =>
  get('/OrganizationAgreementService', {
    operation: 10,
    organizationAgreementId,
  })
    .then(parseDmasAPIResponse)
    .then((response) => response || '');

export const useFetchProjectId = (organizationAgreementId: number) =>
  useQuery<string, Error>(['fetchProjectId', organizationAgreementId], () =>
    fetchProjectId(organizationAgreementId)
  );

export const updateProjectId = (
  organizationAgreementId: number,
  localContextsProjectId?: string
): Promise<undefined> =>
  post('/OrganizationAgreementService', {
    operation: 2,
    organizationAgreementId,
    localContextsProjectId,
  }).then(parseDmasAPIResponse);

export const useUpdateProjectId = (onSuccess, onError) =>
  useMutation({
    mutationFn: ({
      organizationAgreementId,
      localContextsProjectId,
    }: {
      organizationAgreementId: number;
      localContextsProjectId?: string;
    }) =>
      post('/OrganizationAgreementService', {
        operation: 2,
        organizationAgreementId,
        localContextsProjectId,
      }).then(parseDmasAPIResponse),
    onSuccess,
    onError,
  });
