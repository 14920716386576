import { Grid, Typography } from '@mui/material';
import TextWidgetDisplay from 'domain/AppComponents/Dashboard/TextWidgetDisplay';
import { type TextWidgetFormType } from 'domain/AppComponents/Dashboard/TextWidgetFormContents';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import TextWidgetConfigContent from './Config/TextWidgetConfigContent';

export const defaultTextConfig: TextWidgetFormType = {
  title: 'Sample Text',
  showTitle: true,
  htmlValue:
    '<h1 style="text-align:left;"><span style="color: rgb(41,105,176);font-family: Verdana;">Evidence-Based Decision Making</span></h1>\n<p style="text-align:left;"><span style="font-family: Verdana;">The state of the ocean is an important indicator of the overall health of the planet. The ocean off the coasts of Canada, including the Arctic, comprises some of the richest and most diverse ecosystems on Earth. This makes Ocean Networks Canada data relevant to global users.</span></p>\n<p style="text-align:left;"><span style="font-family: Verdana;">http://www.oceannetworks.ca</span></p>\n',
};

const TextWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, initialConfig } = props;
  const { config, setConfig } = useWidgetConfig<TextWidgetFormType>(id, {
    ...defaultTextConfig,
    ...initialConfig,
  });
  const { title, showTitle, htmlValue } = config;

  return (
    <Widget
      key={id}
      titleComponents={<Typography variant="subtitle1">{title}</Typography>}
      configTitle="Text Widget"
      ConfigFieldsComponent={TextWidgetConfigContent}
      onChangeConfig={setConfig}
      config={config}
      collapseHeader={showTitle}
      closeMenuItems
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        <Grid item xs={12} sx={{ height: '99%', padding: '20px 10px' }}>
          <TextWidgetDisplay showTitle title="" htmlValue={htmlValue} />
        </Grid>
      </Grid>
    </Widget>
  );
};

TextWidget.widgetKey = 'text-widget';
TextWidget.widgetTitle = '';
TextWidget.defaultDataGrid = {
  x: 0,
  y: 0,
  w: 5,
  h: 10,
};

export default TextWidget;
