import { useForm } from 'react-hook-form';
import { Box, LoadingButton, Grid } from 'base-components';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import { type SaveType } from './DeviceBulkUpdatePage';

type Action = {
  actionId: number;
  actionName: string;
};

type Cruise = {
  cruiseId: number;
  cruiseName: string;
};

export type DeviceActionBulkUpdateForm = {
  action: Action;
  cruise: Cruise;
  performedDate: Date;
  actionCost: number;
  description: string;
  saveType: SaveType;
};

const DeviceBulkUpdateDeviceActionForm = ({
  actions,
  cruises,
  onSubmit,
  isLoading,
}: {
  actions: Action[];
  cruises: Cruise[];
  onSubmit: ({
    action,
    cruise,
    performedDate,
    actionCost,
    description,
    saveType,
  }: DeviceActionBulkUpdateForm) => void;
  isLoading: boolean;
}) => {
  const deviceActionFormMethods = useForm<DeviceActionBulkUpdateForm>({
    values: {
      action: undefined,
      cruise: undefined,
      performedDate: undefined,
      actionCost: undefined,
      description: undefined,
      saveType: undefined,
    },
  });
  const {
    formState: { isSubmitting },
  } = deviceActionFormMethods;

  const saveType = deviceActionFormMethods.watch('saveType');

  return (
    <Form formMethods={deviceActionFormMethods} onSubmit={onSubmit}>
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={12}>
          <FormAutocomplete
            name="action"
            translationKey="device.bulkUpdate.deviceAction.action"
            required={saveType === 'Add'}
            options={actions}
            getOptionLabel={(option: Action) =>
              `${option.actionName} (${option.actionId})` || ''
            }
          />
          <FormAutocomplete
            name="cruise"
            translationKey="device.bulkUpdate.deviceAction.cruise"
            options={cruises}
            getOptionLabel={(option: Cruise) =>
              `${option.cruiseName} (${option.cruiseId})` || ''
            }
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <FormDateTimePicker
            name="performedDate"
            translationKey="device.performedDate"
            required={saveType === 'Add'}
            fullWidth
          />
          <FormTextField
            name="actionCost"
            translationKey="device.actionCost"
            type="number"
            fullWidth
          />
        </Grid>
      </Grid>
      <FormTextField
        name="description"
        translationKey="common.textfields.description"
        fullWidth
        multiline
        minRows={4}
        maxRows={8}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginLeft: '80%',
          maxWidth: '20%',
        }}
      >
        <FormDropdown
          name="saveType"
          label="Add/Update"
          fullWidth
          required
          options={[
            { key: 'update', label: 'Update', value: 'Update' },
            { key: 'add', label: 'Add', value: 'Add' },
          ]}
        />
        <LoadingButton
          variant="contained"
          translationKey="common.buttons.save"
          type="submit"
          loading={isSubmitting || isLoading}
          disabled={!saveType}
        />
      </Box>
    </Form>
  );
};

export default DeviceBulkUpdateDeviceActionForm;
