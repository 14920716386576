import { Component } from 'react';
import { withStyles } from '@mui/styles';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { flushSync } from 'react-dom';
import { Loading, TextButton } from '@onc/composite-components';
import { Info, HelpIcon } from '@onc/icons';
import {
  Chip,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LabelledCheckbox,
} from 'base-components';

import { NOAA_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import ChatLogList from 'domain/AppComponents/seatube/chat-log/ChatLogList';
import VideoPlayer from 'domain/AppComponents/video/VideoPlayer';
import DiveDetails from 'domain/Apps/seatube/details/DiveDetails';
import AnnotationReviewService from 'domain/services/AnnotationReviewService';
import AnnotationService from 'domain/services/AnnotationService';
import DiveService from 'domain/services/DiveService';
import SeaTubeChatLogService from 'domain/services/SeaTubeChatLogService';
import SeaTubeConfigurationService from 'domain/services/SeaTubeConfigurationService';
import SeaTubePermissionsService from 'domain/services/SeaTubePermissionsService';
import UserManagementService from 'domain/services/UserManagementService';

import AnnotationList from 'library/CompositeComponents/annotation-list/AnnotationList';
import AnnotationListFilterDialog from 'library/CompositeComponents/annotation-list/AnnotationListFilterDialog';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import CookieUtils from 'util/CookieUtils';
import DateUtils from 'util/DateUtils';

import Time from 'util/TimeUtils';
import DiveDetailsPanel from './details/DiveDetailsPanel';
import SeaTubeLayout from './layout/SeaTubeLayout';
import ManualEntryPanel from './manual-entry/ManualEntryPanel';
import SeaTubeMapPanel from './map/SeaTubeMapPanel';
import SeaTubeSensorDisplayPanel from './sensor-display/SeaTubeSensorDisplayPanel';
import AnnotationUtil from './util/AnnotationUtil';
import SeaTubeResourceTypes from './util/SeaTubeResourceTypes';
import SeaTubeVideoPanel from './video/SeaTubeVideoPanel';

const GRAY = '#f5f5f5';
// Expedition Logger - ONC = 241
// Expedition Logger - DFO = 427
// Expedition Admin - ONC = 456
const NULLABLE_USER_GROUPS = [241, 427, 456];
const styles = (theme) => ({
  root: {
    overflow: 'auto',
    backgroundColor: GRAY,
    height: '100%',
  },
  noPadding: {
    padding: 0,
  },
  diveInProgessStatus: {
    paddingRight: theme.spacing(),
  },
  layout: {
    background: GRAY,
  },
  historicalVideoChip: {
    backgroundColor: theme.palette.error.main,
  },
  liveVideoChip: {
    backgroundColor: theme.palette.success.main,
    marginRight: theme.spacing(2),
  },
  diveInProgressChip: {
    backgroundColor: theme.palette.success.main,
    marginLeft: theme.spacing(2),
    color: '#FFF',
  },
  floatRight: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
  },
});

class SeaTube extends Component {
  static propTypes = {
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    diveId: PropTypes.number,
    currentTimestamp: PropTypes.instanceOf(Moment),
    time: PropTypes.instanceOf(Moment),
    classes: PropTypes.objectOf(PropTypes.string),
    isLoggedIn: PropTypes.bool,
    currentUserId: PropTypes.number,
    annotationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seatubeSearch: PropTypes.string,
  };

  static defaultProps = {
    diveId: undefined,
    classes: undefined,
    currentTimestamp: undefined,
    time: undefined,
    isLoggedIn: false,
    currentUserId: -1,
    annotationId: undefined,
    seatubeSearch: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTimestamp: props.currentTimestamp,
      sensorDataTimestamp: props.currentTimestamp,
      annotations: [],
      currentLayoutName: undefined,
      filter: this.getEmptyFilter(),
      appliedFilter: {},
      annotationTotal: 0,
      creatorOptions: [],
      modifierOptions: [],
      selectedAnnotation: undefined,
      diveDetails: undefined,
      showInfoDialog: false,
      sortAnnotationsByMostRecent: false,
      annotationPermissions: undefined,
      deletePermissions: undefined,
      bathymetryPermissions: false,
      selfDeletePermissions: undefined,
      reviewPermissions: undefined,
      editAnnotation: undefined,
      lastEditedAnnotationId: undefined,
      seekTime: props.time
        ? Time.convertMillisecondsToSeconds(props.time.valueOf())
        : undefined,
      showDeleteDialog: false,
      showChatLogMessageDeletionDialog: false,
      showChatLogMessageDeleteAllDialog: false,
      deleteAnnotationId: null,
      isLive: false,
      loading: false,
      nullableAttributes: false,
      chatLog: undefined,
      totalListItems: 0,
      showExtraDiveInfo: CookieUtils.getCookie(`show-extra-dive-info`),
      loadingDiveDetails: true,
      loadingPermissions: true,
      userPrivileges: [],
    };
  }

  async componentDidMount() {
    this.getDiveInformation();
    this.getUserPermissions();
    this.getUserNullAttributePermissions();
  }

  getDiveInformation = async () => {
    const { onError, time } = this.props;
    const { diveId } = this.props;
    const diveDetails = await DiveService.getDiveDetails(diveId, onError);
    const chatLog = await SeaTubeChatLogService.getChatLogForDive(
      diveId,
      onError
    );
    this.setState({ loadingDiveDetails: false });
    const isLive = diveDetails && diveDetails.isDiveLive && !time;
    if (diveDetails)
      this.setState(
        { diveDetails, isLive, chatLog },
        this.getSeatubeConfigInformation
      );
  };

  getSeatubeConfigInformation = async () => {
    const { isLoggedIn, onError } = this.props;
    const { diveDetails } = this.state;
    const { organizationId } = diveDetails;

    if (isLoggedIn) {
      try {
        const seatubeConfig =
          await SeaTubeConfigurationService.getOrganizationConfig(
            organizationId
          );

        this.setState({ seatubeConfig });
      } catch (error) {
        onError(error);
      }
    }
  };

  getUserPermissions = async () => {
    const { onError, diveId } = this.props;
    try {
      const payload = await SeaTubePermissionsService.getUserPermissions({
        diveId,
      });
      this.setState({
        annotationPermissions: payload[0].value,
        deletePermissions: payload[1].value,
        bathymetryPermissions: payload[1].value,
        selfDeletePermissions: payload[2].value,
        reviewPermissions: payload[3].value,
      });
    } catch (error) {
      onError(error);
    } finally {
      this.setState({ loadingPermissions: false }, () =>
        this.getFilteredAnnotations()
      );
    }
  };

  getUserNullAttributePermissions = async () => {
    const { onError, currentUserId, isLoggedIn } = this.props;
    if (isLoggedIn) {
      try {
        const userDetails =
          await UserManagementService.getUserDetails(currentUserId);
        const { userPrivileges } = userDetails;
        this.setState({ userPrivileges });
        const nullableGroups = userPrivileges.filter((group) =>
          NULLABLE_USER_GROUPS.includes(group.groupId)
        );
        if (nullableGroups.length > 0) {
          this.setState({ nullableAttributes: true });
        }
      } catch (error) {
        onError(error);
      }
    }
  };

  deriveFilterOptions = () => {
    const { annotations } = this.state;
    const creatorOptions = [];
    const modifierOptions = [];

    annotations.forEach((annotation) => {
      const { createdBy, modifiedBy, createdDate, modifiedDate } = annotation;
      const creatorExists = creatorOptions.find(
        (person) => person.value === createdBy.userId
      );
      const modifierExists = modifierOptions.find(
        (person) => person.value === modifiedBy.userId
      );

      if (!creatorExists) {
        creatorOptions.push({
          label: `${createdBy.firstName} ${createdBy.lastName}`,
          value: createdBy.userId,
        });
      }

      if (!modifierExists && createdDate !== modifiedDate) {
        modifierOptions.push({
          label: `${modifiedBy.firstName} ${modifiedBy.lastName}`,
          value: modifiedBy.userId,
        });
      }
    });

    creatorOptions.sort((a, b) => a.label > b.label);
    modifierOptions.sort((a, b) => a.label > b.label);

    this.setState({ creatorOptions, modifierOptions });
  };

  generateDiveName = () => {
    const { diveDetails } = this.state;
    if (!diveDetails) return '';
    return `${diveDetails.referenceDiveId} - ${diveDetails.area}`;
  };

  seekVideoTo = (timestamp) => {
    const { isLive, seekTime, currentTimestamp } = this.state;
    if (isLive) return {};
    const time = new Date(timestamp).getTime();
    let newSeekTime = Time.convertMillisecondsToSeconds(Math.floor(time));
    if (newSeekTime < 0) newSeekTime = 0;

    if (seekTime === newSeekTime) {
      return this.setState({ seekTime: currentTimestamp }, () => {
        this.setState({ seekTime: newSeekTime });
      });
    }
    return this.setState({
      seekTime: newSeekTime,
    });
  };

  handleSeek = (time) => {
    this.setState({ currentTimestamp: time });
  };

  handleListItemClicked = (item) => {
    this.setState(
      {
        isLive: false,
      },
      () => {
        if (item.annotationId) {
          this.handleAnnotationClicked(item);
        }
        if (item.chatLogMsgId) {
          this.handleChatMessageClicked(item);
        }
      }
    );
  };

  handleAnnotationClicked = (annotation) => {
    this.setState(
      {
        selectedAnnotation: annotation,
        sensorDataTimestamp: new Date(annotation.startDate),
      },
      () => this.seekVideoTo(annotation.startDate)
    );
  };

  handleChatMessageClicked = (chatMessage) => {
    this.setState(
      {
        sensorDataTimestamp: new Date(chatMessage.msgDate),
      },
      () => this.seekVideoTo(chatMessage.msgDate)
    );
  };

  handleAnnotationEditClicked = (annotation) => {
    const editAnnotation = { ...annotation };
    if (editAnnotation.taxons && editAnnotation.taxons[0].attributes) {
      const attributes = [...editAnnotation.taxons[0].attributes];
      editAnnotation.taxons[0].attributes = attributes.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
    this.setState({
      editAnnotation,
      lastEditedAnnotationId: editAnnotation.annotationId,
    });
  };

  handleClearEntry = () => {
    this.setState({
      editAnnotation: undefined,
      lastEditedAnnotationId: undefined,
    });
  };

  handleClearFilter = () => {
    this.setState(
      { appliedFilter: {}, filter: this.getEmptyFilter() },
      this.getFilteredAnnotations
    );
  };

  getEmptyFilter = () => ({
    cruises: [],
    dives: [],
    modifiers: [],
    creators: [],
    comment: '',
    organizationId: 0,
  });

  handleApplyFilter = () => {
    const { filter } = this.state;
    this.setState(
      { appliedFilter: filter, filterShowing: false },
      this.getFilteredAnnotations
    );
  };

  handleAnnotationDeleteClicked = (annotation) => {
    const { annotationId } = annotation;
    this.setState({ showDeleteDialog: true, deleteAnnotationId: annotationId });
  };

  handleChatLogMessageDeleteClicked = (chatLogMessage) => {
    const { chatLogMsgId } = chatLogMessage;
    this.setState({
      showChatLogMessageDeletionDialog: true,
      chatLogMsgIdToDelete: chatLogMsgId,
    });
  };

  handleChatLogMessageDeleteAllClicked = () => {
    this.setState({
      showChatLogMessageDeleteAllDialog: true,
    });
  };

  handleAnnotationSave = (annotation, isEdit) => {
    const { annotationTotal, creatorOptions, modifierOptions, editAnnotation } =
      this.state;
    this.getFilteredAnnotations().then(() => {
      // If there's a new annotator, derive filter options again to update creators or modifiers.
      if (
        !creatorOptions.filter(
          (creator) => creator.value === annotation.createdBy.dmasUserId
        ).length ||
        !modifierOptions.filter(
          (modifier) => modifier.value === annotation.modifiedBy.dmasUserId
        ).length
      ) {
        this.deriveFilterOptions();
      }
    });

    if (!isEdit) {
      this.setState({ annotationTotal: annotationTotal + 1 });
    }

    if (annotation.annotationId !== editAnnotation?.annotationId) {
      this.setState({ lastEditedAnnotationId: annotation.annotationId });
    }
  };

  handleTimeChange = (time) => {
    const { currentTimestamp } = this.state;

    if (
      time &&
      currentTimestamp &&
      currentTimestamp.getTime() === time.getTime()
    ) {
      return;
    }
    this.setState({ currentTimestamp: time });
  };

  handleAnnotationSort = (sortByMostRecent) => {
    const { annotations } = this.state;
    let sortedAnnotations = annotations;
    if (sortByMostRecent) {
      sortedAnnotations = annotations.sort((a, b) =>
        DateUtils.isDateBefore(a.startDate, b.startDate)
      );
    } else {
      sortedAnnotations = annotations.sort((a, b) =>
        DateUtils.isDateAfter(a.startDate, b.startDate)
      );
    }
    this.setState({
      sortAnnotationsByMostRecent: sortByMostRecent,
      annotations: sortedAnnotations,
    });
  };

  handleFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  getFilteredAnnotations = async () => {
    const { diveId, onError, annotationId } = this.props;
    const {
      sortAnnotationsByMostRecent,
      selectedAnnotation,
      filter,
      creatorOptions,
      modifierOptions,
    } = this.state;
    this.setState({ loading: true });
    const parsedFilter = AnnotationUtil.buildAnnotationFilter(filter);
    // seatube/annotations needs to be updated to be camel case, but still usesd by V1 and V2
    try {
      const response = await AnnotationService.getDiveIdAnnotations(
        diveId,
        parsedFilter,
        sortAnnotationsByMostRecent,
        onError
      );
      const processedAnnotations = AnnotationUtil.processAnnotations(
        response.annotations
      );
      const getAnnotationFromUrl = annotationId && !selectedAnnotation;
      let annotation = selectedAnnotation;
      if (getAnnotationFromUrl) {
        annotation = processedAnnotations.find(
          (currAnnotation) => currAnnotation.annotationId === annotationId
        );
      }
      flushSync(() => {
        this.setState({ annotations: processedAnnotations });
      });

      this.setState(
        (prevState) => ({
          loading: false,
          annotationTotal:
            Object.keys(parsedFilter).length === 0
              ? processedAnnotations.length
              : prevState.annotationTotal,
          selectedAnnotation: annotation,
        }),
        () => {
          this.updateAnnotationListItems();
        }
      );

      if (
        !creatorOptions ||
        !modifierOptions ||
        !creatorOptions.length ||
        !modifierOptions.length
      ) {
        this.deriveFilterOptions();
      }
    } catch (error) {
      onError(error);
      this.setState({ loading: false });
    }
  };

  handleOpenInfoDialog = () => {
    this.setState({
      showInfoDialog: true,
    });
  };

  handleCloseInfoDialog = () => {
    this.setState({
      showInfoDialog: false,
    });
  };

  handleLiveVideoClick = () => {
    const { isLive: oldLiveVideo, layoutName } = this.state;
    if (!oldLiveVideo) {
      this.setState((prevState) => ({
        isLive: !prevState.isLive,
        sortAnnotationsByMostRecent: true,
      }));
    } else if (layoutName !== 'On Ship') {
      this.setState((prevState) => ({
        isLive: !prevState.isLive,
        sortAnnotationsByMostRecent: false,
      }));
    } else {
      this.setState((prevState) => ({ isLive: !prevState.isLive }));
    }
  };

  handleLayoutClick = (currentLayout) => {
    const { isLive } = this.state;
    if (currentLayout.name === 'On Ship') {
      this.setState({
        layoutName: currentLayout.name,
        sortAnnotationsByMostRecent: true,
      });
    } else if (!isLive) {
      this.setState({
        layoutName: currentLayout.name,
        sortAnnotationsByMostRecent: false,
      });
    } else {
      this.setState({
        layoutName: currentLayout.name,
      });
    }
  };

  handleToggleFilter = (showFilter) => {
    this.setState({ filterShowing: showFilter });
  };

  handleUpdateWidgetConfig = (key, config) => {
    const { isLoggedIn } = this.props;
    const userType = isLoggedIn ? 'user' : 'anonymous';
    CookieUtils.saveCookie(`${userType}-${key}-config`, config);
  };

  handleClickHelp = () => {
    window.open(
      'https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help',
      '_blank'
    );
  };

  closeDeleteDialog = () => {
    this.setState({ showDeleteDialog: false, deleteAnnotationId: null });
  };

  closeChatLogMessageDeletionDialog = () => {
    this.setState({
      showChatLogMessageDeletionDialog: false,
      chatLogMsgId: null,
    });
  };

  closeChatLogMessageDeleteAllDialog = () => {
    this.setState({
      showChatLogMessageDeleteAllDialog: false,
    });
  };

  confirmDeleteClicked = async () => {
    const { deleteAnnotationId, annotationTotal } = this.state;
    const { diveId, onError, onInfo } = this.props;

    this.closeDeleteDialog();
    onInfo('Deleting annotation...');
    try {
      await AnnotationService.deleteAnnotationForSeaTube(
        SeaTubeResourceTypes.DIVE,
        diveId,
        deleteAnnotationId
      );
      this.getFilteredAnnotations();
      this.setState({ annotationTotal: annotationTotal - 1 });
      onInfo('Annotation deleted.');
    } catch (error) {
      onError(error);
    }
  };

  confirmChatLogDeleteClicked = async () => {
    const { diveId, onInfo, onError } = this.props;
    const { chatLogMsgIdToDelete } = this.state;
    this.closeChatLogMessageDeletionDialog();
    try {
      await SeaTubeChatLogService.deleteChatLogMessage(chatLogMsgIdToDelete);

      const chatLog = await SeaTubeChatLogService.getChatLogForDive(
        diveId,
        onError
      );
      this.setState({ chatLog });

      onInfo(`Chat log message deleted`);
    } catch (error) {
      onError(error);
    }
  };

  confirmChatLogDeleteAllClicked = async () => {
    const { diveId, onInfo, onError } = this.props;
    this.closeChatLogMessageDeleteAllDialog();
    try {
      await SeaTubeChatLogService.deleteAllChatLogMessagesOfDive(diveId);

      const chatLog = await SeaTubeChatLogService.getChatLogForDive(
        diveId,
        onError
      );
      this.setState({ chatLog });

      onInfo(`All chat log messages for dive deleted`);
    } catch (error) {
      onError(error);
    }
  };

  renderDiveInfoAction = () => (
    <>
      {this.renderVideoSourceAction()}
      <IconButton
        aria-label="Help"
        color="inherit"
        onClick={this.handleClickHelp}
        Icon={HelpIcon}
      />
      <IconButton
        aria-label="Dive Info"
        color="inherit"
        onClick={this.handleOpenInfoDialog}
        Icon={Info}
      />
    </>
  );

  renderVideoSourceAction = () => {
    const { classes } = this.props;
    const { isLive } = this.state;
    const { historicalVideoChip, liveVideoChip } = classes;
    if (this.isDiveLive()) {
      return (
        <>
          <Chip
            className={historicalVideoChip}
            color="primary"
            label="Historical Video"
          />
          <Switch
            color="default"
            onChange={this.handleLiveVideoClick}
            checked={isLive}
          />
          <Chip className={liveVideoChip} color="primary" label="Live Video" />
        </>
      );
    }
    return undefined;
  };

  isDiveLive = () => {
    const { diveDetails } = this.state;
    return diveDetails && diveDetails.isDiveLive;
  };

  renderCurrentInfo = () => {
    const { showInfoDialog } = this.state;
    return (
      <Dialog
        open={showInfoDialog || false}
        onClose={this.handleCloseInfoDialog}
      >
        {this.determineCurrentInfoContent()}
        <DialogActions>
          <TextButton
            translationKey="common.buttons.close"
            onClick={this.handleCloseInfoDialog}
          />
        </DialogActions>
      </Dialog>
    );
  };

  toggleShowMoreInfo = () => {
    const { showExtraDiveInfo } = this.state;
    CookieUtils.saveCookie(`show-extra-dive-info`, !showExtraDiveInfo);
    this.setState({ showExtraDiveInfo: !showExtraDiveInfo });
  };

  determineCurrentInfoContent = () => {
    const { diveId, onError, classes } = this.props;
    const { annotationPermissions, showExtraDiveInfo } = this.state;

    return (
      <>
        <DialogTitle id="dive-info-title">
          Dive Details
          {annotationPermissions ? (
            <LabelledCheckbox
              className={classes.floatRight}
              label="Show More Info"
              value={showExtraDiveInfo}
              onClick={this.toggleShowMoreInfo}
            />
          ) : (
            <></>
          )}
        </DialogTitle>
        <DialogContent>
          <DiveDetails
            showExtraDiveInfo={showExtraDiveInfo && annotationPermissions}
            diveId={parseInt(diveId, 10)}
            onError={onError}
          />
        </DialogContent>
      </>
    );
  };

  updateAnnotationListItems = () => {
    const { annotations, sortAnnotationsByMostRecent, annotationTotal } =
      this.state;

    const annotationListItems = annotations.sort((a, b) => {
      const date1 = a.startDate;
      const date2 = b.startDate;
      if (sortAnnotationsByMostRecent) {
        return new Date(date2) - new Date(date1);
      }
      return new Date(date1) - new Date(date2);
    });
    this.setState({
      annotationListItems,
      totalListItems: annotationTotal,
    });
  };

  handleAnnotationReviewClick = async (annotationId, value) => {
    const { onError, onInfo } = this.props;
    const { annotations } = this.state;

    const currAnnotation = annotations.find(
      (annotation) => annotation.annotationId === annotationId
    );
    const { annotationReview, taxonId } = currAnnotation.taxons[0];

    try {
      // Case 1: create
      if (!annotationReview) {
        const createResponse =
          await AnnotationReviewService.createAnnotationReview(
            annotationId,
            taxonId,
            value
          );
        currAnnotation.taxons[0].annotationReview = createResponse;

        this.updateAnnotationInState(currAnnotation);
        onInfo('Annotation review created successfully.');
      } else {
        const { annotationReviewId } = annotationReview;

        // Case 2: delete
        if (!value) {
          const deleteResponse =
            await AnnotationReviewService.deleteAnnotationReview(
              annotationReviewId
            );
          currAnnotation.taxons[0].annotationReview = undefined;

          this.updateAnnotationInState(currAnnotation);
          onInfo(deleteResponse.data.message);
        } else {
          // Case 3: update
          const updateResponse =
            await AnnotationReviewService.updateAnnotationReview(
              annotationReviewId,
              annotationId,
              taxonId,
              value
            );
          currAnnotation.taxons[0].annotationReview = updateResponse;

          this.updateAnnotationInState(currAnnotation);
          onInfo('Annotation review updated successfully.');
        }
      }
    } catch (error) {
      onError(error);
    }
  };

  updateAnnotationInState = (updatedAnnotation) => {
    const { annotations } = this.state;
    const updatedAnnotations = [...annotations];

    const index = annotations.findIndex(
      (annotation) => annotation.annotationId === updatedAnnotation.annotationId
    );
    updatedAnnotations[index] = updatedAnnotation;

    this.setState({ annotations: updatedAnnotations });
  };

  generateUserList = () => {
    const { chatLog } = this.state;
    if (!chatLog || !chatLog.length) {
      return [];
    }
    let currentIndex = 0;
    const colorList = [
      '#a31a1a',
      '#b05f16',
      '#3c7a1a',
      '#149fa8',
      '#0d3795',
      '#a20a92',
    ];
    const userMap = [];
    chatLog.forEach((item) => {
      if (item.username) {
        if (!userMap.find((user) => user.username === item.username)) {
          if (currentIndex >= colorList.length) {
            currentIndex = 0;
          }
          userMap.push({
            username: item.username,
            color: colorList[currentIndex],
          });
          currentIndex += 1;
        }
      }
    });
    return userMap;
  };

  renderDiveInProgress = () => {
    const { classes } = this.props;
    if (this.isDiveLive()) {
      return (
        <Chip className={classes.diveInProgressChip} label="DIVE IN PROGRESS" />
      );
    }
    return undefined;
  };

  renderDeleteDialog = () => {
    const { showDeleteDialog } = this.state;
    return (
      <Dialog open={showDeleteDialog} onClose={this.closeDeleteDialog}>
        <DialogTitle id="delete-title">Delete Annotation?</DialogTitle>
        <DialogContent>
          Warning, you are deleting an annotation. It will no longer be viewable
          to any user. This cannot be undone.
        </DialogContent>
        <DialogActions>
          <TextButton
            onClick={this.closeDeleteDialog}
            translationKey="common.buttons.cancel"
          />
          <TextButton
            color="primary"
            onClick={this.confirmDeleteClicked}
            translationKey="seatube.deleteAnnotation"
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderChatLogMessageDeletionDialog = () => {
    const { showChatLogMessageDeletionDialog } = this.state;
    return (
      <Dialog
        open={showChatLogMessageDeletionDialog}
        onClose={this.closeChatLogMessageDeletionDialog}
      >
        <DialogTitle id="delete-title">Delete Chat Log Message?</DialogTitle>
        <DialogContent>
          Warning, you are deleting a chat log message. It will no longer be
          viewable to any user. This cannot be undone.
        </DialogContent>
        <DialogActions>
          <TextButton
            onClick={this.closeChatLogMessageDeletionDialog}
            translationKey="common.buttons.cancel"
          />
          <TextButton
            onClick={this.confirmChatLogDeleteClicked}
            translationKey="common.buttons.delete"
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderChatLogMessageDeleteAllDialog = () => {
    const { showChatLogMessageDeleteAllDialog } = this.state;
    return (
      <Dialog
        open={showChatLogMessageDeleteAllDialog}
        onClose={this.closeChatLogMessageDeleteAllDialog}
      >
        <DialogTitle id="delete-title">
          Delete All Chat Log Messages?
        </DialogTitle>
        <DialogContent>
          Warning, you are deleting all chat log messages for this dive. They
          will no longer be viewable to any user. This cannot be undone.
        </DialogContent>
        <DialogActions>
          <TextButton
            onClick={this.closeChatLogMessageDeleteAllDialog}
            translationKey="common.buttons.cancel"
          />
          <TextButton
            onClick={this.confirmChatLogDeleteAllClicked}
            translationKey="common.buttons.delete"
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderSensorDisplayPanel = () => {
    const { diveId } = this.props;
    const { currentTimestamp, diveDetails, sensorDataTimestamp } = this.state;
    const { dateFrom, dateTo, organizationId } = diveDetails || {};
    const timeStamp = currentTimestamp
      ? Moment(currentTimestamp)
      : Moment(sensorDataTimestamp);

    return (
      <SeaTubeSensorDisplayPanel
        key="seatube-sensor-display"
        diveId={diveId}
        currentDate={timeStamp}
        startDate={dateFrom}
        endDate={dateTo}
        organizationId={organizationId}
      />
    );
  };

  renderVideoComponent = () => {
    const { diveId, isLoggedIn, classes } = this.props;
    const { seekTime, diveDetails, isLive } = this.state;
    const liveStreamUrl = diveDetails ? diveDetails.liveStreamUrl : undefined;
    if (diveDetails) {
      return (
        <VideoPlayer
          classes={classes}
          playerId="seatube-player-1"
          diveId={diveId}
          isLive={isLive}
          liveStreamUrl={liveStreamUrl}
          isLoggedIn={isLoggedIn}
          seekTo={seekTime}
          onSeek={this.handleSeek}
          onTime={this.handleTimeChange}
          snapshotQuality="O"
        />
      );
    }
    return <></>;
  };

  renderFilterDialog = () => {
    const { diveId, seatubeSearch, isLoggedIn } = this.props;
    const {
      filter,
      filterShowing,
      creatorOptions,
      modifierOptions,
      diveDetails,
    } = this.state;
    return (
      <AnnotationListFilterDialog
        filter={filter}
        filterShowing={filterShowing}
        creatorOptions={creatorOptions}
        modifierOptions={modifierOptions}
        resourceTypeId={SeaTubeResourceTypes.DIVE}
        resourceId={diveId}
        expeditionId={diveDetails ? diveDetails.cruiseId : undefined}
        seatubeSearch={seatubeSearch}
        isLoggedIn={isLoggedIn}
        onFilterChange={this.handleFilterChange}
        onApplyFilter={this.handleApplyFilter}
        onFilterClose={() => this.handleToggleFilter(false)}
        onReset={this.handleClearFilter}
      />
    );
  };

  render() {
    const { classes, diveId, isLoggedIn, currentUserId, seatubeSearch } =
      this.props;
    const {
      diveDetails,
      currentTimestamp,
      filter,
      annotations,
      selectedAnnotation,
      creatorOptions,
      modifierOptions,
      deletePermissions,
      selfDeletePermissions,
      bathymetryPermissions,
      annotationPermissions,
      reviewPermissions,
      editAnnotation,
      lastEditedAnnotationId,
      sortAnnotationsByMostRecent,
      loading,
      totalListItems,
      nullableAttributes,
      filterShowing,
      seatubeConfig,
      chatLog,
      annotationListItems,
      showExtraDiveInfo,
      isLive,
      sensorDataTimestamp,
      loadingDiveDetails,
      loadingPermissions,
    } = this.state;
    const cruiseId = diveDetails ? diveDetails.cruiseId : undefined;
    const defaultDeviceId = diveDetails
      ? diveDetails.defaultDeviceId
      : undefined;

    const userType = isLoggedIn ? 'user' : 'anonymous';
    const selectedAnnotationId = selectedAnnotation
      ? selectedAnnotation.annotationId
      : undefined;

    if (loadingDiveDetails || loadingPermissions) {
      return <Loading />;
    }

    return (
      <div className={classes.root} id="seatube-root-id">
        {this.renderCurrentInfo()}
        {this.renderDeleteDialog()}
        {this.renderChatLogMessageDeletionDialog()}
        {this.renderChatLogMessageDeleteAllDialog()}
        {this.renderFilterDialog()}
        <SeaTubeLayout
          containerId="seatube-root-id"
          className={classes.layout}
          title={this.generateDiveName()}
          titleContent={this.renderDiveInProgress()}
          annotationPermissions={annotationPermissions}
          appBarContent={this.renderDiveInfoAction()}
          userType={userType}
          onLayoutClick={this.handleLayoutClick}
          chatLog={chatLog}
        >
          {annotationPermissions ? (
            <ManualEntryPanel
              key="annotation-entry"
              name="annotation-entry"
              onSave={this.handleAnnotationSave}
              cruiseId={cruiseId}
              currentTimestamp={currentTimestamp}
              editAnnotation={editAnnotation}
              diveId={diveId}
              onClear={this.handleClearEntry}
              defaultDeviceId={defaultDeviceId}
              nullableAttributes={
                nullableAttributes &&
                diveDetails &&
                diveDetails.organizationId !== NOAA_DATA.organizationId
              }
              onUpdateConfig={this.handleUpdateWidgetConfig}
              userType={userType}
              cruiseName={diveDetails ? diveDetails.cruiseName : undefined}
              diveDateFrom={diveDetails ? diveDetails.dateFrom : undefined}
              diveDateTo={diveDetails ? diveDetails.dateTo : undefined}
              organizationId={
                diveDetails ? diveDetails.organizationId : undefined
              }
              seatubeConfig={seatubeConfig}
              isDiveActive={this.isDiveLive()}
              isLive={isLive}
            />
          ) : null}

          {annotationPermissions && chatLog && chatLog.length ? (
            <ChatLogList
              key="chat-log"
              name="chat-log"
              messages={chatLog}
              chatUserMap={this.generateUserList()}
              currentTimestamp={currentTimestamp || sensorDataTimestamp}
              onMessageClick={this.handleListItemClicked}
              onUpdateConfig={this.handleUpdateWidgetConfig}
              userType={userType}
              deletePermissions={deletePermissions}
              onChatMessageDeleteClick={this.handleChatLogMessageDeleteClicked}
              onChatMessageDeleteAllClick={
                this.handleChatLogMessageDeleteAllClicked
              }
            />
          ) : null}

          <SeaTubeMapPanel
            key="seatube-map"
            handleAnnotationClicked={this.handleAnnotationClicked}
            handleDivePathClicked={this.seekVideoTo}
            diveId={diveId}
            currentTimestamp={currentTimestamp || sensorDataTimestamp}
            annotations={annotations}
            bathymetryPermissions={bathymetryPermissions}
            selectedAnnotationId={selectedAnnotationId}
          />
          <SeaTubeVideoPanel key="seatube-video">
            {this.renderVideoComponent()}
          </SeaTubeVideoPanel>
          <AnnotationList
            key="annotation-list"
            name="annotation-list"
            totalListItems={totalListItems}
            loadAnnotations={this.getFilteredAnnotations}
            onListItemClick={this.handleListItemClicked}
            onAnnotationEditClick={this.handleAnnotationEditClicked}
            onAnnotationDeleteClick={this.handleAnnotationDeleteClicked}
            onUpdateConfig={this.handleUpdateWidgetConfig}
            filter={filter}
            onReset={this.handleClearFilter}
            onToggleFilter={this.handleToggleFilter}
            onAnnotationSort={this.handleAnnotationSort}
            creators={creatorOptions}
            modifiers={modifierOptions}
            resourceTypeId={SeaTubeResourceTypes.DIVE}
            resourceId={diveId}
            expeditionId={cruiseId}
            deletePermissions={deletePermissions}
            selfDeletePermissions={selfDeletePermissions}
            annotationPermissions={annotationPermissions}
            reviewPermissions={reviewPermissions}
            annotationSortByMostRecent={sortAnnotationsByMostRecent}
            loading={loading}
            currentUserId={currentUserId}
            seatubeSearch={seatubeSearch}
            filterShowing={filterShowing}
            userType={userType}
            listItems={annotationListItems || []}
            currentTimestamp={currentTimestamp || sensorDataTimestamp}
            selectedAnnotationId={selectedAnnotationId}
            onReviewClick={this.handleAnnotationReviewClick}
            lastEditedAnnotationId={lastEditedAnnotationId}
            editAnnotationId={editAnnotation?.annotationId}
          />
          {this.renderSensorDisplayPanel()}
          <DiveDetailsPanel
            key="seatube-dive-details"
            diveId={parseInt(diveId, 10)}
            onToggleShowMoreInfo={this.toggleShowMoreInfo}
            showExtraDiveInfo={showExtraDiveInfo}
            permissions={annotationPermissions}
          />
        </SeaTubeLayout>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(SeaTube));
