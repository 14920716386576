import { useEffect, useState } from 'react';
import { Grid, LinearProgress, Switch, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Dropdown, TextField, Tooltip } from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import TaxonAttributeService, {
  MatrixAttribute,
} from 'domain/services/TaxonAttributeService';
import TaxonomyAttributeGroupService from 'domain/services/TaxonomyAttributeGroupService';
import TaxonomyAttributeService, {
  TaxonomyAttributeJson,
} from 'domain/services/TaxonomyAttributeService';
import { TaxonomyItem, TaxonItem } from 'domain/Widgets/TaxonomyTreeWidget';
import useWebService from 'util/hooks/useWebService';

type Props = {
  taxonomy: TaxonomyItem;
  taxon: TaxonItem;
  onError: (message: string) => void;
  onInfo: (message: string) => void;
};

const DefaultAttributeForm = ({ taxonomy, taxon, onError, onInfo }: Props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    attributeActionButton: {
      marginTop: theme.spacing(2),
      float: 'left',
    },
  }));

  const classes = useStyles();

  const [groupList, , fetchGroupList] = useWebService({
    method: TaxonomyAttributeGroupService.getAll,
    onError,
  });

  const [, , createDefaultAttribute] = useWebService({
    method: TaxonAttributeService.create,
    onError,
  });

  const [, , updateDefaultAttribute] = useWebService({
    method: TaxonAttributeService.update,
    onError,
  });

  const [, , deleteDefaultAttribute] = useWebService({
    method: TaxonAttributeService.delete,
    onError,
  });

  const [defaultAttributes, setDefaultAttributes] = useState<MatrixAttribute[]>(
    []
  );

  const [commentField, setCommentField] = useState<string | null>();

  const [reload, setReload] = useState<Date>(new Date());

  const transformGroupListArr = () =>
    groupList.map((group) => ({
      label: group.name,
      value: group.groupId,
    }));

  const [selectableAttributeList, , fetchSelectableAttributeList] =
    useWebService({
      method: TaxonomyAttributeService.getAllSelectable,
      onError,
    });

  const [attributeList, , fetchAttributeList] = useWebService({
    method: TaxonAttributeService.getAttributes,
    onError,
  });

  const createAttributeListFromGroups = (attr) => {
    const temp = [];
    selectableAttributeList.forEach(
      (selAttr) =>
        selAttr.groupName === attr.groupName &&
        temp.push({ label: selAttr.name, value: selAttr.attributeId })
    );
    // List of attribute names to remove from a dropdown's options list
    const toRemove = [];
    defaultAttributes.forEach(
      (dattr) => dattr.name !== attr.name && toRemove.push(dattr.name)
    );
    return temp.filter((t) => !toRemove.find((e) => e === t.label));
  };

  useEffect(() => {
    // Allows for default attributes to be displayed if a taxonomy is selected
    fetchAttributeList(taxonomy.taxonomyId, taxon?.taxonId).then((attrList) => {
      if (attrList !== null) {
        const currentAttrs = attrList.getCurrentAttributes(true);
        setDefaultAttributes(currentAttrs);
        currentAttrs.some((attr) => attr.name === 'Comment') &&
          setCommentField(
            currentAttrs.find((attr) => attr.name === 'Comment').value
          );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxon, taxonomy, reload]);

  useEffect(() => {
    fetchGroupList();
    fetchSelectableAttributeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findLabelFromList = (
    li: {
      label: string;
      value: number;
    }[],
    toCompareWith: number
  ) => li.find((item) => item.value === toCompareWith).label;

  const findValueFromList = (
    li: {
      label: string;
      value: number;
    }[],
    toCompareWith: string
  ) => li.find((item) => item.label === toCompareWith).value;

  const handleCreateDefaultAttribute = (
    attr: MatrixAttribute,
    onInfoStr: string = 'Successfully created a new attribute.'
  ) => {
    const newAttr = { ...attr };
    createDefaultAttribute(
      taxon.taxonId,
      taxonomy.taxonomyId,
      newAttr.attributeId,
      newAttr.value,
      newAttr.excluded
    ).then((payload) => {
      if (payload.wasSaved && payload.id) {
        setDefaultAttributes((defaultAttrArr) => [
          ...defaultAttrArr,
          { ...newAttr, taxonomyMatrixAttributeId: payload.id },
        ]);
        newAttr.name === 'Comment' && setCommentField(newAttr.value);
        setReload(new Date());
        onInfo(onInfoStr);
      } else {
        onError('Failed to create a new default attribute.');
      }
    });
  };

  const handleDefaultAttributeChange = (
    index: number,
    newAttr: MatrixAttribute
  ) => {
    const copyNewAttr = { ...newAttr };
    const newDefaultAttrArr = defaultAttributes.map((attr, i) => {
      if (i === index) {
        return copyNewAttr;
      }
      return attr;
    });
    copyNewAttr.taxonomyMatrixAttributeId &&
      updateDefaultAttribute(
        copyNewAttr.taxonomyMatrixAttributeId,
        taxon ? taxon.taxonomyMatrixId : taxonomy.taxonomyMatrixId,
        copyNewAttr.attributeId,
        copyNewAttr.value,
        copyNewAttr.excluded
      )
        .then(() => {
          onInfo('Successfully updated.');
          setDefaultAttributes(newDefaultAttrArr);
        })
        .catch((e) => onError(e.message));
  };

  const handleDeleteDefaultAttribute = (
    attr: MatrixAttribute,
    onInfoStr: string = 'Successfully deleted.'
  ) => {
    attr.name === 'Comment' && setCommentField(null);
    attr.taxonomyMatrixAttributeId &&
      deleteDefaultAttribute(attr.taxonomyMatrixAttributeId)
        .then(() => {
          setDefaultAttributes((defaultAttrArr) =>
            defaultAttrArr.filter(
              (defAttr) => defAttr.attributeId !== attr.attributeId
            )
          );
          setReload(new Date());
          onInfo(onInfoStr);
        })
        .catch((e) => onError(e.message));
  };

  const onCommentBlur = (index: number, newAttr: MatrixAttribute) => {
    if (commentField !== newAttr.value) {
      setCommentField(newAttr.value);
      handleDefaultAttributeChange(index, newAttr);
    }
  };

  const renderValueField = (index: number, attr: MatrixAttribute) => {
    switch (attr.name) {
      case 'Comment':
        return (
          <div contentEditable onBlur={() => onCommentBlur(index, attr)}>
            <TextField
              fullWidth
              translationKey="common.textfields.value"
              value={attr.value}
              disabled={attr.excluded}
              onChange={(e) =>
                setDefaultAttributes((defaultAttrArr) =>
                  defaultAttrArr.map((attribute, i) => {
                    if (i === index) {
                      return { ...attribute, value: e.target.value };
                    }
                    return attribute;
                  })
                )
              }
            />
          </div>
        );
      case 'Resource':
      case 'Resource Type': {
        const attrValues: any[] = selectableAttributeList.find(
          (taj) => taj.attributeId === attr.attributeId
        ).attributeValues;
        return (
          <Dropdown
            label="Value"
            options={attrValues}
            value={
              attr.value === 'null' || attr.value === ''
                ? ''
                : findValueFromList(attrValues, attr.value)
            }
            onChange={(e) =>
              attr.inherited
                ? handleCreateDefaultAttribute({
                    ...attr,
                    inherited: false,
                    value: findLabelFromList(
                      attrValues,
                      Number(e.target.value)
                    ),
                  })
                : handleDefaultAttributeChange(index, {
                    ...attr,
                    value: findLabelFromList(
                      attrValues,
                      Number(e.target.value)
                    ),
                  })
            }
            disabled={attr.excluded}
            fullWidth
          />
        );
      }
      default:
        return <></>;
    }
  };

  const handleToggleExclude = (index: number, attr: MatrixAttribute) => {
    const updatedAttr = { ...attr };
    // for the logic here, overrideable attributes need to consider if they have an ancestor to replace them
    const isInherited: boolean = attributeList.overrideableAttributes.some(
      (attribute) => attribute.attributeId === attr.attributeId
    )
      ? attributeList.inheritedOverrideableAttributes.some(
          (attribute) => attribute.attributeId === attr.attributeId
        )
      : attr.inherited;

    if (attr.excluded && !isInherited) {
      // handles an excluded attribute, but the "original" matrix attribute it was inheriting from was later deleted
      updatedAttr.excluded = false;
      handleDefaultAttributeChange(index, updatedAttr);
    } else if (!attr.excluded && isInherited) {
      // handles an inherited attribute
      updatedAttr.inherited = false;
      updatedAttr.excluded = true;
      handleCreateDefaultAttribute(
        updatedAttr,
        'Successfully toggled default attribute.'
      );
    } else {
      // handles excluded && inherited (an excluded attribute), and !excluded && !inherited (a local attribute)
      handleDeleteDefaultAttribute(
        attr,
        'Successfully excluded default attribute.'
      );
    }
  };

  const renderAction = (index: number, attr: MatrixAttribute) => {
    if (attr.inherited || attr.excluded) {
      return (
        <Tooltip
          title="Exclude inherited attribute"
          disableHoverListener={attr.excluded}
        >
          <Switch
            onChange={() => handleToggleExclude(index, attr)}
            checked={!attr.excluded}
            className={classes.attributeActionButton}
          />
        </Tooltip>
      );
    }
    return (
      <DeleteIconButton
        className={classes.attributeActionButton}
        onClick={() => handleDeleteDefaultAttribute(attr)}
        tooltipProps={{ placement: 'right-start' }}
      />
    );
  };

  // Creates a MatrixAttribute from a TaxonomyAttributeJson for new attribute creation
  const convertTAJtoMatrixAttr = (taxAttrJson: TaxonomyAttributeJson) => {
    const newAttr = new MatrixAttribute({
      attributeId: taxAttrJson.attributeId,
      excluded: false,
      groupName: taxAttrJson.groupName,
      inherited: false,
      name: taxAttrJson.name,
      value: !taxAttrJson.attributeValues
        ? taxAttrJson.attributeValues[0].value
        : '',
    });
    return newAttr;
  };

  const findFirstUnusedAttribute = () =>
    selectableAttributeList.find(
      (selectableAttr) =>
        !defaultAttributes.some(
          (attr) => attr.attributeId === selectableAttr.attributeId
        )
    );

  const handleCreateDefaultAttributeClick = () => {
    handleCreateDefaultAttribute(
      convertTAJtoMatrixAttr(findFirstUnusedAttribute())
    );
  };

  if (!groupList || !attributeList || !selectableAttributeList) {
    return <LinearProgress />;
  }

  return (
    <form id="default-attribute-form" className={classes.contentContainer}>
      <Typography variant="h6">Default Attributes</Typography>
      {defaultAttributes.length !== 0 &&
        defaultAttributes.map((attr, index) => (
          <div id={`default-attribute-values-${index}`}>
            <Grid container spacing={1}>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Dropdown
                  label="Category"
                  options={transformGroupListArr()}
                  value={findValueFromList(
                    transformGroupListArr(),
                    attr.groupName
                  )}
                  onChange={(e) =>
                    setDefaultAttributes((defaultAttributesArr) =>
                      defaultAttributesArr.map((defAttr, i) =>
                        i === index
                          ? {
                              ...attr,
                              groupName: findLabelFromList(
                                transformGroupListArr(),
                                Number(e.target.value)
                              ),
                              attributeId: -1,
                              name: null,
                              value: 'null',
                            }
                          : defAttr
                      )
                    )
                  }
                  disabled={attr.inherited || attr.excluded}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Dropdown
                  label="Attribute"
                  options={createAttributeListFromGroups(attr)}
                  value={attr.attributeId}
                  onChange={(e) =>
                    handleDefaultAttributeChange(index, {
                      ...attr,
                      attributeId: Number(e.target.value),
                      name: findLabelFromList(
                        createAttributeListFromGroups(attr),
                        Number(e.target.value)
                      ),
                    })
                  }
                  disabled={attr.inherited || attr.excluded}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                {renderAction(index, attr)}
              </Grid>
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              {renderValueField(index, attr)}
            </Grid>
          </div>
        ))}
      <div>
        <TextButton
          translationKey="taxonomy.addDefaultAttribute"
          startIcon={<Add />}
          onClick={handleCreateDefaultAttributeClick}
        />
      </div>
    </form>
  );
};

export default DefaultAttributeForm;
