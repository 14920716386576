/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Collapse, Grid } from 'base-components';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: theme.spacing(80),
  },
}));

export type DummyConfigType = {
  showWidgetHeader: boolean;
  widgetTitle: string;
  widgetComment: string;
};

const DummyWidgetConfigContent: ConfigFields<DummyConfigType> = ({
  formMethods,
}) => {
  const classes = useStyles();
  const { watch, register } = formMethods;
  const { showWidgetHeader } = watch();

  useEffect(() => {
    register('widgetComment', {
      required: 'Widget Comment is required',
      minLength: {
        value: 5,
        message: 'Widget Comment must be at least 5 characters',
      },
    });
  }, [register]);

  return (
    <Grid container spacing={1} className={classes.form}>
      <Grid item xs={12}>
        <FormToggle name="showWidgetHeader" label="Show Widget Header" />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showWidgetHeader}>
          <FormTextField
            name="widgetTitle"
            translationKey="common.textfields.title"
            fullWidth
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          name="widgetComment"
          translationKey="common.textfields.comment"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default DummyWidgetConfigContent;
