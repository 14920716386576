/* eslint-disable camelcase */
// Disable camelcase warnings because the data product option parameters are preceded with dpo_
import { useState, ChangeEvent, useContext } from 'react';

import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { TextButton } from '@onc/composite-components';
import { GetApp, Add, Close } from '@onc/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  LinearProgress,
  Autocomplete,
  TableOld as Table,
} from 'base-components';
import CheckboxArray from 'domain/AppComponents/geospatial-search/community-fisher/CheckboxArray';
import useFabDialogStyles from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/useFabDialogStyles';
import SelectButton from 'domain/AppComponents/geospatial-search/community-fisher/SelectButton';
import SelectedProductIcons from 'domain/AppComponents/geospatial-search/community-fisher/SelectedProductIcons';
import SiteDeviceSubsetProductTableDialog from 'domain/AppComponents/geospatial-search/community-fisher/SiteDeviceSubsetProductTableDialog';
import {
  PLOT_DATA_PRODUCT,
  ANNOTATION_DATA_PRODUCT,
  XML_DATA_PRODUCT,
  ALL_DATA_PRODUCTS,
  COMPLETED,
  NOT_DOWNLOADED,
  ERROR,
  RESTRICTED,
  SELECTED_PRODUCTS,
  USER_NOT_LOGGED_IN,
  COR_AND_CSV_DATA_PRODUCT,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';
import {
  SiteDeviceSubsetProduct,
  ProductNames,
  SiteDeviceSubsetRow,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import dataProductDeliveryRequest from 'domain/Apps/geospatial-search/community-fisher/CastDataProductDeliveryRequest';
import Environment from 'util/Environment';
import { get } from 'util/WebRequest';
import CastDataContext from '../CastDataContext';

const RESTRICTED_DATAPRODUCT_ERROR_CODE = 71;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formButtons: {
      flexDirection: 'row-reverse',
      display: 'flex',
      paddingRight: theme.spacing(3),
    },
    tableStyle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    linearProgStyle: {
      width: '70%',
    },
    buttonGroupStyle: { left: theme.spacing(-5) },
    buttonStyle: {
      color: 'white',
      margin: theme.spacing(0),
      width: theme.spacing(24),
      'justify-content': 'left',
    },
    emptySpanStyle: {
      display: 'inline-block',
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
    },
    fitContent: {
      top: '8% !important',
      width: 'fit-content',
    },
    dialogPaper: {
      // Makes sure the dialog never extends below the screen
      maxHeight: '90vh',
    },
  })
);

interface Props {
  siteDeviceSubsetRows: SiteDeviceSubsetRow[];
  onInfo: (msg: string) => void;
  onError: (msg: string) => void;
  onCloseClick: () => void;
  renderSearchField?: boolean;
  childCheckBoxOnChange?: (subset: any) => void;
}

const SiteDeviceSubsetDownloadTableDialog: React.VFC<Props> = ({
  siteDeviceSubsetRows,
  onInfo,
  onError,
  renderSearchField = undefined,
  onCloseClick,
  childCheckBoxOnChange = undefined,
}) => {
  const { onCastDownloaded, onCastDeleted } = useContext(CastDataContext);
  const classes = { ...useStyles(), ...useFabDialogStyles() };
  const [siteDeviceSubsetRowsState, setSiteDeviceSubetRows] =
    useState<SiteDeviceSubsetRow[]>(siteDeviceSubsetRows);

  const [downloadButtonClicked, setDownloadButtonClicked] =
    useState<boolean>(false);

  const [
    siteDeviceSubsetToShowAddProductsFor,
    setSiteDeviceSubsetToShowAddProductsFor,
  ] = useState<SiteDeviceSubsetRow | undefined>(undefined);

  const deleteIconOnClick = (siteDeviceSubsetId: number) => () => {
    const filteredSiteDeviceSubsetRows = siteDeviceSubsetRowsState.filter(
      (subset) => {
        if (subset.siteDeviceSubsetId === siteDeviceSubsetId) {
          if (childCheckBoxOnChange) {
            childCheckBoxOnChange(subset);
          }
          return false;
        }
        return true;
      }
    );

    setSiteDeviceSubetRows([...filteredSiteDeviceSubsetRows]);
    if (onCastDeleted) {
      onCastDeleted(siteDeviceSubsetId);
    }
  };

  const filterToSelectedProducts = (
    subsetsToFilter: SiteDeviceSubsetRow[]
  ): SiteDeviceSubsetProduct[] =>
    subsetsToFilter
      .flatMap((subset) => subset.productsForCast) //
      .filter((productsForSubsets) => productsForSubsets.isSelected);

  const updateSelectedProducts = (
    subsetToUpdate: SiteDeviceSubsetRow,
    productName: ProductNames,
    isSelected: boolean,
    extension: string
  ) => {
    const castIndex = siteDeviceSubsetRowsState.findIndex(
      (subsetRow) =>
        subsetRow.siteDeviceSubsetId === subsetToUpdate.siteDeviceSubsetId
    );
    const { productsForCast } = siteDeviceSubsetRowsState[castIndex];
    const productIndex = productsForCast.findIndex(
      (prod) => prod.productName === productName && prod.extension === extension
    );
    if (productIndex === -1) {
      // product does not exist for this cast
      return;
    }

    productsForCast[productIndex].isSelected = isSelected;
    // flip the state of the download all button if product is not done
    if (productsForCast[productIndex].status !== COMPLETED) {
      setDownloadButtonClicked(false);
    }

    siteDeviceSubsetRowsState[castIndex].productsForCast = productsForCast;
    setSiteDeviceSubetRows([...siteDeviceSubsetRowsState]);
  };

  const updateStatusForCastProducts = (
    cast: SiteDeviceSubsetRow,
    dataProduct: ProductNames,
    status: string,
    dpRunId: number,
    dpRequestId: number,
    extension?: string
  ): SiteDeviceSubsetRow => {
    const castIndex = siteDeviceSubsetRowsState.findIndex(
      (subsetRow) => subsetRow.siteDeviceSubsetId === cast.siteDeviceSubsetId
    );
    const { productsForCast } = siteDeviceSubsetRowsState[castIndex];
    const indexOfProductToUpdate = productsForCast.findIndex(
      (prods) =>
        prods.productName === dataProduct && prods.extension === extension
    );
    productsForCast[indexOfProductToUpdate].status = status;
    productsForCast[indexOfProductToUpdate].dpRunId = dpRunId;
    productsForCast[indexOfProductToUpdate].dpRequestId = dpRequestId;

    siteDeviceSubsetRowsState[castIndex].productsForCast = productsForCast;
    setSiteDeviceSubetRows([...siteDeviceSubsetRowsState]);
    return siteDeviceSubsetRowsState[castIndex];
  };

  /**
   * Checks status of data product.
   *
   * @param {object} cast
   * @param {ProductNames} dataProduct
   * @param {number} dpRequestId
   * @param {number} dpRunId
   * @param {string} extension
   */
  const checkStatus = (
    cast: SiteDeviceSubsetRow,
    dataProduct: ProductNames,
    dpRequestId: number,
    dpRunId: number,
    extension: string
  ) =>
    get(
      'apiproxy/dataProductDelivery',
      {
        method: 'status',
        dpRequestId,
      },
      {}
    )
      .then((response) => {
        const { searchHdrStatus, newSearches } = response.data;
        const { taskStatus } = newSearches[0];
        if (searchHdrStatus !== 'COMPLETED') {
          setTimeout(() => {
            checkStatus(cast, dataProduct, dpRequestId, dpRunId, extension);
          }, 5000);
        } else {
          const updatedCast = updateStatusForCastProducts(
            cast,
            dataProduct,
            COMPLETED,
            dpRunId,
            dpRequestId,
            extension
          );
          if (onCastDownloaded) {
            onCastDownloaded(updatedCast);
          }
          const completionMessage = `Generation of ${dataProduct} finished for ${cast.siteDeviceSubsetName} with the following status: ${taskStatus}.`;

          if (taskStatus !== COMPLETED) {
            // task does not successfully complete (completed with errors, cancelled, ...)
            onError(completionMessage);
          }
        }
      })
      .catch((error) => {
        const errorMessage = Environment.isUserLoggedIn()
          ? error.message
          : 'Please try logging in to access the requested data products.';
        onError(errorMessage);
        updateStatusForCastProducts(
          cast,
          dataProduct,
          ERROR,
          -1,
          -1,
          extension
        );
      });

  /**
   * Runs data product
   *
   * @param {object} cast
   * @param {ProductNames} dataProduct
   * @param {number} dpRequestId
   * @param {string} extension
   */
  const runDataProduct = (
    cast: SiteDeviceSubsetRow,
    dataProduct: ProductNames,
    dpRequestId: number,
    extension: string
  ) =>
    get(
      'apiproxy/dataProductDelivery',
      {
        method: 'run',
        dpRequestId,
      },
      {}
    )
      .then((response) => {
        const { dpRunId } = response.data[0];
        updateStatusForCastProducts(
          cast,
          dataProduct,
          'Running',
          dpRunId,
          dpRequestId,
          extension
        );
        checkStatus(cast, dataProduct, dpRequestId, dpRunId, extension);
      })
      .catch((error) => {
        onError(error.message);
        updateStatusForCastProducts(
          cast,
          dataProduct,
          ERROR,
          -1,
          -1,
          extension
        );
      });

  const requestDataProduct = (
    cast: SiteDeviceSubsetRow,
    dataProduct: ProductNames,
    extension: string
  ) => {
    dataProductDeliveryRequest({
      ...cast,
      dataProduct,
      extension,
    })
      .then((response) => {
        const { dpRequestId } = response.data;
        updateStatusForCastProducts(
          cast,
          dataProduct,
          'Requesting',
          -1,
          dpRequestId,
          extension
        );
        runDataProduct(cast, dataProduct, dpRequestId, extension);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          error.response.data.errors.forEach((dataError) => {
            onError(`${dataError.errorMessage}: ${dataError.parameter}`);
            // pick out errors caused by restricted dataproducts to provide a specific error message
            dataError.errorCode === RESTRICTED_DATAPRODUCT_ERROR_CODE
              ? updateStatusForCastProducts(
                  cast,
                  dataProduct,
                  RESTRICTED,
                  -1,
                  -1,
                  extension
                )
              : updateStatusForCastProducts(
                  cast,
                  dataProduct,
                  ERROR,
                  -1,
                  -1,
                  extension
                );
          });
        } else {
          onError(error.message);
          updateStatusForCastProducts(
            cast,
            dataProduct,
            ERROR,
            -1,
            -1,
            extension
          );
        }
      });
  };

  const downloadAllFiles = (
    dpIds: {
      dpRunId?: number;
      dpRequestId?: number;
    }[]
  ) => {
    if (dpIds.length > 0) {
      const { dpRequestId } = dpIds[0];
      const dpRunIds = dpIds.map((dpId) => dpId.dpRunId).toString();
      const zipFileUrl = `${Environment.getDmasUrl()}/SearchResultService?searchHdrId=${dpRequestId}&searchIds=${dpRunIds}&skipSubfolders=true`;
      window.location.href = zipFileUrl;
    }
  };

  const onSubsetChange = (
    event: any,
    value?: {
      siteDeviceSubsetName: string;
      siteDeviceSubsetId: number;
      dataProduct: string;
      startDate: Date;
      endDate: Date;
      code: string;
      status: string;
      dpRunId?: number;
      dpRequestId?: number;
    }
  ) => {
    if (value) {
      const subsetRowsWithValue = siteDeviceSubsetRowsState.filter(
        (subset) => subset.siteDeviceSubsetId === value.siteDeviceSubsetId
      );
      const subsetRowsWithoutValue = siteDeviceSubsetRowsState.filter(
        (subset) => subset.siteDeviceSubsetId !== value.siteDeviceSubsetId
      );
      setSiteDeviceSubetRows(
        subsetRowsWithValue.concat(subsetRowsWithoutValue)
      );
    }
  };

  const openAddProductsDialog = (siteDeviceSubset: SiteDeviceSubsetRow) => {
    setSiteDeviceSubsetToShowAddProductsFor(siteDeviceSubset);
  };

  const areSelectedProductsRunning = (
    siteDeviceSubset: SiteDeviceSubsetRow
  ): boolean =>
    siteDeviceSubset.productsForCast
      .filter((prod) => prod.isSelected)
      .some(
        (selectedProd) =>
          selectedProd.status !== COMPLETED &&
          selectedProd.status !== NOT_DOWNLOADED &&
          selectedProd.status !== ERROR &&
          selectedProd.status !== RESTRICTED
      );

  const areAllSelectedProductsGivenStatus = (
    siteDeviceSubset: SiteDeviceSubsetRow,
    status: string
  ): boolean =>
    siteDeviceSubset.productsForCast
      .filter((prod) => prod.isSelected)
      .every((selectedProd) => selectedProd.status === status);

  const areSomeSelectedProductsGivenStatus = (
    siteDeviceSubset: SiteDeviceSubsetRow,
    status: string
  ): boolean =>
    siteDeviceSubset.productsForCast
      .filter((prod) => prod.isSelected)
      .some((selectedProd) => selectedProd.status === status);

  const requestAllSelectedProductsForCast = (
    siteDeviceSubset: SiteDeviceSubsetRow
  ) => {
    const selectedNonCompleteProducts = filterToSelectedProducts([
      siteDeviceSubset,
    ]).filter((prod) => prod.status !== COMPLETED);
    selectedNonCompleteProducts.forEach((prod) =>
      requestDataProduct(siteDeviceSubset, prod.productName, prod.extension)
    );
  };

  const downloadAllSelectedCompletedProducts = (
    siteDeviceSubset: SiteDeviceSubsetRow
  ) => {
    const selectedCompletedProducts = filterToSelectedProducts([
      siteDeviceSubset,
    ])
      .filter((prod) => prod.status === COMPLETED)
      .map((prod) => ({
        dpRunId: prod.dpRunId,
        dpRequestId: prod.dpRequestId,
      }));
    downloadAllFiles(selectedCompletedProducts);
  };

  const onClickCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    productName: ProductNames,
    extension: string
  ) =>
    siteDeviceSubsetRowsState.forEach((subset) =>
      updateSelectedProducts(
        subset,
        productName,
        event.target.checked,
        extension
      )
    );

  const closeAddProductsDialog = () => {
    setSiteDeviceSubsetToShowAddProductsFor(undefined);
  };

  const updateProductsForCast = (
    siteDeviceSubset: SiteDeviceSubsetRow,
    dataProductName: ProductNames,
    isSelected: boolean,
    extension: string
  ) => {
    updateSelectedProducts(
      siteDeviceSubset,
      dataProductName,
      isSelected,
      extension
    );
  };

  const customHeaderCellProvider = (column: {
    title: string;
    name: string;
  }) => {
    const { title } = column;
    const checkbox =
      title === SELECTED_PRODUCTS ? (
        <CheckboxArray
          onClickPlot={(event: ChangeEvent<HTMLInputElement>) =>
            onClickCheckbox(event, PLOT_DATA_PRODUCT, 'png')
          }
          onClickCor={(event: ChangeEvent<HTMLInputElement>) =>
            onClickCheckbox(event, COR_AND_CSV_DATA_PRODUCT, 'cor')
          }
          onClickAnnotations={(event: ChangeEvent<HTMLInputElement>) =>
            onClickCheckbox(event, ANNOTATION_DATA_PRODUCT, 'csv')
          }
          onClickXml={(event: ChangeEvent<HTMLInputElement>) =>
            onClickCheckbox(event, XML_DATA_PRODUCT, 'xml')
          }
          onClickCsv={(event: ChangeEvent<HTMLInputElement>) =>
            onClickCheckbox(event, COR_AND_CSV_DATA_PRODUCT, 'csv')
          }
        />
      ) : (
        // make an empty span the same size as the checkboxes so that the headers line up
        <span className={classes.emptySpanStyle} />
      );
    return (
      <div>
        <span>{title}</span>
        <br />
        {checkbox}
      </div>
    );
  };

  const requestAllCastDataProducts = () => {
    if (
      !downloadButtonClicked &&
      filterToSelectedProducts(siteDeviceSubsetRowsState).some(
        (selectedProducts) => selectedProducts.status !== COMPLETED
      )
    ) {
      siteDeviceSubsetRowsState.forEach((subset) =>
        setTimeout(() => requestAllSelectedProductsForCast(subset), 1000)
      );
    } else if (
      !filterToSelectedProducts(siteDeviceSubsetRowsState).some(
        (selectedProducts) => selectedProducts.status !== COMPLETED
      )
    ) {
      const dataProductIds = filterToSelectedProducts(
        siteDeviceSubsetRowsState
      ).map((selectedProducts) => ({
        dpRunId: selectedProducts.dpRunId,
        dpRequestId: selectedProducts.dpRequestId,
      }));

      downloadAllFiles(dataProductIds);
    } else {
      onInfo(
        'Some data products have not finshed downloading yet. Please wait for all downloads to complete'
      );
    }
    setDownloadButtonClicked(true);
  };

  const downloadRequestProductForAllCasts = (productName: string) => {
    if (productName === ALL_DATA_PRODUCTS) {
      requestAllCastDataProducts();
      return;
    }
    const allSelectedCompletedProductsOfGivenType = filterToSelectedProducts(
      siteDeviceSubsetRowsState
    )
      .filter(
        (prod) => prod.status === COMPLETED && prod.productName === productName
      )
      .map((prod) => ({
        dpRunId: prod.dpRunId,
        dpRequestId: prod.dpRequestId,
      }));
    downloadAllFiles(allSelectedCompletedProductsOfGivenType);
  };

  const columns = [
    {
      name: 'siteDeviceSubsetName',
      title: 'Cast Name',
    },
    {
      name: 'startDate',
      title: 'Start Date',
    },
    {
      name: 'endDate',
      title: 'End Date',
    },
    {
      name: 'product',
      title: SELECTED_PRODUCTS,
    },
    {
      name: 'status',
      title: 'Status',
    },
    {
      name: 'actions',
      title: 'Actions',
    },
  ];

  const isProductSelected = (
    productName: ProductNames,
    siteDeviceSubset: SiteDeviceSubsetRow
  ) => {
    const product = siteDeviceSubset.productsForCast.find(
      (prod) => prod.productName === productName
    );
    return product && product.isSelected;
  };

  const areSlectedProductsOfTypeCompletedOrNotSelected = (
    productName: ProductNames,
    siteDeviceSubset: SiteDeviceSubsetRow
  ) =>
    siteDeviceSubset.productsForCast
      .filter((prod) => prod.productName === productName)
      .every((prod) => prod.status === COMPLETED || prod.isSelected === false);

  // check that at least one product of a given type is selcted and that type of product is completed or not selected for every cast
  const areSelectedProductsOfTypeCompletedForAllCasts = (
    productName: ProductNames
  ) =>
    siteDeviceSubsetRowsState.length > 0 &&
    siteDeviceSubsetRowsState.some((subset) =>
      isProductSelected(productName, subset)
    ) &&
    siteDeviceSubsetRowsState.every((subset) =>
      areSlectedProductsOfTypeCompletedOrNotSelected(productName, subset)
    );

  /** Displays the edit and delete icon in every row in the siteDeviceSubsetTable */
  const renderActionIcons = (siteDeviceSubset: SiteDeviceSubsetRow) => ({
    ...siteDeviceSubset,
    actions: (
      <>
        {areAllSelectedProductsGivenStatus(siteDeviceSubset, COMPLETED) ? (
          <IconButton
            aria-label="Download Data Product"
            onClick={() =>
              downloadAllSelectedCompletedProducts(siteDeviceSubset)
            }
            Icon={GetApp}
          />
        ) : (
          <IconButton
            aria-label="Send Download Request"
            onClick={() => requestAllSelectedProductsForCast(siteDeviceSubset)}
            Icon={GetApp}
          />
        )}
        <DeleteIconButton
          aria-label="Delete Cast"
          onClick={deleteIconOnClick(siteDeviceSubset.siteDeviceSubsetId)}
        />
        <IconButton
          aria-label="Remove or Add Products for Cast"
          onClick={() => openAddProductsDialog(siteDeviceSubset)}
          Icon={Add}
        />
      </>
    ),
  });

  const renderSelectedProducts = (siteDeviceSubset: SiteDeviceSubsetRow) => ({
    ...siteDeviceSubset,
    product: (
      <SelectedProductIcons
        products={filterToSelectedProducts([siteDeviceSubset])}
      />
    ),
  });

  const renderStatusForWholeCast = (siteDeviceSubset: SiteDeviceSubsetRow) => {
    if (areSelectedProductsRunning(siteDeviceSubset)) {
      const linProg = (
        <div className={classes.linearProgStyle}>
          <LinearProgress />
        </div>
      );
      return { ...siteDeviceSubset, status: linProg };
    }
    if (areAllSelectedProductsGivenStatus(siteDeviceSubset, COMPLETED)) {
      return { ...siteDeviceSubset, status: COMPLETED };
    }
    if (areSomeSelectedProductsGivenStatus(siteDeviceSubset, ERROR)) {
      if (!Environment.isUserLoggedIn()) {
        return { ...siteDeviceSubset, status: USER_NOT_LOGGED_IN };
      }
      return { ...siteDeviceSubset, status: ERROR };
    }
    if (areSomeSelectedProductsGivenStatus(siteDeviceSubset, RESTRICTED)) {
      return { ...siteDeviceSubset, status: RESTRICTED };
    }
    return { ...siteDeviceSubset, status: NOT_DOWNLOADED };
  };

  const renderAddProductsTable = () => {
    if (siteDeviceSubsetToShowAddProductsFor) {
      return (
        <SiteDeviceSubsetProductTableDialog
          products={siteDeviceSubsetToShowAddProductsFor.productsForCast}
          onCloseClick={closeAddProductsDialog}
          onDeleteClick={(productName, extension) =>
            updateProductsForCast(
              siteDeviceSubsetToShowAddProductsFor,
              productName,
              false,
              extension
            )
          }
          onDownloadClick={(productName, extension) =>
            requestDataProduct(
              siteDeviceSubsetToShowAddProductsFor,
              productName,
              extension
            )
          }
          onAddClick={(productName, extension) =>
            updateProductsForCast(
              siteDeviceSubsetToShowAddProductsFor,
              productName,
              true,
              extension
            )
          }
        />
      );
    }
    return <></>;
  };

  const renderedSiteDSRows = siteDeviceSubsetRowsState
    .map(renderActionIcons)
    .map(renderSelectedProducts)
    .map(renderStatusForWholeCast);

  const downloadOptions: string[] = [];
  downloadOptions.push(ALL_DATA_PRODUCTS);
  if (areSelectedProductsOfTypeCompletedForAllCasts(COR_AND_CSV_DATA_PRODUCT)) {
    downloadOptions.push(COR_AND_CSV_DATA_PRODUCT);
  }
  if (areSelectedProductsOfTypeCompletedForAllCasts(PLOT_DATA_PRODUCT)) {
    downloadOptions.push(PLOT_DATA_PRODUCT);
  }
  if (areSelectedProductsOfTypeCompletedForAllCasts(ANNOTATION_DATA_PRODUCT)) {
    downloadOptions.push(ANNOTATION_DATA_PRODUCT);
  }
  if (areSelectedProductsOfTypeCompletedForAllCasts(XML_DATA_PRODUCT)) {
    downloadOptions.push(XML_DATA_PRODUCT);
  }
  const { buttonGroupStyle, buttonStyle } = classes;

  return (
    <>
      <Dialog
        open
        maxWidth="md"
        fullWidth
        fullScreen={false}
        hideBackdrop
        disableEnforceFocus
        className={classes.fitContent}
        PaperProps={{
          className: classes.dialogPaper,
        }}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <DialogTitle>Select Casts to Download</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onCloseClick}
              className={classes.closeButton}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <div className={classes.tableStyle}>
            {renderSearchField ? (
              <Autocomplete
                value={undefined}
                virtualized
                getOptionLabel={(siteDeviceSubset) => {
                  if (siteDeviceSubset) {
                    return `${siteDeviceSubset.siteDeviceSubsetName} (${siteDeviceSubset.siteDeviceSubsetId})`;
                  }
                  return '';
                }}
                name="SiteDeviceSubsetListAutocomplete"
                options={siteDeviceSubsetRowsState}
                onChange={onSubsetChange}
                translationKey="communityFishers.castSelection"
              />
            ) : (
              <> </>
            )}
            <Table
              sortable
              columns={columns}
              rows={renderedSiteDSRows}
              customHeaderCellProvider={customHeaderCellProvider}
              pageSize={5}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <TextButton
            translationKey="common.buttons.close"
            onClick={onCloseClick}
          />
          <SelectButton
            initialValue={downloadOptions[0]}
            options={downloadOptions}
            onClick={downloadRequestProductForAllCasts}
            classes={{
              buttonGroupStyle,
              buttonStyle,
            }}
          />
        </DialogActions>
      </Dialog>
      {renderAddProductsTable()}
    </>
  );
};

export default SiteDeviceSubsetDownloadTableDialog;
