import { useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CancelButton,
  ContainedButton,
  TextButton,
} from '@onc/composite-components';
import { GetApp } from '@onc/icons';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dropdown,
  Popover,
  Tooltip,
  LabelledCheckbox,
  LoadingButton,
  Typography,
} from 'base-components';
import { InfoIconButton } from 'domain/AppComponents/IconButtons';
import QUALITY_OPTIONS from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistConstants';
import UserDetailsContext from 'domain/AppComponents/sea-tube/UserDetailsContext';
import DataProductDeliveryService from 'domain/services/DataProductDeliveryService';
import DataSearchService from 'domain/services/DataSearchService';
import { PlaylistLineInfo } from 'domain/services/PlaylistService';
import PlaylistVideoService from 'domain/services/PlaylistVideoService';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';

const useStyles = makeStyles((theme: Theme) => ({
  qualityContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  qualityPopoverText: {
    whiteSpace: 'pre-line',
    padding: theme.spacing(2),
  },
}));

interface DownloadClipsDialogProps {
  playlistHdrId: number;
  open: boolean;
  selectedClips: PlaylistLineInfo[];
  playlistName: string;
  entirePlaylist?: boolean;
  onCancel: () => void;
}

const CHECK_INTERVAL = 3000;

const STATUS_FINISHED = 2;
const STATUS_ERROR = 3;
const STATUS_NO_DATA = 4;

const getDurationInMinutes = (selectedClips: PlaylistLineInfo[]) => {
  const totalSeconds = selectedClips.reduce(
    (acc, curr) => acc + curr.duration,
    0
  );
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds - minutes * 60;
  return `${minutes} min ${seconds} sec`;
};

const DownloadClipsDialog: React.FC<DownloadClipsDialogProps> = ({
  playlistHdrId,
  open,
  playlistName,
  selectedClips,
  entirePlaylist = false,
  onCancel,
}) => {
  const classes = useStyles();
  const [stitchFiles, setStitchFiles] = useState(true);
  const [quality, setQuality] = useState('D');
  const [isGeneratingFile, setIsGeneratingFile] = useState(false);
  const [searchHdrId, setSearchHdrId] = useState(undefined);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [exportErrorMessage, setExportErrorMessage] = useState<string | null>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { userId } = useContext(UserDetailsContext);
  const { onInfo, onError } = useSnackbars();

  const pollingFn = useRef(null);
  const isPolling = useRef(false);

  const [, , generateSearchTaskForPlaylist] = useWebService({
    method: PlaylistVideoService.createSearchTaskForPlaylist,
  });

  const handleCheckBox = () => {
    setStitchFiles(!stitchFiles);
  };

  const startDownload = (url: string, fileName: string): void => {
    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    // Append the link to the body. This is necessary for Firefox.
    document.body.appendChild(link);
    // This will start the download automatically without navigating away from the page
    link.click();
    // Clean up: remove the link from the body. Again, this is necessary for Firefox.
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!open) {
      isPolling.current = false;
      clearTimeout(pollingFn.current);
      setSearchHdrId(undefined);
      setQuality('D');
      setExportErrorMessage(null);
      setIsGeneratingFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const checkStatus = () => {
      DataSearchService.checkSearchStatus(searchHdrId)
        .then((results) => {
          if (!isPolling.current) {
            return; // Check cancellation flag again after async operation
          }
          if (results) {
            const {
              status,
              filename: newFilename,
              fileCount,
              comment,
              searchId: newSearchId,
            } = results;
            if (status === STATUS_ERROR) {
              setExportErrorMessage(comment);
              setIsGeneratingFile(false);
              return;
            }
            if (fileCount === 0 && status === STATUS_NO_DATA) {
              onError(comment);
              setExportErrorMessage(comment);
              setIsGeneratingFile(false);
            } else if (status === STATUS_FINISHED && fileCount > 0) {
              setIsGeneratingFile(false);
              onInfo(
                `Download request for '${playlistName}' playlist complete. Downloading file...`
              );
              setCancelDialogOpen(false);
              onCancel();
              startDownload(
                `${Environment.getDmasUrl()}/SearchResultService?id=${newSearchId}`,
                newFilename
              );
            } else {
              pollingFn.current = setTimeout(checkStatus, CHECK_INTERVAL);
            }
          } else {
            pollingFn.current = setTimeout(checkStatus, CHECK_INTERVAL);
          }
        })
        .catch((error) => onError(error.message));
    };

    if (searchHdrId) {
      pollingFn.current = checkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchHdrId]);

  const runDataProductSearch = async (newSearchHdrId: number) => {
    isPolling.current = true;
    DataProductDeliveryService.runDataProductSearch(newSearchHdrId)
      .then(() => {
        onInfo(
          `Download request for '${playlistName}' playlist now in progress.`
        );
        setIsGeneratingFile(true);
        setSearchHdrId(newSearchHdrId);
      })
      .catch((error) => {
        const { message } = error;
        if (message) {
          setExportErrorMessage(message);
        }
      });
  };

  const startSelectedClipsRequest = async () => {
    if (userId === 0) {
      setExportErrorMessage('You must be logged in to download a playlist.');
      return;
    }
    const { searchHdrId: newSearchHdrId } = await generateSearchTaskForPlaylist(
      playlistHdrId,
      stitchFiles,
      quality,
      selectedClips.map((c) => c.playlistLineId).toString()
    );
    runDataProductSearch(newSearchHdrId);
  };

  const singleClip = selectedClips.length === 1;

  return (
    <>
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
      >
        <DialogTitle id="cancel-download-dialog-title">
          Cancel Download?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-download-dialog-description">
            Are you sure? This will cancel the download request.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <TextButton
            translationKey="common.buttons.no"
            onClick={() => setCancelDialogOpen(false)}
          />
          <ContainedButton
            translationKey="common.buttons.yes"
            onClick={() => {
              setCancelDialogOpen(false);
              onCancel();
            }}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={
          isGeneratingFile ? () => setCancelDialogOpen(true) : () => onCancel()
        }
      >
        <DialogTitle id="download-dialog-title">
          Download {entirePlaylist ? 'Playlist' : 'Clips'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="download-dialog-description">
            {`Are you sure you want to download ${
              entirePlaylist
                ? `the entire playlist? (${selectedClips.length} clip${
                    singleClip ? '' : 's'
                  })`
                : `the selected ${
                    singleClip ? 'clip' : `${selectedClips.length} clips`
                  }?`
            }`}
            <br />
            <br />
            <b>Total Duration:</b> {getDurationInMinutes(selectedClips)}
          </DialogContentText>
          <div className={classes.qualityContainer}>
            <Dropdown
              id="download-quality-dropdown"
              label="Quality"
              value={quality}
              options={QUALITY_OPTIONS.map((option) => ({
                key: option.key,
                label: option.description,
                value: option.code,
              }))}
              onChange={(e) => setQuality(e.target.value)}
              fullWidth
            />
            <InfoIconButton
              aria-label="Quality Info"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorEl(event.currentTarget)
              }
            />
            <Popover
              id="qualityPopover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Typography
                className={classes.qualityPopoverText}
                variant="subtitle1"
                title="qualityInfoPopover"
              >
                {`Highest: Highest resolution in the system. 
                Lowest: Lowest resolution in the system. 
                Clip Default: Resolution when the clip was created.`}
              </Typography>
            </Popover>
          </div>
          <br />
          {selectedClips.length > 1 ? (
            <Tooltip
              placement="right"
              title="Uncheck to download individual clips"
            >
              <span>
                <LabelledCheckbox
                  label="Stitch Files"
                  name="seatube-download-stitched-files"
                  id="seatube-download-stitched-files-checkbox"
                  value={stitchFiles}
                  onChange={handleCheckBox}
                />
              </span>
            </Tooltip>
          ) : undefined}
          {quality !== 'D' ? (
            <Alert severity="warning">
              Some cameras only archive one video resolution. Clips from those
              cameras will be downloaded at Default quality.
            </Alert>
          ) : null}
          {exportErrorMessage ? (
            <Alert severity="error">{exportErrorMessage}</Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <CancelButton
            onClick={
              isGeneratingFile
                ? () => setCancelDialogOpen(true)
                : () => onCancel()
            }
          />
          <LoadingButton
            translationKey={
              isGeneratingFile
                ? 'seatube.generatingFile'
                : 'common.buttons.download'
            }
            variant="contained"
            id="confirm-download-clips-button"
            onClick={startSelectedClipsRequest}
            startIcon={<GetApp />}
            loading={isGeneratingFile}
            loadingPosition="start"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DownloadClipsDialog;
