import { withStyles } from '@mui/styles';
import { TextButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Grid } from 'base-components';
import { Clause } from 'domain/Apps/data-preview-management/DataPreview';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DataPreviewClauseSelects from './DataPreviewClauseSelects';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
});

type Props = {
  clauses: Clause[];
  onAdd: () => void;
  onUpdate: (clause: Clause, index: number) => void;
  onClauseDelete: (index: number) => void;
};

const DataPreviewClauseFields = ({
  clauses,
  onAdd,
  onUpdate,
  onClauseDelete,
}: Props) => (
  <>
    <Grid container spacing={1}>
      {clauses.map((clause: Clause, index: number) => (
        <Grid item xs={12}>
          <DataPreviewClauseSelects
            onChange={onUpdate}
            handleRemove={onClauseDelete}
            index={index}
            value={clause}
            numberOfComp={clauses.length}
          />
        </Grid>
      ))}
      <TextButton
        translationKey="dataPreview.addClause"
        startIcon={<Add />}
        onClick={onAdd}
      />
    </Grid>
  </>
);

export default withStyles(styles)(withSnackbars(DataPreviewClauseFields));
