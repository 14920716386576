import { memo, useState } from 'react';
import { ContainedButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import BatchAddDialog from 'domain/AppComponents/batch/dialog/BatchAddDialog';

const BatchAddContainer = () => {
  const [openBatchDialog, setOpenBatchDialog] = useState<boolean>(false);
  return (
    <>
      <BatchAddDialog
        open={openBatchDialog}
        onClose={() => setOpenBatchDialog(false)}
      />
      <ContainedButton
        translationKey="taskmanagement.addBatch"
        startIcon={<Add />}
        onClick={() => {
          setOpenBatchDialog(true);
        }}
      />
    </>
  );
};

export default memo(BatchAddContainer);
