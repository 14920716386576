/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useContext, useEffect, useState } from 'react';

import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, createStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import _ from 'lodash';
import { ExpandMore } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Dropdown,
  type DropdownOption,
  Grid,
  Typography,
} from 'base-components';
import { ResourceTypeSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import CruiseService from 'domain/services/CruiseService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import useWebService from 'util/hooks/useWebService';
import { ManualEntryErrors } from '../sea-tube/manual-entry/ManualEntryForm';
import SeaTubeLogContext from '../sea-tube/SeaTubeLogContext';

type Props = {
  value: EntryResourceSection;
  onChange: React.Dispatch<React.SetStateAction<EntryResourceSection>>;
  onError: (message: string) => void;
  errors: ManualEntryErrors;
  classes: ClassNameMap;
  device?: {
    deviceId: number;
    deviceName: string;
  };
};

export interface EntryResourceSection {
  resourceTypeId?: number;
  resourceId?: number;
}

const commonResourceTypeOptions = [
  { label: 'Device', value: 2 },
  { label: 'Device Data', value: 1000 },
];
// This will eventually have to be dynamic depending on whether looking
// at a deck log or a dive log
const deckResourceTypeOptions = [
  ...commonResourceTypeOptions,
  { label: 'Expedition', value: 601 },
];

const diveResourceTypeOptions = [
  ...commonResourceTypeOptions,
  { label: 'Dive', value: 600 },
];

const compromisedResourceOptions = (device: Props['device']) => [
  {
    key: device.deviceId.toString(),
    label: `${device.deviceName} (${device.deviceId})`,
    value: device.deviceId,
  },
];

const STYLES = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    chip: {
      maxWidth: '350px',
      marginLeft: 'auto',
      marginRight: theme.spacing(),
    },
  });

const ManualEntryResource: React.FC<Props> = ({
  value,
  classes,
  errors,
  onChange,
  onError,
  device = undefined,
}: Props) => {
  // CruiseService (getting resource options)
  const [resourceOptions, , getResourceOptions] = useWebService({
    method: CruiseService.getResourceOptions,
    onError,
    parser: (response) => response.InstrumentList,
  });

  const { dive, cruise } = useContext(SeaTubeLogContext);

  let resourceTypeOptions = commonResourceTypeOptions;
  if (dive) {
    resourceTypeOptions = diveResourceTypeOptions;
  }
  if (cruise) {
    resourceTypeOptions = deckResourceTypeOptions;
  }
  const [resourceDropdownOptions, setResourceDropdownOptions] = useState<
    DropdownOption[]
  >([]);

  const { resourceTypeId, resourceId } = value;
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => setExpanded(!expanded);

  const handleChange = (val: any, property: keyof EntryResourceSection) => {
    const updatedValue = _.cloneDeep(value);
    updatedValue[property] = val;
    onChange(updatedValue);
  };

  // onMount
  useEffect(() => {
    if (dive && dive.cruiseId > 0) {
      getResourceOptions(dive.cruiseId, dive.diveId);
    }
    if (cruise && cruise.cruiseId > 0) {
      getResourceOptions(cruise.cruiseId);
    }
  }, []);

  useEffect(() => {
    if (errors.resourceTypeError || errors.resourceError) {
      setExpanded(true);
    }
  }, [errors]);

  // Get form data
  useEffect(() => {
    if (!resourceOptions && !device) {
      return;
    }
    if (
      resourceTypeId &&
      !resourceTypeOptions.find((option) => option.value === resourceTypeId)
    ) {
      onError('Invalid Resource Type');
      onChange({
        resourceId: null,
        resourceTypeId: null,
      });
      return;
    }
    if (
      resourceTypeId === SeaTubeResourceTypes.DEVICE ||
      resourceTypeId === SeaTubeResourceTypes.DEVICE_DATA
    ) {
      if (device) {
        setResourceDropdownOptions(compromisedResourceOptions(device));
      } else {
        setResourceDropdownOptions(
          resourceOptions.map((item) => ({
            label: `${item.instrumentName} (${item.instrumentId})`,
            value: item.instrumentId,
          }))
        );
        // If the resource already is in the list, don't change it
        if (resourceOptions.find((item) => item.instrumentId === resourceId)) {
          return;
        }
        handleChange(
          dive ? dive.defaultDeviceId : resourceOptions[0].instrumentId,
          'resourceId'
        );
      }
    }
    if (
      resourceTypeId === SeaTubeResourceTypes.EXPEDITION &&
      cruise?.cruiseId > 0
    ) {
      setResourceDropdownOptions([
        {
          key: cruise.cruiseId.toString(),
          label: cruise.cruiseName,
          value: cruise.cruiseId,
        },
      ]);
      handleChange(cruise.cruiseId, 'resourceId');
    }
    if (resourceTypeId === SeaTubeResourceTypes.DIVE && dive?.diveId > 0) {
      setResourceDropdownOptions([
        {
          key: dive.referenceDiveId,
          label: `${dive.referenceDiveId} - ${dive.area}`,
          value: dive.diveId,
        },
      ]);
      handleChange(dive.diveId, 'resourceId');
    }
  }, [resourceTypeId, resourceOptions, device]);

  const renderChip = () => {
    const resourceTypeName = resourceTypeOptions.find(
      (type) => type.value === resourceTypeId
    );
    const resourceName = resourceDropdownOptions.find(
      (resource) => resource.value === resourceId
    );
    if (resourceTypeName && !expanded) {
      return (
        <Chip
          className={classes.chip}
          variant="outlined"
          label={
            <>
              <b>{`${resourceTypeName.label}`}</b>
              {`: ${resourceName ? resourceName.label : '(none)'}`}
            </>
          }
        />
      );
    }
    return undefined;
  };

  return (
    <Accordion expanded={expanded} onChange={handleExpand}>
      <AccordionSummary
        data-test="resource-accordion"
        expandIcon={<ExpandMore data-test="expand" name="expand" />}
      >
        <div className={classes.flexContainer}>
          <Typography>Resource</Typography>
          {renderChip()}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ResourceTypeSelect
              value={resourceTypeId}
              fullWidth
              options={resourceTypeOptions || []}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e.target.value, 'resourceTypeId')
              }
              error={!!errors.resourceTypeError}
              helperText={errors.resourceTypeError}
              disabled={
                device && resourceTypeId === SeaTubeResourceTypes.DEVICE_DATA
              }
            />
          </Grid>
          <Grid item xs={8}>
            <Dropdown
              fullWidth
              name="resource-select"
              label="Resource"
              disabled={resourceDropdownOptions.length === 1}
              value={resourceId}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e.target.value, 'resourceId')
              }
              options={
                device
                  ? compromisedResourceOptions(device)
                  : resourceDropdownOptions
              }
              error={!!errors.resourceError}
              helperText={errors.resourceError}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(STYLES)(withSnackbars(ManualEntryResource));
