import { Ref, useEffect, useRef, useState, KeyboardEvent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactPlayer } from 'base-components';
import { useSnackbars } from 'util/hooks/useSnackbars';

const useStyles = makeStyles<Theme, { showControls: boolean }>(
  (theme: Theme) => ({
    oncVideoPlayer: {
      position: 'relative',
      height: '100%',
      backgroundColor: 'black',
    },
    controlBar: {
      opacity: ({ showControls }) => (showControls ? 1 : 0),
      position: 'absolute',
      bottom: 0,
      width: '100%',
      transition: 'opacity 250ms ease-in-out',
    },
    splashScreenComponent: {
      position: 'absolute',
      bottom: '50%',
      left: '50%',
      color: theme.palette.getContrastText('rgba(0,0,0,0.7)'),
      transform: 'scale(2, 2)',
    },
  })
);

export type VideoProgress = {
  /** The fraction of the video that has been played */
  played: number;
  /** The number of seconds that have been played */
  playedSeconds: number;
  /** The fraction of the video that has been loaded */
  loaded: number;
  /** The number of seconds that have been loaded */
  loadedSeconds: number;
};

type OncVideoPlayerProps = {
  /** The URL of the video to play */
  url: string;
  /** The id of the video player */
  id?: string;
  /** The video player state */
  isPlaying?: boolean;
  /** Mutes the video (only works if volume is set) */
  isMuted?: boolean;
  /** The playback rate of the video */
  playbackRate?: number;
  /** The time in seconds to seek to */
  seekTo?: number;
  /** Whether the native controls of the video player should be shown */
  showControls?: boolean;
  /** The custom controls for the video player to display */
  ControlsComponent?: JSX.Element;
  /** The play button that appears at the end of the video */
  SplashScreenComponent?: JSX.Element;
  /** The volume of the video */
  volume?: number;
  /** Ref for the container element */
  containerRef?: Ref<HTMLDivElement>;

  /** Callback when the video has ended */
  onEnded?: () => void;
  /** Callback when the video has an error */
  onError?: (e: Error) => void;
  /** Callback when the video is paused */
  onPause?: () => void;
  /** Callback when the video is playing */
  onPlay?: () => void;
  /** Callback when the video progresses */
  onProgress?: (progress: VideoProgress) => void;
  /** Callback when the video is ready to play */
  onReady?: () => void;
  /** Callback when the video is seeking */
  onSeek?: (time: number) => void;
  /** Callback when the video metadata is loaded */
  onMetadata?: (metadata: { height: number; width: number }) => void;
  /** Callback when a track is added to the video */
  onTracksChanged?: (tracks: TextTrack[]) => void;
};

const OncVideoPlayer: React.FC<OncVideoPlayerProps> = ({
  url,
  id = 'onc-video-player',
  isPlaying = undefined,
  isMuted = undefined,
  showControls = true,
  ControlsComponent = undefined,
  SplashScreenComponent = undefined,
  playbackRate = undefined,
  seekTo = undefined,
  volume = undefined,
  containerRef = null,
  onEnded = undefined,
  onError = undefined,
  onPause = undefined,
  onPlay = undefined,
  onProgress = undefined,
  onReady = undefined,
  onSeek = undefined,
  onMetadata = undefined,
  onTracksChanged = undefined,
}: OncVideoPlayerProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tracks, setTracks] = useState<TextTrack[]>([]);
  const classes = useStyles({ showControls: isHovered || !isPlaying });
  const playerRef = useRef(null);

  const { onError: errorSnackbar } = useSnackbars();

  useEffect(() => {
    if (onTracksChanged) {
      onTracksChanged(tracks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracks]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const fetchVideoResolution = () => {
    if (playerRef.current) {
      const videoElement = playerRef.current.getInternalPlayer();
      if (videoElement) {
        onMetadata?.({
          height: videoElement.videoHeight,
          width: videoElement.videoWidth,
        });
      }
    }
  };

  const handleAddTrack = (e: TrackEvent) => {
    const { track } = e;
    if (track.kind === 'subtitles' || track.kind === 'captions') {
      setTracks((prevTracks) => {
        const trackExists = prevTracks.some((t) => t.label === track.label);
        return trackExists
          ? prevTracks.map((t) => (t.label === track.label ? track : t))
          : [...prevTracks, track];
      });
    }
  };

  const handleRemoveTrack = (e: TrackEvent) => {
    const { track } = e;
    if (track.kind === 'subtitles' || track.kind === 'captions') {
      setTracks((prevTracks) => prevTracks.filter((t) => t.id !== track.id));
    }
  };

  const handleReady = () => {
    if (playerRef.current) {
      const videoElement = playerRef.current.getInternalPlayer();
      if (videoElement) {
        /**
         * Can't seem to guarentee this will add the listener before the event
         * is fired, so call fetchVideoResolution directly as well. The video
         * player in Cypress seems to fire the loadedmetadata event quicker than
         * it's added here
         */
        fetchVideoResolution();
        videoElement.addEventListener('loadedmetadata', fetchVideoResolution);
        videoElement.textTracks.addEventListener('addtrack', handleAddTrack);
        videoElement.textTracks.addEventListener(
          'removetrack',
          handleRemoveTrack
        );
      }
    }
    onReady?.();
  };

  const isPresentationLayer = (target: HTMLElement) =>
    // Fix for DMAS-81559
    // Check if the target element or its parent has role="presentation"
    target.closest('[role="presentation"]');

  const handleVideoClick = (e) => {
    const target = e.target as HTMLElement;
    if (isPresentationLayer(target)) {
      return; // Let elements in the presentation layer handle their own events
    }
    e.preventDefault();
    isPlaying ? onPause?.() : onPlay?.();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleVideoClick(e);
    }
  };

  const handleError = (error, errorDetails) => {
    // Don't care about AbortErrors (These happen when the video is changed before it's loaded
    // or non-fatal errors (like pausing too long before playing again)
    if (
      error.name === 'AbortError' ||
      (errorDetails && errorDetails.fatal === false)
    )
      return;
    onError ? onError(error) : errorSnackbar('Error loading video');
  };

  useEffect(() => {
    if (playerRef.current && seekTo !== null) {
      // Note I think JW Player would use player.seek(seekTo) instead of player.seekTo(seekTo)
      playerRef.current.seekTo(seekTo);
    }
  }, [seekTo]);

  const renderCustomControls = () =>
    ControlsComponent && SplashScreenComponent && showControls === false ? (
      <>
        <div className={classes.splashScreenComponent}>
          {SplashScreenComponent}
        </div>
        <div className={classes.controlBar}>{ControlsComponent}</div>
      </>
    ) : undefined;

  return (
    <div
      ref={containerRef}
      className={classes.oncVideoPlayer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleVideoClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-label="Video player"
    >
      <ReactPlayer
        id={id}
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        playing={isPlaying}
        muted={isMuted}
        controls={showControls}
        playbackRate={playbackRate}
        volume={volume}
        onEnded={onEnded}
        onError={handleError}
        onPause={onPause}
        onPlay={onPlay}
        onProgress={onProgress}
        onReady={handleReady}
        onSeek={onSeek}
      />

      {renderCustomControls()}
    </div>
  );
};

export default OncVideoPlayer;
