import { useContext, useEffect, useState } from 'react';
import { TextButton } from '@onc/composite-components';
import { FiberManualRecord, OpenInNew } from '@onc/icons';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LabelledCheckbox,
  Tooltip,
} from 'base-components';
import { LayoutApplication } from 'domain/AppComponents/Dashboard/LayoutService';
import { InfoIconButton } from 'domain/AppComponents/IconButtons';
import { NOAA_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import SeaTubeDashboard from 'domain/AppComponents/sea-tube/SeaTubeDashboard';
import QueryParameterContext from 'domain/Apps/menu/QueryParameterContext';
import DiveDetails from 'domain/Apps/seatube/details/DiveDetails';
import AnnotationListWidget from 'domain/Widgets/AnnotationListWidget';
import ButtonSetWidget from 'domain/Widgets/ButtonSetWidget';
import ChatLogWidget from 'domain/Widgets/ChatLogWidget';
import DiveVideoWidget from 'domain/Widgets/DiveVideoWidget';
import ManualEntryWidget from 'domain/Widgets/ManualEntryWidget';
import MapWidget from 'domain/Widgets/MapWidget';
import SensorReadingsWidget from 'domain/Widgets/SensorReadingsWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import { WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWindowController from 'library/CompositeComponents/dashboard/useWindowController';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import annotationLayout from './layouts/annotationLayout';
import defaultAnonymousLayout from './layouts/defaultAnonymousLayout';
import defaultUserLayout from './layouts/defaultUserLayout';
import onShipLayout from './layouts/onShipLayout';
import videocentricLayout from './layouts/videocentricLayout';
import SeaTubeLogContext from '../SeaTubeLogContext';
import SeaTubePermissionContext from '../SeaTubePermissionContext';

type Props = {
  primaryWindowId: string;
};

const emptyLayout = {
  xl: [],
  lg: [],
  md: [],
  sm: [],
  xs: [],
};
const widgetOptions: WidgetOption[] = [
  {
    Component: ManualEntryWidget,
    label: 'Annotation Entry',
    multiple: false,
  },
  {
    Component: AnnotationListWidget,
    label: 'Annotation List',
    multiple: false,
  },

  {
    Component: DiveVideoWidget,
    label: 'Dive Video',
    multiple: false,
  },

  {
    Component: MapWidget,
    label: 'Map',
    multiple: false,
  },
  {
    Component: ButtonSetWidget,
    label: 'Quick Button Set',
    multiple: true,
  },
  {
    Component: SensorReadingsWidget,
    label: 'Sensor Readings',
    multiple: false,
  },
  {
    Component: ChatLogWidget,
    label: 'Chat Log',
    multiple: false,
  },
];

const anonymousWidgetOptions: WidgetOption[] = [
  {
    Component: AnnotationListWidget,
    label: 'Annotation List',
    multiple: false,
  },
  {
    Component: DiveVideoWidget,
    label: 'Dive Video',
    multiple: false,
  },
  {
    Component: MapWidget,
    label: 'Map',
    multiple: false,
  },
  {
    Component: SensorReadingsWidget,
    label: 'Sensor Readings',
    multiple: false,
  },
];

const anonymousLayouts = [
  {
    label: 'Default',
    layout: defaultAnonymousLayout,
  },
];

const userLayouts = [
  {
    label: 'Default',
    layout: defaultUserLayout,
  },
  {
    label: 'Annotation',
    layout: annotationLayout,
  },
  {
    label: 'On Ship',
    layout: onShipLayout,
  },
  {
    label: 'Videocentric',
    layout: videocentricLayout,
  },
];

const DiveLogDashboard: React.FC<Props> = ({ primaryWindowId }: Props) => {
  const { dive } = useContext(SeaTubeLogContext);
  const { cruiseName, diveId, isDiveLive, referenceDiveId, organizationId } =
    dive;
  const { canCreate } = useContext(SeaTubePermissionContext);
  const { isLiveMode } = useContext(QueryParameterContext);
  const windowController = useWindowController(primaryWindowId);
  const { windowIndex } = windowController;
  const [widgetLibraryOptions, setWidgetLibraryOptions] = useState<
    WidgetOption[]
  >(anonymousWidgetOptions);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [showExtraDiveInfo, setShowExtraDiveInfo] = useState(false);

  const { onError } = useSnackbars();

  useEffect(() => {
    // Only display the Chat Log on NOAA dives where users have permissions
    if (canCreate) {
      setWidgetLibraryOptions(widgetOptions);
    }
    if (organizationId !== NOAA_DATA.organizationId) {
      setWidgetLibraryOptions(
        widgetOptions.filter((widget) => widget.label !== 'Chat Log')
      );
    }
  }, [canCreate, organizationId]);

  const getWidgetOptions = () =>
    new WidgetLibrary(
      canCreate ? widgetLibraryOptions : anonymousWidgetOptions
    );

  const userType = canCreate ? 'User' : 'Anonymous';

  const getDefaultLayout = () => {
    if (windowIndex === 1) {
      return canCreate ? defaultUserLayout : defaultAnonymousLayout;
    }
    return emptyLayout;
  };

  const handleOpenLiveMode = () =>
    window.open(
      `${Environment.getLinkUrl()}/app/dive-logs/${diveId}/live`,
      '_blank'
    );

  const handleClose = () => {
    setOpenDetailsDialog(false);
  };

  const toggleShowMoreInfo = () => {
    setShowExtraDiveInfo(!showExtraDiveInfo);
  };

  const renderLiveDiveChip = () => {
    let LiveDiveChip;
    let tooltipTitle;
    if (isLiveMode) {
      tooltipTitle = 'Live data and video';
      LiveDiveChip = (
        <Chip
          aria-label="live"
          label="Live"
          variant="filled"
          color="primary"
          icon={<FiberManualRecord color="error" />}
        />
      );
    } else {
      tooltipTitle = 'Open Live Mode';
      LiveDiveChip = (
        <Chip
          aria-label="dive in progress"
          variant="filled"
          label="Dive In Progress"
          color="secondary"
          clickable
          onClick={handleOpenLiveMode}
          // onDelete needed to have icon at end of chip
          onDelete={() => {}}
          deleteIcon={<OpenInNew />}
        />
      );
    }
    return (
      <Tooltip title={tooltipTitle}>
        <span>{LiveDiveChip}</span>
      </Tooltip>
    );
  };

  let layoutKey = `${userType}-Dive-Log`;
  if (isLiveMode) {
    layoutKey = layoutKey.concat('-Live');
  }

  return !windowController.loading ? (
    <>
      <SeaTubeDashboard
        title={`${cruiseName} - ${referenceDiveId}`}
        appName="dive-log"
        id={`${userType}-Dive-Log-${diveId}`}
        layoutKey={layoutKey}
        applicationId={LayoutApplication.DIVE_LOG}
        actionComponents={[
          <InfoIconButton
            color="inherit"
            onClick={() => setOpenDetailsDialog(true)}
          />,
        ]}
        widgetLibrary={getWidgetOptions()}
        windowController={windowController}
        defaultLayout={getDefaultLayout()}
        titleComponents={isDiveLive ? renderLiveDiveChip() : undefined}
        predefinedLayouts={canCreate ? userLayouts : anonymousLayouts}
      />
      <Dialog
        open={openDetailsDialog}
        fullWidth
        onClose={() => setOpenDetailsDialog(false)}
      >
        <DialogTitle>
          Details
          {canCreate ? (
            <LabelledCheckbox
              sx={{ position: 'absolute', right: 4 }}
              label="Show More Info"
              value={showExtraDiveInfo}
              onClick={toggleShowMoreInfo}
            />
          ) : (
            <></>
          )}
        </DialogTitle>
        <DialogContent>
          <DiveDetails
            showExtraDiveInfo={showExtraDiveInfo && canCreate}
            diveId={diveId}
            onError={onError}
          />
        </DialogContent>
        <DialogActions>
          <TextButton
            onClick={handleClose}
            translationKey="common.buttons.close"
          />
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <></>
  );
};
export default DiveLogDashboard;
