import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import { TextButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Grid,
  Paper,
  Typography,
} from 'base-components';
import {
  EditIconButton,
  DeleteIconButton,
} from 'domain/AppComponents/IconButtons';
import DataSourceSelector from './DataSourceSelector';

const STYLES = (theme) => ({
  addDataSourceButton: {
    marginLeft: 'auto',
  },
  dataSourcePaper: {
    minHeight: theme.spacing(8),
  },
  listSubheader: {
    lineHeight: 1.5,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
});
class DataSourceList extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    treeTypePreset: PropTypes.string,
    propertyPreset: PropTypes.arrayOf(PropTypes.string),
    dataSources: PropTypes.arrayOf(PropTypes.shape({})),
    dataSourceCallback: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    disableDateRange: PropTypes.bool,
  };

  static defaultProps = {
    treeTypePreset: undefined,
    propertyPreset: [],
    dataSources: [],
    multiple: false,
    disableDateRange: false,
  };

  state = {
    dataSources: [],
    showDialog: false,
  };

  openDialog = () => {
    this.setState({
      showDialog: true,
    });
  };

  onEditSource = (source) => {
    this.openDialog();
    this.setState({
      editData: source,
    });
  };

  onCloseDialog = () => {
    this.setState({
      showDialog: false,
    });
  };

  onAddSources = (sources) => {
    const { dataSourceCallback, dataSources } = this.props;
    const { editData } = this.state;
    let newSources = dataSources.concat(sources);
    if (editData) {
      const index = dataSources.findIndex(
        (item) => item.nodeId === editData.nodeId
      );
      newSources.splice(index, 1);
      this.setState({ editData: undefined });
    }
    newSources = newSources.filter(
      (obj, pos, arr) => arr.map((source) => source.id).indexOf(obj.id) === pos
    );
    dataSourceCallback(newSources);
    this.onCloseDialog();
    return newSources;
  };

  onDataSourceRemove = (source) => {
    const { dataSourceCallback, dataSources } = this.props;
    const newArray = [...dataSources];
    const index = dataSources.findIndex(
      (item) => item.nodeId === source.nodeId
    );
    newArray.splice(index, 1);
    dataSourceCallback(newArray);
  };

  renderDialog = () => {
    const { treeTypePreset, propertyPreset, multiple, disableDateRange } =
      this.props;
    const { showDialog, editData } = this.state;
    return (
      <DataSourceSelector
        onCloseDialog={this.onCloseDialog}
        showDialog={showDialog}
        treeTypePreset={treeTypePreset}
        propertyPreset={propertyPreset}
        editData={editData}
        onSelectNodes={this.onAddSources}
        title="Select Data Source"
        multiple={multiple}
        disableDateRange={disableDateRange}
      />
    );
  };

  renderList = () => {
    const { dataSources, treeTypePreset } = this.props;
    const sections = [];
    dataSources.forEach((source) => {
      let itemSection = [...source.pathName];
      itemSection.pop();
      if (treeTypePreset === 'propertiesByLocation') {
        itemSection.pop(); // Pop the site device if using sensors
      }
      itemSection = itemSection.join(' / ');
      if (!sections.some((section) => itemSection === section.pathName)) {
        sections.push({ pathName: itemSection, items: [] });
      }
      const section = sections.find((item) => item.pathName === itemSection);
      section.items.push(source);
    });
    return this.renderListSections(sections);
  };

  renderListSections = (sections) => {
    const { classes } = this.props;
    return sections.map((section, index) => (
      <div key={section.pathName}>
        <ListSubheader key={section.pathName} className={classes.listSubheader}>
          {section.pathName}
        </ListSubheader>
        {this.renderListItems(section.items)}
        {index < sections.length - 1 ? (
          <Divider className={classes.divider} />
        ) : undefined}
      </div>
    ));
  };

  renderListItems = (items) =>
    items.map((item) => (
      <ListItem key={item.nodeId}>
        <ListItemText primary={item.name} />
        <ListItemIcon>
          <EditIconButton onClick={() => this.onEditSource(item)} />
          <DeleteIconButton onClick={() => this.onDataSourceRemove(item)} />
        </ListItemIcon>
      </ListItem>
    ));

  renderAddSourceButton = () => {
    const { classes, dataSources, multiple } = this.props;
    if (!multiple && dataSources.length > 0) {
      return null;
    }
    return (
      <Grid item className={classes.addDataSourceButton}>
        <TextButton
          translationKey="dashboards.addDataSource"
          startIcon={<Add />}
          onClick={() => this.openDialog()}
        />
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1} alignItems="center">
        {this.renderDialog()}
        <Grid item>
          <Typography variant="subtitle1">Data Sources</Typography>
        </Grid>
        {this.renderAddSourceButton()}

        <Grid item xs={12}>
          <Paper className={classes.dataSourcePaper}>
            <List>{this.renderList()}</List>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(STYLES)(DataSourceList);
