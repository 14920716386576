/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  ColorPickerField,
  SaveButton,
  CancelButton,
  TextButton,
} from '@onc/composite-components';
import { Add, Delete } from '@onc/icons';
import { Grid, Typography } from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import {
  QuickButtonTypeSelect,
  TaxonomySelect,
  AttributeValueSelect,
  AttributeSelect,
  GroupSelect,
} from 'domain/AppComponents/dropdowns/Dropdowns';
import TaxonAsyncAutocomplete from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import {
  ColourField,
  ButtonTypeField,
  TaxonomyField,
  TaxonField,
  MarginedReadOnlyField,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import TaxonService from '../../services/TaxonService';

const IMPORTED_TAXONOMIES = [1, 3]; // WoRMS and CMECS

const styles = (theme) => ({
  configHeader: {
    width: '50%',
    float: 'left',
  },
  addAttributeButton: {
    float: 'right',
  },
  deleteButton: {
    float: 'right',
    marginTop: '0px',
  },
  flexContainer: {
    display: 'flex',
  },
  flexItem: {
    flexGrow: 1,
    width: '100%',
    marginRight: theme.spacing(2),
  },
  flexItemWide: {
    flexGrow: 2,
    width: '100%',
    marginRight: theme.spacing(2),
  },
  attributeDeleteButton: {
    marginTop: theme.spacing(2),
    float: 'left',
  },
  buttonPairContainer: {
    textAlign: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

const TaxonButtonConfig = ({
  onError,
  currentButton = undefined,
  setButtonType,
  buttonType,
  attributeList,
  taxonomyList,
  updateButtonData,
  indexExists,
  currentButtonIndex = undefined,
  handleDelete,
  handleCancel,
  hasPermission,
  groupList,
  classes,
}) => {
  TaxonButtonConfig.propTypes = {
    onError: PropTypes.func.isRequired,
    setButtonType: PropTypes.func.isRequired,
    updateButtonData: PropTypes.func.isRequired,
    currentButton: PropTypes.shape({
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          attributeId: PropTypes.number,
        })
      ),
      colour: PropTypes.string,
      comment: PropTypes.string,
      label: PropTypes.string,
      sequenceNumber: PropTypes.number,
      taxonButtonSetLineId: PropTypes.number,
      taxonId: PropTypes.number,
      taxonLabel: PropTypes.string,
      taxonName: PropTypes.string,
      taxonomyId: PropTypes.number,
    }),
    classes: PropTypes.shape({
      addAttributeButton: PropTypes.string,
      attributeDeleteButton: PropTypes.string,
      buttonField: PropTypes.string,
      buttonPairContainer: PropTypes.string,
      configHeader: PropTypes.string,
      deleteButton: PropTypes.string,
      flexContainer: PropTypes.string,
      flexItem: PropTypes.string,
      flexItemWide: PropTypes.string,
    }).isRequired,
    buttonType: PropTypes.string.isRequired,
    attributeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    taxonomyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    groupList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    currentButtonIndex: PropTypes.number,
    indexExists: PropTypes.func.isRequired,
    hasPermission: PropTypes.bool.isRequired,
  };

  const [userDefined, setUserDefined] = useState(false);
  const [indexInput, setIndexInput] = useState('');
  const [indexError, setIndexError] = useState(false);
  const [deleteButtonDialogOpen, setDeleteButtonDialogOpen] = useState(false);
  const [deleteAttributeDialogOpen, setDeleteAttributeDialogOpen] =
    useState(false);

  useEffect(() => {
    if (currentButton) {
      setIndexInput(currentButton.sequenceNumber);
      if (currentButton.taxonomyId) {
        setUserDefined(!IMPORTED_TAXONOMIES.includes(currentButton.taxonomyId));
      }
      if (currentButton.taxonId && !currentButton.taxonLabel) {
        TaxonService.getTaxon(currentButton.taxonomyId, currentButton.taxonId)
          .then((taxonData) => {
            let label = taxonData.commonName;
            if (taxonData.parentName) {
              label = `${taxonData.parentName} / ${taxonData.commonName}`;
            }
            updateButtonData({ taxonLabel: label }, false);
          })
          .catch((error) => {
            onError(error);
          });
      }
    }
  }, [currentButton]);

  const handleButtonTypeChange = (event) => {
    const { value } = event.target;
    setButtonType(value);
    if (value === 'Taxonomy') {
      updateButtonData({ attributeToTaxonomy: true }, false);
    } else {
      updateButtonData({ taxonomyToAttribute: true }, false);
    }
  };

  const handleAutoCompleteOnChange = (e, taxon) => {
    if (taxon) {
      updateButtonData(
        {
          taxonId: taxon.taxonId,
          taxonName: taxon.commonName,
          taxonLabel: taxon.commonName,
        },
        false
      );
    } else {
      updateButtonData(
        {
          taxonId: null,
          taxonName: null,
          taxonLabel: null,
        },
        false
      );
    }
  };

  const handleIndexUpdate = () => {
    if (!indexExists(indexInput) || indexInput === currentButtonIndex) {
      setIndexError(false);
      updateButtonData({ sequenceNumber: indexInput }, false);
    } else {
      setIndexError(true);
    }
  };

  const findAttribute = (id) =>
    attributeList.find((attribute) => attribute.attributeId === id);

  const handleAddAttribute = () => {
    updateButtonData({ newAttribute: true }, false);
  };

  const handleSave = () => {
    updateButtonData({}, true);
  };

  const renderAttributeOptions = () => {
    const { attributes } = currentButton;

    if (!attributes) return null;

    if (!hasPermission) {
      return (
        <>
          {attributes.map((attr, index) => {
            let value = attr.attributeValue;
            const attribute = attributeList.find(
              (item) => item.attributeId === attr.taxonomyAttributeId
            );
            if (!value && attr.attributeValues) {
              const selectValue = attr.attributeValues.find(
                (item) => item.value === attr.taxonomyAttributeLineId
              );
              if (selectValue) {
                value = selectValue.label;
              }
            }
            return (
              <div
                key={attr.taxonomyAttributeId || `empty-attribute-${index}`}
                className={classes.flexContainer}
              >
                <MarginedReadOnlyField
                  className={classes.flexItem}
                  valueText={value || '(none)'}
                  labelText={attribute ? attribute.name : ''}
                />
              </div>
            );
          })}
        </>
      );
    }

    return (
      <>
        {attributes.map((attribute, index) => (
          <div
            key={attribute.taxonomyAttributeId || `empty-attribute-${index}`}
            className={classes.flexContainer}
          >
            <Grid container spacing={1} className={classes.flexItem}>
              <Grid item xs={3}>
                <GroupSelect
                  fullWidth
                  value={
                    attribute.attributeGroupId ||
                    (attribute.taxonomyAttributeId &&
                      findAttribute(attribute.taxonomyAttributeId).groupId)
                  }
                  options={groupList.map((item) => ({
                    label: item.name,
                    value: item.groupId,
                  }))}
                  onChange={(event) =>
                    updateButtonData(
                      {
                        index,
                        attributeGroupId: event.target.value,
                        groupChange: true,
                      },
                      false
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <AttributeSelect
                  fullwidth
                  className={classes.flexItem}
                  value={attribute.taxonomyAttributeId}
                  onChange={(event) =>
                    updateButtonData(
                      {
                        index,
                        taxonomyAttributeId: event.target.value,
                        dataType: findAttribute(event.target.value).dataType,
                      },
                      false
                    )
                  }
                  options={attributeList
                    .map((option) => ({
                      label: option.name,
                      value: option.attributeId,
                      groupId: option.groupId,
                    }))
                    .filter((item) => filterAttributes(item, attribute))}
                />
              </Grid>
              <Grid item xs={6}>
                {attribute.taxonomyAttributeId
                  ? renderAttributeField(
                      attribute,
                      index,
                      attributes.length > 1 && hasPermission
                    )
                  : renderDisabledField(index)}
              </Grid>
            </Grid>
            {renderDeleteAttributeButton(
              attribute,
              index,
              attributes.length > 1 && hasPermission
            )}
          </div>
        ))}
        {renderAddAttributeButton()}
      </>
    );
  };

  const filterAttributes = (attributeListItem, attribute) => {
    let groupId = attribute.attributeGroupId || -1;
    const attributeFromList = findAttribute(attribute.taxonomyAttributeId);
    if (attributeFromList) {
      // eslint-disable-next-line prefer-destructuring
      groupId = attributeFromList.groupId;
    }
    if (attributeListItem.groupId === groupId) {
      return true;
    }
    if (attribute.attributeGroupId === -1) {
      return true;
    }
    return false;
  };

  const renderDeleteAttributeButton = (attribute, index, isDeletable) => {
    let i;

    if (deleteAttributeDialogOpen) {
      i = deleteAttributeDialogOpen.index;
    }
    if (isDeletable) {
      return (
        <>
          <DeleteIconButton
            onClick={() => setDeleteAttributeDialogOpen({ attribute, index })}
            className={classes.attributeDeleteButton}
          />
          <DeleteDialog
            open={deleteAttributeDialogOpen && Number.isInteger(i) && i >= 0}
            onDelete={handleAttributeDelete}
            onCancel={() => setDeleteAttributeDialogOpen(false)}
            title="Delete Attribute?"
            message="Are you sure that you want to delete the current attribute? It will be
            removed immediately."
          />
        </>
      );
    }
    return undefined;
  };

  const renderAddAttributeButton = () => (
    <TextButton
      translationKey="common.buttons.addAttribute"
      startIcon={<Add />}
      className={classes.addAttributeButton}
      onClick={handleAddAttribute}
    />
  );

  const renderAttributeField = (attribute, index) => {
    let attributeField = <></>;
    switch (attribute.dataType) {
      case 'Select':
        attributeField = (
          <AttributeValueSelect
            options={
              findAttribute(attribute.taxonomyAttributeId).attributeValues || []
            }
            value={attribute.taxonomyAttributeLineId}
            className={classes.flexItemWide}
            onChange={(event) =>
              updateButtonData(
                {
                  index,
                  taxonomyAttributeLineId: event.target.value,
                },
                false
              )
            }
          />
        );
        break;
      case 'String':
        attributeField = (
          <RORWTextField
            name="attributeValue"
            translationKey="device.attributeValue"
            permission={hasPermission ? 'RW' : 'RO'}
            className={classes.flexItemWide}
            value={attribute.attributeValue}
            onChange={(event) =>
              updateButtonData({
                index,
                attributeValue: event.target.value,
              })
            }
          />
        );
        break;
      default:
        break;
    }

    return <>{attributeField}</>;
  };

  const handleAttributeDelete = () => {
    const { attribute, index } = deleteAttributeDialogOpen;
    handleDelete(
      `${attribute.modifyDate ? 'Existing' : 'New'} Attribute${index}`,
      attribute.taxonButtonSetLineId
    );
    setDeleteAttributeDialogOpen(false);
    updateButtonData({ index, deleteAttribute: true }, false);
  };

  const toggleDeleteButtonConfirmationDialog = () =>
    setDeleteButtonDialogOpen(!deleteButtonDialogOpen);

  const handleDeleteButtonConfirm = () => {
    handleDelete('Button', undefined);
    toggleDeleteButtonConfirmationDialog();
  };

  const isDeleteButtonVisible = () => {
    if (!hasPermission || !currentButton) {
      return false;
    }

    if (buttonType === 'Taxonomy' && currentButton.taxonButtonSetLineId) {
      return true;
    }

    if (
      buttonType === 'Attribute' &&
      currentButton.attributes &&
      currentButton.attributes.length > 0 &&
      currentButton.attributes[0].taxonButtonSetLineId
    ) {
      return true;
    }

    return false;
  };

  const renderDisabledField = () => (
    <>
      <RORWTextField
        name="attributeValue"
        translationKey="device.attributeValue"
        permission={hasPermission ? 'RW' : 'RO'}
        className={classes.flexItem}
        onChange={() => {}}
        disabled
      />
    </>
  );

  const getCommentValue = () => {
    if (currentButton.comment) {
      return currentButton.comment;
    }
    if (!hasPermission) {
      return '(none)';
    }
    return '';
  };

  const renderTaxonomyOptions = () => (
    <>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        {renderTaxonomyField()}
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        {renderTaxonField()}
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <RORWTextField
          name="comment"
          translationKey="common.textfields.comment"
          permission={hasPermission ? 'RW' : 'RO'}
          value={getCommentValue()}
          disabled={isButtonEmpty()}
          onChange={(event) => {
            updateButtonData({ comment: event.target.value });
          }}
          className={classes.flexItem}
          multiline
          rows={3}
        />
      </Grid>
    </>
  );

  /*
   * There doesn't seem to be an event for closing the color picker dialog so using
   * a timeout function to prevent too many service calls
   */
  const handleColorChange = (colour) => {
    updateButtonData({ colour });
  };

  const renderColorField = () => {
    if (hasPermission) {
      return (
        <ColorPickerField
          className={classes.flexItem}
          name="colour"
          disableAlpha
          value={currentButton.colour}
          onCloseDialog={() => {
            updateButtonData({}, false);
          }}
          onColorChange={(colour) => handleColorChange(colour.hex)}
          onInputChange={(value) => handleColorChange(value)}
        />
      );
    }
    return (
      <ColourField
        className={classes.flexItem}
        valueText={currentButton.colour}
      />
    );
  };

  const isButtonEmpty = () =>
    buttonType === 'Taxonomy'
      ? currentButton.label === ''
      : (!currentButton.taxonButtonSetLineId && !currentButton.attributes) ||
        currentButton.label === '';

  const isSaveDisabled = () => {
    const { attributes, comment, label, taxonId, taxonomyId } = currentButton;

    if (label === '') {
      return true;
    }

    if (buttonType === 'Taxonomy') {
      // taxonomy selected, but no taxon selected
      if (taxonomyId && !taxonId) {
        return true;
      }

      // nothing filled in at all
      if (!taxonomyId && !taxonId && !comment) {
        return true;
      }
    }

    // at least one of the attributes is undefined, or there are no attributes
    if (
      buttonType === 'Attribute' &&
      Array.isArray(attributes) &&
      (attributes.length === 0 ||
        !attributes.every((attr) => attr.taxonomyAttributeId))
    ) {
      return true;
    }

    return false;
  };

  const renderButtonTypeField = () => {
    if (hasPermission) {
      return (
        <QuickButtonTypeSelect
          className={classes.flexItem}
          value={buttonType}
          onChange={handleButtonTypeChange}
        />
      );
    }
    return <ButtonTypeField valueText={buttonType} />;
  };

  const renderTaxonField = () => {
    if (hasPermission) {
      return (
        <TaxonAsyncAutocomplete
          taxonomyId={currentButton.taxonomyId}
          onChange={handleAutoCompleteOnChange}
          taxonId={currentButton.taxonId}
          searchLettersRequired={userDefined ? 1 : 3}
        />
      );
    }
    return <TaxonField valueText={currentButton.taxonName} />;
  };

  const renderTaxonomyField = () => {
    if (hasPermission) {
      return (
        <TaxonomySelect
          className={classes.buttonField}
          value={currentButton.taxonomyId}
          fullWidth
          onChange={(event) => {
            updateButtonData(
              {
                taxonomyId: event.target.value,
                taxonId: null,
                taxonName: null,
                taxonLabel: null,
              },
              false
            );
          }}
          options={taxonomyList.map((option) => ({
            label: option.taxonomyName,
            name: option.taxonomyName,
            value: option.taxonomyId,
            key: option.taxonomyId,
          }))}
        />
      );
    }
    const taxonomy = taxonomyList.find(
      (item) => item.taxonomyId === currentButton.taxonomyId
    );
    return <TaxonomyField valueText={taxonomy ? taxonomy.taxonomyName : ''} />;
  };

  const renderForm = () => {
    if (!currentButton) {
      return <Typography color="error">No button selected</Typography>;
    }
    return (
      <>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <RORWTextField
            name="label"
            translationKey="taxonomy.label"
            className={classes.flexItem}
            value={currentButton.label}
            permission={hasPermission ? 'RW' : 'RO'}
            onChange={(event) =>
              updateButtonData({ label: event.target.value }, false)
            }
            inputProps={{ maxLength: 15 }}
            error={!currentButton.label}
            helperText={
              currentButton.label
                ? 'Enter 15 characters or less'
                : 'Label cannot be empty'
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <RORWTextField
            name="index"
            translationKey="taxonomy.index"
            type="number"
            className={classes.flexItem}
            value={indexInput}
            permission={hasPermission ? 'RW' : 'RO'}
            error={indexError}
            helperText={indexError ? 'Index already exists' : undefined}
            onChange={(e) => setIndexInput(Number(e.target.value))}
            onBlur={handleIndexUpdate}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          {renderColorField()}
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          {renderButtonTypeField()}
        </Grid>
        {buttonType === 'Attribute'
          ? renderAttributeOptions()
          : renderTaxonomyOptions()}
        {hasPermission ? (
          <div className={classes.buttonPairContainer}>
            <CancelButton onClick={handleCancel} />
            <SaveButton onClick={handleSave} disabled={isSaveDisabled()} />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.configHeader} variant="h6">
          Button Configuration
        </Typography>
        {isDeleteButtonVisible() ? (
          <>
            <DeleteDialog
              open={deleteButtonDialogOpen}
              onDelete={handleDeleteButtonConfirm}
              onCancel={toggleDeleteButtonConfirmationDialog}
              title="Delete Button?"
              message="Are you sure that you want to delete the current button?"
            />
            <TextButton
              translationKey="taxonomy.deleteButton"
              startIcon={<Delete />}
              disabled={isButtonEmpty()}
              onClick={toggleDeleteButtonConfirmationDialog}
              className={classes.deleteButton}
            />
          </>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        {renderForm()}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withSnackbars(TaxonButtonConfig));
