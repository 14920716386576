import React, { useMemo, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { Controller, useFormContext, useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import {
  Autocomplete,
  Collapse,
  Grid,
  ToggleButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from 'base-components';
import DataAvailabilityChartV2 from 'domain/AppComponents/charts/DataAvailabilityChartV2';
import { type Location } from 'domain/AppComponents/Dashboard/DataSourceTypes.types';
import DataSourceList from 'domain/AppComponents/data-source/DataSourceList';
import DataPlayerCategorySelect from 'domain/AppComponents/dropdowns/DataPlayerCategorySelect';
import {
  TimeRangeRadioGroup,
  TimeRangeRadioGroupWithWeek,
} from 'domain/AppComponents/Form/Fields/RadioGroups';
import DataPlayerHelper from 'domain/Apps/data-player/util/DataPlayerHelper';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import FormToggleButtonGroup from 'library/CompositeComponents/form/FormToggleButtonGroup';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useDataPlayerDevices from './util/useDataPlayerDevices';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },
    form: {
      padding: theme.spacing(),
    },
    formButtons: {
      marginTop: 10,
      float: 'right',
    },
    grow: {
      flexGrow: 1,
    },
    flex: {
      display: 'flex',
    },
    typography: {
      color: 'rgba(0, 0, 0, 0.54)',
      padding: 0,
      fontSize: '0.85rem',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: '0.00938em',
    },
  })
);

type DataPlayerFormProps = {
  config: {
    device: {
      deviceCategory: string;
      deviceCode: string;
      deviceId: number;
      name: string;
    };
    deviceId: number;
    deviceCode: string;
    deviceCategoryCode: string;
    timeRange: string;
    startDate: Moment;
  };
  dataSourceType: string;
  dateSelectorValue: string;
  isBroadcasting: boolean;
  isForWidget: boolean;
  locations: Location[];
  showTitle: boolean;
  title: string;
  onCancel: () => void;
  onSave: (savedStates: any) => void;
};

const DEVICE_CATEGORY_CODE = 'HYDROPHONE';

type FormDataAvailabilityChartProps = FormFieldType<
  React.ComponentProps<typeof DataAvailabilityChartV2>
> &
  any;

const FormDataAvailabilityChart: React.FC<FormDataAvailabilityChartProps> = ({
  name,
  dataSources,
  setAvailabilityDateRanges,
  ...rest
}: FormDataAvailabilityChartProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <DataAvailabilityChartV2
          {...rest}
          dataSources={dataSources}
          onDateRangeSelected={(event) => {
            const { dateFrom, availabilityDateRanges } = event;
            setValue('config.startDate', moment(dateFrom));
            setAvailabilityDateRanges(availabilityDateRanges);
          }}
        />
      )}
    />
  );
};

const DataPlayerForm: React.FC<DataPlayerFormProps> = ({
  config,
  dataSourceType,
  dateSelectorValue,
  isBroadcasting,
  isForWidget,
  locations,
  showTitle,
  title,
  onCancel,
  onSave,
}: DataPlayerFormProps) => {
  const classes = useStyles();
  const [deviceCategoryCode, setDeviceCategoryCode] = useState(
    config.deviceCategoryCode
  );
  const [deviceCode, setDeviceCode] = useState(config.deviceCode);
  const [timeRange, setTimeRange] = useState(config.timeRange);
  const [device, setDevice] = useState(config.device);
  const [inputValue, setInputValue] = useState('');
  const [availabilityDateRanges, setAvailabilityDateRanges] =
    useState(undefined);
  const [locationsValue, setLocationsValue] = useState(locations);
  const [locationDevices, setLocationDevices] = useState([]);
  const [deviceId, setDeviceId] = useState(config.deviceId);

  const { onError } = useSnackbars();

  const deviceCategoryCodes = useMemo(
    () => [deviceCategoryCode],
    [deviceCategoryCode]
  );
  const devices = useDataPlayerDevices(onError, deviceCategoryCodes);
  const deviceParameters = devices.map((option) => ({
    deviceCategoryCode: option.deviceCategoryCode,
    deviceCode: option.deviceCode,
    deviceId: option.deviceId,
    name: option.name,
  }));
  const formMethods = useForm<DataPlayerFormProps>({
    defaultValues: {
      config,
      dataSourceType,
      dateSelectorValue,
      isBroadcasting,
      isForWidget,
      locations,
      showTitle,
      title,
    },
    mode: 'onBlur',
  });
  const { watch } = formMethods;
  const showTitleState = watch('showTitle');
  const newTitle = watch('title');
  const broadcastStatus = watch('isBroadcasting');
  const dataSource = watch('dataSourceType');
  const startDate = watch('config.startDate');
  const dateSelector = watch('dateSelectorValue');

  const savedStates = {
    availabilityDateRanges,
    dataSourceType: dataSource,
    dateSelectorValue: dateSelector,
    device,
    deviceCategoryCode,
    deviceId,
    inputValue,
    isBroadcasting: broadcastStatus,
    locationDevices,
    locations: locationsValue,
    timeRange,
    title: newTitle,
    showTitle: showTitleState,
    startDate,
  };

  const isDateInRangeOfAnyDateInArray = (dateRangeArray, dateRangeToCheck) => {
    const dateRangeCheck = (dateRange) =>
      dateRangeToCheck.firstDate.isSameOrBefore(moment(dateRange.dateTo)) &&
      dateRangeToCheck.lastDate.isSameOrAfter(moment(dateRange.dateFrom));
    return dateRangeArray.some(dateRangeCheck);
  };

  const createDevicesArray = (deviceList) => {
    const devicesList = deviceList.map((d) => ({
      deviceId: d.deviceId,
      name: d.name,
      deviceCategoryCode: d.deviceCategoryCode,
      locationCode: d.stationCode,
    }));
    // Delete duplicate deviceIds
    const uniqueDevicesList = [
      ...new Map(devicesList.map((item) => [item.deviceId, item])).values(),
    ];
    setLocationDevices(uniqueDevicesList);
  };

  const onTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
  };

  const onDeviceChange = (event, value) => {
    if (!value) {
      setDevice(null);
      setDeviceId(undefined);
      setDeviceCode(undefined);
    } else {
      setDevice(value);
      setDeviceId(value.deviceId);
      setDeviceCode(value.deviceCode);
      setInputValue(`${value.name} (${value.deviceId})`);
    }
  };

  const onDeviceCategoryChange = (e) => {
    if (dataSource === 'location') {
      setDeviceCategoryCode(e);
      setInputValue('');
    } else {
      setDeviceCategoryCode(e.target.value);
      setInputValue('');
    }
    onDeviceChange(e, undefined);
  };

  const onInputChange = (event, value) => {
    setInputValue(value);
  };

  const onLocationChange = (newLocations) => {
    setLocationsValue(newLocations);
    if (newLocations.length > 0) {
      createDevicesArray(newLocations[0].els);
      onDeviceCategoryChange(newLocations[0].deviceCategoryCode);
    }
  };

  const renderDeviceDropdown = () => {
    if (dataSource === 'location' && dateSelector === 'dateRange') {
      return (
        <Autocomplete
          options={locationDevices}
          virtualized
          getOptionLabel={(option) => `${option.name} (${option.deviceId})`}
          translationKey="device.deviceSelection"
          name="deviceId"
          onChange={onDeviceChange}
          value={device}
        />
      );
    }
    if (dataSource === 'deviceCategory' || !dataSource) {
      return (
        <Autocomplete
          options={deviceParameters}
          virtualized
          getOptionLabel={(option) => `${option.name} (${option.deviceId})`}
          translationKey="device.deviceSelection"
          fullWidth
          name="deviceId"
          onChange={onDeviceChange}
          onInputChange={onInputChange}
          inputValue={inputValue}
          value={device}
        />
      );
    }
    return null;
  };

  const renderTitleField = () => {
    if (isForWidget) {
      return (
        <Grid xs={12} className={classes.field}>
          <Collapse in={showTitleState}>
            <FormTextField
              fullWidth
              translationKey="common.textfields.title"
              name="title"
            />
          </Collapse>
        </Grid>
      );
    }
    return null;
  };

  const renderShowTitleToggle = () => {
    if (isForWidget) {
      return (
        <Grid xs={12} className={classes.field}>
          <FormToggle name="showTitle" label="Show Title" />
        </Grid>
      );
    }
    return null;
  };

  const renderCommunicationToggle = () => {
    if (isForWidget) {
      return (
        <Grid xs={12} className={classes.field}>
          <FormToggle
            name="isBroadcasting"
            label="Enable Audio Player Synchronize"
            tooltip="Synchronizes line with the Audio widget time. Control of time is handled via the Audio widget."
          />
        </Grid>
      );
    }
    return null;
  };

  const handleSubmit = () => {
    // default to true incase availabilityDateRanges was not set.
    let isDataAvailable = true;
    // Only check if availabilityDateRanges was set
    if (availabilityDateRanges) {
      const startEndDates = DataPlayerHelper.getStartEndDateAndOffset(
        startDate,
        timeRange
      );
      isDataAvailable = isDateInRangeOfAnyDateInArray(
        availabilityDateRanges,
        startEndDates
      );
    }

    if (!isDataAvailable) {
      onError('No data available for chosen date range');
    } else {
      onSave(savedStates);
    }
  };

  const renderLocationDataAvailability = () => {
    const newDeviceMap = [];
    locationsValue.forEach((node) => {
      node.els.forEach((siteDevice) => {
        newDeviceMap.push({
          dataSourceType: 'location',
          locationCode: siteDevice.stationCode,
          deviceCategoryCode: siteDevice.deviceCategoryCode,
        });
      });
    });
    return (
      <Collapse in={locationsValue && locationsValue.length > 0}>
        <FormDataAvailabilityChart
          canSelectDateRange
          name="dateRange"
          dataSources={newDeviceMap}
          setAvailabilityDateRanges={setAvailabilityDateRanges}
        />
      </Collapse>
    );
  };

  const renderDeviceDataAvailability = () => (
    <>
      <FormDataAvailabilityChart
        canSelectDateRange
        dataSources={[
          {
            extensions: ['png'],
            deviceCode,
            dataSourceType: 'device',
          },
        ]}
        name="dateRange"
        setAvailabilityDateRanges={setAvailabilityDateRanges}
      />
    </>
  );

  const renderDataSourceType = () => {
    if (dataSource === 'location') {
      return (
        <DataSourceList
          propertyPreset={['DataPlayer']}
          treeTypePreset="instrumentsByLocation"
          dataSourceCallback={onLocationChange}
          dataSources={locationsValue}
          multiple={false}
        />
      );
    }
    return (
      <Grid xs={12} className={classes.field}>
        <DataPlayerCategorySelect
          title="deviceCategory"
          value={deviceCategoryCode}
          onChange={onDeviceCategoryChange}
          onError={onError}
        />
      </Grid>
    );
  };

  const renderDateSelection = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Date Selection</Typography>
        <FormToggleButtonGroup
          name="dateSelectorValue"
          data-test="date-select-buttongroup"
          exclusive
          requireSelection
        >
          <ToggleButton value="latest">Latest</ToggleButton>
          <ToggleButton value="dateRange">Date Range</ToggleButton>
        </FormToggleButtonGroup>
      </Grid>

      <Collapse in={dateSelector === 'dateRange'}>
        <FormDateTimePicker
          translationKey="common.datepickers.startDate"
          fullWidth
          name="config.startDate"
        />
      </Collapse>
    </Grid>
  );

  const renderTimeRangeRadio = () => {
    if (deviceCategoryCode === DEVICE_CATEGORY_CODE) {
      return (
        <TimeRangeRadioGroupWithWeek
          value={timeRange}
          onChange={onTimeRangeChange}
        />
      );
    }
    return (
      <TimeRangeRadioGroup value={timeRange} onChange={onTimeRangeChange} />
    );
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Configure Data Player</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid className={classes.form} container>
            {renderShowTitleToggle()}
            {renderCommunicationToggle()}
            {renderTitleField()}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Data Source</Typography>
            </Grid>
            <FormToggleButtonGroup name="dataSourceType" exclusive>
              <ToggleButton value="deviceCategory">Device</ToggleButton>
              <ToggleButton value="location">Location</ToggleButton>
            </FormToggleButtonGroup>
            <Grid item xs={12}>
              {renderDataSourceType()}
            </Grid>
            <Grid xs={12} className={classes.field}>
              {renderDeviceDropdown()}
            </Grid>
            <Grid xs={12} className={classes.field}>
              {dataSource === 'location'
                ? renderLocationDataAvailability()
                : renderDeviceDataAvailability()}
            </Grid>
            <Grid xs={12} className={classes.field}>
              {renderDateSelection()}
            </Grid>
            <Grid xs={12} className={classes.field}>
              {renderTimeRangeRadio()}
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <CancelButton onClick={onCancel} />
            <SaveButton />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
export default DataPlayerForm;
