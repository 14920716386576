/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';

import * as React from 'react';

import Environment from '@onc/environment';
import { Lock, Movie, PlayArrow } from '@onc/icons';
import {
  DevExpressSorting as Sorting,
  DevExpressTableColumnExtension,
  ButtonBase,
  Tooltip,
  StatelessTable,
} from 'base-components';
import Thumbnail from 'domain/Apps/playlist-management/Thumbnail';
import { Playlist } from 'domain/services/PlaylistService';
import DateUtils from 'util/DateUtils';
import { useLocalStorage } from 'util/hooks/useStorage';
import {
  PlaylistNameFormatter,
  PlaylistDateFormatter,
} from './PlaylistFormatters';
import PlaylistTableActions from './PlaylistTableActions';

const storageKey = 'playlist-table-hidden-columns';
const defaultHidden = ['groupName', 'modifyDate', 'createdDate'];
const alwaysVisible = ['thumbnail', 'playlist', 'actions'];

type Props = {
  playlists: Playlist[];
  selectedGroup: string;
  onShare: (
    event: React.MouseEvent<HTMLButtonElement>,
    playlist: Playlist
  ) => void;
  onOpenDeleteForm: (playlist: Playlist) => void;
  onClone: (playlist: Playlist) => void;
  onDownload: (playlist: Playlist) => void;
  onEdit: (playlist: Playlist) => void;
  onRefresh?: () => void;
};

const FIXED_DATE_WIDTH = 180;
const colWidths: DevExpressTableColumnExtension[] = [
  { columnName: 'locked', width: 110, align: 'center' },
  { columnName: 'thumbnail', width: 300 },
  { columnName: 'numClips', width: 110 },
  { columnName: 'playlist', width: 'auto', wordWrapEnabled: true },
  { columnName: 'groupName', width: 200, wordWrapEnabled: true },
  { columnName: 'modifyDate', width: FIXED_DATE_WIDTH },
  { columnName: 'createdDate', width: FIXED_DATE_WIDTH },
  { columnName: 'actions', width: 160 },
];

const PlaylistTable: React.FC<Props> = ({
  playlists,
  selectedGroup,
  onDownload,
  onOpenDeleteForm,
  onShare,
  onEdit,
  onClone,
  onRefresh = undefined,
}: Props) => {
  const getThumbnail = (playlistLine) => (
    <>
      <Thumbnail
        deviceId={playlistLine.resource.resourceId}
        thumbnailDate={DateUtils.findMidpointDate(
          playlistLine.dateFrom,
          playlistLine.dateTo
        )}
        quality={playlistLine.quality}
        width={180}
        height={101}
      />
      <PlayArrow
        style={{ position: 'absolute', color: 'white' }}
        fontSize="large"
      />
    </>
  );
  const getThumbnailPlaceholder = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '180px',
        height: '101px',
      }}
    >
      <Tooltip title="No clips in playlist" placement="top-start">
        <Movie style={{ fontSize: 60 }} />
      </Tooltip>
    </div>
  );

  const getRows = () =>
    playlists
      .map((playlist) => {
        const {
          locked,
          groupName,
          name,
          description,
          modifyDate,
          createdDate,
          playlistLines,
          playlistHdrId,
        } = playlist;
        return {
          id: playlistHdrId,
          locked: locked ? (
            <Tooltip
              title="This playlist is locked. It can be unlocked on the playlist edit page"
              placement="top-start"
            >
              <Lock />
            </Tooltip>
          ) : undefined,
          thumbnail: (
            <ButtonBase
              href={`${Environment.getLinkUrl()}/app/playlists/${playlistHdrId}`}
              target="_blank"
            >
              {playlistLines[0]?.resource
                ? getThumbnail(playlistLines[0])
                : getThumbnailPlaceholder()}
            </ButtonBase>
          ),
          playlist: `${name} ${description}`,
          playlistName: name,
          groupName,
          description,
          numClips: playlistLines.length,
          createdDate,
          modifyDate,
          actions: PlaylistTableActions({
            playlist,
            isLocked: locked,
            onOpenDeleteForm,
            onClone,
            onDownload,
            onShare,
            onEdit,
          }),
        };
      })
      .filter((playlist) => {
        if (selectedGroup === 'All Playlists') {
          return true;
        }
        if (selectedGroup === '(Uncategorized)') {
          return !playlist.groupName;
        }
        return playlist.groupName === selectedGroup;
      });

  const [sorting, setSorting] = useState<Sorting[]>([
    { columnName: 'playlist', direction: 'asc' },
  ]);

  // Hidden Columns
  const [hiddenColumnNames, setHiddenColumns] = useLocalStorage<string[]>(
    storageKey,
    defaultHidden
  );

  return (
    <StatelessTable
      title={selectedGroup}
      onRefresh={onRefresh}
      rows={useMemo(() => getRows(), [playlists, selectedGroup])}
      columns={[
        { name: 'locked', title: 'Status' },
        { name: 'thumbnail', title: 'Thumbnail' },
        { name: 'numClips', title: '# Clips' },
        { name: 'groupName', title: 'Collection' },
        { name: 'playlist', title: 'Playlist' },
        { name: 'modifyDate', title: 'Modified' },
        { name: 'createdDate', title: 'Created' },
        { name: 'actions', title: 'Actions' },
      ]}
      columnExtensions={colWidths}
      searchable
      sort={{
        sorting,
        columnExtensions: [
          { columnName: 'actions', sortingEnabled: false },
          { columnName: 'thumbnail', sortingEnabled: false },
        ],
        customSorting: [
          {
            columnName: 'playlist',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            columnName: 'groupName',
            compare: (a, b) => {
              if (a === undefined && b === undefined) {
                return 0;
              }
              if (a === undefined) {
                return -1;
              }
              if (b === undefined) {
                return 1;
              }
              return a.toLowerCase().localeCompare(b.toLowerCase());
            },
          },
        ],
        handleSortingChange: setSorting,
      }}
      visible={{
        hiddenColumnNames,
        handleChangeVisibility: setHiddenColumns,
        disableColumnNames: alwaysVisible,
      }}
      columnFormatProviders={[
        {
          name: 'ExpansionFormatter',
          for: ['playlist'],
          formatterComponent: PlaylistNameFormatter,
        },
        {
          name: 'TimeFormatter',
          for: ['modifyDate', 'createdDate'],
          formatterComponent: PlaylistDateFormatter,
        },
      ]}
    />
  );
};

export default PlaylistTable;
