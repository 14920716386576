import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { ContainedButton, OutlinedButton } from '@onc/composite-components';
import { Grid, Paper } from 'base-components';
import DataSourceWidgetTable from './DataSourceWidgetTable';

const useStyles = makeStyles(() =>
  createStyles({
    backButton: {
      marginLeft: 0,
    },
    sourceButton: {
      marginRight: 0,
    },
    list: {
      justifyContent: 'center',
      padding: '0px',
      width: '100%',
      position: 'relative',
      overflow: 'auto',
      maxHeight: '400px',
    },
  })
);

interface Props {
  dataSources: any[];
  dataSourceType: string;
  selectedDataSource: any;
  setSelectedDataSource: (key: number) => void;
  setSelectedDataSourceDevice: (deviceId: number) => void;
  setWidgetView: (view: string) => void;
}

const DataSourceWidgetContainer: React.FC<Props> = (props: Props) => {
  const {
    dataSources,
    dataSourceType,
    selectedDataSource,
    setWidgetView,
    setSelectedDataSource,
    setSelectedDataSourceDevice,
  } = props;
  const classes = useStyles();

  const renderSourceTable = (sources) => (
    <DataSourceWidgetTable
      dataSources={sources}
      setSelectedDataSource={setSelectedDataSource}
      setSelectedDataSourceDevice={setSelectedDataSourceDevice}
      dataSourceType={dataSourceType}
    />
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      wrap="nowrap"
    >
      <Paper className={classes.list} elevation={1}>
        {dataSources ? renderSourceTable(dataSources) : null}
      </Paper>
      <Grid item container justifyContent="space-between">
        <OutlinedButton
          translationKey="common.buttons.back"
          onClick={() => setWidgetView('filters')}
          className={classes.backButton}
          aria-describedby="back-button"
        />
        <ContainedButton
          // TODO: Add onClick handler for data broadcasting
          disabled={selectedDataSource === -1}
          className={classes.sourceButton}
          aria-describedby="source-button"
          translationKey="dashboards.selectSource"
        />
      </Grid>
    </Grid>
  );
};

export default DataSourceWidgetContainer;
