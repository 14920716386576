import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  ContainedButton,
  FloatRightButtonLowStyles,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import DeviceActionService from 'domain/services/DeviceActionService';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const styles = {
  button: FloatRightButtonLowStyles,
};

const headers = [
  { title: 'ID', name: 'deviceActionId' },
  { title: 'Action', name: 'actionName' },
  { title: 'Cruise', name: 'cruise' },
  { title: 'Description', name: 'description' },
  { title: 'Date Performed', name: 'datePerformed' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'ID For Search', name: 'deviceActionIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'deviceActionId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'actionName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'cruise',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.large,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'datePerformed',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceActionIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class DeviceActionTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }).isRequired,
  };

  compareActionId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  deviceActionLink = (actionId) => (
    <a
      href={`${Environment.getDmasUrl()}/DeviceActionMaintenance?ID=${actionId}&isFromDevice=Y`}
    >
      {actionId}
    </a>
  );

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId } = this.props;
    this.setState({ isLoading: true });
    DeviceActionService.getMany(deviceId)
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  actionContent = () => {
    const { deviceId, permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceActionRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return <DeviceActionRefreshButton />;
    }
    return (
      <>
        <DeviceActionRefreshButton />
        <a
          href={`${Environment.getDmasUrl()}/DeviceActionMaintenance?deviceId=${deviceId}&isFromDevice=Y`}
        >
          <ContainedButton
            translationKey="device.addDeviceAction"
            startIcon={<Add />}
            className={classes.button}
          />
        </a>
      </>
    );
  };

  buildTableRows = (actions) => {
    this.setState({
      tableRows: actions.map((action) => {
        const actionFor = {
          ...action,
          deviceActionId: this.deviceActionLink(action.deviceActionId),
          datePerformed: DateFormatUtils.formatDate(
            action.datePerformed,
            'full'
          ),
          modifyDate: DateFormatUtils.formatDate(action.modifyDate, 'full'),
          modifyBy: action.modifyByName,
          cruise: action.cruiseName,
          deviceActionIdForSearch: action.deviceActionId,
        };
        return actionFor;
      }),
    });
  };

  render() {
    const { permission } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <Panel
        title={<Typography variant="h6">Device Action List</Typography>}
        actionContent={this.actionContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={10}
          rows={tableRows}
          searchable
          searchBarMoveable={permission === 'RW'}
          sortExtensions={[
            { columnName: 'deviceActionId', compare: this.compareActionId },
          ]}
          stripedRows
          hiddenColumns={['deviceActionIdForSearch']}
          isLoading={isLoading}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(DeviceActionTable);
