import { ErrorAlert } from '@onc/composite-components';
import { OpenInNew } from '@onc/icons';
import FixedCameraAnnotationEntry from 'domain/AppComponents/sea-tube/manual-entry/FixedCameraAnnotationEntry';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import { SavedAnnotation } from 'domain/services/AnnotationService';
import { useTaxonomies } from 'domain/services/TaxonomyService';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';

import Environment from 'util/Environment';
import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItem,
} from '../../library/CompositeComponents/dashboard/Widget';
import useBroadcast from '../../util/hooks/useBroadcast';

const GeneralManualEntryWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, dashboardId } = props;
  const { dashboardState, setDashboardStateProperty } = useDashboardState();

  const { resourceTypeId, searchTreeNodeCode, selectedButton, editAnnotation } =
    dashboardState;

  const TaxonomyManagementMenuItem = WidgetMenuItem(
    'taxonomy-management-link',
    () => {
      window.open(`${Environment.getDmasUrl()}/Taxonomy`);
    },
    'Taxonomy Management',
    OpenInNew,
    false
  );

  const [currentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    null,
    id
  );

  const { data: taxonomies } = useTaxonomies();

  const handleSubmitSuccess = (data: SavedAnnotation) => {
    setDashboardStateProperty('lastEditedAnnotationId', data.annotationId);
  };

  // Formats taxonomies into DropdownOption format
  const getTaxonomyOptions = () =>
    taxonomies?.map((taxonomy) => ({
      key: taxonomy.taxonomyId.toString(),
      value: taxonomy.taxonomyId,
      label: taxonomy.taxonomyName,
    }));

  const renderFormContext = () => {
    switch (resourceTypeId) {
      case SeaTubeResourceTypes.SEARCH_TREE_NODE:
        return (
          <FixedCameraAnnotationEntry
            editAnnotation={editAnnotation}
            currentTimestamp={currentTimestamp}
            taxonomyOptions={getTaxonomyOptions()}
            searchTreeNodeCode={searchTreeNodeCode}
            onSubmitSuccess={handleSubmitSuccess}
            quickButton={selectedButton}
            onClearEditedAnnotation={() =>
              setDashboardStateProperty('editAnnotation', null)
            }
          />
        );

      // TODO: Components for Dive / Deck Log
      default:
        return (
          <ErrorAlert title="Error">
            {`Resource Type Id: ${resourceTypeId} not supported`}
          </ErrorAlert>
        );
    }
  };

  return (
    <Widget
      key={id}
      title="Annotation Entry"
      MenuItems={[TaxonomyManagementMenuItem]}
      ariaLabel="Annotation Entry"
      {...props}
    >
      {renderFormContext()}
    </Widget>
  );
};

GeneralManualEntryWidget.widgetKey = 'manual-entry';
GeneralManualEntryWidget.widgetTitle = 'Annotation Entry';
GeneralManualEntryWidget.defaultDataGrid = {
  i: 'manual-entry',
  x: 0,
  y: 0,
  w: 4,
  h: 12,
};

export default GeneralManualEntryWidget;
