import { useContext, MouseEvent } from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { ContainedButton, TextButton } from '@onc/composite-components';
import { ExpandMore, FileCopy } from '@onc/icons';
import {
  Box,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from 'base-components';
import { COMPLETED } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';
import CastDataContext from '../CastDataContext';
import SelectedStationsContext from '../SelectedStationsContext';

const unassignedCasts = 'Unassigned Casts';
const UNASSIGNED_CAST_SEARCH_TREE_NODE_ID = 1852;

const STYLES = (theme: Theme) =>
  createStyles({
    button: {
      textAlign: 'end',
    },
    list: {
      overflowY: 'scroll',
      maxHeight: 400,
    },
    fileCopy: {
      margin: 'auto',
      padding: theme.spacing(),
    },
    checkbox: {
      color: theme.palette.warning.light,
      '&.Mui-checked': {
        color: theme.palette.warning.light,
      },
    },
  });

interface Props extends WithStyles<typeof STYLES> {
  /* Filter function to call to refine casts */
  filterCasts: (casts: any) => any;
  childCheckBoxOnChange: (subset: { siteDeviceSubsetName: string }) => void;
  parentCheckBoxOnChange: (selectedStation: any) => void;
  continueButtonOnClick: (event: MouseEvent) => unknown;
  clearAllSubsets: () => void;
  toggleAllSubsets: () => void;
  toggleCastsValue: boolean;
}

interface StationSiteDeviceSubset {
  checkboxSelected: boolean;
  siteDeviceSubsetName: string;
  startDate: Date;
  endDate: Date;
  siteDeviceSubsetId: number;
  accessRestrictionLevel: string;
}

const CastLassoSelection: React.VFC<Props> = (props: Props) => {
  const {
    filterCasts,
    clearAllSubsets,
    toggleAllSubsets,
    childCheckBoxOnChange,
    parentCheckBoxOnChange,
    continueButtonOnClick,
    toggleCastsValue,
    classes,
  } = props;

  const { downloadedCasts } = useContext(CastDataContext);
  const { selectedStations } = useContext(SelectedStationsContext);
  const toggle =
    toggleCastsValue || selectedStations.length === 0 ? 'Select' : 'Deselect';

  const selectedStationswithFilteredCasts = selectedStations.map(
    ({ siteDeviceSubsets, ...rest }) => ({
      ...rest,
      siteDeviceSubsets: filterCasts(siteDeviceSubsets),
    })
  );

  const areAllProductsDownloadedForCast = (subset: StationSiteDeviceSubset) => {
    const castFromListOfDownloadedCasts = downloadedCasts.find(
      (downloadedSubset) =>
        downloadedSubset.siteDeviceSubsetId === subset.siteDeviceSubsetId
    );
    if (!castFromListOfDownloadedCasts) {
      return false;
    }
    return castFromListOfDownloadedCasts.productsForCast.every(
      (product) => product.status === COMPLETED
    );
  };

  const selectedCastsList = selectedStationswithFilteredCasts.map(
    (selectedStation) => {
      const castAssignment = selectedStation.name;
      const children = (
        <Box
          key={selectedStation.name}
          sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}
        >
          {selectedStation.siteDeviceSubsets
            .sort((s1, s2) => {
              if (selectedStation.id !== UNASSIGNED_CAST_SEARCH_TREE_NODE_ID) {
                return (
                  new Date(s2.startDate).valueOf() -
                  new Date(s1.startDate).valueOf()
                );
              }
              return s1.siteDeviceSubsetId > s2.siteDeviceSubsetId ? 1 : -1;
            })
            .map((subset) => (
              <FormControlLabel
                key={subset.siteDeviceSubsetName}
                label={
                  <div style={{ display: 'flex' }}>
                    {castAssignment === unassignedCasts ? (
                      <Typography noWrap>
                        {subset.siteDeviceSubsetName}&nbsp;&nbsp;&nbsp;
                      </Typography>
                    ) : (
                      ''
                    )}
                    <Typography noWrap>
                      {new Date(subset.startDate).toISOString().slice(0, 16)}
                    </Typography>
                    <Typography noWrap>&nbsp;&ndash;&nbsp;</Typography>
                    <Typography noWrap>
                      {new Date(subset.endDate).toISOString().slice(0, 16)}
                    </Typography>
                  </div>
                }
                title={`Cast Id: ${subset.siteDeviceSubsetName}`}
                aria-label={`${subset.siteDeviceSubsetName} child label`}
                control={
                  areAllProductsDownloadedForCast(subset) ? (
                    <FileCopy
                      key={subset.siteDeviceSubsetName}
                      className={classes.fileCopy}
                    />
                  ) : (
                    <Checkbox
                      name={`subset-${subset.siteDeviceSubsetId}`}
                      aria-label={`${subset.siteDeviceSubsetName} child checkbox`}
                      key={subset.siteDeviceSubsetName}
                      checked={subset.checkboxSelected}
                      onChange={() => childCheckBoxOnChange(subset)}
                    />
                  )
                }
              />
            ))}
        </Box>
      );
      // to indicate partial selection of a parent station/node
      const checkboxClass =
        selectedStation.partialCheckboxSelected &&
        !selectedStation.checkboxSelected
          ? classes.checkbox
          : undefined;
      return (
        <div key={selectedStation.name}>
          <Accordion key={selectedStation.name}>
            <AccordionSummary
              key={selectedStation.name}
              expandIcon={
                <ExpandMore
                  aria-label={`${selectedStation.name} expand icon`}
                />
              }
            >
              <FormControlLabel
                key={selectedStation.name}
                label={`${selectedStation.name} (${selectedStation.siteDeviceSubsets.length} casts)`}
                control={
                  <Checkbox
                    name={`station-${selectedStation.code}`}
                    aria-label={`${selectedStation.name} checkbox`}
                    key={selectedStation.name}
                    checked={
                      selectedStation.partialCheckboxSelected ||
                      selectedStation.checkboxSelected
                    }
                    classes={{ checked: checkboxClass }}
                    onChange={() => parentCheckBoxOnChange(selectedStation)}
                  />
                }
              />
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
          </Accordion>
        </div>
      );
    }
  );
  return (
    <>
      <div className={classes.list}> {selectedCastsList} </div>
      <div className={classes.button}>
        <TextButton
          translationKey="communityFishers.clearAllCasts"
          onClick={clearAllSubsets}
        />
        <TextButton
          translationKey="communityFishers.toggleAllCasts"
          translationOptions={{ toggle }}
          onClick={toggleAllSubsets}
        />
        <ContainedButton
          onClick={continueButtonOnClick}
          translationKey="common.buttons.continue"
        />
      </div>
    </>
  );
};

export default withStyles(STYLES)(CastLassoSelection);
