import { createTheme } from '@mui/material/styles';
import oceans2Theme from './oceans2Theme';

const oncDefaultTheme = createTheme({
  typography: {
    fontSize: 14,
  },
  ...oceans2Theme,
});

export default oncDefaultTheme;
