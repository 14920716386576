import '@fontsource/rajdhani';
import * as React from 'react';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { oceans3Theme } from '@onc/theme';
import LandingPageApplications from 'domain/Apps/oceans-3-landing-page/LandingPageApplications';
import LandingPageHeader from 'domain/Apps/oceans-3-landing-page/LandingPageHeader';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const STYLES = () =>
  createStyles({
    page: {
      display: 'block',
    },
    applications: {
      display: 'block',
      width: 'auto',
      position: 'relative',
    },
    header: {
      minHeight: '10%',
      width: 'auto',
      position: 'relative',
    },
    buttonGroup: {
      position: 'relative',
      float: 'right',
    },
  });
type LandingPageProps = WithStyles<typeof STYLES>;

const LandingPage: React.FC<LandingPageProps> = ({ classes }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={oceans3Theme}>
      <div className={classes.header}>
        <LandingPageHeader />
      </div>
      <div className={classes.applications}>
        <LandingPageApplications />
      </div>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default withStyles(STYLES)(LandingPage);
