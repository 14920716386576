import { Component } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CreateButton, SaveButton } from '@onc/composite-components';
import { DateTimePicker, Typography } from 'base-components';
import {
  ModifyBy,
  ModifyDate,
  MarginedReadOnlyField,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import DeviceProcurementService from 'domain/services/DeviceProcurementService';
import ResponsiveDataGridLayout from 'library/CompositeComponents/grid-layout/ResponsiveDataGridLayout';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateFormatUtils from 'util/DateFormatUtils';

const styles = (theme) => ({
  accordionDetails: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  accordionSummary: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  deviceContainer: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  root: {
    paddingLeft: theme.spacing(3),
  },
});

const defaultState = {
  procurementExists: true,
  procurement: {
    procurementId: undefined,
    purchaseOrderNr: '',
    cost: '',
    warrantyMonths: '',
    warrantyStartDate: undefined,
    dateReceived: undefined,
  },
};

class DeviceProcurementPanel extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      accordionDetails: PropTypes.string,
      accordionSummary: PropTypes.string,
      deviceContainer: PropTypes.string,
      formButtons: PropTypes.string,
      root: PropTypes.string,
      textField: PropTypes.string,
    }).isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };

  paramsForPost = (procurement) => {
    const params = { ...procurement };
    const dateStr = DateFormatUtils.formatDate(
      params.dateReceived,
      'time-with-month-name-string'
    );
    const timeStr = DateFormatUtils.formatDate(params.dateReceived, 'time');
    const warrantyDateStr = DateFormatUtils.formatDate(
      params.warrantyStartDate,
      'time-with-month-name-string'
    );
    const warrantyTimeStr = DateFormatUtils.formatDate(
      params.warrantyStartDate,
      'time'
    );

    if (
      params.warrantyStartDate !== '' ||
      params.warrantyStartDate !== undefined
    ) {
      params.warrantyStartDate = `${warrantyDateStr} ${warrantyTimeStr}`;
    } else {
      params.warrantyStartDate = undefined;
    }

    if (params.dataReceived !== '' || params.dateReceived !== undefined) {
      params.dateReceived = `${dateStr} ${timeStr}`;
    } else {
      params.dateReceived = undefined;
    }
    return params;
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = async () => {
    const { deviceId, onError } = this.props;
    await DeviceProcurementService.get(deviceId)
      .then((response) => {
        if (response.data.statusCode === 0 && response.data.payload) {
          this.setState({ procurementExists: true });
          this.fillForm(response.data.payload);
        } else {
          this.setState({ procurementExists: false });
          onError(response.data.message);
        }
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  fillForm = (result) => {
    const { procurement, dmasUser } = result;
    this.setState({
      procurement: {
        ...procurement,
        dateReceived:
          procurement.dateReceived !== '' ||
          procurement.dateReceived !== undefined
            ? new Date(procurement.dateReceived)
            : undefined,
        warrantyStartDate:
          procurement.warrantyStartDate !== '' ||
          procurement.warrantyStartDate !== undefined
            ? new Date(procurement.warrantyStartDate)
            : undefined,
        deviceName: procurement.device.deviceName,
        modifyBy: `${dmasUser.firstname} ${dmasUser.lastname}`,
      },
    });
  };

  fillButtons = (permission) => {
    const { procurementExists } = this.state;

    if (permission !== 'RW') return null;
    if (procurementExists) {
      return <SaveButton onClick={this.onSave} />;
    }
    return <CreateButton onClick={this.onCreate} />;
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { procurement } = this.state;
    this.setState({
      procurement: {
        ...procurement,
        [name]: value,
      },
    });
  };

  onCreate = async () => {
    const { procurement } = this.state;
    const { deviceId, onInfo, onError } = this.props;
    const params = this.paramsForPost(procurement);

    await DeviceProcurementService.create(deviceId, params)
      .then((response) => {
        onInfo(`New Procurement created with id ${response.procurementId}`);
        this.refreshData();
      })
      .catch((response) => {
        onError(response.message);
        this.refreshData();
      });
  };

  onSave = async () => {
    const { procurement } = this.state;
    const { deviceId, onInfo, onError } = this.props;
    const params = this.paramsForPost(procurement);

    await DeviceProcurementService.update(deviceId, params)
      .then(() => {
        onInfo(`Updated Procurement ${procurement.procurementId}`);
        this.refreshData();
      })
      .catch((response) => {
        onError(response.message);
        this.refreshData();
      });
  };

  onDatePerformedChange = (event) => {
    const { procurement } = this.state;
    this.setState({
      procurement: { ...procurement, dateReceived: event.toDate() },
    });
  };

  onWarrantyDateChange = (event) => {
    const { procurement } = this.state;
    this.setState({
      procurement: { ...procurement, warrantyStartDate: event },
    });
  };

  getFormFields = () => {
    const { permission, classes } = this.props;
    const {
      procurement: {
        purchaseOrderNr,
        cost,
        warrantyMonths,
        warrantyStartDate,
        dateReceived,
      },
    } = this.state;
    const fields = [];

    fields.push(
      <RORWTextField
        name="purchaseOrderNr"
        translationKey="device.purchaseOrderNr"
        onChange={this.onChange}
        value={purchaseOrderNr}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />,
      <RORWTextField
        name="cost"
        translationKey="device.cost"
        onChange={this.onChange}
        value={cost}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    if (permission === 'RW') {
      fields.push(
        <DateTimePicker
          translationKey="device.receivedDate"
          value={dateReceived ? moment.utc(dateReceived) : undefined}
          onChange={this.onDatePerformedChange}
          className={classes.textField}
          fullWidth
        />,
        <DateTimePicker
          translationKey="device.warrantyStartDate"
          value={warrantyStartDate ? moment.utc(warrantyStartDate) : undefined}
          onChange={this.onWarrantyDateChange}
          className={classes.textField}
          fullWidth
        />
      );
    } else {
      fields.push(
        <MarginedReadOnlyField
          labelText="Date Received"
          valueText={DateFormatUtils.formatDate(dateReceived, 'full')}
          title="dateReceivedTextField"
        />,
        <MarginedReadOnlyField
          labelText="Warranty Start Date"
          valueText={DateFormatUtils.formatDate(warrantyStartDate, 'full)')}
          title="warrantyStartDateTextField"
        />
      );
    }
    fields.push(
      <RORWTextField
        name="warrantyMonths"
        translationKey="device.warrantyMonths"
        onChange={this.onChange}
        value={warrantyMonths}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    return fields;
  };

  render() {
    const { deviceId, permission, classes } = this.props;
    const {
      procurementExists,
      procurement: { procurementId, modifyByName, modifyDate },
    } = this.state;
    if (!procurementExists && permission !== 'RW') {
      return (
        <Panel
          title={
            <Typography variant="body1">
              No Procurement found for device {deviceId}
            </Typography>
          }
        />
      );
    }
    const fields = this.getFormFields();

    let procurementIdText;
    let color;
    if (procurementExists) {
      procurementIdText = procurementId;
    } else {
      procurementIdText = 'NaN';
      color = 'textSecondary';
    }

    return (
      <Panel
        title={
          <>
            <Typography variant="body1">Procurement ID: </Typography>
            <Typography variant="body1" color={color}>
              {procurementIdText}
            </Typography>
          </>
        }
      >
        <ResponsiveDataGridLayout formFields={fields} />
        <div className={classes.root}>
          <ModifyBy username={modifyByName} />
          <ModifyDate date={modifyDate} />
        </div>
        <div className={classes.formButtons}>
          {this.fillButtons(permission)}
        </div>
      </Panel>
    );
  }
}

export default withStyles(styles)(withSnackbars(DeviceProcurementPanel));
