/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import * as React from 'react';
import { withStyles, createStyles } from '@mui/styles';
import { TextButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Autocomplete, Grid } from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import TaxonomyAttributeGroupService from 'domain/services/TaxonomyAttributeGroupService';
import TaxonomyAttributeService from 'domain/services/TaxonomyAttributeService';
import { FilterEvent } from 'library/CompositeComponents/filter/Filter';
import AttributeFilterValueField from './AttributeFilterValueField';

type Props = {
  onChange: (e: FilterEvent) => void;
  onError: (e: object) => void;
  value: any;
  classes: any;
};

const ALL_ATTRIBUTES = { groupId: -1, name: '(All Attributes)' };

const styles = () =>
  createStyles({
    button: {
      width: '100%',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  });

const emptyList = [
  { attribute: undefined, operator: undefined, attributeGroup: ALL_ATTRIBUTES },
];

const AttributeFilterList: React.FC<Props> = (
  props: Props
): React.ReactElement => {
  const { classes, value, onChange, onError } = props;
  const [attributeFilterList, setAttributeFilterList] = useState<object[]>(
    value || emptyList
  );
  const [attributeList, setAttributeList] = useState<object[]>([]);
  const [groupList, setGroupList] = useState<object[]>([]);

  // Call onChange any time we update the attributeList
  useEffect(() => {
    onChange({
      target: {
        value: attributeFilterList,
        name: 'attributes',
      },
    });
  }, [attributeFilterList]);

  useEffect(() => {
    if (!value) {
      setAttributeFilterList(emptyList);
    }
  }, [value]);

  useEffect(() => {
    TaxonomyAttributeService.getAllSelectable()
      .then((response: object[]) => {
        setAttributeList(response);
      })
      .catch((error: any) => {
        onError(error);
      });
  }, [onError]);

  useEffect(() => {
    TaxonomyAttributeGroupService.getAll()
      .then((response: any[]) => {
        response.push({
          groupId: -1,
          name: '(All Attributes)',
        });
        setGroupList(
          response.sort((a, b) =>
            a.name < b.name || a.groupId === -1 ? -1 : 1
          )
        );
      })
      .catch((error: any) => {
        onError(error);
      });
  }, [onError]);

  const updateAttributeData = (newProps: any, index: number) => {
    const updatedAttributeFilterList = [...attributeFilterList];
    updatedAttributeFilterList[index] = {
      ...updatedAttributeFilterList[index],
      ...newProps,
    };
    setAttributeFilterList(updatedAttributeFilterList);
  };

  const filterAttributes = (attributeListItem: any, attributeLine: any) => {
    const selectedGroup = attributeLine.attributeGroup;
    const groupId = selectedGroup ? selectedGroup.groupId : -1;
    if (groupId === -1) {
      return true;
    }
    if (attributeListItem.groupId === groupId) {
      return true;
    }
    return false;
  };

  const handleRemoveAttributeFilter = (index: number) => {
    const updatedAttributeFilterList = [...attributeFilterList];
    updatedAttributeFilterList.splice(index, 1);
    setAttributeFilterList(updatedAttributeFilterList);
  };

  const handleAddAttributeFilter = () => {
    const updatedAttributeFilterList = [...attributeFilterList];
    updatedAttributeFilterList.push({ attributeGroup: ALL_ATTRIBUTES });
    setAttributeFilterList(updatedAttributeFilterList);
  };

  return (
    <>
      {attributeFilterList.map((attributeLine: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classes.flexContainer} key={index.toString()}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Autocomplete
                translationKey="seatube.group"
                name="group"
                fullWidth
                value={attributeLine.attributeGroup}
                getOptionLabel={(option: any) => option.name}
                isOptionEqualToValue={(option: any, selected: any) =>
                  option.groupId === selected.groupId
                }
                options={groupList}
                onChange={(e: any) =>
                  updateAttributeData({ attributeGroup: e.target.value }, index)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                translationKey="seatube.attribute"
                name="attribute"
                fullWidth
                value={attributeLine.attribute || null}
                getOptionLabel={(option: any) => option.label}
                isOptionEqualToValue={(option: any, selected: any) =>
                  option.value === selected.value
                }
                onChange={(e: any) => {
                  updateAttributeData(
                    { filterValue: undefined, attribute: e.target.value },
                    index
                  );
                }}
                options={attributeList
                  .map((option: any) => ({
                    label: option.name,
                    value: option.attributeId,
                    dataType: option.dataType,
                    attributeValues: option.attributeValues,
                    groupId: option.groupId,
                  }))
                  .filter((item) => filterAttributes(item, attributeLine))}
              />
            </Grid>
            <Grid item xs={5}>
              <AttributeFilterValueField
                attributeLine={attributeLine}
                index={index}
                onChange={updateAttributeData}
              />
            </Grid>
          </Grid>
          <DeleteIconButton
            size="medium"
            disabled={attributeFilterList.length === 1}
            onClick={() => handleRemoveAttributeFilter(index)}
          />
        </div>
      ))}
      <Grid item xs={12} />
      <TextButton
        translationKey="seatube.addAttributeFilter"
        startIcon={<Add />}
        className={classes.button}
        onClick={handleAddAttributeFilter}
      />
    </>
  );
};

export default withStyles(styles)(AttributeFilterList);
