import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { ClearButton, SaveButton } from '@onc/composite-components';
import { LabelledCheckbox } from 'base-components';
import Util from 'domain/AppComponents/manual-entry/ManualEntryLogic';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import {
  useCreateAnnotation,
  useUpdateAnnotation,
} from 'domain/services/AnnotationService';
import { CompromisedAnnotation } from 'domain/services/CompromisedInstrumentsService';
import TaxonAttributeService from 'domain/services/TaxonAttributeService';
import { TaxonomyAttributeJson } from 'domain/services/TaxonomyAttributeService';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import DraggableToolbox from '../annotations/entry/DraggableToolbox';
import { TaxonOption } from '../dropdowns/TaxonAsyncAutocomplete';
import ManualEntryAttributes, {
  EntryAttributesSection,
} from '../manual-entry/ManualEntryAttributes';
import ManualEntryGeneral, {
  EntryGeneralSection,
} from '../manual-entry/ManualEntryGeneral';
import ManualEntryResource from '../manual-entry/ManualEntryResource';
import { ONC_DATA } from '../organization-details/OrganizationServiceData';
import {
  ManualEntryErrors,
  ManualEntryFormType,
} from '../sea-tube/manual-entry/ManualEntryForm';

type Props = {
  open: boolean;
  onClose: () => void;
  device?: {
    deviceId: number;
    deviceName: string;
  };
  editAnnotation?: CompromisedAnnotation;
  onUpdate?: (anno: CompromisedAnnotation) => void;
};

const CompromisedAnnotationEntry = ({
  open,
  onClose,
  device = undefined,
  editAnnotation = undefined,
  onUpdate = undefined,
}: Props) => {
  const emptyForm: ManualEntryFormType = {
    resourceSection: {
      resourceTypeId:
        editAnnotation?.resourceTypeId || SeaTubeResourceTypes.DEVICE_DATA,
      resourceId: device?.deviceId || editAnnotation?.resourceId,
    },
    generalSection: {
      startDate: null,
      endDate: null,
      taxonomyId: 1100,
      taxon: null,
      comment: '',
      shared: false,
      flagged: false,
    },
    attributesSection: {
      attributes: [],
    },
  };
  const { onInfo, onError } = useSnackbars();
  const [formState, setFormState] = useState<ManualEntryFormType>(emptyForm);
  const [lastState, setLastState] = useState<ManualEntryFormType>(emptyForm);
  const [errors, setErrors] = useState<ManualEntryErrors>({
    startDateError: undefined,
    endDateError: undefined,
    attributeErrors: {},
    taxonError: undefined,
  });
  const { data: attributeOptions } = useGet<
    TaxonomyAttributeJson[],
    { filterSelectable: boolean }
  >(
    'internal/taxonomies/attributes',
    { operation: undefined, transform: (response) => response.data },
    { filterSelectable: true }
  );

  const handleSuccess = () => {
    onInfo('Annotation Saved');
    // save the last state after update
    setLastState(formState);
    // set to clear the taxon field properly
    setFormState({
      ...emptyForm,
      generalSection: { ...emptyForm.generalSection, taxonomyId: null },
    });
    onClose();
  };

  const { mutate: createAnnotation } = useCreateAnnotation(handleSuccess, () =>
    onError('Error creating annotation')
  );

  const { data: updateResponse, mutate: updateAnnotation } =
    useUpdateAnnotation(handleSuccess, () =>
      onError('Error updating annotation')
    );

  // TaxonAttributeService (getting attrs for taxon)
  const [, , fetchAttributes] = useWebService({
    method: TaxonAttributeService.getAttributes,
    onError,
  });

  useEffect(() => {
    // reset the taxonomy after a save/clear
    if (formState.generalSection.taxonomyId === null) {
      setFormState(emptyForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.generalSection]);

  useEffect(() => {
    // prepopulate if an annotation is passed for editing
    if (editAnnotation && attributeOptions) {
      const updatedForm = _.cloneDeep(formState);

      updatedForm.resourceSection = {
        resourceId: editAnnotation.resourceId,
        resourceTypeId: editAnnotation.resourceTypeId,
      };

      // use null if the annotation has no taxon
      const originalTaxon: EntryGeneralSection['taxon'] = editAnnotation.taxons
        ? {
            taxonId: editAnnotation.taxons[0].taxonId,
            value: editAnnotation.taxons[0].taxonId,
            label: editAnnotation.taxons[0].displayText,
            commonName: editAnnotation.taxons[0].displayText,
          }
        : null;

      updatedForm.generalSection = {
        annotationId: editAnnotation.annotationHdrId,
        startDate: editAnnotation.startDate,
        endDate: editAnnotation.endDate,
        comment: editAnnotation.comment,
        taxonomyId: editAnnotation.taxons
          ? editAnnotation.taxons[0].taxonomyId
          : null,
        taxon: originalTaxon,
        toBeReviewed: editAnnotation.toBeReviewed,
      };

      updatedForm.attributesSection = {
        attributes: editAnnotation.taxons
          ? Util.convertAttributesToForm(
              editAnnotation.taxons[0].attributes,
              attributeOptions
            ).map((attr) => ({ ...attr, touched: true }))
          : [],
      };

      setFormState(updatedForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAnnotation, attributeOptions]);

  useEffect(() => {
    // on successful update, update the matching table row
    if (updateResponse) {
      const {
        annotationId,
        resourceType,
        resource,
        startDate,
        endDate,
        flagged,
        annotationContents,
        createdBy,
        createdDate,
        modifiedBy,
        modifiedDate,
      } = updateResponse;
      const taxon = annotationContents.find(
        (line) => line.formField.fieldCode === 'taxon'
      );
      const updatedAnnotation: CompromisedAnnotation = {
        annotationHdrId: annotationId,
        resourceTypeId: resourceType.resourceTypeId,
        resourceTypeName: resourceType.resourceTypeName,
        resourceId: resource.resourceId,
        resourceName: resource.resourceName,
        startDate,
        endDate,
        createdBy: {
          userId: createdBy.dmasUserId,
          firstName: createdBy.firstname,
          lastName: createdBy.lastname,
          email: createdBy.email,
        },
        modifiedBy: {
          userId: modifiedBy.dmasUserId,
          firstName: modifiedBy.firstname,
          lastName: modifiedBy.lastname,
          email: modifiedBy.email,
        },
        createdDate,
        modifiedDate,
        comment: !taxon
          ? annotationContents[0].annotation
          : annotationContents.find(
              (line) => line.formField.fieldCode === 'comment'
            ).annotation,
        toBeReviewed: flagged,
        taxons: taxon
          ? [
              {
                taxonId: taxon.taxonId,
                taxonomyId: taxon.taxonomyId,
                associatedAnnotationLineId: taxon.annotationContentId,
                attributes: taxon.annotationAttributes.map((attr) => ({
                  attributeId: attr.attributeId,
                  dataType: 'String',
                  groupId: 1,
                  groupName: 'Common',
                  name: attr.name,
                  value: attr.value,
                })),
                attributeMap: {},
                taxonUrl: '',
                taxonomyCode: 'CI',
                displayText: lastState.generalSection.taxon.label,
              },
            ]
          : undefined,
      };
      onUpdate(updatedAnnotation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResponse]);

  const handleGeneralSectionChange = useCallback(
    (updatedSection: EntryGeneralSection) => {
      setFormState((prevState) =>
        Util.handleFormSectionChange(
          prevState,
          'generalSection',
          updatedSection
        )
      );
    },
    []
  );

  const handleAttributesSectionChange = useCallback(
    (updatedSection: EntryAttributesSection) => {
      setFormState((prevState) =>
        Util.handleFormSectionChange(
          prevState,
          'attributesSection',
          updatedSection
        )
      );
    },
    []
  );

  const handleTaxonChange = async (val: TaxonOption) => {
    const updatedForm = await Util.handleTaxonFormChange(
      val,
      formState,
      fetchAttributes,
      attributeOptions
    );
    // override potential resource attribute changes to stay
    // with the deviceId
    updatedForm.resourceSection = {
      resourceTypeId: SeaTubeResourceTypes.DEVICE_DATA,
      resourceId: device?.deviceId || editAnnotation?.resourceId,
    };
    setFormState(updatedForm);
  };

  const handleCheckboxChange = (key: string) => {
    const updatedSection = _.cloneDeep(formState.generalSection);
    updatedSection[key] = !updatedSection[key];
    handleGeneralSectionChange(updatedSection);
  };

  const handleSubmit = () => {
    const formErrors = Util.performFormValidation(
      formState,
      undefined,
      undefined,
      false
    );
    setErrors(formErrors);
    if (!_.isEmpty(formErrors)) {
      onError('Could not save annotation. The form contains errors.');
      return;
    }
    const annotation = Util.convertFormToServiceData(formState, {
      annotationSource: 'Oceans 3.0 Users',
      annotationSourceId: 3,
    });
    if (editAnnotation) {
      annotation.annotationId = editAnnotation.annotationHdrId;
    }

    const params = {
      annotation: JSON.stringify(annotation),
      organizationId: ONC_DATA.organizationId,
    };
    editAnnotation ? updateAnnotation(params) : createAnnotation(params);
  };

  const renderCheckboxes = () => (
    <>
      <LabelledCheckbox
        label="Shared"
        value={formState.generalSection.shared}
        onChange={() => handleCheckboxChange('shared')}
      />
      <LabelledCheckbox
        label="Flagged"
        value={formState.generalSection.flagged}
        onChange={() => handleCheckboxChange('flagged')}
      />
    </>
  );

  if (open) {
    return (
      <DraggableToolbox
        title={`${editAnnotation ? 'Edit' : 'Create'} Compromised Instrument Annotation`}
        initiallyExpanded
        onClose={onClose}
        actions={
          <>
            {renderCheckboxes()}
            <ClearButton
              onClick={() =>
                setFormState({
                  ...emptyForm,
                  generalSection: {
                    ...emptyForm.generalSection,
                    taxonomyId: null,
                  },
                })
              }
            />
            <SaveButton
              onClick={handleSubmit}
              disabled={!formState.generalSection.startDate}
            />
          </>
        }
      >
        <ManualEntryResource
          value={{
            resourceTypeId:
              editAnnotation?.resourceTypeId ||
              SeaTubeResourceTypes.DEVICE_DATA,
            resourceId: device?.deviceId || editAnnotation?.resourceId,
          }}
          errors={errors}
          device={
            device || {
              deviceId: editAnnotation?.resourceId,
              deviceName: editAnnotation?.resourceName,
            }
          }
        />
        <ManualEntryGeneral
          value={formState.generalSection}
          onChange={handleGeneralSectionChange}
          onTaxonChange={handleTaxonChange}
          onErrorChange={setErrors}
          errors={errors}
          startAndEndDates={!!device || !!editAnnotation}
        />
        <ManualEntryAttributes
          value={formState.attributesSection}
          attributeOptions={attributeOptions}
          onChange={handleAttributesSectionChange}
          errors={errors}
        />
      </DraggableToolbox>
    );
  }
  return null;
};

export default CompromisedAnnotationEntry;
