import { useState } from 'react';
import * as React from 'react';
import AnnotationEntry from 'domain/AppComponents/annotations/entry/AnnotationEntry';

type Props = {
  initialValues: any;
  selectedAnnotation?: number;
};

const AnnotationEntryPage: React.VFC<Props> = ({
  initialValues,
  selectedAnnotation = undefined,
}) => {
  const [entryOpen, setEntryOpen] = useState(true);
  const handleCloseEntryDialog = () => {
    setEntryOpen(false);
  };

  return (
    <AnnotationEntry
      onClose={handleCloseEntryDialog}
      open={entryOpen}
      initialValues={initialValues}
      selectedAnnotation={selectedAnnotation}
    />
  );
};

export default AnnotationEntryPage;
