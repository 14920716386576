import { FC, ComponentType, useState, useContext, useEffect } from 'react';
import { TextButton, ContainedButton } from '@onc/composite-components';
import {
  CsvIcon,
  ShowChart as PlotIcon,
  Description as CorIcon,
  XmlIcon as MetadataIcon,
  Bookmarks as AnnotationIcon,
} from '@onc/icons';
import {
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
  DevExpressTableHeaderRow as TableHeaderRow,
  Checkbox,
  DialogActions,
  DialogContent,
  StatelessTable,
  TableColumn,
} from 'base-components';
import SelectedStationsContext from 'domain/Apps/geospatial-search/SelectedStationsContext';
import DraggableDialog, {
  DraggableDialogProps,
} from 'library/CompositeComponents/dialog/DraggableDialog';
import { DataProductSelectionRow } from '../../definitions/GeospatialSearchTypes';

const columns: TableColumn[] = [
  { name: 'castName', title: 'Cast Name', dataType: 'String' },
  { name: 'startDate', title: 'Start Date', dataType: 'Date' },
  { name: 'endDate', title: 'End Date', dataType: 'Date' },
  { name: 'qaqc', title: 'QAQC', dataType: 'String' },
  { name: 'csv', title: 'CSV', dataType: 'Other' },
  { name: 'cor', title: 'Description', dataType: 'String' },
  { name: 'annotation', title: 'Annotation', dataType: 'String' },
  { name: 'plot', title: 'Plot', dataType: 'String' },
  { name: 'metadata', title: 'Metadata', dataType: 'String' },
];

const columnExtensions: any = [
  { columnName: 'castName', width: 200, align: 'left' },
  { columnName: 'startDate', width: 200, align: 'left' },
  { columnName: 'endDate', width: 200, align: 'left' },
  { columnName: 'qaqc', width: 150, align: 'left' },
  { columnName: 'csv', width: 50, align: 'center' },
  { columnName: 'cor', width: 50, align: 'center' },
  { columnName: 'annotation', width: 50, align: 'center' },
  { columnName: 'plot', width: 50, align: 'center' },
  { columnName: 'metadata', width: 50, align: 'center' },
];

/**
 * Custom Cell renderer for the table header row in StatelessTable. Allows
 * rendering icons for some columns
 *
 * @returns Custom <TableHeaderRow.Cell/>. Icon for data products and default
 *   for the rest
 */
const customHeaderRow: ComponentType<any> = ({
  /* Column info from dev express */
  column,
  ...rest
}) => {
  const renderIcon = (icon) => (
    <TableHeaderRow.Cell column={column} {...rest}>
      {icon}
    </TableHeaderRow.Cell>
  );

  switch (column.name) {
    case 'csv':
      return renderIcon(<CsvIcon color="primary" />);
    case 'cor':
      return renderIcon(<CorIcon color="primary" />);
    case 'annotation':
      return renderIcon(<AnnotationIcon color="primary" />);
    case 'plot':
      return renderIcon(<PlotIcon color="primary" />);
    case 'metadata':
      return renderIcon(<MetadataIcon color="primary" />);
    default:
      return <TableHeaderRow.Cell column={column} {...rest} />;
  }
};

/**
 * Dialog to select wanted data products for a selection of casts. Creates a
 * checkbox matrix to allow each product to be selected
 */
interface DataProductSelectionProps
  extends Omit<DraggableDialogProps, 'title'> {
  onGenerateDataProducts: (selectedRows: DataProductSelectionRow[]) => void;
  updateSiteDeviceSubsetRowsContextFromCheckboxState: (
    checkboxState: any
  ) => void;
}

const DataProductSelection: FC<DataProductSelectionProps> = ({
  open,
  onClose,
  onGenerateDataProducts,
  updateSiteDeviceSubsetRowsContextFromCheckboxState,
}) => {
  const { selectedStations, getCheckboxStateArrayMapFromSelectedStations } =
    useContext(SelectedStationsContext);

  const defaultWidths = columnExtensions.map(
    ({ align, ...rest }) => rest as TableColumnWidthInfo
  );

  const [columnWidths, setColumnWidths] =
    useState<TableColumnWidthInfo[]>(defaultWidths);

  const [checkboxState, setCheckboxState] = useState<DataProductSelectionRow[]>(
    []
  );

  useEffect(() => {
    /* Create an initial state for each selectedStation. The state will be in the form */
    setCheckboxState(getCheckboxStateArrayMapFromSelectedStations(false));
  }, [getCheckboxStateArrayMapFromSelectedStations, selectedStations]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const [field, siteDeviceSubsetId] = name.split('_');

    setCheckboxState((prevState = []) =>
      prevState.map((state) =>
        state.siteDeviceSubsetId === parseInt(siteDeviceSubsetId, 10)
          ? { ...state, [field]: checked }
          : state
      )
    );
  };

  // TODO: Extract the checkbox array into a self managed component
  const rows = selectedStations
    .flatMap((station) => station.siteDeviceSubsets)
    .map((siteDeviceSubset) => ({
      key: siteDeviceSubset.siteDeviceSubsetId,
      castName: siteDeviceSubset.siteDeviceSubsetName,
      startDate: siteDeviceSubset.startDate.toString(),
      endDate: siteDeviceSubset.endDate.toString(),
      qaqc: 'Manual: Completed',
      csv: (
        <Checkbox
          inputProps={{
            'aria-label': 'CSV Checkbox',
            name: `csv_${siteDeviceSubset.siteDeviceSubsetId}`,
          }}
          checked={
            checkboxState?.[`csv_${siteDeviceSubset.siteDeviceSubsetId}`]
          }
          onChange={handleCheckboxChange}
        />
      ),
      cor: (
        <Checkbox
          inputProps={{
            'aria-label': 'COR Checkbox',
            name: `cor_${siteDeviceSubset.siteDeviceSubsetId}`,
          }}
          checked={
            checkboxState?.[`cor_${siteDeviceSubset.siteDeviceSubsetId}`]
          }
          onChange={handleCheckboxChange}
        />
      ),
      plot: (
        <Checkbox
          inputProps={{
            'aria-label': 'Plot Checkbox',
            name: `plot_${siteDeviceSubset.siteDeviceSubsetId}`,
          }}
          checked={
            checkboxState?.[`plot_${siteDeviceSubset.siteDeviceSubsetId}`]
          }
          onChange={handleCheckboxChange}
        />
      ),
      annotation: (
        <Checkbox
          inputProps={{
            'aria-label': 'Annotation Checkbox',
            name: `annotation_${siteDeviceSubset.siteDeviceSubsetId}`,
          }}
          checked={
            checkboxState?.[`annotation_${siteDeviceSubset.siteDeviceSubsetId}`]
          }
          onChange={handleCheckboxChange}
        />
      ),
      metadata: (
        <Checkbox
          inputProps={{
            'aria-label': 'Metadata Checkbox',
            name: `metadata_${siteDeviceSubset.siteDeviceSubsetId}`,
          }}
          checked={
            checkboxState?.[`metadata_${siteDeviceSubset.siteDeviceSubsetId}`]
          }
          onChange={handleCheckboxChange}
        />
      ),
    }));

  const handleGenerateClick = () => {
    updateSiteDeviceSubsetRowsContextFromCheckboxState(checkboxState);
    onGenerateDataProducts(checkboxState);
  };

  return (
    <DraggableDialog
      open={open}
      title="Data Product Selection"
      onClose={onClose}
    >
      <DialogContent>
        <StatelessTable
          columns={columns}
          columnExtensions={columnExtensions}
          resize={{
            columnWidths,
            handleColumnWidths: () => setColumnWidths,
          }}
          rows={rows}
          headerCellComponent={customHeaderRow}
        />
      </DialogContent>
      <DialogActions>
        <TextButton translationKey="common.buttons.cancel" onClick={onClose} />
        <ContainedButton
          translationKey="communityFishers.generate"
          onClick={handleGenerateClick}
        />
      </DialogActions>
    </DraggableDialog>
  );
};
export default DataProductSelection;
