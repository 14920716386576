import { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import {
  StatelessTable,
  TableColumn,
  TableFilter,
  getEmptyFilter,
  TableFilterLogic,
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
} from 'base-components';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import Environment from 'util/Environment';
import { useLocalStorage } from 'util/hooks/useStorage';

export type SiteDeviceTableRow = {
  rowId: string;
  deviceId: JSX.Element;
  deviceName: string;
  siteDeviceId: JSX.Element;
  siteName: string;
  dateFrom: string;
  comment: string;
  modifyBy: string;
  modifyDate: string;
};

interface ResultsProps {
  rows: SiteDeviceTableRow[];
  selection: string[];
  setSelection: (selectedRows: string[]) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      height: '650px',
    },
  })
);

const formatDate = (date: string) => {
  if (!date) return undefined;
  const strArr = date.split('T');
  strArr[1] = strArr[1].substring(0, 8);
  return `${strArr[0]} ${strArr[1]}`;
};

export const transformSiteDeviceToRow = (record): SiteDeviceTableRow => ({
  rowId: `${record.deviceId}-${record.siteDeviceId}`,
  deviceId: (
    <OpenInNewLink
      href={`${Environment.getDmasUrl()}/DeviceListing?DeviceId=${record.deviceId}`}
    >
      {record.deviceId}
    </OpenInNewLink>
  ),
  deviceName: record.deviceName,
  siteDeviceId: record.siteDeviceId ? (
    <OpenInNewLink
      href={`${Environment.getDmasUrl()}/SiteDevice?siteDeviceId=${record.siteDeviceId}`}
    >
      {record.siteDeviceId}
    </OpenInNewLink>
  ) : undefined,
  siteName: record.siteName,
  dateFrom: formatDate(record.dateFrom),
  comment: record.comment,
  modifyBy: record.modifyBy,
  modifyDate: formatDate(record.modifyDate),
});

const DeviceBulkUpdateSiteDeviceTable = ({
  rows,
  selection,
  setSelection,
}: ResultsProps) => {
  const classes = useStyles();
  const permission = Environment.getDmasUserPrivilege();

  const storageKey = 'device-bulk-update-sitedevice-table';
  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'deviceId', direction: 'asc' }],
  };

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  // Filtering
  const initialFilter = config.filter;
  const [filter, setFilter] = useState<TableFilter>(
    initialFilter ? _.cloneDeep(config.filter) : _.cloneDeep(getEmptyFilter())
  );

  const handleSelectRow = (selectedRows: string[]) => {
    setSelection(selectedRows);
  };

  const columns: TableColumn[] = [
    {
      name: 'deviceId',
      title: 'Device Id',
      dataType: 'Number',
    },
    {
      name: 'deviceName',
      title: 'Device Name',
      dataType: 'String',
    },
    {
      name: 'siteDeviceId',
      title: 'Site Device Id',
      dataType: 'Number',
    },
    {
      name: 'siteName',
      title: 'Site Name',
      dataType: 'String',
    },
    {
      name: 'dateFrom',
      title: 'Start Date',
      dataType: 'Date',
    },
    {
      name: 'comment',
      title: 'Comment',
      dataType: 'String',
    },
    {
      name: 'modifyBy',
      title: 'Modify By',
      dataType: 'String',
    },
    {
      name: 'modifyDate',
      title: 'Modify Date',
      dataType: 'Date',
    },
  ];

  const columnWidths: TableColumnWidthInfo[] = [
    {
      columnName: 'deviceId',
      width: ColumnInfo.small,
    },
    {
      columnName: 'deviceName',
      width: ColumnInfo.large,
    },
    {
      columnName: 'siteDeviceId',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'siteName',
      width: ColumnInfo.large,
    },
    {
      columnName: 'dateFrom',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'comment',
      width: ColumnInfo.large,
    },
    {
      columnName: 'modifyBy',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'modifyDate',
      width: ColumnInfo.medium,
    },
  ];

  return (
    <StatelessTable
      className={classes.table}
      rows={rows === null ? [] : rows}
      columns={columns}
      getRowId={(row) => row.rowId}
      permission={permission}
      searchable
      visible={{ hiddenColumnNames: [] }}
      resize={{
        columnWidths,
      }}
      sort={{ sorting, customSorting: [], handleSortingChange: setSorting }}
      filter={{
        filterValue: filter,
        onChange: setFilter,
        filterFn: TableFilterLogic.defaultFilter,
        hidden: false,
      }}
      selection={{
        selection,
        onChange: handleSelectRow,
        selectByRowClick: false,
        highlightRow: true,
        showSelectionColumn: true,
        showSelectAll: true,
      }}
    />
  );
};

export default DeviceBulkUpdateSiteDeviceTable;
