/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { ErrorAlert, Loading } from '@onc/composite-components';
import { UserDetailsService } from '@onc/domain-services';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import SavePlaylistDialog from 'domain/AppComponents/playlist/SavePlaylistDialog';
import AddCollectionDialog from 'domain/AppComponents/sea-tube/playlists/dialogs/AddCollectionDialog';
import EditCollectionDialog from 'domain/AppComponents/sea-tube/playlists/dialogs/EditCollectionDialog';
import PlaylistCollectionPanel from 'domain/AppComponents/sea-tube/playlists/PlaylistCollectionPanel';
import PlaylistTable from 'domain/AppComponents/sea-tube/playlists/PlaylistTable';
import UserDetailsContext, {
  UserContext,
} from 'domain/AppComponents/sea-tube/UserDetailsContext';
import PlaylistService, { Playlist } from 'domain/services/PlaylistService';

import Share from 'library/CompositeComponents/share/Share';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import DeletePlaylistDialog from '../playlist-management/dialogs/DeletePlaylistDialog';
import DownloadClipsDialog from '../playlist-management/dialogs/DownloadClipsDialog';
import EditPlaylistDialog from '../playlist-management/dialogs/EditPlaylistDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(),
  },
  groupPanel: {
    minWidth: 300,
    marginRight: theme.spacing(),
  },
  table: {
    flexShrink: 1,
  },
}));

const PlaylistsApp: React.VFC = () => {
  const { onInfo, onError } = useSnackbars();
  const classes = useStyles();

  const [playlists, loadingPlaylists, fetchPlaylists] = useWebService({
    method: PlaylistService.getPlaylists,
    onError,
  });

  const [isAddGroupOpen, setAddGroupOpen] = useState(false);
  const [isDeleteGroupOpen, setDeleteGroupOpen] = useState(false);
  const [isDeletePlaylistOpen, setDeletePlaylistOpen] = useState(false);
  const [isDownloadOpen, setDownloadOpen] = useState(false);
  const [isEditPlaylistOpen, setEditPlaylistOpen] = useState(false);
  const [isClonePlaylistOpen, setClonePlaylistOpen] = useState(false);
  const [isEditCollectionOpen, setEditCollectionOpen] = useState(false);

  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const [currentPlaylist, setCurrentPlaylist] = useState<Playlist | null>(null);
  const [groupOptions, setGroupOptions] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState('All Playlists');

  const [, waitingForDelete, deleteGroup] = useWebService({
    method: PlaylistService.updateGroupNameMultiplePlaylists,
    onError,
  });

  const handleUserData = (data: string): UserContext => ({
    userId: Number(data),
  });

  const [userId, , fetchUserId] = useWebService({
    method: UserDetailsService.getUserId,
    parser: handleUserData,
    onError,
  });

  const handleDeleteGroup = () => {
    deleteGroup({
      groupName: '',
      playlistHdrIds: playlists
        ?.filter((playlist) => playlist.groupName === selectedGroup)
        ?.map((playlist) => playlist.playlistHdrId),
    })
      .then(() => fetchPlaylists())
      .then(() => {
        setDeleteGroupOpen(false);
        setSelectedGroup('All Playlists');
        onInfo(`Group "${selectedGroup}" deleted.`);
      });
  };

  const handleDownloadPlaylist = (playlist: Playlist) => {
    setDownloadOpen(true);
    setCurrentPlaylist(playlist);
  };

  const handleEditPlaylist = (playlist: Playlist) => {
    setEditPlaylistOpen(true);
    setCurrentPlaylist(playlist);
  };

  useEffect(() => {
    fetchPlaylists();
    fetchUserId();
  }, []);

  useEffect(() => {
    if (playlists) {
      setGroupOptions([
        ...new Set(
          playlists
            .map((playlist) => playlist.groupName)
            .filter((item) => typeof item === 'string')
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        ),
      ]);
    }
  }, [playlists]);

  const handleOpenShareMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    playlist: Playlist
  ) => {
    setShareAnchorEl(event.currentTarget);
    setCurrentPlaylist(playlist);
  };

  const handleOpenDeletePlaylist = (playlist: Playlist) => {
    setDeletePlaylistOpen(true);
    setCurrentPlaylist(playlist);
  };

  const handleOpenClonePlaylist = (playlist: Playlist) => {
    setClonePlaylistOpen(true);
    setCurrentPlaylist(playlist);
  };

  const handleCloseClonePlaylist = () => {
    setClonePlaylistOpen(false);
    fetchPlaylists();
  };

  const handleRefresh = () => {
    fetchPlaylists();
  };

  const renderContent = () => {
    if (playlists === null) {
      return (
        <ErrorAlert title="Error">
          There was a problem getting the playlists.
        </ErrorAlert>
      );
    }

    return (
      <div className={classes.container}>
        <PlaylistCollectionPanel
          groups={groupOptions}
          onAddGroup={() => setAddGroupOpen(true)}
          selectedGroup={selectedGroup}
          onSelectGroup={setSelectedGroup}
          onEditGroup={(group: string) => {
            setSelectedGroup(group);
            setEditCollectionOpen(true);
          }}
          onDeleteGroup={(group: string) => {
            setSelectedGroup(group);
            setDeleteGroupOpen(true);
          }}
          className={classes.groupPanel}
          playlists={playlists || []}
        />
        <div className={classes.table}>
          <PlaylistTable
            playlists={playlists || []}
            selectedGroup={selectedGroup}
            onOpenDeleteForm={handleOpenDeletePlaylist}
            onClone={handleOpenClonePlaylist}
            onDownload={handleDownloadPlaylist}
            onShare={handleOpenShareMenu}
            onEdit={handleEditPlaylist}
            onRefresh={handleRefresh}
          />
        </div>
      </div>
    );
  };

  if (playlists === undefined || userId === undefined) {
    return <Loading />;
  }

  return (
    <div
      style={{
        backgroundColor: '#EEE',
        height: '100%',
      }}
    >
      <UserDetailsContext.Provider value={userId}>
        <Share
          open={Boolean(shareAnchorEl)}
          title="Check out this playlist!"
          onClose={() => setShareAnchorEl(null)}
          popoverAnchorEl={shareAnchorEl}
          playlistHdrId={currentPlaylist?.playlistHdrId}
          shareUrl={`${Environment.getLinkUrl()}/app/playlists/${currentPlaylist?.playlistHdrId}`}
        />
        {isDeletePlaylistOpen ? (
          <DeletePlaylistDialog
            playlistHdrId={currentPlaylist.playlistHdrId}
            playlistHdrName={currentPlaylist.name}
            open={isDeletePlaylistOpen}
            closeDeleteForm={() => setDeletePlaylistOpen(false)}
            actionAfterDelete={fetchPlaylists}
          />
        ) : undefined}

        <DownloadClipsDialog
          open={isDownloadOpen}
          playlistHdrId={currentPlaylist?.playlistHdrId}
          onCancel={() => setDownloadOpen(false)}
          selectedClips={currentPlaylist?.playlistLines || []}
          playlistName={currentPlaylist?.name}
        />

        <EditPlaylistDialog
          open={isEditPlaylistOpen}
          playlistHdrId={currentPlaylist?.playlistHdrId}
          playlistName={currentPlaylist?.name}
          description={currentPlaylist?.description}
          groupName={currentPlaylist?.groupName}
          closeEditDialog={() => setEditPlaylistOpen(false)}
          actionAfterEdit={fetchPlaylists}
          groupOptions={groupOptions}
        />
        <SavePlaylistDialog
          open={isClonePlaylistOpen}
          onClose={handleCloseClonePlaylist}
          playlistLines={currentPlaylist?.playlistLines}
          playlistName={currentPlaylist?.name}
          playlistDescription={currentPlaylist?.description}
          onInfo={onInfo}
          onError={onError}
          isCloneAction
        />
        <AddCollectionDialog
          open={isAddGroupOpen}
          groups={groupOptions}
          playlists={playlists || []}
          onClose={() => setAddGroupOpen(false)}
          actionAfterAddGroup={fetchPlaylists}
        />
        <EditCollectionDialog
          open={isEditCollectionOpen}
          groups={groupOptions}
          groupName={selectedGroup}
          playlists={playlists || []}
          onSelectGroup={setSelectedGroup}
          onClose={() => setEditCollectionOpen(false)}
          onRefresh={fetchPlaylists}
        />
        <DeleteDialog
          open={isDeleteGroupOpen}
          title={`Delete "${selectedGroup}"?`}
          message="This will not delete the playlists in this group.  They will be moved to (Uncategorized)"
          onCancel={() => setDeleteGroupOpen(false)}
          onDelete={handleDeleteGroup}
          loading={waitingForDelete || loadingPlaylists}
        />
        {renderContent()}
      </UserDetailsContext.Provider>
    </div>
  );
};

export default withSnackbars(PlaylistsApp);
