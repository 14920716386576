import { useEffect, useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import { TextButton } from '@onc/composite-components';
import {
  DateTimePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dropdown,
  FormControlLabel,
  RadioButton,
  RadioGroup,
  Typography,
} from 'base-components';
import { type AgreementSiteDeviceRow } from 'domain/Apps/agreement-site-devices/AgreementSiteDevicesTable';
import AgreementSiteDeviceService from 'domain/services/AgreementSiteDeviceService';
import * as LicenceService from 'domain/services/LicenceService';
import DateFormatUtils from 'util/DateFormatUtils';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { AgreementSiteDeviceDate } from './AgreementSiteDeviceComponents';

type EditAgreementSiteDeviceDialogProps = {
  row: AgreementSiteDeviceRow;
  onClose: (refresh?: boolean) => void;
};

type DateValues = {
  original?: Date;
  siteDevice?: Date;
  minmax?: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      flexGrow: 1,
    },
    sectionHeader: {
      fontWeight: 'bold',
    },
    gridItem: {
      padding: theme.spacing(1),
    },
    licenceDropdown: {
      margin: theme.spacing(1),
    },
    defaultMenuItem: {
      fontStyle: 'italic',
      fontSize: '0.875rem',
    },
  })
);

const EditAgreementSiteDeviceDialog: React.FC<
  EditAgreementSiteDeviceDialogProps
> = ({ row, onClose }) => {
  enum RadioState {
    Custom = 'custom',
    Minimum = 'minimum',
    Maximum = 'maximum',
  }

  const [startDateRadioValue, setStartDateRadioValue] = useState<string>();
  const [endDateRadioValue, setEndDateRadioValue] = useState<string>();
  const [customStartDate, setCustomStartDate] = useState<Date>();
  const [customEndDate, setCustomEndDate] = useState<Date>();
  const [from, setFrom] = useState<DateValues>();
  const [to, setTo] = useState<DateValues>();
  const [licenceFormValue, setLicenceFormValue] = useState(
    row.organizationAgreementResource.licence?.licenceId ?? 0
  );

  const { data: licenceList, isLoading } = LicenceService.useFetchAll();

  const { onInfo, onError } = useSnackbars();
  const classes = useStyles();

  useEffect(() => {
    if (row) {
      const {
        organizationAgreementResource: { startDate, endDate },
        siteDevice: { dateFrom, dateTo },
        minStartDate,
        maxEndDate,
      } = row;
      setFrom({
        original: startDate,
        siteDevice: dateFrom,
        minmax: minStartDate,
      });
      setTo({
        original: endDate,
        siteDevice: dateTo,
        minmax: maxEndDate,
      });
    }
  }, [row, setFrom, setTo]);

  useEffect(() => {
    if (row) {
      const {
        organizationAgreementResource: { startDate, endDate },
        siteDevice: { dateFrom, dateTo },
      } = row;
      setCustomStartDate(startDate || dateFrom);
      setCustomEndDate(endDate || dateTo);
      setStartDateRadioValue(
        startDate ? RadioState.Custom : RadioState.Minimum
      );
      setEndDateRadioValue(endDate ? RadioState.Custom : RadioState.Maximum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const handleClose = (refresh?: boolean) => {
    onClose(refresh);
    setCustomStartDate(undefined);
    setCustomEndDate(undefined);
    setStartDateRadioValue(RadioState.Custom);
    setEndDateRadioValue(RadioState.Custom);
  };

  const handleUpdate = () => {
    const beforeMinDate =
      startDateRadioValue === RadioState.Custom &&
      customStartDate &&
      from.minmax &&
      customStartDate < from.minmax;
    const afterMaxDate =
      endDateRadioValue === RadioState.Custom &&
      customEndDate &&
      to.minmax &&
      customEndDate > to.minmax;
    const beforeSiteDeviceDateFrom =
      startDateRadioValue === RadioState.Custom &&
      customStartDate &&
      customStartDate < from.siteDevice;
    const afterSiteDeviceDateTo =
      endDateRadioValue === RadioState.Custom &&
      customEndDate &&
      to.siteDevice &&
      customEndDate > to.siteDevice;
    if (
      beforeMinDate ||
      afterMaxDate ||
      beforeSiteDeviceDateFrom ||
      afterSiteDeviceDateTo
    ) {
      const rangeStart = DateFormatUtils.formatDate(
        from.minmax || from.siteDevice,
        'full'
      );
      const rangeEnd =
        to.minmax || to.siteDevice
          ? DateFormatUtils.formatDate(to.minmax || to.siteDevice, 'full')
          : 'Now';
      onError(`Dates must be within the range: ${rangeStart} - ${rangeEnd}`);
      return;
    }

    AgreementSiteDeviceService.update({
      organizationAgreementResourceId: row?.id,
      startDate:
        startDateRadioValue === RadioState.Minimum
          ? from.minmax
          : customStartDate,
      endDate:
        endDateRadioValue === RadioState.Maximum ? to.minmax : customEndDate,
      // OR operator (||) is used rather than nullish coalescing operator (??) so that if licenceFormValue is 0 then updated value is null per default value of the dropdown
      licenceId: licenceFormValue || null,
    })
      .then(() => {
        handleClose(true);
        onInfo('Updated successfully');
      })
      .catch((e) => onError(e.message));
  };

  return row ? (
    <Dialog open>
      <DialogTitle>
        {`Edit Organization Agreement Resource ID: ${row.id}`}
      </DialogTitle>
      <DialogContent>
        <>
          <Typography className={classes.sectionHeader}>Licence</Typography>
          {isLoading ? (
            <Typography>Loading licence options...</Typography>
          ) : (
            <Dropdown
              label="Licence"
              value={licenceFormValue}
              onChange={({ target: { value } }) => {
                setLicenceFormValue(isNaN(Number(value)) ? 0 : Number(value));
              }}
              options={[
                {
                  key: 'default',
                  value: 0,
                  label: `Agreement Default (${row.organizationAgreementResource.organizationAgreement.licence.licenceName})`,
                },
                ...licenceList.map(({ licenceId, licenceName }) => ({
                  key: String(licenceId),
                  value: licenceId,
                  label: licenceName,
                })),
              ]}
              fullWidth
              className={classes.licenceDropdown}
            />
          )}
          <Typography className={classes.sectionHeader}>Start Date</Typography>
          <RadioGroup
            value={startDateRadioValue}
            onChange={(event) => setStartDateRadioValue(event.target.value)}
          >
            <FormControlLabel
              value={RadioState.Minimum}
              control={<RadioButton color="primary" />}
              label={
                <div>
                  <AgreementSiteDeviceDate
                    date={from?.minmax}
                    siteDeviceDate={from?.siteDevice}
                    label="Minimum"
                    variant="form"
                  />
                </div>
              }
              labelPlacement="end"
              className={classes.gridItem}
            />
            <FormControlLabel
              value={RadioState.Custom}
              control={<RadioButton color="primary" />}
              label={
                <DateTimePicker
                  translationKey="common.datepickers.startDate"
                  onChange={(date) => {
                    setCustomStartDate(date.local().toDate());
                  }}
                  value={moment.utc(customStartDate)}
                  className={classes.datePicker}
                  disabled={startDateRadioValue === 'minimum'}
                />
              }
              labelPlacement="end"
              className={classes.gridItem}
            />
          </RadioGroup>
          <Typography className={classes.sectionHeader}>End Date</Typography>
          <RadioGroup
            value={endDateRadioValue}
            onChange={(event) => setEndDateRadioValue(event.target.value)}
          >
            <FormControlLabel
              value={RadioState.Maximum}
              control={<RadioButton size="small" color="primary" />}
              label={
                <AgreementSiteDeviceDate
                  date={to?.minmax}
                  siteDeviceDate={to?.siteDevice}
                  label="Maximum"
                  variant="form"
                />
              }
              labelPlacement="end"
              className={classes.gridItem}
            />
            <FormControlLabel
              value={RadioState.Custom}
              control={<RadioButton size="small" color="primary" />}
              label={
                <DateTimePicker
                  translationKey="common.datepickers.endDate"
                  onChange={(date) => {
                    setCustomEndDate(date.local().toDate());
                  }}
                  value={moment.utc(customEndDate)}
                />
              }
              labelPlacement="end"
              className={classes.gridItem}
            />
          </RadioGroup>
        </>
      </DialogContent>
      <DialogActions>
        <TextButton
          translationKey="common.buttons.update"
          onClick={handleUpdate}
        />
        <TextButton
          translationKey="common.buttons.close"
          onClick={() => handleClose(false)}
          aria-label="close dialog"
        />
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
};

export default EditAgreementSiteDeviceDialog;
