import { Component } from 'react';
import TableCell from '@mui/material/TableCell';
import { DevExpressTableFilterRowCell as TableFilterRowCell } from 'base-components';

type TablePanelProps = {
  column: {
    name: string;
  };
};
class TabPanel extends Component<TablePanelProps> {
  render() {
    const { column } = this.props;
    if (column.name === 'icons') return <TableCell />;
    // @ts-expect-error Passed in from parent
    return <TableFilterRowCell {...this.props} />;
  }
}

export default TabPanel;
