import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Grid } from 'base-components';
import UserOrganizationDialog from 'domain/AppComponents/user-organization-listing/UserOrganizationDialog';
import UserOrganizationList from 'domain/AppComponents/user-organization-listing/UserOrganizationList';
import UserOrganizationTable from 'domain/AppComponents/user-organization-listing/UserOrganizationTable';
import {
  transformUserOrganizationRows,
  UserOrganizationRow,
} from 'domain/AppComponents/user-organization-listing/UserOrganizationTableLogic';
import UserOrganizationService from 'domain/services/UserOrganizationService';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';
import Panel from 'library/CompositeComponents/panel/Panel';
import Environment from 'util/Environment';
import SnackbarProvider, { useSnackbars } from 'util/hooks/useSnackbars';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  rightPane: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    overflowY: 'auto',
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  detailsContainer: {
    maxWidth: '1200px',
  },
  loader: {
    position: 'fixed',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const UserOrganizationListingPage: React.FC = () => {
  const classes = useStyles();
  const { onError, onInfo } = useSnackbars();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedUserOrganization, setSelectedUserOrganization] =
    useState<UserOrganizationRow | null>(null);
  const [userOrganizations, setUserOrganizations] = useState<
    UserOrganizationRow[]
  >([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState<
    string | null
  >(null);

  const permission = Environment.getDmasUserPrivilege();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedOrgId !== null) {
      setLoading(true);
      setUserOrganizations([]);

      UserOrganizationService.getUsersForOrganization(selectedOrgId)
        .then((response) => {
          setUserOrganizations(transformUserOrganizationRows(response));
        })
        .catch(() => {
          onError('Failed to fetch users for the selected organization');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedOrgId, onError]);

  const handleOrganizationClick = (orgId: number, orgName: string) => {
    setSelectedOrgId(orgId);
    setSelectedOrganizationName(orgName);
  };

  const handleCreateClick = () => {
    setSelectedUserOrganization(null);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedUserOrganization(null);
  };

  const handleSuccess = () => {
    if (selectedOrgId !== null) {
      UserOrganizationService.getUsersForOrganization(selectedOrgId)
        .then((response) =>
          setUserOrganizations(transformUserOrganizationRows(response))
        )
        .catch(() =>
          onError('Failed to refresh users for the selected organization')
        );
    }
    setDialogOpen(false);
  };

  const handleDeleteClick = (row: UserOrganizationRow) => {
    UserOrganizationService.deleteUserOrganization(
      row.userId,
      row.organizationId
    )
      .then(() => {
        onInfo('Deleted User Organization successfully!');
        handleSuccess();
      })
      .catch(onError);
  };

  return (
    <SnackbarProvider>
      <PageWithPadding>
        <Grid container className={classes.root}>
          {/* Left Pane: Organization List */}
          <UserOrganizationList onOrganizationClick={handleOrganizationClick} />

          {/* Right Pane: Details Container */}
          <Grid item xs={12} sm={8} md={9} className={classes.rightPane}>
            <Panel className={classes.detailsContainer}>
              {selectedOrgId && loading && (
                <CircularProgress size={120} className={classes.loader} />
              )}

              {selectedOrgId && !loading && (
                <UserOrganizationTable
                  rows={userOrganizations}
                  permission={permission}
                  onDelete={handleDeleteClick}
                  onCreate={handleCreateClick}
                />
              )}
            </Panel>

            {/* Dialog Component */}
            {isDialogOpen && selectedOrgId && selectedOrganizationName && (
              <UserOrganizationDialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                initialValue={selectedUserOrganization}
                onSuccess={handleSuccess}
                selectedOrganization={{
                  organizationId: selectedOrgId,
                  organizationName: selectedOrganizationName,
                }}
              />
            )}
          </Grid>
        </Grid>
      </PageWithPadding>
    </SnackbarProvider>
  );
};

export default UserOrganizationListingPage;
