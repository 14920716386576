import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ExpandMore as ExpandMoreIcon } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LoadingButton,
  Paper,
  StatelessTable,
  Typography,
} from 'base-components';
import DeviceGeneralTabService, {
  Device,
} from 'domain/services/DeviceGeneralTabService';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Environment from 'util/Environment';
import useWebService from 'util/hooks/useWebService';
import GeneralTabForm from './GeneralTabForm';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(),
    margin: theme.spacing(),
  },
  accordionDetails: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  accordionSummary: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  ippContainer: {
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  ippTable: {
    overflow: 'auto',
  },
}));

type Props = {
  deviceId: string;
  device: Device;
  permission: string;
  clonePrivilege: string;
  fetchDevice: (number) => void;
  onInfo: (message: string) => void;
  onError: (message: string) => void;
};

const GeneralTabPanel = ({
  deviceId,
  device,
  permission,
  clonePrivilege,
  fetchDevice,
  onInfo,
  onError,
}: Props) => {
  const classes = useStyles();

  const [allFormFields, setAllFormFields] = useState<Device>(device);

  const [, loadingUpdate, updateDevice] = useWebService({
    method: DeviceGeneralTabService.updateDevice,
  });

  const [, loadingClone, cloneDevice] = useWebService({
    method: DeviceGeneralTabService.cloneDevice,
  });

  const [, loadingDelete, deleteClone] = useWebService({
    method: DeviceGeneralTabService.deleteClone,
  });

  const handleChange = (key, val) => {
    setAllFormFields((prev) => ({ ...prev, [key]: val }));
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    updateDevice(Number(deviceId), allFormFields)
      .then((response) => {
        if (response) {
          fetchDevice(Number(deviceId));
          onInfo('Updated successfully');
        }
      })
      .catch((err) => {
        onError(err);
      });
  };

  const handleClone = (event) => {
    event.preventDefault();
    cloneDevice(Number(deviceId), allFormFields).then((response) => {
      if (response) {
        window.open(
          `${Environment.getDmasUrl()}/DeviceListing?DeviceId=${response.deviceId}#general_tab`,
          '_self'
        );
        onInfo('Cloned successfully');
      }
    });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteClone(Number(deviceId), allFormFields)
      .then((response) => {
        if (response) {
          window.open(`${Environment.getDmasUrl()}/DeviceListing`, '_self');
          onInfo('Deleted clone successfully');
        }
      })
      .catch((err) => {
        onError(err);
      });
  };

  const renderDeleteCloneButton = () => {
    if (
      permission === 'RW' &&
      clonePrivilege === 'RW' &&
      device.deviceCode.includes('CLONE_TMP_CODE')
    ) {
      return (
        <LoadingButton
          loading={loadingDelete}
          translationKey="device.deleteCloneDevice"
          onClick={handleDelete}
          variant="outlined"
        />
      );
    }
    return null;
  };

  const renderCloneButton = () => {
    if (permission === 'RW' && clonePrivilege === 'RW') {
      return (
        <LoadingButton
          loading={loadingClone}
          translationKey="common.buttons.clone"
          onClick={handleClone}
          variant="outlined"
        />
      );
    }
    return null;
  };

  const renderUpdateButton = () => {
    if (permission === 'RW') {
      return (
        <LoadingButton
          loading={loadingUpdate}
          translationKey="common.buttons.update"
          onClick={handleUpdate}
          variant="contained"
        />
      );
    }
    return null;
  };

  const renderIppGroup = () => {
    if (allFormFields.ippGroupName === undefined) {
      return (
        <Typography variant="body1">
          No associated Instrument Point People Group
        </Typography>
      );
    }
    return (
      <div className={classes.ippContainer}>
        <StatelessTable
          className={classes.ippTable}
          title={allFormFields.ippGroupName}
          rows={allFormFields.ippGroupMembers}
          columns={[
            { name: 'name', title: 'Contact Name' },
            { name: 'department', title: 'Department/Organization' },
            { name: 'contactOrder', title: 'Contact Order' },
          ]}
        />
        <OpenInNewLink href="https://www.oceannetworks.ca/contact-us/">
          <Typography variant="body2">Contact Us</Typography>
        </OpenInNewLink>
      </div>
    );
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item sm={12} md={5} xl={5}>
        <Paper className={classes.paper}>
          <GeneralTabForm
            onChange={handleChange}
            onSubmit={handleUpdate}
            value={allFormFields}
            permission={Environment.getDmasUserPrivilege()}
          />
          <div className={classes.buttonContainer}>
            {renderDeleteCloneButton()}
            {renderCloneButton()}
            {renderUpdateButton()}
          </div>
        </Paper>
      </Grid>
      <Grid item sm={12} md={5} xl={4}>
        <Accordion>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Instrument Point People Group</Typography>
          </AccordionSummary>
          <AccordionDetails>{renderIppGroup()}</AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default GeneralTabPanel;
