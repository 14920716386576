import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import ImageWidgetDisplay from 'domain/AppComponents/Dashboard/ImageWidgetDisplay';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import ImageWidgetConfigContent, {
  type ImageWidgetConfigType,
} from './Config/ImageWidgetConfigContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      paddingTop: theme.spacing(2),
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '100%',
      maxHeight: '96%',
    },
  })
);

export const defaultImageConfig: ImageWidgetConfigType = {
  imageUrl: 'https://farm6.staticflickr.com/5829/22337703739_373e6db366_z.jpg',
  showTitle: true,
  title: 'Sample Image',
};

const ImageWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, initialConfig } = props;
  const { config, setConfig } = useWidgetConfig<ImageWidgetConfigType>(id, {
    ...defaultImageConfig,
    ...initialConfig,
  });
  const { title, showTitle, imageUrl } = config;

  const classes = useStyles();

  return (
    <Widget
      key={id}
      titleComponents={<Typography variant="subtitle1">{title}</Typography>}
      configTitle="Image Widget"
      ConfigFieldsComponent={ImageWidgetConfigContent}
      onChangeConfig={setConfig}
      config={config}
      collapseHeader={showTitle}
      closeMenuItems
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        <Grid item aria-label="widgetContent" xs={12} sx={{ height: '99%' }}>
          <ImageWidgetDisplay
            imageUrl={imageUrl}
            classes={showTitle ? undefined : classes}
          />
        </Grid>
      </Grid>
    </Widget>
  );
};

ImageWidget.widgetKey = 'image-widget';
ImageWidget.widgetTitle = '';
ImageWidget.defaultDataGrid = {
  x: 0,
  y: 0,
  w: 4,
  h: 11,
};

export default ImageWidget;
