import { useMemo, useRef } from 'react';
import moment, { Moment } from 'moment';
import {
  DeploymentDataSource,
  isDeploymentDataSource,
  LocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import { useWidgetConfigMapContext } from 'library/CompositeComponents/dashboard/context/DashboardStateProvider';
import useDeployments from '../../../AppComponents/charts/hooks/useDeployments';
import useLocations from '../../../AppComponents/charts/hooks/useLocations';
import type { ChartWidgetConfigValues } from 'domain/AppComponents/Dashboard/chart-widget/config/ChartWidgetConfig';

const getMoment = (value: string | null): Moment =>
  value ? moment.utc(value) : moment.utc(new Date());

/** This hook extracts data sources from the widget config context */
export const useWidgetDataSources = () => {
  const { widgetConfigMap } = useWidgetConfigMapContext();

  return useMemo(() => {
    const configArray: ChartWidgetConfigValues[] = widgetConfigMap
      .values()
      .toArray();

    const dataSources = configArray.flatMap((config) => [
      ...config.locationsLeftAxis,
      ...config.locationsRightAxis,
    ]);

    return dataSources;
  }, [widgetConfigMap]);
};

export const categorizeDataSources = (
  dataSources: Array<DeploymentDataSource | LocationDataSource>
) => {
  const locationDataSources: LocationDataSource[] = [];
  const deploymentDataSources: DeploymentDataSource[] = [];
  const deviceDataSources: DeploymentDataSource[] = [];

  dataSources.forEach((source) => {
    if (isDeploymentDataSource(source)) {
      if (source.dateFrom && source.dateTo) {
        // This is a deployment data source with dates
        deploymentDataSources.push(source);
      } else {
        // This is a device data source (deployment without dates)
        deviceDataSources.push(source);
      }
    } else {
      // This is a location data source
      locationDataSources.push(source);
    }
  });

  return {
    locationDataSources,
    deploymentDataSources,
    deviceDataSources,
  };
};

/** This hook processes data sources and computes the date range */
export const useDataSourcesDateRange = (
  dataSources: (DeploymentDataSource | LocationDataSource)[]
): [Moment | undefined, Moment | undefined] => {
  // this is needed so the date range doesn't reset to until the deployments are fetched
  // the date range is set to undefined initially, but we could change that by setting the value here
  const prevDateRangeRef = useRef<
    [moment.Moment | undefined, moment.Moment | undefined]
  >([undefined, undefined]);

  // Extract device IDs and deployment dates from data sources
  const { locationDataSources, deploymentDataSources, deviceDataSources } =
    useMemo(() => categorizeDataSources(dataSources), [dataSources]);

  const deploymentDates = useMemo(
    () =>
      deploymentDataSources.flatMap((dataSource) => {
        if (dataSource.dateFrom && dataSource.dateTo) {
          return [getMoment(dataSource.dateFrom), getMoment(dataSource.dateTo)];
        }
        return [];
      }),
    [deploymentDataSources]
  );

  const {
    deploymentsData: deviceDeploymentsData,
    isFetching: isFetchingDeployments,
  } = useDeployments(deviceDataSources);

  const {
    deploymentsData: locationDeploymentsData,
    isFetching: isFetchingLocations,
  } = useLocations(locationDataSources);

  // Compute date range from all available dates
  return useMemo(() => {
    // Check if we're still fetching data or if we don't have any data yet
    if (
      isFetchingDeployments ||
      isFetchingLocations ||
      (deviceDeploymentsData.length === 0 &&
        locationDeploymentsData.length === 0 &&
        deploymentDates.length === 0)
    ) {
      return prevDateRangeRef.current;
    }

    // Extract dates from device deployments
    const deviceDates = deviceDeploymentsData.flatMap((deploymentData) => [
      getMoment(deploymentData.dateFrom),
      getMoment(deploymentData.dateTo),
    ]);

    // Extract dates from location deployments
    const locationDates = locationDeploymentsData.flatMap((deploymentData) => [
      getMoment(deploymentData.begin),
      getMoment(deploymentData.end),
    ]);

    // Combine all dates: explicit deployment dates, device deployment dates, and location deployment dates
    const allDates = [...deploymentDates, ...deviceDates, ...locationDates];

    // Find the earliest and latest dates
    const startDate = moment.min(allDates);
    const endDate = moment.max(allDates);

    const newDateRange: [moment.Moment, moment.Moment] = [startDate, endDate];
    prevDateRangeRef.current = newDateRange;

    return newDateRange;
  }, [
    isFetchingDeployments,
    isFetchingLocations,
    deviceDeploymentsData,
    locationDeploymentsData,
    deploymentDates,
  ]);
};

/** This hook provides the date range based on the current widget configurations */
const useWidgetDateRange = (): [Moment | undefined, Moment | undefined] => {
  const dataSources = useWidgetDataSources();
  return useDataSourcesDateRange(dataSources);
};

export default useWidgetDateRange;
