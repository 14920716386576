import PropTypes from 'prop-types';
import { OutlinedButton, TextButton } from '@onc/composite-components';
import {
  ShowChart,
  Description,
  Bookmarks,
  XmlIcon,
  CsvIcon,
} from '@onc/icons';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Grid,
} from 'base-components';
import DataProductCorAndXmlProgress from 'domain/AppComponents/geospatial-search/community-fisher/DataProductCorAndXmlProgress';
import DataProductPlotProgress from 'domain/AppComponents/geospatial-search/community-fisher/DataProductPlotProgress';
import Environment from 'util/Environment';

const CastPopupList = ({
  classes = undefined,
  casts = [],
  openCast = undefined,
  castStatuses = new Map(),
  code,
  errorStatus,
  restrictedStatus,
  doneStatus,
  handleOpenPlotByCast,
  generateCastPlot,
  generateCsv,
  generateTxt,
  handleAnnotationButtonClick,
  generateXmlFromList,
  backButtonHandler,
  renderImage,
}) => {
  CastPopupList.propTypes = {
    code: PropTypes.string.isRequired,
    classes: PropTypes.shape({
      listItem: PropTypes.string,
      gridWidth: PropTypes.string,
      castIconQuadrant: PropTypes.string,
      list: PropTypes.string,
      listSubheader: PropTypes.string,
      buttonContainer: PropTypes.string,
      linkButtonPrimaryOverride: PropTypes.string,
    }),
    casts: PropTypes.arrayOf(
      PropTypes.shape({
        endDate: PropTypes.string, // "2018-01-01T01:24:34.000Z"
        generationType: PropTypes.string, // "automated"
        referenceDepth: PropTypes.number, // 100.000000000000000
        referenceLat: PropTypes.number, // 49.398352504970640
        referenceLon: PropTypes.number, // -124.558354807672120
        siteDeviceId: PropTypes.number, // 1200682
        siteDeviceSubsetId: PropTypes.number, // 3820
        siteDeviceSubsetName: PropTypes.string, // BMP-001
        siteDeviceSubsetType: PropTypes.string, // UP_CAST
        startDate: PropTypes.string, // "2018-01-01T01:22:34.000Z"
        png: PropTypes.shape({
          status: PropTypes.string,
          restrictedMessage: PropTypes.string,
          dpRunId: PropTypes.number,
        }),
        cor: PropTypes.shape({
          status: PropTypes.string,
          restrictedMessage: PropTypes.string,
          dpRunId: PropTypes.number,
        }),
        xml: PropTypes.shape({
          status: PropTypes.string,
          restrictedMessage: PropTypes.string,
          dpRunId: PropTypes.number,
        }),
      })
    ),
    openCast: PropTypes.shape({
      endDate: PropTypes.string, // "2018-01-01T01:24:34.000Z"
      generationType: PropTypes.string, // "automated"
      referenceDepth: PropTypes.number, // 100.000000000000000
      referenceLat: PropTypes.number, // 49.398352504970640
      referenceLon: PropTypes.number, // -124.558354807672120
      siteDeviceId: PropTypes.number, // 1200682
      siteDeviceSubsetId: PropTypes.number, // 3820
      siteDeviceSubsetName: PropTypes.string, // BMP-001
      siteDeviceSubsetType: PropTypes.string, // UP_CAST
      startDate: PropTypes.string, // "2018-01-01T01:22:34.000Z"
      png: PropTypes.shape({
        status: PropTypes.string,
        restrictedMessage: PropTypes.string,
        dpRunId: PropTypes.number,
      }),
      cor: PropTypes.shape({
        status: PropTypes.string,
        restrictedMessage: PropTypes.string,
        dpRunId: PropTypes.number,
      }),
    }),
    castStatuses: PropTypes.instanceOf(Map),
    errorStatus: PropTypes.string.isRequired,
    restrictedStatus: PropTypes.string.isRequired,
    doneStatus: PropTypes.string.isRequired,
    handleOpenPlotByCast: PropTypes.func.isRequired,
    generateCastPlot: PropTypes.func.isRequired,
    generateCsv: PropTypes.func.isRequired,
    generateTxt: PropTypes.func.isRequired,
    handleAnnotationButtonClick: PropTypes.func.isRequired,
    generateXmlFromList: PropTypes.func.isRequired,
    backButtonHandler: PropTypes.func.isRequired,
    renderImage: PropTypes.func.isRequired,
  };

  const getCastReviewedStatus = (cast) => {
    const status = castStatuses.get(cast.siteDeviceSubsetId);
    if (status) {
      return 'Manual QAQC: complete';
    }
    return 'Manual QAQC: in progress';
  };

  // uses siteDeviceSubsetId to pull cast from array, saves passing down cast array to children
  const handleOpenPlotById = (siteDeviceSubsetId) => {
    const castForId = casts.find(
      (castObj) => castObj.siteDeviceSubsetId === siteDeviceSubsetId
    );
    handleOpenPlotByCast(castForId);
  };

  const title = `${code} Plot from ${openCast?.startDate} to ${openCast?.endDate}`;
  const imageSrc = `${Environment.getDmasUrl()}/SearchResultService?id=${
    openCast?.png?.dpRunId
  }`;

  const listContent = casts.slice(1).map((cast) => (
    <ListItem
      key={cast.siteDeviceSubsetId}
      divider
      className={classes.listItem}
      alignItems="flex-start"
    >
      <ListItemText
        primary={cast.siteDeviceSubsetType.replace('_', ' ')}
        secondary={
          <>
            {cast.startDate}
            <br />
            {getCastReviewedStatus(cast)}
            {cast.png?.status ? <br /> : null}
            <DataProductPlotProgress
              classes={classes}
              status={cast.png?.status}
              errorStatus={errorStatus}
              restrictedStatus={restrictedStatus}
              restrictedMessage={cast.png?.restrictedMessage}
              doneStatus={doneStatus}
              handleOpenPlotById={handleOpenPlotById}
              siteDeviceSubsetId={cast.siteDeviceSubsetId}
            />
            <br />
            <DataProductCorAndXmlProgress
              classes={classes}
              dataProductType="Cor"
              status={cast.cor?.status}
              errorStatus={errorStatus}
              restrictedStatus={restrictedStatus}
              restrictedMessage={cast.cor?.restrictedMessage}
              doneStatus={doneStatus}
              dpRunId={cast.cor?.dpRunId}
            />
            <br />
            <DataProductCorAndXmlProgress
              classes={classes}
              dataProductType="Xml"
              status={cast.xml?.status}
              errorStatus={errorStatus}
              restrictedStatus={restrictedStatus}
              restrictedMessage={cast.xml?.restrictedMessage}
              doneStatus={doneStatus}
              dpRunId={cast.xml?.dpRunId}
            />
            <br />
            <DataProductCorAndXmlProgress
              classes={classes}
              dataProductType="Csv"
              status={cast.csv?.status}
              errorStatus={errorStatus}
              restrictedStatus={restrictedStatus}
              restrictedMessage={cast.csv?.restrictedMessage}
              doneStatus={doneStatus}
              dpRunId={cast.csv?.dpRunId}
            />
          </>
        }
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        className={classes.gridWidth}
        spacing={0}
      >
        <Grid item xs={6}>
          <IconButton
            aria-label="Generate Plot"
            onClick={() => generateCastPlot(cast)}
            size="small"
            Icon={ShowChart}
            className={classes.castIconQuadrant}
          />
        </Grid>
        <Grid item xs={6}>
          <IconButton
            aria-label="Generate .COR"
            Icon={Description}
            size="small"
            onClick={() => generateTxt(cast)}
            className={classes.castIconQuadrant}
          />
        </Grid>
        <Grid item xs={6}>
          <IconButton
            aria-label="Generate .CSV"
            Icon={CsvIcon}
            size="small"
            onClick={() => generateCsv(cast)}
            className={classes.castIconQuadrant}
          />
        </Grid>
        <Grid item xs={6}>
          <IconButton
            aria-label="Display Annotations"
            Icon={Bookmarks}
            size="small"
            onClick={() => handleAnnotationButtonClick(cast)}
            className={classes.castIconQuadrant}
          />
        </Grid>
        <Grid item xs={6}>
          <IconButton
            aria-label="Download ISO19115 XML Metadata file"
            Icon={XmlIcon}
            size="small"
            id="downloadXmlFromList"
            onClick={() => generateXmlFromList(cast)}
            className={classes.castIconQuadrant}
          />
        </Grid>
      </Grid>
    </ListItem>
  ));
  return (
    <>
      <List
        className={classes.list}
        subheader={
          <ListSubheader
            component="div"
            className={classes.listSubheader}
            color="inherit"
          >
            Filtered Casts
          </ListSubheader>
        }
        dense
      >
        {listContent}
      </List>
      <div className={classes.buttonContainer}>
        <TextButton
          translationKey="common.buttons.back"
          onClick={backButtonHandler}
        />
        <OutlinedButton
          translationKey="communityFishers.goToDataSearch"
          className={classes.linkButtonPrimaryOverride}
          href={`${Environment.getDmasUrl()}/DataSearch?location=${code}`}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
      {renderImage(imageSrc, title)}
    </>
  );
};

export default CastPopupList;
