// Direct dev express components. These shouldn't be used so the intermediate component is to add a deprecation warning
import * as DXReactGrid from '@devexpress/dx-react-grid';
import * as DXReactGridMUI from '@devexpress/dx-react-grid-material-ui';

// Material Base Components
export { default as Accordion } from './accordion/Accordion';
export { default as AccordionActions } from './accordion/AccordionActions';
export { default as AccordionDetails } from './accordion/AccordionDetails';
export { default as AccordionSummary } from './accordion/AccordionSummary';
export { default as AnchoredHiddenMenu } from './anchored-hidden-menu/AnchoredHiddenMenu';
export {
  default as Autocomplete,
  type AutocompleteProps,
} from './Autocomplete';
export { default as AsyncAutocomplete } from './AsyncAutocomplete';
export { default as AvailabilityChart } from './availability-chart/AvailabilityChart';
export { default as Button, type ButtonProps } from './button/Button';
export { default as ButtonBase } from './button/ButtonBase';
export { default as DailyAvailabilityChart } from './availability-chart/DailyAvailabilityChart';
export {
  default as DatePicker,
  type DatePickerProps,
} from './date-pickers/DatePicker';
export {
  default as DateTimePicker,
  type DateTimePickerProps,
} from './date-pickers/DateTimePicker';
export { default as Drawer, type DrawerProps } from './Drawer';
export { default as Dropdown, type DropdownOption } from './Dropdown';
export { default as ExpansionSubMenu } from './menu/ExpansionSubMenu';
export { default as FloatingActionButton } from './FloatingActionButton';
export { default as Grid } from './Grid';
export { default as Grow } from './Grow';
export { default as InputLabel } from './InputLabel';
export {
  default as LabelledCheckbox,
  type LabelledCheckboxProps,
} from './LabelledCheckbox';
export {
  default as LabelledRadioButton,
  type LabelledRadioButtonProps,
} from './LabelledRadioButton';
export {
  default as LabelledRadioGroup,
  type LabelledRadioGroupProps,
} from './LabelledRadioGroup';
export { default as Link } from './Link';
export { default as Paper } from './Paper';
export { default as PipWindow } from './pip-window/PipWindow';
export { default as Popper } from './Popper';
export { CircularProgress, LinearProgress } from './Progress';
export { default as RadioButton } from './RadioButton';
export { default as RadioGroup } from './RadioGroup';
export { default as Tab } from './tab/Tab';
export { default as Tabs } from './tab/Tabs';
export { default as TabContext } from './tab/TabContext';
export { default as LoadingButton } from './button/LoadingButton';
export { default as Box } from './box/Box';
export { default as Alert, type AlertProps } from './alert/Alert';
export { default as AlertTitle } from './alert/AlertTitle';
export { default as AppBar } from './app-bar/AppBar';
export { default as Avatar } from './avatar/Avatar';
export { default as CardOld } from './Card';
export { default as Card } from './card/MaterialCard';
export { default as CardActions } from './card/CardActions';
export { default as CardActionArea } from './card/CardActionArea';
export { default as CardContent } from './card/CardContent';
export { default as CardHeader } from './card/CardHeader';
export { default as CardMedia } from './card/CardMedia';
export { default as Checkbox } from './Checkbox';
export { default as Chip } from './Chip';
export { default as CloseableTab } from './tab/CloseableTab';
export { default as Collapse } from './transition/Collapse';
export { default as ClickAwayListener } from './ClickAwayListener';
export { default as Dialog, type DialogProps } from './dialog/Dialog';
export { default as DialogActions } from './dialog/DialogActions';
export { default as DialogContent } from './dialog/DialogContent';
export { default as DialogContentText } from './dialog/DialogContentText';
export { default as DialogTitle } from './dialog/DialogTitle';
export { default as Divider } from './divider/Divider';
export { default as DmasLink } from './DmasLink';
export { default as ExpandableSearchBar } from './search/ExpandableSearchBar';
export { default as FormControl } from './form/FormControl';
export { default as ErrorPage } from './ErrorPage';
export { default as ExpandableContainer } from './expandable/ExpandableContainer';
export {
  default as FormControlLabel,
  type FormControlLabelProps,
} from './form/FormControlLabel';
export { default as FormHelperText } from './form/FormHelperText';
export { default as FormGroup } from './form/FormGroup';
export { default as FormLabel } from './form/FormLabel';
export { default as HelpLink } from './HelpLink';
export { default as ImageList } from './image-list/ImageList';
export { default as ImageListItem } from './image-list/ImageListItem';
export { default as InputAdornment } from './input-adornment/InputAdornment';
export {
  default as IconButton,
  type OncIconButtonProps,
} from './icon-button/IconButton';
export { default as Popover, type PopoverProps } from './popover/Popover';
export { default as Tooltip } from './Tooltip';
export { default as List } from './list/List';
export { default as ListItem } from './list/ListItem';
export { default as ListItemButton } from './list/ListItemButton';
export { default as ListItemAvatar } from './list/ListItemAvatar';
export { default as ListItemIcon } from './list/ListItemIcon';
export { default as ListItemSecondaryAction } from './list/ListItemSecondaryAction';
export { default as ListItemText } from './list/ListItemText';
export { default as ListSubheader } from './list/ListSubheader';
export { default as Listbox } from './Listbox';
export { default as SizeableVirtualList } from './SizeableVirtualList';
export { default as Menu } from './menu/Menu';
export { default as MenuItem } from './menu/MenuItem';
export { default as MultiSelect } from './MultiSelect';
export { default as NestedItem, type NestedItemType } from './menu/NestedItem';
export { default as NestedMenuItem } from './menu/NestedMenuItem';
export { default as ReadOnlyTextField } from './text/ReadOnlyTextField';
export { default as SequentialSubMenu } from './menu/SequentialSubMenu';
export { default as Snackbar } from './snackbars/Snackbar';
export { default as Skeleton } from './Skeleton';
export { default as Stack } from './Stack';
export { default as Stepper, type StepDisplayInfo } from './stepper/Stepper';
export { default as Toggle, type ToggleProps } from './Toggle';
export { default as TreeItem } from './tree/TreeItem';
export { default as TreeView } from './tree/TreeView';
export { default as TextAreaAutosize } from './text/TextAreaAutosize';
export { default as Typography } from './text/Typography';
export { default as TypographyWithTranslation } from './text/TypographyWithTranslation';
export {
  default as TextField,
  type TextFieldPropTypes,
} from './text/TextField';
export { default as ToggleButton } from './toggle-button/ToggleButton';
export { default as ToggleButtonGroup } from './toggle-button/ToggleButtonGroup';
export { default as Toolbar } from './toolbar/Toolbar';
export { default as Fade } from './transition/Fade';
export { default as Select } from './select/Select';
export { default as Slide } from './slide/Slide';
export { default as Slider } from './slider/Slider';
export { default as SliderTooltipLabel } from './slider/SliderTooltipLabel';
export { default as Switch } from './Switch';
export { default as VirtualList } from './virtual-list/VirtualList';
export {
  default as SnackbarProvider,
  useSnackbars,
} from './snackbars/useSnackbars';
export { default as withCircularProgress } from './with-circular-progress/withCircularProgress';

// Dev express table & plugins
export { default as TableOld, type TableProps as TableOldProps } from './Table';
export {
  default as StatelessTable,
  type TableColumn,
  type TableColumnDataTypes,
  type DataFormatProvider,
} from './table/StatelessTable';
export { default as CellComponent } from './table/plugins/CellComponent';
export {
  default as ColumnSorting,
  type SortingProps,
} from './table/plugins/ColumnSorting';
export { default as ExpandRow } from './table/plugins/ExpandRow';
export { type PagingProps } from './table/plugins/Pagination';
export { default as TableFilterLogic } from './table/plugins/filter/filters/TableFilterLogic';
export {
  default as ToolbarFilter,
  getEmptyFilter,
  type TableFilter,
  type TableFilterLine,
} from './table/plugins/filter/ToolbarFilter';
export { default as DateFormatter } from './table/formatters/DateFormatter';
export { default as BooleanFormatter } from './table/formatters/BooleanFormatter';
export { default as ShowAllContentFormatter } from './table/formatters/ShowAllContentFormatter';
export { default as NoTotalCountPagingContainer } from './table/NoTotalCountPagingContainer';

// Don't use this directly. Use stateless table & plugins
/** @deprecated */
export const DevExpressTable = DXReactGridMUI.Table;
export type DevExpressTableColumnExtension =
  DXReactGridMUI.Table.ColumnExtension;
export const DevExpressTableRowDetail = DXReactGridMUI.TableRowDetail;
export type DevExpressSorting = DXReactGrid.Sorting;
export const DevExpressSortingState: typeof DXReactGrid.SortingState =
  DXReactGrid.SortingState;
export const DevExpressIntegratedSorting = DXReactGrid.IntegratedSorting;
export type DevExpressSortingDirection = DXReactGrid.SortingDirection;
export type DevExpressTableColumnWidthInfo = DXReactGrid.TableColumnWidthInfo;
export const DevExpressDataTypeProvider = DXReactGrid.DataTypeProvider;
export type DevExpressGrouping = DXReactGrid.Grouping;
export const DevExpressCustomTreeData = DXReactGrid.CustomTreeData;
export const DevExpressTreeDataState = DXReactGrid.TreeDataState;
export const DevExpressGrid = DXReactGridMUI.Grid;
export const DevExpressTableHeaderRow = DXReactGridMUI.TableHeaderRow;
export const DevExpressTableTreeColumn = DXReactGridMUI.TableTreeColumn;
export const DevExpressToolbar = DXReactGridMUI.Toolbar;
export const DevExpressGroupingPanel = DXReactGridMUI.GroupingPanel;
export const DevExpressTableFilterRowCell = DXReactGridMUI.TableFilterRow.Cell;

// Direct material exports
export { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
export { LocalizationProvider } from '@mui/x-date-pickers';

// Non-material base-components
// TODO: Lightbox library is deprecated. Also doesn't have very good support for screen readers (All image placeholders are just image)
// We should look at replacing/rewriting this.
export { default as Lightbox } from './lightbox/Lightbox';
export {
  default as QuickButton,
  type QuickButtonAttribute,
  type QuickEntryButton,
} from './button/QuickButton';
export { default as ReactPlayer } from './video/ReactPlayer';
export { default as ResizableJWPlayer } from './ResizableJWPlayer';
export { default as RouterLink } from './router-link/RouterLink';
export {
  default as DragScroll,
  DragScrollForStorybook,
} from './drag-scroll/DragScroll';
export { default as SizeMe, withSize } from './size-me/SizeMe';
export { default as TextEditor } from './text-editor/TextEditor';
export {
  Router,
  BrowserRouter,
  Switch as RouterSwitch,
  MemoryRouter,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';

// React-leaflet and other map exports
// Maybe these should be in a different package to seperate Map components from others?
export { default as BaseLayer } from './map/leaflet-ui-layers/BaseLayer';
export { default as BingTileLayer } from './map/leaflet-tile-layers/BingTileLayer';
export { default as Circle } from './map/leaflet-vector-layers/Circle';
export { default as CircleMarker } from './map/leaflet-vector-layers/CircleMarker';
export { default as divIcon } from './map/leaflet-ui-layers/DivIcon';
export { default as DrawControl } from './map/buttons/DrawControl';
export { default as EditControl } from './map/buttons/EditControl';
export { default as EmbeddedMap } from './map/EmbeddedMap';
export { default as ESRIDynamicMapLayer } from './map/leaflet-raster-layers/ESRIDynamicMapLayer';
export { default as ESRIImageLayer } from './map/leaflet-raster-layers/ESRIImageLayer';
export { default as FullscreenControl } from './map/buttons/FullscreenControl';
export { default as icon } from './map/leaflet-ui-layers/Icon';
export { default as LassoControl } from './map/buttons/LassoControl';
export { default as LayersControl } from './map/buttons/LayersControl';
export { default as LayerGroup } from './map/leaflet-ui-layers/LayerGroup';
export { default as MapContext } from './map/MapContext';
export { default as MapFAB } from './map/buttons/MapFAB';
export { default as MapFABControl } from './map/buttons/MapFABControl';
export { default as Marker } from './map/leaflet-ui-layers/Marker';
export { default as MarkerClusterGroup } from './map/leaflet-ui-layers/MarkerClusterGroup';
export { default as Overlay } from './map/leaflet-ui-layers/Overlay';
export { default as Point } from './map/leaflet-ui-layers/Point';
export { default as Popup } from './map/leaflet-ui-layers/Popup';
export { default as Polygon } from './map/leaflet-vector-layers/Polygon';
export { default as Polyline } from './map/leaflet-vector-layers/Polyline';
export { default as Search } from './map/buttons/Search';
export { default as TileLayer } from './map/leaflet-tile-layers/TileLayer';
export { default as LeafletIcon } from './map/leaflet-ui-layers/LeafletIcon';
export { default as LeafletTooltip } from './map/leaflet-ui-layers/Tooltip';
export { default as WMSTileLayer } from './map/leaflet-raster-layers/WMSTileLayer';
export { default as ZoomControl } from './map/buttons/ZoomControl';
