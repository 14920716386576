import { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { ErrorSnackbar, InfoSnackbar } from '@onc/composite-components';
import DiverImage from 'assets/images/standard_divers_med.jpg';
import DataPreviewWidgetDisplay from 'domain/AppComponents/Dashboard/DataPreviewWidgetDisplay';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';

import { parseDmasAPIResponse, get } from 'util/WebRequest';
import DataPreviewWidgetConfig, {
  type DataPreviewWidgetConfigType,
} from './Config/DataPreviewWidgetConfigContent';

export const defaultDataPreviewConfig: DataPreviewWidgetConfigType = {
  previewUrl:
    'https://qa.oceannetworks.ca/DataPreviewService?operation=5&searchTreeNodeId=15&deviceCategoryId=5&sensorCodeId=492&timeConfigId=2&dataProductFormatId=3&plotNumber=1',
  showTitle: true,
  title: 'Sample Preview Plot',
};

const NO_PLOT_FOUND_IMAGE = DiverImage;
const NO_PLOT_FOUND_MESSAGE =
  'Preview not found! We expect these guys will find it soon.';

const DataPreviewWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, initialConfig } = props;
  const { config, setConfig } = useWidgetConfig<DataPreviewWidgetConfigType>(
    id,
    {
      ...defaultDataPreviewConfig,
      ...initialConfig,
    }
  );

  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDataPreviewImage = useCallback(async () => {
    // Call service to get image URL
    try {
      const response = await get(config.previewUrl);
      const payload = parseDmasAPIResponse(response);

      if (payload.filePath) {
        setImageUrl(payload.filePath);
        setInfo(null);
        setLoading(false);
      } else {
        setImageUrl(NO_PLOT_FOUND_IMAGE);
        setInfo(NO_PLOT_FOUND_MESSAGE);
        setLoading(false);
      }
      return Promise.resolve();
    } catch (newError) {
      setError(newError);
      setLoading(false);
      throw new Error(String(newError));
    }
  }, [config.previewUrl]);

  useEffect(() => {
    setLoading(true);
    fetchDataPreviewImage();
  }, [fetchDataPreviewImage]);

  if (loading) {
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  }
  let errorComp;
  if (error) {
    errorComp = <ErrorSnackbar message={error.message} />;
  }

  let infoComp;
  if (info) {
    infoComp = <InfoSnackbar message={info} />;
  }
  return (
    <Widget
      key={id}
      titleComponents={
        <Typography variant="subtitle1">{config.title}</Typography>
      }
      configTitle="Data Preview Widget"
      ConfigFieldsComponent={DataPreviewWidgetConfig}
      onChangeConfig={setConfig}
      config={config}
      collapseHeader={config.showTitle}
      closeMenuItems
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        {infoComp}
        {errorComp}
        <DataPreviewWidgetDisplay imageUrl={imageUrl} />
      </Grid>
    </Widget>
  );
};

DataPreviewWidget.widgetKey = 'data-preview-widget';
DataPreviewWidget.widgetTitle = '';
DataPreviewWidget.defaultDataGrid = {
  x: 0,
  y: 0,
  w: 5,
  h: 22,
};

export default DataPreviewWidget;
