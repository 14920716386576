import Environment from '@onc/environment';
import FIXED_CAMERA_APP_TOKEN from 'domain/Apps/seatube/SeaTubeAppTokens';
import SearchExportWidget from 'domain/Widgets/seatube-search/SearchExportWidget';
import SearchFormWidget from 'domain/Widgets/seatube-search/SearchFormWidget';
import SearchResultsTableWidget from 'domain/Widgets/seatube-search/SearchResultsTableWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import { WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';
import searchLayout from './layouts/searchLayouts';
import SeaTubeDashboard from '../SeaTubeDashboard';

const SeaTubeSearchDashboard = () => {
  Environment.setCurrentApplicationToken(FIXED_CAMERA_APP_TOKEN);
  const initialDashboardState = {
    filter: {},
    searchTreeNodeIds: { key: -1, label: '', value: -1 },
    locationCode: '',
    sortAnnotationsByMostRecent: true,
    deviceCategoryIds: -1,
    searchResults: [],
  };
  const widgets: WidgetOption[] = [
    { Component: SearchFormWidget, label: 'Search', multiple: false },
    { Component: SearchResultsTableWidget, label: 'Search', multiple: false },
    { Component: SearchExportWidget, label: 'Search', multiple: false },
  ];
  return (
    <SeaTubeDashboard
      id="seatube-search"
      title="SeaTube Search"
      widgetLibrary={new WidgetLibrary(widgets)}
      layoutKey="seatube-search-layout"
      defaultLayout={searchLayout}
      initialDashboardState={initialDashboardState}
      isStatic
    />
  );
};

export default SeaTubeSearchDashboard;
