import { useMemo } from 'react';
import { useQueries } from '@onc/service';
import SiteDeviceService from 'domain/services/SiteDeviceService';
import { getSourceIdentifier } from '../TraceDataUtils';
import type { TraceLegendRowData } from '../TraceLegend';
import type { ScalarDataChartDeploymentSource } from '../types/TimeSeriesScalarDataChart.types';
import type { DeploymentResponse } from 'domain/hooks/useFilteredDeployments';

type SourcedDeployment = DeploymentResponse & {
  dataSource: ScalarDataChartDeploymentSource;
};

const useDeploymentRows = (
  dataSources: ScalarDataChartDeploymentSource[],
  disabled: boolean = false
) => {
  const dataSourceMap = useMemo(() => {
    const map: Record<number, ScalarDataChartDeploymentSource[]> = {};

    dataSources.forEach((ds) => {
      if (!map[ds.dataSource.deviceId]) {
        map[ds.dataSource.deviceId] = [];
      }
      map[ds.dataSource.deviceId].push(ds);
    });

    return map;
  }, [dataSources]);

  // Get unique device IDs to avoid duplicate queries
  const deviceIds = useMemo(
    () => [
      ...new Set(
        dataSources.map((dataSource) => dataSource.dataSource.deviceId)
      ),
    ],
    [dataSources]
  );

  // queries will be a different object on each render.
  // TODO: when we upgrade to tanstack query v5 all of the following memos can be replaced by using the combine property
  const deploymentQueries = useQueries({
    queries: deviceIds.map((deviceId) => ({
      queryKey: ['multipleDeployments', deviceId],
      queryFn: async (): Promise<SourcedDeployment[]> => {
        if (!disabled) {
          const deployments = await SiteDeviceService.getDeployments(deviceId);

          // Create a deployment for each data source associated with this device
          return dataSourceMap[deviceId].flatMap((dataSource) =>
            deployments.map((deployment) => ({
              ...deployment,
              dataSource,
            }))
          );
        }
        return [];
      },
    })),
  });

  const deviceDeploymentRows: TraceLegendRowData[] = useMemo(
    () =>
      deploymentQueries.flatMap((deploymentQuery) => {
        const { data: deployments } = deploymentQuery;
        if (!deployments) {
          return [];
        }
        return deployments.map((deployment, i) => ({
          id: `${deployment.dataSource.dataSource?.nodeId}-${deployment.locationName}-${i}`,
          sourceIdentifier: getSourceIdentifier(deployment.dataSource),
          sourceName: deployment.dataSource.traceName,
          traceColour: deployment.dataSource.traceColour,
          deviceName:
            deployment.dataSource.device.name ??
            deployment.dataSource.deviceCode,
          deviceId: deployment.dataSource.device.deviceId,
          dateFrom: deployment.dateFrom,
          dateTo: deployment.dateTo,
        }));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(deploymentQueries)]
  );

  const isFetching = useMemo(
    () => deploymentQueries.some((query) => query.isFetching),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(deploymentQueries)]
  );

  const result = useMemo(
    () => ({
      deviceDeploymentRows,
      isFetching,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceDeploymentRows, isFetching]
  );

  return result;
};

export default useDeploymentRows;
