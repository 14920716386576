import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import WidgetDisplay from 'domain/AppComponents/Dashboard/WidgetDisplay';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const STYLES = {};

class Widgets extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    onError: PropTypes.func.isRequired,
  };

  renderWidget = (routerProps) => {
    const { onError } = this.props;
    return (
      <WidgetDisplay
        onError={onError}
        widgetId={Number(routerProps.match.params.id)}
      />
    );
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/id/:id`} render={this.renderWidget} />
      </Switch>
    );
  }
}

export default withStyles(STYLES)(withSnackbars(Widgets));
