import { YAxisSide } from 'domain/AppComponents/charts/TimeSeriesChart';
import {
  DeploymentDataSource,
  LocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import { Device as DeviceValue } from 'domain/AppComponents/Dashboard/data-source-widget/DataSourceSelectionWidgetFilters';
import type { Moment } from 'moment';

export enum DataSourceType {
  Deployment = 'deployment',
  Location = 'location',
}

export type ScalarDataChartSource = {
  propertyCode: string;
  traceName: string;
  traceColour: string;
  initialQualityControl: string;
  yaxisSide: YAxisSide;
  sensorName: string;
  type?: DataSourceType;
  qualityControl?: ChartQuality;
};

export interface ScalarDataChartLocationSource extends ScalarDataChartSource {
  locationCode: string;
  datasetReference: string;
  deviceCategoryCode: string;
  deviceCategoryName: string;
  dataSource: LocationDataSource;
  type?: DataSourceType.Location;
}

export const isScalarDataChartLocationSource = (
  source: ScalarDataChartSource
): source is ScalarDataChartLocationSource =>
  !source.type || source.type === 'location';

export interface ScalarDataChartDeploymentSource extends ScalarDataChartSource {
  deviceCode: string;
  sensorCategoryCode: string;
  device: DeviceValue;
  type: DataSourceType.Deployment;
  dataSource: DeploymentDataSource;
  dateFrom?: Moment;
  dateTo?: Moment;
}

export const isScalarDataChartDeploymentSource = (
  source: ScalarDataChartSource
): source is ScalarDataChartDeploymentSource =>
  source.type === DataSourceType.Deployment;

export type ScalarData = {
  actualSamples: number;
  data: Data;
  outputFormat: string;
  sensorCategoryCode: string;
  sensorCode: string;
  sensorName: string;
  unitOfMeasure: string;
};
export type Data = {
  counts: number[];
  max: Array<number | null>;
  min: Array<number | null>;
  qaqcFlags: number[];
  sampleTimes: Date[];
  values: Array<number | null>;
};
type ScalarDataResponseWithAxisSide = {
  traceName: string;
  scalarData: ScalarData[];
  yaxisSide: YAxisSide;
  propertyCode: string;
};
export type ScalarDataInfo = {
  requestList: Array<{ request: Promise<any>; cancel: () => void } | undefined>;
  scalarDataList: Array<ScalarDataResponseWithAxisSide | string>;
  resamplePeriod?: string;
  resampleType?: string;
  isCleanAvg?: boolean;
};
type ScalarDataInfoActionRequest = {
  type: 'request';
  data: {
    index: number;
    request: { request: Promise<any>; cancel: () => void };
  };
};
type ScalarDataInfoActionDataReceived = {
  type: 'datareceived';
  data: {
    index: number;
    response: ScalarData[];
    resampling: { resampleType: string; resamplePeriod: string };
    isCleanAvg: boolean;
    source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource;
  };
};
type ScalarDataInfoActionCancelRequest = {
  type: 'cancelrequest';
  data: { index: number };
};
export type ScalarDataInfoActionReset = {
  type: 'reset';
};
export type ScalarDataInfoActionTypes = ScalarDataInfoAction['type'];
export type ScalarDataInfoAction =
  | ScalarDataInfoActionRequest
  | ScalarDataInfoActionDataReceived
  | ScalarDataInfoActionCancelRequest
  | ScalarDataInfoActionReset;
export const isScalarDataInfoActionReset = (
  action: ScalarDataInfoAction
): action is ScalarDataInfoActionReset => action.type === 'reset';

type TraceActionSet = {
  type: 'set';
  data?: {
    chartQuality?: ChartQuality;
    index: number;
    firstSensor: ScalarData;
    yaxisSide: YAxisSide;
    propertyColor: string;
    minMaxColor: string;
    propertyCode: string;
    deviceCategoryName: string;
    datasetReference: string;
    traceName: string;
    showTrace: boolean;
    isCleanAvg?: boolean;
  };
};
type TraceActionNoDataOrMarkPending = {
  type: 'nodata' | 'markpending';
  data: { index: number };
};
type TraceActionRemoveCleanAvgForRaw = { type: 'removecleanavgforraw' };
export type TraceAction =
  | TraceActionSet
  | TraceActionNoDataOrMarkPending
  | TraceActionRemoveCleanAvgForRaw;
export const traceActionDataHasIndex = (
  action: TraceAction
): action is TraceActionNoDataOrMarkPending | TraceActionSet =>
  action.type === 'nodata' ||
  action.type === 'markpending' ||
  action.type === 'set';

export type ResamplePeriod =
  | 1
  | 5
  | 10
  | 15
  | 30
  | 60
  | 300
  | 600
  | 900
  | 1800
  | 3600
  | 7200
  | 21600
  | 43200
  | 86400
  | 172800
  | 259200
  | 604800;

export type ResampleType = 'avg';
export interface Resampling {
  resamplePeriod: ResamplePeriod;
  resampleType: ResampleType;
}

export type ChartQuality = 'raw' | 'clean';

export type YAxisRanges = {
  yAxisLeft: [number | null, number | null];
  yAxisRight: [number | null, number | null];
} | null;

export type TraceControlMap = Map<
  string,
  { isAverageEnabled: boolean; isMinMaxEnabled: boolean }
>;
