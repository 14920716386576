import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Route } from 'react-router-dom';
import DeviceActionMaintenance from './DeviceActionMaintenance';

class DeviceActionMaintenanceContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const params = this.parseURLParams();
    this.state = {
      deviceActionId: Number(params.ID),
      deviceId: Number(params.deviceId),
      isFromDevice: String(params.isFromDevice),
      isFromCruise: String(params.isFromCruise),
      cruiseId: params.cruiseId ? Number(params.cruiseId) : 0,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => this.updatePage());
  }

  parseURLParams = () => {
    const { history } = this.props;

    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  };

  updatePage() {
    const params = this.parseURLParams();
    this.setState({
      deviceActionId: Number(params.ID),
      deviceId: Number(params.deviceId),
      cruiseId: Number(params.cruiseId),
      isFromDevice: String(params.isFromDevice),
      isFromCruise: String(params.isFromCruise),
    });
  }

  renderDeviceActionPage = () => {
    const {
      deviceActionId,
      deviceId,
      cruiseId,
      isFromDevice: fromDeviceParam,
      isFromCruise: fromCruiseParam,
    } = this.state;
    const { history } = this.props;
    let isFromDevice;
    let isFromCruise;
    if (fromDeviceParam === 'Y') {
      isFromDevice = true;
    } else {
      // default prop
      isFromDevice = false;
    }

    if (fromCruiseParam === 'Y') {
      isFromCruise = true;
    } else {
      // default prop
      isFromCruise = false;
    }

    return (
      <DeviceActionMaintenance
        deviceActionId={deviceActionId}
        deviceId={deviceId}
        cruiseId={cruiseId}
        history={history}
        isFromDevice={isFromDevice}
        isFromCruise={isFromCruise}
      />
    );
  };

  render() {
    const { match } = this.props;

    return (
      <Route
        exact
        path={`${match.path}`}
        render={this.renderDeviceActionPage}
      />
    );
  }
}

export default DeviceActionMaintenanceContainer;
