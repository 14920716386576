import { useQuery } from '@onc/service';
import SiteDeviceService from 'domain/services/SiteDeviceService';

export interface DeploymentResponse {
  locationName: string;
  dateFrom: string;
  dateTo?: string;
}

const useFilteredDeployments = (deviceId: number, disabled) => {
  const { data, isFetching } = useQuery<DeploymentResponse[]>(
    ['filteredDeployments', deviceId],
    async () => {
      const response: DeploymentResponse[] = !disabled
        ? await SiteDeviceService.getDeployments(deviceId)
        : [];
      return response;
    }
  );

  return { filteredDeployments: data ?? [], isFetching };
};

export default useFilteredDeployments;
