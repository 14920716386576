import { useEffect, useRef } from 'react';
import * as React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import {
  TextButton,
  Filter,
  FilterEvent,
  FilterImplementationProps,
  FilterValue,
  SummaryValues,
  withSnackbars,
} from '@onc/composite-components';
import { Add, TextFields } from '@onc/icons';
import { Grid } from 'base-components';
import AnnotationUtil from 'domain/AppComponents/annotations/AnnotationUtil';
import useFormFields from 'domain/hooks/useFormFields';
import FormFieldLine, {
  FormFieldLineValue,
} from '../form-fields/FormFieldLine';

interface Props extends FilterImplementationProps {
  onChange: (event: FilterEvent) => void;
  onError: (error: Error) => void;
  name: string;
  value?: FormFieldLineValue[];
  filter: FilterValue;
  classes: any;
}

const styles = () =>
  createStyles({
    button: {
      width: '100%',
    },
  });

const emptyFieldLine = {
  fieldGroup: undefined,
  field: undefined,
  fieldValue: undefined,
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const FieldsFilter: React.FC<Props> = ({
  onChange,
  onError,
  name,
  value = [emptyFieldLine],
  filter,
  classes,
  onExpand,
  expanded,
}: Props) => {
  const prevFilter: any = usePrevious(filter);

  const renderSummaryValues = (): SummaryValues[] =>
    value
      .filter(
        (line) =>
          line?.field &&
          line?.fieldValue !== undefined &&
          line?.fieldValue !== '' &&
          line?.fieldValue !== null
      )
      .map((line) => {
        // Value is an array for multiselect
        const displayValue = Array.isArray(line.fieldValue)
          ? line.fieldValue.map((item) => item.label).join(', ')
          : line.fieldValue.label || line.fieldValue.value || line.fieldValue;
        return {
          label: `${line.field.label}: ${displayValue}`,
        };
      });

  // useFormFields hook --------------------------------------------------
  const fieldData = useFormFields(onError, {
    formTypeId: 1,
    sourceIds:
      filter && filter.sourceFilter
        ? AnnotationUtil.getSourceId(filter.sourceFilter)
        : undefined,
    resourceTypeId:
      filter && filter.resourceFilter
        ? filter.resourceFilter.resourceTypeId
        : undefined,
  });
  fieldData.delete('1'); // Remove Date Range Field
  fieldData.delete('2'); // Remove Shared Field
  fieldData.delete('3'); // Remove Flagged Field

  const handleChange = (newValue: FormFieldLineValue[]) => {
    onChange({
      target: {
        value: newValue,
        name,
      },
    });
  };

  // Resets the fields when the resource changes ---------------------------------------------
  useEffect(
    () => {
      if (
        prevFilter &&
        prevFilter.resourceFilter &&
        prevFilter.resourceFilter.resourceTypeId !== undefined
      ) {
        handleChange([emptyFieldLine]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.resourceFilter]
  );

  // Field Line Handlers ------------------------------------------------
  const handleUpdateFieldLine = (index: number, data: FormFieldLineValue) => {
    const updatedFieldLines = [...value];
    updatedFieldLines[index] = data;
    handleChange(updatedFieldLines);
  };

  const handleRemoveFieldLine = (index: number) => {
    const updatedFieldLines = [...value];
    updatedFieldLines.splice(index, 1);
    handleChange(updatedFieldLines);
  };

  const handleAddFieldLine = () => {
    const updatedFieldLines = [...value];
    updatedFieldLines.push(emptyFieldLine);
    handleChange(updatedFieldLines);
  };

  // each item needs to be mapped to a unique key or you get console errors
  const fieldsKeys = ['value', 'label', 'fieldGroup'];
  //--------------------------------------------------------------------
  return (
    <Filter
      title="Fields"
      value={value}
      name={name}
      onChange={onChange}
      summaryValues={renderSummaryValues()}
      icon={<TextFields color="action" />}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        {value.map((line: FormFieldLineValue, index: number) => (
          <Grid item xs={12} key={fieldsKeys.pop()}>
            <FormFieldLine
              onChange={handleUpdateFieldLine}
              value={line}
              onRemove={handleRemoveFieldLine}
              index={index}
              data={fieldData}
              numLines={value.length}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <TextButton
            translationKey="annotations.addField"
            startIcon={<Add />}
            className={classes.button}
            onClick={handleAddFieldLine}
            data-test="addFieldLine"
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default withSnackbars(withStyles(styles)(FieldsFilter));
