import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  ContainedButton,
  FloatRightButtonLowStyles,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  RefreshButton,
  EditIconButton,
  DeleteIconButton,
} from 'domain/AppComponents/IconButtons';
import ActionCategoryService from 'domain/services/ActionCategoryService';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import ActionConfig from './ActionConfig';
import ActionService from './ActionService';

const styles = {
  button: FloatRightButtonLowStyles,
};

const headers = [
  { title: 'ID', name: 'actionId' },
  { title: 'Action Name', name: 'actionName' },
  { title: 'Needs Cruise', name: 'needsCruise' },
  { title: 'Action Category', name: 'actionCategoryName' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: ' ', name: 'icons' },
];

const tableColumnExtensions = [
  {
    columnName: 'actionId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'actionName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'needsCruise',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'actionCategoryName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'icons',
    width: ColumnInfo.small,
    align: 'left',
  },
];

class ActionTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }).isRequired,
  };

  titleContent = () => <Typography variant="h6">Actions List</Typography>;

  columnSizeInfo = [
    { columnName: 'actionId', width: ColumnInfo.mini },
    { columnName: 'actionName', width: ColumnInfo.medium },
    { columnName: 'needsCruise', width: ColumnInfo.medium },
    { columnName: 'actionCategoryName', width: ColumnInfo.medium },
    { columnName: 'modifyBy', width: ColumnInfo.medium },
    { columnName: 'modifyDate', width: ColumnInfo.medium },
    { columnName: 'icons', width: ColumnInfo.mini },
  ];

  disabledSort = [{ columnName: 'icons', sortingEnabled: false }];

  actionCategoryMap = [];

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
      openCreateDialog: false,
      openDeleteDialog: false,
    };
  }

  async componentDidMount() {
    this.actionCategoryMap = await ActionCategoryService.get().then(
      (result) => result
    );
    this.refreshTableData();
  }

  actionContent = () => {
    const { permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components, react/no-this-in-sfc
    const ActionRefreshButton = () => <RefreshButton onClick={this.refresh} />;
    if (permission !== 'RW') {
      return <ActionRefreshButton />;
    }
    return (
      <>
        <ActionRefreshButton />
        <ContainedButton
          translationKey="device.addAction"
          startIcon={<Add />}
          className={classes.button}
          onClick={this.handleAddRole}
          TooltipProps={{ title: 'Add Action' }}
        />
      </>
    );
  };

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError } = this.props;
    ActionService.get()
      .then((result) => {
        this.buildTableRows(result, this.actionCategoryMap);
      })
      .catch((result) => {
        onError(result);
      });
  };

  icons = (row) => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return null;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            this.setState({
              openCreateDialog: true,
              rowToBeEdited: row,
            });
          }}
          aria-label="Edit Action"
          size="small"
        />
        <DeleteIconButton
          onClick={() => {
            this.setState({
              openDeleteDialog: true,
              rowToBeDeleted: row,
            });
          }}
          aria-label="Delete Action"
          size="small"
        />
      </>
    );
  };

  addEventDialogue = () => {
    const { openCreateDialog, rowToBeEdited } = this.state;
    const { permission } = this.props;
    if (!openCreateDialog) {
      return null;
    }
    return (
      <ActionConfig
        permission={permission}
        title={rowToBeEdited ? 'Edit Action' : 'Add New Action'}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        actionListing={rowToBeEdited}
        actionCategoryMap={this.actionCategoryMap}
      />
    );
  };

  deleteEventDialogue = () => {
    const { openDeleteDialog, rowToBeDeleted } = this.state;
    if (!openDeleteDialog) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Action"
        content={`Are you sure you want to delete action ${rowToBeDeleted.actionId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.handleDelete}
      />
    );
  };

  // open dialog to add new resource role
  handleAddRole = () =>
    this.setState({
      openCreateDialog: true,
      openDeleteDialog: false,
      rowToBeEdited: undefined,
    });

  // cancel add/edit and close dialog
  handleCancel = () =>
    this.setState({
      openCreateDialog: false,
      openDeleteDialog: false,
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
    });

  handleDelete = async () => {
    const { onError, onInfo } = this.props;
    const { rowToBeDeleted } = this.state;
    await ActionService.delete(rowToBeDeleted.actionId)
      .then((response) => {
        this.setState({ openCreateDialog: false, openDeleteDialog: false });
        if (response.data.statusCode === 0) {
          onInfo('Deleted Successfully');
        } else {
          onError(
            `Failed to delete with error code: ${response.data.statusCode}`
          );
        }
      })
      .then(() => {
        this.refresh();
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  handleSave = async (form) => {
    const { onError, onInfo } = this.props;
    const { rowToBeEdited } = this.state;
    const updatedInfo = form;
    // add all row data that is missing from form back into the post
    if (rowToBeEdited) {
      updatedInfo.actionId = rowToBeEdited.actionId;

      if (form.needsCruise === 'True') {
        updatedInfo.needsCruise = true;
      } else {
        updatedInfo.needsCruise = false;
      }

      await ActionService.update(updatedInfo)
        .then((response) => {
          this.setState({ openCreateDialog: false });
          if (response.data.statusCode === 0) {
            onInfo('Saved Successfully');
          } else if (response.data.statusCode === 9001) {
            onError(response.data.message);
          } else {
            onError(
              `Failed to save with error code: ${response.data.statusCode}`
            );
          }
        })
        .then(() => {
          this.refresh();
        })
        .catch((response) => {
          onError(response.message);
        });
    } else {
      if (form.needsCruise === 'True') {
        updatedInfo.needsCruise = true;
      } else {
        updatedInfo.needsCruise = false;
      }

      await ActionService.create(updatedInfo)
        .then((response) => {
          this.setState({ openCreateDialog: false });
          if (response.data.statusCode === 0) {
            onInfo('Saved Successfully');
          } else if (response.data.statusCode === 9001) {
            onError(response.data.message);
          } else {
            onError(
              `Failed to save with error code: ${response.data.statusCode}`
            );
          }
        })
        .then(() => {
          this.refresh();
        })
        .catch((response) => {
          onError(response.message);
        });
    }
  };

  buildTableRows(actionListings, actionCategoryMap) {
    this.setState({
      tableRows: actionListings.map((action) => {
        const actionWithIcons = { ...action };
        if (action.needsCruise === true) {
          actionWithIcons.needsCruise = 'True';
        } else {
          actionWithIcons.needsCruise = 'False';
        }
        actionWithIcons.actionCategoryName =
          actionCategoryMap[action.actionCategoryId];
        actionWithIcons.modifyDate = DateFormatUtils.formatDate(
          action.modifyDate,
          'date'
        );
        actionWithIcons.icons = this.icons(actionWithIcons);
        return actionWithIcons;
      }),
    });
  }

  render() {
    const { permission } = this.props;

    const { tableRows } = this.state;
    if (!tableRows) return undefined;

    return (
      <>
        {this.addEventDialogue()}
        {this.deleteEventDialogue()}
        <Panel title={this.titleContent()} actionContent={this.actionContent()}>
          <SortableTable
            searchBarMoveable={permission === 'RW'}
            pageSize={15}
            searchable
            columnSizes={this.columnSizeInfo}
            columnExtensions={tableColumnExtensions}
            elevation={0}
            stripedRows
            columns={headers}
            rows={tableRows}
            disabledSort={this.disabledSort}
          />
        </Panel>
      </>
    );
  }
}
export default withStyles(styles)(ActionTable);
