import React, { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles/createTheme';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import moment from 'moment';
import {
  ErrorAlert,
  CancelButton,
  DeleteButton,
  SaveButton,
  withSnackbars,
} from '@onc/composite-components';
import {
  Autocomplete,
  DateTimePicker,
  FormHelperText,
  Grid,
  LabelledCheckbox,
} from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import { ResourceTypeSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import useFormFields from 'domain/hooks/useFormFields';
import AnnotationService from 'domain/services/AnnotationService';
import ResourceService from 'domain/services/ResourceService';
import AnnotationEntryUtil from './AnnotationEntryUtil';
import DraggableToolbox from './DraggableToolbox';
import ResourceTypes from '../AnnotationResourceTypes';
import FormFieldEntrySection from '../form-fields/FormFieldEntrySection';
import FormFieldValue from '../form-fields/FormFieldValue';
import FormFieldParser, { FormField } from '../util/FormFieldParser';

const STYLES = (theme: Theme) =>
  createStyles({
    helperText: {
      marginLeft: theme.spacing(1),
      marginTop: 0,
    },
    fullWidth: {
      width: '100%',
    },
  });

type option = {
  id: number;
  name: string;
};

interface AnnotationEntryProps extends WithStyles<typeof STYLES> {
  onClose: (triggerRefresh: boolean) => void;
  onError: (message: any, callback?: any) => void;
  onInfo: (message: any, callback?: any) => void;
  initialValues: any;
  classes: {
    helperText: string;
    fullWidth: string;
  };
  open: boolean;
  selectedAnnotation?: number;
}

const MIN_DATE_TIME = moment([1900, 0, 1]);
const MAX_DATE_TIME = moment().utc().add(1, 'year');

const resourceTypes = Object.values(ResourceTypes);

const AnnotationEntry: React.VFC<AnnotationEntryProps> = ({
  onClose,
  onInfo,
  onError,
  initialValues,
  classes,
  open,
  selectedAnnotation = undefined,
}: AnnotationEntryProps) => {
  const [resourceType, setResourceType] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [resource, setResource] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [shared, setShared] = useState(false);
  const [flagged, setFlagged] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [resourceOptions, setResourceOptions] = useState([]);
  const [formFields, setFormFields] = useState(new Map());
  const [errorAlert, setErrorAlert] = useState<string | null>();
  const [sourceId, setSourceId] = useState(3);
  const [canEdit, setCanEdit] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // fixes a weird issue where the sourceId is chronically 1 state behind
  // Related to how hook default states are not used until after the first render
  const getCurrentSourceIds = () => {
    if (
      initialValues &&
      initialValues.sourceId &&
      sourceId !== initialValues.sourceId
    ) {
      setSourceId(initialValues.sourceId);
    }
  };
  getCurrentSourceIds();
  // useFormFields hook --------------------------------------------------
  const fieldData = useFormFields(onError, {
    formTypeId: 1,
    sourceIds: sourceId ? sourceId.toString() : '3',
    resourceTypeId: resourceType ? resourceType.value : undefined,
    resourceId: resource ? Number(resource.id) : undefined,
  });
  fieldData.delete('1'); // Remove Date Range Field
  fieldData.delete('2'); // Remove Shared Field
  fieldData.delete('3'); // Remove Flagged Field

  const handleClose = (triggerRefresh) => {
    // Clears out the state when the toolbox is closed so the next dialog doesn't have old information
    setShared(false);
    setFlagged(false);
    setStartTime(new Date());
    setAttemptedSubmit(false);
    setEndTime(null);
    setFormFields(new Map());
    setErrorAlert(null);
    setSourceId(3);
    setCanEdit(true);
    onClose(triggerRefresh);
  };

  const handleFieldChange = (fieldId: string, value: any, type?: string) => {
    const updatedMap = new Map(formFields);
    const data = {
      value,
      type,
    };
    updatedMap.set(fieldId, data);
    setFormFields(updatedMap);
  };

  const renderFields = (fields: Map<string, FormField>) => {
    const fieldArray: JSX.Element[] = [];
    fields.forEach((field) => {
      const fieldValue = formFields.get(field.name);
      const value = fieldValue ? fieldValue.value : null;
      fieldArray.push(
        <Grid item xs={12}>
          <FormFieldValue
            data={field}
            name={field.name}
            value={value}
            disabled={!canEdit}
            onChange={(val, type) => {
              handleFieldChange(field.name, val, type);
            }}
            label={field.label || ''}
          />
        </Grid>
      );
    });
    return fieldArray;
  };

  const renderFieldSections = () => {
    const groups = FormFieldParser.getFormFieldsByGroup(fieldData);
    const groupArray: JSX.Element[] = [];
    groups.forEach((groupData) => {
      groupArray.push(
        <FormFieldEntrySection
          name={groupData.name}
          title={groupData.label}
          className={classes.fullWidth}
        >
          <Grid container spacing={0}>
            {renderFields(groupData.fields)}
          </Grid>
        </FormFieldEntrySection>
      );
    });
    return groupArray;
  };

  useEffect(() => {
    if (selectedAnnotation) {
      AnnotationService.getAnnotation(selectedAnnotation, onError).then(
        (response) => {
          const valueMap = new Map();
          setStartTime(new Date(response.startDate));
          setEndTime(response.endDate ? new Date(response.endDate) : null);
          setShared(response.published);
          setFlagged(response.flagged);
          setSourceId(response.annotationSource.annotationSourceId);
          setResourceType({
            label: response.resourceType.resourceTypeName,
            value: response.resourceType.resourceTypeId,
          });
          setCanEdit(response.permissionToEdit);
          setResource({
            name: response.resource.resourceName,
            id: response.resource.resourceId,
          });
          response.annotationContents.forEach((annotation) => {
            const data = {
              value: annotation.annotation,
              type: annotation.formField.fieldType || 'text',
            };
            valueMap.set(`${annotation.formField.formFieldId}`, data);
          });
          setFormFields(valueMap);
        }
      );
    }
  }, [selectedAnnotation, onError]);

  useEffect(
    () => {
      if (open && initialValues) {
        setResourceType(
          Object.values(ResourceTypes).find(
            (item) => item.value === initialValues.resourceTypeId
          )
        );
        setResource(initialValues.resource);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  useEffect(
    () => {
      if (open && initialValues) {
        if (initialValues.startDate) {
          setStartTime(initialValues.startDate);
        }
        if (initialValues.endDate) {
          setEndTime(initialValues.endDate);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  useEffect(
    () => {
      if (resourceType && resourceType.value) {
        const setResourceNameFromResourceList = (options: [option]) => {
          // Resource is set with initial values. This should prevent the
          // code from getting stuck in an infinite loop
          if (
            resource &&
            resource.id &&
            (!resource.name || resource.name === '')
          ) {
            for (const entry of options) {
              if (entry.id === initialValues.resource.id) {
                setResource({
                  name: entry.name,
                  id: initialValues.resource.id,
                });
              }
            }
          }
        };
        ResourceService.getResourcesByResourceTypeId(`${resourceType.value}`)
          .then((response: any) => {
            setResourceOptions(response);
            setResourceNameFromResourceList(response);
          })
          .catch((error: any) => {
            onError(error.message);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceType, onError]
  );

  const handleSubmit = () => {
    setAttemptedSubmit(true);
    if (!resourceType || !resourceType.value) {
      return setErrorAlert('Please select a resource type.');
    }

    if (!resource || !resource.id) {
      return setErrorAlert('Please select a resource.');
    }

    if (!(startTime instanceof Date) || isNaN(startTime.getTime())) {
      return setErrorAlert('Please enter a start date');
    }

    if (
      moment(startTime) > MAX_DATE_TIME ||
      moment(startTime) < MIN_DATE_TIME
    ) {
      return setErrorAlert(
        `Start date must be in range: ${MIN_DATE_TIME} - ${MAX_DATE_TIME}`
      );
    }

    if (
      endTime instanceof Date &&
      (moment(endTime) > MAX_DATE_TIME || moment(endTime) < MIN_DATE_TIME)
    ) {
      return setErrorAlert(
        `End date must be in range: ${MIN_DATE_TIME} - ${MAX_DATE_TIME}`
      );
    }

    const serviceAnnotation = AnnotationEntryUtil.buildServiceAnnotation(
      resourceType,
      resource,
      formFields,
      shared,
      flagged,
      startTime,
      endTime,
      selectedAnnotation,
      sourceId
    );

    // Update if there is a selected annotation
    if (selectedAnnotation) {
      return AnnotationService.updateAnnotation(serviceAnnotation)
        .then((response) => {
          if (response.data.statusCode === 0) {
            handleClose(true);
            onInfo('Annotation Saved Successfully');
          } else {
            setErrorAlert(response.data.message);
            onError('Error saving Annotation');
          }
        })
        .catch(() => {
          onError('Error saving Annotation');
        });
    }
    return AnnotationService.createAnnotation(serviceAnnotation)
      .then((response) => {
        if (response.data.statusCode === 0) {
          handleClose(true);
          onInfo('Annotation Saved Successfully');
          // refresh deviceListing or SensorListing page after a label is saved
          if (
            window.location.href.includes('sensor_config_tab') ||
            window.location.href.includes('config_tab')
          ) {
            window.location.reload();
          }
        } else {
          setErrorAlert(response.data.message);
          onError('Error saving Annotation');
        }
      })
      .catch(() => {
        onError('Error saving Annotation');
      });
  };

  const handleDelete = () => {
    if (selectedAnnotation) {
      setShowDeleteConfirmation(false);
      return AnnotationService.deleteAnnotation(selectedAnnotation)
        .then((response) => {
          if (response.data.statusCode === 0) {
            onInfo('Annotation Deleted Successfully');
            handleClose(true);
          } else {
            onError('Could not delete annotation');
            handleClose(false);
          }
        })
        .catch(() => {
          onError('Could not delete annotation');
        });
    }
    return undefined;
  };

  const renderDeleteButton = () => {
    if (selectedAnnotation) {
      return (
        <DeleteButton
          variant="outlined"
          disabled={!canEdit}
          onClick={() => setShowDeleteConfirmation(true)}
        />
      );
    }
    return undefined;
  };

  const dateError = attemptedSubmit && startTime === null;

  if (open) {
    return (
      <DraggableToolbox
        title="Annotation Entry"
        initiallyExpanded
        onClose={() => handleClose(false)}
        actions={
          <>
            {renderDeleteButton()}
            <CancelButton onClick={() => handleClose(false)} />
            <SaveButton disabled={!canEdit} onClick={handleSubmit} />
          </>
        }
      >
        <ConfirmationDialog
          title="Delete Annotation?"
          content="Are you sure you want to delete this annotation?"
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          open={showDeleteConfirmation}
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ResourceTypeSelect
              options={resourceTypes}
              fullWidth
              disabled={selectedAnnotation}
              error={attemptedSubmit && !resourceType}
              value={resourceType ? resourceType.value : null}
              onChange={(e: any) => setResourceType(e.target)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              data-test="resource-autocomplete"
              getOptionLabel={(option) => (option ? option.name : undefined)}
              isOptionEqualToValue={(option, val) => option.id === val.id}
              translationKey="common.autoCompletes.resource"
              virtualized
              name="resource"
              fullWidth
              disabled={Boolean(selectedAnnotation)}
              error={attemptedSubmit && !resource}
              options={resourceOptions}
              value={resource || null}
              onChange={(e: any) => setResource(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <DateTimePicker
              translationKey="common.datepickers.startDate"
              value={startTime ? moment.utc(startTime) : null}
              maxDateTime={MAX_DATE_TIME}
              disabled={!canEdit}
              onChange={(date) => setStartTime(date.toDate())}
              fullWidth
              required
            />
            <FormHelperText
              error={dateError}
              margin="dense"
              className={classes.helperText}
            >
              {dateError ? 'A start date is required!' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              translationKey="common.datepickers.endDate"
              value={endTime ? moment.utc(endTime) : null}
              maxDateTime={MAX_DATE_TIME}
              disabled={!canEdit}
              onChange={(date) => setEndTime(date.toDate())}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelledCheckbox
              name="shared-checkbox"
              label="Shared"
              onChange={(event, checked) => setShared(checked)}
              disabled={!canEdit}
              value={shared}
            />
            <LabelledCheckbox
              name="shared-checkbox"
              label="Flagged"
              onChange={(event, checked) => setFlagged(checked)}
              disabled={!canEdit}
              value={flagged}
            />
          </Grid>
          <Grid item xs={12}>
            {renderFieldSections()}
          </Grid>
          <Grid item xs={12}>
            {errorAlert ? <ErrorAlert>{errorAlert}</ErrorAlert> : undefined}
          </Grid>
        </Grid>
      </DraggableToolbox>
    );
  }
  return null;
};

export default withStyles(STYLES)(withSnackbars(AnnotationEntry));
