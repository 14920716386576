import * as React from 'react';
import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { SaveButton, CancelButton } from '@onc/composite-components';
import { Warning } from '@onc/icons';
import { Grid, Switch, Tooltip, Typography } from 'base-components';

import { RORWDropdown } from 'domain/AppComponents/dropdowns/RORWDropdowns';
import { ConfigValue } from './ConfigurationContent';
import { GLOBAL_USER } from './GlobalConfigConstants';
import SeaTubeResourceTypes from '../../../Apps/seatube/util/SeaTubeResourceTypes';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  saveButton: {
    marginRight: '0px',
  },
  flexLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionText: {
    margin: 'auto',
  },
  warning: {
    marginRight: theme.spacing(2),
    color: theme.palette.warning.main,
  },
  selected: {
    color: theme.palette.primary.main,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

type Option = {
  label: string;
  value: number | string;
};

const RESOURCE_TYPE_OPTIONS: Option[] = [
  { label: 'Device', value: 2 },
  { label: 'Device Data', value: 1000 },
];

const RESOURCE_OPTIONS: Option[] = [
  { label: '(no value)', value: 'none' },
  { label: 'Default Value', value: 'default' },
];

interface ResourceConfigurationProps {
  config: ConfigValue;
  onChange: (config: ConfigValue) => void;
  ownerId: number;
  ownerTypeId: number;
  permission: boolean;
  onSave: () => void;
  onCancel: () => void;
  saveDisabled: boolean;
  isLoading: boolean;
  globalConfig: ConfigValue;
}

const ResourceConfiguration: React.FC<ResourceConfigurationProps> = ({
  config,
  onChange,
  ownerId,
  ownerTypeId,
  permission,
  onSave,
  onCancel,
  saveDisabled,
  isLoading,
  globalConfig,
}: ResourceConfigurationProps) => {
  const classes = useStyles();

  const handleChangeLine = (line: string, key: string, value: number) => {
    const newValue = value === -1 ? undefined : value;
    const updatedConfig = _.cloneDeep(config);
    const updatedLine = updatedConfig[line] || { [key]: value };
    updatedLine[key] = newValue;

    /* For deck log, if the resourceType is set to Device or Device Data,
    default to (no value) */
    if (
      line === 'deckLog' &&
      key === 'resourceType' &&
      (value === SeaTubeResourceTypes.DEVICE ||
        SeaTubeResourceTypes.DEVICE_DATA)
    ) {
      updatedLine.resource = 'none';
    }
    updatedConfig[line] = updatedLine;
    /* If a value is undefined, just remove the key/value */
    if (!newValue) {
      delete updatedLine[key];
    }
    /* If the entire line is empty, remove the line from the config */
    if (_.isEmpty(updatedConfig[line])) {
      delete updatedConfig[line];
    }
    onChange(updatedConfig);
  };

  const getOptions = (key: string, line: string, optionList: Option[]) => {
    const isGlobal =
      ownerId === GLOBAL_USER.ID && ownerTypeId === GLOBAL_USER.OWNER_TYPE;
    const options = [...optionList];
    if (key === 'resourceType') {
      if (line !== 'deckLog') {
        options.push({ label: 'Dive', value: 600 });
      } else {
        options.push({ label: 'Expedition', value: 601 });
      }
    }

    if (globalConfig && globalConfig[line] && ownerId !== -1 && !isGlobal) {
      const globalLine = globalConfig[line];
      const globalValue = globalLine[key];
      const globalOption = options.find(
        (option) => option.value === globalValue
      );

      if (globalOption) {
        options.push({ label: `${globalOption.label} (inherited)`, value: -1 });
      }
    }

    return options;
  };

  const renderOverrideWarning = () => ({
    endAdornment: (
      <Tooltip title="This is overriding the default value">
        <Warning className={classes.warning} />
      </Tooltip>
    ),
  });

  const renderResourceLine = (line: string, label: string) => {
    const isGlobal =
      ownerId === GLOBAL_USER.ID && ownerTypeId === GLOBAL_USER.OWNER_TYPE;
    const resourceTypeValue =
      config && config[line] ? config[line].resourceType : undefined;
    const resourceValue =
      config && config[line] ? config[line].resource : undefined;
    const isTypeOveridden =
      !isGlobal && resourceTypeValue !== undefined && resourceTypeValue !== -1;
    const isResourceOveridden =
      !isGlobal && resourceValue !== undefined && resourceValue !== -1;
    return (
      <Grid container spacing={1} role="row" aria-label={label}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="subtitle2">{label}:</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <RORWDropdown
            id="resource-type"
            label="Resource Type"
            permission={permission}
            value={resourceTypeValue || -1}
            options={getOptions('resourceType', line, RESOURCE_TYPE_OPTIONS)}
            onChange={(e) =>
              handleChangeLine(line, 'resourceType', e.target.value)
            }
            InputProps={isTypeOveridden ? renderOverrideWarning() : undefined}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <RORWDropdown
            id="resource"
            label="Resource"
            permission={permission}
            value={resourceValue || -1}
            options={
              isGlobal
                ? RESOURCE_OPTIONS
                : getOptions('resource', line, RESOURCE_OPTIONS)
            }
            onChange={(e) => handleChangeLine(line, 'resource', e.target.value)}
            InputProps={
              isResourceOveridden ? renderOverrideWarning() : undefined
            }
            disabled={
              config &&
              line === 'deckLog' &&
              config[line] &&
              (config[line].resourceType === SeaTubeResourceTypes.DEVICE ||
                config[line].resourceType === SeaTubeResourceTypes.DEVICE_DATA)
            }
          />
        </Grid>
      </Grid>
    );
  };

  const renderFormButtons = () => (
    <div className={classes.flexContainer}>
      <CancelButton onClick={onCancel} />
      <SaveButton
        className={classes.saveButton}
        onClick={onSave}
        disabled={saveDisabled || isLoading}
      />
    </div>
  );
  return (
    <>
      <Typography variant="h6" className={classes.header}>
        Default Resource Type Settings
      </Typography>
      <div className={classes.section}>
        {renderResourceLine('noTaxonomy', 'No Taxonomy (Dive)')}
        {renderResourceLine('deckLog', 'No Taxonomy (Expedition)')}
        {renderResourceLine('cmecs', 'CMECS')}
        {renderResourceLine('worms', 'WoRMS')}
      </div>
      <Typography variant="h6" className={classes.header}>
        Taxonomy Settings
      </Typography>
      <div className={classes.section}>
        <div
          className={classes.flexLine}
          role="row"
          aria-label="nullable attributes"
        >
          <Typography variant="subtitle2">
            Keep Taxonomy After Saving Annotations:
          </Typography>
          <Switch
            inputProps={{ 'aria-label': 'keep taxonomy' }}
            disabled={!permission}
            checked={config?.keepTaxonomy || false}
            onChange={(e) => {
              onChange({
                ...config,
                keepTaxonomy: e.target.checked,
              });
            }}
          />
        </div>
      </div>
      <Typography variant="h6" className={classes.header}>
        Attribute Settings
      </Typography>
      <div className={classes.section}>
        <div
          className={classes.flexLine}
          role="row"
          aria-label="nullable attributes"
        >
          <Typography variant="subtitle2">Allow Null Attributes: </Typography>
          <Switch
            inputProps={{ 'aria-label': 'nullable attributes' }}
            disabled={!permission}
            checked={config?.nullableAttributes || false}
            onChange={(e) => {
              onChange({
                ...config,
                nullableAttributes: e.target.checked,
              });
            }}
          />
        </div>
      </div>
      {permission ? renderFormButtons() : undefined}
    </>
  );
};

export default ResourceConfiguration;
