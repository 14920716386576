/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { HelpIcon } from '@onc/icons';
import { Divider, SizeMe } from 'base-components';
import SeaTubeLogContext from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import SeaTubePermissionContext from 'domain/AppComponents/sea-tube/SeaTubePermissionContext';
import SeaTubeMapProvider from 'domain/Apps/seatube/map/SeaTubeMap';
import {
  ServiceAnnotationV3,
  useSeaTubeAnnotations,
} from 'domain/services/AnnotationService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItem,
} from 'library/CompositeComponents/dashboard/Widget';
import useBroadcast from 'util/hooks/useBroadcast';
import BroadcastChannel from './BroadcastChannel';

const MapWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;

  const { canDelete } = useContext(SeaTubePermissionContext);
  const { dive } = useContext(SeaTubeLogContext);
  const { diveId } = dive;

  // User must have full delete permissions to delete bathymetry
  // canDelete will return true even if annotation is undefined
  const deleteBathymetryPermission = canDelete(undefined);

  const {
    data: annotationPayload,
    invalidateQuery: fetchAnnotations,
    isFetching,
  } = useSeaTubeAnnotations({
    diveIds: diveId,
  });

  const [currentTimestamp, setCurrentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    null,
    id
  );
  const [triggerRefresh, setTriggerRefresh] = useBroadcast(
    dashboardId,
    BroadcastChannel.RefreshAnnotations,
    false,
    id
  );

  const [, setSeekToTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.SeekToTimestamp,
    null,
    id
  );

  const [selectedAnnotationId, setSelectedAnnotationId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.SelectedAnnotationId,
    undefined,
    id
  );

  const [annotations, setAnnotations] =
    useState<ServiceAnnotationV3[]>(undefined);

  useEffect(() => {
    if (triggerRefresh) {
      fetchAnnotations();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  const mapMenu = WidgetMenuItem(
    'help',
    () =>
      window.open(
        'https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-MapWidget'
      ),
    'Help',
    HelpIcon,
    false
  );

  useEffect(() => {
    if (!annotationPayload) {
      return;
    }
    setAnnotations(annotationPayload.annotations);
  }, [annotationPayload]);

  const handleAnnotationClicked = (annotation) => {
    setSelectedAnnotationId(annotation.annotationId);
    setCurrentTimestamp(annotation.startDate);
    setSeekToTimestamp(annotation.startDate);
  };

  const handleDivePathClicked = (timestamp) => {
    setSeekToTimestamp(timestamp);
    setCurrentTimestamp(timestamp);
  };

  return (
    <Widget
      key={id}
      title="Map"
      MenuItems={[mapMenu, <Divider />]}
      loading={isFetching}
      {...props}
    >
      <SizeMe>
        <SeaTubeMapProvider
          onDivePathClick={handleDivePathClicked}
          onAnnotationClick={handleAnnotationClicked}
          currentTimestamp={currentTimestamp}
          diveId={diveId}
          annotations={annotations}
          selectedAnnotationId={selectedAnnotationId}
          bathymetryPermissions={deleteBathymetryPermission}
        />
      </SizeMe>
    </Widget>
  );
};

MapWidget.widgetKey = 'map';
MapWidget.widgetTitle = 'Map';
MapWidget.defaultDataGrid = {
  i: 'map',
  x: 0,
  y: 0,
  w: 5,
  h: 12,
};

export default MapWidget;
