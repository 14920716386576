import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { CancelButton } from '@onc/composite-components';
import { Grid, Paper, Typography, LoadingButton } from 'base-components';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import { UserOrganizationSaveValues } from './UserOrganizationTableLogic';

const useStyles = makeStyles(() => ({
  style: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-8px',
  },
}));

type UserOrganizationProps = {
  users: { userId: number; name: string }[];
  organizations: { organizationId: number; organizationName: string }[];
  initialValue: UserOrganizationSaveValues;
  onClose: () => void;
  onSubmit: (formValues: UserOrganizationSaveValues) => void;
  isCreate: boolean;
  loadingUsers: boolean;
};

const UserOrganizationForm: React.FC<UserOrganizationProps> = ({
  users,
  organizations,
  initialValue,
  onClose,
  onSubmit,
  isCreate,
  loadingUsers,
}: UserOrganizationProps) => {
  const classes = useStyles();

  const formMethods = useForm<UserOrganizationSaveValues>({
    values: initialValue,
  });

  const {
    formState: { isSubmitting },
  } = formMethods;

  return (
    <Paper
      style={{
        padding: '16px',
        maxWidth: '800px',
        width: '94%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Typography variant="h6" gutterBottom>
        {isCreate ? 'Create User Organization' : 'Edit User Organization'}
      </Typography>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12}>
            <FormAutocomplete
              translationKey="common.textfields.name"
              name="user"
              fullWidth
              options={users}
              loading={loadingUsers}
              loadingText="Loading users..."
              noOptionsText={
                loadingUsers ? 'loading users...' : 'No options available'
              }
              getOptionLabel={(option) =>
                option.userId === 0
                  ? 'Select a user...'
                  : `${option.name} (${option.userId})`
              }
              disabled={!isCreate}
              rules={{ required: 'User is required' }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormAutocomplete
              translationKey="networkConsole.organizationName"
              name="organization"
              fullWidth
              options={organizations}
              getOptionLabel={(option) =>
                `${option.organizationName} (${option.organizationId})`
              }
              disabled={isCreate}
              rules={{ required: 'Organization is required' }}
            />
          </Grid>
        </Grid>

        <div className={classes.style}>
          <CancelButton onClick={onClose} />
          <LoadingButton
            variant="contained"
            translationKey="common.buttons.save"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </Form>
    </Paper>
  );
};

export default UserOrganizationForm;
