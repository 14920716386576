import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ContainedButton, TextButton } from '@onc/composite-components';
import { Grid, TextField } from 'base-components';
import TaxonAsyncAutocomplete from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import TaxonomySelect from 'domain/AppComponents/dropdowns/TaxonomySelect';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import OrganizationSelect from '../../dropdowns/OrganizationSelect';

const styles = (theme) => ({
  container: {
    margin: theme.spacing(2),
  },
  actionButtonContainer: {
    textAlign: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

const IMPORTED_TAXONOMIES = ['1', '3']; // WoRMS and CMECS

class BroadSearchFilter extends Component {
  static propTypes = {
    initialFilterValues: PropTypes.shape({ parentTaxonId: PropTypes.number })
      .isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    filter: PropTypes.shape({
      comment: PropTypes.string,
      organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      parentTaxonomy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      taxon: PropTypes.shape({
        label: PropTypes.string,
        taxonId: PropTypes.number,
        value: PropTypes.number,
      }),
    }),
    classes: PropTypes.shape({
      actionButtonContainer: PropTypes.string,
      container: PropTypes.string,
    }).isRequired,
    name: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    filter: {},
    name: 'broadSearch',
    loading: false,
  };

  state = {
    searchTreeNodeOptions: [],
    isLoading: true,
    error: false,
  };

  handleFilterChange = (event) => {
    const { onChange, filter, name } = this.props;
    onChange({
      target: {
        value: { ...filter, [event.target.name]: event.target.value },
        name,
      },
    });
  };

  handleTaxonomyChange = (e) => {
    const userDefined = !IMPORTED_TAXONOMIES.includes(e.target.value);
    this.handleFilterChange({
      target: {
        value: e.target.value,
        name: 'parentTaxonomy',
      },
    });
    this.setState({ taxonomyId: e.target.value, userDefined });
  };

  isSearchDisabled = () => {
    const { filter, loading } = this.props;
    const { comment, parentTaxonomy, taxon } = filter;

    if (loading) return true;
    return !comment && (!parentTaxonomy || !taxon);
  };

  handleReset = () => {
    const { onReset } = this.props;
    onReset();
  };

  render() {
    const { initialFilterValues, filter, classes, onSubmit } = this.props;

    const { taxonomyId, userDefined } = this.state;

    return (
      <div className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TaxonomySelect
              value={filter.parentTaxonomy}
              onChange={this.handleTaxonomyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TaxonAsyncAutocomplete
              taxonomyId={taxonomyId}
              onChange={this.handleFilterChange}
              taxonId={
                filter?.taxon?.taxonId || initialFilterValues.parentTaxonId
              }
              searchLettersRequired={userDefined ? 1 : 3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={filter.comment}
              fullWidth
              onChange={this.handleFilterChange}
              name="comment"
              translationKey="common.textfields.comment"
            />
          </Grid>
          <Grid item xs={12}>
            <OrganizationSelect
              value={filter.organizationId}
              name="organizationId"
              onChange={this.handleFilterChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <div className={classes.actionButtonContainer}>
          <TextButton
            translationKey="common.buttons.reset"
            onClick={this.handleReset}
          />
          <ContainedButton
            onClick={onSubmit}
            disabled={this.isSearchDisabled()}
            translationKey="common.search"
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(BroadSearchFilter));
