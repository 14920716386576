import { Component } from 'react';
import { Box } from '@mui/material';

import { InputLabel } from 'base-components';
import FilterChip from './FilterChip';

type FilterChipGroupProps = {
  label?: string;
  filterList: {
    label: string;
    value: React.ReactNode;
  }[];
  activeFilters: React.ReactNode[];
  onClick: (event: any) => void;
};

class FilterChipGroup extends Component<FilterChipGroupProps> {
  static defaultProps = {
    label: '',
  };

  isFiltered = (value) => {
    const { activeFilters } = this.props;
    return activeFilters.includes(value);
  };

  renderFilters = () => {
    const { filterList, onClick } = this.props;
    return filterList.map((filter) => (
      <FilterChip
        key={filter.label}
        label={filter.label}
        selected={this.isFiltered(filter.value)}
        onClick={() => onClick(filter.value)}
      />
    ));
  };

  render() {
    const { label } = this.props;
    return (
      <Box display="block" flexWrap="wrap">
        <InputLabel shrink>{label}</InputLabel>
        <Box display="flex" flexWrap="wrap">
          {this.renderFilters()}
        </Box>
      </Box>
    );
  }
}

export default FilterChipGroup;
