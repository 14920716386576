/* eslint-disable react-hooks/exhaustive-deps */
import { AnyCnameRecord } from 'dns';
import { useContext, useEffect, useState } from 'react';

import Moment from 'moment';
import { TextButton } from '@onc/composite-components';
import { Info } from '@onc/icons';
import {
  DateTimePicker,
  Checkbox,
  IconButton,
  FormControlLabel,
  TextField,
} from 'base-components';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';
import { useLocalStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import EarthquakeContext from './EarthquakeContext';
import EarthquakeCatalogService from './service/EarthquakeCatalogService';

import { GenericInfoDialog } from '../dialogs/Dialogs';

type EarthquakeFilter = {
  minMag?: number;
  maxMag?: number;
  minDistanceToEdge?: number;
  maxDistanceToEdge?: number;
  dateFrom?: Date;
  dateTo?: Date;
  confirmedDetections?: boolean;
  unconfirmedDetections?: boolean;
  missedDetections?: boolean;
};

const EarthquakeFilterWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const [filteredEarthquakeList, , fetchFilteredEarthquakes] = useWebService({
    method: EarthquakeCatalogService.getFilteredEarthquakes,
  });

  const { id } = props;
  const { earthquakes, dashboardId } = useContext(EarthquakeContext);

  const defaultFilter = {
    minMag: 0,
    maxMag: 10,
    dateFrom: null,
    dateTo: null,
    confirmedDetections: true,
    unconfirmedDetections: true,
    missedDetections: true,
  };

  const [broadCastFilters, setBroadCastFilters] =
    useLocalStorage<EarthquakeFilter>('eew-catalog-filter', defaultFilter);

  const [, setEarthquakeList] = useBroadcast<AnyCnameRecord>(
    dashboardId,
    BroadcastChannel.EarthquakeList,
    earthquakes,
    id
  );

  useEffect(() => {
    setBroadCastFilters(defaultFilter);
    setEarthquakeList(earthquakes);
  }, []);

  useEffect(() => {
    fetchFilteredEarthquakes(broadCastFilters);
  }, [broadCastFilters]);

  useEffect(() => {
    if (filteredEarthquakeList != null) {
      setEarthquakeList(filteredEarthquakeList.earthquakes);
    }
  }, [filteredEarthquakeList]);

  const [confirmedDetections, setConfirmedDetections] = useState<boolean>(true);

  const [unconfirmedDetections, setUnconfirmedDetections] =
    useState<boolean>(true);

  const [missedDetections, setMissedDetections] = useState<boolean>(true);

  const [dateFrom, setDateFrom] = useState<Date>();

  const [dateTo, setDateTo] = useState<Date>();

  const [filterUrl, setFilterUrl] = useState<string>();

  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>();

  const [minMag, setMinMag] = useState<number>(0);

  const [maxMag, setMaxMag] = useState<number>(10);

  const [minDistanceToEdge, setMinDistanceToEdge] = useState<number>();

  const [maxDistanceToEdge, setMaxDistanceToEdge] = useState<number>();

  const applyFiltersFunc = () => {
    let tempUrl = `${Environment.getDmasUrl()}/EarthquakeCatalog?`;
    setFilterUrl(`${Environment.getDmasUrl()}/EarthquakeCatalog?`);

    if (minMag != null) {
      setFilterUrl(`${filterUrl}minMag=${minMag}&`);
      tempUrl += `minMag=${minMag}&`;
    }

    if (maxMag != null) {
      setFilterUrl(`${filterUrl}minMag=${maxMag}&`);
      tempUrl += `maxMag=${maxMag}&`;
    }

    if (minDistanceToEdge) {
      setFilterUrl(`${filterUrl}minDistanceToEdge=${minDistanceToEdge}&`);
      tempUrl += `minDistanceToEdge=${minDistanceToEdge}&`;
    }

    if (maxDistanceToEdge) {
      setFilterUrl(`${filterUrl}maxDistanceToEdge=${maxDistanceToEdge}&`);
      tempUrl += `maxDistanceToEdge=${maxDistanceToEdge}&`;
    }

    if (dateFrom && !isNaN(dateFrom.getDate())) {
      setDateFrom(dateFrom);
      setFilterUrl(`${filterUrl}dateFrom=${Moment(dateFrom).toISOString()}&`);
      tempUrl += `${filterUrl}dateFrom=${Moment(dateFrom).toISOString()}&`;
    }
    if (dateTo && !isNaN(dateTo.getDate())) {
      setDateTo(dateTo);
      tempUrl += `${filterUrl}dateFrom=${Moment(dateTo).toISOString()}&`;
      setFilterUrl(`${filterUrl}dateFrom=${Moment(dateTo).toISOString()}&`);
    }

    tempUrl += `missedDetections=${missedDetections}&confirmedDetections=${confirmedDetections}&unconfirmedDetections=${unconfirmedDetections}`;

    setFilterUrl(tempUrl);

    // Remove null values
    const cleanedFilters = Object.fromEntries(
      Object.entries({
        minMag,
        maxMag,
        minDistanceToEdge,
        maxDistanceToEdge,
        dateFrom,
        dateTo,
        confirmedDetections,
        unconfirmedDetections,
        missedDetections,
      }).filter(([, value]) => value !== null)
    );

    setBroadCastFilters(cleanedFilters);
  };

  return (
    <Widget
      key={id}
      title="Earthquake Filter"
      {...props}
      actionComponents={[
        <IconButton
          Icon={Info}
          onClick={() => setOpenInfoDialog(true)}
          aria-label="Filter Information"
        />,
      ]}
    >
      <GenericInfoDialog
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
        title="Filter Information"
        message="You can filter an earthquake detection on magnitude, location relative to the detection area, origin time, and detection type. The filters are all inclusive; for example, to only see earthquakes within 50 km of the detection area edge, select 0 km and 50 km for the Min and Max Distance to the Edge, respectively. The filters are applied only when the APPLY FILTER button is clicked. The filters can also be shared with a URL that loads the page with the applied filters set. A missed detection is not detected by ONC but is detected by another organization. An unconfirmed detection is detected by ONC but is not detected by any other organization. A confirmed detection is detected by ONC and at least one other organization."
      />
      <form onSubmitCapture={applyFiltersFunc}>
        <div>
          <TextField
            translationKey="earthquake.minMag"
            title="Minimum Magnitude (inclusive)"
            type="number"
            value={minMag ?? ''}
            onChange={(e) =>
              setMinMag(e.target.value !== '' ? Number(e.target.value) : null)
            }
            error={minMag === null}
          />
          <TextField
            translationKey="earthquake.maxMag"
            title="Maximum Magnitude (inclusive)"
            type="number"
            value={maxMag ?? ''}
            onChange={(e) =>
              setMaxMag(e.target.value !== '' ? Number(e.target.value) : null)
            }
            error={maxMag === null}
          />
          <TextField
            translationKey="earthquake.minDistanceToEdge"
            title="Minimum distance to detection area edge (km, inclusive)"
            type="number"
            value={minDistanceToEdge ?? ''}
            onChange={(e) =>
              setMinDistanceToEdge(
                e.target.value !== '' ? Number(e.target.value) : null
              )
            }
            error={minDistanceToEdge === null}
          />
          <TextField
            translationKey="earthquake.maxDistanceToEdge"
            title="Maximum distance to detection area edge (km, inclusive)"
            type="number"
            value={maxDistanceToEdge ?? ''}
            onChange={(e) =>
              setMaxDistanceToEdge(
                e.target.value !== '' ? Number(e.target.value) : null
              )
            }
            error={maxDistanceToEdge === null}
          />
          <DateTimePicker
            translationKey="common.datepickers.startDate"
            value={dateFrom ? Moment.utc(dateFrom) : null}
            onChange={(newDateFrom) => {
              setDateFrom(newDateFrom.local().toDate());
            }}
          />
          <DateTimePicker
            translationKey="common.datepickers.endDate"
            value={dateTo ? Moment.utc(dateTo) : null}
            onChange={(newDateTo) => {
              setDateTo(newDateTo.local().toDate());
            }}
          />
          <div>
            <FormControlLabel
              title="Detections by NRCan and/or USGS but not ONC"
              control={
                <Checkbox
                  onChange={() => {
                    setMissedDetections(!missedDetections);
                  }}
                  checked={missedDetections}
                />
              }
              label="Missed Detections"
            />
          </div>
          <div>
            <FormControlLabel
              title="Detections by ONC only"
              control={
                <Checkbox
                  onChange={() => {
                    setUnconfirmedDetections(!unconfirmedDetections);
                  }}
                  checked={unconfirmedDetections}
                />
              }
              label="Unconfirmed Detections"
            />
          </div>
          <div>
            <FormControlLabel
              title="Detections by NRCan and/or USGS plus ONC"
              control={
                <Checkbox
                  onChange={() => {
                    setConfirmedDetections(!confirmedDetections);
                  }}
                  checked={confirmedDetections}
                />
              }
              label="Confirmed Detections"
            />
          </div>
        </div>
        <div>
          <TextButton
            onClick={applyFiltersFunc}
            translationKey="seatube.applyFilter"
          />
          <TextButton
            translationKey="common.buttons.reset"
            data-label="filterGroupReset"
            onClick={() => {
              setBroadCastFilters(defaultFilter);
              // Reset state values
              setMinMag(defaultFilter.minMag);
              setMaxMag(defaultFilter.maxMag);
              setMinDistanceToEdge(undefined);
              setMaxDistanceToEdge(undefined);
              setDateFrom(undefined);
              setDateTo(undefined);
              setConfirmedDetections(defaultFilter.confirmedDetections);
              setUnconfirmedDetections(defaultFilter.unconfirmedDetections);
              setMissedDetections(defaultFilter.missedDetections);
            }}
          />
        </div>
      </form>
    </Widget>
  );
};

EarthquakeFilterWidget.widgetKey = 'earthquake-filters';
EarthquakeFilterWidget.widgetTitle = 'Earthquake Filter';
EarthquakeFilterWidget.defaultDataGrid = {
  i: 'earthquake-filter',
  x: 0,
  y: 0,
  w: 2,
  h: 6,
};

export default EarthquakeFilterWidget;
