import { useState } from 'react';
import moment, { Moment } from 'moment';
import { Control, useForm, useWatch } from 'react-hook-form';
import {
  Loading,
  CancelButton,
  DeleteButton,
  SaveButton,
  TextButton,
} from '@onc/composite-components';
import { Box, Divider, ErrorPage, Grid, Typography } from 'base-components';
import SiteDeviceSubsetTable from 'domain/Apps/site-device-maintenance/SiteDeviceSubsetTable';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import Panel from 'library/CompositeComponents/panel/Panel';
import Environment from 'util/Environment';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { handleRedirect, redirect } from './RedirectLink';
import ErddapDatasetLinks from '../datasets/ErddapDatasetLinks';
import SiteDeviceDOIDatasetLinks from '../datasets/SiteDeviceDOIDatasetLinks';
import SiteDropdown from '../dropdowns/SiteDropdown';
import { ModifyBy, ModifyDate } from '../Form/Fields/ReadOnlyFields';

const classes = {
  entryFormContainer: {
    paddingLeft: 3,
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: 2,
  },
};

type SiteDeviceResponse = {
  siteDeviceId: number;
  deviceId: number;
  siteId: number;
  siteName: string;
  dateFrom: string;
  comment: string;
  modifyBy: string;
  modifyDate: string;
  offsetRoll: number;
  offsetPitch: number;
  offsetHeading: number;
  offsetLat: number;
  offsetLong: number;
  offsetDepth: number;
  datasetsInErddap: boolean;
};

type SiteDevicePostProps = {
  deviceId: number;
  pickedSiteId: string;
  pickerDate: string;
  pickerTime: string;
  comment: string;
  offsetLatitude: number;
  offsetLongitude: number;
  offsetDepth: number;
  offsetHeading: number;
  offsetPitch: number;
  offsetRoll: number;
  batchId: number;
  siteDeviceId?: number;
};

type SiteDeviceMaintenanceRWForm = {
  deviceId: number;
  siteName: string;
  dateFrom: Moment;
  comment: string;
  offsetLatitude: string;
  offsetLongitude: string;
  offsetDepth: string;
  offsetHeading: string;
  offsetPitch: string;
  offsetRoll: string;
};

type SiteDeviceMaintenanceRWProps = {
  siteDeviceId?: number;
  siteId?: number;
  deviceId?: number;
  isFromSite?: boolean;
  history: {
    push: (url: string) => void;
  };
};

// Dynamic label kills performance unless field is re-rendered separately
function CommentField({
  control,
}: {
  control: Control<SiteDeviceMaintenanceRWForm>;
}) {
  const comment = useWatch({
    control,
    name: 'comment',
    defaultValue: '0',
  });

  return (
    <FormTextField
      name="comment"
      translationKey="common.textfields.commentCharCount"
      translationOptions={{ charCount: comment ? comment.length : 0 }}
      multiline
      fullWidth
      rows={4}
      rules={{
        validate: {
          verifyLength: (input) =>
            input.length <= 255 ||
            'Comment is too long -- must be under 255 characters',
        },
      }}
    />
  );
}

const SiteDeviceMaintenanceRW = ({
  siteDeviceId = undefined,
  siteId = undefined,
  deviceId = undefined,
  isFromSite = false,
  history,
}: SiteDeviceMaintenanceRWProps) => {
  const { onError, onInfo } = useSnackbars();
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);

  const editingSiteDevice = !isNaN(siteDeviceId) && siteDeviceId !== null;

  const updateURL = (params) => {
    const url = {
      siteDeviceId: params.siteDeviceId,
      deviceId: params.deviceId,
      siteId: params.siteId,
    };
    history.push(
      `?${Object.keys(url)[0]}=${Object.values(url)[0]}&${
        Object.keys(url)[1]
      }=${Object.values(url)[1]}&${Object.keys(url)[2]}=${
        Object.values(url)[2]
      }${isFromSite ? `&isFromSite=Y` : ''}`
    );
  };

  const siteDeviceData = useGet<SiteDeviceResponse, { siteDeviceId: number }>(
    'SiteDeviceService',
    {
      operation: 4,
      options: {
        enabled: editingSiteDevice,
      },
    },
    {
      siteDeviceId,
    }
  );

  const { mutate: saveSiteDevice } = usePost<
    Partial<SiteDevicePostProps>,
    SiteDeviceResponse
  >(
    'SiteDeviceService',
    {
      onSuccess: (payload) => {
        if (payload.siteDeviceId !== 0) {
          onInfo('Site Device Saved!');
          updateURL(payload);
        } else {
          throw new Error();
        }
        setIsPosting(false);
        redirect({
          isFromSite,
          siteId: payload.siteId,
          deviceId: payload.deviceId,
        });
      },
      onError: (e) => {
        onError(`Site Device not updated: ${e.message}`);
        setIsPosting(false);
      },
    },
    1
  );

  const { mutate: updateSiteDevice } = usePost<
    Partial<SiteDevicePostProps>,
    SiteDeviceResponse
  >(
    'SiteDeviceService',
    {
      onSuccess: (payload) => {
        if (payload.siteDeviceId !== 0) {
          onInfo('Site Device Updated!');
          updateURL(payload);
        } else {
          throw new Error();
        }
        setIsPosting(false);
        redirect({
          isFromSite,
          siteId: payload.siteId,
          deviceId: payload.deviceId,
        });
      },
      onError: (e) => {
        onError(`Site Device not updated: ${e.message}`);
        setIsPosting(false);
      },
    },
    2
  );

  const { mutate: deleteSiteDevice } = usePost<
    { siteDeviceId: number },
    SiteDeviceResponse
  >(
    'SiteDeviceService',
    {
      onSuccess: () => {
        onInfo(`Site Device Deleted!`);
        setIsPosting(false);
        redirect({
          isFromSite,
          siteId,
          deviceId,
        });
      },
      onError: (e) => {
        onError(`Site Device not deleted: ${e.message}`);
        setIsPosting(false);
      },
    },
    3
  );

  const formMethods = useForm<SiteDeviceMaintenanceRWForm>({
    mode: 'onBlur',
    defaultValues: {
      deviceId,
      siteName: '',
      dateFrom: undefined,
      comment: '',
      offsetLatitude: '',
      offsetLongitude: '',
      offsetDepth: '',
      offsetHeading: '',
      offsetPitch: '',
      offsetRoll: '',
    },
  });

  if (editingSiteDevice && siteDeviceData.isLoading) {
    return <Loading />;
  }

  if (editingSiteDevice && siteDeviceData.error) {
    return <ErrorPage />;
  }

  const getBatchId = () => {
    const batchdropdown = document.getElementById(
      'batch-drop-down'
    ) as HTMLSelectElement;
    const batchId = parseInt(batchdropdown?.value, 10);
    return isNaN(batchId) ? undefined : batchId;
  };

  const handleErddapManagementButton = () => {
    onInfo('Opening ErddapManagementTable...');
    window.open(
      `${Environment.getDmasUrl()}/ErddapManagementTable?resourceId=${siteDeviceId}`,
      '_blank'
    );
  };

  const handleDelete = async () => {
    onInfo('Deleting site device...');
    setIsPosting(true);
    deleteSiteDevice({ siteDeviceId });
  };

  const saveSiteDeviceData = async (params) => {
    onInfo('Saving site device...');
    setIsPosting(true);

    // Function to prune empty properties
    const pruneEmptyProperties = (obj) =>
      Object.fromEntries(
        Object.entries(obj).filter(
          ([, value]) => value !== null && value !== undefined && value !== ''
        )
      );

    // Prune empty properties from params
    const prunedParams = pruneEmptyProperties(params);

    if (editingSiteDevice) {
      updateSiteDevice(prunedParams);
    } else {
      saveSiteDevice(prunedParams);
    }
  };

  const handleSubmit = (values: SiteDeviceMaintenanceRWForm) => {
    const props = {
      deviceId: deviceId || values.deviceId,
      siteId: isFromSite ? siteId : values.siteName,
      dateFrom: values.dateFrom?.toISOString(),
      comment: values.comment,
      offsetLatitude:
        values.offsetLatitude !== '' ? Number(values.offsetLatitude) : '',
      offsetLongitude:
        values.offsetLongitude !== '' ? Number(values.offsetLongitude) : '',
      offsetDepth: values.offsetDepth !== '' ? Number(values.offsetDepth) : '',
      offsetHeading:
        values.offsetHeading !== '' ? Number(values.offsetHeading) : '',
      offsetPitch: values.offsetPitch !== '' ? Number(values.offsetPitch) : '',
      offsetRoll: values.offsetRoll !== '' ? Number(values.offsetRoll) : '',
      batchId: getBatchId(),
      ...(editingSiteDevice ? { siteDeviceId } : {}),
    };
    saveSiteDeviceData(props);
  };

  if (!renderedOnce) {
    formMethods.reset({
      deviceId: siteDeviceData.data?.deviceId ?? deviceId ?? undefined,
      siteName: siteDeviceData.data?.siteId?.toString(),
      dateFrom: siteDeviceData.data?.dateFrom
        ? moment.utc(siteDeviceData.data?.dateFrom)
        : undefined,
      comment: siteDeviceData.data?.comment || '',
      offsetLatitude: siteDeviceData.data?.offsetLat?.toString() || '',
      offsetLongitude: siteDeviceData.data?.offsetLong?.toString() || '',
      offsetDepth: siteDeviceData.data?.offsetDepth?.toString() || '',
      offsetHeading: siteDeviceData.data?.offsetHeading?.toString() || '',
      offsetPitch: siteDeviceData.data?.offsetPitch?.toString() || '',
      offsetRoll: siteDeviceData.data?.offsetRoll?.toString() || '',
    });
    if (editingSiteDevice) {
      updateURL(siteDeviceData.data);
    }
    setRenderedOnce(true);
  }

  return (
    // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
    <div>
      <Panel
        title={
          <>
            <Typography variant="body1">Site Device Entry ID:</Typography>
            <Typography
              variant="body1"
              color={editingSiteDevice ? 'textPrimary' : 'textSecondary'}
            >
              {editingSiteDevice ? siteDeviceId : 'New'}
            </Typography>
            <TextButton
              translationKey="erddapmanagement.erddapManagement"
              onClick={handleErddapManagementButton}
              disabled={!editingSiteDevice}
            />
          </>
        }
      >
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid container direction="row" sx={classes.entryFormContainer}>
            <Grid item xs={6}>
              <FormTextField
                name="deviceId"
                type="number"
                translationKey="device.deviceId"
                rules={{ required: 'A device ID is required' }}
                disabled={!isFromSite && !!deviceId}
              />
              <SiteDropdown
                name="siteName"
                label="Site Name"
                defaultValue={siteId}
                fullWidth
                disabled={isFromSite}
              />
              <CommentField control={formMethods.control} />
              <FormDateTimePicker
                translationKey="common.datepickers.startDate"
                name="dateFrom"
              />
              <FormTextField
                name="offsetLatitude"
                translationKey="device.offsetLatitude"
                fullWidth
                type="number"
              />
              <FormTextField
                name="offsetLongitude"
                translationKey="device.offsetLongitude"
                fullWidth
                type="number"
              />
              <FormTextField
                name="offsetDepth"
                translationKey="device.offsetDepth"
                fullWidth
                type="number"
              />
              <FormTextField
                name="offsetHeading"
                translationKey="device.offsetHeading"
                fullWidth
                type="number"
              />
              <FormTextField
                name="offsetPitch"
                translationKey="device.offsetPitch"
                fullWidth
                type="number"
              />
              <FormTextField
                name="offsetRoll"
                translationKey="device.offsetRoll"
                fullWidth
                type="number"
              />
              <ModifyBy username={siteDeviceData.data?.modifyBy} />
              <ModifyDate date={siteDeviceData.data?.modifyDate} />
              <SiteDeviceDOIDatasetLinks siteDeviceId={siteDeviceId} />
              <ErddapDatasetLinks siteDeviceId={siteDeviceId} />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <SaveButton type="submit" disabled={isPosting} />
            <CancelButton
              onClick={handleRedirect({
                isFromSite,
                siteId,
                deviceId,
              })}
              disabled={isPosting}
            />
            <DeleteButton
              onClick={handleDelete}
              disabled={!editingSiteDevice || isPosting}
            />
          </Box>
        </Form>
        <Divider variant="middle" />
        <SiteDeviceSubsetTable siteDeviceId={siteDeviceId} />
      </Panel>
    </div>
  );
};

export default SiteDeviceMaintenanceRW;
