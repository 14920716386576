import { memo, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { DenseThemeProvider } from '@onc/theme';
import { StepDisplayInfo, Paper } from 'base-components';
import DeviceInfo from 'domain/Apps/device-listing/DeviceInfo';
import ActionService from 'domain/services/ActionService';
import CruiseService from 'domain/services/CruiseService';
import DeviceActionService from 'domain/services/DeviceActionService';
import SiteDeviceService from 'domain/services/SiteDeviceService';
import SiteService from 'domain/services/SiteService';
import LinearOncStepper from 'library/CompositeComponents/stepper/LinearOncStepper';
import Step from 'library/CompositeComponents/stepper/Step';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import DeviceBulkUpdateDeviceActionForm, {
  type DeviceActionBulkUpdateForm,
} from './DeviceBulkUpdateDeviceActionForm';
import DeviceBulkUpdateDeviceActionTable, {
  transformDeviceActionToRow,
  type DeviceActionTableRow,
} from './DeviceBulkUpdateDeviceActionTable';
import DeviceBulkUpdateFilterStep, {
  type Module,
} from './DeviceBulkUpdateFilterStep';
import DeviceBulkUpdateSiteDeviceForm, {
  type SiteDeviceBulkUpdateForm,
} from './DeviceBulkUpdateSiteDeviceForm';
import DeviceBulkUpdateSiteDeviceTable, {
  transformSiteDeviceToRow,
  type SiteDeviceTableRow,
} from './DeviceBulkUpdateSiteDeviceTable';
import type { CommunityFishersDevicePackage } from 'domain/Apps/community-fishers/CommunityFishersDevicePackageTypes';

export type SaveType = 'Add' | 'Update';

const useStyles = makeStyles((theme) =>
  createStyles({
    devicebulkupdatepage: {
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(),
        paddingLeft: theme.spacing(),
      },
    },
  })
);

const DeviceBulkUpdatePage = () => {
  const classes = useStyles();
  const [cfSets, setCFSets] = useState<CommunityFishersDevicePackage[]>([]);
  const [devices, setDevices] = useState<DeviceInfo[]>([]);
  const [deviceActions, setDeviceActions] = useState<DeviceActionTableRow[]>(
    []
  );
  const [siteDevices, setSiteDevices] = useState<SiteDeviceTableRow[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [module, setModule] = useState<Module | undefined>();

  const [, , fetchDeviceAction] = useWebService({
    method: DeviceActionService.getMany,
  });

  const [, , fetchSiteDevice] = useWebService({
    method: SiteDeviceService.getMany,
  });
  const [actions, , fetchActions] = useWebService({
    method: ActionService.getAllActions,
  });

  const [cruises, , fetchCruises] = useWebService({
    method: CruiseService.getCruises,
  });

  const [sites, , fetchSites] = useWebService({
    method: SiteService.getAllSites,
  });

  const [, , updateDeviceAction] = useWebService({
    method: DeviceActionService.update,
  });

  const [, , saveDeviceAction] = useWebService({
    method: DeviceActionService.create,
  });

  const [, , updateSiteDevice] = useWebService({
    method: SiteDeviceService.update,
  });

  const [, , saveSiteDevice] = useWebService({
    method: SiteDeviceService.save,
  });

  const { onInfo, onError } = useSnackbars();

  const handleDeviceChange = (event, option: DeviceInfo[]) => {
    setDevices(option);
    setCFSets((prevCFSets) =>
      prevCFSets.filter((cfSet) => {
        const cfSetDeviceIds = [
          ...(cfSet.deviceInformation?.ctdDeviceInfos || []),
          ...(cfSet.deviceInformation?.navDeviceInfos || []),
          ...(cfSet.deviceInformation?.piggybackDeviceInfos || []),
        ].map((device) => device.deviceId);

        return cfSetDeviceIds.every((deviceId) =>
          option.some((device) => device.deviceId === deviceId)
        );
      })
    );
  };

  const handleCFSetChange = (
    event,
    option: CommunityFishersDevicePackage[]
  ) => {
    setCFSets(option);

    const allDevices = option.flatMap((devicePackage) =>
      [
        ...(devicePackage.deviceInformation?.ctdDeviceInfos || []),
        ...(devicePackage.deviceInformation?.navDeviceInfos || []),
        ...(devicePackage.deviceInformation?.piggybackDeviceInfos || []),
      ].map((device) => ({
        deviceId: device.deviceId,
        deviceName: device.deviceName,
      }))
    );

    setDevices((prevDevices) => {
      const newDeviceIds = new Set(allDevices.map((device) => device.deviceId));

      const filteredDevices = prevDevices.filter((device) =>
        newDeviceIds.has(device.deviceId)
      );

      const appendedDevices = allDevices.filter(
        (device) =>
          !prevDevices.some(
            (prevDevice) => prevDevice.deviceId === device.deviceId
          )
      );

      return [...filteredDevices, ...appendedDevices];
    });
  };

  const [isPerformingAction, setIsPerformingAction] = useState<boolean>(false);

  const [stepNum, setStepNum] = useState<number>(1);
  const displayInfo: StepDisplayInfo[] = [
    {
      label: 'Filters',
    },
    {
      label: 'Results',
    },
    {
      label: 'Action',
    },
  ];

  const decrementStep = () => {
    if (stepNum === 2) {
      setSelection([]);
    }
    setStepNum(stepNum - 1);
  };

  const incrementStep = () => setStepNum(stepNum + 1);

  const applyFilters = async () => {
    if (module === 'Device Action') {
      const promises = devices.map((device) =>
        fetchDeviceAction(device.deviceId).then((response) => {
          if (response.length !== 0) {
            return transformDeviceActionToRow(response[0]);
          }
          return transformDeviceActionToRow({
            deviceId: device.deviceId,
            deviceName: device.deviceName,
          });
        })
      );
      const records = await Promise.all(promises);
      setDeviceActions(records);
    } else {
      const promises = devices.map((device) =>
        fetchSiteDevice(device.deviceId).then((response) => {
          if (response.length !== 0) {
            return transformSiteDeviceToRow({
              deviceName: device.deviceName,
              ...response[response.length - 1],
            });
          }
          return transformSiteDeviceToRow({
            deviceId: device.deviceId,
            deviceName: device.deviceName,
          });
        })
      );
      const records = await Promise.all(promises);
      setSiteDevices(records);
    }
  };

  const submitFilters = () => {
    setIsPerformingAction(true);
    applyFilters().then(() => {
      incrementStep();
      setIsPerformingAction(false);
    });
  };

  const submitResults = async () => {
    setIsPerformingAction(true);
    if (module === 'Device Action') {
      await Promise.all([fetchActions(), fetchCruises()]);
    } else {
      await fetchSites();
    }
    incrementStep();
    setIsPerformingAction(false);
  };

  const handleDeviceActionSubmit = ({
    action,
    cruise,
    performedDate,
    actionCost,
    description,
    saveType,
  }: DeviceActionBulkUpdateForm) => {
    setIsPerformingAction(true);
    const promises = selection.map((rowId) => {
      const [deviceId, deviceActionId] = rowId.split('-');
      const actionFunction =
        saveType === 'Update' ? updateDeviceAction : saveDeviceAction;
      return actionFunction({
        deviceId,
        deviceActionId: saveType === 'Update' ? deviceActionId : 0,
        actionId: action?.actionId,
        cruise: cruise?.cruiseId,
        performedDateTime: performedDate?.toISOString(),
        actionCost,
        actionDescription: description,
      })
        .then((response) => {
          if (
            (response.deviceActionId === Number(deviceActionId)) ===
            (saveType === 'Update')
          ) {
            setSelection((prev) => [
              ...prev,
              `${response.deviceId}-${response.deviceActionId}`,
            ]);
          } else {
            onError(
              `Error ${saveType}ing DeviceAction for Device: ${deviceId}`
            );
          }
        })
        .catch((err) => {
          onError(err);
        });
    });

    Promise.all(promises)
      .then(async () => {
        await applyFilters();
        onInfo(`Device Actions ${saveType}ed successfully`);
        decrementStep();
        setIsPerformingAction(false);
      })
      .catch((err) => {
        onError(err);
        setIsPerformingAction(false);
      });
  };

  const handleSiteDeviceSubmit = ({
    site,
    comment,
    dateFrom,
    offsetLatitude,
    offsetLongitude,
    offsetDepth,
    offsetHeading,
    offsetPitch,
    offsetRoll,
    saveType,
  }: SiteDeviceBulkUpdateForm) => {
    setIsPerformingAction(true);
    const promises = selection.map((rowId) => {
      const [deviceId, siteDeviceId] = rowId.split('-');
      const actionFunction =
        saveType === 'Update' ? updateSiteDevice : saveSiteDevice;
      return actionFunction({
        deviceId,
        siteDeviceId: saveType === 'Update' ? siteDeviceId : undefined,
        siteId: site?.siteid,
        dateFrom: dateFrom?.toISOString(),
        comment,
        offsetLatitude,
        offsetLongitude,
        offsetDepth,
        offsetHeading,
        offsetPitch,
        offsetRoll,
      })
        .then((response) => {
          if (
            (response.siteDeviceId === Number(siteDeviceId)) ===
            (saveType === 'Update')
          ) {
            setSelection((prev) => [
              ...prev,
              `${response.deviceId}-${response.siteDeviceId}`,
            ]);
          } else {
            onError(`Error ${saveType}ing SiteDevice for Device: ${deviceId}`);
          }
        })
        .catch((err) => onError(err));
    });

    Promise.all(promises)
      .then(async () => {
        await applyFilters();
        onInfo(`Site Devices ${saveType}ed successfully`);
        decrementStep();
        setIsPerformingAction(false);
      })
      .catch((err) => {
        onError(err);
        setIsPerformingAction(false);
      });
  };

  return (
    <div className={classes.devicebulkupdatepage}>
      <DenseThemeProvider>
        <LinearOncStepper
          displayInfo={displayInfo}
          showLabelBelow
          stepNum={stepNum}
        >
          <Step
            content={
              <DeviceBulkUpdateFilterStep
                selectedCFSets={cfSets}
                handleCFSetChange={handleCFSetChange}
                selectedDevices={devices}
                handleDeviceChange={handleDeviceChange}
                module={module}
                setModule={setModule}
                isPerformingAction={isPerformingAction}
              />
            }
            isNextDisabled={() => devices.length === 0 || !module}
            isNextLoading={isPerformingAction}
            isBackDisabled={() => true}
            onBack={() => {}}
            onNext={submitFilters}
          />
          <Step
            content={
              module === 'Device Action' ? (
                <DeviceBulkUpdateDeviceActionTable
                  rows={deviceActions}
                  selection={selection}
                  setSelection={setSelection}
                />
              ) : (
                <DeviceBulkUpdateSiteDeviceTable
                  rows={siteDevices}
                  selection={selection}
                  setSelection={setSelection}
                />
              )
            }
            isNextDisabled={() => selection.length === 0}
            isNextLoading={isPerformingAction}
            onBack={decrementStep}
            onNext={submitResults}
          />
          <Step
            content={
              <Paper elevation={1}>
                {module === 'Device Action' ? (
                  <DeviceBulkUpdateDeviceActionForm
                    actions={actions}
                    cruises={cruises?.cruises}
                    onSubmit={handleDeviceActionSubmit}
                    isLoading={isPerformingAction}
                  />
                ) : (
                  <DeviceBulkUpdateSiteDeviceForm
                    sites={sites}
                    onSubmit={handleSiteDeviceSubmit}
                    isLoading={isPerformingAction}
                  />
                )}
              </Paper>
            }
            isNextDisabled={() => true}
            isBackDisabled={() => isPerformingAction}
            onBack={decrementStep}
            onNext={() => {}}
          />
        </LinearOncStepper>
      </DenseThemeProvider>
    </div>
  );
};

export default memo(DeviceBulkUpdatePage);
