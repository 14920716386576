import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { type FlacFile } from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidget';
import AudioPlayerWidgetDisplay from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidgetDisplay';
import { type AudioPlayerWidgetFormType } from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidgetFormContents';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';

import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';
import AudioPlayerWidgetConfig from './Config/AudioPlayerWidgetConfig';

export const defaultAudioConfig: AudioPlayerWidgetFormType = {
  audioFormat: 'flac',
  clipSelectorValue: 'latestClip',
  dataSourceType: 'device',
  device: {
    dataRating: [
      {
        dateFrom: '2018-09-07T00:00:00.000Z',
        dateTo: null,
        samplePeriod: 60,
        sampleSize: 1,
      },
    ],
    deviceCategoryCode: 'HYDROPHONE',
    deviceCode: 'ICLISTENAF2556',
    deviceId: 28559,
    name: 'Ocean Sonics icListen AF Hydrophone 2556',
  },
  endDate: moment(new Date()),
  isBroadcasting: false,
  locations: [],
  loopPlayback: false,
  startDate: moment(new Date()),
  showTitle: true,
  title: 'Sample Audio',
};

const fileNameFormatter = (fileObject: FlacFile) =>
  `${fileObject.archiveLocation}/${fileObject.path}/${fileObject.filename}`;

const AudioPlayerWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, initialConfig } = props;
  const { config, setConfig } = useWidgetConfig<AudioPlayerWidgetFormType>(id, {
    ...defaultAudioConfig,
    ...initialConfig,
  });
  const {
    locations,
    loopPlayback,
    isBroadcasting,
    device,
    clipSelectorValue,
    startDate,
    dataSourceType,
    endDate,
    audioFormat,
  } = config;

  const [flacData] = useBroadcast<FlacFile>(
    '0',
    BroadcastChannel.FlacData,
    undefined
  );

  const latestSpectrogramFlac = flacData
    ? `${Environment.getADProxyUrl()}${fileNameFormatter(flacData)}`
    : null;

  return (
    <Widget
      key={id}
      titleComponents={
        <Typography variant="subtitle1">{config.title}</Typography>
      }
      configTitle="Audio Player Widget"
      ConfigFieldsComponent={AudioPlayerWidgetConfig}
      onChangeConfig={setConfig}
      config={config}
      collapseHeader={config.showTitle}
      closeMenuItems
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        <Grid item aria-label="widgetContent" xs={12} sx={{ height: '99%' }}>
          <AudioPlayerWidgetDisplay
            locations={locations}
            loopPlayback={loopPlayback}
            showTitle
            isBroadcasting={isBroadcasting}
            title=""
            device={device}
            clipSelectorValue={clipSelectorValue}
            startDate={startDate}
            dataSourceType={dataSourceType}
            endDate={endDate}
            audioFormat={audioFormat}
            flacData={flacData}
            latestSpectrogramFlac={latestSpectrogramFlac}
            classes={{ circularProgress: '', contentDiv: '' }}
            isAutoRefreshing={false}
            widgetId=""
          />
        </Grid>
      </Grid>
    </Widget>
  );
};

AudioPlayerWidget.widgetKey = 'audio-player-widget';
AudioPlayerWidget.widgetTitle = '';
AudioPlayerWidget.defaultDataGrid = {
  x: 0,
  y: 0,
  w: 4,
  h: 6,
};

export default AudioPlayerWidget;
