import { useMemo } from 'react';
import { useQueries } from '@onc/service';
import {
  DeploymentDataSource,
  LocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import DeploymentsWebService, {
  type Deployment,
} from 'domain/services/DeploymentsWebService';

type SourcedLocationDeployment = Deployment & {
  dataSource: DeploymentDataSource | LocationDataSource;
};

const useLocations = (
  locations: LocationDataSource[],
  disabled: boolean = false,
  additionalParams: Record<string, any> = {}
) => {
  // queries will be a different object on each render.
  // TODO: when we upgrade to tanstack query v5 all of the following memos can be replaced by using the combine property
  const queries = useQueries({
    queries: locations.map((location) => ({
      queryKey: [
        'multipleLocationDeployments',
        location.stationCode,
        location.deviceCategoryCode,
      ],
      queryFn: async (): Promise<SourcedLocationDeployment[]> => {
        if (!disabled) {
          const response = await DeploymentsWebService.get({
            locationCode: location.stationCode,
            deviceCategoryCode: location.deviceCategoryCode,
            ...additionalParams,
          });
          return (response.data as Deployment[]).map((deployment) => ({
            ...deployment,
            dataSource: location,
          }));
        }
        return [];
      },
    })),
  });

  const deploymentsData = useMemo(
    () => queries.flatMap((query) => query.data ?? []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queries)]
  );

  const isFetching = useMemo(
    () => queries.some((query) => query.isFetching),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queries)]
  );

  const errors = useMemo(
    () => queries.filter((query) => query.isError).map((query) => query.error),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queries)]
  );

  const result = useMemo(
    () => ({
      deploymentsData,
      isFetching,
      queries,
      errors,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deploymentsData, isFetching, errors, JSON.stringify(queries)]
  );

  return result;
};

export default useLocations;
