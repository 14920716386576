import { memo, useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { ClearButton } from '@onc/composite-components';
import { Autocomplete, Box } from 'base-components';
import ProcessGroupService, {
  ProcessGroup,
} from 'domain/services/ProcessGroupService';
import ProcessService, {
  AreasOfResponsibility,
  Phase,
  PhaseTask,
  Process,
} from 'domain/services/ProcessService';
import useWebService from 'util/hooks/useWebService';

const useStyles = makeStyles((theme) =>
  createStyles({
    styling: {
      paddingTop: theme.spacing(1),
    },
    text: {
      paddingLeft: theme.spacing(1),
    },
  })
);

export type Device = {
  deviceId: number;
  deviceName: string;
};

interface Props {
  selectedDevices: Device[];
  selectedProcesses: Process[];
  selectedProcessGroups: ProcessGroup[];
  selectedPhases: Phase[];
  selectedTasks: PhaseTask[];
  areasOfResponsibility: AreasOfResponsibility[];
  handleDeviceChange: (event: any, value: Device) => void;
  handleProcessChange: (event: any, value: Process) => void;
  handleProcessGroupChange: (event: any, value: ProcessGroup) => void;
  handlePhaseChange: (event: any, value: Phase) => void;
  handlePhaseTaskChange: (event: any, value: PhaseTask) => void;
  handleAreasOfResponsibilityList: (
    event: any,
    value: AreasOfResponsibility
  ) => void;
  clearAll: () => void;
}

const DeviceWorkflowBulkUpdateFilterTab = ({
  selectedDevices,
  selectedProcesses,
  selectedProcessGroups,
  selectedPhases,
  selectedTasks,
  areasOfResponsibility,
  handleDeviceChange,
  handleProcessChange,
  handleProcessGroupChange,
  handlePhaseChange,
  handlePhaseTaskChange,
  handleAreasOfResponsibilityList,
  clearAll,
}: Props) => {
  const classes = useStyles();
  const [devices, setDevices] = useState<any[]>([]);

  const [, deviceLoading, fetchDevices] = useWebService({
    method: ProcessGroupService.getDevices,
  });

  const [processes, processLoading, fetchProcesses] = useWebService({
    method: ProcessService.getAllProcesses,
  });
  const [processGroups, , fetchProcessGroups] = useWebService({
    method: ProcessGroupService.getAll,
  });

  const [phases, phaseLoading, fetchPhases] = useWebService({
    method: ProcessService.getAllPhases,
  });

  const [phaseTasks, taskLoading, fetchPhaseTasks] = useWebService({
    method: ProcessService.getAllTasks,
  });
  const [areasOfResponsibilityList, , fetchAreasOfResponsibilityList] =
    useWebService({
      method: ProcessService.getAllAreasOfResponsibility,
    });

  const [filterDisabled, setFilterDisabled] = useState(false);

  useEffect(() => {
    fetchProcesses();
    fetchProcessGroups();
    fetchPhases();
    fetchPhaseTasks();
    fetchAreasOfResponsibilityList();
  }, [
    fetchAreasOfResponsibilityList,
    fetchPhaseTasks,
    fetchPhases,
    fetchProcessGroups,
    fetchProcesses,
  ]);

  const setDeviceFilter = useCallback(async () => {
    const results = [];
    selectedProcessGroups.forEach(async (pg) => {
      const value = await fetchDevices(pg.processGroupId);
      value.forEach((val) => results.push(val));
    });
    setDevices(results);
  }, [fetchDevices, selectedProcessGroups]);

  // Update any filters dependent on the ProcessGroup filter
  const updateFilters = useCallback(async () => {
    const processGroupIds = selectedProcessGroups.map(
      (pg) => pg.processGroupId
    );

    fetchProcesses(processGroupIds);
    fetchPhases(processGroupIds);
    fetchPhaseTasks(processGroupIds);
  }, [fetchPhaseTasks, fetchPhases, fetchProcesses, selectedProcessGroups]);

  // The device filter is dependent on the ProcessGroup filter
  // Only fetch devices once that filters is set and then enable the
  // device filter
  useEffect(() => {
    if (selectedProcessGroups.length > 0) {
      setFilterDisabled(false);
      setDeviceFilter();
    } else {
      setFilterDisabled(true);
    }

    updateFilters();
  }, [selectedProcessGroups, setDeviceFilter, updateFilters]);

  return (
    <div>
      <Autocomplete
        name="areasOfResponsibility"
        translationKey="device.areasOfResponsibility"
        fullWidth
        multiple
        onChange={handleAreasOfResponsibilityList}
        value={areasOfResponsibility}
        options={
          areasOfResponsibilityList === undefined
            ? []
            : areasOfResponsibilityList
        }
        isOptionEqualToValue={(option, value) =>
          option.areasOfResponsibilityId === value.areasOfResponsibilityId
        }
        getOptionLabel={(option) =>
          option.areasOfResponsibilityId === undefined
            ? ''
            : `${option.areasOfResponsibilityName} (${option.areasOfResponsibilityId})`
        }
        className={classes.styling}
      />
      <Autocomplete
        name="processGroup"
        translationKey="device.processGroup"
        fullWidth
        multiple
        required
        onChange={handleProcessGroupChange}
        value={selectedProcessGroups}
        options={processGroups === undefined ? [] : processGroups}
        isOptionEqualToValue={(option, value) =>
          option.processGroupId === value.processGroupId
        }
        getOptionLabel={(option) =>
          option.processGroupId === undefined
            ? ''
            : `${option.processGroupName} (${option.processGroupId})`
        }
        className={classes.styling}
      />
      <Autocomplete
        name="process"
        translationKey="device.process"
        fullWidth
        multiple
        onChange={handleProcessChange}
        value={selectedProcesses}
        options={processes === undefined || processLoading ? [] : processes}
        isOptionEqualToValue={(option, value) =>
          option.processTypeId === value.processTypeId
        }
        getOptionLabel={(option) =>
          option.processTypeId === undefined
            ? ''
            : `${option.processTypeName} (${option.processTypeId})`
        }
        className={classes.styling}
        loading={processLoading}
      />
      <Autocomplete
        name="phase"
        translationKey="device.phase"
        fullWidth
        multiple
        onChange={handlePhaseChange}
        value={selectedPhases}
        options={phases === undefined || phaseLoading ? [] : phases}
        isOptionEqualToValue={(option, value) =>
          option.phaseTypeId === value.phaseTypeId
        }
        getOptionLabel={(option) =>
          option.phaseTypeId === undefined
            ? ''
            : `${option.phaseTypeName} (${option.phaseTypeId})`
        }
        className={classes.styling}
        loading={phaseLoading}
      />
      <Autocomplete
        name="phaseTask"
        translationKey="device.task"
        fullWidth
        multiple
        onChange={handlePhaseTaskChange}
        value={selectedTasks}
        options={phaseTasks === undefined || taskLoading ? [] : phaseTasks}
        isOptionEqualToValue={(option, value) => option.taskId === value.taskId}
        getOptionLabel={(option) =>
          option.taskId === undefined
            ? ''
            : `${option.taskName} (${option.taskId})`
        }
        className={classes.styling}
        loading={taskLoading}
      />
      <Autocomplete
        name="device"
        translationKey="device.device"
        fullWidth
        multiple
        onChange={handleDeviceChange}
        value={selectedDevices}
        options={devices === undefined || deviceLoading ? [] : devices}
        isOptionEqualToValue={(option, value) =>
          option.deviceId === value.deviceId
        }
        disabled={filterDisabled}
        getOptionLabel={(option) =>
          option.deviceId === undefined
            ? ''
            : `${option.deviceName} (${option.deviceId})`
        }
        className={classes.styling}
        loading={deviceLoading}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: '24px',
        }}
      >
        <ClearButton onClick={() => clearAll()} />
      </Box>
    </div>
  );
};

export default memo(DeviceWorkflowBulkUpdateFilterTab);
