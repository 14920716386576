import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import jschardet from 'jschardet';
import PropTypes from 'prop-types';

import { ContainedButton } from '@onc/composite-components';
import { FileTypeSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import ResultTextArea from 'domain/AppComponents/Form/Fields/ResultTextArea';
import FileUploadService from 'domain/services/FileUploadService';
import TranslationFileService from 'domain/services/TranslationFileService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  dropdown: {
    margin: theme.spacing(1),
    minWidth: '400px',
    maxWidth: '400px',
  },
  fileUpload: {
    display: 'none',
  },
  uploadButton: {
    display: 'flex',
  },
  textField: {
    width: `calc(100% - ${theme.spacing(80)})`,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[800],
  },
  input: {
    color: `${theme.palette.secondary.main} !important`,
  },
});

const defaultState = {
  fileType: 'SelectType',
  fileName: '',
  fileToUpload: null,
};

class FileUploadPage extends PureComponent {
  static propTypes = {
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  handleFileTypeChange = (event) => {
    this.setState({ fileType: event.target.value });
  };

  handleFileChange = (event) => {
    this.setState({
      fileName: event.target.files[0].name,
      fileToUpload: event.target.files[0],
    });
  };

  handleFileUpload = () => {
    const { fileToUpload, fileType } = this.state;
    if (fileType === 'Translation') {
      const { encoding } = jschardet.detect(fileToUpload);
      TranslationFileService.post(fileToUpload, encoding).then((response) => {
        const { data } = response;
        this.setState({ result: data.join('') });
      });
    } else {
      FileUploadService.post(fileToUpload).then((response) => {
        const { data } = response;
        this.setState({ result: data.join('') });
      });
    }
  };

  render() {
    const { classes, onInfo, onError } = this.props;
    const { fileType, fileName, fileToUpload, result } = this.state;

    return (
      <div>
        <Panel classes={{ root: classes.root }}>
          <FileTypeSelect
            className={classes.dropdown}
            value={fileType}
            onChange={this.handleFileTypeChange}
            onInfo={onInfo}
            onError={onError}
          />
          <div>
            <ContainedButton
              translationKey="common.buttons.chooseFile"
              disabled={fileType === 'SelectType'}
              component="label"
            >
              <input
                type="file"
                onChange={this.handleFileChange}
                className={classes.fileUpload}
                accept={fileType === 'Translation' ? '.csv' : '.an'}
              />
            </ContainedButton>
            {fileName}
            <ContainedButton
              translationKey="common.buttons.upload"
              className={classes.uploadButton}
              disabled={fileToUpload === null && fileType === 'SelectType'}
              onClick={this.handleFileUpload}
            />
          </div>
        </Panel>
        <Panel classes={{ root: classes.root }}>
          <ResultTextArea value={result} />
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(FileUploadPage));
