import { useMemo } from 'react';
import { useQueries } from '@onc/service';
import { type DateRange } from 'domain/hooks/useDateRange';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useTraceList from './useTraceList';
import useTraceQueries from './useTraceQueries';
import {
  getSourceIdentifier,
  getDateRangeIdentifier,
  getDevicesOneDCQueryFn,
} from '../TraceDataUtils';
import {
  TraceControlMap,
  type ChartQuality,
  type ScalarDataChartDeploymentSource,
  type ScalarDataChartLocationSource,
} from '../types/TimeSeriesScalarDataChart.types';

const useDeviceQueries = (
  sources: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  >,
  dateRange: DateRange,
  onError: (message: string) => void
) =>
  useQueries({
    queries: sources.map((source) => ({
      queryKey: [
        'devicesOneDC',
        getSourceIdentifier(source),
        getDateRangeIdentifier(dateRange),
      ],
      queryFn: () => getDevicesOneDCQueryFn(source, dateRange, onError),
      staleTime: 1000 * 60 * 5,
    })),
  });

const useScalarDataTraces = (
  sources: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  >,
  chartWidth: number | null,
  dateRange: DateRange,
  isMinMaxEnabled: boolean,
  chartQuality?: ChartQuality,
  traceControlMap: TraceControlMap = new Map()
) => {
  const { onError } = useSnackbars();

  const devicesResult = useDeviceQueries(sources, dateRange, onError);

  const traceResults = useTraceQueries(
    devicesResult,
    sources,
    chartWidth,
    dateRange,
    isMinMaxEnabled,
    onError,
    chartQuality
  );

  const isLoading = useMemo(
    () =>
      traceResults.some((traceResult) => traceResult.isFetching) ||
      devicesResult.some((result) => result.isFetching),
    [devicesResult, traceResults]
  );

  const traceList = useTraceList(
    traceResults,
    devicesResult,
    isLoading,
    traceControlMap
  );

  const devices = useMemo(
    () =>
      devicesResult.every((result) => !result.isLoading)
        ? devicesResult.flatMap((result) =>
            result?.data ? result.data[1] : []
          )
        : [],
    [devicesResult]
  );

  const serviceError = traceResults.some((traceResult) => traceResult.isError);

  return {
    traceList,
    devices,
    serviceError,
    isLoading,
  };
};

export default useScalarDataTraces;
