import { useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from 'base-components';
import * as LicenceService from 'domain/services/LicenceService';

import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import { useSnackbars } from 'util/hooks/useSnackbars';
import type { CreateLicence, LicenceForm } from './Licence.types';

type LicenceFormConfigEditProps = {
  licenceId: number;
  originalLicence: Partial<LicenceForm>;
};

type LicenceFormConfigCreateProps = {
  licenceId: undefined;
  originalLicence: undefined;
};

type LicenceFormConfigProps = (
  | LicenceFormConfigCreateProps
  | LicenceFormConfigEditProps
) & {
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
};

const LicenceFormConfig: React.FC<LicenceFormConfigProps> = ({
  licenceId,
  originalLicence = {},
  open,
  onClose,
}: LicenceFormConfigProps) => {
  const { onInfo, onError } = useSnackbars();
  const formMethods = useForm<LicenceForm>({
    mode: 'onChange',
    defaultValues: {
      licenceName: originalLicence.licenceName ?? '',
      licenceAbbreviation: originalLicence.licenceAbbreviation ?? '',
      licenceProvider: originalLicence.licenceProvider ?? '',
      licenceProviderUrl: originalLicence.licenceProviderUrl ?? '',
      licenceUrl: originalLicence.licenceUrl ?? '',
      licenceConstraint: originalLicence.licenceConstraint ?? '',
    },
  });

  const {
    formState: { isSubmitting, isValid },
  } = formMethods;

  const { mutate: createLicence } = LicenceService.useCreate({
    onSuccess: (licence) => {
      onInfo(`Licence (ID ${licence.licenceId}) created`);
      onClose(true);
    },
    onError: (e) => {
      onError(e.message);
    },
  });

  const { mutate: updateLicence } = LicenceService.useUpdate({
    onSuccess: (licence) => {
      onInfo(`Licence (ID ${licence.licenceId}) updated`);
      onClose(true);
    },
    onError: (e) => {
      onError(e.message);
    },
  });

  return (
    <Dialog open={open}>
      <DialogTitle>
        {licenceId ? `Update Licence (ID ${licenceId})` : 'Create Licence'}
      </DialogTitle>
      <Form
        onSubmit={(vars) => {
          const data = Object.fromEntries(
            Object.entries(vars).map(([key, value]) => [
              key,
              value || undefined,
            ])
          ) as CreateLicence;
          if (licenceId) updateLicence({ ...data, licenceId });
          else createLicence(data);
        }}
        formMethods={formMethods}
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormTextField
                name="licenceName"
                translationKey="licence.licenceName"
                rules={{ required: 'Licence Name is required' }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="licenceAbbreviation"
                translationKey="licence.licenceAbbreviation"
                rules={{ required: 'Licence Abbreviation is required' }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="licenceProvider"
                translationKey="licence.licenceProvider"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="licenceProviderUrl"
                translationKey="licence.licenceProviderUrl"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="licenceUrl"
                translationKey="licence.licenceUrl"
                rules={{ required: 'Licence URL is required' }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="licenceConstraint"
                translationKey="licence.licenceConstraint"
                rules={{ required: 'Licence Constraint is required' }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton disabled={isSubmitting} onClick={() => onClose()} />
          <SaveButton disabled={isSubmitting || !isValid} />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default LicenceFormConfig;
