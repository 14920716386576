import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  QueryClientProvider,
  QueryClient,
  QueryClientConfig,
} from '@onc/service';

const defaultQueryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};

interface ReactQueryProps {
  children: any;
  queryClientConfig?: QueryClientConfig;
}

/**
 * ReactQueryProvider
 *
 * A provider component for setting up React Query with a QueryClient.
 *
 * Note:
 *
 * - The `QueryClient` instance is initialized using a `useRef` hook to ensure
 *   stability across renders.
 * - Currently, the `queryClientConfig` prop is only used during the initial
 *   creation of the `QueryClient`.
 *
 *   - **Limitation**: Updates to `queryClientConfig` after the initial render will
 *       not be reflected in the existing `QueryClient` instance.
 *   - **Reason**: `QueryClient` is stored in a `ref`, and changes to the config do
 *       not trigger re-initialization of the `QueryClient`.
 *
 * Future Consideration:
 *
 * - This approach ensures consistency for React Query DevTools but introduces a
 *   limitation in terms of updating configuration dynamically.
 * - A more comprehensive approach to handling query client instances and
 *   configuration will be resolved down the line.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @param {QueryClientConfig} [props.queryClientConfig] - Configuration options
 *   for the `QueryClient`. Defaults to a standard configuration.
 * @returns {JSX.Element} The QueryClientProvider wrapped around the child
 *   components.
 */
const ReactQueryProvider: React.VFC<ReactQueryProps> = ({
  children,
  queryClientConfig = defaultQueryClientConfig,
}: ReactQueryProps) => {
  const queryClient = React.useRef(new QueryClient(queryClientConfig));
  return (
    <QueryClientProvider client={queryClient.current}>
      {children}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
