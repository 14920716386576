import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Route } from 'react-router-dom';
import NamePlateMaintenance from './NamePlateMaintenance';

class NamePlateMaintenanceContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const params = this.parseURLParams();
    this.state = {
      namePlateId: Number(params.namePlateId),
      deviceId: Number(params.deviceId),
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => this.updatePage());
  }

  parseURLParams = () => {
    const { history } = this.props;

    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  };

  updatePage() {
    const params = this.parseURLParams();
    this.setState({
      namePlateId: Number(params.namePlateId),
      deviceId: Number(params.deviceId),
    });
  }

  renderDevicePortPage = () => {
    const { namePlateId, deviceId } = this.state;
    const { history } = this.props;

    return (
      <NamePlateMaintenance
        namePlateId={namePlateId}
        deviceId={deviceId}
        history={history}
      />
    );
  };

  render() {
    const { match } = this.props;

    return (
      <Route exact path={`${match.path}`} render={this.renderDevicePortPage} />
    );
  }
}

export default NamePlateMaintenanceContainer;
