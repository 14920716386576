/* eslint-disable no-useless-escape */
import { useState } from 'react';

import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, createStyles } from '@mui/styles';

import { CancelButton, SaveButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dropdown as GenericDropdown,
  Grid,
  TextField,
} from 'base-components';

const STYLES = (theme: Theme) =>
  createStyles({
    exportTitle: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    entryStyles: {
      width: 'auto',
      display: 'block',
    },
  });

interface AnnotationExportProps {
  onClose: () => void;
  onSubmit: (fileName: string, responseType: number) => any;
  classes?: any;
}

const exportOptions = [
  { label: 'Excel', value: 3, key: '3' },
  { label: 'CSV', value: 2, key: '2' },
];

const AnnotationExportDialog: React.VFC<AnnotationExportProps> = ({
  onClose,
  onSubmit,
  classes = undefined,
}: AnnotationExportProps) => {
  const [fileName, setFileName] = useState<string>();
  const [responseType, setResponseType] = useState<number>(3);
  const [error, setError] = useState<boolean>();
  const [helperText, setHelperText] = useState<string>();

  const handleOnSubmit = () => {
    if (fileName && !error) {
      onSubmit(fileName, responseType);
    }
  };

  const handleFileNameChange = (newFileName: string) => {
    const rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
    const rg2 = /^\./; // cannot start with dot (.)
    const rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
    if (
      newFileName.match(rg1) &&
      !newFileName.match(rg2) &&
      !newFileName.match(rg3)
    ) {
      setError(false);
      setFileName(newFileName);
      setHelperText(undefined);
    } else {
      setError(true);
      setHelperText('Invalid file name');
      setFileName(newFileName);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Dialog open onClose={onClose}>
          <DialogTitle className={classes.exportTitle}>
            Export Annotation
          </DialogTitle>
          <DialogContent className={classes.entryStyles}>
            <TextField
              title="fileNameField"
              fullWidth
              translationKey="common.textfields.fileName"
              name="fileName"
              value={fileName}
              onChange={(e: any) => handleFileNameChange(e.target.value)}
              error={error}
              helperText={helperText}
            />
            <GenericDropdown
              name="responseType"
              label="Export Annotations"
              options={exportOptions}
              fullWidth
              value={responseType}
              onChange={(e: any) => setResponseType(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose} />
            <SaveButton onClick={handleOnSubmit} />
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default withStyles(STYLES)(AnnotationExportDialog);
