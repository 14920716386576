import { memo } from 'react';
import * as React from 'react';
import { TextButton } from '@onc/composite-components';
import { TranslationType } from '@onc/i18n';
import { Box, LoadingButton } from 'base-components';

interface StepProps {
  content?: React.ReactNode;
  backButtonTranslationKey?: TranslationType;
  nextButtonTranslationKey?: TranslationType;
  isBackDisabled?: () => boolean;
  isNextDisabled?: () => boolean;
  onBack: () => void;
  onNext: () => void;
  minHeight?: string;
  isNextLoading?: boolean;
}

const Step: React.FC<StepProps> = ({
  content = undefined,
  isBackDisabled = () => false,
  isNextDisabled = () => false,
  backButtonTranslationKey = 'common.buttons.back',
  nextButtonTranslationKey = 'common.buttons.next',
  onBack,
  onNext,
  isNextLoading = false,
  minHeight = '0px',
}) => (
  <>
    <Box
      sx={{
        minHeight,
      }}
    >
      {content}
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '24px',
      }}
    >
      <TextButton
        translationKey={backButtonTranslationKey}
        onClick={onBack}
        disabled={isBackDisabled()}
      />
      <LoadingButton
        variant="contained"
        translationKey={nextButtonTranslationKey}
        onClick={onNext}
        disabled={isNextDisabled()}
        loading={isNextLoading}
      />
    </Box>
  </>
);

export default memo(Step);
