import { useCallback, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import moment, { Moment } from 'moment';
import { CancelButton, ContainedButton } from '@onc/composite-components';
import {
  Autocomplete,
  DateTimePicker,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LabelledCheckbox,
  TextField,
  Tooltip,
  Typography,
} from 'base-components';
import { CruiseJSON } from 'domain/services/CruiseService';
import { DiveJSON } from 'domain/services/DiveListingService';
import { TopologyJSON } from 'domain/services/TopologyService';
import { UserManagementJSON } from 'domain/services/UserManagementService';
import CruiseAutocomplete from './CruiseAutocomplete';
import DiveFormContext from './DiveFormContext';

const PLATFORM_CATEGORY = 106;
const CAMERA_CATEGORY = 107;
type DeviceOption = Pick<TopologyJSON, 'deviceToId' | 'deviceToName'>;

const CAMERA_NOT_SET = {
  deviceToName: '(Not Set)',
  deviceToId: -1,
};

type Props = {
  open: boolean;
  onClose: () => void;
  initialCruiseId?: number;
  diveId?: number;
};

const DiveForm: React.VFC<Props> = ({
  open,
  onClose,
  initialCruiseId = undefined,
  diveId = undefined,
}) => {
  // ----- Context ----- //
  const { getCruises, getDiveChiefs, getTopologyForDevice, getDive, onSubmit } =
    useContext(DiveFormContext);

  // ----- Dropdown Data ----- //
  const [cruises, setCruises] = useState<CruiseJSON[] | undefined>(undefined);
  const [diveChiefs, setDiveChiefs] = useState<
    UserManagementJSON[] | undefined
  >(undefined);
  const [platforms, setPlatforms] = useState<TopologyJSON[] | undefined>(
    undefined
  );
  const [cameras, setCameras] = useState<TopologyJSON[] | undefined>(undefined);

  // ----- Dive Data ------- //
  const [diveData, setDiveData] = useState<DiveJSON | undefined>(undefined);

  // ----- Form Values ----- //
  const [area, setArea] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [cruise, setCruise] = useState<CruiseJSON | null>(null);
  const [dateFrom, setDateFrom] = useState<Moment | undefined>(undefined);
  const [dateTo, setDateTo] = useState<Moment | undefined>(undefined);
  const [defaultCamera, setDefaultCamera] =
    useState<DeviceOption>(CAMERA_NOT_SET);
  const [diveChief, setDiveChief] = useState<UserManagementJSON | null>(null);
  const [diveName, setDiveName] = useState<string>('');
  const [platform, setPlatform] = useState<DeviceOption | null>(null);
  const [seaTubeReady, setSeaTubeReady] = useState(false);

  const clearFormData = useCallback(() => {
    setArea('');
    setComment('');
    setCruise(null);
    setDateFrom(undefined);
    setDateTo(undefined);
    setDefaultCamera(CAMERA_NOT_SET);
    setDiveChief(null);
    setDiveName('');
    setPlatform(null);
    setSeaTubeReady(false);
    onClose();
  }, [onClose]);

  // ----- Hooks ----- //

  // Get form data
  useEffect(() => {
    async function fetchCruises() {
      setCruises(await getCruises());
      setDiveChiefs(await getDiveChiefs());
    }
    fetchCruises();
  }, [getCruises, getDiveChiefs]);

  // Set Initial Cruise
  useEffect(() => {
    if (initialCruiseId && cruises) {
      const initialCruise = cruises.find(
        (item) => item.cruiseId === initialCruiseId
      );
      setCruise(initialCruise || null);
    }
  }, [initialCruiseId, open, cruises]);

  // Load data from dive if diveId given
  useEffect(() => {
    async function fetchDive() {
      // Wait for cruises, diveChiefs, and platforms to be loaded before trying to set them
      if (diveId) {
        setDiveData(await getDive(diveId));
      }
    }
    setDiveData(undefined);
    clearFormData();
    fetchDive();
  }, [diveId, getDive, clearFormData]);

  useEffect(
    () => {
      if (diveData && cruises && diveChiefs) {
        setArea(diveData.area ? diveData.area : '');
        setComment(diveData.diveComment ? diveData.diveComment : '');
        setDateFrom(diveData.dateFrom ? moment(diveData.dateFrom) : undefined);
        setDateTo(diveData.dateTo ? moment(diveData.dateTo) : undefined);
        setDiveName(diveData.referenceDiveId ? diveData.referenceDiveId : '');
        setSeaTubeReady(diveData.ready === true);

        // Cruise
        const loadedCruise = cruises.find(
          (item) => item.cruiseId === diveData.cruiseId
        );
        setCruise(loadedCruise || null);

        // Dive Chief
        const loadedChief = diveChiefs.find(
          (item) => item.dmasUserId === diveData.scientistId
        );
        setDiveChief(loadedChief || null);
      }
    },
    // Ignore platforms and cameras as they can trigger an update while the user if filling out the form
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [diveData, cruises, diveChiefs]
  );

  // Default date to start of cruise and reset dateFrom when changing expeditions
  // Also load the topology for the newly selected expedition
  useEffect(
    () => {
      async function fetchTopologyForCruise() {
        if (cruise && cruise.platformId) {
          const platformList = await getTopologyForDevice({
            deviceId: cruise.platformId,
            dateFrom: cruise.startDate,
            dateTo: cruise.endDate || new Date().toISOString(),
            deviceCategoryIds: [PLATFORM_CATEGORY].toString(),
          });
          setPlatforms(platformList);
          if (platformList.length === 1 && !diveId) {
            setPlatform(platformList[0]);
          }
        }
      }
      fetchTopologyForCruise();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cruise, getTopologyForDevice]
  );

  // Updates the default camera field when editing
  useEffect(() => {
    if (cameras && diveData) {
      const loadedDefaultCamera = cameras.find(
        (item) => item.deviceToId === diveData.defaultDeviceId
      );
      setDefaultCamera(loadedDefaultCamera || CAMERA_NOT_SET);
    }
  }, [cameras, diveData]);

  // Updates the platform field when editing
  useEffect(() => {
    if (platforms && diveData) {
      const loadedPlatform = platforms.find(
        (item) => item.deviceToId === diveData.deviceId
      );
      setPlatform(loadedPlatform || null);
    }
  }, [platforms, diveData]);

  // get topology for the cameras when platform changes
  useEffect(
    () => {
      async function fetchTopologyForPlatform() {
        if (platform && cruise) {
          const cameraList = await getTopologyForDevice({
            deviceId: platform.deviceToId,
            dateFrom: cruise.startDate,
            dateTo: cruise.endDate || new Date().toISOString(),
            deviceCategoryIds: [CAMERA_CATEGORY].toString(),
          });
          setCameras(cameraList);
          if (cameraList.length === 1 && !diveId) {
            setDefaultCamera(cameraList[0]);
          }
        }
      }
      setDefaultCamera(CAMERA_NOT_SET);
      fetchTopologyForPlatform();
    },
    // ignoring cruise, it's case is handled in the hook above
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [platform, getTopologyForDevice]
  );

  // ----- Helpers ----- //
  const getCameraOptions = () => {
    if (cameras) {
      const options = [CAMERA_NOT_SET];
      return options.concat(cameras);
    }
    return [];
  };

  // -----  Handlers ----- //
  // Autocompletes
  const handleCruise = (e: any, value: CruiseJSON) => {
    setDateFrom(undefined);
    setDateTo(undefined);
    setPlatform(null);
    setCruise(value);
  };
  const handlePlatform = (e: any, value: DeviceOption) => {
    setPlatform(value);
  };
  const handleDiveChief = (e: any, value: UserManagementJSON) => {
    setDiveChief(value);
  };
  const handleDefaultCamera = (e: any, value: DeviceOption) => {
    setDefaultCamera(value);
  };

  // Text Fields
  const handleDiveName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiveName(event.target.value);
  };
  const handleArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    setArea(event.target.value);
  };
  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  // Date pickers
  const handleDateFrom = (date: Moment) => {
    setDateFrom(moment.utc(date));
  };
  const handleDateTo = (date: Moment) => {
    setDateTo(moment.utc(date));
  };

  // Checkboxes
  const handleSeaTubeReady = (e: any, isChecked: boolean) => {
    setSeaTubeReady(isChecked);
  };

  const validateDate = () => {
    if (dateTo && dateFrom && dateTo.isBefore(dateFrom)) {
      return 'Start date cannot be after end date';
    }
    return '';
  };

  const performValidation = () => {
    if (!cruise) {
      return 'Please specify a cruise';
    }
    if (!diveName || diveName === '') {
      return 'Dive name is required';
    }
    if (!dateFrom || !dateFrom.isValid()) {
      return 'Start date is not valid';
    }
    if (!dateTo || !dateTo.isValid()) {
      return 'End date is not valid';
    }

    if (!diveChief) {
      return 'Please specify a dive lead';
    }

    if (!platform) {
      return 'Please specify a platform';
    }

    if (dateFrom.isBefore(cruise.startDate)) {
      return 'Start date cannot be before the cruise start date';
    }
    if (cruise.endDate) {
      if (dateTo.isAfter(cruise.endDate)) {
        return 'End date cannot be after the cruise end date';
      }
    }
    return '';
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    return onSubmit({
      area,
      comment,
      cruise,
      dateFrom,
      dateTo,
      defaultCamera,
      diveId,
      diveChief,
      diveName,
      platform,
      seaTubeReady,
    });
  };

  const validation = performValidation();
  const dateValidation = validateDate();

  // -----  Render ----- //
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>{diveId ? 'Edit Dive' : 'Create Dive'}</DialogTitle>
      <form aria-label="Edit Dive" onSubmit={handleSubmit}>
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <CruiseAutocomplete
                onChange={handleCruise}
                value={cruise}
                options={cruises || []}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                translationKey="seatube.diveName"
                fullWidth
                onChange={handleDiveName}
                value={diveName}
                role="textbox"
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                translationKey="common.datepickers.startDate"
                value={dateFrom ? dateFrom.utc() : null}
                onChange={handleDateFrom}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                translationKey="common.datepickers.endDate"
                value={dateTo ? dateTo.utc() : null}
                onChange={handleDateTo}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={!!dateValidation}>
                <Typography
                  variant="caption"
                  color="error"
                  style={{ marginLeft: '16px' }}
                >
                  {dateValidation}
                </Typography>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                onChange={handlePlatform}
                translationKey="seatube.platform"
                name="platform-autocomplete"
                value={platform}
                options={platforms || []}
                getOptionLabel={(option: TopologyJSON) => option.deviceToName}
                isOptionEqualToValue={(
                  option: TopologyJSON,
                  value: TopologyJSON
                ) => option.deviceToId === value.deviceToId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                translationKey="seatube.area"
                onChange={handleArea}
                value={area}
                role="textbox"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                onChange={handleDiveChief}
                translationKey="seatube.diveLead"
                name="dive-chief-autocomplete"
                value={diveChief}
                options={diveChiefs || []}
                getOptionLabel={(user) => `${user.lastname}, ${user.firstname}`}
                isOptionEqualToValue={(
                  option: UserManagementJSON,
                  value: UserManagementJSON
                ) => option.dmasUserId === value.dmasUserId}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                onChange={handleDefaultCamera}
                value={defaultCamera}
                translationKey="seatube.defaultCamera"
                name="default-camera-autocomplete"
                options={getCameraOptions()}
                getOptionLabel={(option: TopologyJSON) => option.deviceToName}
                isOptionEqualToValue={(
                  option: TopologyJSON,
                  value: TopologyJSON
                ) => option.deviceToId === value.deviceToId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                translationKey="common.textfields.comment"
                multiline
                minRows={5}
                onChange={handleComment}
                value={comment}
                role="textbox"
              />
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                placement="right"
                title="Turn on to make this dive visible for all users in the Expedition Management tree"
              >
                <span>
                  <LabelledCheckbox
                    label="SeaTube Ready"
                    name="seatube-ready-checkbox"
                    id="seatube-ready-checkbox"
                    value={seaTubeReady}
                    onChange={handleSeaTubeReady}
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose} />
          <Tooltip placement="top" title={validation || dateValidation}>
            <span>
              <ContainedButton
                translationKey="common.buttons.save"
                type="submit"
                disabled={!!validation || !!dateValidation}
              />
            </span>
          </Tooltip>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DiveForm;
