import { useState, useEffect, useCallback } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {
  AbsoluteRightButtonStyle,
  ContainedButton,
} from '@onc/composite-components';
import Organization from 'domain/Apps/network-console/organizations/Organization';
import OrganizationsDialogBox from 'domain/Apps/network-console/organizations/OrganizationsDialogBox';
import SortableLink from 'domain/Apps/network-console/SortableLink';
import OrganizationService from 'domain/services/OrganizationService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      ...AbsoluteRightButtonStyle(theme),
      position: 'absolute',
      zIndex: 4,
    },
  })
);

type Props = {
  onError: (message: any, callback?: any) => void;
};

const columns = [
  { title: 'Organization ID', name: 'id' },
  { title: 'Organization Name', name: 'name' },
  { title: 'Organization URL', name: 'organizationUrl' },
  { title: 'Organization Email', name: 'organizationEmail' },
  { title: 'Organization Phone', name: 'organizationPhone' },
  { title: 'ROR ID', name: 'rorId' },
  { title: 'Organization Logo URL', name: 'logoUrl' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'hiddenId', name: 'hiddenId' },
];

const columnExtensions = [
  {
    columnName: 'id',
    align: 'center',
  },
  {
    columnName: 'name',
    align: 'center',
  },
  {
    columnName: 'organizationUrl',
    align: 'center',
  },
  {
    columnName: 'organizationEmail',
    align: 'center',
  },
  {
    columnName: 'organizationPhone',
    align: 'center',
  },
  {
    columnName: 'rorId',
    align: 'center',
  },
  {
    columnName: 'logoUrl',
    align: 'center',
  },
  {
    columnName: 'modifyBy',
    align: 'center',
  },
  {
    columnName: 'modifyDate',
    align: 'center',
  },
];

const OrganizationPage = ({ onError }: Props) => {
  const [organizations, setOrganizations] = useState<Array<Organization>>([]);
  const [isOrganizationOpen, setIsOrganizationOpen] = useState<boolean>(false);
  const [editOrganization, setEditOrganization] = useState<Organization>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const permission = Environment.getDmasUserPrivilege() === 'RW';
  const classes = useStyles();

  const renderCreateOrganizationsButton = () => (
    <ContainedButton
      translationKey="networkConsole.createOrganization"
      className={classes.button}
      onClick={() => {
        setIsOrganizationOpen(true);
      }}
      disabled={isOrganizationOpen || !permission}
    />
  );

  const getModifyByWithName = (organization: Organization) =>
    organization.modifyByName;

  const getOrganizations = useCallback(async () => {
    try {
      setIsLoading(true);
      let newOrganizations = await OrganizationService.getAllAdditionalInfo();
      newOrganizations = newOrganizations.map((organization) => {
        const newOrganization = { ...organization };
        if (permission) {
          newOrganization.id = (
            <SortableLink
              id={organization.id}
              onClick={() => {
                setEditOrganization(organization);
              }}
            />
          );
          newOrganization.hiddenId = organization.id;
        } else {
          newOrganization.id = organization.id;
        }

        newOrganization.modifyBy = getModifyByWithName(organization);
        newOrganization.modifyDate = DateFormatUtils.formatDate(
          organization.modifyDate,
          'full'
        );
        return newOrganization;
      });
      setOrganizations(newOrganizations);
    } catch (e) {
      onError('Could not get Organizations.');
    } finally {
      setIsLoading(false);
    }
  }, [permission, onError]);

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  useEffect(() => {
    setIsOrganizationOpen(Boolean(editOrganization));
  }, [editOrganization]);

  const handlePostCall =
    (
      call: (
        organization: Organization
      ) => Promise<{ payload: Organization; statusCode: number }>
    ) =>
    async (value) => {
      try {
        await call(value);
        getOrganizations();
        if (editOrganization) {
          setEditOrganization(null);
        } else {
          setIsOrganizationOpen(false);
        }
      } catch (e: any) {
        onError(e.message);
      }
    };

  const renderDialogBox = () => {
    if (!isOrganizationOpen) {
      return <></>;
    }
    if (editOrganization) {
      return (
        <OrganizationsDialogBox
          title="Edit Organization"
          organization={editOrganization}
          onClose={() => setEditOrganization(null)}
          onSubmit={handlePostCall(OrganizationService.updateOrganization)}
        />
      );
    }
    return (
      <OrganizationsDialogBox
        title="Create Organization"
        organization={undefined}
        onClose={() => setIsOrganizationOpen(false)}
        onSubmit={handlePostCall(OrganizationService.createOrganization)}
      />
    );
  };

  return (
    <>
      {renderCreateOrganizationsButton()}
      {renderDialogBox()}
      <SortableTable
        pageSize={15}
        searchable
        searchBarMoveable
        sortExtensions={
          permission
            ? [
                {
                  columnName: 'id',
                  compare: (a, b) => (a.props.id > b.props.id ? 1 : -1),
                },
                {
                  columnName: 'organizationPhone',
                  compare: (a, b) => {
                    if (!a || !a.trim()) return 1;
                    return a.localeCompare(b);
                  },
                },
                {
                  columnName: 'organizationURL',
                  compare: (a, b) => {
                    if (!a || !a.trim()) return 1;
                    return a.localeCompare(b);
                  },
                },
              ]
            : []
        }
        hiddenColumns={['hiddenId']}
        defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
        columns={columns}
        columnExtensions={columnExtensions}
        rows={organizations}
        stripedRows
        elevation={0}
        pageSizes={[15, 30, 60, 0]}
        isLoading={isLoading}
      />
    </>
  );
};

export default withSnackbars(OrganizationPage);
