import { FC, MouseEvent } from 'react';
import { Link } from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

interface Props {
  onInfo: (message: string) => void;
}

const Logout: FC<Props> = ({ onInfo }) =>
  Environment.isUserLoggedIn() ? (
    <Link
      href={`${Environment.getDmasUrl()}/Logout`}
      color="inherit"
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        // Couldn't get this to work with WebRequest and Axios easily
        fetch(e.currentTarget.href, { credentials: 'include' }).then(() => {
          window.DMAS.Config.DMAS_USER_ID = undefined;
          window.DMAS.Config.DMAS_USER_FIRSTNAME = undefined;
          window.DMAS.Config.DMAS_USER_LASTNAME = undefined;
          window.DMAS.Config.DMAS_USER_EMAIL = undefined;
          window.DMAS.Config.DMAS_USER_TOKEN = undefined;
          window.DMAS.Config.DMAS_USER_PRIVILEGE = undefined;
          Environment.setCurrentApplicationToken(undefined);
          onInfo('Logged out from Oceans 3.0');
        });
      }}
    >
      Logout
    </Link>
  ) : null;
export default withSnackbars(Logout);
