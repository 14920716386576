/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { memo, useContext, useEffect, useState } from 'react';

import * as React from 'react';

import _ from 'lodash';
import { OutlinedButton } from '@onc/composite-components';
import Environment from '@onc/environment';
import { OpenInNew } from '@onc/icons';
import {
  BooleanFormatter,
  DateFormatter,
  getEmptyFilter,
  ShowAllContentFormatter,
  StatelessTable,
  TableColumn,
  TableFilter,
  DevExpressGrouping as Grouping,
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
} from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import AnnotationService from 'domain/services/AnnotationService';
import { SeaTubeAnnotationPermissions } from 'domain/services/SeaTubePermissionsService';
import { useLocalStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import defaultConfig from './AnnotationTableConfig';
import AnnotationTableLogic from './AnnotationTableLogic';
import AttributeSelectDialog from './AttributeSelectDialog';
import DefaultActionFormatter, {
  AnnotationActionProps,
} from './formatters/ActionFormatter';
import AttributeFormatter from './formatters/AttributeFormatter';
import TableAnnotation, { Attribute } from './TableAnnotation';
import SeaTubeLogContext from '../SeaTubeLogContext';

interface Props {
  rows: any[];
  permissions?: SeaTubeAnnotationPermissions;
  expeditionId: number;
  storageKey?: string;
  scrollToRow?: number;
  onRefresh?: () => void;
  onEdit: (annotation: TableAnnotation) => void;
  onInfo: (message: string) => void;
  onError: (message: string) => void;
  onDeleteSuccess?: () => void;
  broadcastDelete?: (annotationId: number) => void;
  ActionFormatter?: React.FC<AnnotationActionProps>;
  hideFilter?: boolean;
  disabledColumns?: string[];
  showNumberOfRecords?: boolean;
  additionalColumns?: TableColumn[];
}

const groupingStateColumnExtensions = [
  { columnName: 'startDate', groupingEnabled: false },
  { columnName: 'createdDate', groupingEnabled: false },
  { columnName: 'modifiedDate', groupingEnabled: false },
  { columnName: 'attributes', groupingEnabled: false },
  { columnName: 'comment', groupingEnabled: false },
  { columnName: 'actions', groupingEnabled: false },
];

const AnnotationTable: React.FC<Props> = ({
  rows,
  permissions = undefined,
  storageKey = 'annotation-table-config',
  scrollToRow = undefined,
  expeditionId,
  onRefresh = undefined,
  onEdit,
  onInfo,
  onError,
  onDeleteSuccess = undefined,
  broadcastDelete = undefined,
  ActionFormatter = DefaultActionFormatter,
  hideFilter = false,
  disabledColumns = [],
  showNumberOfRecords = false,
  additionalColumns = [],
}: Props) => {
  const { cruise } = useContext(SeaTubeLogContext);
  const [deleteAnnotationId, setDeleteAnnotationId] = useState<number>(0);
  const handleDeleteSuccess = () => {
    onDeleteSuccess();
    broadcastDelete(deleteAnnotationId);
    setDeleteAnnotationId(-1);
  };
  const [config, setConfig] = useLocalStorage<any>(storageKey, defaultConfig);
  const [, , deleteAnnotation] = useWebService({
    method: AnnotationService.deleteAnnotationForSeaTube,
    parser: handleDeleteSuccess,
    onError,
  });
  const [attributeDialogOpen, setAttributeDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [filterGroupIndex, setFilterGroupIndex] = useState(-1);
  const [filterLineIndex, setFilterLineIndex] = useState(-1);
  const [selected, setSelected] = useState<number[]>([]);

  const customFilterBehaviour = (groupIndex: number, lineIndex: number) => {
    setAttributeDialogOpen(true);
    setFilterGroupIndex(groupIndex);
    setFilterLineIndex(lineIndex);
  };

  const columns: TableColumn[] = [
    { name: 'startDate', title: 'Timestamp', dataType: 'Date' },
    { name: 'createdBy', title: 'Creator', dataType: 'Select' },
    { name: 'createdDate', title: 'Date Created', dataType: 'Date' },
    { name: 'modifiedDate', title: 'Modified Timestamp', dataType: 'Date' },
    { name: 'modifiedBy', title: 'Modified By', dataType: 'Select' },
    { name: 'taxonomy', title: 'Taxonomy', dataType: 'Select' },
    { name: 'taxon', title: 'Taxon', dataType: 'Select' },
    {
      name: 'attributes',
      title: 'Attributes',
      dataType: 'Select',
      onFilterColumn: customFilterBehaviour,
    },
    { name: 'comment', title: 'Comment', dataType: 'String' },
    { name: 'toBeReviewed', title: 'To Be Reviewed', dataType: 'Boolean' },
    { name: 'lat', title: 'Latitude', dataType: 'Number' },
    { name: 'lon', title: 'Longitude', dataType: 'Number' },
    { name: 'depth', title: 'Depth', dataType: 'Number' },
    { name: 'heading', title: 'Heading', dataType: 'Number' },
    { name: 'actions', title: 'Actions', dataType: 'Other' },
  ];

  // Filtering
  const initialFilter = config.filter;
  const [filter, setFilter] = useState<TableFilter>(
    initialFilter ? _.cloneDeep(config.filter) : _.cloneDeep(getEmptyFilter())
  );

  const [filterForm, setFilterForm] = useState<TableFilter>(
    _.cloneDeep(filter) || _.cloneDeep(getEmptyFilter())
  );

  const handleCloseDialog = () => {
    setAttributeDialogOpen(false);
    setFilterGroupIndex(-1);
    setFilterLineIndex(-1);
  };

  const handleCancelAttributeSelect = () => {
    const updatedFilter = { ...filterForm };
    const filterLine =
      updatedFilter.filterGroups[filterGroupIndex].filterLines[filterLineIndex];
    filterLine.column = '';
    filterLine.operator = null;
    filterLine.data = undefined;
    filterLine.dataType = undefined;
    filterLine.value = '';
    setFilterForm(updatedFilter);
    handleCloseDialog();
  };

  const handleSelectAttribute = (attribute: Attribute) => {
    const updatedFilter = _.cloneDeep(filterForm);
    const filterLine =
      updatedFilter.filterGroups[filterGroupIndex].filterLines[filterLineIndex];
    filterLine.column = `Attributes / ${attribute.name}`;
    filterLine.operator = undefined;
    filterLine.data = attribute;
    filterLine.dataType = attribute.dataType;
    if (attribute.dataType === 'Select') {
      filterLine.options = AnnotationTableLogic.getValuesForAttribute(
        attribute.attributeId,
        rows
      );
    }
    setFilterForm(updatedFilter);
    handleCloseDialog();
  };

  const handleSelectRow = (selectedRows: number[]) => {
    if (selectedRows.length > 0) {
      setSelected(selectedRows.splice(-1));
    }
  };

  // Column Ordering - If a new column is added but the user doesn't have that column saved in there config, add it
  const [columnOrder, setColumnOrder] = useState<string[]>(
    [...config.columnOrder, ...defaultConfig.columnOrder].filter(
      (item, index, arr) => arr.indexOf(item) === index
    )
  );
  // Column Resizing
  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>(
    [...config.columnWidths, ...defaultConfig.columnWidths].filter(
      (item, index, arr) => arr.indexOf(item) === index
    )
  );
  // Grouping
  const [grouping, setGrouping] = useState<Grouping[]>(config.grouping);

  // Column Sorting
  const [sorting, setSorting] = useState(config.sorting);

  // Hidden Columns
  const [hiddenColumnNames, setHiddenColumns] = useState<string[]>(
    config.hiddenColumnNames
  );

  const handleResetView = () => {
    setHiddenColumns(defaultConfig.hiddenColumnNames);
    setSorting(defaultConfig.sorting);
    setGrouping(defaultConfig.grouping);
    setColumnOrder(defaultConfig.columnOrder);
    setColumnWidths(defaultConfig.columnWidths);
  };

  // Update localStorage
  useEffect(() => {
    const newConfig = {
      columnOrder,
      columnWidths,
      grouping,
      sorting,
      hiddenColumnNames,
      filter,
    };
    setConfig(newConfig);
  }, [
    columnOrder,
    columnWidths,
    grouping,
    sorting,
    hiddenColumnNames,
    filter,
    storageKey,
  ]);

  const handleCopyAnnotationLink = (annotation) => {
    const { resourceId } = annotation;
    let url = `${Environment.getDmasUrl()}/app/expedition-logs/`;
    url += `${resourceId}?`;
    url += `annotationId=${annotation.id}`;
    navigator.clipboard.writeText(`${url}`).then(
      () => {
        onInfo('Link Copied to Clipboard');
      },
      () => {
        onError('Unable to copy link');
      }
    );
  };

  useEffect(() => {
    if (scrollToRow) {
      setSelected([scrollToRow]);
    }
  }, [scrollToRow]);

  const handleDeleteClick = (annotationId: number) => {
    setDeleteDialogOpen(true);
    setDeleteAnnotationId(annotationId);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteAnnotationId(-1);
  };

  const handleDeleteAnnotation = () => {
    setDeleteDialogOpen(false);
    onInfo('Deleting annotation...');
    deleteAnnotation(
      SeaTubeResourceTypes.EXPEDITION,
      expeditionId,
      deleteAnnotationId
    );
  };

  const handleSeaTubeSearchLink = () => {
    let queryString = '';
    const { cruiseId } = cruise;
    queryString = queryString.concat(`cruiseIds=${cruiseId}`);
    queryString = queryString.concat(`&includeDeckLog`);

    window.open(`/SeaTubeSearch?${queryString}`, '_blank');
  };

  const handleAddExtraColumns = () => {
    if (additionalColumns) {
      columns.push(...additionalColumns);
    }
    return columns;
  };

  return (
    <>
      <AttributeSelectDialog
        open={attributeDialogOpen}
        onSelect={handleSelectAttribute}
        onCancel={handleCancelAttributeSelect}
        rows={rows}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onCancel={handleDeleteCancel}
        onDelete={handleDeleteAnnotation}
        title="Delete Annotation?"
        message="Warning, you are deleting an annotation. It will no longer be viewable
        to any user. This cannot be undone."
      />
      <StatelessTable
        showNumberOfRecords={showNumberOfRecords}
        onReset={handleResetView}
        onRefresh={onRefresh}
        columnExtensions={[{ columnName: 'actions', align: 'right' }]}
        // This seems to impact performance HEAVILY
        virtual={{
          virtualized: true,
          rowHeight: 40,
        }}
        filter={{
          filterValue: filter,
          onChange: setFilter,
          filterForm,
          onFormChange: setFilterForm,
          filterFn: AnnotationTableLogic.filterFn,
          hidden: hideFilter,
        }}
        filterButtons={[
          <OutlinedButton
            translationKey="seatube.seatubeSearch"
            onClick={handleSeaTubeSearchLink}
            endIcon={<OpenInNew />}
          />,
        ]}
        rows={rows}
        columns={handleAddExtraColumns()
          .sort((a: any, b: any) => a.title.localeCompare(b.title))
          .filter((col) => !disabledColumns.includes(col.name))}
        sort={{
          sorting,
          columnExtensions: [{ columnName: 'actions', sortingEnabled: false }],
          handleSortingChange: setSorting,
        }}
        visible={{
          hiddenColumnNames,
          handleChangeVisibility: setHiddenColumns,
        }}
        group={{
          grouping,
          columnExtensions: groupingStateColumnExtensions,
          handleGroupingChange: setGrouping,
        }}
        selection={{
          selection: selected,
          onChange: handleSelectRow,
          showSelectionColumn: false,
          highlightRow: true,
          selectByRowClick: true,
        }}
        reorder={{
          columnOrder,
          handleColumnOrder: setColumnOrder,
          frozenLeftColumns: ['startDate'],
          frozenRightColumns: ['actions'],
        }}
        resize={{
          columnWidths,
          handleColumnWidths: setColumnWidths,
          columnExtensions: defaultConfig.resizingExtensions,
        }}
        fixed={{
          leftColumns: [],
          rightColumns: ['actions'],
        }}
        scrollToRow={scrollToRow}
        columnFormatProviders={[
          {
            name: 'ExpansionFormatter',
            for: [
              'modifiedBy',
              'taxonomy',
              'taxon',
              'comment',
              'lat',
              'lon',
              'depth',
              'heading',
            ],
            formatterComponent: ({ row, value }) =>
              ShowAllContentFormatter({ row, value, selected }),
          },
          {
            name: 'DateFormatter',
            for: ['startDate', 'createdDate', 'modifiedDate'],
            formatterComponent: ({ row, value }) =>
              DateFormatter({ row, value, selected }),
          },
          {
            name: 'AttributeFormatter',
            for: ['attributes'],
            formatterComponent: ({ row, value }) =>
              AttributeFormatter({ row, value, selected }),
          },
          {
            name: 'ToReviewFormatter',
            for: ['toBeReviewed'],
            formatterComponent: BooleanFormatter,
          },
          {
            name: 'ActionFormatter',
            for: ['actions'],
            formatterComponent: ({ row }) =>
              ActionFormatter({
                onCopyLink: handleCopyAnnotationLink,
                onEdit,
                onDelete: handleDeleteClick,
                row,
                permissions,
              }),
          },
        ]}
      />
    </>
  );
};

export default memo(AnnotationTable, (prev, next) => {
  const targetProps = [
    'rows',
    'permissions',
    'expeditionId',
    'storageKey',
    'scrollToRow',
  ];
  const prevProps = _.pick(prev, targetProps);
  const nextProps = _.pick(next, targetProps);
  return _.isEqual(prevProps, nextProps);
});
