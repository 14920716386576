import { useEffect, useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { ClearButton, ContainedButton } from '@onc/composite-components';
import { Autocomplete, DateTimePicker, Grid, TextField } from 'base-components';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemRoot: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    gridItemDateRoot: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'flex',
    },
    date: {
      flexGrow: 1,
    },
    x: {
      display: 'inline-block',
    },
  })
);

interface SearchParams {
  siteDeviceId?: number;
  site: { siteId: number; siteName: string } | null;
  deviceCategory: {
    deviceCategoryId: number;
    deviceCategoryName: string;
  } | null;
  device: { deviceId: number; deviceName: string } | null;
  startDate: Date;
  endDate: Date;
}

type SiteDeviceSearchParameterSelectProps = {
  searchParams: SearchParams;
  onSearch: () => void;
  onChange: (
    newParams: Record<string, SearchParams[keyof SearchParams]>
  ) => void;
};

const SiteDeviceSearchParameterSelect: React.VFC<
  SiteDeviceSearchParameterSelectProps
> = (props: SiteDeviceSearchParameterSelectProps): React.ReactElement => {
  const {
    searchParams: {
      siteDeviceId,
      site,
      deviceCategory,
      device,
      startDate,
      endDate,
    },
    onSearch,
    onChange,
  } = props;
  const classes = useStyles();

  const [sites, setSites] = useState([]);
  const [deviceCategories, setDeviceCategories] = useState([]);
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    get('SiteService')
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        const siteList = payload.map(({ sitename, siteid }) => ({
          siteLabel: `${sitename} (${siteid})`,
          siteId: siteid,
        }));
        setSites(siteList);
      });
    get('DeviceService', {
      operationtype: 9,
    }).then((response) => {
      const {
        data: {
          payload: { categories },
        },
      } = response;

      const deviceCategoryList = categories.map(
        ({ id: dcId, name: dcLabel, els }) => ({
          deviceCategoryId: dcId,
          deviceCategoryLabel: dcLabel,
          devices: els.map(({ id, name: deviceName }) => ({
            deviceId: id,
            deviceLabel: `${deviceName} (${id})`,
          })),
        })
      );
      setDeviceCategories(deviceCategoryList);
      setDevices(deviceCategoryList.flatMap((c) => c.devices));
    });
  }, []);

  const handleStartDateChange = (date: Moment) => {
    onChange({ startDate: date.toDate() });
  };

  const handleEndDateChange = (date: Moment) => {
    onChange({ endDate: date.toDate() });
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12} classes={{ root: classes.gridItemRoot }}>
          <TextField
            translationKey="device.siteDeviceId"
            name="siteDeviceId"
            aria-label="site device id"
            defaultValue={siteDeviceId || ''}
            value={siteDeviceId || ''}
            onChange={(e) => {
              const { value } = e.target;
              if (value === '') {
                onChange({ siteDeviceId: undefined });
              } else {
                const newValue = Number(value);
                if (!isNaN(newValue)) {
                  onChange({ siteDeviceId: newValue });
                }
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.gridItemRoot }}>
          <Autocomplete
            translationKey="device.site"
            name="site"
            aria-label="site selector"
            onChange={(_, newValue: SearchParams['site']) => {
              onChange({ site: newValue });
            }}
            options={sites}
            getOptionLabel={({ siteLabel }) => siteLabel ?? ''}
            isOptionEqualToValue={(option, value) =>
              option.siteId === value.siteId
            }
            value={site}
            virtualized
            fullWidth
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.gridItemRoot }}>
          <Autocomplete
            translationKey="device.deviceCategory"
            name="deviceCategory"
            aria-label="device category selector"
            onChange={(_, newValue: SearchParams['deviceCategory']) => {
              let newParams: Record<
                string,
                SearchParams['deviceCategory' | 'device']
              > = {
                deviceCategory: newValue,
              };
              const newDevices = newValue
                ? deviceCategories.find(
                    (dc) => dc.deviceCategoryId === newValue.deviceCategoryId
                  ).devices
                : deviceCategories.flatMap((c) => c.devices);
              if (!newDevices.some((d) => d.deviceId === device?.deviceId)) {
                newParams = { ...newParams, device: null };
              }
              onChange(newParams);
              setDevices(newDevices);
            }}
            options={deviceCategories}
            getOptionLabel={({ deviceCategoryLabel }) =>
              deviceCategoryLabel ?? ''
            }
            isOptionEqualToValue={(option, value) =>
              option.deviceCategoryId === value.deviceCategoryId
            }
            value={deviceCategory}
            virtualized
            fullWidth
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.gridItemRoot }}>
          <Autocomplete
            translationKey="device.device"
            name="device"
            aria-label="device selector"
            onChange={(_, newValue: SearchParams['device']) => {
              onChange({ device: newValue });
            }}
            options={devices}
            getOptionLabel={({ deviceLabel }) => deviceLabel ?? ''}
            isOptionEqualToValue={(option, value) =>
              option.deviceId === value.deviceId
            }
            value={device}
            virtualized
            fullWidth
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} classes={{ root: classes.gridItemDateRoot }}>
            <DateTimePicker
              translationKey="common.datepickers.startDate"
              onChange={handleStartDateChange}
              value={startDate ? moment(startDate) : null}
              fullWidth
            />
            <ClearButton
              aria-label="clear start date and time"
              onClick={() => onChange({ startDate: undefined })}
            />
          </Grid>
          <Grid item xs={6} classes={{ root: classes.gridItemDateRoot }}>
            <DateTimePicker
              translationKey="common.datepickers.endDate"
              onChange={handleEndDateChange}
              value={endDate ? moment(endDate) : null}
              fullWidth
            />
            <ClearButton
              aria-label="clear end date and time"
              onClick={() => onChange({ endDate: undefined })}
            />
          </Grid>
        </Grid>
        <Grid item classes={{ root: classes.gridItemRoot }}>
          <ContainedButton
            translationKey="common.search"
            aria-label="submit search parameters"
            onClick={onSearch}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { SiteDeviceSearchParameterSelect, type SearchParams };
