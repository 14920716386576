import { useState } from 'react';
import { InputBase, alpha } from '@mui/material';
import { Typography } from 'base-components';

const styles = {
  editable: (theme) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    width: '98%',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      position: 'relative',
      float: 'left',
      paddingLeft: '1%',
      verticalAlign: 'baseline',
    },
  }),
};

type UserDefinedDashboardTitleProps = {
  isEditable: boolean;
  initialTitle: string;
  onTitleSave?: (title: string) => void;
};

const UserDefinedDashboardTitle = ({
  isEditable,
  initialTitle,
  onTitleSave = undefined,
}: UserDefinedDashboardTitleProps) => {
  const [title, setTitle] = useState(initialTitle);

  const handleTitleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setTitle(e.currentTarget.value);
  };

  const handleTitleSave: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    onTitleSave?.(e.currentTarget.value);
  };

  if (isEditable) {
    return (
      <InputBase
        sx={styles.editable}
        title={title}
        value={title}
        onChange={handleTitleChange}
        onBlur={handleTitleSave}
      />
    );
  }
  return <Typography>{title}</Typography>;
};

export default UserDefinedDashboardTitle;
