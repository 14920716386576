import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  AbsoluteRightButtonStyle,
  withSnackbars,
} from '@onc/composite-components';
import Environment from '@onc/environment';
import AppliedQaqcTable from './AppliedQaqcTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  tableDiv: {
    margin: theme.spacing(1),
  },
  button: {
    paddingBottom: theme.spacing(7),
  },
  addQaqcAutoTestButton: AbsoluteRightButtonStyle(theme),
});

class AppliedQaqcPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    sensorId: PropTypes.number.isRequired,
  };

  render() {
    const { classes, onInfo, onError, sensorId } = this.props;
    return (
      <div className={classes.tableDiv}>
        <AppliedQaqcTable
          permission={Environment.getDmasUserPrivilege()}
          sensorId={sensorId}
          className={classes.root}
          classes={classes}
          stripedRows
          onInfo={onInfo}
          onError={onError}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(AppliedQaqcPage));
