import { useMemo } from 'react';
import {
  DeploymentDataSource,
  LocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import useDeploymentRows from './useDeploymentRows';
import useLocationRows from './useLocationRows';
import { getSourceIdentifier } from '../TraceDataUtils';
import { TraceLegendRowData } from '../TraceLegend';
import {
  isScalarDataChartDeploymentSource,
  type ScalarDataChartDeploymentSource,
  type ScalarDataChartLocationSource,
} from '../types/TimeSeriesScalarDataChart.types';

export type SiteDeviceServiceDeployment = {
  deviceName: string;
  dateFrom: string;
  dateTo: string;
  dataSource: DeploymentDataSource | LocationDataSource;
};

export const categorizeScalarDataSources = (
  dataSources: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  >
) => {
  const locationDataSources: ScalarDataChartLocationSource[] = [];
  const deploymentDataSources: ScalarDataChartDeploymentSource[] = [];
  const deviceDataSources: ScalarDataChartDeploymentSource[] = [];

  dataSources.forEach((source) => {
    if (isScalarDataChartDeploymentSource(source)) {
      if (source.dataSource.dateFrom && source.dataSource.dateTo) {
        // This is a deployment data source with dates
        deploymentDataSources.push(source);
      } else {
        // This is a device data source (deployment without dates)
        deviceDataSources.push(source);
      }
    } else {
      // This is a location data source
      locationDataSources.push(source);
    }
  });

  return {
    locationDataSources,
    deploymentDataSources,
    deviceDataSources,
  };
};

const useRowsFromSources = (
  dataSources: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  >
): TraceLegendRowData[] => {
  const { locationDataSources, deploymentDataSources, deviceDataSources } =
    categorizeScalarDataSources(dataSources);
  const { deviceDeploymentRows, isFetching: isFetchingDeployments } =
    useDeploymentRows(deviceDataSources);

  const deploymentRows = deploymentDataSources.map((deployment) => ({
    id: `${deployment.dataSource?.nodeId}-${deployment.dataSource?.deviceId}`,
    sourceName: deployment.traceName,
    traceColour: deployment.traceColour,
    deviceName: deployment.device.name,
    deviceId: deployment.device.deviceId,
    dateFrom: deployment.dataSource?.dateFrom,
    dateTo: deployment.dataSource?.dateTo,
    sourceIdentifier: getSourceIdentifier(deployment),
  }));

  const { rows: locationRows, isFetching: isFetchingLocations } =
    useLocationRows(locationDataSources);

  return useMemo(() => {
    if (isFetchingDeployments || isFetchingLocations) {
      return [];
    }

    return [...locationRows, ...deploymentRows, ...deviceDeploymentRows];
  }, [
    isFetchingDeployments,
    isFetchingLocations,
    locationRows,
    deploymentRows,
    deviceDeploymentRows,
  ]);
};

export default useRowsFromSources;
