import { useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  CancelButton,
  ContainedButton,
  SaveButton,
  TextButton,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  TableOld as Table,
  TextField,
  Typography,
} from 'base-components';
import {
  FormDialog,
  FullScreenDialog,
} from 'domain/AppComponents/dialogs/FormDialog';
import BatchDropdown from 'domain/AppComponents/dropdowns/BatchDropdown';
import {
  DataciteEnvironmentSelect,
  TaskDefinitionSelect,
  DeprecateDOIReasonSelect,
  BatchTypeSelect,
} from 'domain/AppComponents/dropdowns/Dropdowns';
import BatchTaskDetailTextFields from '../batch/dialog/BatchTaskDetailTextFields';

const CONFIRMATION_DIALOG_STYLES = makeStyles((theme) => ({
  dialogTitle: {
    paddingBottom: theme.spacing(1),
  },
  dialogContent: {
    paddingTop: theme.spacing(0.5),
    minWidth: 200,
    maxWidth: 300,
  },
}));

const SCROLLABLE_LIST = makeStyles(() => ({
  listContent: {
    maxHeight: 240,
    overflow: 'auto',
  },
}));

const GENERATE_DIALOG_STYLES = makeStyles(() => ({
  formControl: {
    width: '100%',
    minWidth: 240,
  },
}));

const ConfirmationDialog = (props) => {
  const { title, content, onCancel, onConfirm, open, ...rest } = props;
  const classes = CONFIRMATION_DIALOG_STYLES();
  return (
    <Dialog open={open} {...rest}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>{content}</DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel} />
        <ContainedButton
          translationKey="common.buttons.confirm"
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const CancelConfirmationDialog = (props) => {
  const { title, content, onCancel, onGoBack, open, ...rest } = props;
  const classes = CONFIRMATION_DIALOG_STYLES();
  return (
    <Dialog open={open} {...rest}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>{content}</DialogContent>
      <DialogActions>
        <TextButton translationKey="common.buttons.goBack" onClick={onGoBack} />
        <CancelButton variant="contained" onClick={onCancel} />
      </DialogActions>
    </Dialog>
  );
};

CancelConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const SaveDialog = (props) => {
  const { title, content, onCancel, onSave, ...rest } = props;
  const classes = CONFIRMATION_DIALOG_STYLES();
  return (
    <Dialog {...rest}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>{content}</DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel} />
        <SaveButton onClick={onSave} />
      </DialogActions>
    </Dialog>
  );
};

SaveDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const BatchDialog = ({
  batchDetails = {
    input: {
      batchName: '',
      reason: '',
    },
  },
  title,
  confirmButtonContent,
  batchDetailsCallback,
  batchTaskType = undefined,
  batchTypes = [],
  taskDetails = [],
  onSelectChange = () => {},
  onTextFieldChange = () => {},
  ...rest
}) => {
  const batchDetailsChange = (event) => {
    const newBatchDetails = batchDetails;
    newBatchDetails.input[event.target.name] = event.target.value;
    batchDetailsCallback(newBatchDetails);
  };

  return (
    <FormDialog
      title={title}
      confirmButtonContent={confirmButtonContent}
      {...rest}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <BatchTypeSelect
            value={batchTaskType}
            options={batchTypes}
            onChange={onSelectChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            translationKey="taskmanagement.batchName"
            fullWidth
            name="batchName"
            onChange={batchDetailsChange}
            value={batchDetails.input.batchName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            translationKey="taskmanagement.batchReason"
            fullWidth
            multiline
            rows={3}
            name="reason"
            onChange={batchDetailsChange}
            value={batchDetails.input.reason}
          />
        </Grid>
        <Grid item xs={12}>
          <BatchTaskDetailTextFields
            taskDetails={taskDetails}
            onTextFieldChange={onTextFieldChange}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

BatchDialog.propTypes = {
  batchDetails: PropTypes.shape({
    input: PropTypes.shape({
      batchName: PropTypes.string.isRequired,
      reason: PropTypes.string.isRequired,
    }),
  }),
  title: PropTypes.string.isRequired,
  confirmButtonContent: PropTypes.node.isRequired,
  batchDetailsCallback: PropTypes.func.isRequired,
  batchTaskType: PropTypes.string,
  batchTypes: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectChange: PropTypes.func,
  onTextFieldChange: PropTypes.func,
  taskDetails: PropTypes.arrayOf(PropTypes.shape({})),
};

const GenerateDOIDialog = ({
  open,
  onCancel,
  onConfirm,
  onBatchChange,
  onEnviroChange,
  title,
  text,
  batchId = undefined,
  environment,
  noAdmin = false,
}) => {
  const classes = GENERATE_DIALOG_STYLES();

  const selectProps = { onEnviroChange, environment, noAdmin };
  const content = (
    <>
      <DialogContentText align="center">{text}</DialogContentText>
      <FormControl required className={classes.formControl}>
        <BatchDropdown
          value={!isNaN(batchId) ? batchId.toString() : ''}
          title="Batch"
          helperText="Associate task with selected batch"
          onChange={onBatchChange}
          fullWidth
        />
        <DataciteEnvironmentSelect
          fullWidth
          helperText="Choose DataCite repository"
          {...selectProps}
        />
      </FormControl>
    </>
  );
  return (
    <FormDialog
      title={title}
      open={open}
      onClose={onCancel}
      onSubmit={onConfirm}
      confirmButtonContent={title}
    >
      {content}
    </FormDialog>
  );
};

GenerateDOIDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onBatchChange: PropTypes.func.isRequired,
  onEnviroChange: PropTypes.func.isRequired,
  batchId: PropTypes.string,
  environment: PropTypes.string.isRequired,
  noAdmin: PropTypes.bool,
};

const DeleteDashboardDialog = (props) => {
  const { open, onCancel, onConfirm, dashboardTitle } = props;
  const content = (
    <Typography>
      Are you sure you want to delete {dashboardTitle}? This cannot be undone!
    </Typography>
  );
  return (
    <ConfirmationDialog
      title="Delete Dashboard"
      content={content}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

DeleteDashboardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  dashboardTitle: PropTypes.string.isRequired,
};

const RemoveFilterConfirmation = (props) => {
  const { open, onCancel, onConfirm, filterLabel } = props;
  const content = (
    <Typography>Are you sure you want to remove this filter?</Typography>
  );
  return (
    <ConfirmationDialog
      title={`Remove ${filterLabel} Filter?`}
      content={content}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

RemoveFilterConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  filterLabel: PropTypes.string.isRequired,
};

/** @param {any} props */
const DeprecateDOIDialog = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    message,
    onMessageChange,
    onReasonChange,
    reason,
    title,
    confirmButtonContent,
  } = props;

  return (
    <FormDialog
      title={title}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      confirmButtonContent={confirmButtonContent}
    >
      <DeprecateDOIReasonSelect
        onChange={onReasonChange}
        fullWidth
        value={reason}
      />
      <TextField
        translationKey="taskmanagement.batchMessage"
        fullWidth
        multiline
        rows={3}
        onChange={onMessageChange}
        value={message}
      />
    </FormDialog>
  );
};

DeprecateDOIDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onReasonChange: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  confirmButtonContent: PropTypes.string.isRequired,
};

/** @param {any} props */
const StackTraceDialog = ({
  open = undefined,
  onClose,
  title,
  errorMessage,
  ...rest
}) => {
  const theme = useTheme();
  let fullScreen = false;

  if (useMediaQuery(theme.breakpoints.down('md'))) {
    fullScreen = true;
  }

  const renderDialogActions = () => {
    if (!fullScreen) {
      return (
        <DialogActions>
          <TextButton translationKey="common.buttons.close" onClick={onClose} />
        </DialogActions>
      );
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      aria-label={title}
      maxWidth="lg"
    >
      <FullScreenDialog fullScreen={fullScreen} onClose={onClose} {...rest} />
      {fullScreen ? null : <DialogTitle id={title}>{title}</DialogTitle>}
      <DialogContent>
        <code style={{ fontSize: 'small', whiteSpace: 'pre' }}>
          {errorMessage}
        </code>
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );
};
StackTraceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

/**
 * Dialog to add a Task to a Batch
 *
 * @param {any} props
 */
const BatchTaskDialog = ({
  taskDetails = [],
  taskDefinitionId = undefined,
  onSelectChange = () => {},
  taskDefinitions = [],
  onTextFieldChange,
  edit = false,
  ...rest
}) => {
  const confirmButtonContent = () => {
    if (!edit) {
      return (
        <>
          <Add /> Add Batch Task
        </>
      );
    }

    return <>Update Task</>;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const TaskDefinitionSelectGrid = () => {
    if (!edit) {
      return (
        <Grid item xs={12}>
          <TaskDefinitionSelect
            options={taskDefinitions}
            value={taskDefinitionId}
            onChange={onSelectChange}
            fullWidth
          />
        </Grid>
      );
    }

    return null;
  };

  return (
    <FormDialog
      title={edit ? 'Update Task' : 'Add Task to Batch'}
      canSave={typeof taskDefinitionId !== 'undefined' || edit}
      confirmButtonContent={confirmButtonContent()}
      {...rest}
    >
      <Grid container spacing={2}>
        <TaskDefinitionSelectGrid />
        <BatchTaskDetailTextFields
          taskDetails={taskDetails}
          onTextFieldChange={onTextFieldChange}
        />
      </Grid>
    </FormDialog>
  );
};

BatchTaskDialog.propTypes = {
  onTextFieldChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func,
  taskDefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  taskDetails: PropTypes.arrayOf(PropTypes.shape({})),
  taskDefinitionId: PropTypes.number,
  edit: PropTypes.bool,
};

/**
 * Dialog to add/edit a Task to a Batch
 *
 * @param {any} props
 */
const BatchTaskInfoDialog = ({ open, onClose, data = {} }) => {
  const FORMATTING = [{ columnName: 'value', wordWrapEnabled: true }];
  const classes = SCROLLABLE_LIST();

  const renderList = (list) =>
    list.map((row) => <ListItem key={row}>{row}</ListItem>);

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return <List className={classes.listContent}>{renderList(value)}</List>;
    }
    return value;
  };

  const renderTableRows = () => {
    const rows = [];
    for (const row in data) {
      if (row && data[row]) {
        rows.push({
          key: row,
          property: row,
          value: renderValue(data[row]),
        });
      }
    }

    return rows;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Task Information</DialogTitle>
      <DialogContent>
        <Table
          rows={renderTableRows()}
          columns={[
            { name: 'property', title: 'Property' },
            { name: 'value', title: 'Value' },
          ]}
          columnExtensions={FORMATTING}
        />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

BatchTaskInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
};

/**
 * Dialog to add cancel a task
 *
 * @param {any} props
 */
const RemoveTaskDialog = (props) => {
  const {
    open,
    onClose,
    taskId,
    onSubmit,
    deleteTask,
    handleCheckbox,
    canDelete,
  } = props;

  const renderPermaDelete = () => {
    if (canDelete) {
      return (
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                onClick={handleCheckbox}
                checked={deleteTask}
                name="permanentDeleteCheckbox"
              />
            }
            label="Permanently Delete Task"
          />
        </DialogContent>
      );
    }
    return null;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove Task</DialogTitle>
      <DialogContent>
        Are you sure you want to remove task {taskId}?
      </DialogContent>
      {renderPermaDelete()}
      <DialogActions>
        <CancelButton variant="text" onClick={onClose} color="primary" />
        <ContainedButton
          translationKey="taskmanagement.removeTask"
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

RemoveTaskDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
  deleteTask: PropTypes.bool.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
};

/** A generic dialog for displaying information via a string, and nothing else. */
const GenericInfoDialog = ({ open, onClose, title = null, message }) => (
  <Dialog open={open} onClose={onClose}>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{message}</DialogContent>
    <DialogActions>
      <ContainedButton
        translationKey="common.buttons.ok"
        onClick={onClose}
        color="primary"
      />
    </DialogActions>
  </Dialog>
);

GenericInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

/** A generic dialog for displaying information via a node, and nothing else. */
const GenericInfoNodeDialog = (props) => {
  const { open, onClose, title, message } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <ContainedButton
          translationKey="common.buttons.ok"
          onClick={onClose}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

GenericInfoNodeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
};

export {
  DeleteDashboardDialog,
  RemoveFilterConfirmation,
  GenerateDOIDialog,
  ConfirmationDialog,
  CancelConfirmationDialog,
  BatchTaskDialog,
  BatchDialog,
  StackTraceDialog,
  BatchTaskInfoDialog,
  RemoveTaskDialog,
  DeprecateDOIDialog,
  SaveDialog,
  GenericInfoDialog,
  GenericInfoNodeDialog,
};
