import { useEffect, useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { SaveButton, CancelButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import {
  ResourceSelect,
  ResourceTypeDropdown,
} from 'domain/AppComponents/dropdowns/ResourceDropdowns';
import TaxonAsyncAutocomplete, {
  TaxonOption,
} from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import TaxonomySelect from 'domain/AppComponents/dropdowns/TaxonomySelect';

type TaxonResourceState = {
  taxonResourceId?: number;
  taxonomyId?: number;
  taxonId?: number;
  resourceTypeId?: number;
  resource?: {
    id: number;
    name: string;
  };
};

type TaxonResourceConfigProps = {
  title: string;
  variant: 'staticResourceTypeId' | 'staticResourceId' | undefined;
  taxonResourceId?: number;
  taxonomy?: {
    taxonomyId: number;
    taxonomyName: string;
  };
  taxon?: {
    taxonId: number;
    commonName: string;
  };
  resourceType?: {
    resourceTypeId: number;
    resourceTypeName: string;
  };
  resource?: {
    id: number;
    name: string;
  };
  classes: {
    root: string;
    rightAlign: string;
  };
  onCancel: () => void;
  onSave: (taxonResource: TaxonResourceState) => void;
  onError: (error: Error) => void;
};

interface ChangeEvent {
  target: {
    name: string;
    value: any;
  };
}

const STYLES = (theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  leftJustify: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  leftAlign: {
    marginLeft: theme.spacing(1),
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const TaxonResourceConfig: React.VFC<TaxonResourceConfigProps> = (
  // eslint-disable-next-line react/require-default-props
  props: TaxonResourceConfigProps
): React.ReactElement => {
  const {
    title,
    variant,
    taxonResourceId: taxonResourceIdProp,
    taxonomy: taxonomyProp,
    taxon: taxonProp,
    resourceType: resourceTypeProp,
    resource: resourceProp,
    onCancel,
    onSave,
    onError,
    classes,
  } = props;

  const IMPORTED_TAXONOMIES = ['1', '3']; // WoRMS and CMECS

  const isUserDefined = (value: string): boolean =>
    !IMPORTED_TAXONOMIES.includes(value);

  const [taxonResource, setTaxonResource] = useState<TaxonResourceState>({
    taxonResourceId: taxonResourceIdProp,
    taxonomyId: taxonomyProp ? taxonomyProp.taxonomyId : undefined,
    taxonId: taxonProp ? taxonProp.taxonId : undefined,
    resourceTypeId: resourceTypeProp
      ? resourceTypeProp.resourceTypeId
      : undefined,
    resource: resourceProp,
  });
  const [userDefined, setUserDefined] = useState<boolean>(
    taxonomyProp ? isUserDefined(String(taxonomyProp.taxonomyId)) : false
  );

  useEffect(() => {
    setTaxonResource({
      taxonResourceId: taxonResourceIdProp,
      taxonomyId: taxonomyProp ? taxonomyProp.taxonomyId : undefined,
      taxonId: taxonProp ? taxonProp.taxonId : undefined,
      resourceTypeId: resourceTypeProp
        ? resourceTypeProp.resourceTypeId
        : undefined,
      resource: resourceProp,
    });
  }, [
    taxonResourceIdProp,
    taxonomyProp,
    taxonProp,
    resourceTypeProp,
    resourceProp,
  ]);

  const handleChange = (e: ChangeEvent) => {
    const prevTR = taxonResource;
    setTaxonResource({ ...prevTR, [e.target.name]: e.target.value });
  };

  const handleTaxonomyChange = (e: ChangeEvent) => {
    const prevTR = taxonResource;
    setUserDefined(isUserDefined(String(e.target.value)));
    setTaxonResource({ ...prevTR, taxonId: undefined });
    handleChange(e);
  };

  const handleResourceTypeChange = (e: ChangeEvent) => {
    const prevTR = taxonResource;
    setTaxonResource({ ...prevTR, resource: undefined });
    handleChange(e);
  };

  const handleTaxonChange = (e: ChangeEvent, taxon: TaxonOption) => {
    handleChange({
      ...e,
      target: { name: 'taxonId', value: taxon?.taxonId || null },
    });
  };

  const taxonomySelector = (): React.ReactElement => {
    const { taxonomyId } = taxonResource;
    return (
      <Grid item xs={12}>
        <TaxonomySelect
          name="taxonomyId"
          initialValue={taxonomyId || ''}
          value={taxonomyId || ''}
          onChange={handleTaxonomyChange}
          onError={onError}
          fullWidth
        />
      </Grid>
    );
  };

  const taxonSelector = (): React.ReactElement => {
    const { taxonomyId, taxonId } = taxonResource;
    return (
      <Grid item xs={12}>
        <TaxonAsyncAutocomplete
          taxonomyId={taxonomyId}
          taxonId={taxonId}
          searchLettersRequired={userDefined ? 1 : 3}
          onChange={handleTaxonChange}
        />
      </Grid>
    );
  };

  const resourceTypeSelector = (): React.ReactElement => {
    const { resourceTypeId } = taxonResource;
    return (
      <Grid item xs={12}>
        <ResourceTypeDropdown
          resourceTypeId={resourceTypeId}
          onChange={handleResourceTypeChange}
          onError={onError}
        />
      </Grid>
    );
  };

  const resourceSelector = (): React.ReactElement => {
    const { resourceTypeId, resource } = taxonResource;
    return (
      <Grid item xs={12}>
        <ResourceSelect
          resourceTypeId={resourceTypeId}
          resource={resource}
          onChange={handleChange}
          onError={onError}
        />
      </Grid>
    );
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} className={classes.root}>
          {taxonomySelector()}
          {taxonSelector()}
          {!variant ? resourceTypeSelector() : null}
          {variant !== 'staticResourceId' ? resourceSelector() : null}
        </Grid>
        <Grid item className={classes.rightAlign}>
          <CancelButton onClick={onCancel} />
          <SaveButton
            onClick={() => {
              onSave(taxonResource);
            }}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(STYLES)(TaxonResourceConfig);
