import * as React from 'react';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
  TemplateConnector,
} from '@devexpress/dx-react-core';
import { Badge } from '@mui/material';
import { FilterList, FilterListOff } from '@onc/icons';
import _ from 'lodash';
import { IconButton, Typography } from 'base-components';

import ToolbarFilterMenu from './ToolbarFilterMenu';
import { TableColumnDataTypes } from '../../StatelessTable';

const pluginDependencies = [
  { name: 'Toolbar' },
  { name: 'ToolbarFilterState' },
];

export interface TableFilterProps {
  filterValue: TableFilter;
  filterForm?: TableFilter;
  onChange?: (filter: TableFilter) => void;
  onFormChange?: (filter: TableFilter) => void;
  filterFn?: (row: any, filterLine: any) => void;
  hidden?: boolean;
}

export interface TableFilterLine {
  column: string;
  dataType: TableColumnDataTypes;
  data?: any;
  operator?: string | null;
  value?: string | string[];
  options?: any[];
}

export interface TableFilter {
  logic: 'And' | 'Or';
  filterGroups: TableFilterGroup[];
  enabled: boolean;
}

export interface TableFilterGroup {
  logic: 'And' | 'Or';
  filterLines: TableFilterLine[];
}

export const getEmptyFilter = () => {
  const emptyFilter: TableFilter = {
    logic: 'And',
    enabled: true,
    filterGroups: [
      {
        logic: 'And',
        filterLines: [
          {
            column: '',
            operator: '',
            value: '',
            dataType: 'String',
          },
        ],
      },
    ],
  };
  return _.cloneDeep(emptyFilter);
};

interface Props {
  filteredRows: any[];
  allRows: any[];
  filterButtons?: React.ReactNode[];
}

const ToolbarFilter: React.VFC<Props> = ({
  allRows,
  filteredRows,
  filterButtons = [],
}: Props) => (
  <Plugin dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <TemplateConnector>
        {(
          { columns, filterForm, open, filterCount },
          { onOpen, onClose, onChangeFilterForm, onApplyFilter }
        ) => (
          <>
            <Typography
              variant="caption"
              style={{ whiteSpace: 'noWrap', marginRight: '8px' }}
            >
              {filteredRows.length} of {allRows.length}
            </Typography>
            <IconButton
              aria-controls="table-filter"
              data-test="table-filter"
              aria-label="Open Filter Menu"
              onClick={onOpen}
              style={{ width: '48px' }}
            >
              <Badge
                badgeContent={filterCount}
                color="error"
                overlap="rectangular"
                invisible={filterCount === 0}
              >
                {filterForm.enabled ? <FilterList /> : <FilterListOff />}
              </Badge>
            </IconButton>

            <ToolbarFilterMenu
              open={open}
              columns={columns}
              onClose={onClose}
              filterState={filterForm}
              onStateChange={onChangeFilterForm}
              onChange={onApplyFilter}
              rows={allRows}
              filterButtons={filterButtons}
            />
          </>
        )}
      </TemplateConnector>
    </Template>
  </Plugin>
);

export default ToolbarFilter;
