import { useEffect, useState } from 'react';
import _ from 'lodash';
import CompromisedAnnotationEntry from 'domain/AppComponents/compromised-instruments/CompromisedAnnotationEntry';
import CompromisedInstrumentsTable from 'domain/AppComponents/compromised-instruments/CompromisedInstrumentsTable';
import { EditIconButton } from 'domain/AppComponents/IconButtons';
import JiraLink from 'domain/AppComponents/link/JiraLink';
import {
  CompromisedAnnotation,
  useGetAllCompromisedAnnotations,
} from 'domain/services/CompromisedInstrumentsService';

const JIRA_REGEX = /(INSTR|NEPDATA|DSO|DATA|SYS|EN|OPE)-{1}([0-9]{3,5})/;

export type CompromisedAnnotationRow = {
  annotationHdrId: number;
  resourceType: string;
  comment: string;
  startDate: string;
  endDate: string;
  taxon?: string;
  jiraLink: JSX.Element | string;
  actions: JSX.Element;
};

const CompromisedInstrumentsPage = () => {
  const [annotations, setAnnotations] =
    useState<CompromisedAnnotation[]>(undefined);
  const [editAnnotation, setEditAnnotation] =
    useState<CompromisedAnnotation>(undefined);
  const [compromisedRows, setCompromisedRows] =
    useState<CompromisedAnnotationRow[]>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);

  const extractJiraId = (anno: CompromisedAnnotation) => {
    let id: string;
    if (anno.taxons && anno.taxons[0].attributes) {
      const attribute = anno.taxons[0].attributes.find((attr) =>
        attr.name.match(/Jira Ticket/i)
      );
      id = attribute ? attribute.value : null;
    }
    if (!id) {
      const matches = anno.comment.match(JIRA_REGEX);
      id = matches ? matches[0] : null;
    }
    return id;
  };

  const transformToRows = (data: CompromisedAnnotation[]) =>
    data.map((anno) => ({
      annotationHdrId: anno.annotationHdrId,
      resourceType: anno.resourceTypeName,
      deviceName: anno.resourceName,
      deviceId: anno.resourceId.toString(),
      comment: anno.comment,
      taxon: anno.taxons ? anno.taxons[0].displayText : '',
      startDate: anno.startDate,
      endDate: anno.endDate || '',
      jiraLink: extractJiraId(anno) ? (
        <JiraLink jiraId={extractJiraId(anno)} />
      ) : (
        ''
      ),
      actions: (
        <EditIconButton
          aria-label="Edit Annotation"
          size="small"
          onClick={() => {
            setEditAnnotation(anno);
            setDialogOpen(true);
          }}
        />
      ),
    }));

  const { data } = useGetAllCompromisedAnnotations();

  useEffect(() => {
    setAnnotations(data);
  }, [data]);

  const handleAnnotationUpdated = (anno: CompromisedAnnotation) => {
    const newAnnotations = _.cloneDeep(annotations);
    const index = newAnnotations.findIndex(
      (a: CompromisedAnnotation) => a.annotationHdrId === anno.annotationHdrId
    );
    newAnnotations[index] = anno;
    setAnnotations(newAnnotations);
  };

  useEffect(() => {
    if (annotations) {
      setCompromisedRows(transformToRows(annotations));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotations]);

  return (
    <>
      <CompromisedAnnotationEntry
        open={dialogOpen}
        onClose={() => {
          setEditAnnotation(undefined);
          setDialogOpen(false);
        }}
        editAnnotation={editAnnotation}
        onUpdate={handleAnnotationUpdated}
      />
      <CompromisedInstrumentsTable rows={compromisedRows} />
    </>
  );
};

export default CompromisedInstrumentsPage;
