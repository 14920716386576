import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';

const useStyles = makeStyles((theme) => ({
  formButtons: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

type NodeType = {
  children?: Array<any>;
  enabled?: boolean;
  modifyBy?: string;
  modifyDate?: string;
  multiQueueTreeNodeId?: number;
  multiQueueTreeNodeName?: string;
  multiQueueTypeId?: number;
  objectId?: number;
  primaryShorestation?: boolean;
  defaultQueue?: string;
};

type CreateQueueDialogProps = {
  closeDialog: () => void;
  refreshTree: () => void;
  showDialog: boolean;
  node: NodeType;
  locationNode: NodeType;
};

const CreateQueueForm = ({
  closeDialog,
  refreshTree,
  showDialog,
  node,
  locationNode,
}: CreateQueueDialogProps) => {
  const classes = useStyles();
  const { onInfo, onError } = useSnackbars();
  const [defaultQueueChecked, setDefaultQueueChecked] =
    useState<boolean>(false);
  const { mutate: saveQueue } = usePost<{ type: string; payload: string }>(
    'MultiQueueService',
    {
      onSuccess: () => {
        onInfo(`successfully saved queue`);
        refreshTree();
      },
      onError: (e) => {
        onError(`${e} failed to save queue`);
      },
    },
    1
  );

  const handleSave = (values) => {
    const params = {
      name: values.name,
      jvmId: node.objectId,
      defaultQueue: defaultQueueChecked || !locationNode.defaultQueue,
    };
    saveQueue({ type: 'queue', payload: JSON.stringify(params) });
    setDefaultQueueChecked(false);
    closeDialog();
  };

  const formMethods = useForm<{ jvmId: number; name: string }>({
    mode: 'onBlur',
    values: {
      jvmId: node.objectId,
      name: undefined,
    },
  });

  if (!node) {
    return null;
  }
  return (
    <Dialog open={showDialog} onClose={closeDialog} fullWidth>
      <DialogTitle>Create Queue</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSave} formMethods={formMethods}>
          <FormTextField
            id="name-field"
            className={classes.textField}
            fullWidth
            disabled
            translationKey="common.textfields.variable"
            translationOptions={{ variableName: node.multiQueueTreeNodeName }}
            name="jvmId"
          />
          <FormTextField
            InputLabelProps={{ required: false }}
            translationKey="multiqueue.queueName"
            placeholder="Enter Queue Name"
            name="name"
            rules={{ required: 'A queue name is required!' }}
            fullWidth
          />
          <div className={classes.formButtons}>
            <CancelButton onClick={closeDialog} />
            <SaveButton type="submit" />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const CreateQueueDialog = ({
  closeDialog,
  refreshTree,
  showDialog,
  node,
  locationNode,
}: CreateQueueDialogProps) => (
  <CreateQueueForm
    node={node}
    showDialog={showDialog}
    closeDialog={closeDialog}
    refreshTree={refreshTree}
    locationNode={locationNode}
  />
);

export default CreateQueueDialog;
