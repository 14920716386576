import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  SaveButton,
  CancelButton,
  TextButton,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Grid, TextField, Tooltip } from 'base-components';
import {
  GroupSelect,
  DataTypeSelect,
} from 'domain/AppComponents/dropdowns/Dropdowns';
import { BaseReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import Panel from 'library/CompositeComponents/panel/Panel';
import Environment from '../../../util/Environment';

const styles = (theme) => ({
  contentContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  optionsContainer: {
    paddingLeft: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
  },
  flexGrow: {
    flexGrow: '1',
  },
  floatRight: {
    float: 'right',
  },
});

// Should we have a service to get these?
const SELECT = 6;
const DATA_TYPE_LIST = [
  {
    value: 3,
    label: 'Boolean',
  },
  {
    value: 1,
    label: 'Integer',
  },
  {
    value: 5,
    label: 'Number',
  },
  {
    value: 6,
    label: 'Select',
  },
  {
    value: 2,
    label: 'String',
  },
];

class AttributeDetailsPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    selectedAttribute: PropTypes.shape({
      attributeValues: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    handleUpdateAttribute: PropTypes.func.isRequired,
    setUnsavedChanges: PropTypes.func.isRequired,
    attributeLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    groupList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  static defaultProps = {
    selectedAttribute: undefined,
  };

  constructor(props) {
    super(props);
    const { selectedAttribute, attributeLines } = this.props;
    this.state = {
      currentAttribute: selectedAttribute,
      currentAttributeLines: attributeLines,
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedAttribute } = this.props;
    const { selectedAttribute: prevSelectedAttribute } = prevProps;
    if (selectedAttribute !== prevSelectedAttribute) {
      this.createCurrentAttribute();
      this.createCurrentAttributeLines();
    }
  }

  createCurrentAttributeLines = () => {
    const { attributeLines } = this.props;
    const linesCopy = [...attributeLines];
    this.setState({
      currentAttributeLines: linesCopy,
    });
  };

  createCurrentAttribute = () => {
    const { selectedAttribute } = this.props;
    this.setState({
      currentAttribute: selectedAttribute,
    });
  };

  handleValueChange = (updatedProps) => {
    const { currentAttribute } = this.state;
    const updatedAttribute = { ...currentAttribute };
    Object.assign(updatedAttribute, updatedProps);
    this.setState({ currentAttribute: updatedAttribute });
  };

  handleOptionValue = (event, item) => {
    const { currentAttributeLines } = this.state;
    const updatedLines = [...currentAttributeLines];
    const index = updatedLines.findIndex(
      (line) => line.taxonomyAttributeLineId === item.taxonomyAttributeLineId
    );
    updatedLines[index].attributeValue = event.target.value;
    this.setState({ currentAttributeLines: updatedLines });
  };

  handleAddOption = () => {
    const { currentAttributeLines } = this.state;
    const updatedLines = [...currentAttributeLines];
    // Generate unique key for React
    let key = 0;
    // eslint-disable-next-line no-loop-func
    while (updatedLines.find((line) => line.taxonomyAttributeLineId === key)) {
      key += 1;
    }
    updatedLines.push({ attributeValue: '', taxonomyAttributeLineId: key });
    this.setState({ currentAttributeLines: updatedLines });
  };

  handleRemoveOption = (index) => {
    const { currentAttributeLines } = this.state;
    const updatedLines = [...currentAttributeLines];
    updatedLines.splice(index, 1);
    this.setState({ currentAttributeLines: updatedLines });
  };

  renderNameField = (hasPermission) => {
    const { currentAttribute } = this.state;
    if (hasPermission) {
      return (
        <TextField
          translationKey="common.textfields.name"
          fullWidth
          value={currentAttribute.name}
          onChange={(e) => {
            this.handleValueChange({ name: e.target.value });
          }}
        />
      );
    }
    return (
      <BaseReadOnlyField valueText={currentAttribute.name} labelText="Name" />
    );
  };

  renderGroupNameField = (hasPermission) => {
    const { groupList } = this.props;
    const { currentAttribute } = this.state;
    if (hasPermission) {
      return (
        <GroupSelect
          id="attribute-details-panel-group-select"
          fullWidth
          value={currentAttribute.groupId}
          options={groupList.map((item) => ({
            label: item.name,
            value: item.key,
          }))}
          onChange={(e) => {
            this.handleValueChange({ groupId: e.target.value });
          }}
        />
      );
    }
    return (
      <BaseReadOnlyField
        valueText={currentAttribute.groupName}
        labelText="Group"
      />
    );
  };

  renderDataTypeField = (hasPermission) => {
    const { currentAttribute } = this.state;
    if (hasPermission) {
      return (
        <Tooltip
          disableHoverListener={currentAttribute.canBeUpdated}
          title="Data Type can only be changed if the attribute is not currently being used on an annotation or as a taxon's default attribute"
        >
          <span>
            <DataTypeSelect
              id="data-type-select"
              fullWidth
              options={DATA_TYPE_LIST}
              value={currentAttribute.dataTypeId}
              onChange={(e) => {
                this.handleValueChange({ dataTypeId: e.target.value });
              }}
              disabled={!currentAttribute.canBeUpdated}
            />
          </span>
        </Tooltip>
      );
    }
    return (
      <BaseReadOnlyField
        valueText={currentAttribute.dataType}
        labelText="Data Type"
      />
    );
  };

  renderOptionLines = (hasPermission) => {
    const { classes } = this.props;
    const { currentAttributeLines, currentAttribute } = this.state;
    if (currentAttributeLines && currentAttribute.dataTypeId === SELECT) {
      return currentAttributeLines.map((item, index) => {
        const newItem = { ...item };
        if (hasPermission) {
          return (
            <div key={newItem.taxonomyAttributeLineId} className={classes.flex}>
              <TextField
                id={`attribute-details-panel-option${index + 1}`}
                className={classes.flexGrow}
                value={newItem.attributeValue}
                name="attributeValue"
                translationKey="taxonomy.numberedOption"
                translationOptions={{ optionIndex: index + 1 }}
                onChange={(e) => this.handleOptionValue(e, newItem)}
              />
              <DeleteIconButton
                id={`delete-option${index + 1}`}
                onClick={() => this.handleRemoveOption(index)}
              />
            </div>
          );
        }
        return (
          <Grid key={newItem.taxonomyAttributeLineId} item xs={12}>
            <BaseReadOnlyField
              valueText={newItem.attributeValue}
              labelText={`Option ${index}`}
            />
          </Grid>
        );
      });
    }
    return null;
  };

  renderAddOptionButton = (hasPermission) => {
    const { currentAttribute } = this.state;
    if (hasPermission && currentAttribute.dataTypeId === SELECT) {
      return (
        <TextButton
          translationKey="taxonomy.addOption"
          startIcon={<Add />}
          id="AddOption"
          onClick={this.handleAddOption}
        />
      );
    }
    return undefined;
  };

  hasAttributeChanged = (
    currentAttribute,
    selectedAttribute,
    currentAttributeLines
  ) => {
    const { setUnsavedChanges } = this.props;
    let unsavedAttribute = false;
    let unsavedOptions = false;

    if (
      currentAttribute &&
      selectedAttribute &&
      currentAttribute.attributeId === selectedAttribute.attributeId
    ) {
      unsavedAttribute = !_.isEqual(selectedAttribute, currentAttribute);
      currentAttributeLines.forEach((line) => {
        if (
          !selectedAttribute.attributeValues.find(
            (item) => item.label === line.attributeValue
          )
        ) {
          unsavedOptions = true;
        }
      });
      selectedAttribute.attributeValues.forEach((line) => {
        if (
          !currentAttributeLines.find(
            (item) => line.label === item.attributeValue
          )
        ) {
          currentAttributeLines.forEach((attrLine) => {
            if (!this.attributeOptionNameExists(attrLine.attributeValue)) {
              unsavedOptions = true;
            }
          });
        }
        if (!this.attributeOptionValueExists(line.value)) {
          unsavedOptions = true;
        }
      });
      setUnsavedChanges(
        unsavedAttribute || unsavedOptions,
        currentAttribute,
        currentAttributeLines
      );
      return unsavedAttribute || unsavedOptions;
    }
    setUnsavedChanges(false, undefined, undefined);
    return false;
  };

  attributeOptionNameExists = (name) => {
    const { currentAttributeLines } = this.state;
    return currentAttributeLines.some(
      (option) => option.attributeValue === name
    );
  };

  attributeOptionValueExists = (value) => {
    const { currentAttributeLines } = this.state;
    return currentAttributeLines.some(
      (option) => option.taxonomyAttributeLineId === value
    );
  };

  handleCancel = (selectedAttribute) => {
    const { attributeLines } = this.props;
    const resetAttributeLines = [];
    attributeLines.forEach((attrLine) => {
      const resetAttrOption = selectedAttribute.attributeValues.find(
        (attrValue) =>
          attrValue.value === attrLine.taxonomyAttributeLineId &&
          attrLine.attributeValue !== attrValue.label
      );
      if (resetAttrOption) {
        // eslint-disable-next-line no-param-reassign
        attrLine.attributeValue = resetAttrOption.label;
      }
      resetAttributeLines.push(attrLine);
    });

    this.setState({
      currentAttribute: selectedAttribute,
      currentAttributeLines: resetAttributeLines,
    });
  };

  renderFormButtons = (hasPermission) => {
    const { classes, selectedAttribute, handleUpdateAttribute } = this.props;
    const { currentAttribute, currentAttributeLines } = this.state;
    if (hasPermission) {
      return (
        <Grid item xs={12}>
          <SaveButton
            id="attribue-details-panel-save"
            className={classes.floatRight}
            disabled={
              !this.hasAttributeChanged(
                currentAttribute,
                selectedAttribute,
                currentAttributeLines
              )
            }
            onClick={() =>
              handleUpdateAttribute(
                currentAttribute,
                currentAttributeLines,
                false,
                selectedAttribute
              )
            }
          />
          <CancelButton
            id="attribue-details-panel-cancel"
            className={classes.floatRight}
            onClick={() => this.handleCancel(selectedAttribute)}
            disabled={
              !this.hasAttributeChanged(
                currentAttribute,
                selectedAttribute,
                currentAttributeLines
              )
            }
          />
        </Grid>
      );
    }
    return undefined;
  };

  render() {
    const { classes } = this.props;
    const { currentAttribute } = this.state;
    const hasPermission = Environment.getDmasUserPrivilege() === 'RW';
    if (currentAttribute) {
      return (
        <Panel title="Attribute Information">
          <div className={classes.contentContainer}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {this.renderNameField(hasPermission)}
              </Grid>
              <Grid item xs={12}>
                {this.renderGroupNameField(hasPermission)}
              </Grid>
              <Grid item xs={12}>
                {this.renderDataTypeField(hasPermission)}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  className={classes.optionsContainer}
                >
                  {this.renderOptionLines(hasPermission)}
                  {this.renderAddOptionButton(hasPermission)}
                </Grid>
              </Grid>
              {this.renderFormButtons(hasPermission)}
            </Grid>
          </div>
        </Panel>
      );
    }
    return null;
  }
}

export default withStyles(styles)(AttributeDetailsPanel);
