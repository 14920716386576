import React from 'react';
import LatestReadingsWidgetConfig from 'domain/AppComponents/Dashboard/latest-reading-widget/LatestReadingsWidgetConfig';
import LatestReadingsWidgetDisplay from 'domain/AppComponents/Dashboard/latest-reading-widget/LatestReadingsWidgetDisplay';
import { type Location } from '../DataSourceTypes.types';

type LatestReadingsWidgetProps = {
  onSave: (values: any) => Promise<void>;
  onConfigFinish: () => void;
  showDisplay: boolean;
  sensorIds?: string;
  locations?: Location[];
  dataSourceSelection?: 'sensorId' | 'location';
  showTitle?: boolean;
  autoRefreshEnabled?: boolean;
  title?: string;
  simpleDateEnabled?: boolean;
  dataWarningEnabled?: boolean;
  dataAgeLimit?: number;
};

const LatestReadingsWidget: React.FC<LatestReadingsWidgetProps> = ({
  onSave,
  onConfigFinish,
  showDisplay,
  sensorIds = undefined,
  locations = [],
  dataSourceSelection = 'sensorId',
  showTitle = false,
  autoRefreshEnabled = true,
  title = '',
  simpleDateEnabled = false,
  dataWarningEnabled = false,
  dataAgeLimit = 7,
}) => {
  const handleSave = async (values: any) => {
    await onSave(values);
    onConfigFinish();
  };

  const getLocations = () => {
    if (dataSourceSelection === 'location' && locations.length > 0) {
      return locations.map(
        ({ deviceCategoryCode, stationCode, sensorTypeCode }) => ({
          deviceCategoryCode,
          locationCode: stationCode,
          sensorTypeCode,
        })
      );
    }
    return [];
  };

  return showDisplay ? (
    <LatestReadingsWidgetDisplay
      title={title}
      locations={getLocations()}
      sensorIds={dataSourceSelection === 'sensorId' ? sensorIds : ''}
      autoRefresh={autoRefreshEnabled}
      showTitle={showTitle}
      dataWarningEnabled={dataWarningEnabled}
      dataAgeLimit={dataAgeLimit}
      simpleDateEnabled={simpleDateEnabled}
      dataSourceSelection={dataSourceSelection}
    />
  ) : (
    <LatestReadingsWidgetConfig
      sensorIds={sensorIds}
      dataAgeLimit={dataAgeLimit}
      locations={locations}
      autoRefreshEnabled={autoRefreshEnabled}
      showTitle={showTitle}
      title={title}
      onSave={handleSave}
      onCancel={onConfigFinish}
      simpleDateEnabled={simpleDateEnabled}
      dataWarningEnabled={dataWarningEnabled}
      dataSourceSelection={dataSourceSelection}
    />
  );
};

export default LatestReadingsWidget;
