import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  AbsoluteRightButtonStyle,
  FloatRightButtonLowStyles,
  ContainedButton,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import TaskTableStyledCell from 'domain/AppComponents/batch/task-table/TaskTableStyledCell';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import DataRatingService from 'domain/services/DataRatingService.js';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const styles = (theme) => ({
  dataRatingButton: FloatRightButtonLowStyles,
  sensorDataRatingButton: AbsoluteRightButtonStyle(theme),
});

const headers = [
  { title: 'Data Rating ID', name: 'dataRatingId' },
  { title: 'Date From', name: 'dateFrom' },
  { title: 'Sample Period (sec)', name: 'samplePeriod' },
  { title: 'Sample Size', name: 'sampleSize' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Comment', name: 'comment' },
  { title: 'Data Rating ID For Research', name: 'dataRatingIdForResearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'dataRatingId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'dateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'samplePeriod',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'sampleSize',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'comment',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'dataRatingIdForResearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class DataRatingTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
      dataRatingButton: PropTypes.string,
      sensorDataRatingButton: PropTypes.string,
    }).isRequired,
    deviceId: PropTypes.number,
    sensorId: PropTypes.number,
    noTitle: PropTypes.bool,
  };

  static defaultProps = {
    deviceId: undefined,
    sensorId: undefined,
    noTitle: false,
  };

  compareDate = (a, b) => {
    const priorityA = new Date(a);
    const priorityB = new Date(b);
    if (priorityA.getTime() === priorityB.getTime()) {
      return 0;
    }
    return priorityA < priorityB ? -1 : 1;
  };

  compareRatingId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId, sensorId } = this.props;
    this.setState({ isLoading: true });
    let promise;
    if (sensorId !== undefined) {
      promise = DataRatingService.getManyBySensorId(sensorId)
        .then((result) => {
          this.buildTableRows(result);
        })
        .catch((result) => {
          onError(result);
        });
    } else if (deviceId !== undefined) {
      promise = DataRatingService.getManyByDeviceId(deviceId)
        .then((result) => {
          this.buildTableRows(result);
        })
        .catch((result) => {
          onError(result);
        });
    }
    if (promise) {
      promise.finally(() => {
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  actionContent = () => {
    const { deviceId, permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceActionRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return <DeviceActionRefreshButton />;
    }
    return (
      <>
        <DeviceActionRefreshButton />
        <a href={`${Environment.getDmasUrl()}/DataRating?deviceid=${deviceId}`}>
          <ContainedButton
            translationKey="device.addDataRating"
            startIcon={<Add />}
            className={classes.dataRatingButton}
          />
        </a>
      </>
    );
  };

  deviceActionLink = (actionId) => {
    const { deviceId, sensorId } = this.props;
    if (!sensorId) {
      return (
        <a
          href={`${Environment.getDmasUrl()}/DataRating?dataratingid=${actionId}&deviceid=${deviceId}`}
        >
          {actionId}
        </a>
      );
    }
    return (
      <a
        href={`${Environment.getDmasUrl()}/DataRating?dataratingid=${actionId}&deviceid=${deviceId}&sensorid=${sensorId}`}
      >
        {actionId}
      </a>
    );
  };

  buildTableRows = (ratings) => {
    const { permission } = this.props;
    this.setState({
      tableRows: ratings.map((rating) => {
        const ratingFor = {
          ...rating,
          dataRatingId:
            permission === 'RW'
              ? this.deviceActionLink(rating.dataRatingId)
              : rating.dataRatingId,
          dateFrom: DateFormatUtils.formatDate(rating.dateFrom, 'full'),
          modifyDate: DateFormatUtils.formatDate(rating.modifyDate, 'full'),
          samplePeriod: rating.samplePeriod.toFixed(6),
          modifyBy: rating.modifyByName,
          dataRatingIdForResearch: rating.dataRatingId,
        };
        return ratingFor;
      }),
    });
  };

  renderAddButtonForSensor = (sensorId) => {
    const { permission, deviceId, classes } = this.props;

    if (permission === 'RW') {
      return (
        <div className={classes.button}>
          <a
            href={`${Environment.getDmasUrl()}/DataRating?sensorid=${sensorId}&deviceid=${deviceId}`}
          >
            <ContainedButton
              translationKey="device.addDataRating"
              startIcon={<Add />}
              className={classes.sensorDataRatingButton}
            />
          </a>
        </div>
      );
    }

    return null;
  };

  render() {
    const { permission, noTitle, sensorId } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    if (noTitle) {
      return (
        <>
          {this.renderAddButtonForSensor(sensorId)}
          <SortableTable
            columns={headers}
            columnSizes={tableColumnExtensions}
            columnExtensions={tableColumnExtensions}
            elevation={0}
            pageSize={10}
            rows={tableRows}
            cellComponent={TaskTableStyledCell}
            stripedRows
            sort={[
              {
                columnName: 'dateFrom',
                direction: 'asc',
              },
            ]}
            sortExtensions={[
              { columnName: 'dateFrom', compare: this.compareDate },
              { columnName: 'dataRatingId', compare: this.compareRatingId },
            ]}
            hiddenColumns={['dataRatingIdForResearch']}
            isLoading={isLoading}
          />
        </>
      );
    }

    return (
      <Panel
        title={<Typography variant="h6">Data Rating List</Typography>}
        actionContent={this.actionContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={10}
          rows={tableRows}
          searchable
          searchBarMoveable={permission === 'RW'}
          stripedRows
          sort={[
            {
              columnName: 'dateFrom',
              direction: 'asc',
            },
          ]}
          sortExtensions={[
            { columnName: 'dateFrom', compare: this.compareDate },
            { columnName: 'dataRatingId', compare: this.compareRatingId },
          ]}
          hiddenColumns={['dataRatingIdForResearch']}
          isLoading={isLoading}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(DataRatingTable);
