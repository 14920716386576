import { Control, useForm, useWatch } from 'react-hook-form';
import { Box, Grid, LoadingButton } from 'base-components';
import { type Site } from 'domain/services/SiteService';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import { type SaveType } from './DeviceBulkUpdatePage';

export type SiteDeviceBulkUpdateForm = {
  site: Site;
  comment: string;
  dateFrom: Date;
  offsetLatitude: number;
  offsetLongitude: number;
  offsetDepth: number;
  offsetHeading: number;
  offsetPitch: number;
  offsetRoll: number;
  saveType: SaveType;
};

const CommentField = ({
  control,
}: {
  control: Control<SiteDeviceBulkUpdateForm>;
}) => {
  const comment = useWatch({
    control,
    name: 'comment',
    defaultValue: '',
  });

  return (
    <FormTextField
      name="comment"
      translationKey="common.textfields.commentCharCount"
      translationOptions={{ charCount: comment?.length || 0 }}
      multiline
      fullWidth
      rows={4}
      rules={{
        validate: {
          verifyLength: (input) =>
            !input ||
            input.length <= 255 ||
            'Comment is too long -- must be under 255 characters',
        },
      }}
    />
  );
};

const DeviceBulkUpdateSiteDeviceForm = ({
  sites,
  onSubmit,
  isLoading,
}: {
  sites: Site[];
  onSubmit: ({
    site,
    comment,
    dateFrom,
    offsetLatitude,
    offsetLongitude,
    offsetDepth,
    offsetHeading,
    offsetPitch,
    offsetRoll,
    saveType,
  }: SiteDeviceBulkUpdateForm) => void;
  isLoading: boolean;
}) => {
  const siteDeviceFormMethods = useForm<SiteDeviceBulkUpdateForm>({
    values: {
      site: undefined,
      comment: undefined,
      dateFrom: undefined,
      offsetLatitude: undefined,
      offsetLongitude: undefined,
      offsetDepth: undefined,
      offsetHeading: undefined,
      offsetPitch: undefined,
      offsetRoll: undefined,
      saveType: undefined,
    },
  });
  const {
    formState: { isSubmitting },
  } = siteDeviceFormMethods;

  const saveType = siteDeviceFormMethods.watch('saveType');

  return (
    <Form formMethods={siteDeviceFormMethods} onSubmit={onSubmit}>
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={12}>
          <FormAutocomplete
            name="site"
            translationKey="device.site"
            required={saveType === 'Add'}
            fullWidth
            options={sites}
            getOptionLabel={(option: Site) =>
              `${option.sitename} (${option.siteid})` || ''
            }
          />
          <FormDateTimePicker
            name="dateFrom"
            required={saveType === 'Add'}
            translationKey="common.datepickers.startDate"
            fullWidth
          />
        </Grid>
      </Grid>
      <CommentField control={siteDeviceFormMethods.control} />
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={12}>
          <FormTextField
            name="offsetLatitude"
            translationKey="device.offsetLatitude"
            fullWidth
            type="number"
          />
          <FormTextField
            name="offsetLongitude"
            translationKey="device.offsetLongitude"
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <FormTextField
            name="offsetDepth"
            translationKey="device.offsetDepth"
            fullWidth
            type="number"
          />
          <FormTextField
            name="offsetHeading"
            translationKey="device.offsetHeading"
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <FormTextField
            name="offsetPitch"
            translationKey="device.offsetPitch"
            fullWidth
            type="number"
          />
          <FormTextField
            name="offsetRoll"
            translationKey="device.offsetRoll"
            fullWidth
            type="number"
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginLeft: '80%',
          maxWidth: '20%',
        }}
      >
        <FormDropdown
          name="saveType"
          label="Add/Update"
          fullWidth
          required
          options={[
            { key: 'update', label: 'Update', value: 'Update' },
            { key: 'add', label: 'Add', value: 'Add' },
          ]}
        />
        <LoadingButton
          variant="contained"
          translationKey="common.buttons.save"
          type="submit"
          loading={isSubmitting || isLoading}
          disabled={!saveType}
        />
      </Box>
    </Form>
  );
};

export default DeviceBulkUpdateSiteDeviceForm;
