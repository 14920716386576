import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import { Grid, Typography } from 'base-components';
import VideoWidgetDisplay from 'domain/AppComponents/Dashboard/video-widget/VideoWidgetDisplay';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import DashboardVideoWidgetConfig, {
  VideoWidgetConfigType,
} from './Config/DashboardVideoWidgetConfig';

const useStyles = makeStyles(() =>
  createStyles({
    videoDiv: {
      height: '97%',
      width: '100%',
    },
  })
);

export const defaultVideoConfig: VideoWidgetConfigType = {
  endDate: moment(new Date()),
  startDate: moment(new Date()),
  showTitle: true,
  loopPlayback: false,
  title: 'Sample Video Widget',
  dataSource: 'deviceId',
  timeSource: 'latestClip',
  device: {
    deviceId: 23959,
    name: 'SubC Imaging 1Cam Mk5 SN 15551',
    deviceCode: 'SUBC1CAMMK5_15551',
  },
  liveStreamUrl: '',
  playlistHdr: { key: undefined, label: undefined, value: undefined },
  locations: [],
};

const DashboardVideoWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const classes = useStyles();

  const { id, initialConfig } = props;
  const { config, setConfig } = useWidgetConfig<VideoWidgetConfigType>(id, {
    ...defaultVideoConfig,
    ...initialConfig,
  });

  const {
    loopPlayback,
    dataSource,
    timeSource,
    device,
    startDate,
    endDate,
    liveStreamUrl,
    playlistHdr,
    locations,
  } = config;

  return (
    <Widget
      id={id}
      titleComponents={
        <Typography variant="subtitle1">{config.title}</Typography>
      }
      configTitle="Video Widget"
      ConfigFieldsComponent={DashboardVideoWidgetConfig}
      onChangeConfig={setConfig}
      config={config}
      collapseHeader={config.showTitle}
      closeMenuItems
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        <Grid item aria-label="widgetContent" xs={12} sx={{ height: '99%' }}>
          <VideoWidgetDisplay
            showTitle
            loopPlayback={loopPlayback}
            title=""
            dataSource={dataSource}
            onError={() => {}}
            onInfo={() => {}}
            timeSource={timeSource}
            device={device}
            startDate={startDate}
            endDate={endDate}
            liveStreamUrl={liveStreamUrl}
            widgetId={id}
            playlistHdrId={playlistHdr?.label || ''}
            locations={locations}
            classes={classes}
          />
        </Grid>
      </Grid>
    </Widget>
  );
};

DashboardVideoWidget.widgetKey = 'video-widget';
DashboardVideoWidget.widgetTitle = '';
DashboardVideoWidget.defaultDataGrid = {
  x: 0,
  y: 0,
  w: 4,
  h: 15,
};

export default DashboardVideoWidget;
