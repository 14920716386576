import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  CreateButton,
  SaveButton,
  CancelButton,
} from '@onc/composite-components';
import { Typography } from 'base-components';
import { INSTRUMENT } from 'domain/services/QaqcAutotestsConstants';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import QaqcAttributesTable from './QaqcAttributesTable';
import QaqcDetailsAttributes from './QaqcDetailsAttributes';
import QaqcDetailsData from './QaqcDetailsData';
import QaqcDetailsDropdowns from './QaqcDetailsDropdowns';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  buttonDiv: {
    marginLeft: theme.spacing(2),
  },
});

const formulaDescript = new Map([
  [8, 'Range Test'],
  [9, 'Range Test'],
  [10, 'Range Test'],
  [11, 'TC Curve Test'],
  [14, 'Spike Test'],
  [15, 'Gradient Test'],
  [17, 'Range Test'],
  [18, 'TC Curve Test'],
  [19, 'Range Test'],
  [20, 'Hampel Median Filter'],
  [21, 'Impossible Speed Test'],
  [22, 'Ferry Speed over Ground Test'],
  [23, 'Community Fishers EDS Test'],
  [24, 'Pump and Valve Control System Test'],
  [25, 'Geofencing QAQC Test'],
]);

const defaultState = {
  attributeValues: {},
  dataValues: {
    description: '',
    effectiveDateFrom: null,
    effectiveDateTo: null,
    modifyBy: '',
    modifyDate: '',
  },
  dropdownIdValues: {
    deviceCategoryId: 0,
    deviceAutocomplete: null,
    deviceId: 0,
    deviceCode: '',
    formulaId: 0,
    qaqcTestGroupId: 0,
    searchTreeNodeId: 0,
    sensorName: '',
    sensorId: 0,
    sensorTypeId: 0,
    studyAreaId: 0,
  },
  formulaAttributes: [],
  sensors: [],
  testLevel: '',
  testLevelValue: 0,
  // acts as a lock to prevent overwriting values during get
  valueMutex: false,
};

class QaqcDetailsPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    qaqcId: PropTypes.number,
    sensorid: PropTypes.number,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    qaqcId: 0,
    sensorid: 0,
  };

  redirectToFinder = () => {
    const { sensorid } = this.props;
    const { dropdownIdValues } = this.state;
    if (!sensorid) {
      window.open(`${Environment.getDmasUrl()}/QaqcAutotestsFinder`, '_self');
    } else {
      window.open(
        `${Environment.getDmasUrl()}/SensorListing?DeviceId=${
          dropdownIdValues.deviceId
        }&SensorId=${sensorid}#calibration_tab`,
        '_self'
      );
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidMount() {
    const { qaqcId, sensorid } = this.props;

    this.setState({ permission: Environment.getDmasUserPrivilege() });

    if (qaqcId) {
      QaqcTestDetailsService.getQAQCTD(qaqcId)
        .then((results) => {
          const { device, modifyBy, modifyDate, qaqc, testAttributes } =
            results;
          const {
            description,
            effectiveDateFrom,
            effectiveDateTo,
            formulaId,
            qaqcTestGroupId,
            searchTreeNodeId,
            sensorId,
            sensorTypeId,
            studyAreaId,
            testLevel,
          } = qaqc;

          this.setState(
            {
              valueMutex: true,
              attributeValues: testAttributes
                ? testAttributes.reduce((oldAttributeValues, attribute) => {
                    const newAttributeValues = oldAttributeValues;
                    newAttributeValues[attribute.attributeId] =
                      attribute.attributeValue;
                    return newAttributeValues;
                  }, {})
                : {},
              dataValues: {
                description: description || '',
                effectiveDateFrom: effectiveDateFrom
                  ? moment.utc(effectiveDateFrom)
                  : null,
                effectiveDateTo: effectiveDateTo
                  ? moment.utc(effectiveDateTo)
                  : null,
                modifyBy: modifyBy || '',
                modifyDate: modifyDate
                  ? DateFormatUtils.formatDate(modifyDate, 'full')
                  : '',
              },
              dropdownIdValues: {
                deviceCategoryId:
                  device &&
                  device.deviceCategory &&
                  device.deviceCategory.deviceCategoryId
                    ? device.deviceCategory.deviceCategoryId
                    : 0,
                deviceId: device && device.deviceId ? device.deviceId : 0,
                formulaId: formulaId || 0,
                qaqcTestGroupId: qaqcTestGroupId || 0,
                searchTreeNodeId: searchTreeNodeId || 0,
                sensorId: sensorId || 0,
                sensorTypeId: sensorTypeId || 0,
                studyAreaId: studyAreaId || 0,
              },
              testLevel,
            },
            () => {
              this.setState({ valueMutex: false });
            }
          );
        })
        .catch((response) => {
          const { onError } = this.props;
          onError(response.message);
        });
    }

    if (sensorid) {
      QaqcTestDetailsService.getQAQCDeviceSensor(sensorid)
        .then((results) => {
          const { deviceId, deviceCategoryId, deviceCode } = results;
          this.setState(
            {
              valueMutex: true,
              dropdownIdValues: {
                deviceCategoryId: deviceCategoryId || 0,
                deviceId: deviceId || 0,
                deviceCode,
                sensorId: sensorid,
              },
              testLevel: INSTRUMENT,
              testLevelValue: 1,
            },
            () => {
              this.setState({ valueMutex: false });
            }
          );
        })
        .catch((response) => {
          const { onError } = this.props;
          onError(response.message);
        });
    }
  }

  handleAttributeValueChange = (event) => {
    const { attributeValues } = this.state;
    const attributeKeys = Object.keys(attributeValues);
    const matchingKey = attributeKeys.find(
      (key) => key.match(/\d+/g)[0] === event.target.name.match(/\d+/g)[0]
    );
    if (matchingKey) {
      this.setState({
        attributeValues: {
          ...attributeValues,
          [matchingKey]: event.target.value,
        },
      });
    } else {
      this.setState({
        attributeValues: {
          ...attributeValues,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  handleChangeFormula = (event) => {
    const {
      dropdownIdValues,
      dataValues,
      formulaAttributes,
      sensors,
      testLevel,
    } = this.state;
    const formulaId = event.target.value;
    let attributeValues = {};
    let description = '';

    if (formulaAttributes[formulaId]) {
      formulaAttributes[formulaId].forEach((attribute) => {
        attributeValues = { ...attributeValues, [attribute]: '' };
      });
    }

    const tempSensor = sensors.find(
      (sensor) => sensor.value === dropdownIdValues.sensorId
    );
    const formulaDescription = formulaDescript.get(formulaId);

    if (
      dropdownIdValues.deviceAutocomplete !== null &&
      dropdownIdValues.deviceAutocomplete !== undefined
    ) {
      description = `${dropdownIdValues.deviceAutocomplete.deviceCode} ${testLevel} ${tempSensor.label} ${formulaDescription}`;
    } else if (dropdownIdValues.deviceCode !== '') {
      description = `${dropdownIdValues.deviceCode} ${testLevel} ${tempSensor.label} ${formulaDescription}`;
    } else {
      description = `${testLevel} ${formulaDescription}`;
    }

    this.setState({
      attributeValues,
      dropdownIdValues: {
        ...dropdownIdValues,
        formulaId,
      },
      dataValues: {
        ...dataValues,
        description,
      },
    });
  };

  handleCreate = async () => {
    const { onInfo, onError } = this.props;

    await QaqcTestDetailsService.createQAQC(this.parametersForPostReq())
      .then((response) => {
        if (response.data.statusCode === 0) {
          onInfo('Saved Successfully');
          window.open(
            `${Environment.getDmasUrl()}${`/QaqcAutotestDetails?qaqcId=${response.data.payload.qaqc.qaqcId}`}`,
            '_self'
          );
        } else {
          onError(response.data.message);
        }
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  handleDateChange = (fieldName) => (date) => {
    const { dataValues } = this.state;

    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    date.utc(true);

    this.setState({
      dataValues: {
        ...dataValues,
        [fieldName]: date,
      },
    });
  };

  handleDescriptionChange = (event) => {
    const { dataValues } = this.state;
    this.setState({
      dataValues: {
        ...dataValues,
        description: event.target.value,
      },
    });
  };

  handleGetFormulas = (formulaAttributes) => {
    this.setState({ formulaAttributes });
  };

  handleGetSensors = (sensors) => {
    this.setState({ sensors });
  };

  handleIdValuesChange = (event) => {
    const { dropdownIdValues, dataValues, valueMutex } = this.state;
    let newDropdownIdValues = { [event.target.name]: event.target.value };
    if (event.target.name === 'deviceCategoryId' && !valueMutex) {
      newDropdownIdValues = {
        ...newDropdownIdValues,
        deviceId: 0,
        sensorId: 0,
      };
    } else if (event.target.name === 'deviceId' && !valueMutex) {
      newDropdownIdValues = { ...newDropdownIdValues, sensorId: 0 };
    } else if (event.target.name === 'deviceAutocomplete' && !valueMutex) {
      newDropdownIdValues = {
        ...newDropdownIdValues,
        deviceId: newDropdownIdValues.deviceAutocomplete.deviceId,
        sensorId: 0,
      };
    } else if (event.target.name === 'sensorId' && !valueMutex) {
      newDropdownIdValues = {
        ...newDropdownIdValues,
        sensorId: newDropdownIdValues.sensorId,
        sensorName: newDropdownIdValues.sensorName,
      };
    } else if (
      (event.target.name === 'qaqcTestGroupId' ||
        event.target.name === 'searchTreeNodeId' ||
        event.target.name === 'studyAreaId') &&
      !valueMutex
    ) {
      newDropdownIdValues = { ...newDropdownIdValues, sensorTypeId: 0 };
    }

    this.setState({
      attributeValues: defaultState.attributeValues,
      dropdownIdValues: {
        ...dropdownIdValues,
        ...newDropdownIdValues,
        formulaId: 0,
      },
      dataValues: {
        ...dataValues,
        description: '',
      },
    });
  };

  handleTestLevelValueChange = (value, label) => {
    const { valueMutex } = this.state;

    this.setState({
      testLevel: label,
      testLevelValue: value,
    });

    if (!valueMutex) {
      this.setState({
        attributeValues: defaultState.attributeValues,
        dropdownIdValues: defaultState.dropdownIdValues,
      });
    }
  };

  handleUpdate = async () => {
    const { onInfo, onError } = this.props;
    const modifiedPayload = this.parametersForPostReq();
    if (modifiedPayload.testAttributes) {
      delete modifiedPayload.testAttributes;
    }
    await QaqcTestDetailsService.updateQAQC(modifiedPayload)
      .then((response) => {
        if (response.data.statusCode === 0) {
          onInfo('Saved Successfully');
        } else {
          onError(response.data.message);
        }
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  parametersForPostReq = () => {
    const { qaqcId } = this.props;
    const {
      attributeValues,
      dropdownIdValues: {
        deviceCategoryId,
        deviceId,
        formulaId,
        qaqcTestGroupId,
        searchTreeNodeId,
        sensorId,
        sensorTypeId,
        studyAreaId,
      },
      dataValues: { description, effectiveDateFrom, effectiveDateTo },
      testLevel,
    } = this.state;

    let qaqc = {
      description,
      formulaId,
      qaqcTestGroupId,
      reprocess: false,
      searchTreeNodeId,
      sensorId,
      sensorTypeId,
      studyAreaId,
      testLevel,
    };

    if (effectiveDateFrom) {
      qaqc = { ...qaqc, effectiveDateFrom: effectiveDateFrom.toISOString() };
    }

    if (effectiveDateTo) {
      qaqc = { ...qaqc, effectiveDateTo: effectiveDateTo.toISOString() };
    }

    if (qaqcId) qaqc = { ...qaqc, qaqcId };

    const testAttributes = Object.keys(attributeValues).map((attributeKey) => ({
      attributeId: attributeKey.match(/\d+/g)[0],
      attributeValue: Number(attributeValues[attributeKey]),
    }));

    if (testLevel === INSTRUMENT) {
      const device = { deviceId, deviceCategory: { deviceCategoryId } };
      return {
        device,
        qaqc,
        testAttributes,
      };
    }

    return { qaqc, testAttributes };
  };

  render() {
    const { classes, qaqcId, sensorid, onInfo, onError } = this.props;
    const {
      attributeValues,
      dataValues,
      dropdownIdValues,
      formulaAttributes,
      permission,
      testLevel,
      testLevelValue,
    } = this.state;
    const { formulaId } = dropdownIdValues;

    if (permission !== 'RW' && !qaqcId)
      return (
        <Panel
          title={
            <Typography classes={{ root: classes.root }} variant="h6">
              No qaqcId parameter provided. Login to create a new Qaqc Autotest.
            </Typography>
          }
        />
      );
    return (
      <div>
        <Panel classes={{ root: classes.root }}>
          <QaqcDetailsDropdowns
            idValues={dropdownIdValues}
            qaqcExists={!!qaqcId}
            sensorExists={!!sensorid}
            permission={permission}
            testLevel={testLevel}
            testLevelValue={testLevelValue}
            onChangeFormula={this.handleChangeFormula}
            onGetSensors={this.handleGetSensors}
            onGetFormulas={this.handleGetFormulas}
            onIdValuesChange={this.handleIdValuesChange}
            onTestLevelValueChange={this.handleTestLevelValueChange}
            onInfo={onInfo}
            onError={onError}
          />
        </Panel>
        <Panel classes={{ root: classes.root }}>
          {qaqcId ? (
            <QaqcAttributesTable
              permission={Environment.getDmasUserPrivilege()}
              className={classes.root}
              formulaAttributes={formulaAttributes[formulaId]}
              stripedRows
              qaqcId={qaqcId}
              onInfo={onInfo}
              onError={onError}
            />
          ) : (
            <QaqcDetailsAttributes
              attributeValues={attributeValues}
              formulaAttributes={formulaAttributes[formulaId]}
              permission={permission}
              onChange={this.handleAttributeValueChange}
              onInfo={onInfo}
              onError={onError}
            />
          )}
        </Panel>
        <Panel classes={{ root: classes.root }}>
          <QaqcDetailsData
            dataValues={dataValues}
            permission={permission}
            onDateChange={this.handleDateChange}
            onDescriptionChange={this.handleDescriptionChange}
          />
        </Panel>
        {permission === 'RW' ? (
          <div className={classes.buttonDiv}>
            {qaqcId ? (
              <SaveButton onClick={this.handleUpdate} />
            ) : (
              <CreateButton onClick={this.handleCreate} />
            )}
            <CancelButton onClick={this.redirectToFinder} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(QaqcDetailsPage));
