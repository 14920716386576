import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Paper } from 'base-components';
import OrganizationService from 'domain/services/OrganizationService';
import SearchableList from 'library/CompositeComponents/list-container/SearchableList';
import { useSnackbars } from 'util/hooks/useSnackbars';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  listContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    backgroundColor: '#f4f4f4',
    borderRight: '1px solid #ddd',
    minWidth: '200px',
    maxWidth: '300px',
    position: 'fixed',
    left: 0,
    top: '64px',
    height: 'calc(100% - 95px)',
  },
}));

export interface OrganizationTreeNode {
  organizationId: number;
  organizationName: string;
}

type OrganizationListProps = {
  onOrganizationClick: (orgId: number, orgName: string) => void;
};

const UserOrganizationList: React.FC<OrganizationListProps> = ({
  onOrganizationClick,
}) => {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState<OrganizationTreeNode[]>(
    []
  );
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const { onError } = useSnackbars();

  useEffect(() => {
    OrganizationService.getOrganizations()
      .then((orgs) => {
        const sortedOrgs = orgs.sort((a, b) =>
          a.organizationName.localeCompare(b.organizationName)
        );
        setOrganizations(sortedOrgs);
      })
      .catch(() => onError('Failed to load organizations'));
  }, [onError]);

  const handleItemClick = (orgId: number, orgName: string) => {
    setSelectedOrgId(orgId);
    onOrganizationClick(orgId, orgName);
  };

  return (
    <Grid item xs={12} sm={4} md={3} className={classes.root}>
      <Paper className={classes.listContainer}>
        <SearchableList
          searchText={searchInput}
          handleSearchInput={(e) => setSearchInput(e.target.value)}
          listItems={organizations.map((org) => ({
            key: Number(org.organizationId),
            name: org.organizationName,
          }))}
          selectedItem={selectedOrgId}
          handleClickItem={(item) =>
            handleItemClick(Number(item.key), item.name)
          }
        />
      </Paper>
    </Grid>
  );
};

export default UserOrganizationList;
