import * as React from 'react';
import { Switch, Route, type RouteComponentProps } from 'react-router-dom';
import DashboardTable from 'domain/AppComponents/Dashboard/dashboard-table/DashboardTable';
import DashboardEdit from 'domain/AppComponents/Dashboard/DashboardEdit';
import { useSnackbars } from 'util/hooks/useSnackbars';

interface DashboardsParams {
  id?: string;
}

type DashboardsProps = RouteComponentProps<DashboardsParams>;

const DashboardsPage: React.VFC<DashboardsProps> = ({
  match,
}: DashboardsProps) => {
  const { onError, onInfo } = useSnackbars();

  const renderTable = (routerProps: DashboardsProps) => (
    <DashboardTable {...routerProps} />
  );

  const renderEdit = (routerProps: DashboardsProps) => (
    <DashboardEdit
      onInfo={onInfo}
      onError={onError}
      dashboardId={Number(routerProps.match.params.id)}
      showTitleBar={!routerProps.match.url.includes('/embed/')}
    />
  );

  return (
    <Switch>
      <Route exact path={match.path} render={renderTable} />
      <Route path={`${match.path}/id/:id`} render={renderEdit} />
    </Switch>
  );
};

export default DashboardsPage;
