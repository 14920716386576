import { useContext } from 'react';
import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import useBroadcast from 'util/hooks/useBroadcast';
import EarthquakeContext from './EarthquakeContext';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../library/CompositeComponents/dashboard/DashboardTypes';

const Plot = createPlotlyComponent(Plotly);

const EarthquakeSummaryWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { earthquakes, dashboardId } = useContext(EarthquakeContext);

  const [earthquakeList] = useBroadcast<any>(
    dashboardId,
    BroadcastChannel.EarthquakeList,
    earthquakes,
    id
  );

  const categorizeEarthquakes = () => {
    const categories = {
      'NRCan only': 0,
      'USGS only': 0,
      'ONC only': 0,
      'NRCan and USGS': 0,
      'NRCan and ONC': 0,
      'USGS and ONC': 0,
      'NRCan, USGS and ONC': 0,
    };

    earthquakeList.forEach((eq) => {
      const hasNrcan = !!eq.nrcan;
      const hasUsgs = !!eq.usgs;
      const hasOnc = !!eq.onc;

      if (hasNrcan && hasUsgs && hasOnc) {
        categories['NRCan, USGS and ONC'] += 1;
      } else if (hasNrcan && hasUsgs) {
        categories['NRCan and USGS'] += 1;
      } else if (hasNrcan && hasOnc) {
        categories['NRCan and ONC'] += 1;
      } else if (hasUsgs && hasOnc) {
        categories['USGS and ONC'] += 1;
      } else if (hasNrcan) {
        categories['NRCan only'] += 1;
      } else if (hasUsgs) {
        categories['USGS only'] += 1;
      } else if (hasOnc) {
        categories['ONC only'] += 1;
      }
    });

    return categories;
  };

  const categoryCounts = categorizeEarthquakes();
  const oncDetected =
    categoryCounts['ONC only'] +
    categoryCounts['USGS and ONC'] +
    categoryCounts['NRCan and ONC'] +
    categoryCounts['NRCan, USGS and ONC'];
  const oncAndUsgsDetected =
    categoryCounts['USGS and ONC'] + categoryCounts['NRCan, USGS and ONC'];
  const usgsDetectedOncMissed =
    categoryCounts['USGS only'] + categoryCounts['NRCan and USGS'];
  const detectionPercentage =
    oncAndUsgsDetected + usgsDetectedOncMissed > 0
      ? (100 * usgsDetectedOncMissed) /
        (oncAndUsgsDetected + usgsDetectedOncMissed)
      : 0;

  return (
    <Widget key={id} title="Earthquake Summary" {...props}>
      <div
        style={{
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          marginBottom: '10px',
        }}
      >
        <div>Total number: {earthquakeList.length}</div>
        <div>Total ONC detections: {oncDetected}</div>
        <div>Total ONC and USGS detected: {oncAndUsgsDetected}</div>
        <div>Total USGS detected and ONC missed: {usgsDetectedOncMissed}</div>
        <div>
          Detection percentage compared to USGS:{' '}
          {detectionPercentage.toFixed(2)}%
        </div>
      </div>
      <Plot
        data={[
          {
            type: 'pie',
            values: Object.values(categoryCounts),
            labels: Object.keys(categoryCounts),
            marker: {
              colors: [
                '#d62728', // nrcan only (red)
                '#1f77b4', // onc only (blue)
                '#2ca02c', // usgs only (green)
                '#ffeb3b', // nrcan and usgs (yellow)
                '#ff69b4', // nrcan and onc (magenta)
                '#20c997', // usgs and onc (teal)
                '#8c564b', // nrcan, usgs, and onc (brown)
              ],
            },
            textinfo: 'label+percent', // Hides percentage and label on the chart
            hole: 0, // For a full pie, set to 0. Use 0.4 or so for a donut.
          },
        ]}
        layout={{}}
      />
    </Widget>
  );
};

EarthquakeSummaryWidget.widgetKey = 'earthquake-summary';
EarthquakeSummaryWidget.widgetTitle = 'Earthquake Summary';
EarthquakeSummaryWidget.defaultDataGrid = {
  i: 'earthquake-summary',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EarthquakeSummaryWidget;
