import { useState } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ContainedButton, CancelButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import ResultTextArea from 'domain/AppComponents/Form/Fields/ResultTextArea';
import TaxonService from 'domain/services/TaxonService';

type Props = {
  onClose: () => void;
};

const TaxonFileUploadConfig = ({ onClose }: Props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      margin: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)}px)`,
      minWidth: theme.spacing(50),
    },
    dropdown: {
      margin: theme.spacing(1),
      minWidth: theme.spacing(50),
      maxWidth: theme.spacing(50),
    },
    fileUpload: {
      display: 'none',
    },
    fileName: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    uploadButton: {
      display: 'flex',
    },
    cancelButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }));

  const classes = useStyles();

  const [fileName, setFileName] = useState<string>('');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [result, setResult] = useState<string>('');
  const [isResultError, setIsResultError] = useState<boolean>(false);

  const handleFileChange = (event) => {
    setFileName(event.target.files[0].name);
    setFileToUpload(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    const uploadResult: string[] = await TaxonService.put(fileToUpload)
      .then(({ data }) => {
        setIsResultError(false);
        return data;
      })
      .catch(({ response: { data } }) => {
        setIsResultError(true);
        return data;
      });
    setResult(uploadResult.join('\n'));
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Taxon File Upload</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <ContainedButton
            translationKey="common.buttons.chooseFile"
            component="label"
          >
            <input
              type="file"
              onChange={handleFileChange}
              className={classes.fileUpload}
            />
          </ContainedButton>
          <ContainedButton
            translationKey="common.buttons.upload"
            className={classes.uploadButton}
            disabled={fileToUpload === null}
            onClick={handleFileUpload}
          />
        </Grid>
        <Grid container spacing={1}>
          <Typography className={classes.fileName}>{fileName}</Typography>
          <ResultTextArea value={result} error={isResultError} />
        </Grid>
        <Grid item className={classes.cancelButton}>
          <CancelButton onClick={onClose} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TaxonFileUploadConfig;
