import { useState, useEffect, useCallback } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  getEmptyFilter,
  SortingProps,
  StatelessTable,
  TableColumn,
  TableFilter,
  TableFilterLogic,
  useSnackbars,
} from 'base-components';
import { ResizingProps } from 'base-components/src/table/plugins/ColumnResizing';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';
import DeviceInfo from 'domain/Apps/device-listing/DeviceInfo';
import DeviceService from 'domain/services/DeviceService';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import { useLocalStorage } from 'util/hooks/useStorage';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      'overflow-x': 'hidden',
      margin: theme.spacing(1),
      minWidth: theme.spacing(75),
    },
  })
);

const columns: TableColumn[] = [
  {
    name: 'deviceIdForSearch',
    title: 'Device ID (USE FOR FILTERING)',
    dataType: 'Number',
  },
  { name: 'deviceId', title: 'Device ID', dataType: 'Other' },
  { name: 'deviceName', title: 'Device Name', dataType: 'String' },
  { name: 'securityTier', title: 'Security Tier', dataType: 'String' },
  { name: 'deviceTypeId', title: 'Device Type ID', dataType: 'Number' },
  { name: 'deviceTypeName', title: 'Device Type Name', dataType: 'String' },
  { name: 'deviceCategoryId', title: 'Device Category ID', dataType: 'Number' },
  {
    name: 'deviceCategoryName',
    title: 'Device Category Name',
    dataType: 'String',
  },
  { name: 'siteName', title: 'Site Name', dataType: 'String' },
  { name: 'serialnr2', title: 'Serial #2', dataType: 'String' },
  { name: 'owner', title: 'Owner', dataType: 'String' },
];

const columnWidths: ResizingProps['columnWidths'] = [
  { columnName: 'deviceId', width: ColumnInfo.small },
  { columnName: 'deviceName', width: ColumnInfo.large },
  { columnName: 'securityTier', width: ColumnInfo.small },
  { columnName: 'deviceIdForSearch', width: ColumnInfo.mini },
  { columnName: 'deviceTypeId', width: ColumnInfo.small },
  { columnName: 'deviceTypeName', width: ColumnInfo.large },
  { columnName: 'deviceCategoryId', width: ColumnInfo.small },
  { columnName: 'deviceCategoryName', width: ColumnInfo.medium },
  { columnName: 'siteName', width: ColumnInfo.medium },
  { columnName: 'serialnr2', width: ColumnInfo.medium },
  { columnName: 'owner', width: ColumnInfo.medium },
];

type Props = {
  permission: string;
  deviceCategoryId: number;
  deviceTypeId: number;
};

interface DeviceTableRowInfo extends DeviceInfo {
  deviceId: any;
  deviceIdForSearch: number;
  securityTier?: any;
}

const DeviceListingTable = ({
  permission,
  deviceCategoryId,
  deviceTypeId,
}: Props) => {
  const classes = useStyles();
  const { onError } = useSnackbars();
  const storageKey = 'device-listing-table';
  const defaultConfig = {
    sorting: [{ columnName: 'deviceName', direction: 'asc' }],
  };
  const [tableRows, setTableRows] = useState<Array<DeviceTableRowInfo>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const AllDropDownValue = -1;

  // Column Sorting
  const [config, setConfig] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState<SortingProps['sorting']>(
    config.sorting
  );

  // Filtering
  const initialFilter = config.filter;
  const [filter, setFilter] = useState<TableFilter>(
    initialFilter
      ? structuredClone(config.filter)
      : structuredClone(getEmptyFilter())
  );

  // Column Choosing
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    config.hiddenColumns ||
      (permission !== 'RW'
        ? ['securityTier', 'deviceIdForSearch']
        : ['deviceIdForSearch'])
  );

  // Paging
  const [currentPage, setCurrentPage] = useState(0);

  const updateConfig = (
    key: string,
    value: SortingProps['sorting'] | TableFilter | string[]
  ) => {
    const updatedConfig = structuredClone(config);
    updatedConfig[key] = value;
    setConfig(updatedConfig);
  };

  const handleSortingChange = (value: SortingProps['sorting']) => {
    setSorting(value);
    updateConfig('sorting', value);
  };

  const handleFilterChange = (value: TableFilter) => {
    setFilter(value);
    updateConfig('filter', value);
  };

  const handleHiddenColumnsChange = (value: string[]) => {
    setHiddenColumns(value);
    updateConfig('hiddenColumns', value);
  };

  const buildTableRows = (deviceInfos: DeviceInfo[]) => {
    if (!deviceInfos) {
      return [];
    }
    const newTableRows = deviceInfos.map((device) => {
      const returnValue: DeviceTableRowInfo = {
        deviceIdForSearch: device.deviceId,
        deviceName: device.deviceName,
        deviceId: <DeviceDetailsLink deviceId={device.deviceId} />,
        deviceTypeId: device.deviceTypeId,
        deviceTypeName: device.deviceTypeName,
        deviceCategoryName: device.deviceCategoryName,
        deviceCategoryId: device.deviceCategoryId,
        siteName: device.siteName,
        serialnr2: device.serialnr2,
        owner: device.owner,
      };
      if (device.security === 3) {
        returnValue.securityTier = 'Low';
      } else if (device.security === 2) {
        returnValue.securityTier = 'Medium';
      } else {
        returnValue.securityTier = 'High';
      }
      return returnValue;
    });
    return newTableRows;
  };

  const refreshTableData = useCallback(async () => {
    try {
      let payload;
      setIsLoading(true);
      if (
        deviceCategoryId !== AllDropDownValue &&
        deviceTypeId === AllDropDownValue
      ) {
        payload =
          await DeviceService.getDevicesForDeviceCategoryId(deviceCategoryId);
      } else if (
        deviceTypeId !== AllDropDownValue &&
        deviceCategoryId === AllDropDownValue
      ) {
        payload = await DeviceService.getDevicesForDeviceTypeId(deviceTypeId);
      } else if (
        deviceCategoryId !== AllDropDownValue &&
        deviceTypeId !== AllDropDownValue
      ) {
        payload = await DeviceService.getDevicesForDeviceTypeAndCategoryId(
          deviceTypeId,
          deviceCategoryId
        );
      } else {
        payload = await DeviceService.getAllDevices();
      }
      setTableRows(buildTableRows(payload.devices));
    } catch (e) {
      onError(e.toString());
      setTableRows([]);
    } finally {
      setIsLoading(false);
    }
  }, [deviceCategoryId, AllDropDownValue, deviceTypeId, onError]);

  useEffect(() => {
    refreshTableData();
  }, [refreshTableData]);

  const compareDeviceId = (a, b) => {
    const idA = a.props.deviceId;
    const idB = b.props.deviceId;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  if (!tableRows) return undefined;

  return (
    <StatelessTable
      className={classes.table}
      rows={tableRows || []}
      columns={columns}
      getRowId={(row) => row.deviceIdForSearch}
      searchable
      visible={{
        hiddenColumnNames: hiddenColumns,
        handleChangeVisibility: handleHiddenColumnsChange,
      }}
      sort={{
        sorting,
        customSorting: [
          {
            columnName: 'deviceId',
            compare: compareDeviceId,
          },
        ],
        handleSortingChange,
      }}
      filter={{
        filterValue: filter,
        onChange: handleFilterChange,
        filterFn: TableFilterLogic.defaultFilter,
        hidden: false,
      }}
      paging={{
        currentPage,
        onCurrentPageChange: (page) => setCurrentPage(page),
        pageSize: 15,
        pageSizes: [15, 30, 60, 300],
      }}
      resize={{ columnWidths }}
      loading={isLoading}
    />
  );
};

export default DeviceListingTable;
