import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ContainedButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import TaskTableStyledCell from 'domain/AppComponents/batch/task-table/TaskTableStyledCell';
import QaqcAppliedTestsService from 'domain/services/QaqcAppliedTestsService';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const headers = [
  { title: 'QAQC ID', name: 'qaqcId' },
  { title: 'Source ID', name: 'sourceId' },
  { title: 'Effective Date From', name: 'effectiveDateFrom' },
  { title: 'Effective Date To', name: 'effectiveDateTo' },
  { title: 'Description', name: 'description' },
  { title: 'Test Level', name: 'testLevel' },
  { title: 'Station/Region', name: 'abcd' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
];

const tableColumnExtensions = [
  {
    columnName: 'qaqcId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'sourceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'effectiveDateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'effectiveDateTo',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'testLevel',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'abcd',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class AppliedQaqcTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    sensorId: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
      addQaqcAutoTestButton: PropTypes.string,
    }).isRequired,
  };

  descriptionLink = (description, id) => (
    <a href={`${Environment.getDmasUrl()}/QaqcAutotestDetails?qaqcId=${id}`}>
      {description}
    </a>
  );

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData = () => {
    const { onError, sensorId } = this.props;
    this.setState({ isLoading: true });
    QaqcAppliedTestsService.get(sensorId)
      .then((result) => {
        this.setState({ isLoading: false });
        this.buildTableRows(result);
      })
      .catch((result) => {
        this.setState({ isLoading: false });
        onError(result);
      });
  };

  buildTableRows = (tests) => {
    this.setState({
      tableRows: tests.map((test) => {
        const testFor = {
          qaqcId: test.cacheData.testId,
          sourceId: test.cacheData.sourceId,
          effectiveDateFrom: DateFormatUtils.formatDate(
            test.cacheData.effectiveDateFrom,
            'full'
          ),
          effectiveDateTo: test.cacheData.effectiveDateTo
            ? DateFormatUtils.formatDate(test.cacheData.effectiveDateTo, 'full')
            : '',
          description: this.descriptionLink(
            test.cacheData.description,
            test.cacheData.testId
          ),
          testLevel: test.cacheData.testLevel,
          groupName: test.cacheData.groupName,
          modifyBy: test.modifyByName,
          modifyDate: DateFormatUtils.formatDate(
            test.cacheData.modifyDate,
            'full'
          ),
        };
        return testFor;
      }),
    });
  };

  AddQaqcButton = (sensorId) => {
    const { permission, classes } = this.props;
    if (permission === 'RW') {
      return (
        <div className={classes.button}>
          <a
            href={`${Environment.getDmasUrl()}/QaqcAutotestDetails?sensorId=${sensorId}`}
          >
            <ContainedButton
              translationKey="device.addQaQcAutoTest"
              startIcon={<Add />}
              className={classes.addQaqcAutoTestButton}
            />
          </a>
        </div>
      );
    }

    return null;
  };

  render() {
    const { sensorId } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <>
        {this.AddQaqcButton(sensorId)}
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={10}
          cellComponent={TaskTableStyledCell}
          rows={tableRows}
          stripedRows
          isLoading={isLoading}
        />
      </>
    );
  }
}

export default AppliedQaqcTable;
