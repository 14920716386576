import { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import _ from 'lodash';
import {
  StatelessTable,
  getEmptyFilter,
  TableColumn,
  TableFilterLogic,
  TableFilter,
} from 'base-components';
import { CompromisedAnnotationRow } from 'domain/Apps/compromised-instruments/CompromisedInstrumentsPage';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import { useLocalStorage } from 'util/hooks/useStorage';

type Props = {
  rows: CompromisedAnnotationRow[];
};

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      height: '850px',
    },
  })
);

const columns: TableColumn[] = [
  {
    name: 'annotationHdrId',
    title: 'Annotation Id',
    dataType: 'String',
  },
  {
    name: 'resourceType',
    title: 'Resource Type',
    dataType: 'String',
  },
  {
    name: 'deviceId',
    title: 'Device Id',
    dataType: 'String',
  },
  {
    name: 'deviceName',
    title: 'Device Name',
    dataType: 'String',
  },
  {
    name: 'comment',
    title: 'Comment',
    dataType: 'String',
  },
  {
    name: 'taxon',
    title: 'Taxon',
    dataType: 'String',
  },
  {
    name: 'startDate',
    title: 'Start Date',
    dataType: 'String',
  },
  {
    name: 'endDate',
    title: 'End Date',
    dataType: 'String',
  },
  {
    name: 'jiraLink',
    title: 'Jira Id',
    dataType: 'Other',
  },
  {
    name: 'actions',
    title: ' ',
    dataType: 'Other',
  },
];

const columnExtensions = [
  { columnName: 'annotationHdrId', width: ColumnInfo.small + 20 },
  { columnName: 'resourceType', width: ColumnInfo.small + 20 },
  { columnName: 'deviceId', width: ColumnInfo.small },
  { columnName: 'deviceName', width: ColumnInfo.medium },
  { columnName: 'comment', width: ColumnInfo.xLarge, wordWrapEnabled: true },
  { columnName: 'taxon', width: ColumnInfo.medium },
  { columnName: 'startDate', width: ColumnInfo.medium },
  { columnName: 'endDate', width: ColumnInfo.medium },
  { columnName: 'jiraLink', width: ColumnInfo.small + 20 },
  { columnName: 'actions', width: ColumnInfo.mini },
];

const CompromisedInstrumentsTable = ({ rows }: Props) => {
  const classes = useStyles();
  const storageKey = 'compromised-instrument-annotations-table';
  const defaultConfig = {
    sorting: [{ columnName: 'startDate', direction: 'desc' }],
  };

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  // Filtering
  const initialFilter = config.filter;
  const [filter, setFilter] = useState<TableFilter>(
    initialFilter ? _.cloneDeep(config.filter) : _.cloneDeep(getEmptyFilter())
  );

  // Paging
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <StatelessTable
      className={classes.table}
      rows={rows || []}
      columns={columns}
      getRowId={(row) => row.annotationHdrId}
      searchable
      visible={{ hiddenColumnNames: [] }}
      sort={{ sorting, customSorting: [], handleSortingChange: setSorting }}
      filter={{
        filterValue: filter,
        onChange: setFilter,
        filterFn: TableFilterLogic.defaultFilter,
        hidden: false,
      }}
      paging={{
        currentPage,
        onCurrentPageChange: (page) => setCurrentPage(page),
        pageSize: 15,
      }}
      resize={{
        columnWidths: columnExtensions.map((row) => ({
          columnName: row.columnName,
          width: row.width,
        })),
      }}
      columnExtensions={columnExtensions}
      fixed={{ leftColumns: [], rightColumns: ['actions'] }}
    />
  );
};

export default CompromisedInstrumentsTable;
