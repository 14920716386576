import AudioPlayerWidget, {
  defaultAudioConfig,
} from 'domain/Widgets/AudioPlayerWidget';
import DashboardVideoWidget, {
  defaultVideoConfig,
} from 'domain/Widgets/DashboardVideoWidget';
import DataPreviewWidget, {
  defaultDataPreviewConfig,
} from 'domain/Widgets/DataPreviewWidget';
import DummyWidget from 'domain/Widgets/DummyWidget';
import ImageWidget, { defaultImageConfig } from 'domain/Widgets/ImageWidget';
import LatestReadingsWidget, {
  defaultLatestReadingsConfig,
} from 'domain/Widgets/LatestReadingsWidget';
import TextWidget, { defaultTextConfig } from 'domain/Widgets/TextWidget';
import TimeSeriesScalarDataChartWidget, {
  defaultChartConfig,
} from 'domain/Widgets/TimeSeriesScalarDataChartWidget';
import {
  OncLayouts,
  WidgetConfig,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';
import {
  AUDIO_PLAYER_WIDGET_TYPE_ID,
  CHART_WIDGET_TYPE_ID,
  DATA_PLAYER_WIDGET_TYPE_ID,
  DATA_PREVIEW_WIDGET_TYPE_ID,
  IMAGE_WIDGET_TYPE_ID,
  LATEST_READINGS_WIDGET_TYPE_ID,
  TEXT_WIDGET_TYPE_ID,
  VIDEO_WIDGET_TYPE_ID,
} from './WidgetTypeConstants';

const WIDGET_SERVICE = 'WidgetService';
const EMPTY_WIDGET_CONFIG = [];
const EMPTY_CONFIG = {};

export type WidgetServiceData = {
  layoutId: number;
  modifyBy: number;
  modifyDate: string;
  widgetConfig: string;
  widgetId: number;
  widgetTypeId: number;
};

type WidgetServiceFetchParameters = {
  layoutId: number;
};

export type WidgetServiceGetResponse = {
  widgets: WidgetServiceData[];
};

export type WidgetServiceUpdateParameters = {
  widgetId: number;
  widgetConfig: string;
};

export type WidgetServiceResponse = {
  widget: WidgetServiceData;
};

export type WidgetServiceCreateParameters = {
  widgetTypeId: number;
  widgetConfig: string;
  layoutId: number;
};

export type WidgetServiceRemoveParameters = {
  widgetId: number;
};
export type WidgetServiceRemoveResponse = {
  statusCode: number;
};

export const useLayoutWidgets = (params: WidgetServiceFetchParameters) =>
  useGet<WidgetServiceGetResponse, WidgetServiceFetchParameters>(
    WIDGET_SERVICE,
    100,
    params
  );

export const useUpdateWidget = () =>
  usePost<WidgetServiceUpdateParameters, WidgetServiceResponse>(
    WIDGET_SERVICE,
    {},
    2
  );

export const useCreateWidget = () =>
  usePost<WidgetServiceCreateParameters, WidgetServiceResponse>(
    WIDGET_SERVICE,
    {},
    1
  );

export const useRemoveWidget = () =>
  usePost<WidgetServiceRemoveParameters, WidgetServiceRemoveResponse>(
    WIDGET_SERVICE,
    {},
    3
  );

export const transformWidgetIdToWidgetKey = (widgetTypeId: number) => {
  switch (widgetTypeId) {
    case AUDIO_PLAYER_WIDGET_TYPE_ID:
      return AudioPlayerWidget.widgetKey;
    case CHART_WIDGET_TYPE_ID:
      return TimeSeriesScalarDataChartWidget.widgetKey;
    case DATA_PLAYER_WIDGET_TYPE_ID:
      // TODO: Dataplayer not yet converted to compatible widget, to be added later
      return null;
    case DATA_PREVIEW_WIDGET_TYPE_ID:
      return DataPreviewWidget.widgetKey;
    case IMAGE_WIDGET_TYPE_ID:
      return ImageWidget.widgetKey;
    case LATEST_READINGS_WIDGET_TYPE_ID:
      return LatestReadingsWidget.widgetKey;
    case TEXT_WIDGET_TYPE_ID:
      return TextWidget.widgetKey;
    case VIDEO_WIDGET_TYPE_ID:
      return DashboardVideoWidget.widgetKey;
    default:
      return DummyWidget.widgetKey;
  }
};

export const transformWidgetKeyToWidgetTypeId = (widgetKey: string) => {
  switch (widgetKey) {
    case AudioPlayerWidget.widgetKey:
      return AUDIO_PLAYER_WIDGET_TYPE_ID;
    case TimeSeriesScalarDataChartWidget.widgetKey:
      return CHART_WIDGET_TYPE_ID;
    case DataPreviewWidget.widgetKey:
      return DATA_PREVIEW_WIDGET_TYPE_ID;
    case ImageWidget.widgetKey:
      return IMAGE_WIDGET_TYPE_ID;
    case LatestReadingsWidget.widgetKey:
      return LATEST_READINGS_WIDGET_TYPE_ID;
    case TextWidget.widgetKey:
      return TEXT_WIDGET_TYPE_ID;
    case DashboardVideoWidget.widgetKey:
      return VIDEO_WIDGET_TYPE_ID;
    default:
      return null;
  }
};

export const getDefaultConfig = (widgetTypeId: number) => {
  switch (widgetTypeId) {
    case VIDEO_WIDGET_TYPE_ID:
      return defaultVideoConfig;
    case DATA_PREVIEW_WIDGET_TYPE_ID:
      return defaultDataPreviewConfig;
    case LATEST_READINGS_WIDGET_TYPE_ID:
      return defaultLatestReadingsConfig;
    case CHART_WIDGET_TYPE_ID:
      return defaultChartConfig;
    case AUDIO_PLAYER_WIDGET_TYPE_ID:
      return defaultAudioConfig;
    case TEXT_WIDGET_TYPE_ID:
      return defaultTextConfig;
    case IMAGE_WIDGET_TYPE_ID:
      return defaultImageConfig;
    // TODO: Add Data player widget case when compatible with UD dashboard
    default:
      return EMPTY_CONFIG;
  }
};

export const useDashboardWidgets = (
  layoutId: number,
  layout: OncLayouts
): WidgetConfig[] => {
  const { data } = useLayoutWidgets({ layoutId });

  if (!layout) return EMPTY_WIDGET_CONFIG;

  const defaultWidgets: WidgetConfig[] = data?.widgets?.map((widget) => ({
    i: widget.widgetId.toString(),
    widgetKey: transformWidgetIdToWidgetKey(widget.widgetTypeId),
    config: JSON.parse(widget.widgetConfig),
  }));

  return defaultWidgets;
};

class WidgetService {
  static get(params) {
    return get('WidgetService', params).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static post(params) {
    return post('WidgetService', params).then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}
export default WidgetService;
