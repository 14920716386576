/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import useBroadcast from 'util/hooks/useBroadcast';
import { useLocalStorage } from 'util/hooks/useStorage';

/**
 * This is a custom hook that allows a primary window to spawn secondary windows
 *
 * - Closing the primary window will close all secondary windows
 * - The number of windows for a particular group are tracked through local
 *   storage
 * - When closing, a window broadcasts it's index so others can be updated
 * - When the primary window is unloaded, it clears the variable from local
 *   storage
 * - Supports passing configuration data to new windows
 *
 * It's highly recommended that the id for this hook be something completely
 * unique to the tab/window the user is in, such as a uuid. Otherwise,
 * unexpected behaviour could occur
 */

export type WindowController = {
  windowIndex: number;
  numWindows: number;
  openNewWindow: (config?: object) => void;
  loading: boolean;
  parentWindowId: string;
  configData?: object; // New property to store received config data
};

// Storage key for config data
const CONFIG_STORAGE_PREFIX = 'window_config_';

export default function useWindowController(
  windowControllerId: string
): WindowController {
  const [loading, setLoading] = useState(true);
  const [closedWindowId, setClosedWindowId] = useBroadcast(
    windowControllerId,
    BroadcastChannel.WindowController,
    undefined,
    'useWindowController'
  );
  const [windowIds, updateWindowIds, clearWindowIds] = useLocalStorage<
    string[]
  >(`${windowControllerId}_connections`, []);
  const [windowId, setWindowId] = useState<string | null>(null);
  // Generate unique ID for the config
  const configId = `${CONFIG_STORAGE_PREFIX}${windowControllerId}`;
  const [configData, setConfigData, deleteConfigData] = useLocalStorage<
    object | undefined
  >(configId, undefined);

  const handleUnload = (uuid: string, windows: string[]) => {
    // If the primary window is closed, close all windows by setting the closedWindowId to window controller id
    setClosedWindowId(uuid);
    if (uuid === windowIds[0]) {
      clearWindowIds();
    } else {
      const updatedWindows = windows.filter((itemId) => itemId !== uuid);

      if (updatedWindows.length === 0) {
        clearWindowIds();
      } else {
        updateWindowIds(updatedWindows);
      }
    }

    // Clean up any config data for this window
    if (uuid) {
      deleteConfigData();
    }
  };

  const openNewWindow = (config?: object) => {
    // Store config in localStorage for immediate access
    if (config) {
      setConfigData(config);
    }

    // Prepare URL with necessary parameters
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('primaryWindowId', windowControllerId);
    newUrl.searchParams.set('configId', configId);

    // Open new window
    window.open(newUrl, '', 'width=1280,height=720');
  };

  // Check for URL parameters and localStorage on mount
  useEffect(() => {
    // Check if this is a secondary window by looking for configId parameter
    const params = new URLSearchParams(window.location.search);
    const localConfigId = params.get('configId');

    if (localConfigId) {
      // Try to get config from localStorage first (synchronous)
      const storedConfig = localStorage.getItem(
        `${CONFIG_STORAGE_PREFIX}${localConfigId}`
      );

      if (storedConfig) {
        try {
          setConfigData(JSON.parse(storedConfig));
          setLoading(false);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Failed to parse config data:', e);
        }
      } else {
        // No config expected
        setLoading(false);
      }
    } else {
      // This is a primary window or doesn't need config
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Note crypto.randomUUID is not supported on dive loggers (DMAS-75716)
    let newWindowId = crypto.getRandomValues(new Uint32Array(1)).toString();
    if (windowIds.length === 0) {
      newWindowId = windowControllerId;
    }
    setWindowId(newWindowId);
    updateWindowIds([...windowIds, newWindowId]);

    // Only update loading if not waiting for config
    if (!new URLSearchParams(window.location.search).get('configId')) {
      setLoading(false);
    }

    return () => {
      handleUnload(newWindowId, windowIds);
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => handleUnload(windowId, windowIds);
    return () => {
      window.onbeforeunload = null;
    };
  }, [windowIds, windowId]);

  useEffect(() => {
    if (!closedWindowId || windowIds.length === 0) return;
    if (
      closedWindowId === windowControllerId &&
      windowId !== windowControllerId
    ) {
      window.close();
    } else {
      const updatedWindowIds = windowIds.filter(
        (itemId) => itemId !== closedWindowId
      );
      if (updatedWindowIds.length > 0) {
        updateWindowIds(updatedWindowIds);
      }
    }
  }, [closedWindowId]);

  const windowIndex = windowIds.indexOf(windowId) + 1;

  return {
    windowIndex,
    numWindows: windowIds.length,
    openNewWindow,
    loading,
    parentWindowId: windowControllerId,
    configData, // Return the config data to components using this hook
  };
}
