import { createRef, useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import Environment from '@onc/environment';
import { GetApp, Refresh } from '@onc/icons';
import {
  Box,
  Checkbox,
  Divider,
  HelpLink,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SizeableVirtualList,
  Typography,
} from 'base-components';
import { ShowFilterButton } from 'domain/AppComponents/IconButtons';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import CookieUtils from 'util/CookieUtils';
import { AnnotationLine } from './AnnotationLine';
import decideRowHeight from './DecideRowHeight';
import Panel from '../panel/Panel';

const classes = {
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    paddingRight: '10px',
  },
  listItem: {
    padding: '0',
    paddingRight: 4,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '95%',
  },
};

const DEFAULT_MENU = {
  'List Options': {
    autoRefresh: 'Auto Refresh',
    displayFullTimestamp: 'Full Timestamp',
    sortAnnotations: 'Sort By Most Recent',
  },
  'Annotation Options': {
    displayCopyLink: 'Copy Link',
    displayEditIcon: 'Edit',
    displayDeleteIcon: 'Delete',
    displayVoteIcons: 'Vote',
    displayCreatedBy: 'Created By',
    displayModifiedBy: 'Modified By',
    displayPositionalData: 'Positional Data',
    displayTaxonAttributes: 'Taxon Attributes',
    toBeReviewed: 'To Be Reviewed',
  },
};

/*
 * Format:
 * {
 *   'OPTIONS_HEADER': {
 *     subOption1: { label: 'SUB_OPTION_1', checked: false }
 *   }
 * }
 *
 * i.e.:
 * {
 *   'List Options': {
 *      autoRefresh: { label: 'Auto Refresh', checked: true },
 *      // etc...
 *    }
 * }
 */
type MenuMap = Record<
  string,
  Record<string, { label: string; checked: boolean }>
>;

type ListItem = {
  annotationId: number;
  startDate: string;
};

type AnnotationListProps = {
  listItems: ListItem[];
  loadAnnotations: () => void;
  name: string;
  onAnnotationSort: (shouldSort: boolean) => void;
  onInfo: (message: string) => void;
  onListItemClick: (info, index: number) => void;
  onToggleFilter: (arg0: boolean) => void;
  annotationPermissions?: boolean;
  annotationSortByMostRecent?: boolean;
  currentTimestamp?: Date;
  currentUserId?: number;
  deletePermissions?: boolean;
  editAnnotationId?: number;
  filter?: object;
  lastEditedAnnotationId?: number;
  listHeight?: number;
  loading?: boolean;
  menu?: any;
  menuOptions?: object;
  onAnnotationDeleteClick?: () => void;
  onAnnotationEditClick?: () => void;
  onDownloadClick?: () => void;
  onReviewClick?: () => void;
  onUpdateConfig?: (name: string, config) => void;
  renderDownloadButton?: boolean;
  renderRefreshButton?: boolean;
  resourceId?: number;
  resourceTypeId?: number;
  reviewPermissions?: boolean;
  selectedAnnotationId?: number;
  selfDeletePermissions?: boolean;
  userType?: string;
};

const AnnotationList = ({
  listItems,
  loadAnnotations,
  name,
  onAnnotationSort,
  onInfo,
  onListItemClick,
  onToggleFilter,
  annotationPermissions = undefined,
  annotationSortByMostRecent = false,
  currentTimestamp = undefined,
  currentUserId = -1,
  deletePermissions = undefined,
  editAnnotationId = undefined,
  filter = undefined,
  lastEditedAnnotationId = undefined,
  listHeight = undefined,
  loading = false,
  menu = undefined,
  menuOptions = DEFAULT_MENU,
  onAnnotationDeleteClick = undefined,
  onAnnotationEditClick = undefined,
  onDownloadClick = undefined,
  onReviewClick = undefined,
  onUpdateConfig = () => {},
  renderDownloadButton = false,
  renderRefreshButton = true,
  resourceId = undefined,
  resourceTypeId = undefined,
  reviewPermissions = undefined,
  selectedAnnotationId = undefined,
  selfDeletePermissions = undefined,
  userType = undefined,
}: AnnotationListProps) => {
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [listRef, setListRef] = useState<any>(createRef());
  const [filteredListItems, setFilteredListItems] =
    useState<ListItem[]>(listItems);

  // parse previously saved menu options if exists in cookie
  const config = userType
    ? CookieUtils.getCookie(`${userType}-${name}-config`)
    : undefined;

  // Map menu options (same format as DEFAULT_MENU) to MenuMap type
  const [menuState, setMenuState] = useState<MenuMap>(
    Object.fromEntries(
      Object.entries(menuOptions).map(([menuOption, subOptions]) => [
        menuOption,
        Object.entries(subOptions).reduce(
          (acc, entry) => ({
            ...acc,
            [entry[0]]: {
              label: entry[1],
              checked: config ? config[entry[0]] : false,
            },
          }),
          {}
        ),
      ])
    )
  );

  // ============================== Misc ==============================

  /**
   * Return all entries nested 1 layer deep into menuState (basically just
   * remove the headers)
   */
  const flattenMenuState = () =>
    Object.values(menuState).reduce((acc, obj) => ({ ...acc, ...obj }), {});

  const recomputeRowHeights = () => {
    if (listRef && listRef.recomputeRowHeights) {
      listRef.recomputeRowHeights(0);
    }
  };

  // set or unset autorefreshing when option is toggled
  useEffect(() => {
    const flattenedMenuState = Object.values(menuState).reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );
    const { autoRefresh } = flattenedMenuState;

    if (autoRefresh?.checked) {
      const intervalId = setInterval(() => {
        loadAnnotations();
      }, 30000); // every 30 seconds

      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [loadAnnotations, menuState]);

  // update cookie to remember menu choices
  const updateConfig = () => {
    const {
      autoRefresh,
      sortAnnotations,
      displayCopyLink,
      displayEditIcon,
      displayDeleteIcon,
      displayVoteIcons,
      displayCreatedBy,
      displayFullTimestamp,
      displayModifiedBy,
      displayPositionalData,
      displayTaxonAttributes,
      toBeReviewed,
    } = flattenMenuState();
    const newConfig = {
      autoRefresh: autoRefresh?.checked,
      sortAnnotations: sortAnnotations?.checked,
      displayCopyLink: displayCopyLink?.checked,
      displayEditIcon: displayEditIcon?.checked,
      displayDeleteIcon: displayDeleteIcon?.checked,
      displayVoteIcons: displayVoteIcons?.checked,
      displayCreatedBy: displayCreatedBy?.checked,
      displayFullTimestamp: displayFullTimestamp?.checked,
      displayModifiedBy: displayModifiedBy?.checked,
      displayPositionalData: displayPositionalData?.checked,
      displayTaxonAttributes: displayTaxonAttributes?.checked,
      toBeReviewed: toBeReviewed?.checked,
    };
    onUpdateConfig(name, newConfig);
  };

  // ============================== Menu ==============================

  /**
   * Check or uncheck 'key' in menuState's header 'menuOption', then resize and
   * update cookie
   */
  const onMenuToggle = (menuOption: string, key: string) => {
    const newMenuState = {
      ...menuState,
      [menuOption]: {
        ...menuState[menuOption],
        [key]: {
          label: menuState[menuOption][key].label,
          checked: !menuState[menuOption][key].checked,
        },
      },
    };
    setMenuState(newMenuState);
    recomputeRowHeights();
    updateConfig();
  };

  // map menuState entries in format 'Header' | option1: checkbox, option2: checkbox...
  const renderMenu = () => (
    <div>
      {Object.entries(menuState).map(([menuOption, subOptions]) => (
        <div key={menuOption}>
          <ListItem>
            <ListItemText disableTypography>
              <Typography variant="subtitle1">{`${menuOption}:`}</Typography>
            </ListItemText>
          </ListItem>
          <Menu key={menuOption} menuList>
            {Object.entries(subOptions).map(([key, obj]) => (
              <MenuItem
                sx={classes.listItem}
                key={key}
                onClick={() => onMenuToggle(menuOption, key)}
              >
                <Checkbox checked={obj.checked} />
                {obj.label}
              </MenuItem>
            ))}
          </Menu>
          <Divider />
        </div>
      ))}
      <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-AnnotationListWidget" />
      <Divider />
      {menu}
    </div>
  );

  // update menu options if permissions change
  useEffect(() => {
    const updatedMenu = { ...menuState };

    if (
      !annotationPermissions &&
      updatedMenu['Annotation Options'].displayEditIcon
    ) {
      delete updatedMenu['Annotation Options'].displayEditIcon;
    }

    if (
      !deletePermissions &&
      !selfDeletePermissions &&
      updatedMenu['Annotation Options'].displayDeleteIcon
    ) {
      delete updatedMenu['Annotation Options'].displayDeleteIcon;
    }

    if (
      !reviewPermissions &&
      updatedMenu['Annotation Options'].displayVoteIcons
    ) {
      delete updatedMenu['Annotation Options'].displayVoteIcons;
    }

    setMenuState(updatedMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    annotationPermissions,
    deletePermissions,
    selfDeletePermissions,
    reviewPermissions,
  ]);

  // ======================== Annotation List =========================

  const determineAnnotationPermissions = (annotation) => {
    switch (resourceTypeId) {
      case SeaTubeResourceTypes.SEARCH_TREE_NODE:
        return (
          annotationPermissions && annotation.createdBy.userId === currentUserId
        );
      default:
        return annotationPermissions;
    }
  };

  const handleCopyAnnotationLink = (annotation) => {
    const env = Environment.getDmasUrl();
    let url = `${env}/SeaTubeV3?`;
    url += `resourceTypeId=${resourceTypeId}&`;
    url += `resourceId=${resourceId}&`;
    url += `time=${annotation.startDate}&`;
    url += `annotationId=${annotation.annotationId}`;
    navigator.clipboard.writeText(`${url}`);
    onInfo('Link Copied to Clipboard');
  };

  const renderAnnotationLine = (info, item) => {
    const annotation = { ...item };
    const { annotationId } = annotation;
    let selected;

    if (selectedIndex !== undefined) {
      selected = info.index === selectedIndex;
    }

    const menuValues = flattenMenuState();

    return (
      <AnnotationLine
        style={info.style}
        key={annotationId}
        annotation={annotation}
        onAnnotationEditClick={onAnnotationEditClick}
        onAnnotationClick={() => {
          onListItemClick(item, info.index);
          setSelectedIndex(info.index);
        }}
        onAnnotationDeleteClick={onAnnotationDeleteClick}
        onCopyLink={handleCopyAnnotationLink}
        selected={selected}
        displayFullTimestamp={menuValues.displayFullTimestamp?.checked}
        displayPositionalData={menuValues.displayPositionalData?.checked}
        displayModifiedBy={menuValues.displayModifiedBy?.checked}
        displayCopyLink={menuValues.displayCopyLink?.checked}
        displayEditIcon={menuValues.displayEditIcon?.checked}
        displayDeleteIcon={menuValues.displayDeleteIcon?.checked}
        displayVoteIcons={menuValues.displayVoteIcons?.checked}
        displayCreatedBy={menuValues.displayCreatedBy?.checked}
        displayTaxonAttributes={menuValues.displayTaxonAttributes?.checked}
        highlightToBeReviewed={menuValues.toBeReviewed?.checked}
        deletePermissions={
          deletePermissions ||
          (selfDeletePermissions &&
            annotation.createdBy.userId === currentUserId)
        }
        annotationPermissions={determineAnnotationPermissions(annotation)}
        reviewPermissions={reviewPermissions}
        onReviewClick={onReviewClick}
        lastEditedAnnotationId={lastEditedAnnotationId}
        editAnnotationId={editAnnotationId}
      />
    );
  };

  const renderListLine = (info) => {
    const item = filteredListItems[info.index];
    if (item.annotationId) {
      return renderAnnotationLine(info, item);
    }
    return undefined;
  };

  const renderAnnotations = () => {
    if (filteredListItems && filteredListItems.length === 0 && !loading) {
      return <Box sx={classes.noData}>No Data</Box>;
    }
    if (!loading) {
      return (
        <AutoSizer disableHeight={listHeight}>
          {({ height, width }) => (
            <SizeableVirtualList
              height={listHeight || height}
              width={width}
              rowHeight={(info) =>
                decideRowHeight(
                  info,
                  width,
                  filteredListItems,
                  flattenMenuState()
                )
              }
              rowRenderer={(info) => renderListLine(info)}
              rowCount={filteredListItems?.length}
              setRef={setListRef}
            />
          )}
        </AutoSizer>
      );
    }
    return undefined;
  };

  // set filtered list items if sorting gets toggled or listItems changes
  useEffect(() => {
    const { sortAnnotations } = Object.values(menuState).reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );
    if (sortAnnotations) {
      onAnnotationSort(sortAnnotations.checked);
    }

    let fli = [...listItems];

    fli = fli.sort((a, b) => {
      const date1 = a.startDate;
      const date2 = b.startDate;
      if (sortAnnotations?.checked) {
        return new Date(date2).getTime() - new Date(date1).getTime();
      }
      return new Date(date1).getTime() - new Date(date2).getTime();
    });
    let currIndex;
    fli.forEach((item, index) => {
      if (item.annotationId && item.annotationId === selectedAnnotationId) {
        currIndex = index;
      }
    });
    setFilteredListItems(fli);
    setSelectedIndex(currIndex);
  }, [
    menuState,
    listItems,
    annotationSortByMostRecent,
    selectedAnnotationId,
    onAnnotationSort,
  ]);

  // maintain list selection if list sorting changes
  useEffect(() => {
    if (selectedIndex === null || isNaN(selectedIndex)) {
      return;
    }

    const timeStampSeconds = Math.floor(
      new Date(currentTimestamp).getTime() / 1000
    );

    const { sortAnnotations } = Object.values(menuState).reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );
    const sortAnnotationsValue = sortAnnotations
      ? sortAnnotations.checked
      : false;

    // Prevents weird behavior when annotations have the same timestamp
    if (filteredListItems[selectedIndex]) {
      const currentSeconds = Math.floor(
        new Date(filteredListItems[selectedIndex].startDate).getTime() / 1000
      );

      if (currentSeconds === timeStampSeconds) {
        return;
      }
    }

    if (!filteredListItems || !filteredListItems.length) {
      return;
    }
    let index = 0;
    let currAnnotationTimestamp;
    for (index; index < filteredListItems.length; index += 1) {
      currAnnotationTimestamp = new Date(filteredListItems[index].startDate);
      const currentTimeStampSeconds = Math.floor(
        currAnnotationTimestamp.getTime() / 1000
      );
      // if the current annotation is "past" the timestamp, stop!
      if (
        (sortAnnotationsValue && currentTimeStampSeconds < timeStampSeconds) ||
        (!sortAnnotationsValue && currentTimeStampSeconds > timeStampSeconds)
      ) {
        break;
      }
    }

    // grab the annotation and index of the "correct" annotation
    if (!sortAnnotationsValue) {
      index -= 1;
      index = index < 0 ? 0 : index; // if it's less than 0, make it 0
    }

    const item = filteredListItems[index];

    if (item) {
      setSelectedIndex(index);
    }
  }, [
    currentTimestamp,
    listItems,
    filteredListItems,
    menuState,
    selectedIndex,
  ]);

  // ============================= Render =============================

  const titleContent = (
    <Typography sx={classes.title} variant="body1">
      Annotation List
    </Typography>
  );

  const filterButton = filter ? (
    <ShowFilterButton onClick={() => onToggleFilter(true)} />
  ) : (
    <></>
  );

  const refreshButton = renderRefreshButton ? (
    <IconButton onClick={loadAnnotations} aria-label="Refresh" Icon={Refresh} />
  ) : (
    <></>
  );

  const downloadButton = renderDownloadButton ? (
    <IconButton
      aria-label="Download Data Product"
      onClick={onDownloadClick}
      Icon={GetApp}
    />
  ) : (
    <></>
  );

  const actionContent = (
    <>
      {refreshButton}
      {filterButton}
      {downloadButton}
      <Typography variant="body1" label="Annotation Count">
        {`${
          filteredListItems ? filteredListItems.length : 0
        } of ${listItems.length}`}
      </Typography>
    </>
  );

  if (!renderedOnce) {
    setRenderedOnce(true);
    recomputeRowHeights();
  }

  return (
    <Box sx={classes.root}>
      <Panel
        id="seatube-annotation-list-panel"
        title={titleContent}
        actionContent={actionContent}
        menu={renderMenu()}
        menuProps={{
          closeOnClick: false,
          buttonLabel: 'Annotation List Menu',
        }}
        headerDraggable
        loading={loading}
        enableOverflow
      >
        <Divider />
        {renderAnnotations()}
      </Panel>
    </Box>
  );
};

export default AnnotationList;
