import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import Environment from '@onc/environment';
import { StatelessTable, useSnackbars } from 'base-components';
import {
  AgreementSiteDeviceDate,
  AgreementSiteDeviceLicence,
} from 'domain/AppComponents/agreement-site-devices/AgreementSiteDeviceComponents';
import EditAgreementSiteDeviceDialog from 'domain/AppComponents/agreement-site-devices/EditAgreementSiteDeviceDialog';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import SiteDeviceSearchDialog from 'domain/AppComponents/site-device-search/SiteDeviceSearchDialog';
import AgreementSiteDeviceService, {
  type ExistingAgreementSiteDevice,
} from 'domain/services/AgreementSiteDeviceService';
import DateFormatUtils from 'util/DateFormatUtils';

type AgreementSiteDevicesPanelProps = {
  organizationAgreementId: number;
  organizationAgreementName: string;
};

export type AgreementSiteDeviceRow = ExistingAgreementSiteDevice & {
  id: number;
  startDateFormatted: ReactNode;
  endDateFormatted: ReactNode;
  licenceFormatted: ReactNode;
};

const useIcons = (
  permission: string,
  setRowToBeDeleted: (row: AgreementSiteDeviceRow) => void,
  setRowToBeEdited: (row: AgreementSiteDeviceRow) => void
) =>
  useMemo(
    () => (row: AgreementSiteDeviceRow) => {
      if (permission !== 'RW') {
        return null;
      }
      return (
        <>
          <EditIconButton
            onClick={() => setRowToBeEdited(row)}
            aria-label="edit"
          />
          <DeleteIconButton
            onClick={() => setRowToBeDeleted(row)}
            size="small"
            aria-label="delete"
          />
        </>
      );
    },
    [permission, setRowToBeDeleted, setRowToBeEdited]
  );

const useOrganizationAgreementResources = (
  organizationAgreementId: number,
  onInfo: (message: string) => void,
  onError: (errorMessage: string) => void
): [ExistingAgreementSiteDevice[], () => void] => {
  const [
    organizationAgreementResourceList,
    setOrganizationAgreementResourceList,
  ] = useState<ExistingAgreementSiteDevice[]>([]);

  const handleRefresh = useCallback(() => {
    AgreementSiteDeviceService.getByOrganizationAgreementId(
      organizationAgreementId
    )
      .then((response: ExistingAgreementSiteDevice[]) => {
        setOrganizationAgreementResourceList(response);
        onInfo('Results refreshed');
      })
      .catch((error) => {
        onError(error.message);
      });
  }, [organizationAgreementId, onInfo, onError]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return [organizationAgreementResourceList, handleRefresh];
};

const AgreementSiteDevicesTable: React.VFC<AgreementSiteDevicesPanelProps> = ({
  organizationAgreementId,
  organizationAgreementName,
}) => {
  const permission = Environment.getDmasUserPrivilege();
  const { onInfo, onError } = useSnackbars();
  const [agreementSiteDevicesList, handleRefresh] =
    useOrganizationAgreementResources(organizationAgreementId, onInfo, onError);

  const [isAddResourcePanelOpen, setAddResourcePanelOpen] =
    useState<boolean>(false);
  const [rowToBeDeleted, setRowToBeDeleted] =
    useState<AgreementSiteDeviceRow | null>(null);
  const [rowToBeEdited, setRowToBeEdited] =
    useState<AgreementSiteDeviceRow | null>(null);

  const icons = useIcons(permission, setRowToBeDeleted, setRowToBeEdited);

  const formatDateIfExists = (date, type) =>
    date ? DateFormatUtils.formatDate(date, type) : '';

  const tableRows = useMemo(
    () =>
      (agreementSiteDevicesList || []).map((oar) => {
        const {
          organizationAgreementResource: {
            organizationAgreementResourceId,
            organizationAgreement: { licence: agreementLicence },
            startDate,
            endDate,
            licence,
            modifyBy,
            modifyDate,
          },
          siteDevice: { dateFrom, dateTo },
        } = oar;
        const oarRow = {
          ...oar,
          id: organizationAgreementResourceId,
          siteDeviceId: oar.siteDevice.siteDeviceId,
          startDateFormatted: (
            <AgreementSiteDeviceDate
              date={startDate}
              siteDeviceDate={dateFrom}
              variant="table"
            />
          ),
          endDateFormatted: (
            <AgreementSiteDeviceDate
              date={endDate}
              siteDeviceDate={dateTo}
              variant="table"
            />
          ),
          licenceFormatted: (
            <AgreementSiteDeviceLicence
              licence={licence}
              agreementLicence={agreementLicence}
            />
          ),
          modifyBy,
          modifyDate: formatDateIfExists(modifyDate, 'date'),
        };
        return {
          icons: icons(oarRow),
          ...oarRow,
        };
      }),
    [agreementSiteDevicesList, icons]
  );

  const columns = useMemo(() => {
    const baseColumns = [
      { title: 'ID', name: 'id' },
      { title: 'Site Device ID', name: 'siteDeviceId' },
      { title: 'Start Date', name: 'startDateFormatted' },
      { title: 'End Date', name: 'endDateFormatted' },
      { title: 'Licence', name: 'licenceFormatted' },
      { title: 'Modify By', name: 'modifyBy' },
      { title: 'Modify Date', name: 'modifyDate' },
    ];
    return permission === 'RW'
      ? [...baseColumns, { title: ' ', name: 'icons' }]
      : baseColumns;
  }, [permission]);

  const handleCancel = () => setRowToBeDeleted(null);

  const handleDelete = () => {
    const { id } = rowToBeDeleted;
    AgreementSiteDeviceService.delete(id)
      .then(() => {
        onInfo(
          `Organization Agreement Resource (ID: ${id}) deleted successfully`
        );
      })
      .catch((e: Error) => {
        onError(e.message);
      })
      .finally(() => {
        setRowToBeDeleted(null);
        handleRefresh();
      });
  };

  const handleCloseAddDialog = () => {
    setAddResourcePanelOpen(false);
    handleRefresh();
  };

  const columnExtensions = [
    {
      columnName: 'id',
      groupingEnabled: false,
    },
    {
      columnName: 'siteDeviceId',
      groupingEnabled: true,
    },
    { columnName: 'startDateFormatted', groupingEnabled: false },
    { columnName: 'endDateFormatted', groupingEnabled: false },
    { columnName: 'licenceFormatted', groupingEnabled: false },
    { columnName: 'modifyBy', groupingEnabled: false },
    { columnName: 'modifyDate', groupingEnabled: false },
    {
      columnName: 'icons',
      groupingEnabled: false,
    },
  ];

  return (
    <>
      {rowToBeDeleted && (
        <ConfirmationDialog
          open={Boolean(rowToBeDeleted)}
          title={`Delete Organization Agreement Resource ${rowToBeDeleted?.id}`}
          content={`Are you sure you want to delete Organization Agreement Resource ${rowToBeDeleted?.id}?`}
          onCancel={handleCancel}
          onConfirm={handleDelete}
        />
      )}
      {rowToBeEdited && (
        <EditAgreementSiteDeviceDialog
          row={rowToBeEdited}
          onClose={(refresh?: boolean) => {
            setRowToBeEdited(null);
            refresh && handleRefresh();
          }}
        />
      )}
      <SiteDeviceSearchDialog
        organizationAgreementName={organizationAgreementName}
        organizationAgreementId={organizationAgreementId}
        open={isAddResourcePanelOpen}
        onClose={handleCloseAddDialog}
        onInfo={onInfo}
        onError={onError}
      />
      <StatelessTable
        title="Site Devices for Organization Agreement"
        rows={tableRows}
        columns={columns}
        permission={permission}
        sort={{
          sorting: [{ columnName: 'startDateFormatted', direction: 'asc' }],
          columnExtensions: [{ columnName: 'icons', sortingEnabled: false }],
        }}
        group={{
          grouping: [{ columnName: 'siteDeviceId' }],
          columnExtensions,
        }}
        searchable
        onCreate={() => setAddResourcePanelOpen(true)}
        onRefresh={handleRefresh}
      />
    </>
  );
};

export default AgreementSiteDevicesTable;
