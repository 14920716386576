import { Fragment, memo, useMemo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import moment from 'moment';
import { Edit, Delete, Link, Close, VideocamOff } from '@onc/icons';
import {
  Divider,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from 'base-components';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import { SeaTubeAnnotationPermissions } from 'domain/services/SeaTubePermissionsService';
import { AnnotationListConfig } from 'domain/Widgets/AnnotationListWidget';
import AnnotationActions from 'library/CompositeComponents/annotation-list/AnnotationActions';
import { VideoInterval } from 'library/CompositeComponents/video/SeamlessVideoUtil';
import DateFormatUtils from 'util/DateFormatUtils';
import ObjectUtils from 'util/ObjectUtils';

const HIGHLIGHT_FULL_DURATION = 5; // seconds for full highlight
const HIGHLIGHT_FADE_DURATION = 5; // seconds for fading effect
const NO_VIDEO_MESSAGE =
  'No video archived for current resolution;\ntry another resolution if available';

const useStyles = makeStyles((theme) => ({
  highlightRecent: {
    transition: 'background-color',
    backgroundColor: `${theme.palette.highlight.main} !important`,
  },
  highlightFading: {
    transition: 'background-color 5s ease-out',
    backgroundColor: 'transparent',
  },
  toBeReviewed: {
    background: '#ea9999',
  },
  lastEdited: {
    width: theme.spacing(),
    backgroundColor: theme.palette.primary.main, // light blue
  },
  editing: {
    outlineOffset: '-2px',
    outline: `2px dashed ${theme.palette.primary.main}`, // light blue
  },
  attributes: {
    display: 'inline-block',
    textIndent: theme.spacing(2),
  },
  blockSpan: {
    display: 'block',
  },
  flexContainer: {
    display: 'flex',
  },
  annotationTimestamp: {
    marginRight: '16px',
    minWidth: '0',
    marginTop: '0',
    position: 'relative',
    userSelect: 'text',
  },
  annotationContent: {
    paddingRight: '80px',
    marginTop: 0,
    marginBottom: 0,
    userSelect: 'text',
  },
  tooltip: {
    display: 'flex',
    width: '100%',
  },
}));

type AnnotationListItemProps = {
  annotation: ListAnnotation;
  onClick: (annotation: ListAnnotation) => void;
  onEdit: (annotation: ListAnnotation) => void;
  onDelete: (annotation: ListAnnotation) => void;
  onCopyLink: (annotation: ListAnnotation) => void;
  onReviewClick: (id: number, value: 'up' | 'down' | undefined) => void;
  config: AnnotationListConfig;
  permissions: SeaTubeAnnotationPermissions;
  lastEditedAnnotationId: number;
  editAnnotationId: number;
  isLiveMode: boolean;
  videoIntervals: VideoInterval[];
  currentTimestamp?: string;
};

const AnnotationListItem: React.FC<AnnotationListItemProps> = ({
  annotation,
  config,
  onClick,
  onEdit,
  onDelete,
  onCopyLink,
  onReviewClick,
  permissions,
  lastEditedAnnotationId,
  editAnnotationId,
  isLiveMode,
  videoIntervals,
  currentTimestamp = undefined,
}: AnnotationListItemProps) => {
  const classes = useStyles();
  const [highlightState, setHighlightState] = useState<
    'none' | 'solid' | 'fading'
  >('none');

  const {
    displayFullTimestamp,
    displayPositionalData,
    displayModifiedBy,
    displayCopyLink,
    displayEditIcon,
    displayDeleteIcon,
    displayVoteIcons,
    displayCreatedBy,
    displayTaxonAttributes,
    highlightToBeReviewed,
  } = config;

  const { canDelete, canEdit, canReview } = permissions;

  useEffect(() => {
    if (!currentTimestamp) {
      return setHighlightState('none');
    }

    const annotationTime = moment.utc(annotation.startDate);
    const currentTime = moment.utc(currentTimestamp);

    // Ignore future annotations entirely
    if (annotationTime.isAfter(currentTime)) {
      return setHighlightState('none');
    }

    // Calculate how many seconds ago the annotation happened
    const secondsAgo = currentTime.diff(annotationTime, 'seconds');
    // Case 1: Within full highlight duration - show full highlight
    if (secondsAgo <= HIGHLIGHT_FULL_DURATION) {
      return setHighlightState('solid');
    }
    // Case 2: Between full and fade duration - trigger fade if not already fading
    if (secondsAgo < HIGHLIGHT_FULL_DURATION + HIGHLIGHT_FADE_DURATION) {
      if (highlightState !== 'fading') {
        return setHighlightState('fading');
      }
    }
    // Case 3: Beyond the combined duration or in the future - no highlight
    return setHighlightState('none');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimestamp, annotation.startDate]);

  const generateLocationString = (lat, lon, heading, depth) => {
    let coordinateText = '';
    let elevationText = '';

    if (!lat && !lon && !depth && !heading) {
      return undefined;
    }

    if (lat === 0 && lon === 0) {
      coordinateText = 'LAT LON: N/A';
    } else {
      coordinateText = `LAT LON: ${Number.parseFloat(lat).toFixed(
        6
      )}, ${Number.parseFloat(lon).toFixed(6)}, `;
    }

    elevationText = 'Depth: ';
    elevationText += depth !== 0 ? `${Math.round(depth)}m` : 'N/A';

    elevationText += ', Heading: ';
    elevationText += heading !== 0 ? `${heading}°` : 'N/A';

    return `${coordinateText} ${elevationText}`;
  };

  const sortAttributes = (attributes) =>
    attributes.sort((a, b) => (a.name < b.name ? -1 : 1));

  const renderTaxon = () => {
    const { taxons } = annotation;

    let taxonText;
    let attributeText;

    if (taxons) {
      taxonText = (
        <Typography variant="body2" className={classes.blockSpan}>
          {AnnotationUtil.constructDisplayText(annotation, false)}
        </Typography>
      );
      if (taxons[0].attributes && displayTaxonAttributes) {
        const attributes = ObjectUtils.deepClone(taxons[0].attributes);
        attributeText = sortAttributes(attributes).map(({ name, value }) => (
          <Fragment key={`${name}-${value}`}>
            <span className={classes.attributes}>{`${name}: ${value}`}</span>
            <br />
          </Fragment>
        ));
        attributeText = (
          <Typography variant="body2">{attributeText}</Typography>
        );
      }

      return (
        <div>
          {taxonText}
          {attributeText}
        </div>
      );
    }

    return null;
  };

  const renderComment = () => {
    let finalComment = annotation.comment;
    if (annotation.comment === '') {
      if (!annotation.taxons) finalComment = '-';
      else return undefined;
    }
    return (
      <Typography variant="body2" className={classes.blockSpan}>
        {finalComment}
      </Typography>
    );
  };

  const renderUserText = () => {
    if (!displayCreatedBy && !displayModifiedBy) return undefined;

    const { createdBy, createdDate, modifiedBy, modifiedDate } = annotation;
    const creator = displayCreatedBy ? (
      <Typography
        className={classes.blockSpan}
        variant="caption"
      >{`Created By: ${createdBy.firstName} ${
        createdBy.lastName
      } (${DateFormatUtils.formatDate(createdDate, 'full')})`}</Typography>
    ) : undefined;

    const modifier =
      displayModifiedBy && createdDate !== modifiedDate ? (
        <Typography className={classes.blockSpan} variant="caption">
          {`Modified By: ${modifiedBy.firstName} ${
            modifiedBy.lastName
          } (${DateFormatUtils.formatDate(modifiedDate, 'full')})`}
        </Typography>
      ) : undefined;

    return (
      <div>
        {creator}
        {modifier}
      </div>
    );
  };

  const renderPositionalTags = () => {
    const { lat, lon, depth, heading } = annotation;

    if (!displayPositionalData) {
      return undefined;
    }

    const positionText = generateLocationString(lat, lon, heading, depth);

    return (
      <div>
        <span className={classes.blockSpan}>
          <Typography variant="caption">
            <span>{positionText}</span>
          </Typography>
        </span>
      </div>
    );
  };

  const renderAnnotationActions = () => {
    const { taxons } = annotation;
    const options = [];
    // Copy Link
    if (displayCopyLink) {
      options.push({
        label: 'Copy Link',
        onClick: (e) => {
          e.stopPropagation();
          onCopyLink(annotation);
        },
        icon: Link,
      });
    }
    // Edit Action
    if (canEdit(annotation) && displayEditIcon) {
      const isBeingEdited = annotation.annotationId === editAnnotationId;
      options.push({
        label: isBeingEdited ? 'Cancel' : 'Edit Annotation',
        onClick: (e) => {
          e.stopPropagation();
          onEdit(isBeingEdited ? undefined : annotation);
        },
        icon: isBeingEdited ? Close : Edit,
      });
    }
    // Delete Action
    if (canDelete(annotation) && displayDeleteIcon) {
      options.push({
        label: 'Delete Annotation',
        onClick: (e) => {
          e.stopPropagation();
          onDelete(annotation);
        },
        icon: Delete,
      });
    }

    let reviewProps;
    if (
      canReview &&
      displayVoteIcons &&
      taxons &&
      taxons[0].taxonomyCode === 'WoRMS'
    ) {
      const { annotationReview } = taxons[0];
      reviewProps = {
        annotationId: annotation.annotationId,
        reviewValue: annotationReview
          ? annotationReview.reviewValue.toLowerCase()
          : undefined,
        onReviewClick,
      };
    }
    return <AnnotationActions options={options} {...reviewProps} />;
  };

  const renderDisplayText = () => (
    <>
      {renderTaxon()}
      {renderComment()}
      {renderPositionalTags()}
      {renderUserText()}
    </>
  );

  const timeFormat = displayFullTimestamp ? 'full' : 'time-no-seconds';
  const timestamp = DateFormatUtils.formatDate(
    annotation.startDate,
    timeFormat
  );

  const isAnnotationDateInIntervals = useMemo(() => {
    if (!videoIntervals) return false;
    const dateToCheck = moment.utc(annotation.startDate);
    return videoIntervals.some((interval) => {
      const startDate = moment.utc(interval.startDate);
      const endDate = moment.utc(interval.endDate);
      return dateToCheck.isBetween(startDate, endDate, undefined, '[)');
    });
  }, [videoIntervals, annotation.startDate]);

  let annotationClasses = '';
  if (highlightToBeReviewed)
    annotationClasses += annotation.toBeReviewed
      ? ` ${classes.toBeReviewed}`
      : '';

  if (annotation.annotationId === editAnnotationId)
    annotationClasses += ` ${classes.editing}`;

  // Add time-based highlight classes
  if (highlightState === 'solid') {
    annotationClasses += ` ${classes.highlightRecent}`;
  } else if (highlightState === 'fading') {
    annotationClasses += ` ${classes.highlightFading}`;
  }

  const renderWarning = () => {
    if (isLiveMode || !videoIntervals) return null;
    if (!isAnnotationDateInIntervals) {
      return (
        <Tooltip
          placement="top"
          title={
            <span style={{ whiteSpace: 'pre-line' }}>{NO_VIDEO_MESSAGE}</span>
          }
        >
          <VideocamOff fontSize="small" sx={{ mr: 1 }} />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <span>
      {config.sortMostRecent && <Divider />}
      <div className={classes.flexContainer}>
        {lastEditedAnnotationId === annotation.annotationId && (
          <div
            className={classes.lastEdited}
            data-cy="last-edited-annotation"
          />
        )}
        <ListItem
          className={annotationClasses}
          button={!isLiveMode}
          alignItems="flex-start"
          onClick={!isLiveMode ? () => onClick(annotation) : undefined}
          role="listitem"
          classes={{
            root: annotationClasses,
          }}
        >
          <ListItemIcon className={classes.annotationTimestamp}>
            {renderWarning()}
            <Typography variant="body2">{timestamp}</Typography>
          </ListItemIcon>
          <ListItemText
            primary={renderDisplayText()}
            className={classes.annotationContent}
          />
          <ListItemSecondaryAction>
            {renderAnnotationActions()}
          </ListItemSecondaryAction>
        </ListItem>
      </div>
      {!config.sortMostRecent && <Divider />}
    </span>
  );
};

export default memo(AnnotationListItem);
