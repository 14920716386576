import { useContext, useEffect, useState } from 'react';
import { Grid, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { SaveButton } from '@onc/composite-components';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import TaxonService from 'domain/services/TaxonService';
import useWebService from 'util/hooks/useWebService';
import TaxonDetailsForm, { TaxonDetailsFormErrors } from './TaxonDetailsForm';
import { TaxonomyContext } from './TaxonomyApp';
import { TaxonItem, TaxonomyItem } from '../../Widgets/TaxonomyTreeWidget';

type Props = {
  taxonomy: TaxonomyItem;
  taxon: TaxonItem;
  nodeTypes: any[];
  isEditEnabled: boolean;
  onError: (message: string) => void;
  onInfo: (message: string) => void;
};

export function TaxonDetailsFormValidation(value: TaxonItem) {
  const err: TaxonDetailsFormErrors = {};
  if (value.commonName.trim() === '') {
    err.commonName = 'Name Required';
  }
  if (
    value.englishNames &&
    value.englishNames.length > 1 &&
    value.englishNames.some((text) => text === '')
  ) {
    err.englishNames = 'Invalid Common Names';
  }
  return err;
}

const TaxonEditForm = ({
  taxonomy,
  taxon,
  nodeTypes,
  isEditEnabled,
  onError,
  onInfo,
}: Props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const [taxonValue, setTaxonValue] = useState<TaxonItem>(taxon);

  const [errors, setErrors] = useState<TaxonDetailsFormErrors>({});

  const { setTaxon, setMatrixId } = useContext(TaxonomyContext);

  const [, , updateTaxon] = useWebService({
    method: TaxonService.updateTaxon,
    onError,
  });

  useEffect(() => {
    setTaxonValue(taxon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxon]);

  const handleTaxonChange = (key: keyof TaxonItem, val) => {
    setErrors((prev) => ({ ...prev, [key]: undefined }));
    if (key === 'englishNames') {
      setTaxonValue((prev) => ({
        ...prev,
        englishNames: val.length !== 0 ? val.split(',') : [],
      }));
    } else {
      setTaxonValue((prev) => ({ ...prev, [key]: val }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = TaxonDetailsFormValidation(taxonValue);
    if (!_.isEmpty(formErrors)) {
      onError('Please fix the errors in the form');
      setErrors(formErrors);
      return;
    }
    updateTaxon(taxonValue.taxonId, {
      ...taxonValue,
      englishNames: taxonValue.englishNames || [''],
    })
      .then(() => {
        setTaxon({
          ...taxonValue,
        });
        setMatrixId(taxonValue.taxonomyMatrixId);

        onInfo('Successfully updated taxon');
      })
      .catch((e) => onError(e.message));
  };

  const transformNodeTypeArr = () =>
    nodeTypes.map((nodeType) => ({
      label: nodeType.name,
      value: nodeType.nodeTypeId,
    }));

  const renderTitle = () => (
    <Typography variant="h6">
      {isEditEnabled ? 'Edit Taxon' : 'Taxon'}
    </Typography>
  );

  if (!nodeTypes) {
    return <LinearProgress />;
  }

  return (
    <div className={classes.contentContainer}>
      {renderTitle()}
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          translationKey="taxonomy.taxonId"
          value={taxonValue.taxonId}
          permission="RO"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          translationKey="taxonomy.originTaxonomy"
          name="originTaxonomy"
          value={taxonomy.taxonomyName}
          permission="RO"
        />
      </Grid>
      <TaxonDetailsForm
        permission={isEditEnabled ? 'RW' : 'RO'}
        isEnabled
        onChange={handleTaxonChange}
        onSubmit={handleSubmit}
        taxon={taxonValue}
        nodeTypes={transformNodeTypeArr()}
        errors={errors}
        taxonomyDetails={{
          referenceUrl: taxonomy.referenceUrl,
          code: taxonomy.taxonomyCode,
        }}
      />
      {isEditEnabled && (
        <SaveButton
          disabled={_.isEqual(taxonValue, taxon)}
          onClick={handleSubmit}
        />
      )}
    </div>
  );
};

export default TaxonEditForm;
