import { Fragment } from 'react';
import { Delete, Edit, Link } from '@onc/icons';
import { oncDefaultTheme } from '@onc/theme';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from 'base-components';
import { NodeType } from 'domain/AppComponents/multi-queue/CreateShorestationDialog';
import DateFormatUtils from 'util/DateFormatUtils';
import ObjectUtils from 'util/ObjectUtils';
import AnnotationActions from './AnnotationActions';

const FULL_TIMESTAMP_WIDTH = 133;
const DEFAULT_TIMESTAMP_WIDTH = 36;
const ICON_WIDTH = 26;
const ANNOTATION_PADDING_WIDTH = 16;

const generateLocationString = (lat, lon, heading, depth) => {
  let coordinateText = '';
  let elevationText = '';

  if (!lat && !lon && !depth && !heading) {
    return undefined;
  }

  if (lat === 0 && lon === 0) {
    coordinateText = 'LAT LON: N/A';
  } else {
    // LATITUDE & LONGITUDE
    coordinateText = `LAT LON: ${Number.parseFloat(lat).toFixed(
      6
    )}, ${Number.parseFloat(lon).toFixed(6)}, `;
  }

  // DEPTH
  elevationText = 'Depth: ';
  elevationText += depth !== 0 ? `${Math.round(depth)}m` : 'N/A';

  // HEADING
  elevationText += ', Heading: ';
  elevationText += heading !== 0 ? `${heading}°` : 'N/A';

  return `${coordinateText} ${elevationText}`;
};

const getAnnotationContentWidth = (iconArray, fullTimestamp, review) => {
  let buffer = 0;
  // Timestamp
  if (fullTimestamp) {
    buffer += FULL_TIMESTAMP_WIDTH;
  } else {
    buffer += DEFAULT_TIMESTAMP_WIDTH;
  }

  // Inner left padding
  buffer += ANNOTATION_PADDING_WIDTH; // Padding between time and content

  // Icons
  const iconsShowing = iconArray.filter(Boolean).length;
  if (iconsShowing > 0) {
    // Action icon padding
    buffer += ANNOTATION_PADDING_WIDTH;

    if (iconsShowing > 2) {
      // Compression into a single three-dot menu
      buffer += ICON_WIDTH;
    } else {
      // However many icons there are
      buffer += iconsShowing * ICON_WIDTH;
    }
  }
  // Vote Icons
  if (review) {
    buffer += 2 * ICON_WIDTH;
  }
  return buffer;
};

const classes = {
  lineBase: {
    cursor: 'pointer',
  },
  selected: {
    background: '#c5debb !important',
  },

  toBeReviewed: {
    background: '#ea9999',
  },
  lastEdited: {
    background: oncDefaultTheme.palette.primary.main, // light blue
  },
  editing: {
    backgroundColor: oncDefaultTheme.palette.primary.light, // lighter blue
  },
  attributes: {
    display: 'inline-block',
    textIndent: 2,
  },
  blockSpan: {
    display: 'block',
  },
  annotationTimestamp: {
    marginRight: '16px',
    minWidth: '0',
    marginTop: '0',
    position: 'relative',
    top: '50%',
  },
  timestamp: {
    color: oncDefaultTheme.palette.grey[700],
  },
  listItemTextRoot: {
    marginBottom: '0',
    marginTop: '0',
    flex: 'none',
    wordWrap: 'break-word',
  },
  tooltip: {
    display: 'flex',
    width: '100%',
  },
};

type AnnotationLineProps = {
  annotation: {
    annotationId: number;
    comment: string;
    createdBy: {
      firstName: string;
      lastName: string;
    };
    createdDate: string;
    depth: number;
    heading: number;
    lat: number;
    lon: number;
    modifiedBy: {
      email: string;
      firstName: string;
      lastName: string;
    };
    displayText: NodeType;
    modifiedDate: string;
    startDate: string;
    taxons: [
      {
        attributes: [
          {
            name: string;
            value: string;
          },
        ];
        taxonomyCode: string;
        annotationReview: {
          reviewValue: string;
        };
      },
    ];
    toBeReviewed: boolean;
  };
  key?: number;
  onAnnotationClick?: () => void; // ?
  onAnnotationEditClick?: (annotation: object) => void; // ?
  onAnnotationDeleteClick?: (annotation: object) => void; // ?
  selected?: boolean;
  displayFullTimestamp?: boolean;
  displayPositionalData?: boolean;
  displayModifiedBy?: boolean;
  displayCopyLink?: boolean;
  displayEditIcon?: boolean;
  displayDeleteIcon?: boolean;
  displayVoteIcons?: boolean;
  displayCreatedBy?: boolean;
  displayTaxonAttributes?: boolean;
  highlightToBeReviewed?: boolean;
  deletePermissions?: boolean;
  annotationPermissions?: boolean;
  reviewPermissions?: boolean;
  onCopyLink?: (annotation: object) => void; // ?
  onReviewClick?: () => void; // ?
  style?: {
    height: number;
    left: number;
    position: string;
    top: number;
    width: string;
  };
  lastEditedAnnotationId?: number;
  editAnnotationId?: number;
};

const AnnotationLine = ({
  key = undefined,
  onAnnotationClick = undefined,
  onAnnotationEditClick = undefined,
  onAnnotationDeleteClick = undefined,
  onCopyLink = undefined,
  onReviewClick = undefined,
  selected = false,
  displayFullTimestamp = false,
  displayPositionalData = false,
  displayModifiedBy = false,
  displayCopyLink = true,
  displayEditIcon = false,
  displayDeleteIcon = false,
  displayVoteIcons = false,
  displayCreatedBy = false,
  displayTaxonAttributes = false,
  highlightToBeReviewed = false,
  deletePermissions = false,
  annotationPermissions = false,
  reviewPermissions = false,
  style = undefined,
  lastEditedAnnotationId = undefined,
  editAnnotationId = undefined,
  annotation,
}: AnnotationLineProps) => {
  const getContentStyle = () => {
    const buffer = getAnnotationContentWidth(
      [displayCopyLink, displayEditIcon, displayDeleteIcon],
      displayFullTimestamp,
      displayVoteIcons &&
        annotation.taxons &&
        annotation.taxons[0].taxonomyCode === 'WoRMS'
    );
    return { width: `calc(100% - ${buffer}px` };
  };

  const sortAttributes = (attributes) =>
    attributes.sort((a, b) => (a.name < b.name ? -1 : 1));

  const renderAnnotationActions = () => {
    const { taxons } = annotation;
    const options = [];
    // Copy Link
    if (displayCopyLink) {
      options.push({
        label: 'Copy Link',
        onClick: () => onCopyLink(annotation),
        icon: Link,
      });
    }
    // Edit Action
    if (annotationPermissions && displayEditIcon) {
      options.push({
        label: 'Edit Annotation',
        onClick: () => onAnnotationEditClick(annotation),
        icon: Edit,
      });
    }
    // Delete Action
    if (deletePermissions && displayDeleteIcon) {
      options.push({
        label: 'Delete Annotation',
        onClick: () => onAnnotationDeleteClick(annotation),
        icon: Delete,
      });
    }

    let reviewProps;
    if (
      reviewPermissions &&
      displayVoteIcons &&
      taxons &&
      taxons[0].taxonomyCode === 'WoRMS'
    ) {
      const { annotationReview } = taxons[0];
      reviewProps = {
        annotationId: annotation.annotationId,
        reviewValue: annotationReview
          ? annotationReview.reviewValue.toLowerCase()
          : undefined,
        onReviewClick,
      };
    }
    return <AnnotationActions options={options} {...reviewProps} />;
  };

  const renderTaxon = () => {
    const { taxons, displayText } = annotation;

    let taxonText;
    let attributeText;

    if (taxons) {
      taxonText = (
        <Typography variant="body2" sx={classes.blockSpan}>
          {displayText}
        </Typography>
      );
      if (taxons[0].attributes && displayTaxonAttributes) {
        const attributes = ObjectUtils.deepClone(taxons[0].attributes);
        attributeText = sortAttributes(attributes).map(({ name, value }) => (
          <Fragment key={`${name}-${value}`}>
            <Box sx={classes.attributes}>{`${name}: ${value}`}</Box>
            <br />
          </Fragment>
        ));
        attributeText = (
          <Typography variant="body2">{attributeText}</Typography>
        );
      }

      return (
        <div>
          {taxonText}
          {attributeText}
        </div>
      );
    }

    return null;
  };

  const renderComment = () => {
    let finalComment = annotation.comment;
    if (annotation.comment === '') {
      if (!annotation.taxons) finalComment = '-';
      else return undefined;
    }
    return (
      <div>
        <Typography variant="body2">
          <Box sx={classes.blockSpan}>{finalComment}</Box>
        </Typography>
      </div>
    );
  };

  const renderUserText = () => {
    if (!displayCreatedBy && !displayModifiedBy) return undefined;

    const { createdBy, createdDate, modifiedBy, modifiedDate } = annotation;
    const creator = displayCreatedBy ? (
      <Box sx={classes.blockSpan}>
        <Typography variant="caption">{`Created By: ${createdBy.firstName} ${
          createdBy.lastName
        } (${DateFormatUtils.formatDate(createdDate, 'full')})`}</Typography>
      </Box>
    ) : undefined;

    const modifier =
      displayModifiedBy && createdDate !== modifiedDate ? (
        <Box sx={classes.blockSpan}>
          <Typography variant="caption">
            {`Modified By: ${modifiedBy.firstName} ${
              modifiedBy.lastName
            } (${DateFormatUtils.formatDate(modifiedDate, 'full')})`}
          </Typography>
        </Box>
      ) : undefined;

    return (
      <div>
        {creator}
        {modifier}
      </div>
    );
  };

  const renderPositionalTags = () => {
    const { lat, lon, depth, heading } = annotation;

    if (!displayPositionalData) {
      return undefined;
    }

    const positionText = generateLocationString(lat, lon, heading, depth);

    return (
      <div>
        <Box sx={classes.blockSpan}>
          <Typography variant="caption">
            <span>{positionText}</span>
          </Typography>
        </Box>
      </div>
    );
  };

  const renderDisplayText = () => (
    <>
      {renderTaxon()}
      {renderComment()}
      {renderPositionalTags()}
      {renderUserText()}
    </>
  );

  const timeFormat = displayFullTimestamp ? 'full' : 'time-no-seconds';
  const timestamp = DateFormatUtils.formatDate(
    annotation.startDate,
    timeFormat
  );

  const annotationClasses = {
    ...classes.lineBase,

    ...(selected //
      ? classes.selected
      : {}),

    ...(annotation.toBeReviewed && highlightToBeReviewed //
      ? classes.toBeReviewed
      : {}),

    ...(annotation.annotationId === editAnnotationId //
      ? classes.editing
      : {}),

    ...(annotation.annotationId === lastEditedAnnotationId //
      ? classes.lastEdited
      : {}),
  };

  const handleAnnotationActionsClick = (e) => e.stopPropagation();

  return (
    <ListItem
      style={style}
      sx={annotationClasses}
      key={key}
      divider
      alignItems="flex-start"
      onClick={onAnnotationClick}
    >
      <Tooltip
        id="jump-tooltip"
        title={`Jump to ${timestamp}`}
        placement="left"
        disableTouchListener
        disableFocusListener
      >
        <Box sx={classes.tooltip}>
          <ListItemIcon sx={classes.annotationTimestamp}>
            <Typography variant="body2" sx={classes.timestamp}>
              {timestamp}
            </Typography>
          </ListItemIcon>
          <ListItemText
            sx={classes.listItemTextRoot}
            style={getContentStyle()}
            primary={renderDisplayText()}
          />
        </Box>
      </Tooltip>
      <ListItemSecondaryAction onClick={handleAnnotationActionsClick}>
        {renderAnnotationActions()}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export { AnnotationLine, getAnnotationContentWidth, generateLocationString };
