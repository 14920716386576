import Environment from '@onc/environment';
import FormFieldParser from './util/FormFieldParser';

class AnnotationUtil {
  static getSourceId = (sourceFilter) => {
    let sources = '';
    // get key: value pairs
    for (const key in sourceFilter) {
      if (sourceFilter[key]) {
        if (sources === '') {
          sources += key;
        } else {
          sources += `,${key}`;
        }
      }
    }
    return sources;
  };

  static sortFilterValues = (filter, sorting) => {
    const updatedFilter = { ...filter };
    if (filter) {
      // may need to add
      if (filter.sourceFilter) {
        if (!updatedFilter.annotationSources) {
          updatedFilter.annotationSources = '';
        }
        updatedFilter.annotationSources += AnnotationUtil.getSourceId(
          filter.sourceFilter
        );
      }
      if (filter.resourceFilter) {
        updatedFilter.resourceTypeId = filter.resourceFilter.resourceTypeId;
        updatedFilter.includeTopology = filter.resourceFilter.includeTopology;
        if (filter.resourceFilter.resource) {
          updatedFilter.resourceId = filter.resourceFilter.resource.id;
        }
      }
      // only update filter date when it is valid
      if (filter.dateFilter) {
        if (Date.parse(filter.dateFilter.fromDate)) {
          updatedFilter.fromDate = filter.dateFilter.fromDate;
        }
        if (Date.parse(filter.dateFilter.toDate)) {
          updatedFilter.toDate = filter.dateFilter.toDate;
        }
      }
      if (filter.ownerFilter) {
        if (filter.ownerFilter.owner) {
          updatedFilter.userId =
            filter.ownerFilter.owner === 'myAnnotations'
              ? Environment.getDmasUserId()
              : undefined;
        }
        if (filter.ownerFilter.isShared !== undefined) {
          if (filter.ownerFilter.isShared === filter.ownerFilter.isPrivate) {
            // if both items are the same leave undefined for 'all'
            updatedFilter.published = undefined;
          } else {
            // if shared, published is Y, if private it's N
            updatedFilter.published = filter.ownerFilter.isShared ? 'Y' : 'N';
          }
        }
        if (filter.ownerFilter.isFlagged !== undefined) {
          if (filter.ownerFilter.isFlagged === filter.ownerFilter.isUnflagged) {
            // if both items are the same leave undefined for 'all'
            updatedFilter.flagged = undefined;
          } else {
            updatedFilter.flagged = filter.ownerFilter.isFlagged ? 'Y' : 'N';
          }
        }
      }
      // minimum requirements to get service to work
      if (filter && filter.fieldFilter && filter.fieldFilter.length > 0) {
        updatedFilter.fields = FormFieldParser.buildServiceString(
          filter.fieldFilter
        );
        delete updatedFilter.fieldFilter;
      }
      updatedFilter.levelIndexes = '0,1,2,3,4,5';
    }
    // if multiple columns need to be sorted on this just needs to be updated
    if (
      sorting &&
      sorting.length > 0 &&
      sorting[0].columnName &&
      sorting[0].direction
    ) {
      updatedFilter.orderBy = sorting[0].columnName;
      updatedFilter.order = sorting[0].direction;
    }
    // if left in the filter these cause the service call to fail.
    delete updatedFilter.ownerFilter;
    delete updatedFilter.dateFilter;
    delete updatedFilter.sourceFilter;
    delete updatedFilter.resourceFilter;
    return updatedFilter;
  };
}
export default AnnotationUtil;
