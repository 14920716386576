import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import { QaqcTestGroup } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsTestGroupDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    testGroupTypeId: PropTypes.number.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    testGroups: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getTestGroups();
  }

  getTestGroup = () => {
    const { value } = this.props;
    const { testGroups } = this.state;
    const currQaqcTestGroup = testGroups.find(
      (qaqcTestGroup) => qaqcTestGroup.value === value
    );

    if (!currQaqcTestGroup || value === 0) return '';
    return currQaqcTestGroup.label;
  };

  getTestGroups = () => {
    const { testGroupTypeId } = this.props;
    QaqcTestDetailsService.getQAQCTestGroups(testGroupTypeId)
      .then((results) => {
        const testGroups = results.map((testGroup) => ({
          label: `${testGroup.name} (${testGroup.qaqcTestGroupId})`,
          value: testGroup.qaqcTestGroupId,
        }));
        this.setState({
          testGroups: [{ label: 'Select', value: 0 }, ...testGroups],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      value,
      onChange,
      ...rest
    } = this.props;
    const { testGroups } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Qaqc Test Group"
          options={testGroups}
          value={value}
          onChange={onChange}
          {...rest}
        />
      );
    }

    return (
      <QaqcTestGroup
        classes={{ root: classes.roFieldRoot }}
        qaqcTestGroup={this.getTestGroup()}
      />
    );
  }
}
export default QaqcDetailsTestGroupDropdown;
