import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import DatasetLandingPage from './DatasetLandingPage';

class DatasetLandingPageContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const params = this.parseURLParams();
    this.state = {
      doidataset: params.doidataset,
      queryPid: params.queryPid,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => this.updatePage());
  }

  parseURLParams = () => {
    const { history } = this.props;
    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  };

  updatePage() {
    const params = this.parseURLParams();
    this.setState({
      doidataset: params.doidataset,
      queryPid: params.queryPid,
    });
  }

  render() {
    const { history, match } = this.props;
    const { doidataset, queryPid } = this.state;
    return (
      <DatasetLandingPage
        doidataset={doidataset}
        queryPid={queryPid}
        history={history}
        match={match}
      />
    );
  }
}

export default DatasetLandingPageContainer;
