import { useEffect } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { CancelButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
} from 'base-components';
import {
  DeploymentDataSource,
  LocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import ChartWidgetConfigContent, {
  TimeSeriesScalarDataChartConfigType,
} from 'domain/Widgets/Config/ChartWidgetConfigContent';
import Form from 'library/CompositeComponents/form/Form';

export type ChartWidgetConfigValues = {
  autoRefreshEnabled: boolean;
  chartTitle: string;
  datePreset: string;
  dateSelection: 'dateRange' | 'latest';
  isMinMaxEnabled: boolean;
  isRawCleanEnabled?: boolean;
  showHeader?: boolean;
  showChartTitle: boolean;
  showWidgetTitle: boolean;
  widgetTitle: string;
  endDate?: Date;
  startDate?: Date;
  locationsLeftAxis?: Array<LocationDataSource | DeploymentDataSource>;
  locationsRightAxis?: Array<LocationDataSource | DeploymentDataSource>;
  displayQaqc: boolean;
};

type ChartWidgetConfigProps = {
  onCancel: () => void;
  onConfigFinish: () => void;
  onSave: (values: TimeSeriesScalarDataChartConfigType) => void;
  open: boolean;
} & ChartWidgetConfigValues;

const ChartWidgetConfig: React.FC<ChartWidgetConfigProps> = ({
  onCancel,
  onConfigFinish,
  onSave,
  open,
  endDate = undefined,
  startDate = undefined,
  ...formDefaultValues
}: ChartWidgetConfigProps) => {
  const formMethods = useForm<TimeSeriesScalarDataChartConfigType>({
    defaultValues: {
      ...formDefaultValues,
      endDate: endDate ? moment(endDate) : undefined,
      startDate: startDate ? moment(startDate) : undefined,
    },
    mode: 'onBlur',
  });

  const {
    formState: { isSubmitting },
    watch,
    setValue,
  } = formMethods;

  const locationsLeftAxis = watch('locationsLeftAxis');
  const locationsRightAxis = watch('locationsRightAxis');

  const numberOfDataSources =
    (locationsLeftAxis?.length ?? 0) + (locationsRightAxis?.length ?? 0);

  useEffect(() => {
    if (numberOfDataSources > 1) {
      setValue('displayQaqc', false);
    }
  }, [numberOfDataSources, setValue]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-label="Configure Chart Widget"
      maxWidth="lg"
    >
      <DialogTitle>Configure Chart Widget</DialogTitle>
      <DialogContent>
        <Form
          formMethods={formMethods}
          onSubmit={async (x) => {
            await onSave(x);
            onConfigFinish();
          }}
        >
          <ChartWidgetConfigContent formMethods={formMethods} />
          <DialogActions>
            <CancelButton onClick={onCancel} />
            <LoadingButton
              disabled={numberOfDataSources === 0}
              variant="contained"
              translationKey="common.buttons.save"
              type="submit"
              loading={isSubmitting}
            />
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ChartWidgetConfig;
