import { useState } from 'react';
import PropTypes from 'prop-types';
import { ContainedButton, TextButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from 'base-components';

import EnvironmentSelector from './EnvironmentSelector';
import IssueDescriptionField from './IssueDescriptionField';
import RequestTypeDropdown from './RequestTypeDropdown';
import UserAffiliationField from './UserAffiliationField';

const SupportRequestForm = (props) => {
  const { jiraDomain, user, requestTypes, open, ready, onSubmit, onClose } =
    props;

  const environment = {
    location: window.location.href,
    referrer: window.document.referrer,
    userAgent: navigator.userAgent,
    resolution: `${window.innerWidth} x ${window.innerHeight}`,
  };

  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [requestType, setRequestType] = useState('');
  const [userAffiliation, setUserAffiliation] = useState(
    user.email.endsWith('noaa.gov') ? 'NOAA' : ''
  );
  const [includeEnv, setIncludeEnv] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!ready) return;
    if (!email || !summary) return;

    const descriptionStr = `${description}\n\n*Name* ${name}\n*Email* ${email}`;

    const locationStr = `*Location*: ${environment.location}`;
    const environmentStr = `${locationStr}\n*Referrer*: ${environment.referrer}\n*User-Agent*: ${environment.userAgent}\n*Screen Resolution*: ${environment.resolution}`;

    onSubmit('OC', summary, {
      requestTypeId: requestType,
      description: descriptionStr,
      environment: includeEnv ? environmentStr : locationStr,
      userAffiliation,
    });
  };

  return (
    // FIXME use a FormDialog?
    <Dialog open={open}>
      <DialogTitle>Request Support</DialogTitle>
      <form aria-label="Request Support" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                translationKey="common.textfields.name"
                title="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>

            <Grid item>
              <TextField
                translationKey="common.textfields.email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item>
              <RequestTypeDropdown
                requestTypes={requestTypes}
                requestType={requestType}
                onChange={setRequestType}
              />
            </Grid>

            <Grid item>
              <TextField
                translationKey="support.summary"
                fullWidth
                required
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
            </Grid>

            <Grid item>
              <IssueDescriptionField
                jiraDomain={jiraDomain}
                description={description}
                onChange={setDescription}
              />
            </Grid>

            <Grid item>
              <UserAffiliationField
                affiliation={userAffiliation}
                onChange={setUserAffiliation}
              />
            </Grid>

            <EnvironmentSelector
              environment={environment}
              value={includeEnv}
              onToggle={setIncludeEnv}
            />

            <Typography>
              * If you have a technical problem, consider keeping a screen shot
              image at hand to share with us when we follow up with you.
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ContainedButton
                translationKey="common.buttons.submit"
                type="submit"
                disabled={!ready}
              />
            </Grid>
            <Grid item>
              <TextButton
                translationKey="common.buttons.close"
                onClick={onClose}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SupportRequestForm.propTypes = {
  jiraDomain: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  requestTypes: PropTypes.arrayOf(
    PropTypes.shape({
      supportRequestTypeId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,

  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SupportRequestForm;
