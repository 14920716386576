import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Box } from 'base-components';
import ChartUtils from 'domain/AppComponents/charts/ChartUtils';
import TimeSeriesScalarDataChart from 'domain/AppComponents/charts/TimeSeriesScalarDataChart';
import {
  type ChartQuality,
  ScalarDataChartDeploymentSource,
  ScalarDataChartLocationSource,
} from 'domain/AppComponents/charts/types/TimeSeriesScalarDataChart.types';
import { isLocationDataSource } from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import { WidgetTitle } from 'domain/AppComponents/Dashboard/Titles';
import {
  WidgetConfigError,
  getWidgetConfigErrorMessage,
} from 'domain/AppComponents/Dashboard/widget-error/WidgetConfigError';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import type {
  LocationDataSource,
  DeploymentDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import type { DateRange } from 'domain/hooks/useDateRange';

type ChartWidgetDisplayProps = {
  showWidgetTitle: boolean;
  showChartTitle: boolean;
  widgetTitle: string;
  chartTitle: string;
  alwaysShowModebar: boolean;
  isMinMaxEnabled: boolean;
  isRawCleanEnabled: boolean;
  onError: (message: string) => void;
  isleftOverride?: boolean;
  isrightOverride?: boolean;
  locationsLeftAxis: Array<LocationDataSource | DeploymentDataSource>;
  locationsRightAxis?: Array<LocationDataSource | DeploymentDataSource>;
  startDate?: Date;
  endDate?: Date;
  dateSelection?: string;
  datePreset?: string;
  autoRefreshEnabled?: boolean;
  displayQaqc?: boolean;
  leftmin?: string;
  leftmax?: string;
  rightmin?: string;
  rightmax?: string;
  onSettingsClick?: () => void;
  onRemoveClick?: () => void;
  onChangeDateRange?: (range: DateRange) => void;
  enableTraceLegend?: boolean;
};

const buildDateRange = (
  datePreset?: string,
  dateSelection?: string,
  startDate?: Date,
  endDate?: Date
): DateRange => {
  if (datePreset !== undefined && dateSelection === 'latest') {
    const dateTo = new Date();
    let dateFrom = moment(dateTo);
    switch (datePreset) {
      case 'tenMinutes':
        dateFrom = dateFrom.subtract(10, 'minutes');
        break;
      case 'twoHours':
        dateFrom = dateFrom.subtract(2, 'hours');
        break;
      case 'day':
        dateFrom = dateFrom.subtract(1, 'day');
        break;
      case 'sevenDays':
        dateFrom = dateFrom.subtract(7, 'days');
        break;
      default:
        break;
    }
    return [moment.utc(dateFrom), moment.utc(dateTo)];
  }
  return [moment.utc(startDate), moment.utc(endDate)];
};

const getInvalidFields = (dateRange: DateRange) => {
  const invalidFields: string[] = [];
  if (!dateRange || dateRange[0].format() === 'Invalid date') {
    invalidFields.push('Start Date');
  }
  if (!dateRange || dateRange[1].format() === 'Invalid date') {
    invalidFields.push('End Date');
  }
  return invalidFields;
};

const getSource = (
  source: LocationDataSource | DeploymentDataSource,
  axisSide: 'left' | 'right',
  fallbackPropertyColour: string = '#000000'
): ScalarDataChartLocationSource | ScalarDataChartDeploymentSource => {
  if (isLocationDataSource(source)) {
    return {
      locationCode: source.stationCode,
      deviceCategoryCode: source.deviceCategoryCode,
      deviceCategoryName: source.deviceCategoryName,
      datasetReference: source.datasetRef,
      propertyCode: source.sensorTypeCode,
      // source.traceName added in DMAS-66576, for sources created prior to that fall back on source.name
      traceName: source.traceName ? source.traceName : source.name,
      traceColour: source.traceColour
        ? source.traceColour
        : fallbackPropertyColour,
      // source.sensorName added in DMAS-66576, for sources created prior to that fall back on the last pathName
      sensorName: source.sensorName
        ? source.sensorName
        : source.pathName[source.pathName.length - 1],
      initialQualityControl: 'clean',
      yaxisSide: axisSide,
      type: source.dataSourceType,
      dataSource: source,
    };
  }
  return {
    deviceCode: source.deviceCode,
    sensorCategoryCode: source.sensorCategoryCode,
    propertyCode: source.sensorTypeCode,
    // source.traceName added in DMAS-66576, for sources created prior to that fall back on source.name
    traceName: source.traceName ? source.traceName : source.name,
    traceColour: source.traceColour
      ? source.traceColour
      : fallbackPropertyColour,
    // source.sensorName added in DMAS-66576, for sources created prior to that fall back on source.name
    sensorName: source.sensorName ? source.sensorName : source.name,
    initialQualityControl: 'clean',
    yaxisSide: axisSide,
    device: source.device,
    type: source.dataSourceType,
    dateFrom: source.dateFrom ? moment.utc(source.dateFrom) : undefined,
    dateTo: source.dateTo ? moment.utc(source.dateTo) : undefined,
    dataSource: source,
  };
};

const buildSources = (
  locationsLeftAxis: (LocationDataSource | DeploymentDataSource)[],
  locationsRightAxis: (LocationDataSource | DeploymentDataSource)[]
) => {
  const sources: (LocationDataSource | DeploymentDataSource)[] = [
    ...(locationsLeftAxis || []),
    ...(locationsRightAxis || []),
  ];

  const fallbackPropertyColours: string[] = ChartUtils.getPropertyColors(
    sources.map((source) => source.sensorTypeCode)
  );

  const retVal: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  > = [];

  if (locationsLeftAxis) {
    for (const prop of locationsLeftAxis) {
      // Find the index of this property in the combined sources array
      const sourceIndex = sources.indexOf(prop);
      const fallbackPropertyColour = fallbackPropertyColours[sourceIndex];
      retVal.push(getSource(prop, 'left', fallbackPropertyColour));
    }
  }

  if (locationsRightAxis) {
    for (const prop of locationsRightAxis) {
      // Find the index of this property in the combined sources array
      const sourceIndex = sources.indexOf(prop);
      const fallbackPropertyColour = fallbackPropertyColours[sourceIndex];
      retVal.push(getSource(prop, 'right', fallbackPropertyColour));
    }
  }

  return retVal;
};

const ChartWidgetDisplay: React.FC<ChartWidgetDisplayProps> = ({
  showWidgetTitle,
  showChartTitle,
  widgetTitle,
  chartTitle,
  alwaysShowModebar,
  isMinMaxEnabled,
  isRawCleanEnabled,
  isleftOverride = false,
  isrightOverride = false,
  onError,
  locationsLeftAxis,
  locationsRightAxis = undefined,
  startDate = undefined,
  endDate = undefined,
  dateSelection = undefined,
  datePreset = undefined,
  autoRefreshEnabled = undefined,
  displayQaqc = false,
  leftmin = undefined,
  leftmax = undefined,
  rightmin = undefined,
  rightmax = undefined,
  onSettingsClick = undefined,
  onRemoveClick = undefined,
  onChangeDateRange = undefined,
  enableTraceLegend = true,
}) => {
  if (!locationsLeftAxis && !locationsRightAxis) {
    onError('No Data found');
  }
  const [chartQuality, setChartQuality] = useState<ChartQuality | undefined>(
    'clean'
  );
  const dateRange = buildDateRange(
    datePreset,
    dateSelection,
    startDate,
    endDate
  );

  const sources = useMemo(
    () => buildSources(locationsLeftAxis, locationsRightAxis),
    [locationsLeftAxis, locationsRightAxis]
  );
  const chartTitleWithDate = showChartTitle ? chartTitle : '';

  const invalidFields = getInvalidFields(dateRange);
  // Cast min/max limits to numbers, otherwise use nulls for plotly's dynamic axis ranges
  const toNumberOrNull = (value: string) => {
    if (value === '') return null;
    const num = Number(value);
    return isNaN(num) ? null : num;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {showWidgetTitle ? <WidgetTitle titleText={widgetTitle} /> : null}
      {invalidFields.length > 0 ? (
        <WidgetConfigError
          message={getWidgetConfigErrorMessage(invalidFields)}
        />
      ) : (
        <TimeSeriesScalarDataChart
          alwaysShowModebar={alwaysShowModebar}
          isMinMaxEnabled={isMinMaxEnabled}
          isRawCleanEnabled={isRawCleanEnabled}
          sources={sources}
          initialDateRange={dateRange}
          title={chartTitleWithDate}
          autoRefresh={autoRefreshEnabled}
          displayQaqc={displayQaqc}
          leftmin={isleftOverride ? toNumberOrNull(leftmin) : undefined}
          leftmax={isleftOverride ? toNumberOrNull(leftmax) : undefined}
          rightmin={isrightOverride ? toNumberOrNull(rightmin) : undefined}
          rightmax={isrightOverride ? toNumberOrNull(rightmax) : undefined}
          onSettingsClick={onSettingsClick}
          onRemoveClick={onRemoveClick}
          onChangeDateRange={onChangeDateRange}
          chartQuality={chartQuality}
          setChartQuality={setChartQuality}
          enableTraceLegend={enableTraceLegend}
        />
      )}
    </Box>
  );
};

export default withSnackbars(ChartWidgetDisplay);
