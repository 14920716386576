import { Toggle, ToggleProps } from 'base-components';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldType } from './FormFieldType';

type FormToggleProps = FormFieldType<ToggleProps>;

const FormToggle: React.FC<FormToggleProps> = ({
  name,
  rules,
  ...rest
}: FormToggleProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => <Toggle {...rest} {...field} />}
    />
  );
};

export default FormToggle;
