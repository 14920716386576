import PropTypes from 'prop-types';
import { CancelButton, DeleteButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from 'base-components';

const SiteDeviceSubsetDeleteDialog = (props) => {
  SiteDeviceSubsetDeleteDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    siteDeviceSubsetId: PropTypes.number.isRequired,
  };

  const { onCancel, onDelete, siteDeviceSubsetId } = props;

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>
        Warning: You are about to permanently delete this site device subset and
        its associated aligned and depth binned scalar data
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to permanently delete Site Device Subset Id ${siteDeviceSubsetId}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => onCancel()} />
        <DeleteButton variant="contained" onClick={() => onDelete()} />
      </DialogActions>
    </Dialog>
  );
};

export default SiteDeviceSubsetDeleteDialog;
