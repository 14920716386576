import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  ContainedButton,
  FloatRightButtonLowStyles,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import SiteDeviceService from 'domain/services/SiteDeviceService';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const styles = {
  button: FloatRightButtonLowStyles,
};

const headers = [
  { title: 'Site Device Id', name: 'siteDeviceId' },
  { title: 'Site Name', name: 'siteName' },
  { title: 'Date From', name: 'dateFrom' },
  { title: 'Has ERDDAP', name: 'datasetsInErddap' },
  { title: 'Offset Latitude', name: 'offsetLatitude' },
  { title: 'Offset Longitude', name: 'offsetLongitude' },
  { title: 'Offset Depth (m)', name: 'offsetDepth' },
  { title: 'Offset Heading °', name: 'offsetHeading' },
  { title: 'Offset Pitch °', name: 'offsetPitch' },
  { title: 'Offset Roll °', name: 'offsetRoll' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
];

const tableColumnExtensions = [
  {
    columnName: 'siteDeviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'siteName',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'dateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'datasetsInErddap',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'offsetLatitude',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'offsetLongitude',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'offsetDepth',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'offsetHeading',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'offsetPitch',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'offsetRoll',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class SitePanel extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }).isRequired,
  };

  defaultSorting = [
    {
      columnName: 'dateFrom',
      direction: 'desc',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId } = this.props;
    this.setState({ isLoading: true });
    SiteDeviceService.getMany(deviceId)
      .then((result) => {
        this.setState({ isLoading: false });
        this.buildTableRows(result);
      })
      .catch((result) => {
        this.setState({ isLoading: false });
        onError(result);
      });
  };

  actionContent = () => {
    const { deviceId, permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const SiteDeviceRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return <SiteDeviceRefreshButton />;
    }
    return (
      <>
        <SiteDeviceRefreshButton />
        <a href={`${Environment.getDmasUrl()}/SiteDevice?deviceId=${deviceId}`}>
          <ContainedButton
            translationKey="device.addSiteDevice"
            startIcon={<Add />}
            className={classes.button}
          />
        </a>
      </>
    );
  };

  siteDeviceLink = (siteDeviceId) => (
    <a
      href={`${Environment.getDmasUrl()}/SiteDevice?siteDeviceId=${siteDeviceId}`}
    >
      {siteDeviceId}
    </a>
  );

  siteLink = (siteId, siteName) => (
    <a href={`${Environment.getDmasUrl()}/Sites?siteId=${siteId}`}>
      {siteName}
    </a>
  );

  buildTableRows = (sites) => {
    this.setState({
      tableRows: sites.map((site) => {
        const siteFor = {
          ...site,
          siteDeviceId: this.siteDeviceLink(site.siteDeviceId),
          siteName: this.siteLink(site.siteId, site.siteName),
          dateFrom: DateFormatUtils.formatDate(site.dateFrom, 'full'),
          datasetsInErddap: site.datasetsInErddap ? 'True' : 'False',
          offsetLatitude: !isNaN(site.offsetLatitude)
            ? Number(site.offsetLatitude).toFixed(10)
            : undefined,
          offsetLongitude: !isNaN(site.offsetLongitude)
            ? Number(site.offsetLongitude).toFixed(10)
            : undefined,
          offsetDepth: !isNaN(site.offsetDepth)
            ? Number(site.offsetDepth).toFixed(10)
            : undefined,
          offsetHeading: !isNaN(site.offsetHeading)
            ? Number(site.offsetHeading).toFixed(10)
            : undefined,
          offsetPitch: !isNaN(site.offsetPitch)
            ? Number(site.offsetPitch).toFixed(10)
            : undefined,
          offsetRoll: !isNaN(site.offsetRoll)
            ? Number(site.offsetRoll).toFixed(10)
            : undefined,
          modifyDate: DateFormatUtils.formatDate(site.modifyDate, 'full'),
        };

        return siteFor;
      }),
    });
  };

  compareLink = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    if (Number(idA) && Number(idB)) {
      return Number(idA) < Number(idB) ? -1 : 1;
    }
    return idA < idB ? -1 : 1;
  };

  render() {
    const { permission } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <Panel
        title={<Typography variant="h6">Site Device List</Typography>}
        actionContent={this.actionContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          defaultSorting={this.defaultSorting}
          elevation={0}
          pageSize={20}
          rows={tableRows}
          searchable
          searchBarMoveable={permission === 'RW'}
          sortExtensions={[
            { columnName: 'siteDeviceId', compare: this.compareLink },
            { columnName: 'siteName', compare: this.compareLink },
          ]}
          stripedRows
          isLoading={isLoading}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(SitePanel);
