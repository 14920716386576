import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class CruiseDropdown extends Component {
  static propTypes = {
    validation: PropTypes.func,
    title: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    validation: () => {},
    disabled: false,
    initialValue: undefined,
  };

  sortCruises = (a, b) => {
    if (a.label === b.label) return 0;
    return a.label < b.label ? -1 : 1;
  };

  state = {
    cruises: [],
  };

  componentDidMount() {
    this.getCruises();
  }

  getCruises = () => {
    get('CruiseService', { operation: 11, dropdownOptions: true }).then(
      (response) => {
        const cruiseList = parseDmasAPIResponse(response).cruises;
        cruiseList.sort(this.sortCruises);
        this.setState({
          cruises: cruiseList,
        });
      }
    );
  };

  render() {
    const { title, initialValue, validation, disabled } = this.props;
    const { cruises } = this.state;
    return (
      <Dropdown
        label="Cruise Name"
        options={cruises}
        title={title}
        initialValue={initialValue}
        validation={validation}
        disabled={disabled}
        {...this.props}
      />
    );
  }
}
export default CruiseDropdown;
