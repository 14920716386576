import { Grid } from '@mui/material';
import { TypographyWithTranslation } from 'base-components';
import LatestReadingsWidgetDisplay from 'domain/AppComponents/Dashboard/latest-reading-widget/LatestReadingsWidgetDisplay';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import LatestReadingsWidgetConfigContent, {
  type LatestReadingsWidgetFormType,
} from './Config/LatestReadingsWidgetConfigContent';

export const defaultLatestReadingsConfig: LatestReadingsWidgetFormType = {
  dataSourceSelection: 'sensorId',
  locations: [],
  title: 'Sample Latest Readings',
  sensorIds: '22209,22219',
  simpleDateEnabled: false,
  showTitle: true,
  dataWarningEnabled: false,
  autoRefreshEnabled: true,
  dataAgeLimit: 7,
};

const getLocations = (locations, dataSourceSelection) => {
  if (dataSourceSelection === 'location' && locations.length > 0) {
    return locations.map(
      ({ deviceCategoryCode, stationCode, sensorTypeCode }) => ({
        deviceCategoryCode,
        locationCode: stationCode,
        sensorTypeCode,
      })
    );
  }
  return [];
};

const LatestReadingsWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, initialConfig } = props;
  const { config, setConfig } = useWidgetConfig<LatestReadingsWidgetFormType>(
    id,
    {
      ...defaultLatestReadingsConfig,
      ...initialConfig,
    }
  );
  const {
    title,
    showTitle,
    locations,
    dataSourceSelection,
    sensorIds,
    autoRefreshEnabled,
    dataAgeLimit,
    simpleDateEnabled,
    dataWarningEnabled,
  } = config;

  return (
    <Widget
      key={id}
      titleComponents={
        <TypographyWithTranslation
          variant="subtitle1"
          translationKey="common.textfields.variable"
          translationOptions={{ variableName: title }}
        />
      }
      configTitle="Latest Readings Widget"
      ConfigFieldsComponent={LatestReadingsWidgetConfigContent}
      onChangeConfig={setConfig}
      config={config}
      collapseHeader={showTitle}
      closeMenuItems
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        <Grid item aria-label="widgetContent" xs={12} sx={{ height: '99%' }}>
          <LatestReadingsWidgetDisplay
            showTitle
            title=""
            sensorIds={dataSourceSelection === 'sensorId' ? sensorIds : ''}
            locations={getLocations(locations, dataSourceSelection)}
            dataSourceSelection={dataSourceSelection}
            autoRefresh={autoRefreshEnabled}
            dataAgeLimit={dataAgeLimit}
            simpleDateEnabled={simpleDateEnabled}
            dataWarningEnabled={dataWarningEnabled}
          />
        </Grid>
      </Grid>
    </Widget>
  );
};

LatestReadingsWidget.widgetKey = 'latest-readings-widget';
LatestReadingsWidget.widgetTitle = '';
LatestReadingsWidget.defaultDataGrid = {
  x: 0,
  y: 0,
  w: 5,
  h: 10,
};

export default LatestReadingsWidget;
