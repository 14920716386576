import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Grid,
  NoTotalCountPagingContainer,
  StatelessTable,
} from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
});

const headers = [
  { title: 'File Id', name: 'fileId', dataType: 'Number' },
  { title: 'Device Id', name: 'deviceId', dataType: 'Number' },
  { title: 'File Name', name: 'fileName', dataType: 'String' },
  { title: 'File State', name: 'fileState', dataType: 'String' },
  { title: 'Start Date', name: 'startDate', dataType: 'Date' },
  { title: 'End Date', name: 'endDate', dataType: 'Date' },
  { title: 'File Size (Bytes)', name: 'fileSize', dataType: 'String' },
];

const defaultColumnWidths = [
  { columnName: 'fileId', width: '10%' },
  { columnName: 'deviceId', width: '10%' },
  { columnName: 'fileName', width: '35%' },
  { columnName: 'fileState', width: '10%' },
  { columnName: 'startDate', width: '10%' },
  { columnName: 'endDate', width: '10%' },
  { columnName: 'fileSize', width: '15%' },
];

class FileManagementQueryResults extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      spaceBetween: PropTypes.string,
      root: PropTypes.string,
    }).isRequired,
    selections: PropTypes.arrayOf(PropTypes.number),
    getDeviceFiles: PropTypes.func,
    tableRows: PropTypes.arrayOf(PropTypes.shape({})),
    onSelectionChange: PropTypes.func,
    fileState: PropTypes.string,
  };

  static defaultProps = {
    selections: [],
    getDeviceFiles: () => {},
    tableRows: [],
    onSelectionChange: () => {},
    fileState: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      pageSize: 15,
      pageIndex: 0,
      sorting: [],
      columnWidths: defaultColumnWidths,
    };
  }

  componentDidMount() {
    const { getDeviceFiles, fileState } = this.props;
    const { pageIndex, pageSize } = this.state;
    getDeviceFiles(pageIndex, pageSize, fileState);
  }

  componentDidUpdate(prevProps) {
    const { getDeviceFiles, fileState } = this.props;
    const { pageIndex, pageSize } = this.state;

    if (prevProps.fileState !== fileState) {
      getDeviceFiles(pageIndex, pageSize, fileState);
    }
  }

  handlePageSizeChange = (newPageSize) => {
    const { getDeviceFiles, fileState } = this.props;
    getDeviceFiles(0, newPageSize, fileState);

    this.setState({ pageSize: newPageSize });
  };

  handleCurrentPageChange = (newPageIndex) => {
    const { pageSize } = this.state;
    const { getDeviceFiles, fileState } = this.props;
    getDeviceFiles(
      (newPageIndex >= 0 ? newPageIndex : 0) * pageSize,
      pageSize,
      fileState
    );
  };

  render() {
    const { selections, tableRows, onSelectionChange } = this.props;
    const { pageSize, pageIndex, sorting, columnWidths } = this.state;
    return (
      <>
        <Grid>
          <StatelessTable
            columns={headers}
            rows={tableRows}
            visible={{}}
            selection={{
              showSelectionColumn: true,
              selection: selections,
              onChange: onSelectionChange,
              showSelectAll: true,
            }}
            paging={{
              containerComponent: NoTotalCountPagingContainer,
              currentPage: pageIndex,
              pageSize,
              pageSizes: [15, 30, 60, 150, 300],
              onCurrentPageChange: this.handleCurrentPageChange,
              onPageSizeChange: this.handlePageSizeChange,
            }}
            sort={{
              sorting,
              handleSortingChange: (newSorting) =>
                this.setState({ sorting: newSorting }),
            }}
            resize={{
              columnWidths,
              handleColumnWidths: (updatedColumnWidths) =>
                this.setState({ columnWidths: updatedColumnWidths }),
              resizingMode: 'nextColumn',
            }}
          />
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(FileManagementQueryResults));
