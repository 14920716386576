import { Delete } from '@onc/icons';
import { oncDefaultTheme } from '@onc/theme';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from 'base-components';
import DateFormatUtils from 'util/DateFormatUtils';
import AnnotationActions from './AnnotationActions';

const FULL_TIMESTAMP_WIDTH = 133;
const DEFAULT_TIMESTAMP_WIDTH = 36;
const ICON_WIDTH = 26;
const ANNOTATION_PADDING_WIDTH = 16;

const getAnnotationContentWidth = (iconArray, fullTimestamp) => {
  let buffer = 0;
  // Timestamp
  if (fullTimestamp) {
    buffer += FULL_TIMESTAMP_WIDTH;
  } else {
    buffer += DEFAULT_TIMESTAMP_WIDTH;
  }
  // Inner left padding
  buffer += ANNOTATION_PADDING_WIDTH; // Padding between time and content

  // Icons
  const iconsShowing = iconArray.filter(Boolean).length;
  if (iconsShowing > 0) {
    // Action icon padding
    buffer += ANNOTATION_PADDING_WIDTH;

    if (iconsShowing > 2) {
      // Compression into a single three-dot menu
      buffer += ICON_WIDTH;
    } else {
      // However many icons there are
      buffer += iconsShowing * ICON_WIDTH;
    }
  }
  return buffer;
};

const classes = {
  lineBase: {
    cursor: 'pointer',
  },
  selected: {
    background: '#c5debb !important',
  },

  toBeReviewed: {
    background: '#ea9999',
  },
  attributes: {
    display: 'inline-block',
    textIndent: 2,
  },
  blockSpan: {
    display: 'block',
  },
  usernameSpan: {
    display: 'block',
    fontWeight: 'bold',
  },
  lineTimestamp: {
    marginRight: '16px',
    minWidth: '0',
    marginTop: '0',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  timestamp: {
    color: oncDefaultTheme.palette.grey[700],
  },
  listItemTextRoot: {
    marginBottom: '0',
    marginTop: '0',
    flex: 'none',
    wordWrap: 'break-word',
  },
};

type ChatMessageLineProps = {
  chatMessage: {
    chatLogMsgId: number;
    username: string;
    msg: string;
    msgDate: string;
  };
  key?: number;
  selected?: boolean;
  displayFullTimestamp?: boolean;
  displayDeleteIcon?: boolean;
  deletePermissions?: boolean;
  onChatMessageDeleteClick?: (arg0: object) => void;
  style?: {
    height: number;
    left: number;
    position: string;
    top: number;
    width: string;
  };
  chatUserMap: {
    username: string;
    color: string;
  }[];
  coloredUsernames: boolean;
  onChatMessageClick: (arg0: object, arg1: number) => void;
  listIndex: number;
};

const ChatMessageLine = ({
  key = undefined,
  selected = false,
  displayFullTimestamp = false,
  displayDeleteIcon = false,
  deletePermissions = false,
  onChatMessageDeleteClick = undefined,
  style = undefined,
  chatUserMap,
  coloredUsernames,
  onChatMessageClick,
  listIndex,
  chatMessage,
}: ChatMessageLineProps) => {
  const getContentStyle = () => {
    const buffer = getAnnotationContentWidth(
      [displayDeleteIcon],
      displayFullTimestamp
    );
    return { width: `calc(100% - ${buffer}px` };
  };

  const formatUrl = (url) => {
    const illegalLastChars = ['.', ',', '!', ';'];
    let illegalChar = '';
    let parsedUrl = url;
    if (illegalLastChars.includes(url.charAt(url.length - 1))) {
      illegalChar = url.slice(-1);
      parsedUrl = url.slice(0, url.length - 1);
    }
    return (
      <>
        <Link href={parsedUrl} target="_blank">
          {parsedUrl}
        </Link>
        {illegalChar}
      </>
    );
  };

  const getUserStyle = (username) => {
    const user = chatUserMap.find((item) => item.username === username);
    if (user && coloredUsernames) {
      return { color: user.color };
    }
    return { color: `#000` };
  };

  const generateFormattedText = (text) => {
    const parts = text.split(/(\S+[^\s])|(\s+[^\S])/g);
    // Format any @mentions
    const tagRegex = /(@[^\s]+)/g;
    const urlRegex = /https?:\/\/[^\s]+/g;
    return parts
      .map((part) => {
        if (part && part.match(tagRegex)) {
          return <b style={getUserStyle(part.substring(1))}>{part}</b>;
        }
        if (part && part.match(urlRegex)) {
          return formatUrl(part);
        }
        if (chatUserMap.find((user) => user.username === part)) {
          return <b style={getUserStyle(part)}>{part}</b>;
        }
        return part;
      })
      .filter((part) => part !== undefined && part !== '');
  };

  const renderDisplayText = () => (
    <>
      <Typography
        variant="body2"
        sx={classes.usernameSpan}
        style={getUserStyle(chatMessage.username)}
      >
        {chatMessage.username ? chatMessage.username : '(System Message)'}
      </Typography>
      <Typography variant="body2" sx={classes.blockSpan}>
        {generateFormattedText(chatMessage.msg)}
      </Typography>
    </>
  );

  const timeFormat = displayFullTimestamp ? 'full' : 'time-no-seconds';
  const timestamp = DateFormatUtils.formatDate(chatMessage.msgDate, timeFormat);

  const chatClasses = {
    ...classes.lineBase,
    ...(selected ? classes.selected : {}),
  };

  const renderChatLogActions = () => {
    const options = [];

    // Delete Action
    if (deletePermissions && displayDeleteIcon) {
      options.push({
        label: 'Delete Annotation',
        onClick: () => onChatMessageDeleteClick(chatMessage),
        icon: Delete,
      });
    }

    return <AnnotationActions options={options} />;
  };

  return (
    <ListItem
      style={style}
      sx={chatClasses}
      key={key}
      divider
      alignItems="flex-start"
      onClick={() => onChatMessageClick(chatMessage, listIndex)}
    >
      <ListItemIcon sx={classes.lineTimestamp}>
        <Typography variant="body2" sx={classes.timestamp}>
          {timestamp}
        </Typography>
      </ListItemIcon>
      <ListItemText
        sx={classes.listItemTextRoot}
        style={getContentStyle()}
        primary={renderDisplayText()}
      />
      <ListItemSecondaryAction>
        {renderChatLogActions()}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export { ChatMessageLine, getAnnotationContentWidth };
