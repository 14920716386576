import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useQueries, useQueryClient, UseQueryResult } from '@onc/service';
import { TraceData } from 'domain/AppComponents/charts/TimeSeriesChart';
import { type DateRange } from 'domain/hooks/useDateRange';
import ChartUtils from '../ChartUtils';
import {
  getSourceIdentifier,
  getDateRangeIdentifier,
  getAdjustedDateRange,
  fetchTraceData,
  getQueryIdentifier,
  fetchMinMaxData,
} from '../TraceDataUtils';
import {
  type ChartQuality,
  type ScalarDataChartDeploymentSource,
  type ScalarDataChartLocationSource,
} from '../types/TimeSeriesScalarDataChart.types';

const ERROR_MESSAGES_TO_IGNORE = ['canceled'];

const handleServiceCallError = (
  serviceCall: () => Promise<any>,
  onError: (message: string) => void
) =>
  serviceCall().catch((error) => {
    if (ERROR_MESSAGES_TO_IGNORE.includes(error.message)) return;

    onError(error.message);
    throw error;
  });

const buildTraceQuery = (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  resamplingResult: any,
  dateRange: DateRange,
  chartQuality: ChartQuality | undefined,
  isMinMaxEnabled: boolean,
  onError: (message: string) => void
) => ({
  keepPreviousData: true,
  queryKey: [
    'traceData',
    getSourceIdentifier(source),
    getDateRangeIdentifier(dateRange),
    chartQuality,
    isMinMaxEnabled,
  ],
  queryFn: async ({ signal }) => {
    const adjustedDateRange = getAdjustedDateRange(source, dateRange);
    if (adjustedDateRange[1].isBefore(adjustedDateRange[0])) {
      return () => [];
    }

    return handleServiceCallError(
      () =>
        fetchTraceData(
          source,
          resamplingResult,
          adjustedDateRange,
          chartQuality,
          isMinMaxEnabled,
          signal
        ),
      onError
    );
  },
  staleTime: 1000 * 60 * 5,
});

const buildMinMaxQuery = (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  resamplingResult: any,
  dateRange: DateRange,
  chartQuality: ChartQuality | undefined,
  isMinMaxEnabled: boolean,
  onError: (message: string) => void
) => ({
  queryKey: ['minMaxData', getQueryIdentifier(source, dateRange, chartQuality)],
  queryFn: async ({ signal }) => {
    const adjustedDateRange = getAdjustedDateRange(source, dateRange);
    if (adjustedDateRange[1].isBefore(adjustedDateRange[0])) {
      return () => [];
    }

    return handleServiceCallError(
      () =>
        fetchMinMaxData(
          source,
          resamplingResult,
          adjustedDateRange,
          chartQuality,
          isMinMaxEnabled,
          signal
        ),
      onError
    );
  },
  staleTime: 1000 * 60 * 5,
});

const useTraceQueries = (
  devicesResult: UseQueryResult[],
  sources: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  >,
  chartWidth: number | null,
  dateRange: DateRange,
  isMinMaxEnabled: boolean,
  onError: (message: string) => void,
  chartQuality?: ChartQuality
) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    sources.forEach((source) => {
      queryClient.cancelQueries({
        queryKey: ['traceData', getSourceIdentifier(source)],
        exact: false,
      });
    });
  }, [queryClient, sources, dateRange]);

  const queries = useMemo(() => {
    const newTraceQueries = [];

    devicesResult.forEach((deviceResult, index) => {
      if (deviceResult.isSuccess && deviceResult?.data[1]) {
        const deviceData = deviceResult.data[1];
        const source = sources[index];

        const dataRating = ChartUtils.getDataRating(deviceData);
        const resamplingResult = ChartUtils.getResampling(
          chartWidth,
          dataRating,
          dateRange.map((d) => moment.utc(d).toDate()),
          chartQuality
        );

        newTraceQueries.push(
          buildTraceQuery(
            source,
            resamplingResult,
            dateRange,
            chartQuality,
            isMinMaxEnabled,
            onError
          )
        );

        if (chartQuality === 'raw' && resamplingResult.resamplePeriod) {
          newTraceQueries.push(
            buildMinMaxQuery(
              source,
              resamplingResult,
              dateRange,
              chartQuality,
              isMinMaxEnabled,
              onError
            )
          );
        }
      }
    });

    return newTraceQueries;
  }, [
    chartQuality,
    chartWidth,
    dateRange,
    devicesResult,
    isMinMaxEnabled,
    onError,
    sources,
  ]);

  const deviceQueries = useQueries<UseQueryResult<TraceData, Error>[]>({
    queries,
  });

  return deviceQueries as UseQueryResult<TraceData, Error>[];
};

export default useTraceQueries;
