import React from 'react';
import { ListItemText, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { CardGrid } from '@onc/composite-components';
import { ExpandMore as ExpandMoreIcon } from '@onc/icons';
import { Accordion, AccordionDetails, AccordionSummary } from 'base-components';
import {
  CalibratedDevice,
  CommunityFishersDevicePackage,
  DeviceInformation,
  NavDevice,
} from 'domain/Apps/community-fishers/CommunityFishersDevicePackageTypes';
import CommunityFishersDevicePackageCard from './CommunityFishersDevicePackageCard';
import CommunityFishersSetInfo from './CommunityFishersSetInfo';
import CommunityFishersXChangeSensorTable from './CommunityFishersXChangeSensorTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummary: {
      backgroundColor: theme.palette.background.default,
    },
    accordionDetails: {
      display: 'block',
      marginLeft: theme.spacing(2),
    },
  })
);

type DeviceInfo = {
  deviceId: number;
  deviceType: string;
  deviceName: string;
  deviceTypeName: string;
  deviceTypeId: number;
};

type Props = {
  devicePackage: CommunityFishersDevicePackage;
  accordionIdentifier: string;
  expanded: boolean;
  handleChange: (
    accordionIdentifier: string
  ) => (_event: any, isExpanded: boolean) => void;
};

const CommunityFishersDevicePackageAccordion: React.FC<Props> = ({
  devicePackage,
  accordionIdentifier,
  expanded,
  handleChange,
}: Props) => {
  const classes = useStyles();

  const createDeviceObj = (
    prefix: string,
    deviceInfo?: CalibratedDevice | NavDevice
  ): DeviceInfo => ({
    deviceId: deviceInfo?.deviceId,
    deviceType: prefix,
    deviceName: deviceInfo?.deviceName,
    deviceTypeName: deviceInfo?.deviceTypeName,
    deviceTypeId: deviceInfo?.deviceTypeId,
  });

  const buildDevicesArray = (
    deviceInformation: DeviceInformation
  ): DeviceInfo[] => {
    const ctdDevices = deviceInformation?.ctdDeviceInfos || [];
    const navDevices = deviceInformation?.navDeviceInfos || [];
    const piggyDevices = deviceInformation?.piggybackDeviceInfos || [];

    const ctdObjs =
      ctdDevices.length > 0
        ? ctdDevices.map((ctdDevice) => createDeviceObj('CTD', ctdDevice))
        : [createDeviceObj('CTD', undefined)];

    const tabletObjs =
      navDevices.length > 0
        ? navDevices.map((navDevice) => createDeviceObj('Tablet', navDevice))
        : [createDeviceObj('Tablet', undefined)];

    const piggybackObjs =
      piggyDevices.length > 0
        ? piggyDevices?.map((piggybackDevice) =>
            createDeviceObj('Piggyback', piggybackDevice)
          )
        : [];

    return [...ctdObjs, ...tabletObjs, ...piggybackObjs];
  };

  const renderDeviceText = () => {
    const devicesArray = buildDevicesArray(devicePackage?.deviceInformation);

    if (devicesArray.filter((device) => !!device.deviceId).length === 0) {
      return (
        <>
          <Typography component="span" variant="body2">
            No Devices in Package
          </Typography>
        </>
      );
    }

    return (
      <>
        {devicesArray.map((device) => (
          <React.Fragment key={device.deviceId}>
            <Typography component="span" variant="body2">
              {`${device.deviceType}: `}
              <abbr
                title={
                  device.deviceId
                    ? `${device.deviceName} : ${device.deviceTypeName} (${device.deviceTypeId})`
                    : 'Not Assigned'
                }
              >
                {device.deviceId || 'N/A'}
              </abbr>
            </Typography>
            {device !== devicesArray[devicesArray.length - 1] && ', '}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderCardSet = (
    deviceType: 'CTD' | 'Tablet' | 'Piggyback',
    deviceArray: CalibratedDevice[] | NavDevice[]
  ) => {
    /* || deviceType === 'Piggyback' since don't want to render N/A card if a piggyback device is missing */
    if ((deviceArray && deviceArray.length > 0) || deviceType === 'Piggyback') {
      return deviceArray?.map((device: CalibratedDevice | NavDevice) => (
        <CommunityFishersDevicePackageCard
          deviceInformation={device}
          deviceType={deviceType}
          key={device.deviceId}
        />
      ));
    }

    return [
      <CommunityFishersDevicePackageCard
        deviceInformation={undefined}
        deviceType={deviceType}
        key={1}
      />,
    ];
  };

  const setNameBase = devicePackage.setInformation.setName;
  const communityNames =
    devicePackage.setInformation?.communityNamesViaDataAttributions?.join(', ');

  return (
    <Accordion expanded={expanded} onChange={handleChange(accordionIdentifier)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <ListItemText
          primary={`${setNameBase}${communityNames ? ` - ${communityNames}` : ''}`}
          secondary={renderDeviceText()}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <CommunityFishersSetInfo
          dateOfLastCastByCtd={
            devicePackage.setInformation?.dateOfLastCastByCtd
          }
          communityNamesViaDataAttributions={
            devicePackage.setInformation?.communityNamesViaDataAttributions
          }
        />
        <CardGrid columns={4}>
          {[
            renderCardSet(
              'CTD',
              devicePackage?.deviceInformation?.ctdDeviceInfos
            ),
            renderCardSet(
              'Tablet',
              devicePackage?.deviceInformation?.navDeviceInfos
            ),
            renderCardSet(
              'Piggyback',
              devicePackage?.deviceInformation?.piggybackDeviceInfos
            ),
          ]
            .flat()
            .filter((card) => card)}
        </CardGrid>
        <CommunityFishersXChangeSensorTable
          xChangeSensors={devicePackage?.xChangeSensors}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CommunityFishersDevicePackageAccordion;
