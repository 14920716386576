import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';
import Environment from 'util/Environment';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

export enum LayoutApplication {
  DASHBOARDS = 1,
  OLD_DIVE_LOG = 2,
  DIVE_LOG = 12,
  FIXED_CAMERA_PLAYBACK = 4,
}

const LAYOUT_SERVICE = 'LayoutService';
export const LAYOUT_RESOURCE_TYPE_ID = 2000;

type LayoutServiceGetParams = {
  resourceTypeId?: number;
  resourceId: number;
};

type LayoutServiceGetOneParams = {
  layoutId: number;
};

export type Layout = {
  adminAccounts: number[];
  isPublic: boolean;
  layoutId: number;
  layoutName: string;
  modifyBy: number;
  modifyDate: string;
  ownerId: number;
  ownerName: string;
  permission: string;
  resourceId: number;
  resourceTypeId: 2000;
  sharedAccounts: number[];
  token: string;
  widgetLayout: string;
};

export type LayoutServiceDeleteParameters = {
  resourceTypeId: number;
  resourceId: number;
  layoutId: number;
};

export type LayoutServiceUpdateParameters = {
  resourceTypeId: number;
  resourceId: number;
  isPublic: boolean;
  layoutId: number;
  ownerId: number;
  widgetLayout: string;
  layoutName: string;
};

export type LayoutServiceCloneParameters = {
  resourceTypeId: number;
  resourceId: number;
  layoutName: string;
  widgetLayout: string;
  layoutId: number;
};

export type LayoutServiceCreateParameters = {
  resourceTypeId: number;
  resourceId: number;
  layoutName: string;
  widgetLayout: string;
};

export type LayoutServicePostResponse = {
  layout: OncLayouts;
};

type LayoutPayload = { layouts: Layout[] };

export const useOwnerLayoutsForApp = (params: LayoutServiceGetParams) =>
  useGet<LayoutPayload, LayoutServiceGetParams>(
    LAYOUT_SERVICE,
    {
      operation: 100,
      transform: (response) => ({
        layouts: response.data.payload.layouts.filter(
          (l) => l.ownerId === Number(Environment.getDmasUserId())
        ),
      }),
    },
    {
      resourceTypeId: 2000, // 2000 is the resource type for the app
      ...params,
    }
  );

export const useLayout = (params: LayoutServiceGetOneParams) =>
  useGet<{ layout: Layout }, LayoutServiceGetOneParams>(
    LAYOUT_SERVICE,
    4,
    params
  );

export const useCreateLayout = (onSuccess, onError) =>
  usePost<LayoutServiceCreateParameters, LayoutServicePostResponse>(
    LAYOUT_SERVICE,
    {
      onSuccess,
      onError,
    },
    1 // 1 = Create
  );

export const useUpdateLayout = (
  onSuccess?: (data: LayoutServicePostResponse) => void,
  onError?: (error: unknown) => void
) =>
  usePost<LayoutServiceUpdateParameters, LayoutServicePostResponse>(
    LAYOUT_SERVICE,
    {
      onSuccess,
      onError,
    },
    2 // 2 = Update
  );

export const useDeleteLayout = (onSuccess, onError) =>
  usePost<LayoutServiceDeleteParameters, LayoutServicePostResponse>(
    LAYOUT_SERVICE,
    {
      onSuccess,
      onError,
    },
    3 // 3 = Delete
  );

class LayoutService {
  static get(params) {
    return get('LayoutService', params).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static post(params) {
    return post('LayoutService', params).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static addROPermission(layoutId, accountType, accountName) {
    return post('LayoutService', {
      operation: 101,
      layoutId,
      accountType,
      accountName,
    });
  }

  static removeROPermission(layoutId, accountType, accountId) {
    return post('LayoutService', {
      operation: 102,
      layoutId,
      accountType,
      accountId,
    });
  }

  static addRWPermission(layoutId, accountType, accountName) {
    return post('LayoutService', {
      operation: 103,
      layoutId,
      accountType,
      accountName,
    });
  }

  static removeRWPermission(layoutId, accountType, accountId) {
    return post('LayoutService', {
      operation: 104,
      layoutId,
      accountType,
      accountId,
    });
  }
}
export default LayoutService;
