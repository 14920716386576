/* eslint-disable react/prop-types */
import { Moment } from 'moment';
import VideoWidgetFormContents from 'domain/AppComponents/Dashboard/video-widget/VideoWidgetFormContents';
import { option } from 'domain/AppComponents/dropdowns/PlaylistsDropdown';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';

export type VideoWidgetConfigType = {
  title?: string;
  liveStreamUrl?: string;
  playlistHdr?: option;
  device?: {
    deviceId: number;
    name: string;
    deviceCode: string;
  };
  timeSource?: string;
  locations?: {
    deviceCategoryCode: string;
    stationCode: string;
    pathName: string[];
    els: { deviceId: number; sitedeviceid: number }[];
  }[];
  loopPlayback?: boolean;
  showTitle: boolean;
  dataSource: string;
  startDate?: Moment;
  endDate?: Moment;
};

const DashboardVideoWidgetConfig: ConfigFields<VideoWidgetConfigType> = ({
  formMethods,
}) => {
  const { watch } = formMethods;
  const formValue = watch();

  const {
    showTitle,
    loopPlayback,
    title,
    dataSource,
    timeSource,
    endDate,
    startDate,
    device,
    liveStreamUrl,
    playlistHdr,
    locations,
  } = formValue;

  return (
    <>
      <VideoWidgetFormContents
        showTitle={showTitle}
        loopPlayback={loopPlayback}
        title={title}
        dataSource={dataSource}
        timeSource={timeSource}
        endDate={endDate}
        startDate={startDate}
        device={device}
        liveStreamUrl={liveStreamUrl}
        playlistHdr={playlistHdr}
        locations={locations}
      />
    </>
  );
};

export default DashboardVideoWidgetConfig;
