import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import {
  Loading,
  CancelButton,
  DeleteButton,
  SaveButton,
} from '@onc/composite-components';
import { Divider, Grid, Typography } from 'base-components';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import Panel from 'library/CompositeComponents/panel/Panel';
import DateFormatUtils from 'util/DateFormatUtils';
import DateUtils from 'util/DateUtils';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import {
  MarginedReadOnlyField,
  ModifyBy,
  ModifyDate,
} from '../Form/Fields/ReadOnlyFields';
import { openDeviceDetails } from '../link/DeviceDetailsLink';
import { openSensorDetails } from '../link/SensorDetailsLink';

const useStyles = makeStyles((theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
}));

type DevicePayload = {
  devices: {
    device: {
      name: string;
    };
    dataRatings: {
      dataRating: Array<{
        dateFrom: string;
        id: number;
        modifyBy: string;
        modifyDate: string;
        samplePeriod: number;
        sampleSize: number;
        comment: string;
      }>;
    };
    sensors: {
      sensor: Array<{ id: number; name: string }>;
    };
  };
};

type DataRatingPayload = {
  dataRatingId: number;
  deviceName: string;
  sensorName: string;
  sampleSize: number;
  samplePeriod: number;
  modifyBy: number;
  modifyByName: string;
  modifyDate: string;
  dateFrom: string;
  comment: string;
};

type DataRatingPostProps = {
  samplePeriod: number;
  sampleSize: number;
  comment: string;
  dateFromDate: string;
  dateFromTime: string;
  dataRatingId: number;
  deviceId: number;
  sensorId: number;
};

type DataRatingForm = {
  dataRatingId: number;
  dateFrom: Moment;
  deviceName: string;
  samplePeriod: number;
  sampleSize: number;
  comment: string;
};

type DataRatingRWProps = DataRatingRWHandlerProps & {
  deviceData: DevicePayload;
  dataRatingData: DataRatingPayload;
  onError: (message: string, options?: any) => void;
  onInfo: (message: string, options?: any) => void;
};

type DataRatingRWHandlerProps = {
  dataRatingId?: number;
  sensorId?: number;
  deviceId: number;
};

const DataRatingRW = ({
  dataRatingId = undefined,
  sensorId = undefined,
  deviceId,
  onError,
  onInfo,
  deviceData,
  dataRatingData,
}: DataRatingRWProps) => {
  const classes = useStyles();

  const formMethods = useForm<DataRatingForm>({
    mode: 'onBlur',
    defaultValues: {
      dataRatingId,
      dateFrom: moment.utc(dataRatingData?.dateFrom),
      deviceName:
        deviceData?.devices.device.name || dataRatingData.deviceName || '',
      samplePeriod: dataRatingData?.samplePeriod,
      sampleSize: dataRatingData?.sampleSize,
      comment: dataRatingData?.comment || '',
    },
  });

  let sensorName;
  if (sensorId && dataRatingData?.sensorName) {
    sensorName = dataRatingData.sensorName;
  } else if (sensorId && !isNaN(sensorId)) {
    sensorName = deviceData?.devices.sensors.sensor.find(
      (sensor) => sensor.id === sensorId
    )?.name;
  }

  const redirectPage = () => {
    if (sensorId) {
      openSensorDetails(sensorId, 'data_rating_tab');
    } else {
      openDeviceDetails(deviceId, 'dr_tab');
    }
  };

  const parametersForPostReq = (values) => {
    const dateStr = DateFormatUtils.formatDate(
      values.dateFrom,
      'time-with-month-name-string'
    );
    const timeStr = DateFormatUtils.formatDate(values.dateFrom, 'time');

    const params = {
      samplePeriod: values.samplePeriod,
      sampleSize: values.sampleSize,
      comment: values.comment || '',
      dateFromDate: dateStr,
      dateFromTime: timeStr,
      dataRatingId: !isNaN(dataRatingId) && dataRatingId ? dataRatingId : 0,
      deviceId,
      sensorId: !isNaN(sensorId) && sensorId ? sensorId : 0,
    };

    return params;
  };

  const { mutate: saveDataRatingData } = usePost<DataRatingPostProps>(
    'DataRatingService',
    {
      onSuccess: () => {
        onInfo('Data Rating saved!');
        redirectPage();
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    1
  );

  const { mutate: updateDataRatingData } = usePost<DataRatingPostProps>(
    'DataRatingService',
    {
      onSuccess: () => {
        onInfo('Data Rating saved!');
        redirectPage();
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    2
  );

  const { mutate: deleteDataRatingData } = usePost<{ dataRatingId: number }>(
    'DataRatingService',
    {
      onSuccess: () => {
        onInfo('Data Rating deleted!');
        redirectPage();
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    3
  );

  const handleSubmit = (values) => {
    const params = parametersForPostReq(values);

    if (!dataRatingId || isNaN(dataRatingId)) {
      saveDataRatingData(params);
    } else {
      updateDataRatingData(params);
    }
  };

  const handleDelete = () => {
    deleteDataRatingData({ dataRatingId });
  };

  const handleCancel = () => {
    redirectPage();
  };

  const renderSensorNameField = () => {
    if (sensorName) {
      return (
        <MarginedReadOnlyField
          labelText="Sensor Name"
          valueText={sensorName}
          title="sensorNameField"
        />
      );
    }
    return <></>;
  };

  return (
    // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
    <div>
      <Panel
        title={
          <>
            <Typography variant="body1">Data Rating ID:</Typography>
            <Typography variant="body1">{dataRatingId}</Typography>
          </>
        }
      >
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid
            container
            direction="row"
            className={classes.entryFormContainer}
          >
            <Grid item xs={6}>
              <FormDateTimePicker
                translationKey="common.datepickers.dateFrom"
                name="dateFrom"
                fullWidth
              />
              <MarginedReadOnlyField
                labelText="Device"
                valueText={
                  deviceData?.devices.device.name || dataRatingData.deviceName
                }
                title="deviceField"
              />
              {renderSensorNameField()}
              <FormTextField
                fullWidth
                name="samplePeriod"
                translationKey="dataRating.samplePeriod"
                rules={{ required: 'A sample period is required!' }}
                type="number"
              />
              <FormTextField
                fullWidth
                name="sampleSize"
                translationKey="dataRating.sampleSize"
                rules={{ required: 'A sample size is required!' }}
                type="number"
              />
              <FormTextField
                fullWidth
                name="comment"
                translationKey="common.textfields.comment"
                multiline
                minRows={4}
                maxRows={8}
              />
              <ModifyBy username={dataRatingData?.modifyByName} />
              <ModifyDate
                date={DateUtils.formatDateAsString(
                  new Date(dataRatingData?.modifyDate)
                )}
              />
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <SaveButton />
            <CancelButton onClick={handleCancel} />
            <DeleteButton
              onClick={handleDelete}
              disabled={isNaN(dataRatingId) || !dataRatingId}
            />
          </div>
        </Form>
        <Divider variant="middle" />
      </Panel>
    </div>
  );
};

const DataRatingRWQueryHandler = ({
  dataRatingId = undefined,
  sensorId = undefined,
  deviceId,
}: DataRatingRWHandlerProps) => {
  const { onError, onInfo } = useSnackbars();
  const deviceData = useGet<
    DevicePayload,
    { deviceid: number; operationtype: number; network: number }
  >(
    'DeviceService',
    {
      operation: 2,
      transform: (response) => {
        const { data } = response;
        if (!data.payload) {
          throw new Error(data);
        }
        return data.payload;
      },
      options: { enabled: !dataRatingId },
    },
    {
      deviceid: deviceId,
      operationtype: 2,
      network: -1,
    }
  );

  const dataRatingData = useGet<DataRatingPayload, { dataRatingId: number }>(
    'DataRatingService',
    {
      operation: 4,
      options: {
        enabled: dataRatingId !== undefined && !isNaN(dataRatingId),
      },
    },
    {
      dataRatingId,
    }
  );

  if (
    (!dataRatingId && deviceData.isLoading) ||
    (dataRatingId && dataRatingData.isLoading)
  ) {
    return <Loading />;
  }

  if (!dataRatingId && deviceData.error) {
    onError(deviceData.error.message);
    return <></>;
  }

  if (dataRatingId && !isNaN(dataRatingId) && dataRatingData.error) {
    onError(dataRatingData.error.message);
    return <></>;
  }

  if (deviceData.data?.devices?.device === null) {
    onError('Failed to fetch device data');
    return <></>;
  }

  return (
    <DataRatingRW
      deviceId={deviceId}
      dataRatingId={dataRatingId}
      sensorId={sensorId}
      onError={onError}
      onInfo={onInfo}
      deviceData={deviceData?.data}
      dataRatingData={dataRatingData?.data}
    />
  );
};

export default DataRatingRWQueryHandler;
