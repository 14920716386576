import React, { useContext, useState } from 'react';
import { CancelButton, ContainedButton } from '@onc/composite-components';
import { Lock, LockOpen } from '@onc/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from 'base-components';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import PlaylistService from 'domain/services/PlaylistService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import PlaylistContext from '../playlist-playback/PlaylistContext';

type Props = {
  open: boolean;
  onClose: () => void;
};

const PlaylistLockingDialog: React.FC<Props> = ({ open, onClose }) => {
  const { onError, onInfo } = useSnackbars();
  const playlistContext = useContext(PlaylistContext);
  const { playlist } = playlistContext;
  const { locked } = playlist;
  const [editedLockReason, setEditedLockReason] = useState<string>(
    playlist.lockReason
  );

  const togglePlaylistLock = () => {
    PlaylistService.updatePlaylistHdr({
      playlistHdrId: playlist.playlistHdrId,
      playlistHdrDescription: playlist.description,
      playlistHdrName: playlist.name,
      groupName: playlist.groupName,
      locked: !locked,
      lockReason: editedLockReason,
    })
      .then(() => {
        onClose();
        playlistContext.refreshPlaylist(playlist.playlistHdrId);
        onInfo(`${playlist.name} has been ${locked ? 'unlocked' : 'locked'}.`);
      })
      .catch((error) => {
        onError(error.message);
      });
  };

  const renderDialogText = () =>
    locked ? (
      <DialogContentText>
        The playlist was locked for the following reason. Are you sure that you
        wish to unlock it?
      </DialogContentText>
    ) : (
      <>
        <DialogContentText>
          Lock a playlist to prevent yourself from accidentally editing it. You
          might want to do this after sharing it publicly or publishing a paper
          with links to clips.
        </DialogContentText>
        <DialogContentText>
          You must specify a reason for locking; this is visible only to you.
        </DialogContentText>
      </>
    );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="locking-title">{`${
        locked ? 'Unlock' : 'Lock'
      } Playlist?`}</DialogTitle>
      <DialogContent>
        {renderDialogText()}
        <RORWTextField
          translationKey="common.textfields.reason"
          value={editedLockReason}
          permission={locked ? 'RO' : 'RW'}
          onChange={(e) => setEditedLockReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <ContainedButton
          translationKey={
            locked ? 'common.buttons.unlock' : 'common.buttons.lock'
          }
          onClick={togglePlaylistLock}
          startIcon={locked ? <LockOpen /> : <Lock />}
          disabled={!locked && !editedLockReason}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PlaylistLockingDialog;
