import { useState, useEffect } from 'react';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Loading } from '@onc/composite-components';
import { Grid } from 'base-components';
import { GLOBAL_USER } from 'domain/AppComponents/sea-tube/configuration/GlobalConfigConstants';
import OrganizationService, {
  OrganizationPermission,
} from 'domain/services/OrganizationService';

import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import ConfigurationContent from '../../../AppComponents/sea-tube/configuration/ConfigurationContent';
import OrganizationList from '../../../AppComponents/sea-tube/configuration/OrganizationList';
import SeaTubeConfigurationService from '../../../services/SeaTubeConfigurationService';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    flexGrow: 1,
  },
}));

const SeaTubeConfigurationPage: React.FC = () => {
  const classes = useStyles();
  const { onError } = useSnackbars();
  const [isDmasAdmin, setIsDmasAdmin] = useState(false);
  const [configs, configsLoading, fetchConfigs] = useWebService({
    method: SeaTubeConfigurationService.getAll,
    onError,
  });

  // These values default to the global config showing
  const [ownerId, setOwnerId] = useState(GLOBAL_USER.ID);
  const [ownerType, setOwnerType] = useState(GLOBAL_USER.OWNER_TYPE);
  const [permission, setPermission] = useState(false);

  const [organizations, loadingOrganizations, fetchOrganizations] =
    useWebService({
      method: OrganizationService.getOrganizationPermissions,
      parser: (response) => {
        setIsDmasAdmin(response.isDmasAdmin);
        setPermission(response.isDmasAdmin);
        return response.userAdminInfo.sort(
          (a: OrganizationPermission, b: OrganizationPermission) =>
            a.organizationName > b.organizationName ? 1 : -1
        );
      },
      onError,
    });

  useEffect(() => {
    const ORGANIZATION_LIST = [134, 340, 559];
    fetchConfigs();
    fetchOrganizations(ORGANIZATION_LIST.join());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrganizationSelection = (
    updatedOwnerId: number,
    updatedOwnerType: number,
    hasPermission: boolean
  ) => {
    setOwnerId(updatedOwnerId);
    setOwnerType(updatedOwnerType);
    setPermission(hasPermission);
  };

  if (configsLoading || loadingOrganizations || configs === undefined) {
    return <Loading />;
  }

  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      alignItems="stretch"
    >
      <Grid item xs={6} sm={4} md={3}>
        <OrganizationList
          onChange={handleOrganizationSelection}
          organizations={organizations}
          isDmasAdmin={isDmasAdmin}
          value={ownerId}
        />
      </Grid>
      <Grid item xs={6} sm={8} md={9}>
        {ownerId ? (
          <ConfigurationContent
            configs={configs}
            ownerId={ownerId}
            ownerTypeId={ownerType}
            permission={permission}
            reloadConfigs={fetchConfigs}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default SeaTubeConfigurationPage;
