import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import {
  AbsoluteRightButtonStyle,
  ContainedButton,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';
import SensorDetailsLink from 'domain/AppComponents/link/SensorDetailsLink';
import {
  OBSERVATORY,
  REGION,
  STATION,
  STUDY_AREA_FROM_SERVICE,
} from 'domain/services/QaqcAutotestsConstants';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  divClass: {
    margin: theme.spacing(1),
  },
  addQaqcAutoTestButton: AbsoluteRightButtonStyle(theme),
});

const headers = [
  { title: 'QAQC Id', name: 'qaqcId' },
  { title: 'Effective Date From (UTC)', name: 'effectiveDateFrom' },
  { title: 'Effective Date To (UTC)', name: 'effectiveDateTo' },
  { title: 'Description', name: 'description' },
  { title: 'Device Id', name: 'deviceId' },
  { title: 'Sensor (Id)', name: 'sensor' },
  { title: 'Sensor Type', name: 'sensorType' },
  { title: 'Test Level', name: 'testLevel' },
  { title: 'Group / Study Area / Station', name: 'locationId' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Description For Search', name: 'descriptionForSearch' },
  { title: 'Device Id For Search', name: 'deviceIdForSearch' },
  { title: 'Sensor (Id) For Search', name: 'sensorForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'qaqcId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'effectiveDateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'effectiveDateTo',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'deviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'sensor',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'sensorType',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'testLevel',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'locationId',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'descriptionForSearch',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'deviceIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'sensorForSearch',
    width: ColumnInfo.small,
    align: 'left',
  },
];

class QaqcFinderTable extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isLoading: PropTypes.bool.isRequired,
    searchActive: PropTypes.bool,
    qaqcTests: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    searchActive: false,
    qaqcTests: [],
  };

  addQaqcAutotestLink = (permission) => {
    const { classes } = this.props;
    if (permission === 'RW') {
      return (
        <a href={`${Environment.getDmasUrl()}/QaqcAutotestDetails`}>
          <ContainedButton
            translationKey="device.addQaQcAutoTest"
            startIcon={<Add />}
            className={classes.addQaqcAutoTestButton}
          />
        </a>
      );
    }
    return null;
  };

  qaqcDescriptionLink = (qaqcId, description) => (
    <a
      href={`${Environment.getDmasUrl()}/QaqcAutotestDetails?qaqcId=${qaqcId}`}
    >
      {description}
    </a>
  );

  getLocationId = (testLevel, qaqc) => {
    const { qaqcTestGroupId, searchTreeNodeId, studyAreaId } = qaqc;
    switch (testLevel) {
      case OBSERVATORY:
      case REGION:
        return qaqcTestGroupId;
      case STATION:
        return searchTreeNodeId;
      case STUDY_AREA_FROM_SERVICE:
        return studyAreaId;
      default:
        return '';
    }
  };

  compare = (a, b, field) => {
    if (a.props === undefined) {
      return 1;
    }
    if (b.props === undefined) {
      return -1;
    }
    const idA = a.props[field];
    const idB = b.props[field];
    if (idA === idB) {
      return 0;
    }
    if (Number(idA) < Number(idB)) {
      return -1;
    }
    return 1;
  };

  compareSensorID = (a, b) => this.compare(a, b, 'sensorId');

  compareDeviceID = (a, b) => this.compare(a, b, 'deviceId');

  buildTableRows = () => {
    const { qaqcTests } = this.props;
    return qaqcTests.map((qaqcTest) => {
      const { dmasUser, qaqc, sensor, sensorType } = qaqcTest;
      const { firstname, lastname } = dmasUser;
      const {
        description,
        effectiveDateFrom,
        effectiveDateTo,
        modifyDate,
        qaqcId,
        testLevel,
      } = qaqc;

      const locationId = this.getLocationId(testLevel, qaqc);

      const qaqcFor = {
        ...qaqc,
        description: this.qaqcDescriptionLink(qaqcId, description),
        deviceId: sensor ? (
          <DeviceDetailsLink deviceId={sensor.deviceId} />
        ) : (
          ''
        ),
        effectiveDateFrom: DateFormatUtils.formatDate(
          effectiveDateFrom,
          'full'
        ),
        effectiveDateTo: effectiveDateTo
          ? DateFormatUtils.formatDate(effectiveDateTo, 'full')
          : '',
        locationId,
        sensor: sensor ? (
          <SensorDetailsLink sensorId={sensor.sensorId}>
            {`${sensor.sensorName} (${sensor.sensorId})`}
          </SensorDetailsLink>
        ) : (
          ''
        ),
        sensorType: sensorType ? sensorType.description : '',
        modifyBy: `${firstname} ${lastname}`,
        modifyDate: DateFormatUtils.formatDate(modifyDate, 'date'),
        descriptionForSearch: description,
        deviceIdForSearch: sensor ? sensor.deviceId : '',
        sensorForSearch: sensor ? `(${sensor.sensorId})` : '',
      };

      return qaqcFor;
    });
  };

  render() {
    const { classes, searchActive, isLoading } = this.props;
    const permission = Environment.getDmasUserPrivilege();

    return (
      <>
        {this.addQaqcAutotestLink(permission)}
        <SortableTable
          className={classes.divClass}
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          noDataMessage={searchActive ? 'No Data' : 'Select Filter Options'}
          pageSize={50}
          rows={this.buildTableRows()}
          sortExtensions={[
            {
              columnName: 'deviceId',
              compare: this.compareDeviceID,
            },
            {
              columnName: 'sensor',
              compare: this.compareSensorID,
            },
          ]}
          searchable
          searchBarMoveable={permission === 'RW'}
          stripedRows
          hiddenColumns={[
            'descriptionForSearch',
            'deviceIdForSearch',
            'sensorForSearch',
          ]}
          isLoading={isLoading}
        />
      </>
    );
  }
}

export default withStyles(styles)(QaqcFinderTable);
