import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Grid, Typography } from 'base-components';
import { DataSourceType } from 'domain/AppComponents/charts/types/TimeSeriesScalarDataChart.types';
import ChartWidgetDisplay from 'domain/AppComponents/Dashboard/chart-widget/ChartWidgetDisplay';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import ChartWidgetConfigContent, {
  TimeSeriesScalarDataChartConfigType,
} from './Config/ChartWidgetConfigContent';
import type { DataViewerDashboardConfigType } from '../Apps/data-viewer/config/DataViewerDashboardConfig';
import type { DateRange } from 'domain/hooks/useDateRange';

export const defaultChartConfig: TimeSeriesScalarDataChartConfigType = {
  autoRefreshEnabled: false,
  chartTitle: 'Sample Chart Title',
  widgetTitle: 'Sample Widget Title',
  datePreset: 'day',
  dateSelection: 'dateRange',
  isMinMaxEnabled: false,
  isRawCleanEnabled: false,
  showHeader: true,
  leftmin: undefined,
  leftmax: undefined,
  rightmin: undefined,
  rightmax: undefined,
  showChartTitle: false,
  showWidgetTitle: true,
  endDate: moment(new Date('2011-12-05T00:00:00.000Z')),
  startDate: moment(new Date('2011-09-20T00:00:00.000Z')),
  locationsLeftAxis: [
    {
      device: {
        deviceId: 11002,
        name: 'ADM CTD 105 on Crawler',
        deviceCode: 'BC_CRW_CTDFT',
        deviceCategoryCode: 'CTD',
        dataRating: [
          {
            dateFrom: '2008-03-01T00:00:00.000Z',
            dateTo: '2016-05-01T20:33:42.000Z',
            samplePeriod: 0.25,
            sampleSize: 1,
          },
          {
            dateFrom: '2016-05-01T20:33:42.000Z',
            dateTo: null,
            samplePeriod: 0.2,
            sampleSize: 1,
          },
        ],
      },
      sensor: {
        outputFormat: 'array',
        propertyCode: 'conductivity',
        sensorCategoryCode: 'conductivity',
        sensorCode: 'cond',
        sensorName: 'Conductivity',
        unitOfMeasure: 'S/m',
      },
      dataSourceType: DataSourceType.Deployment,
      name: 'Conductivity',
      nodeId: 'BC_CRW_CTDFT/cond',
      deviceId: 11002,
      deviceCategoryCode: 'CTD',
      deviceCategoryName: 'CTD',
      sensorTypeCode: 'conductivity',
      sensorCodeName: 'cond',
      deviceCode: 'BC_CRW_CTDFT',
      sensorCategoryCode: 'conductivity',
      traceName: 'Conductivity',
      traceColour: '#CC6600',
      sensorName: 'Conductivity',
    },
  ],
  locationsRightAxis: [],
  displayQaqc: false,
};

const TimeSeriesScalarDataChartWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, initialConfig: initialConfigProp, onRemove } = props;
  const initialConfig = useMemo(
    () => ({
      ...defaultChartConfig,
      ...initialConfigProp,
    }),
    [initialConfigProp]
  );
  const { config, setConfig } =
    useWidgetConfig<TimeSeriesScalarDataChartConfigType>(id, initialConfig);

  const { setDashboardState } =
    useDashboardState<DataViewerDashboardConfigType>();

  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const onSettingsClick = useCallback(() => {
    setIsConfigOpen(true);
  }, []);

  const onRemoveClick = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  const handleConfigClose = useCallback(() => {
    setIsConfigOpen(false);
  }, []);

  const setDateRange = (dateRange: DateRange) => {
    setDashboardState({
      startDate: dateRange[0],
      endDate: dateRange[1],
      preset: 'custom',
    });
  };

  return (
    <Widget
      id={id}
      titleComponents={
        <Typography variant="subtitle1">{config.widgetTitle}</Typography>
      }
      configTitle="Chart Widget"
      ConfigFieldsComponent={ChartWidgetConfigContent}
      onChangeConfig={setConfig}
      config={config}
      collapseHeader={config.showWidgetTitle}
      closeMenuItems
      isConfigOpen={isConfigOpen}
      onCloseConfig={handleConfigClose}
      showMenu
      reduceHeader
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        <Grid
          item
          xs={12}
          sx={{ height: config.isRawCleanEnabled ? '89%' : '99%' }}
        >
          <ChartWidgetDisplay
            showChartTitle={config.showChartTitle}
            chartTitle={config.chartTitle}
            isMinMaxEnabled={config.isMinMaxEnabled}
            isRawCleanEnabled={config.isRawCleanEnabled}
            isleftOverride={config.isleftOverride}
            isrightOverride={config.isrightOverride}
            alwaysShowModebar
            leftmin={config.leftmin}
            leftmax={config.leftmax}
            rightmin={config.rightmin}
            rightmax={config.rightmax}
            onError={() => {}}
            locationsLeftAxis={config.locationsLeftAxis}
            locationsRightAxis={config.locationsRightAxis}
            startDate={config.startDate}
            endDate={config.endDate}
            dateSelection={config.dateSelection}
            datePreset={config.datePreset}
            autoRefreshEnabled={config.autoRefreshEnabled}
            displayQaqc={config.displayQaqc}
            onSettingsClick={onSettingsClick}
            onRemoveClick={onRemoveClick}
            onChangeDateRange={setDateRange}
          />
        </Grid>
      </Grid>
    </Widget>
  );
};

TimeSeriesScalarDataChartWidget.widgetKey =
  'time-series-scalar-data-chart-widget';
TimeSeriesScalarDataChartWidget.widgetTitle = '';
TimeSeriesScalarDataChartWidget.defaultDataGrid = {
  i: 'example-widget',
  x: 0,
  y: 0,
  w: 9999,
  h: 14,
};

export default TimeSeriesScalarDataChartWidget;
