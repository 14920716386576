import { createContext } from 'react';
import { SeaTubeAnnotationPermissions } from 'domain/services/SeaTubePermissionsService';

const SeaTubePermissionContext = createContext<SeaTubeAnnotationPermissions>({
  canCreate: false,
  canPublish: false,
  canReview: false,
  canEdit: () => false,
  canDelete: () => false,
});

export default SeaTubePermissionContext;
