import { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import {
  StatelessTable,
  TableColumn,
  TableFilter,
  getEmptyFilter,
  TableFilterLogic,
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
} from 'base-components';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import Environment from 'util/Environment';
import { useLocalStorage } from 'util/hooks/useStorage';

export type DeviceActionTableRow = {
  rowId: string;
  deviceId: JSX.Element;
  deviceName: string;
  deviceActionId: JSX.Element;
  actionId: number;
  actionName: string;
  cruiseId: number;
  cruiseName: string;
  actionCost: number;
  deviceActionDescription: string;
  datePerformed: string;
  modifyBy: string;
  modifyDate: string;
};

interface ResultsProps {
  rows: DeviceActionTableRow[];
  selection: string[];
  setSelection: (selectedRows: string[]) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      height: '650px',
    },
  })
);

const formatDate = (date: string) => {
  if (!date) return undefined;
  const strArr = date.split('T');
  strArr[1] = strArr[1].substring(0, 8);
  return `${strArr[0]} ${strArr[1]}`;
};

export const transformDeviceActionToRow = (record): DeviceActionTableRow => ({
  rowId: `${record.deviceId}-${record.deviceActionId}`,
  deviceId: (
    <OpenInNewLink
      href={`${Environment.getDmasUrl()}/DeviceListing?DeviceId=${record.deviceId}`}
    >
      {record.deviceId}
    </OpenInNewLink>
  ),
  deviceName: record.deviceName,
  deviceActionId: record.deviceActionId ? (
    <OpenInNewLink
      href={`${Environment.getDmasUrl()}/DeviceActionMaintenance?ID=${record.deviceActionId}&isFromDevice=Y`}
    >
      {record.deviceActionId}
    </OpenInNewLink>
  ) : undefined,
  actionId: record.actionId,
  actionName: record.actionName,
  cruiseId: record.cruiseId,
  cruiseName: record.cruiseName,
  actionCost: record.actionCost,
  deviceActionDescription: record.description,
  datePerformed: formatDate(record.datePerformed),
  modifyBy: record.modifyByName,
  modifyDate: formatDate(record.modifyDate),
});

const DeviceBulkUpdateDeviceActionTable = ({
  rows,
  selection,
  setSelection,
}: ResultsProps) => {
  const classes = useStyles();
  const permission = Environment.getDmasUserPrivilege();

  const storageKey = 'device-bulk-update-deviceaction-table';
  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'deviceId', direction: 'asc' }],
  };

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  // Filtering
  const initialFilter = config.filter;
  const [filter, setFilter] = useState<TableFilter>(
    initialFilter ? _.cloneDeep(config.filter) : _.cloneDeep(getEmptyFilter())
  );

  const handleSelectRow = (selectedRows: string[]) => {
    setSelection(selectedRows);
  };

  const columns: TableColumn[] = [
    {
      name: 'deviceId',
      title: 'Device Id',
      dataType: 'Number',
    },
    {
      name: 'deviceName',
      title: 'Device Name',
      dataType: 'String',
    },
    {
      name: 'deviceActionId',
      title: 'Device Action Id',
      dataType: 'Number',
    },
    {
      name: 'actionId',
      title: 'Action Id',
      dataType: 'Number',
    },
    {
      name: 'actionName',
      title: 'Action Name',
      dataType: 'String',
    },
    {
      name: 'cruiseId',
      title: 'Cruise Id',
      dataType: 'Number',
    },
    {
      name: 'cruiseName',
      title: 'Cruise Name',
      dataType: 'String',
    },
    {
      name: 'actionCost',
      title: 'Action Cost',
      dataType: 'Number',
    },
    {
      name: 'deviceActionDescription',
      title: 'Description',
      dataType: 'String',
    },
    {
      name: 'datePerformed',
      title: 'Date Performed',
      dataType: 'Date',
    },
    {
      name: 'modifyBy',
      title: 'Modify By',
      dataType: 'String',
    },
    {
      name: 'modifyDate',
      title: 'Modify Date',
      dataType: 'Date',
    },
  ];

  const columnWidths: TableColumnWidthInfo[] = [
    {
      columnName: 'deviceId',
      width: ColumnInfo.small,
    },
    {
      columnName: 'deviceName',
      width: ColumnInfo.large,
    },
    {
      columnName: 'deviceActionId',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'actionId',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'actionName',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'cruiseId',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'cruiseName',
      width: ColumnInfo.large,
    },
    {
      columnName: 'actionCost',
      width: ColumnInfo.small,
    },
    {
      columnName: 'deviceActionDescription',
      width: ColumnInfo.large,
    },
    {
      columnName: 'datePerformed',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'modifyBy',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'modifyDate',
      width: ColumnInfo.medium,
    },
  ];

  return (
    <StatelessTable
      className={classes.table}
      rows={rows === null ? [] : rows}
      columns={columns}
      getRowId={(row) => row.rowId}
      permission={permission}
      searchable
      visible={{
        hiddenColumnNames: ['actionId', 'cruiseId', 'cruiseName', 'actionCost'],
      }}
      resize={{
        columnWidths,
      }}
      sort={{ sorting, customSorting: [], handleSortingChange: setSorting }}
      filter={{
        filterValue: filter,
        onChange: setFilter,
        filterFn: TableFilterLogic.defaultFilter,
        hidden: false,
      }}
      selection={{
        selection,
        onChange: handleSelectRow,
        selectByRowClick: false,
        highlightRow: true,
        showSelectionColumn: true,
        showSelectAll: true,
      }}
    />
  );
};

export default DeviceBulkUpdateDeviceActionTable;
