import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ContainedButton, OutlinedButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import { TableOld as Table, Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import MultiQueueService from 'domain/AppComponents/multi-queue/service/MultiQueueService';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';
import Panel from 'library/CompositeComponents/panel/Panel';
import DateFormatUtils from 'util/DateFormatUtils';
import CreateQueueDialog from './CreateQueueDialog';

const styles = (theme) => ({
  container: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'sticky',
    willChange: 'transform',
    bottom: '2px',
    backgroundColor: 'white',
  },
});

const HEADERS = [
  { title: 'Queue ID', name: 'queueId' },
  { title: 'Queue', name: 'queueName' },
  { title: 'Default Queue', name: 'defaultQueue' },
  { title: 'Activity', name: 'activity' },
  { title: 'Modify By', name: 'modifyBy' },
  { title: 'Modify Date', name: 'modifyDate' },
];

class MultiQueueShorestationPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    refreshTree: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
    node: NodePropType,
    locationNode: NodePropType,
  };

  static defaultProps = {
    node: undefined,
    locationNode: undefined,
  };

  getRowId = (row) => row.queueId;

  state = {
    showCreateDialog: false,
    showDeleteDialog: false,
    selections: [],
  };

  constructor(props) {
    super(props);
    const { node } = props;
    if (node) {
      this.state = {
        showCreateDialog: false,
        showDeleteDialog: false,
        selections: [],
      };
    }
  }

  componentDidUpdate(prevProps) {
    const { node } = this.props;
    const { node: prevNode } = prevProps;
    if (node && prevNode !== node) {
      this.clearSelections();
    }
  }

  clearSelections = () => {
    this.setState({
      selections: [],
    });
  };

  openCreateDialog = () => {
    this.setState({
      showCreateDialog: true,
    });
  };

  closeCreateDialog = () => {
    this.setState({
      showCreateDialog: false,
    });
  };

  openDeleteDialog = () => {
    this.setState({
      showDeleteDialog: true,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      showDeleteDialog: false,
    });
  };

  renderQueues = () => {
    const { node } = this.props;
    if (node) {
      return node.children.map((row) => ({
        queueId: row.objectId,
        queueName: row.multiQueueTreeNodeName,
        discription: row.description,
        defaultQueue: row.defaultQueue ? 'True' : 'False',
        activity: row.activity,
        modifyBy: row.modifyBy,
        modifyDate: DateFormatUtils.formatDate(row.modifyDate, 'full'),
      }));
    }
    return [];
  };

  renderConfirmDeleteDialog = () => {
    const { selections, showDeleteDialog } = this.state;
    const queueIds = selections ? selections.join(',') : '';
    const content = (
      <Typography variant="subtitle1" align="center">
        Are you sure you want to delete queueIds: {queueIds}.
      </Typography>
    );
    return (
      <ConfirmationDialog
        open={showDeleteDialog}
        disableBackdropClick
        title="Delete Queues"
        content={content}
        onCancel={this.closeDeleteDialog}
        onConfirm={this.handleDeleteQueue}
      />
    );
  };

  handleDeleteQueue = () => {
    const { refreshTree, onError } = this.props;
    const { selections } = this.state;
    if (selections.length > 0) {
      MultiQueueService.deleteQueue(selections.join(','))
        .then(() => {
          refreshTree();
          this.setState({
            selections: [],
            showDeleteDialog: false,
          });
        })
        .catch((response) => {
          onError(response);
        });
    }
  };

  onSelectionChange = (selections) => {
    this.setState({
      selections,
    });
  };

  renderButtons() {
    const {
      classes,
      node,
      refreshTree,
      onInfo,
      onError,
      locationNode,
      permission,
    } = this.props;
    const { showCreateDialog } = this.state;
    if (permission !== 'RW') {
      return null;
    }
    return (
      <div className={classes.buttonContainer}>
        <ContainedButton
          translationKey="multiqueue.addQueue"
          startIcon={<Add />}
          onClick={this.openCreateDialog}
        />
        <CreateQueueDialog
          node={node}
          showDialog={showCreateDialog}
          closeDialog={this.closeCreateDialog}
          refreshTree={refreshTree}
          onInfo={onInfo}
          onError={onError}
          locationNode={locationNode}
        />
        <OutlinedButton
          translationKey="multiqueue.deleteQueue"
          onClick={this.openDeleteDialog}
        />
        {this.renderConfirmDeleteDialog()}
      </div>
    );
  }

  renderNodeInformation() {
    const { node } = this.props;
    const { selections } = this.state;
    if (node) {
      const rows = this.renderQueues();
      return (
        <>
          <Table
            showBorders
            columns={HEADERS}
            elevation={0}
            showHeaderRow
            onSelectionChange={this.onSelectionChange}
            selection={selections}
            rows={rows}
            getRowId={this.getRowId}
            selectable
          />
          {this.renderButtons()}
        </>
      );
    }
    return null;
  }

  render() {
    const { classes, node } = this.props;

    return (
      <Panel title={node.multiQueueTreeNodeName}>
        <div className={classes.container}>{this.renderNodeInformation()}</div>
      </Panel>
    );
  }
}

export default withStyles(styles)(MultiQueueShorestationPanel);
