import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { CancelButton } from '@onc/composite-components';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from 'base-components';
import DataAvailabilityChartV2 from 'domain/AppComponents/charts/DataAvailabilityChartV2';
import { DataSourceType } from 'domain/AppComponents/charts/types/TimeSeriesScalarDataChart.types';
import DeploymentsAutocomplete from 'library/CompositeComponents/dashboard/chart-widget/DeploymentsAutocomplete';
import DeviceAutocomplete from 'library/CompositeComponents/dashboard/chart-widget/DeviceAutocomplete';
import DeviceCategoriesAutocomplete from 'library/CompositeComponents/dashboard/chart-widget/DeviceCategoriesAutocomplete';
import SensorAutocomplete from 'library/CompositeComponents/dashboard/chart-widget/SensorAutocomplete';
import Form from 'library/CompositeComponents/form/Form';
import Sensor from './types/Sensor';
import type {
  Device,
  DeviceCategory,
} from '../data-source-widget/DataSourceSelectionWidgetFilters';
import type { DeploymentDataSource } from './types/ChartWidgetConfig.types';
import type { DeploymentResponse } from 'domain/hooks/useFilteredDeployments';

export type DeploymentDataSourceDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<DeploymentDataSource, 'traceColour'>) => void;
  source?: DeploymentDataSource;
};

type DeploymentFormValues = {
  deviceCategory: DeviceCategory | null;
  device: Device | null;
  deployment: DeploymentResponse | null;
  sensor: Sensor | null;
};

const padding = 0.5;
const DeploymentDataSourceDialog = ({
  open,
  onClose,
  onSubmit,
  source = undefined,
}: DeploymentDataSourceDialogProps) => {
  const formMethods = useForm<DeploymentFormValues>({
    defaultValues: {
      deviceCategory: null,
      device: null,
      deployment: null,
      sensor: null,
    },
  });

  const { control, watch, setValue, reset } = formMethods;
  useEffect(() => {
    reset({
      deviceCategory: source?.deviceCategory || null,
      device: source?.device || null,
      deployment: source?.deployment || null,
      sensor: source?.sensor || null,
    });
  }, [source, reset]);

  const { deviceCategory, device, deployment } = watch();

  const handleFormSubmit = (data: DeploymentFormValues) => {
    const { sensor } = data;
    onSubmit({
      deviceCategory,
      device,
      deployment,
      sensor,
      dataSourceType: DataSourceType.Deployment,
      name: sensor.sensorName,
      nodeId: `${device.deviceCode}/${sensor.sensorCode}`,
      deviceId: device.deviceId,
      deviceCategoryCode: device.deviceCategoryCode,
      deviceCategoryName: device.deviceCategoryCode,
      sensorTypeCode: sensor.propertyCode,
      sensorCodeName: sensor.sensorCode,
      deviceCode: device.deviceCode,
      sensorCategoryCode: sensor.sensorCategoryCode,
      dateFrom: deployment?.dateFrom,
      dateTo: deployment?.dateTo,
    });
    onClose();
  };

  const dateFrom = useMemo(
    () => (deployment ? moment(new Date(deployment.dateFrom)) : undefined),
    [deployment]
  );

  const dateTo = useMemo(
    () => (deployment ? moment(new Date(deployment.dateTo)) : undefined),
    [deployment]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Form formMethods={formMethods} onSubmit={handleFormSubmit}>
        <DialogTitle>Select Deployment Data Source</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                pt: padding,
                pb: padding,
              }}
            >
              <Controller
                name="deviceCategory"
                control={control}
                render={({ field }) => (
                  <DeviceCategoriesAutocomplete
                    {...field}
                    deviceCategoryCode={device?.deviceCategoryCode}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                pt: padding,
                pb: padding,
              }}
            >
              <Controller
                name="device"
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <DeviceAutocomplete
                    {...field}
                    onChange={(newValue) => {
                      onChange(newValue);
                      if (newValue !== device) {
                        setValue('deployment', null);
                        setValue('sensor', null);
                      }
                    }}
                    value={field.value || null}
                    deviceCategoryCodes={
                      deviceCategory?.deviceCategoryCode
                        ? [deviceCategory.deviceCategoryCode]
                        : undefined
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                pt: device ? padding : 0,
                pb: device ? padding : 0,
              }}
            >
              <Collapse in={!!device}>
                <DataAvailabilityChartV2
                  dataSources={[
                    {
                      dataSourceType: 'device',
                      deviceCode: device?.deviceCode,
                      dataProductCode: 'LF',
                      extensions: ['txt'],
                    },
                  ]}
                />
              </Collapse>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                pt: padding,
                pb: padding,
              }}
            >
              <Controller
                name="deployment"
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <DeploymentsAutocomplete
                    {...field}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                      if (newValue !== deployment) {
                        setValue('sensor', null);
                      }
                    }}
                    deviceId={device?.deviceId}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                pt: padding,
                pb: padding,
              }}
            >
              <Controller
                name="sensor"
                control={control}
                render={({ field }) => (
                  <SensorAutocomplete
                    {...field}
                    deviceCode={device?.deviceCode}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    disabled={!device}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose} />
          <Button
            variant="contained"
            translationKey="common.buttons.add"
            onClick={formMethods.handleSubmit(handleFormSubmit)}
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default DeploymentDataSourceDialog;
