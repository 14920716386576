import React, {
  useState,
  useMemo,
  createContext,
  PropsWithChildren,
  useReducer,
  useContext,
  useCallback,
} from 'react';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import { WindowController } from 'library/CompositeComponents/dashboard/useWindowController';
import useBroadcast from 'util/hooks/useBroadcast';
import widgetConfigMapReducer, {
  WidgetConfigMap,
  type WidgetConfigMapAction,
} from './WidgetConfigMapReducer';
import { WidgetConfig } from '../DashboardTypes';

export type DashboardStateContextValue<TDashboardState = any> = {
  dashboardState: TDashboardState;
  setDashboardState: React.Dispatch<React.SetStateAction<TDashboardState>>;
};

const DashboardStateContext = createContext<
  DashboardStateContextValue | undefined
>(undefined);

export type WidgetConfigMapContextValue = {
  widgetConfigMap: WidgetConfigMap;
  dispatch: React.Dispatch<WidgetConfigMapAction>;
};

const WidgetConfigMapContext = createContext<
  WidgetConfigMapContextValue | undefined
>(undefined);

/**
 * Provider component for dashboard state and widget config management
 *
 * @template TDashboardState - Type of dashboard state
 * @param {object} props - The props object
 * @param {WidgetConfig[]} [props.defaultWidgets] - Array of existing widgets on
 *   the dashboard
 * @param {TDashboardState} [props.initialState] - Initial dashboard state
 * @param {WindowController} [props.windowController] - Window controller for
 *   broadcasting
 * @param {(widgetId: number, config: any) => void} [props.onWidgetConfigChange]
 *   - Action for updating widget configuration
 *
 * @param {React.ReactNode} [props.children] - Child components to render
 */
const DashboardStateProvider = <TDashboardState = any,>({
  defaultWidgets = [],
  initialState = {} as TDashboardState,
  onWidgetConfigChange = () => {},
  windowController = undefined,
  children = null,
}: PropsWithChildren<{
  defaultWidgets?: WidgetConfig[];
  initialState?: TDashboardState;
  onWidgetConfigChange?: (widgetId: number, config: any) => void;
  windowController?: WindowController;
}>) => {
  // Always call both hooks to maintain hook order
  const [localState, setLocalState] = useState<TDashboardState>(initialState);

  const [broadcastState, setBroadcastState] = useBroadcast<TDashboardState>(
    windowController?.parentWindowId,
    BroadcastChannel.DashboardState,
    initialState,
    `${windowController?.parentWindowId}-${windowController?.windowIndex}`
  );

  const initialWidgetConfigMap = new Map<string, any>(
    defaultWidgets?.map((widget) => [widget.i, widget.config])
  );

  // Determine which state and setter to use based on windowController
  const isMultiScreen = windowController;
  const dashboardState = isMultiScreen ? broadcastState : localState;
  const setDashboardState = isMultiScreen ? setBroadcastState : setLocalState;

  const [widgetConfigMap, baseDispatch] = useReducer(
    widgetConfigMapReducer,
    initialWidgetConfigMap || new Map<string, any>()
  );

  const dispatch = useCallback(
    (action: WidgetConfigMapAction) => {
      if (action.widgetId) {
        const nextState = widgetConfigMapReducer(widgetConfigMap, action);
        const newConfig = nextState.get(action.widgetId);
        baseDispatch(action);
        onWidgetConfigChange(Number(action.widgetId), newConfig);
      }
    },
    [widgetConfigMap, onWidgetConfigChange]
  );

  // Create memoized value for context
  const value = useMemo(
    () => ({
      dashboardState,
      setDashboardState,
    }),
    [dashboardState, setDashboardState]
  );

  const widgetConfigMapValue = useMemo(
    () => ({
      widgetConfigMap,
      dispatch,
    }),
    [dispatch, widgetConfigMap]
  );

  return (
    <DashboardStateContext.Provider value={value}>
      <WidgetConfigMapContext.Provider value={widgetConfigMapValue}>
        {children}
      </WidgetConfigMapContext.Provider>
    </DashboardStateContext.Provider>
  );
};

export const useDashboardStateContext = <TDashboardState = any,>() => {
  const context: DashboardStateContextValue<TDashboardState> = useContext(
    DashboardStateContext
  );

  if (!context) {
    throw new Error(
      'Dashboard config hooks must be used within a DashboardStateContext'
    );
  }

  return context;
};

export const useWidgetConfigMapContext = () => {
  const context = useContext(WidgetConfigMapContext);

  if (!context) {
    throw new Error(
      'Dashboard config hooks must be used within a WidgetConfigMapContext'
    );
  }

  return context;
};

export default DashboardStateProvider;
