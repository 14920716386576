import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ContainedButton } from '@onc/composite-components';
import { Grid, Typography } from 'base-components';
import DeviceSelect from 'domain/AppComponents/dropdowns/DeviceSelect';
import DeviceService from 'domain/services/DeviceService';
import FerryPVCSCommandsService from 'domain/services/FerryPVCSCommandsService';
import LastSensorReadingsService from 'domain/services/LastSensorReadingsService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import PVCSViewTable from './PVCSViewTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  dropdown: {
    margin: theme.spacing(1),
    minWidth: '400px',
  },
  deviceInfo: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const defaultState = {
  deviceCategoryId: 64,
  loading: false,
  deviceIdSel: 14080,
  deviceName: '---',
  sampleTime: '---',
};

class PVCSViewPage extends PureComponent {
  static propTypes = {
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string,
      dropdown: PropTypes.string,
      deviceInfo: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };

    this.doneWaitingForPromise = this.doneWaitingForPromise.bind(this);
  }

  handleDeviceChange = (event) => {
    const { onInfo } = this.props;
    this.setState({ deviceIdSel: event.target.value, loading: true });
    onInfo('Loading Devices');
  };

  renderDeviceInfoTexts = () => {
    const { onError } = this.props;
    const { deviceIdSel, deviceName, sampleTime } = this.state;
    DeviceService.getOne(deviceIdSel)
      .then((result) => {
        this.buildDeviceInfoTexts(result);
        this.doneWaitingForPromise();
      })
      .catch(() => {
        onError('Failed to retrieve Info for PVCS device!');
        this.doneWaitingForPromise();
      });
    LastSensorReadingsService.get(deviceIdSel)
      .then((result) => {
        this.buildSampleTimeTexts(result);
        this.doneWaitingForPromise();
      })
      .catch(() => {
        onError(`Failed to retrieve Sensor Info for deviceId ${deviceIdSel}`);
        this.doneWaitingForPromise();
      });
    return (
      <>
        <Typography variant="h6">{`Device Id: ${deviceIdSel}`}</Typography>
        <Typography variant="h6">{`Device Name: ${deviceName}`}</Typography>
        <Typography variant="h6">{`Sample Time (UTC): ${sampleTime}`}</Typography>
      </>
    );
  };

  buildDeviceInfoTexts = (payload) => {
    this.setState({
      deviceName: payload.devices.device.devicecode,
    });
  };

  buildSampleTimeTexts = (payload) => {
    if (payload.length > 0) {
      this.setState({
        sampleTime: payload[0],
      });
    }
  };

  renderOperationButtons = () => {
    const { onClick, classes, ...otherProps } = this.props;
    const permission = Environment.getDmasUserPrivilege();
    const disabled = permission !== 'RW';
    return (
      <>
        <ContainedButton
          onClick={this.handleEnableSamplingButtonClick}
          disabled={disabled}
          translationKey="device.enableSampling"
          {...otherProps}
        />
        <ContainedButton
          onClick={this.handleTurnOffButtonClick}
          disabled={disabled}
          translationKey="device.turnOff"
          {...otherProps}
        />
      </>
    );
  };

  handleEnableSamplingButtonClick = () => {
    const { onError } = this.props;
    const { deviceIdSel } = this.state;
    FerryPVCSCommandsService.enableSampling(deviceIdSel)
      .then(() => {
        this.doneWaitingForPromise();
      })
      .catch(() => {
        onError('Failed to enable sampling for PVCS device!');
        this.doneWaitingForPromise();
      });
  };

  handleTurnOffButtonClick = () => {
    const { onError } = this.props;
    const { deviceIdSel } = this.state;
    FerryPVCSCommandsService.turnOff(deviceIdSel)
      .then(() => {
        this.doneWaitingForPromise();
      })
      .catch(() => {
        onError('Failed to turn off PVCS device!');
        this.doneWaitingForPromise();
      });
  };

  doneWaitingForPromise() {
    const { loading } = this.state;
    if (loading) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { classes, onInfo, onError } = this.props;
    const { deviceIdSel, loading } = this.state;

    return (
      <>
        <div>
          <Panel classes={classes}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DeviceSelect
                  className={classes.dropdown}
                  name="deviceSelect"
                  onChange={this.handleDeviceChange}
                  onError={onError}
                  onInfo={onInfo}
                  deviceCategoryCode="PVCS"
                  value={deviceIdSel}
                  initialValue={14080}
                  disabled={loading}
                />
              </Grid>
              <Grid className={classes.deviceInfo} item xs={12}>
                <>{this.renderDeviceInfoTexts()}</>
              </Grid>
            </Grid>
          </Panel>
          <Panel>
            <PVCSViewTable
              deviceId={deviceIdSel}
              permission={Environment.getDmasUserPrivilege()}
              doneWaitingForPromise={this.doneWaitingForPromise}
            />
          </Panel>
        </div>
        <div>{this.renderOperationButtons()}</div>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(PVCSViewPage));
