import { createStyles, makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import { type option } from 'domain/AppComponents/dropdowns/PlaylistsDropdown';
import Form from 'library/CompositeComponents/form/Form';
import VideoWidgetFormContents from './VideoWidgetFormContents';

const useStyles = makeStyles(() =>
  createStyles({
    formButtons: {
      marginTop: 10,
      float: 'right',
    },
    titleField: {
      marginTop: 0,
    },
  })
);

type VideoWidgetConfigValues = {
  title?: string;
  liveStreamUrl?: string;
  playlistHdr?: option;
  device?: {
    deviceId: number;
    name: string;
    deviceCode: string;
  };
  timeSource?: string;
  locations?: {
    deviceCategoryCode: string;
    stationCode: string;
    pathName: string[];
    els: { deviceId: number; sitedeviceid: number }[];
  }[];
  loopPlayback?: boolean;
  showTitle: boolean;
  dataSource: string;
  startDate: Moment;
  endDate: Moment;
};

type VideoWidgetConfigProps = {
  onCancel: () => void;
  onSave: (values) => Promise<void>;
  open: boolean;
} & VideoWidgetConfigValues;

const VideoWidgetConfig = ({
  onCancel,
  onSave,
  startDate,
  endDate,
  ...rest
}: VideoWidgetConfigProps) => {
  const classes = useStyles();

  const formMethods = useForm<VideoWidgetConfigValues>({
    values: {
      startDate: startDate ? moment(startDate) : undefined,
      endDate: endDate ? moment(endDate) : undefined,
      ...rest,
    },
    mode: 'onBlur',
  });

  const { watch } = formMethods;

  const timeSource = watch('timeSource');
  const dataSource = watch('dataSource');
  const showTitle = watch('showTitle');
  const device = watch('device');
  const locations = watch('locations');

  return (
    <Dialog open fullWidth>
      <DialogTitle>Configure Video Widget</DialogTitle>
      <DialogContent>
        <Form onSubmit={onSave} formMethods={formMethods}>
          <VideoWidgetFormContents
            showTitle={showTitle}
            loopPlayback={rest.loopPlayback}
            title={rest.title}
            dataSource={dataSource}
            timeSource={timeSource}
            endDate={moment(endDate)}
            startDate={moment(startDate)}
            device={device}
            liveStreamUrl={rest.liveStreamUrl}
            playlistHdr={rest.playlistHdr}
            locations={locations}
          />
          <div className={classes.formButtons}>
            <CancelButton onClick={onCancel} />
            <SaveButton />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default VideoWidgetConfig;
