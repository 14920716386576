import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ClearButton, ContainedButton } from '@onc/composite-components';
import {
  Checkbox,
  Dropdown,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
} from 'base-components';
import AllDevicesAutocomplete from 'domain/AppComponents/dropdowns/AllDevicesAutocomplete';
import QaqcFinderSensorDropdown from 'domain/AppComponents/dropdowns/QaqcFinderSensorDropdown';
import QaqcFinderSensorTypeDropdown from 'domain/AppComponents/dropdowns/QaqcFinderSensorTypeDropdown';
import QaqcFinderStationDropdown from 'domain/AppComponents/dropdowns/QaqcFinderStationDropdown';
import QaqcFinderStudyAreaDropdown from 'domain/AppComponents/dropdowns/QaqcFinderStudyAreaDropdown';
import QaqcFinderTestGroupDropdown from 'domain/AppComponents/dropdowns/QaqcFinderTestGroupDropdown';
import QaqcFinderTestLevelDropdown from 'domain/AppComponents/dropdowns/QaqcFinderTestLevelDropdown';
import {
  INSTRUMENT,
  OBSERVATORY,
  REGION,
  STATION,
  STUDY_AREA,
  QAQC_TEST_GROUP_REGION_ID,
  QAQC_TEST_GROUP_OBSERVATORY_ID,
  DEVICE_AND_SENSOR,
} from 'domain/services/QaqcAutotestsConstants';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  dropdown: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  divClass: {
    margin: theme.spacing(1),
  },
});

const defaultState = {
  searchActive: false,
  testLevelValue: 0,
  testLevel: '',
  idValues: {
    qaqcTestGroupId: 0,
    searchTreeNodeId: 0,
    sensorId: 0,
    sensorTypeId: 0,
    studyAreaId: 0,
    deviceAutocomplete: null,
    sensorIds: '',
    activeOnly: true,
  },
};

class QaqcFinderDropdowns extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClear: PropTypes.func,
    onFind: PropTypes.func,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onClear: () => {},
    onFind: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  getDropdownComponent = () => {
    const { classes, onInfo, onError } = this.props;
    const { testLevel, idValues } = this.state;
    const {
      sensorId,
      sensorTypeId,
      qaqcTestGroupId,
      searchTreeNodeId,
      studyAreaId,
    } = idValues;
    switch (testLevel) {
      case INSTRUMENT:
        return (
          <QaqcFinderSensorDropdown
            className={classes.dropdown}
            fullWidth
            sensorTypeId={sensorTypeId}
            name="sensorId"
            value={sensorId}
            onChange={this.handleChange}
            onInfo={onInfo}
            onError={onError}
          />
        );
      case OBSERVATORY:
        return (
          <QaqcFinderTestGroupDropdown
            className={classes.dropdown}
            fullWidth
            testGroupTypeId={QAQC_TEST_GROUP_OBSERVATORY_ID}
            name="qaqcTestGroupId"
            value={qaqcTestGroupId}
            onChange={this.handleChange}
            onInfo={onInfo}
            onError={onError}
          />
        );
      case REGION:
        return (
          <QaqcFinderTestGroupDropdown
            className={classes.dropdown}
            fullWidth
            testGroupTypeId={QAQC_TEST_GROUP_REGION_ID}
            name="qaqcTestGroupId"
            value={qaqcTestGroupId}
            onChange={this.handleChange}
            onInfo={onInfo}
            onError={onError}
          />
        );
      case STATION:
        return (
          <QaqcFinderStationDropdown
            className={classes.dropdown}
            fullWidth
            name="searchTreeNodeId"
            value={searchTreeNodeId}
            onChange={this.handleChange}
            onInfo={onInfo}
            onError={onError}
          />
        );
      case STUDY_AREA:
        return (
          <QaqcFinderStudyAreaDropdown
            className={classes.dropdown}
            fullWidth
            name="studyAreaId"
            value={studyAreaId}
            onChange={this.handleChange}
            onInfo={onInfo}
            onError={onError}
          />
        );
      default:
        return (
          <Dropdown
            className={classes.dropdown}
            disabled
            fullWidth
            options={[{ value: 0, label: '' }]}
          />
        );
    }
  };

  handleChange = (event) => {
    const { idValues } = this.state;
    this.setState({
      idValues: { ...idValues, [event.target.name]: event.target.value },
    });
  };

  handleDeviceSensorIdChange = (event) => {
    const { idValues } = this.state;
    this.setState({
      idValues: {
        ...idValues,
        [event.target.name]: event.target.value,
      },
      testLevelValue: 0,
      testLevel: DEVICE_AND_SENSOR,
    });
  };

  handleCheckboxChange = (event) => {
    const { idValues } = this.state;
    this.setState({
      idValues: {
        ...idValues,
        activeOnly: event.target.checked,
      },
    });
  };

  render() {
    const { classes, onClear, onFind, onInfo, onError } = this.props;
    const { searchActive, testLevelValue, testLevel, idValues } = this.state;
    const { activeOnly, sensorTypeId, deviceAutocomplete, sensorIds } =
      idValues;

    return (
      <div className={classes.divClass}>
        <Typography variant="subtitle1">Search by Test Level</Typography>
        <Grid container direction="column">
          <Grid item lg={4} md={6} sm={12}>
            <QaqcFinderTestLevelDropdown
              className={classes.dropdown}
              fullWidth
              value={testLevelValue}
              onChange={(event, label) =>
                this.setState({
                  testLevelValue: event.target.value,
                  testLevel: label,
                  idValues: defaultState.idValues,
                })
              }
              onInfo={onInfo}
              onError={onError}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <QaqcFinderSensorTypeDropdown
              className={classes.dropdown}
              disabled={testLevelValue === 0}
              fullWidth
              name="sensorTypeId"
              value={sensorTypeId}
              onChange={this.handleChange}
              onInfo={onInfo}
              onError={onError}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            {this.getDropdownComponent()}
          </Grid>
        </Grid>
        <Typography variant="subtitle1">
          Search by Device and Sensor IDs
        </Typography>
        <Grid container direction="column">
          <Grid item lg={4} md={6} sm={12} classes={{ root: classes.dropdown }}>
            <AllDevicesAutocomplete
              className={classes.dropdown}
              onChange={this.handleDeviceSensorIdChange}
              onError={onError}
              value={deviceAutocomplete}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <TextField
              sx={{
                root: classes.textField,
                textField: classes.textField,
              }}
              value={sensorIds}
              onChange={this.handleDeviceSensorIdChange}
              fullWidth
              title="sensorIds"
              name="sensorIds"
              translationKey="device.sensorIds"
            />
          </Grid>
        </Grid>
        <ContainedButton
          translationKey="common.buttons.find"
          onClick={() => {
            this.setState({ searchActive: true });
            onFind(testLevel, idValues);
          }}
        />
        <ClearButton
          variant="contained"
          disabled={!searchActive}
          onClick={() => {
            this.setState({
              ...defaultState,
            });
            onClear();
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activeOnly}
              onChange={this.handleCheckboxChange}
              name="activeOnly"
            />
          }
          label="Active Only"
          labelPlacement="start"
        />
      </div>
    );
  }
}

export default withStyles(styles)(QaqcFinderDropdowns);
