/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from 'react';
import { TableColumn, useSnackbars } from 'base-components';
import AnnotationTable from 'domain/AppComponents/sea-tube/annotation-table/AnnotationTable';
import AnnotationTableLogic from 'domain/AppComponents/sea-tube/annotation-table/AnnotationTableLogic';

import TableAnnotation from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import SeaTubeLogContext from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import SeaTubePermissionContext from 'domain/AppComponents/sea-tube/SeaTubePermissionContext';
import AnnotationService from 'domain/services/AnnotationService';
import { SeaTubeAnnotationPermissions } from 'domain/services/SeaTubePermissionsService';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSessionStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItemCheckbox,
} from '../../library/CompositeComponents/dashboard/Widget';
import QueryParameterContext, {
  QueryParameters,
} from '../Apps/menu/QueryParameterContext';

const AUTO_REFRESH_INTERVAL_MILLIS = 10000;

const DEFAULT_PERMISSIONS: SeaTubeAnnotationPermissions = {
  canCreate: false,
  canPublish: false,
  canReview: false,
  canEdit: () => false,
  canDelete: () => false,
};

const AnnotationTableWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, dashboardId } = props;
  const [autoRefreshCheckbox, setAutoRefreshCheckbox] = useState(false);
  const { onInfo, onError } = useSnackbars();

  const { cruiseId, cruise, logType } = useContext(SeaTubeLogContext);
  const permissions = useContext(SeaTubePermissionContext);
  const params = useContext<QueryParameters>(QueryParameterContext);
  const { dive } = useContext(SeaTubeLogContext);

  const logId = dive ? dive.diveId : cruise.cruiseId;

  const [rows, loadingRows, fetchRows] = useWebService({
    method: AnnotationService.getAnnotations,
    onError,
    parser: AnnotationTableLogic.formatRows,
  });

  const [savedEditAnnotation] = useSessionStorage<TableAnnotation>(
    `${logType}-${logId}-edit-annotation`,
    null
  );

  const [, setEditAnnotation] = useBroadcast<TableAnnotation>(
    dashboardId,
    BroadcastChannel.Annotation,
    savedEditAnnotation,
    id
  );
  const [, setDeleteAnnotationId] = useBroadcast(
    dashboardId,
    BroadcastChannel.AnnotationDelete,
    undefined,
    id
  );
  const [triggerRefresh, setTriggerRefresh] = useBroadcast(
    dashboardId,
    BroadcastChannel.RefreshAnnotations,
    false,
    id
  );

  useEffect(() => {
    if (cruise) {
      fetchRows({ cruiseIds: [cruiseId] });
    }
  }, [cruise]);

  useEffect(() => {
    if (triggerRefresh) {
      fetchRows({ cruiseIds: [cruiseId] });
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoRefreshCheckbox) {
        fetchRows({ cruiseIds: [cruiseId] });
      }
    }, AUTO_REFRESH_INTERVAL_MILLIS);

    return () => clearInterval(interval);
  }, [autoRefreshCheckbox]);

  const AutoRefreshCheckbox = (
    <WidgetMenuItemCheckbox
      key="auto-refresh-checkbox"
      onClick={() => {
        setAutoRefreshCheckbox(!autoRefreshCheckbox);
      }}
      label="Auto Refresh"
      checked={autoRefreshCheckbox}
    />
  );

  const handleDeleteSuccess = () => {
    onInfo('Annotation deleted.');
    setTriggerRefresh(true);
  };

  const additionalColumns: TableColumn[] = [
    { name: 'cruiseName', title: 'Expedition', dataType: 'String' },
    { name: 'logName', title: 'Log', dataType: 'String' },
  ];

  const { annotationId } = params;
  return (
    <Widget
      key={id}
      title="Annotation Table"
      loading={loadingRows}
      MenuItems={[AutoRefreshCheckbox]}
      ariaLabel="annotation-table-widget"
      {...props}
    >
      <AnnotationTable
        rows={rows || []}
        permissions={permissions || DEFAULT_PERMISSIONS}
        onEdit={setEditAnnotation}
        onRefresh={() => fetchRows({ cruiseIds: [cruiseId] })}
        onInfo={onInfo}
        onError={onError}
        scrollToRow={params && annotationId ? Number(annotationId) : undefined}
        expeditionId={cruiseId}
        onDeleteSuccess={handleDeleteSuccess}
        broadcastDelete={setDeleteAnnotationId}
        additionalColumns={additionalColumns}
        disabledColumns={['logName', 'cruiseName']}
      />
    </Widget>
  );
};

AnnotationTableWidget.widgetKey = 'annotation-table';
AnnotationTableWidget.widgetTitle = 'Annotation Table';
AnnotationTableWidget.defaultDataGrid = {
  i: 'annotation-table',
  x: 0,
  y: 0,
  w: 9,
  h: 18,
};

export default AnnotationTableWidget;
