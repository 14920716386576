import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Divider } from 'base-components';
import { ONC_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import {
  formatNumberForDisplay,
  getFormattedUnit,
} from 'domain/Apps/seatube/sensor-display/util/SensorUtil';
import { LocationDetails } from 'domain/services/LocationsWebServiceWithToken';
import ResizingInfoList from 'library/CompositeComponents/list-container/ResizingInfoList';
import InfoItem from 'library/CompositeComponents/list-items/InfoItem';
import InfoItemScrollable from 'library/CompositeComponents/list-items/InfoItemScrollable';
import InfoItemWithIcon from 'library/CompositeComponents/list-items/InfoItemWithIcon';
import OrganizationLogo from 'library/CompositeComponents/logo/OrganizationLogo';

type SearchTreeNodeDetailsProps = {
  searchTreeNodeDetails?: LocationDetails;
};

export const defaultDetails: LocationDetails = {
  deployments: undefined,
  locationName: 'Fixed Video Cameras',
  depth: undefined,
  bbox: {
    maxDepth: undefined,
    maxLat: undefined,
    maxLon: undefined,
    minDepth: undefined,
    minLat: undefined,
    minLon: undefined,
  },
  description:
    'The Fixed Video Camera Locations page lists sites where fixed cameras at the seafloor and time-lapse cameras at coastal shore stations produce video recordings.',
  hasDeviceData: 'true',
  lon: undefined,
  locationCode: 'FXCMR',
  hasPropertyData: 'false',
  lat: undefined,
  dataSearchURL: '',
};

const SearchTreeNodeDetails = ({
  searchTreeNodeDetails = defaultDetails,
}: SearchTreeNodeDetailsProps) => {
  const { depth, description, lat, locationName, locationCode, lon, bbox } =
    searchTreeNodeDetails;
  const [formattedBbox, setFormattedBbox] = useState(bbox);
  const [formattedLatLonDepth, setFormattedLatLonDepth] = useState({
    lat,
    lon,
    depth,
  });

  const formatObjNumbersForDisplay = (obj) => {
    const newBbox = obj;
    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        newBbox[key] = formatNumberForDisplay(value);
      }
    }
    return newBbox;
  };
  useEffect(() => {
    setFormattedBbox(formatObjNumbersForDisplay(bbox));
    setFormattedLatLonDepth(
      formatObjNumbersForDisplay({
        lat,
        lon,
        depth,
      })
    );
  }, [bbox, lat, lon, depth]);

  const degrees = getFormattedUnit('deg');

  const renderBboxValues = () =>
    !Object.values(formattedBbox).includes(undefined) ? (
      <>
        <Divider />
        <div style={{ display: 'flex' }}>
          <InfoItem
            primary="Min Latitude"
            secondary={`${formattedBbox.minLat}${degrees}`}
          />
          <InfoItem
            primary="Max Latitude"
            secondary={`${formattedBbox.maxLat}${degrees}`}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <InfoItem
            primary="Min Longitude"
            secondary={`${formattedBbox.minLon}${degrees}`}
          />
          <InfoItem
            primary="Max Longitude"
            secondary={`${formattedBbox.maxLon}${degrees}`}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <InfoItem
            primary="Min Depth (m)"
            secondary={`${formattedBbox.minDepth}`}
          />
          <InfoItemScrollable
            primary="Max Depth (m)"
            secondary={formattedBbox.maxDepth}
          />
        </div>
      </>
    ) : undefined;

  return (
    <ResizingInfoList>
      <InfoItemWithIcon>
        <OrganizationLogo id={ONC_DATA.organizationId} />
        <InfoItem primary="Search Tree Node" secondary={locationName} />
      </InfoItemWithIcon>
      <InfoItem primary="Code" secondary={locationCode} />
      <InfoItemScrollable
        primary="Search Tree Node Description"
        secondary={description ? ReactHtmlParser(description) : '(none)'}
      />
      <InfoItem
        primary="Latitude"
        secondary={`${formattedLatLonDepth.lat || 'N/A'}${degrees}`}
      />
      <InfoItem
        primary="Longitude"
        secondary={`${formattedLatLonDepth.lon || 'N/A'}${degrees}`}
      />
      <InfoItem
        primary="Depth (m)"
        secondary={formattedLatLonDepth.depth || 'N/A'}
      />
      {renderBboxValues()}
    </ResizingInfoList>
  );
};

export default SearchTreeNodeDetails;
