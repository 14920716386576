import { useContext } from 'react';
import * as React from 'react';
import SeaTubeDashboard from 'domain/AppComponents/sea-tube/SeaTubeDashboard';
import AnnotationTableWidget from 'domain/Widgets/AnnotationTableWidget';
import ButtonSetWidget from 'domain/Widgets/ButtonSetWidget';
import ManualEntryWidget from 'domain/Widgets/ManualEntryWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import { WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWindowController from 'library/CompositeComponents/dashboard/useWindowController';
import {
  defaultAnonymousLayout,
  defaultAnonymousWidgets,
  defaultUserLayout,
  defaultUserWidgets,
} from './layouts';
import SeaTubeLogContext from '../SeaTubeLogContext';
import SeaTubePermissionContext from '../SeaTubePermissionContext';

type Props = {
  primaryWindowId: string;
};

const emptyLayout = {
  xl: [],
  lg: [],
  md: [],
  sm: [],
  xs: [],
};

const widgetOptions: WidgetOption[] = [
  {
    Component: ManualEntryWidget,
    label: 'Annotation Entry',
    multiple: false,
  },
  {
    Component: AnnotationTableWidget,
    label: 'Annotation Table',
    multiple: false,
  },
  {
    Component: ButtonSetWidget,
    label: 'Quick Button Set',
    multiple: true,
  },
];

const anonymousWidgetOptions: WidgetOption[] = [
  {
    Component: AnnotationTableWidget,
    label: 'Annotation Table',
    multiple: false,
  },
];

const DeckLogDashboard: React.VFC<Props> = ({ primaryWindowId }: Props) => {
  const { cruiseId, cruise } = useContext(SeaTubeLogContext);
  const { canCreate } = useContext(SeaTubePermissionContext);
  const windowController = useWindowController(primaryWindowId);
  const { windowIndex } = windowController;
  const { cruiseName } = cruise;

  const widgetLibrary = new WidgetLibrary(
    canCreate ? widgetOptions : anonymousWidgetOptions
  );

  const userType = canCreate ? 'User' : 'Anonymous';

  const getDefaultLayout = () => {
    if (windowIndex === 1) {
      return canCreate ? defaultUserLayout : defaultAnonymousLayout;
    }
    return emptyLayout;
  };

  const getDefaultWidgets = () => {
    if (windowIndex === 1) {
      return canCreate ? defaultUserWidgets : defaultAnonymousWidgets;
    }
    return [];
  };

  return !windowController.loading ? (
    <SeaTubeDashboard
      title={`Deck Log - ${cruiseName}`}
      id={`${userType}-Deck-Log-Cruise-${cruiseId}`}
      layoutKey={`${userType}-Deck-Log`}
      widgetLibrary={widgetLibrary}
      windowController={windowController}
      defaultLayout={getDefaultLayout()}
      defaultWidgets={getDefaultWidgets()}
    />
  ) : (
    <></>
  );
};

export default DeckLogDashboard;
