import { useEffect, useState } from 'react';
import { CableTypeService, CableType } from '@onc/domain-services';
import { useWebService } from '@onc/service';
import { Dialog, useSnackbars } from 'base-components';
import EditableDomainForm from 'library/CompositeComponents/editable-domain/EditableDomainForm';

type CableTypeDialogProps = {
  initialValue: Partial<CableType>;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const EMPTY_FORM: CableType = {
  name: '',
  description: '',
};

const CableTypeDialog = ({
  initialValue,
  open,
  onClose,
  onSuccess,
}: CableTypeDialogProps) => {
  const [cableForm, setCableTypeForm] = useState<CableType>(EMPTY_FORM);
  const { onInfo, onError } = useSnackbars();

  const formFields = {
    cableTypeName: String,
    description: String,
  };

  const columns = [
    {
      name: 'cableTypeName',
      label: 'Cable Type Name',
      fullWidth: true,
      rules: { required: 'Cable Type Name Required' },
    },
    {
      name: 'description',
      label: 'Description',
      fullWidth: true,
    },
  ];

  // When true, create a new CableType
  // when false, edit an existing CableType
  const [isCreate, setIsCreate] = useState(false);

  const [, , createCableType] = useWebService({
    method: CableTypeService.createCableType,
  });

  const [, , updateCableType] = useWebService({
    method: CableTypeService.updateCableType,
  });

  useEffect(() => {
    if (!initialValue) {
      setIsCreate(true);
      setCableTypeForm(EMPTY_FORM);
      return;
    }
    setIsCreate(false);
    setCableTypeForm({
      name: initialValue.name,
      description: initialValue.description,
    });
  }, [initialValue]);

  const saveCableType = (response, cableTypeName) => {
    if (response.name === cableTypeName) {
      onInfo('Cable Type saved successfully!');
      onClose();
      onSuccess();
    } else {
      onError('Cable Type failed to save');
    }
  };

  const handleSubmit = ({ cableTypeName, description }) => {
    if (isCreate) {
      return createCableType({
        name: cableTypeName,
        description,
      })
        .then((response) => {
          saveCableType(response, cableTypeName);
        })
        .catch((err) => {
          onError(err);
        });
    }
    return updateCableType({
      cableTypeId: initialValue.cableTypeId,
      name: cableTypeName,
      description,
    })
      .then((response) => {
        saveCableType(response, cableTypeName);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const cableTypeName = cableForm.name;
  const { description } = cableForm;

  const values = {
    values: { cableTypeName, description },
  };

  return (
    <Dialog open={open}>
      <EditableDomainForm
        isCreate={isCreate}
        onClose={onClose}
        onSubmit={handleSubmit}
        formFields={formFields}
        formName="Cable Type"
        columns={columns}
        values={values}
      />
    </Dialog>
  );
};

export default CableTypeDialog;
