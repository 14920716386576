import { memo, useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Loading } from '@onc/composite-components';
import { Autocomplete } from 'base-components';
import DeviceInfo from 'domain/Apps/device-listing/DeviceInfo';
import CommunityFishersDevicePackageService from 'domain/services/CommunityFishersDevicePackageService';
import DeviceService from 'domain/services/DeviceService';
import useWebService from 'util/hooks/useWebService';
import type { CommunityFishersDevicePackage } from 'domain/Apps/community-fishers/CommunityFishersDevicePackageTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    styling: {
      paddingTop: theme.spacing(1),
    },
    text: {
      paddingLeft: theme.spacing(1),
    },
  })
);

export type Module = 'Device Action' | 'Site Device';

interface DeviceBulkUpdateFilterStepProps {
  selectedCFSets: CommunityFishersDevicePackage[];
  handleCFSetChange: (
    event: any,
    value: CommunityFishersDevicePackage[]
  ) => void;
  selectedDevices: DeviceInfo[];
  handleDeviceChange: (event: any, value: DeviceInfo[]) => void;
  module: Module;
  setModule: (module: Module) => void;
  isPerformingAction: boolean;
}

const DeviceBulkUpdateFilterStep = ({
  selectedCFSets,
  handleCFSetChange,
  selectedDevices,
  handleDeviceChange,
  module,
  setModule,
  isPerformingAction,
}: DeviceBulkUpdateFilterStepProps) => {
  const classes = useStyles();
  const [deviceData, isDeviceLoading, fetchDevices] = useWebService({
    method: DeviceService.getAllDevices,
  });
  const [cfSetData, isCFLoading, fetchCFSets] = useWebService({
    method: CommunityFishersDevicePackageService.getAllDevicePackages,
  });

  const handleModuleChange = (event, option: Module) => {
    setModule(option);
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchCFSets(), fetchDevices()]);
    };

    fetchData();
  }, [fetchDevices, fetchCFSets]);

  return isPerformingAction ? (
    <Loading />
  ) : (
    <div>
      <Autocomplete
        name="cfSets"
        translationKey="device.bulkUpdate.cfDevicePackages"
        fullWidth
        multiple
        onChange={handleCFSetChange}
        value={selectedCFSets}
        options={isCFLoading ? [] : cfSetData || []}
        isOptionEqualToValue={(
          option: CommunityFishersDevicePackage,
          value: CommunityFishersDevicePackage
        ) => option.setInformation.setName === value.setInformation.setName}
        getOptionLabel={(option: CommunityFishersDevicePackage) => {
          const communityNames =
            option.setInformation?.communityNamesViaDataAttributions?.join(
              ', '
            );
          if (communityNames)
            return `${option.setInformation.setName} - ${communityNames}`;
          return `${option.setInformation.setName}` || '';
        }}
        className={classes.styling}
      />
      <Autocomplete
        name="devices"
        translationKey="device.bulkUpdate.devices"
        fullWidth
        multiple
        required
        onChange={handleDeviceChange}
        value={selectedDevices}
        options={isDeviceLoading ? [] : deviceData?.devices || []}
        isOptionEqualToValue={(option: DeviceInfo, value: DeviceInfo) =>
          option.deviceId === value.deviceId
        }
        getOptionLabel={(option: DeviceInfo) =>
          `${option.deviceName} (${option.deviceId})` || ''
        }
        className={classes.styling}
      />
      <Autocomplete
        name="module"
        translationKey="device.bulkUpdate.module"
        fullWidth
        required
        onChange={handleModuleChange}
        value={module}
        options={['Device Action', 'Site Device']}
        isOptionEqualToValue={(option: Module, value: Module) =>
          option === value
        }
        getOptionLabel={(option: Module) => option || ''}
        className={classes.styling}
      />
    </div>
  );
};

export default memo(DeviceBulkUpdateFilterStep);
