import { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import Environment from '@onc/environment/src';
import { Refresh } from '@onc/icons/src';
import { Divider, IconButton, Typography, useSnackbars } from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import { ShowFilterButton } from 'domain/AppComponents/IconButtons';
import VirtualAnnotationList, {
  ListAnnotation,
  publicToListAnnotation,
} from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import {
  PublicAnnotation,
  useDeleteAnnotation,
  usePublicAnnotation,
} from 'domain/services/AnnotationService';
import mockUnlistedAnnotationData from 'domain/services/mocks/mock-data/mockUnlistedAnnotationData';
import {
  SeaTubeAnnotationPermissions,
  useFixedCameraPermissions,
} from 'domain/services/SeaTubePermissionsService';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import AnnotationListFilterDialog from 'library/CompositeComponents/annotation-list/AnnotationListFilterDialog';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget, {
  WidgetMenuItemCheckbox,
} from 'library/CompositeComponents/dashboard/Widget';
import { VideoInterval } from 'library/CompositeComponents/video/SeamlessVideoUtil';
import useBroadcast from 'util/hooks/useBroadcast';
import { useLocalStorage } from 'util/hooks/useStorage';
import { AnnotationListConfig } from '../AnnotationListWidget';

const AUTO_REFRESH_INTERVAL_MILLIS = 10000;
const EMPTY_FILTER = {
  cruises: [],
  dives: [],
  modifiers: [],
  creators: [],
  comment: '',
  organizationId: 0,
};

type ExpectedDashboardState = {
  resourceId: string;
  date: Moment;
  videoIntervals: VideoInterval[];
  editAnnotation: ListAnnotation | null;
  selectedAnnotation: ListAnnotation;
  searchTreeNodeCode: string;
  lastEditedAnnotationId: number;
};

type Option = {
  value: number;
  label: string;
};

const FixedCameraAnnotationListWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, dashboardId, hasPrimaryFocus } = props;

  /** State variables */
  const [annotations, setAnnotations] = useState<PublicAnnotation[]>([]);
  const [deleteAnnotationId, setDeleteAnnotationId] = useState(null);
  const [, setSelectedAnnotationId] = useState(-1);
  const [scrollToIndex, setScrollToIndex] = useState(-1);
  const [setFilter] = useState<any>({});
  const [filterState, setFilterState] = useState<any>(EMPTY_FILTER);
  const [showFilter, setShowFilter] = useState(false);
  const [creators] = useState<Option[]>([]);
  const [modifiers] = useState<Option[]>([]);
  const [annotationCount, setAnnotationCount] = useState(0);
  const [localStorageConfig, saveConfig] =
    useLocalStorage<AnnotationListConfig>('fc-annotation-list-display-config', {
      autoRefresh: false,
      displayFullTimestamp: false,
      sortMostRecent: false,
      displayPositionalData: false,
      displayModifiedBy: false,
      displayCopyLink: true,
      displayEditIcon: true,
      displayDeleteIcon: true,
      displayVoteIcons: true,
      displayCreatedBy: false,
      displayTaxonAttributes: false,
      highlightToBeReviewed: false,
      hidePublicAnnotations: false,
    });
  const { widgetConfig: config, setConfig } =
    useWidgetConfig<AnnotationListConfig>(id, localStorageConfig);

  const { dashboardState, setDashboardStateProperty } =
    useDashboardState<ExpectedDashboardState>();
  const {
    resourceId,
    editAnnotation,
    videoIntervals,
    searchTreeNodeCode,
    date,
    lastEditedAnnotationId,
  } = dashboardState;

  const {
    data: publisherPermissions,
    isLoading,
    isFetching,
  } = useFixedCameraPermissions();

  const {
    data: serviceAnnotations,
    isLoading: isAnnosLoading,
    isFetching: isAnnosFetching,
    invalidateQuery: fetchAnnotations,
  } = usePublicAnnotation({
    includeIds: true,
    locationCode: searchTreeNodeCode,
    dateFrom: moment.utc(date).startOf('day').toISOString(),
    dateTo: moment.utc(date).endOf('day').toISOString(),
  });
  const [currentTimestamp, setCurrentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    undefined,
    id
  );

  const memoizedConfig = useMemo<AnnotationListConfig>(() => config, [config]);

  const listAnnotations = useMemo(
    () => annotations?.map((anno) => publicToListAnnotation(anno)),
    [annotations]
  );

  const { onError, onInfo } = useSnackbars();

  const { mutate: deleteAnnotation, isLoading: deleteLoading } =
    useDeleteAnnotation(
      () => onInfo('Annotation deleted successfully'),
      () => onError('Error deleting annotation')
    );

  const userId = String(Environment.getDmasUserId());

  const isOwner = (anno: ListAnnotation) =>
    Number(anno.createdBy.userCode) === Number(userId);

  const permissions: SeaTubeAnnotationPermissions = {
    canCreate: true,
    canPublish: publisherPermissions?.[0].value,
    canReview: false,
    canEdit: (anno) => {
      // If the annotation is public, must be a publisher
      if (anno.isPublic) {
        return publisherPermissions?.[0].value;
      }
      // Can only edit an annotation that belongs to the user
      return isOwner(anno);
    },
    canDelete: (anno) => {
      // If the annotation is public, must be a publisher
      if (anno.isPublic && isOwner(anno)) {
        return publisherPermissions?.[0].value;
      }
      // Can only edit an annotation that belongs to the user
      return isOwner(anno);
    },
  };

  useEffect(() => {
    fetchAnnotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  // Save config to local storage
  useEffect(() => {
    saveConfig(memoizedConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedConfig]);

  useEffect(() => {
    setAnnotationCount(listAnnotations?.length);
  }, [listAnnotations]);

  useEffect(() => {
    if (lastEditedAnnotationId) {
      fetchAnnotations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastEditedAnnotationId]);

  useEffect(() => {
    if (serviceAnnotations) {
      setAnnotations(
        serviceAnnotations?.filter((anno) =>
          localStorageConfig.hidePublicAnnotations ? !anno.isPublic : anno
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageConfig.hidePublicAnnotations, serviceAnnotations]);

  useEffect(() => {
    if (!currentTimestamp) {
      setSelectedAnnotationId(null);
      return;
    }
    const index = AnnotationUtil.selectClosestAnnotationToTimestamp(
      listAnnotations,
      moment.utc(currentTimestamp),
      localStorageConfig.sortMostRecent,
      setSelectedAnnotationId
    );
    setScrollToIndex(index);
    if (index && index < annotations.length) {
      setSelectedAnnotationId(annotations[index].annotationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimestamp]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (localStorageConfig.autoRefresh) {
        setAnnotations(mockUnlistedAnnotationData.annotations);
      }
    }, AUTO_REFRESH_INTERVAL_MILLIS);

    return () => clearInterval(refreshInterval);
  }, [localStorageConfig.autoRefresh, localStorageConfig.sortMostRecent]);

  const RefreshButton = (
    <IconButton onClick={() => fetchAnnotations()} aria-label="Refresh">
      <Refresh />
    </IconButton>
  );

  const handleChange = (property: keyof AnnotationListConfig) => () =>
    setConfig({
      ...localStorageConfig,
      [property]: !localStorageConfig[property],
    });

  const handleCopyLink = useCallback(
    (annotation: ListAnnotation) => {
      const url = `${Environment.getLinkUrl()}/app/fixed-camera-locations/${resourceId}?annotationId=${annotation.annotationId}&time=${annotation.startDate}`;
      navigator.clipboard.writeText(`${url}`).then(
        () => {
          onInfo('Link Copied to Clipboard');
        },
        () => {
          onError('Unable to copy link');
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceId]
  );

  const handleAnnotationClick = useCallback((annotation: ListAnnotation) => {
    setSelectedAnnotationId(annotation.annotationId);
    setDashboardStateProperty('selectedAnnotation', annotation);
    setCurrentTimestamp(annotation.startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Rendering */
  const renderSectionHeader = (title: string) => (
    <Typography
      variant="subtitle2"
      color="textSecondary"
      sx={{ paddingLeft: '16px', paddingBottom: '8px' }}
    >
      {title}
    </Typography>
  );
  const renderCheckbox = (key: keyof AnnotationListConfig, label: string) => (
    <WidgetMenuItemCheckbox
      key={key}
      label={label}
      checked={localStorageConfig[key]}
      onClick={handleChange(key)}
    />
  );

  const renderContent = () => (
    <VirtualAnnotationList
      annotations={listAnnotations}
      permissions={permissions}
      memoizedConfig={memoizedConfig}
      scrollToIndex={hasPrimaryFocus ? undefined : scrollToIndex}
      currentTimestamp={currentTimestamp}
      videoIntervals={videoIntervals}
      onClickDelete={(anno: ListAnnotation) =>
        setDeleteAnnotationId(anno.annotationId)
      }
      onClick={handleAnnotationClick}
      onCopyLink={handleCopyLink}
      onEdit={(listAnnotation) => {
        setDashboardStateProperty('editAnnotation', listAnnotation);
      }}
      onAnnotationReviewClick={() => {}}
      editAnnotationId={editAnnotation?.annotationId}
      lastEditedAnnotationId={lastEditedAnnotationId}
    />
  );

  const handleDelete = () => {
    deleteAnnotation({ annotationId: deleteAnnotationId });
    setDeleteAnnotationId(null);
    fetchAnnotations();
  };

  return (
    <Widget
      title="Annotation List"
      id={id}
      loading={
        isAnnosLoading ||
        isAnnosFetching ||
        isFetching ||
        isLoading ||
        !annotations
      }
      actionComponents={[
        RefreshButton,
        <ShowFilterButton onClick={() => setShowFilter(true)} />,
        <Typography variant="body2" color="textSecondary">
          {annotationCount}
        </Typography>,
      ]}
      MenuItems={[
        renderCheckbox('autoRefresh', 'Auto Refresh'),
        <Divider />,
        renderSectionHeader('Display Options'),
        renderCheckbox('displayFullTimestamp', 'Full Timestamp'),
        renderCheckbox('displayCopyLink', 'Copy Link'),
        renderCheckbox('displayEditIcon', 'Edit'),
        renderCheckbox('displayDeleteIcon', 'Delete'),
        renderCheckbox('displayVoteIcons', 'Vote'),
        renderCheckbox('displayCreatedBy', 'Created By'),
        renderCheckbox('displayModifiedBy', 'Modified By'),
        renderCheckbox('displayTaxonAttributes', 'Taxon Attributes'),
        renderCheckbox('highlightToBeReviewed', 'To Be Reviewed'),
        <Divider />,
        renderCheckbox('hidePublicAnnotations', 'Hide Public Annotations'),
      ]}
      {...props}
    >
      <DeleteDialog
        open={!!deleteAnnotationId}
        title="Delete Annotation?"
        onCancel={() => setDeleteAnnotationId(false)}
        onDelete={handleDelete}
        loading={deleteLoading}
        message="Are you sure you want to permanently delete this annotation?"
      />
      <AnnotationListFilterDialog
        filter={filterState}
        filterShowing={showFilter}
        creatorOptions={creators}
        modifierOptions={modifiers}
        resourceTypeId={SeaTubeResourceTypes.SEARCH_TREE_NODE}
        resourceId={-1}
        expeditionId={-1}
        isLoggedIn
        onFilterChange={(e) => setFilterState(e.target.value)}
        onApplyFilter={() => {
          setFilter(filterState);
          setShowFilter(false);
        }}
        onFilterClose={() => setShowFilter(false)}
        onReset={() => {
          setFilterState(EMPTY_FILTER);
          setFilter({});
        }}
      />
      {renderContent()}
    </Widget>
  );
};

FixedCameraAnnotationListWidget.widgetKey =
  'fixed-camera-annotation-list-widget';
FixedCameraAnnotationListWidget.widgetTitle = 'Annotation List';
FixedCameraAnnotationListWidget.defaultDataGrid = {
  i: 'fixed-camera-annotation-list-widget',
  x: 6,
  y: 12,
  w: 8,
  h: 16,
};

export default FixedCameraAnnotationListWidget;
