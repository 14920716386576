import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { UserDetailsService } from '@onc/domain-services';
import { Box } from 'base-components';
import SeaTubeSearchDashboard from 'domain/AppComponents/sea-tube/search/SeaTubeSearchDashboard';
import UserDetailsContext, {
  UserContext,
} from 'domain/AppComponents/sea-tube/UserDetailsContext';

import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';

const SeaTubeSearchApp = () => {
  const { onError } = useSnackbars();
  const handleUserData = (data: string): UserContext => ({
    userId: Number(data),
  });

  const [userId, loadingUserId, fetchUserId] = useWebService({
    method: UserDetailsService.getUserId,
    parser: handleUserData,
    onError,
  });

  useEffect(() => {
    fetchUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (loadingUserId || userId === undefined) {
      return (
        <CircularProgress
          size={120}
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
          }}
        />
      );
    }
    return (
      <UserDetailsContext.Provider value={userId}>
        <SeaTubeSearchDashboard />
      </UserDetailsContext.Provider>
    );
  };

  return <Box sx={{ height: '100%' }}>{renderContent()}</Box>;
};
export default SeaTubeSearchApp;
