import { DmasUser } from '@onc/domain-services';
import { patch, post, get } from 'util/WebRequest';
import { Organization } from './OrganizationService';

export interface SynchronizerType {
  typeId: number;
  typeName: string;
  description: string;
  modifyBy: DmasUser;
  modifyDate: string;
}

export interface MqLocation {
  mqLocationId: number;
  mqLocationCode: string;
  synchronizable: boolean;
  modifyBy: DmasUser;
  modifyDate: string;
}

export interface Synchronizer {
  synchronizerId: number;
  synchronizerName: string;
  synchronizerType: SynchronizerType;
  modifyBy: DmasUser;
  modifyDate: string;
}

export interface SynchronizerDeployment {
  deploymentId: number;
  synchronizer: Synchronizer;
  organization: Organization;
  mqLocation: MqLocation;
  isPrimary: boolean;
  dateFrom: string;
  dateTo: string;
  modifyBy: DmasUser;
  modifyDate: string;
}

type SynchronizerDeploymentParams = {
  synchronizerId: number;
  organizationId: number;
  locationId: number;
  isPrimary: boolean;
  startNow: boolean;
  dateFrom: string;
  dateTo: string;
};

class SynchronizerManagementService {
  /** Function for getting all active and future deployments */
  static getActiveAndFutureDeployments = async (): Promise<
    SynchronizerDeployment[]
  > => {
    const response = await get(
      'internal/expeditions/synchronizers/deployments'
    );
    return response.data;
  };

  static getSynchronizers = async (excludedTypes): Promise<Synchronizer[]> => {
    const response = await get(
      'internal/expeditions/synchronizers',
      excludedTypes
    );
    return response.data;
  };

  static getMqLocations = async (): Promise<MqLocation[]> => {
    const response = await get('/internal/expeditions/locations');
    return response.data;
  };

  static createDeployment = async (
    params: SynchronizerDeploymentParams
  ): Promise<SynchronizerDeployment> => {
    const response = await post(
      'internal/expeditions/synchronizers/deployments',
      params,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  };

  static updateDeployment = async (
    deploymentId: number,
    params: SynchronizerDeploymentParams
  ): Promise<SynchronizerDeployment> => {
    const response = await patch(
      `internal/expeditions/synchronizers/deployments/${deploymentId}`,
      params,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  };
}

export default SynchronizerManagementService;
