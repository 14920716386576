import * as React from 'react';
import TextField from '@mui/material/TextField';
import { ArrowDropDown } from '@onc/icons';
import { Menu, NestedItem, NestedMenuItem } from 'base-components';

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  value: string;
  onChange: (e: any) => void;
  menuItems: NestedItem[];
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const PaperProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    minWidth: 100,
  },
};

const NestedMenuSelect: React.VFC<Props> = ({
  label,
  disabled = false,
  value,
  menuItems,
  name,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    onChange(e);
    handleClose();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <TextField
        label={label}
        value={value || ''}
        onClick={handleOpen}
        disabled={disabled}
        fullWidth
        InputProps={{ endAdornment: <ArrowDropDown /> }}
        InputLabelProps={{ shrink: !!value }}
        data-test={name}
      />
      <Menu
        id={name}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={PaperProps}
      >
        {menuItems.map((item) => (
          <NestedMenuItem
            key={item.value}
            value={item.value}
            label={item.label}
            name={name}
            childrenItems={item.children}
            onClick={handleChange}
          />
        ))}
      </Menu>
    </>
  );
};

export default NestedMenuSelect;
