import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Route } from 'react-router-dom';
import DataRating from './DataRating';

class DataRatingContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const params = this.parseURLParams();
    this.state = {
      dataRatingId: Number(params.dataratingid),
      sensorId: Number(params.sensorid),
      deviceId: Number(params.deviceid),
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => this.updatePage());
  }

  parseURLParams = () => {
    const { history } = this.props;

    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  };

  updatePage() {
    const params = this.parseURLParams();
    this.setState({
      dataRatingId: Number(params.dataratingid),
      deviceId: Number(params.deviceId),
      sensorId: Number(params.sensorid),
    });
  }

  renderDataRatingPage = () => {
    const { dataRatingId, deviceId, sensorId } = this.state;
    const { history } = this.props;

    return (
      <DataRating
        dataRatingId={dataRatingId}
        sensorId={sensorId}
        deviceId={deviceId}
        history={history}
      />
    );
  };

  render() {
    const { match } = this.props;

    return (
      <Route exact path={`${match.path}`} render={this.renderDataRatingPage} />
    );
  }
}

export default DataRatingContainer;
