import { useEffect } from 'react';

import { ErrorAlert } from '@onc/composite-components';
import { CircularProgress } from 'base-components';
import EarthquakeContext from 'domain/AppComponents/earthquake-catalog/EarthquakeContext';
import EarthquakeCatalogService from 'domain/AppComponents/earthquake-catalog/service/EarthquakeCatalogService';
import useWebService from 'util/hooks/useWebService';
import EarthquakeCatalogDashboard from './EarthquakeCatalogDashboard';

const EarthquakeCatalogPage = () => {
  const [earthquakeList, loadingEarthquake, fetchFilteredEarthquakes] =
    useWebService({
      method: EarthquakeCatalogService.getFilteredEarthquakes,
    });

  useEffect(() => {
    fetchFilteredEarthquakes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (earthquakeList === null) {
      return (
        <ErrorAlert title="Error">
          There was a problem getting : {earthquakeList}
        </ErrorAlert>
      );
    }

    if (earthquakeList === undefined || loadingEarthquake) {
      return (
        <CircularProgress
          size={120}
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
          }}
        />
      );
    }
    return (
      <EarthquakeContext.Provider value={earthquakeList}>
        <EarthquakeCatalogDashboard />
      </EarthquakeContext.Provider>
    );
  };

  return (
    <div
      style={{
        backgroundColor: '#EEE',
        height: '100%',
      }}
    >
      {renderContent()}
    </div>
  );
};
export default EarthquakeCatalogPage;
