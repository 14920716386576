import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import { Formula } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsFormulaDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    testLevel: PropTypes.string,
    value: PropTypes.number,
    onGetFormulas: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    initialValue: 0,
    testLevel: '',
    value: 0,
    onGetFormulas: () => {},
  };

  parseFormula = (expression) => {
    const attributes = expression.match(/\.A\d+|[ABRS]\d+/gi) || [];

    const acceptedAttributes = [];
    let i = 0;
    for (i = 0; i < attributes.length; i += 1) {
      if (attributes[i].charAt(0) !== '.') {
        // remove attribute like '.A956'
        acceptedAttributes.push(attributes[i]);
      }
    }

    return acceptedAttributes;
  };

  state = {
    formulas: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getFormulas();
  }

  componentDidUpdate(prevProps) {
    const { testLevel } = this.props;
    if (prevProps.testLevel !== testLevel) {
      this.getFormulas();
    }
  }

  getFormula = () => {
    const { value } = this.props;
    const { formulas } = this.state;

    const currFormula = formulas.find((formula) => formula.value === value);
    if (!currFormula || value === 0) return '';
    return currFormula.label;
  };

  getFormulas = () => {
    const { testLevel, onGetFormulas } = this.props;

    QaqcTestDetailsService.getQAQCTDFormulas(testLevel)
      .then((results) => {
        const formulas = results.map((formula) => ({
          label: `${formula.formulaName} (${formula.formulaId}): ${formula.formulaExpression}`,
          value: formula.formulaId,
        }));
        this.setState({
          formulas: [{ label: 'Select', value: 0 }, ...formulas],
        });

        const attributes = [];
        results.forEach((formula) => {
          attributes[formula.formulaId] = this.parseFormula(
            formula.formulaExpression
          );
        });
        onGetFormulas(attributes);
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      value,
      onChange,
      ...rest
    } = this.props;
    const { formulas } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Formula"
          options={formulas}
          value={value}
          onChange={onChange}
          {...rest}
        />
      );
    }

    return (
      <Formula
        formula={this.getFormula()}
        classes={{ root: classes.roFieldRoot }}
      />
    );
  }
}
export default QaqcDetailsFormulaDropdown;
