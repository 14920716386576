import { Component } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import AnnotationFilter from 'domain/AppComponents/seatube/filter/AnnotationFilter';
import AnnotationListFilterButtons from 'domain/AppComponents/seatube/filter/AnnotationListFilterButtons';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import { FilterEvent } from 'library/CompositeComponents/filter/Filter';

type Props = {
  filter: any;
  filterShowing: boolean;
  creatorOptions: object[];
  modifierOptions: object[];
  resourceTypeId: number;
  resourceId: number;
  expeditionId: number;
  seatubeSearch?: string;
  isLoggedIn: boolean;
  onFilterChange: (e: FilterEvent) => void;
  onFilterClose: (e: object) => void;
  onApplyFilter: (e: object) => void;
  onReset: (e: object) => void;
};

type State = any;
class AnnotationListFilterDialog extends Component<Props, State> {
  static defaultProps = {
    seatubeSearch: '/SeaTubeSearch',
  };

  handleSeatubeSearchLink = () => {
    const { filter, resourceTypeId, resourceId, expeditionId, seatubeSearch } =
      this.props;

    sessionStorage.clear();
    for (const key of Object.keys(filter)) {
      if (filter[key] && filter[key].length !== 0) {
        if (key === 'obisCount' || key === 'depth') {
          if (
            filter[key].greaterThan ||
            filter[key].lessThan ||
            Number.parseFloat(filter[key].greaterThan) === 0 ||
            Number.parseFloat(filter[key].lessThan) === 0
          ) {
            sessionStorage.setItem(key, JSON.stringify(filter[key]));
          }
        } else if (key === 'date') {
          if (
            moment(filter[key].fromDate).isValid() ||
            moment(filter[key].toDate).isValid()
          ) {
            const dateFilter = {};
            if (moment(filter[key].fromDate).isValid()) {
              Object.assign(dateFilter, { fromDate: filter[key].fromDate });
            }
            if (moment(filter[key].toDate).isValid()) {
              Object.assign(dateFilter, { toDate: filter[key].toDate });
            }
            sessionStorage.setItem(key, JSON.stringify(dateFilter));
          }
        } else if (key === 'taxonomy') {
          if (
            (filter[key].taxonomy || filter[key].taxonomy === 0) &&
            filter[key].taxon
          ) {
            sessionStorage.setItem(key, JSON.stringify(filter[key]));
          }
        } else {
          sessionStorage.setItem(key, JSON.stringify(filter[key]));
        }
      }
    }

    let queryString = '';
    switch (resourceTypeId) {
      case SeaTubeResourceTypes.DIVE:
        queryString = queryString.concat(`?cruiseIds=${expeditionId}`);
        queryString = queryString.concat(`&diveIds=${resourceId}`);
        break;
      case SeaTubeResourceTypes.SEARCH_TREE_NODE:
        queryString = queryString.concat(`?searchTreeNodeIds=${resourceId}`);
        break;
      default:
        break;
    }

    window.open(`${seatubeSearch}${queryString}`, '_blank');
  };

  render() {
    const {
      filter,
      filterShowing,
      creatorOptions,
      modifierOptions,
      isLoggedIn,
      onFilterChange,
      onApplyFilter,
      onFilterClose,
      onReset,
    } = this.props;
    return (
      <Dialog
        onClose={onFilterClose}
        open={filterShowing}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle> Annotation Filter </DialogTitle>
        <DialogContent>
          <AnnotationFilter
            context="list"
            name="annotationFilter"
            onChange={onFilterChange}
            onSubmit={onApplyFilter}
            onReset={onReset}
            creatorOptions={creatorOptions}
            modifierOptions={modifierOptions}
            filter={filter}
            isLoggedIn={isLoggedIn}
          />
        </DialogContent>
        <DialogActions>
          <AnnotationListFilterButtons
            onSubmit={onApplyFilter}
            handleReset={onReset}
            handleSeatubeSearchLink={this.handleSeatubeSearchLink}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default AnnotationListFilterDialog;
