import { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ContainedButton } from '@onc/composite-components';
import { Divider, Grid, Typography } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { handleOpenDeviceDetails } from 'domain/AppComponents/link/DeviceDetailsLink';
import DeviceNamePlateService from 'domain/services/DeviceNamePlateService';

import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateUtils from 'util/DateUtils';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(1),
  },
});

const defaultState = {
  namePlateId: undefined,
  serialnr: undefined,
  serialnr2: undefined,
  modelnr: undefined,
  supplierName: undefined,
  producerName: undefined,
  deviceId: undefined,
  manufactureDate: undefined,
  firmwareRevision: undefined,
  softwareRevision: undefined,
  contactInfo: undefined,
  namePlateDescription: undefined,
  deviceName: undefined,
  modifyBy: undefined,
  modifyDate: DateUtils.formatDateAsString(new Date()),
};

class NamePlateMaintenanceRO extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
      roDivider: PropTypes.string,
    }).isRequired,
    namePlateId: PropTypes.number,
    deviceId: PropTypes.number,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    namePlateId: undefined,
    deviceId: undefined,
  };

  constructor(props) {
    super(props);
    const { namePlateId, deviceId } = this.props;
    this.state = {
      ...defaultState,
      namePlateId,
      deviceId,
    };
  }

  componentDidMount() {
    const { namePlateId } = this.state;

    if (namePlateId) {
      this.getDeviceNamePlateService();
    }
  }

  getDeviceNamePlateService = () => {
    const { onError } = this.props;
    const { namePlateId, deviceId } = this.state;

    if (!namePlateId || isNaN(namePlateId)) return;

    DeviceNamePlateService.getOne(namePlateId, deviceId)
      .then((payload) => {
        const { namePlate, producer, supplier, dmasUser } = payload;
        const { manufactureDate, ...rest } = namePlate;
        this.setState({
          manufactureDate:
            manufactureDate !== undefined && manufactureDate !== ''
              ? new Date(manufactureDate)
              : undefined,
          ...rest,
          supplierName: supplier ? supplier.organizationName : undefined,
          producerName: producer.organizationName,
          modifyBy: `${dmasUser.firstname} ${dmasUser.lastname}`,
          deviceName: namePlate.device.deviceName,
        });
      })
      .catch((e) => {
        onError(e.message);
        this.setState(defaultState);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      namePlateId,
      serialnr,
      serialnr2,
      modelnr,
      firmwareRevision,
      softwareRevision,
      manufactureDate,
      producerName,
      supplierName,
      deviceId,
      deviceName,
      contactInfo,
      namePlateDescription,
      modifyDate,
      modifyBy,
    } = this.state;

    let color;
    return (
      // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
      <div>
        <Panel
          title={
            <>
              <Typography variant="body1">Nameplate ID:</Typography>
              <Typography variant="body1" color={color}>
                {namePlateId}
              </Typography>
            </>
          }
        >
          <Grid
            container
            direction="row"
            className={classes.entryFormContainer}
          >
            <Grid item xs={6}>
              <MarginedReadOnlyField
                labelText="Serial Number"
                valueText={serialnr}
                title="serialNrField"
              />
              <MarginedReadOnlyField
                labelText="Serial Number 2"
                valueText={serialnr2}
                title="serialNr2Field"
              />
              <MarginedReadOnlyField
                labelText="Model"
                valueText={modelnr}
                title="modelNrField"
              />
              <MarginedReadOnlyField
                labelText="Firmware Revision"
                valueText={firmwareRevision}
                title="firmwareRevisionField"
              />
              <MarginedReadOnlyField
                labelText="Software Revision"
                valueText={softwareRevision}
                title="softwareRevisionField"
              />
              <MarginedReadOnlyField
                labelText="Manufacture Date"
                valueText={DateUtils.formatDateAsString(
                  new Date(manufactureDate)
                )}
                title="manufactureDateField"
              />
              <MarginedReadOnlyField
                labelText="Manufacturer"
                valueText={producerName}
                title="producerNameField"
              />
              <MarginedReadOnlyField
                labelText="Supplier Name"
                valueText={supplierName}
                title="supplierNameField"
              />
              <MarginedReadOnlyField
                labelText="Device Name"
                valueText={deviceName}
                title="deviceNameField"
              />
              <MarginedReadOnlyField
                labelText="Contact Info"
                valueText={contactInfo}
                title="contactInfoField"
              />
              <MarginedReadOnlyField
                labelText="Description"
                valueText={namePlateDescription}
                title="descriptionField"
              />
              <MarginedReadOnlyField
                labelText="Last Modified Date"
                valueText={DateUtils.formatDateAsString(new Date(modifyDate))}
                title="modifyDateField"
              />
              <MarginedReadOnlyField
                labelText="Last Modified By"
                valueText={modifyBy}
                title="modifyByField"
              />
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <ContainedButton
              translationKey="common.buttons.done"
              onClick={handleOpenDeviceDetails(deviceId)}
            />
          </div>
          <Divider variant="middle" className={classes.roDivider} />
        </Panel>
      </div>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(NamePlateMaintenanceRO));
