import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import {
  ErrorSnackbar,
  InfoSnackbar,
  InlineEditField,
  TextButton,
} from '@onc/composite-components';
import { Grid, Paper, Typography, TextField } from 'base-components';

import TestDetails from './TestDetails';
import TestDeviceLink from './TestDeviceLink';
import TestInformation from './TestInformation';
import DeviceConsoleService from '../DeviceConsoleService';
import DevicePortService from '../DevicePortService';
import LiveDafTestService from '../LiveDafTestService';

import { isTestRunning, isTestStarted } from '../TestUtil';

const defaultTest = { ipAddress: '', duration: 5, comment: '' };

const styles = (theme) => ({
  root: {
    margin: theme.spacing(),
  },
});

class TestForm extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string,
      isExact: PropTypes.bool,
    }),
    classes: PropTypes.shape({
      list: PropTypes.string,
      root: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    match: {
      path: undefined,
    },
  };

  state = {
    test: defaultTest,
    deviceId: 0,
    clone: false,
    start: false,
    info: undefined,
    error: undefined,
  };

  componentDidMount() {
    const { match } = this.props;
    if (window.location.search) {
      this.handleQueryParam();
    }
    if (!match.isExact) {
      const fullPath = window.location.pathname;
      const testId = Number(
        fullPath.substring(fullPath.indexOf(match.path) + match.path.length)
      );
      this.loadTest(testId);
    }
  }

  handleQueryParam = () => {
    const params = new URLSearchParams(window.location.search);
    const newTest = {
      ipAddress: params.get('ipAddress'),
      duration: Number(params.get('duration')),
    };
    const deviceId = Number(params.get('deviceId'));
    this.setState(
      {
        deviceId,
        test: newTest,
      },
      () => this.handleCheckDeviceId(deviceId)
    );
  };

  handleCloneTest = () => {
    const { test, deviceId } = this.state;
    const newTest = {
      ipAddress: test.ipAddress,
      duration: test.duration,
      deviceName: test.deviceName,
      comment: '', // Don't clone the comment
      testId: undefined,
      status: undefined,
    };
    this.setState({
      deviceId,
      test: newTest,
      stop: false,
      start: false,
      error: null,
      clone: true,
    });
  };

  handleUpdateComment = (value) => {
    const { test } = this.state;
    if (test.dafTestId) {
      return LiveDafTestService.update(test, value)
        .then(() => {
          this.setState({
            test: {
              ...test,
              comment: value,
            },
            info: 'Successfully updated comment',
          });
        })
        .catch(() => {
          this.setState({
            error: 'There was an error saving the comment',
          });
        });
    }
    return null;
  };

  handleStopTest = () => {
    const { test } = this.state;
    return LiveDafTestService.cancel(test.dafTestId)
      .then(() => {
        this.setState({
          info: 'Stopping Test',
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  getPiggyBacks = async (deviceId) => {
    const { test } = this.state;
    return DevicePortService.get(deviceId, test.startDate)
      .then((response) => {
        this.setState({
          test: {
            ...test,
            piggyBacks: response.ports.filter((port) => port.deviceId),
          },
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  };

  isLatestResponse = (response) => {
    const { deviceId } = this.state;

    return Number(response[0].deviceId) === deviceId;
  };

  handleCheckDeviceId = async (deviceId) => {
    const { test } = this.state;
    if (!deviceId) {
      this.setState({
        test: {
          ...test,
          deviceName: '',
          ipAddress: '',
          validDeviceId: false,
        },
      });
      return false;
    }
    return DeviceConsoleService.get(deviceId)
      .then((response) => {
        if (response && this.isLatestResponse(response)) {
          this.setState({
            test: {
              ...test,
              deviceName: response[0].deviceName,
              ipAddress: response[0].ipAddress,
              validDeviceId: true,
            },
          });
          this.getPiggyBacks(deviceId);
          return true;
        }
        this.setState({
          test: {
            ...test,
            deviceName: '',
            ipAddress: '',
            validDeviceId: false,
          },
        });
        return false;
      })
      .catch((error) => {
        this.setState({
          error,
          test: {
            ...test,
            deviceName: '',
            ipAddress: '',
            validDeviceId: false,
          },
        });
        return false;
      });
  };

  handleSubmitTest = (event) => {
    const { test, deviceId } = this.state;

    event.preventDefault();

    this.setState({ error: undefined, clone: false });
    return LiveDafTestService.create(
      deviceId,
      test.duration,
      test.ipAddress,
      test.comment
    )
      .then((dafTestId) => {
        this.setState(
          {
            test: {
              ...test,
              dafTestId,
              comment: test.comment,
            },
            start: true,
          },
          () => {
            this.loadTest(dafTestId);
          }
        );
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  handleCommentChange = (value) => {
    const { test } = this.state;
    const newTest = { ...test, comment: value };
    this.setState({ test: newTest });
  };

  resetState = () => {
    this.setState({
      deviceId: 0,
      test: defaultTest,
    });
  };

  handleChangeDeviceId = (event) => {
    this.setState({ deviceId: Number(event.target.value) });
    this.handleCheckDeviceId(Number(event.target.value));
  };

  handleChangeIpAddress = (event) => {
    const { test } = this.state;
    const newTest = { ...test, ipAddress: event.target.value };
    this.setState({ test: newTest });
  };

  handleChangeDuration = (event) => {
    const { test } = this.state;
    const newTest = { ...test, duration: event.target.value };
    this.setState({ test: newTest });
  };

  loadTest(testId) {
    return LiveDafTestService.getOne(testId)
      .then((response) => {
        this.setState(
          {
            deviceId: response.deviceId,
            test: {
              ...response,
              duration: response.duration.seconds / 60,
            },
          },
          () => {
            this.handleCheckDeviceId(response.deviceId);
          }
        );
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  renderDeviceName() {
    const { test, deviceId } = this.state;
    if (test.deviceName) {
      return (
        <TestDeviceLink
          deviceId={deviceId}
          ipAddress={test.ipAddress}
          deviceName={test.deviceName}
          piggyBacks={test.piggyBacks}
        />
      );
    }
    return <Typography> Enter a valid device id... </Typography>;
  }

  renderForm() {
    const { test, deviceId } = this.state;
    if (test.dafTestId) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TestInformation test={{ ...test, deviceId }} />
          </Grid>
          <Grid item xs={12}>
            {this.renderCommentForm()}
          </Grid>
          <Grid item xs={12}>
            {this.renderActionButtons()}
          </Grid>
        </Grid>
      );
    }
    return (
      <form onSubmit={this.handleSubmitTest}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              translationKey="common.textfields.title"
              title="deviceId"
              fullWidth
              name="deviceId"
              value={deviceId || ''}
              onChange={this.handleChangeDeviceId}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              translationKey="common.textfields.description"
              required
              label="IP Address"
              title="ipAddress"
              placeholder="0.0.0.0:0000"
              value={test.ipAddress || ''}
              fullWidth
              disabled={isTestStarted(test.dafTestId)}
              onChange={this.handleChangeIpAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              translationKey="common.textfields.comment"
              required
              label="Test Duration (minutes)"
              defaultValue={test.duration || '5'}
              type="number"
              title="duration"
              fullWidth
              disabled={isTestStarted(test.dafTestId)}
              onChange={this.handleChangeDuration}
            />
          </Grid>
          <Grid item xs={12}>
            {this.renderCommentForm()}
          </Grid>
          <Grid item xs={12}>
            {this.renderActionButtons()}
          </Grid>
        </Grid>
      </form>
    );
  }

  renderCommentForm() {
    const { test } = this.state;
    if (test.dafTestId) {
      return (
        <InlineEditField
          value={test.comment || ''}
          handleSave={this.handleUpdateComment}
        />
      );
    }
    return (
      <TextField
        translationKey="common.textfields.comment"
        onChange={(e) => this.handleCommentChange(e.target.value)}
        title="comment"
        value={test.comment || ''}
        name="comment"
        rows={9}
        multiline
        fullWidth
      />
    );
  }

  renderActionButtons() {
    const { test } = this.state;
    return (
      <>
        <TextButton
          type="submit"
          disabled={isTestStarted(test.dafTestId)}
          translationKey="testTree.runTest"
        />
        <TextButton
          disabled={!isTestRunning(test.dafTestId, test.status)}
          onClick={this.handleStopTest}
          translationkey="common.buttons.cancel"
        />

        <TextButton
          disabled={!isTestStarted(test.dafTestId)}
          onClick={this.handleCloneTest}
          translationKey="device.cloneDevice"
        />
      </>
    );
  }

  renderSnackbar() {
    const { error, info } = this.state;
    if (error) {
      return (
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ error: undefined })}
        />
      );
    }
    if (info) {
      return (
        <InfoSnackbar
          message={info}
          onClose={() => this.setState({ info: undefined })}
        />
      );
    }
    return null;
  }

  render() {
    const { match, classes, location } = this.props;
    const { clone, start, test } = this.state;
    if (match.path === location.pathname && test.dafTestId) {
      this.resetState();
    }
    return (
      <div className={classes.root}>
        {this.renderSnackbar()}
        <Grid container>
          <Grid item xs={false} xl={1} />
          <Grid item xs={12} xl={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Paper>{this.renderForm()}</Paper>
              </Grid>
              <Grid item xs={12} md={7}>
                <Paper>{this.renderDeviceName()}</Paper>
              </Grid>
              <Route path={`${match.path}:id`} component={TestDetails} />
              {clone && <Redirect to="/live-daf-testing/test" />}
              {start && <Redirect to={`${match.path}${test.dafTestId}`} />}
            </Grid>
          </Grid>
          <Grid item xs={false} xl={1} />
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(TestForm);
