import { WebRequest } from '@onc/service';

const {
  get,
  parseDmasAPIResponse,
  getAbortable,
  getControlledAbortable,
  put,
  patch,
  post,
  deleteImpl,
} = WebRequest;

export {
  get,
  parseDmasAPIResponse,
  getAbortable,
  getControlledAbortable,
  put,
  patch,
  post,
  deleteImpl,
};
