import { WebRequest } from '@onc/service';

export interface DmasUser {
  dmasUserId: number;
  lastname: string;
  firstname: string;
  email: string;
}

class UserDetailsService {
  /** Check if the user is logged in. */
  static isLoggedIn = async () => {
    const user = await UserDetailsService.getUser();
    return user.DMAS_USER_ID > 0;
  };

  /**
   * Function for getting the user id of the current user
   *
   * @returns The current user's id, or 0 if not logged in
   */
  static getUserId = async () => {
    const user = await UserDetailsService.getUser();
    return user.DMAS_USER_ID;
  };

  /**
   * Get details about the current user. If not logged in, the only value
   * returned is { DMAS_USER_ID: 0 }; if logged in, returns { DMAS_USER_EMAIL:
   * string, DMAS_USER_FIRSTNAME: string, DMAS_USER_ID: number,
   * DMAS_USER_LASTNAME: string, DMAS_USER_PRIVILEGE: NONE|RO|RW
   * DMAS_USER_TOKEN: string }
   *
   * DMAS_USER_PRIVILEGE is only useful if the request includes a pageName
   * (which should be the second parameter of an IResourceName), and will be
   * undefined if the user isn't logged in
   */
  static getUser = async (pageName = undefined) => {
    try {
      return await WebRequest.get('UserDetailsService', {
        pageName,
      }).then(WebRequest.parseDmasAPIResponse);
    } catch (error) {
      return { DMAS_USER_ID: 0 };
    }
  };
}

export default UserDetailsService;
