import { FC, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { useMap } from 'react-leaflet';
import { TextButton } from '@onc/composite-components';
import { CalendarToday, Close, EventAvailable } from '@onc/icons';
import {
  Box,
  DateTimePicker,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  MapFABControl,
} from 'base-components';
import useFabDialogStyles from './useFabDialogStyles';

interface FilterDateRange {
  /** DateTo The higher bound to filter casts */
  dateTo: Date;
  /** DateFrom The lower bound to filter casts */
  dateFrom: Date;
}

interface FilterFABProps {
  /** DateRange a dateFrom and a dateTo to filter casts by */
  dateRange: FilterDateRange;
  /** FilterCallback fires a callback to filter casts as needed */
  setDateRange: (dateRange: FilterDateRange) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateToPicker: {
      marginTop: theme.spacing(),
    },
    filterButtonContainer: {
      textAlign: 'end',
    },
  })
);

/**
 * Filter Floating Action button with popup. Should be used inside a Map such as
 * SimpleMap or ONCMap
 */
const FilterFAB: FC<FilterFABProps> = ({ dateRange, setDateRange }) => {
  const { dateFrom, dateTo } = dateRange;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const map = useMap();
  // Merges styles
  const classes = { ...useStyles(), ...useFabDialogStyles() };

  const title = showFilter ? 'Hide Date Filter' : 'Show Date Filter';

  const handlePointerOut = () => {
    if (map) {
      map.options.dragging = true;
      map.options.scrollWheelZoom = true;
      map.options.doubleClickZoom = true;
    }
  };

  const handlePointerOver = () => {
    if (map) {
      map.options.dragging = false;
      map.options.scrollWheelZoom = false;
      map.options.doubleClickZoom = false;
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleDateFromChange = (date: Moment) => {
    setDateRange({ dateFrom: date?.toDate() || null, dateTo });
  };

  const handleDateToChange = (date: Moment) => {
    setDateRange({ dateFrom, dateTo: date?.toDate() || null });
  };

  const clearFilter = () => {
    setDateRange({ dateFrom: null, dateTo: null });
  };

  return (
    map && (
      <>
        <MapFABControl
          TooltipProps={{ title }}
          onClick={toggleFilter}
          size="medium"
          color="primary"
          prepend
          options={{ position: 'bottomleft' }}
        >
          {dateTo !== undefined || dateFrom !== undefined ? (
            <EventAvailable />
          ) : (
            <CalendarToday />
          )}
        </MapFABControl>
        <Dialog
          open={showFilter}
          maxWidth="lg"
          fullWidth
          fullScreen={false}
          hideBackdrop
          disableEnforceFocus
          className={classes.dialog}
        >
          <DialogContent>
            <Grow in style={{ transformOrigin: 'bottom right 0' }}>
              <Box className={classes.boxOuter}>
                <Box className={classes.boxInner}>
                  <Grid container spacing={1}>
                    <DialogTitle>
                      Community Fishers - Cast Date Filter
                    </DialogTitle>
                    <IconButton
                      style={{ marginLeft: 'auto', padding: '20px' }}
                      onClick={toggleFilter}
                      aria-label="Close CF Date Filter"
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <DialogContent>
                    <div
                      className={classes.controlLayersContainer}
                      onPointerOver={handlePointerOver}
                      onPointerOut={handlePointerOut}
                    >
                      <div>
                        <div>
                          <DateTimePicker
                            translationKey="common.datepickers.dateFrom"
                            onChange={handleDateFromChange}
                            value={dateFrom ? moment.utc(dateFrom) : null}
                            maxDateTime={dateTo ? moment.utc(dateTo) : null}
                            fullWidth
                          />
                        </div>
                        <div className={classes.dateToPicker}>
                          <DateTimePicker
                            translationKey="common.datepickers.dateTo"
                            onChange={handleDateToChange}
                            value={dateTo ? moment.utc(dateTo) : null}
                            minDateTime={dateFrom ? moment.utc(dateFrom) : null}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className={classes.filterButtonContainer}>
                        <TextButton
                          translationKey="communityFishers.clearFilter"
                          onClick={clearFilter}
                        />
                      </div>
                    </div>
                  </DialogContent>
                </Box>
              </Box>
            </Grow>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

export default FilterFAB;
