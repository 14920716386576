import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import { ErrorSnackbar } from '@onc/composite-components';
import { CircularProgress } from 'base-components';
import TokenUtils from 'domain/AppComponents/Dashboard/services/TokenUtils';
import { WidgetTitle } from 'domain/AppComponents/Dashboard/Titles';
import {
  WidgetConfigError,
  getWidgetConfigErrorMessage,
} from 'domain/AppComponents/Dashboard/widget-error/WidgetConfigError';
import MediaUtils from 'domain/AppComponents/video/MediaUtils';
import VideoPlayer from 'domain/AppComponents/video/VideoPlayer';
import ArchiveFileService from 'domain/services/ArchiveFileService';
import DateFormatUtils from 'util/DateFormatUtils';
import DateUtils from 'util/DateUtils';
import Environment from 'util/Environment';

const useStyles = makeStyles(() =>
  createStyles({
    circularProgress: {},
    contentDiv: {
      height: 'calc(100% - 4.2em)',
    },
  })
);

const EXTENSIONS = ['mov', 'avi', 'mp4', 'ogg', 'mpg', 'mp2'];

const TIMESOURCE_LATEST = 'latestClip';

type VideoProps = {
  archiveFiles?: any;
  startDate?: Moment;
  endDate?: Moment;
  deviceId?: number;
  playlistHdrId?: string;
  liveStreamUrl?: string;
  isLive?: boolean;
  title?: string;
  description?: string;
  useContinuous?: boolean;
};

type VideoWidgetDisplayProps = {
  classes: any;
  loopPlayback: boolean;
  onInfo: () => void;
  onError: () => void;
  showTitle: boolean;
  title: string;
  dataSource: string;
  timeSource: string;
  device?: {
    deviceId: number;
    name: string;
    deviceCode: string;
  };
  liveStreamUrl?: string;
  startDate?: Moment;
  endDate?: Moment;
  widgetId?: string;
  playlistHdrId?: string;
  locations?: Array<{
    pathName: string[];
    stationCode: string;
    deviceCategoryCode: string;
  }>;
};

const VideoWidgetDisplay: React.FC<VideoWidgetDisplayProps> = ({
  loopPlayback,
  onInfo,
  onError,
  showTitle,
  title,
  dataSource,
  timeSource,
  device = undefined,
  widgetId = undefined,
  playlistHdrId = undefined,
  liveStreamUrl = undefined,
  locations = undefined,
  startDate = undefined,
  endDate = undefined,
  classes,
}) => {
  const allClasses = { ...useStyles(), ...classes };

  const [videoProps, setVideoProps] = useState<VideoProps>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const getInvalidFields = useCallback(() => {
    const invalidFields = [];

    if (dataSource === 'liveStream' && !liveStreamUrl) {
      invalidFields.push('Live Stream URL');
    }
    if (dataSource === 'playlist' && !playlistHdrId) {
      invalidFields.push('Playlist');
    }
    if (
      dataSource === 'location' &&
      (!locations || (locations && locations.length === 0))
    ) {
      invalidFields.push('Location');
    }
    if (dataSource === 'deviceId' && !device) {
      invalidFields.push('Device');
    }
    // date range can only be invalid for devices or locations
    if (
      timeSource === 'timeRange' &&
      (dataSource === 'deviceId' || dataSource === 'location')
    ) {
      if (!startDate) {
        invalidFields.push('Start Date');
      }
      if (!endDate) {
        invalidFields.push('End Date');
      }
    }
    return invalidFields;
  }, [
    dataSource,
    device,
    endDate,
    liveStreamUrl,
    locations,
    playlistHdrId,
    startDate,
    timeSource,
  ]);

  const getDateString = useCallback(() => {
    if (timeSource === TIMESOURCE_LATEST) {
      return 'Latest';
    }
    return `${DateUtils.formatDateAsString(
      startDate
    )}-${DateUtils.formatDateAsString(endDate)}`;
  }, [endDate, startDate, timeSource]);

  const convertList = useCallback(
    (list) => {
      if (list === undefined || list.length <= 0) {
        setError({
          condition: 'noVideoData',
          data: { dataSource, timeSource },
        });
        return undefined;
      }

      let fileList = [].concat(...list.map(({ data }) => data.files));
      if (fileList.length > 0) {
        if (timeSource === TIMESOURCE_LATEST) {
          fileList = [fileList[fileList.length - 1]];
        }
      } else {
        setError({
          condition: 'noVideoData',
          data: { dataSource, timeSource },
        });
      }
      return fileList;
    },
    [dataSource, timeSource]
  );

  const getLatestFileListByLocation = async () => {
    const results = [];
    for (const extension of EXTENSIONS) {
      results.push(
        ArchiveFileService.getFileListByLocation(
          locations.length ? locations[0].stationCode : undefined,
          locations.length ? locations[0].deviceCategoryCode : undefined,
          undefined,
          undefined,
          extension,
          1,
          true
        )
      );
    }
    return Promise.all(results);
  };

  const getLatestFileListByDevice = async () => {
    const { deviceCode } = device;
    const results = [];
    for (const extension of EXTENSIONS) {
      results.push(
        ArchiveFileService.getFileListByDevice(
          deviceCode,
          undefined,
          undefined,
          extension,
          undefined,
          null,
          1,
          true
        )
      );
    }
    return Promise.all(results);
  };

  const getAsyncFileListByLocation = useCallback(async () => {
    const results = [];
    for (const extension of EXTENSIONS) {
      results.push(
        ArchiveFileService.getFileListByLocation(
          locations[0].stationCode,
          locations[0].deviceCategoryCode,
          timeSource === 'timeRange' ? startDate : undefined,
          timeSource === 'timeRange' ? endDate : undefined,
          extension,
          timeSource === TIMESOURCE_LATEST ? 1 : undefined,
          timeSource === TIMESOURCE_LATEST
        )
      );
    }
    return Promise.all(results);
  }, [endDate, locations, startDate, timeSource]);

  const getArchiveFileListByLocation = useCallback(async () => {
    const results = await getAsyncFileListByLocation().catch((newError) => {
      const updatedError = TokenUtils.getTokenError(newError);
      setError({ condition: 'service', data: updatedError });
    });
    return results;
  }, [getAsyncFileListByLocation]);

  const getAsyncFileListByDevice = useCallback(async () => {
    const { deviceCode } = device;
    const results = [];
    for (const extension of EXTENSIONS) {
      results.push(
        ArchiveFileService.getFileListByDevice(
          deviceCode,
          timeSource === 'timeRange' ? startDate : undefined,
          timeSource === 'timeRange' ? endDate : undefined,
          extension,
          undefined,
          null,
          timeSource === TIMESOURCE_LATEST ? 1 : undefined,
          timeSource === TIMESOURCE_LATEST
        )
      );
    }
    return Promise.all(results);
  }, [device, endDate, startDate, timeSource]);

  const getArchiveFileListByDevice = useCallback(async () => {
    const results = await getAsyncFileListByDevice().catch((newError) => {
      const updatedError = TokenUtils.getTokenError(newError);
      setError({ condition: 'service', data: updatedError });
    });
    // TODO change to fileList
    return results;
  }, [getAsyncFileListByDevice]);

  const getWidgetDataErrorMessage = (newError) => {
    let dataSourceName = dataSource;
    const userLoggedIn = Environment.isUserLoggedIn();
    if (dataSourceName === 'deviceId') {
      dataSourceName = 'device';
    }
    let message;
    switch (newError.condition) {
      case 'config':
        message = getWidgetConfigErrorMessage(newError.data);
        break;
      case 'service':
        message = 'A service error has occurred';
        message += userLoggedIn ? `: ${newError.data}` : '';
        break;
      default:
        message = `No video data found for this ${dataSourceName}`;
        message +=
          timeSource === 'timeRange'
            ? ` from UTC ${DateFormatUtils.formatDate(
                startDate,
                'full'
              )} to UTC ${DateFormatUtils.formatDate(endDate, 'full')}`
            : '';
        break;
    }
    return message;
  };

  const getPlaylist = (archiveFiles) => {
    const description = getDateString();
    const newlist = archiveFiles.map((file) => ({
      title,
      description: `${description}${description ? ' (' : ''}${
        file.dateFrom
      } - ${file.dateTo}${description ? ')' : ''}`,
      file: `${Environment.getMediaBaseUrl()}${file.archiveLocation}/${
        file.path
      }/${file.filename}/${MediaUtils.getRequestFormat()}`,
    }));
    return newlist;
  };

  const latestVideoFile = (list) => {
    if (list === undefined || list.length <= 0) {
      return undefined;
    }
    let fileList = [].concat(...list.map(({ data }) => data.files));
    if (fileList.length) {
      fileList = [fileList[fileList.length - 1]];
    } else {
      return undefined;
    }
    return getPlaylist(fileList);
  };

  const handleFetchingLatestAudio = async () => {
    const description = getDateString();
    if (description === 'Latest' && loopPlayback && dataSource === 'location') {
      // Check for more recent video file & return based on the data source
      const latestresult = await getLatestFileListByLocation();
      return latestVideoFile(latestresult);
    }
    if (description === 'Latest' && loopPlayback && dataSource === 'deviceId') {
      const latestresult = await getLatestFileListByDevice();
      return latestVideoFile(latestresult);
    }
    return undefined;
  };

  const renderDisplayContent = () => {
    if (error) {
      const message = getWidgetDataErrorMessage(error);
      return (
        <>
          <ErrorSnackbar message={message} />
          <WidgetConfigError message={message} />
        </>
      );
    }
    return (
      <VideoPlayer
        locations={locations}
        containerId={`containerid-${widgetId}`}
        playerId={`playerid-${widgetId}`}
        classes={{
          root: allClasses.videoDiv,
        }}
        onInfo={onInfo}
        onError={onError}
        loopPlayback={loopPlayback}
        dataSource={dataSource}
        deviceCode={device?.deviceCode}
        onCompleted={handleFetchingLatestAudio}
        {...videoProps}
      />
    );
  };

  const createVideoProps = useCallback(async () => {
    switch (dataSource) {
      case 'location': {
        if (!locations || locations.length <= 0) {
          break;
        }
        const list = await getArchiveFileListByLocation();
        setVideoProps((prev) => ({
          ...prev,
          archiveFiles: convertList(list),
          useContinuous: timeSource !== TIMESOURCE_LATEST,
          title: `${locations[0].pathName[locations[0].pathName.length - 2]}`,
          description: getDateString(),
          isLive: false,
        }));
        break;
      }

      case 'deviceId': {
        if (!device) {
          break;
        }
        const { name } = device;
        const list = await getArchiveFileListByDevice();
        setVideoProps((prev) => ({
          ...prev,
          archiveFiles: convertList(list),
          useContinuous: timeSource !== TIMESOURCE_LATEST,
          title: `${name}`,
          description: getDateString(),
          isLive: false,
        }));
        break;
      }

      case 'playlist':
        setVideoProps((prev) => ({ ...prev, playlistHdrId, isLive: false }));
        break;

      case 'liveStream':
        setVideoProps((prev) => ({
          ...prev,
          liveStreamUrl,
          isLive: true,
        }));
        break;

      default:
        break;
    }
    setLoading(false);
  }, [
    convertList,
    dataSource,
    device,
    getArchiveFileListByDevice,
    getArchiveFileListByLocation,
    getDateString,
    liveStreamUrl,
    locations,
    playlistHdrId,
    timeSource,
  ]);

  useEffect(() => {
    setError('');
    const invalidFields = getInvalidFields();
    if (invalidFields.length > 0) {
      setLoading(false);
      setError({ condition: 'config', data: invalidFields });
    } else {
      setLoading(true);
      createVideoProps();
    }
  }, [
    dataSource,
    timeSource,
    locations,
    device,
    startDate,
    endDate,
    getInvalidFields,
    createVideoProps,
  ]);

  if (loading) {
    return (
      <div className={allClasses.circularProgress}>
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      {showTitle && <WidgetTitle titleText={title} />}
      <div className={allClasses.contentDiv}>{renderDisplayContent()}</div>
    </>
  );
};

export default VideoWidgetDisplay;
