import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import {
  SaveButton,
  CancelButton,
  DeleteButton,
} from '@onc/composite-components';
import { Grid, TextField, Toggle } from 'base-components';
import DraggableToolbox from 'domain/AppComponents/annotations/entry/DraggableToolbox';
import {
  ConfirmationDialog,
  GenericInfoDialog,
} from 'domain/AppComponents/dialogs/Dialogs';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import DataPreviewManagementService from 'domain/services/DataPreviewManagementsService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DataPreview, { Clause } from './DataPreview';
import DataPreviewClauseFields from './DataPreviewClauseFields';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
});

type Props = {
  onClose: () => void;
  onSubmit: (dataPreview: DataPreview) => void;
  label: string;
  userHasWritePermission: boolean;
  dataPreview: DataPreview;
  onDelete: () => void;
};

const validate = (
  dataPreview: DataPreview | undefined,
  fieldName: keyof DataPreview
) =>
  dataPreview && dataPreview[fieldName] ? String(dataPreview[fieldName]) : '';

const FormTextField = (props) => (
  <Grid item xs={12}>
    <TextField fullWidth {...props} />
  </Grid>
);

const createToggle = (label: string, value: boolean, onChange) => (
  <Grid item xs={12}>
    <Toggle label={label} name={label} value={value} onChange={onChange} />
  </Grid>
);

const DataPreviewManagementDialogBox = ({
  onClose,
  label,
  userHasWritePermission,
  dataPreview,
  onSubmit,
  onDelete,
}: Props) => {
  const [dataPreviewDescriptionValue, setDataPreviewDescriptionValue] =
    useState(validate(dataPreview, 'description'));
  const [dataPreviewRuleValue, setDataPreviewRuleValue] = useState(
    validate(dataPreview, 'ruleString')
  );
  const [enabledValue, setEnabledValue] = useState(
    dataPreview?.enabled !== undefined ? Boolean(dataPreview.enabled) : true
  );
  const [keepSummaryTabValue, setKeepSummaryTabValue] = useState(
    Boolean(dataPreview?.keepSummaryTab)
  );

  const [clauseValue, setClauseValue] = useState<Clause[]>(
    dataPreview?.clauses || []
  );
  const [isThereAClause, setIsThereAClause] = useState(
    dataPreview?.clauses ? dataPreview.clauses.length > 0 : false
  );
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [OpenAddClauseRequiredDialog, setOpenAddClauseRequiredDialog] =
    useState(false);

  useEffect(() => {
    if (dataPreview) {
      setDataPreviewDescriptionValue(dataPreview?.description || '');
      setDataPreviewRuleValue(dataPreview?.ruleString || '');
      setEnabledValue(
        dataPreview?.enabled !== undefined ? Boolean(dataPreview.enabled) : true
      );
      setKeepSummaryTabValue(Boolean(dataPreview?.keepSummaryTab));
      setClauseValue(dataPreview?.clauses || []);
      setIsThereAClause(
        dataPreview?.clauses ? dataPreview.clauses.length > 0 : false
      );
      setAttemptedSubmit(false);
    }
  }, [dataPreview]);

  const handleOnSubmit = () => {
    setAttemptedSubmit(true);

    if (!dataPreviewDescriptionValue.trim()) {
      setDataPreviewDescriptionValue('');
      return;
    }

    if (isThereAClause) {
      onSubmit({
        dataPreviewRuleId: dataPreview?.dataPreviewRuleId,
        description: dataPreviewDescriptionValue,
        ruleString: dataPreviewRuleValue,
        keepSummaryTab: keepSummaryTabValue,
        enabled: enabledValue,
        modifyBy: dataPreview?.modifyBy,
        modifyDate: dataPreview?.modifyDate,
        createdDate: dataPreview?.createdDate,
        modifyByString: dataPreview?.modifyByString,
        clauses: clauseValue,
      });
      onClose();
    } else {
      setOpenAddClauseRequiredDialog(true);
    }
  };

  const handleDelete = async (id: number) => {
    await DataPreviewManagementService.deleteDataPreview(id);
    onDelete();
    onClose();
  };

  const renderFormButtons = () => (
    <Grid item xs={12}>
      <SaveButton onClick={() => handleOnSubmit()} />
      <CancelButton
        onClick={() => {
          onClose();
        }}
      />
      <DeleteButton
        onClick={() => setOpenConfirmationDialog(true)}
        disabled={!userHasWritePermission || !dataPreview}
      />
    </Grid>
  );

  const emptyClause = {
    resourceId: undefined,
    resourceTypeId: undefined,
  };

  const handleClauseCheck = (clause: Clause[]) => {
    for (let i = 0; i < clause.length; i += 1) {
      if (
        clause[i].resourceId === undefined ||
        clause[i].resourceTypeId === undefined
      ) {
        setIsThereAClause(false);
      } else {
        setIsThereAClause(true);
      }
    }
  };

  const onAdd = () => {
    const updateClauseValues = [...clauseValue];
    updateClauseValues.push(emptyClause);
    setClauseValue(updateClauseValues);
    handleClauseCheck(updateClauseValues);
  };

  const onUpdate = (data: Clause, index: number) => {
    const updateClauseValues = [...clauseValue];
    updateClauseValues[index] = data;
    setClauseValue(updateClauseValues);
    handleClauseCheck(updateClauseValues);
  };

  const onClauseDelete = (index: number) => {
    const updateClauseValues = [...clauseValue];
    updateClauseValues.splice(index, 1);
    setClauseValue(updateClauseValues);
    handleClauseCheck(updateClauseValues);
  };

  return (
    <>
      <DraggableToolbox
        title={label}
        initiallyExpanded
        onClose={() => onClose()}
      >
        <Grid container spacing={1}>
          <FormTextField
            value={validate(dataPreview, 'dataPreviewRuleId')}
            translationKey="dataPreview.id"
            disabled
          />
          <FormTextField
            value={dataPreviewRuleValue}
            onChange={(event) => setDataPreviewRuleValue(event.target.value)}
            translationKey="dataPreview.rule"
            disabled
          />
          <FormTextField
            value={dataPreviewDescriptionValue}
            onChange={(event) =>
              setDataPreviewDescriptionValue(event.target.value)
            }
            translationKey="common.textfields.description"
            error={attemptedSubmit && !dataPreviewDescriptionValue.trim()}
            helperText={
              attemptedSubmit && !dataPreviewDescriptionValue.trim()
                ? 'A description is required!'
                : ''
            }
            required
          />
          {createToggle('Keep Summary Tab', keepSummaryTabValue, () =>
            keepSummaryTabValue
              ? setKeepSummaryTabValue(false)
              : setKeepSummaryTabValue(true)
          )}
          {createToggle('Enabled', enabledValue, () =>
            enabledValue ? setEnabledValue(false) : setEnabledValue(true)
          )}
          <DataPreviewClauseFields
            clauses={clauseValue}
            onAdd={onAdd}
            onClauseDelete={onClauseDelete}
            onUpdate={onUpdate}
          />
          <ModifyBy
            username={
              dataPreview
                ? `${dataPreview.modifyByString} (${dataPreview.modifyBy})`
                : ''
            }
          />
          <ModifyDate
            date={dataPreview ? String(dataPreview.modifyDate) : ''}
          />
          {renderFormButtons()}
          <ConfirmationDialog
            title="Delete Rule?"
            content="Are you sure you want to delete this rule?"
            onConfirm={() =>
              handleDelete(
                dataPreview ? dataPreview.dataPreviewRuleId : undefined
              )
            }
            onCancel={() => setOpenConfirmationDialog(false)}
            open={openConfirmationDialog}
          />
          <GenericInfoDialog
            title="Warning"
            message="You must add at least 1 full Clause to add a rule."
            open={OpenAddClauseRequiredDialog}
            onClose={() => setOpenAddClauseRequiredDialog(false)}
          />
        </Grid>
      </DraggableToolbox>
    </>
  );
};

export default withStyles(styles)(
  withSnackbars(DataPreviewManagementDialogBox)
);
