import { makeStyles } from '@mui/styles';
import { RegisterOptions, useForm } from 'react-hook-form';
import { CancelButton } from '@onc/composite-components';
import { Grid, LoadingButton, Paper, Typography } from 'base-components';
import { CableTypeRow } from 'domain/AppComponents/cabletype/CableTypeTableLogic';
import { ConnectorTypeRow } from 'domain/Apps/connectortype/ConnectorTypeTableLogic';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const useStyles = makeStyles(() => ({
  style: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-8px',
  },
}));

type CableListingFormFields = {
  cableName: string;
  length: string;
  colorBand: string;
  characteristics: string;
  jira: string;
  cableType: { value: number; label: string } | null;
  connectorType1: { value: number; label: string } | null;
  connectorType2: { value: number; label: string } | null;
};

type FormRules<T> = {
  [P in keyof T]?: RegisterOptions;
};

const isJiraValid = (value: string) => {
  if (value === undefined) {
    return true;
  }
  const jira = value.split('-');
  if (jira[0] !== 'DMAS' || Number.isNaN(Number(jira[1]))) {
    return 'Must be of the form DMAS-####';
  }
  return true;
};

const isLengthValid = (value: string) => {
  if (value === undefined) {
    return true;
  }
  if (isNaN(Number(value)) || Number(value) < 0) {
    return 'Length must be a positive number';
  }
  return true;
};

const rules: FormRules<CableListingFormFields> = {
  cableName: {
    required: 'Cable Name Required',
  },
  length: {
    validate: (value: string) => isLengthValid(value),
  },
  jira: {
    validate: (value: string) => isJiraValid(value),
  },
};

type CableListingProps = {
  cableTypeList: CableTypeRow[];
  connectorTypeList: ConnectorTypeRow[];
  onClose: () => void;
  onSubmit: ({
    cableName,
    cableType,
    connectorType1,
    connectorType2,
    length,
    colorBand,
    characteristics,
    jira,
  }: {
    cableName: string;
    cableType: { value: number; label: string };
    connectorType1: { value: number; label: string };
    connectorType2: { value: number; label: string };
    length: string;
    colorBand: string;
    characteristics: string;
    jira: string;
  }) => void;
};

const CableListingForm: React.FC<CableListingProps> = ({
  cableTypeList,
  connectorTypeList,
  onClose,
  onSubmit,
}: CableListingProps) => {
  const classes = useStyles();
  const formMethods = useForm<CableListingFormFields>({});
  const {
    formState: { isSubmitting },
  } = formMethods;
  return (
    <Paper
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        maxWidth: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Create New Cable
      </Typography>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Grid container rowSpacing={0} columnSpacing={1}>
          <Grid item xs={6}>
            <FormTextField
              translationKey="cables.cableName"
              name="cableName"
              fullWidth
              rules={rules.cableName}
            />
            <FormAutocomplete
              name="cableType"
              translationKey="cables.cableType"
              fullWidth
              options={cableTypeList}
              getOptionLabel={(option) =>
                `${option.cableTypeName} (${option.cableTypeId})`
              }
            />
            <FormAutocomplete
              name="connectorType1"
              translationKey="cables.connectorType"
              translationOptions={{ connectorType: '1' }}
              fullWidth
              options={connectorTypeList}
              getOptionLabel={(option) =>
                `${option.connectorTypeName} (${option.connectorTypeId})`
              }
            />
            <FormAutocomplete
              name="connectorType2"
              translationKey="cables.connectorType"
              translationOptions={{ connectorType: '2' }}
              fullWidth
              options={connectorTypeList}
              getOptionLabel={(option) =>
                `${option.connectorTypeName} (${option.connectorTypeId})`
              }
            />
            <FormTextField
              name="length"
              translationKey="cables.length"
              fullWidth
              rules={rules.length}
            />
            <FormTextField
              name="colorBand"
              translationKey="cables.colourBand"
              fullWidth
            />
            <FormTextField
              name="characteristics"
              translationKey="cables.characteristics"
              fullWidth
            />
            <FormTextField
              name="jira"
              translationKey="cables.jira"
              fullWidth
              rules={rules.jira}
            />
          </Grid>
        </Grid>
        <div className={classes.style}>
          <CancelButton onClick={onClose} />
          <LoadingButton
            variant="contained"
            translationKey="common.buttons.save"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </Form>
    </Paper>
  );
};

export default CableListingForm;
