import { useMemo } from 'react';
import { useQueries } from '@onc/service';
import DeploymentsWebService, {
  type Deployment,
} from 'domain/services/DeploymentsWebService';
import DeviceWebService from 'domain/services/DeviceWebService';
import { getSourceIdentifier } from '../TraceDataUtils';
import type { TraceLegendRowData } from '../TraceLegend';
import type { ScalarDataChartLocationSource } from '../types/TimeSeriesScalarDataChart.types';
import type { Device } from 'domain/services/DeviceGeneralTabService';

export type SourcedLocationDeployment = Deployment & {
  dataSource: ScalarDataChartLocationSource;
};

const useLocationRows = (
  locations: ScalarDataChartLocationSource[],
  disabled: boolean = false,
  additionalParams: Record<string, any> = {}
) => {
  // queries will be a different object on each render.
  // TODO: when we upgrade to tanstack query v5 all of the following memos can be replaced by using the combine property
  const deployments = useQueries({
    queries: locations.map((location) => ({
      queryKey: ['multipleLocationDeployments', getSourceIdentifier(location)],
      queryFn: async (): Promise<SourcedLocationDeployment[]> => {
        if (!disabled) {
          const response = await DeploymentsWebService.get({
            locationCode: location.dataSource.stationCode,
            deviceCategoryCode: location.deviceCategoryCode,
            ...additionalParams,
          });
          return (response.data as Deployment[]).map((deployment) => ({
            ...deployment,
            dataSource: location,
          }));
        }
        return [];
      },
    })),
  });

  const devices = useQueries({
    queries: locations
      .flatMap((location) => location.dataSource.siteDeviceList)
      .map((siteDevice) => siteDevice.deviceId)
      .map((deviceId) => ({
        queryKey: ['locationDeployments', deviceId],
        queryFn: async (): Promise<Device> => {
          if (!disabled) {
            const response = await DeviceWebService.get({
              deviceId,
              method: 'get',
            });
            return response.data[0];
          }
          return undefined;
        },
      })),
  });

  // once devices is loaded create a map of deviceCodes to deviceNames
  const deviceMap: Map<string, Device> = useMemo(() => {
    if (!devices.every((device) => device.isSuccess)) {
      return new Map<string, Device>();
    }

    const retVal = new Map<string, Device>();
    devices.forEach((device) => {
      retVal.set(device.data.deviceCode, device.data);
    });
    return retVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(devices)]);

  const rows: TraceLegendRowData[] = useMemo(
    () =>
      deployments.flatMap((deployment, i) => {
        const { data: deploymentsData } = deployment;
        if (deploymentsData) {
          return deploymentsData.map((deploymentData, j) => {
            const device = deviceMap.get(deploymentData.deviceCode);
            const sourceIdentifier = getSourceIdentifier(
              deploymentData.dataSource
            );
            return {
              id: `${sourceIdentifier}-${i}-${j}`,
              sourceIdentifier,
              sourceName:
                deploymentData.dataSource.traceName ??
                deploymentData.dataSource.deviceCategoryCode,
              traceColour: deploymentData.dataSource.traceColour,
              deviceName: device?.deviceName || '',
              deviceId: device?.deviceId || 0,
              dateFrom: deploymentData.begin,
              dateTo: deploymentData.end,
            };
          });
        }
        return [];
      }),
    [deployments, deviceMap]
  );

  const isFetching = useMemo(
    () =>
      deployments.some((query) => query.isFetching) &&
      devices.some((query) => query.isFetching),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(deployments), JSON.stringify(devices)]
  );

  const isError = useMemo(
    () =>
      deployments.some((query) => query.isError) ||
      devices.some((query) => query.isError),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(deployments), JSON.stringify(devices)]
  );

  const result = useMemo(
    () => ({
      rows,
      isFetching,
      isError,
    }),
    [isError, isFetching, rows]
  );

  return result;
};

export default useLocationRows;
