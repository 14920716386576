import { useState } from 'react';
import { useLocalStorage } from '@onc/storage';
import {
  SnackbarProvider,
  StatelessTable,
  TableFilterLogic,
  TableFilter,
  TableColumn,
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
} from 'base-components';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import { CableListingRow } from './CableListingTableLogic';

type CableListingProps = {
  rows: CableListingRow[];
  permission: string;
  onCreate: () => void;
};
const CableListingTable: React.FC<CableListingProps> = ({
  rows,
  permission,
  onCreate,
}: CableListingProps) => {
  const storageKey = 'cable-listing-table';
  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'cableId', direction: 'desc' }],
  };

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  // Filtering
  const [filter, setFilter] = useState<TableFilter>(config.filter);

  const columns: TableColumn[] = [
    { title: 'Cable Id', name: 'cableId', dataType: 'Number' },
    { title: 'Cable Name', name: 'cableName', dataType: 'String' },
    { title: 'Cable Type', name: 'cableTypeName', dataType: 'String' },
    {
      title: 'Connector Type 1',
      name: 'connectorTypeName1',
      dataType: 'String',
    },
    {
      title: 'Connector Type 2',
      name: 'connectorTypeName2',
      dataType: 'String',
    },
    { title: 'Length', name: 'length', dataType: 'String' },
    { title: 'Color Band', name: 'colorBand', dataType: 'String' },
    { title: 'Characteristics', name: 'characteristics', dataType: 'String' },
    { title: 'Jira', name: 'jira', dataType: 'String' },
    { title: 'Modified By', name: 'modifyBy', dataType: 'String' },
    { title: 'Modified Date', name: 'modifyDate', dataType: 'String' },
  ];

  const columnWidths: TableColumnWidthInfo[] = [
    {
      columnName: 'cableId',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'cableName',
      width: ColumnInfo.large,
    },
    {
      columnName: 'cableTypeName',
      width: ColumnInfo.large,
    },
    {
      columnName: 'connectorTypeName1',
      width: ColumnInfo.large,
    },
    {
      columnName: 'connectorTypeName2',
      width: ColumnInfo.large,
    },
    {
      columnName: 'length',
      width: ColumnInfo.small,
    },
    {
      columnName: 'colorBand',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'characteristics',
      width: ColumnInfo.large,
    },
    {
      columnName: 'jira',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'modifyBy',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'modifyDate',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'actions',
      width: ColumnInfo.small,
    },
  ];

  return (
    <SnackbarProvider>
      <StatelessTable
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row.cableId}
        searchable
        resize={{
          columnWidths,
        }}
        sort={{
          sorting,
          customSorting: [
            {
              columnName: 'cableName',
              compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            },
            {
              columnName: 'comment',
              compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            },
          ],
          handleSortingChange: setSorting,
        }}
        paging={{ pageSize: 15, pageSizes: [15, 30, 60, 300] }}
        permission={permission}
        visible={{
          hiddenColumnNames: [],
        }}
        onCreate={onCreate}
        filter={{
          filterValue: filter,
          onChange: setFilter,
          filterFn: TableFilterLogic.defaultFilter,
          hidden: false,
        }}
      />
    </SnackbarProvider>
  );
};

export default CableListingTable;
