import { createStyles, makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { SaveButton, CancelButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import ImageWidgetConfigContent from 'domain/Widgets/Config/ImageWidgetConfigContent';
import Form from 'library/CompositeComponents/form/Form';

const useStyles = makeStyles(() =>
  createStyles({
    formButtons: {
      marginTop: 10,
      float: 'right',
    },
  })
);

type ImageWidgetConfigValues = {
  title?: string;
  imageUrl?: string;
  onSave?: (values: any) => void;
  onCancel?: () => void;
  showTitle?: boolean;
};

type ImageWidgetConfigFormType = {
  title: string;
  imageUrl: string;
  showTitle: boolean;
};

const ImageWidgetConfig = ({
  title = undefined,
  imageUrl = undefined,
  onSave = undefined,
  onCancel = undefined,
  showTitle = undefined,
}: ImageWidgetConfigValues) => {
  const classes = useStyles();

  const formMethods = useForm<ImageWidgetConfigFormType>({
    defaultValues: {
      title,
      imageUrl,
      showTitle,
    },
    mode: 'onBlur',
  });

  return (
    <Dialog open fullWidth>
      <DialogTitle>Configure Image Widget</DialogTitle>
      <DialogContent>
        <Form onSubmit={onSave} formMethods={formMethods}>
          <ImageWidgetConfigContent formMethods={formMethods} />
          <div className={classes.formButtons}>
            <CancelButton onClick={onCancel} />
            <SaveButton />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ImageWidgetConfig;
