import { useState, useEffect } from 'react';
import {
  ContainedButton,
  SaveButton,
  CancelButton,
} from '@onc/composite-components';
import { Grid, TextField } from 'base-components';
import DraggableToolbox from 'domain/AppComponents/annotations/entry/DraggableToolbox';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

import Organization from 'domain/Apps/network-console/organizations/Organization';
import DateFormatUtils from 'util/DateFormatUtils';

const validate = (
  organization: Organization | undefined,
  fieldName: keyof Organization
) => (organization && organization[fieldName] ? organization[fieldName] : '');

const FormTextField = (props) => (
  <Grid item xs={12}>
    <TextField fullWidth {...props} />
  </Grid>
);

const OrganizationsDialogBox = ({
  title,
  onClose,
  onSubmit,
  organization,
}: {
  title: string;
  onClose: () => any;
  onSubmit: (data: any) => any;
  organization: Organization | undefined;
}) => {
  const [organizationNameValue, setOrganizationName] = useState(
    String(validate(organization, 'name'))
  );
  const [organizationUrlValue, setOrganizationUrl] = useState(
    validate(organization, 'organizationUrl')
  );
  const [organizationEmailValue, setOrganizationEmail] = useState(
    validate(organization, 'organizationEmail')
  );
  const [organizationPhoneValue, setOrganizationPhone] = useState(
    validate(organization, 'organizationPhone')
  );
  const [rorIdValue, setRorId] = useState(validate(organization, 'rorId'));
  const [smallLogo, setSmallLogo] = useState<{ name: string; base64?: string }>(
    organization
      ? {
          base64: organization.logoSmall,
          name: 'No file chosen',
        }
      : { name: 'No file chosen ' }
  );
  const [bigLogo, setBigLogo] = useState<{ name: string; base64?: string }>(
    organization
      ? {
          base64: organization.logoBig,
          name: 'No file chosen',
        }
      : { name: 'No file chosen ' }
  );
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const createChooseFileField = (
    value,
    setValue,
    fieldTitle: string,
    width: number,
    height: number
  ) => (
    <>
      <Grid item xs={12}>
        <span>{fieldTitle}:</span>
        <ContainedButton
          translationKey="common.buttons.chooseFile"
          component="label"
        >
          <input
            type="file"
            onChange={(event) => {
              if (event && event.target && event.target.files) {
                const file: File & { base64?: string } = event.target.files[0];
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onloadend = () => {
                  if (typeof fileReader.result === 'string') {
                    file.base64 = fileReader.result;
                  } else {
                    file.base64 = String(fileReader.result);
                  }
                  setValue(file);
                };
              }
            }}
            style={{ display: 'none' }}
            accept="img"
          />
        </ContainedButton>
        {value.name}
      </Grid>
      {value.base64 ? (
        <Grid>
          <img
            src={value.base64}
            style={{ width: `${width}px`, height: `${height}px` }}
            alt=""
          />
        </Grid>
      ) : (
        ''
      )}
    </>
  );

  useEffect(() => {
    if (organization) {
      setOrganizationName(organization?.name || '');
      setOrganizationUrl(organization?.organizationUrl || '');
      setOrganizationEmail(organization?.organizationEmail || '');
      setOrganizationPhone(organization?.organizationPhone || '');
      setRorId(organization?.rorId || '');
      setSmallLogo({
        base64: organization.logoSmall,
        name: 'No file chosen',
      });
      setBigLogo({
        base64: organization.logoBig,
        name: 'No file chosen',
      });
      setAttemptedSubmit(false);
    }
  }, [organization]);

  const handleSubmit = () => {
    setAttemptedSubmit(true);

    if (!organizationNameValue.trim()) {
      setOrganizationName('');
      return;
    }

    onSubmit({
      id: organization ? organization.id : undefined,
      name: organizationNameValue,
      organizationEmail: organizationEmailValue,
      organizationUrl: organizationUrlValue,
      organizationPhone: organizationPhoneValue,
      rorId: rorIdValue,
      logoSmall: smallLogo.base64,
      logoBig: bigLogo.base64,
      organizationType: organization ? organization.type : '',
      parentOrganization: organization ? organization.parentOrganization : '',
    });
  };

  const renderFormButtons = () => (
    <Grid item xs={12}>
      <SaveButton onClick={() => handleSubmit()} />
      <CancelButton
        onClick={() => {
          onClose();
        }}
      />
    </Grid>
  );

  return (
    <>
      <DraggableToolbox
        title={title}
        initiallyExpanded
        onClose={() => onClose()}
      >
        <Grid container spacing={1}>
          <FormTextField
            value={organization && organization.id ? organization.id : ''}
            translationKey="networkConsole.organizationId"
            disabled
          />
          <FormTextField
            value={organizationNameValue}
            onChange={(event) => setOrganizationName(event.target.value)}
            translationKey="networkConsole.organizationName"
            error={attemptedSubmit && !organizationNameValue.trim()}
            helperText={
              attemptedSubmit && !organizationNameValue.trim()
                ? 'An organization name is required!'
                : ''
            }
            required
          />
          <FormTextField
            value={organizationUrlValue}
            onChange={(event) => setOrganizationUrl(event.target.value)}
            translationKey="networkConsole.organizationUrl"
          />
          <FormTextField
            value={organizationEmailValue}
            onChange={(event) => setOrganizationEmail(event.target.value)}
            translationKey="networkConsole.organizationEmail"
          />
          <FormTextField
            value={organizationPhoneValue}
            onChange={(event) => setOrganizationPhone(event.target.value)}
            translationKey="networkConsole.organizationPhone"
          />
          <FormTextField
            value={rorIdValue}
            onChange={(event) => setRorId(event.target.value)}
            translationKey="networkConsole.organizationRorId"
          />
          {createChooseFileField(
            smallLogo,
            setSmallLogo,
            'Small Logo (284px X 68px)',
            284,
            68
          )}
          {createChooseFileField(
            bigLogo,
            setBigLogo,
            'Big Logo (2028px X 488px)',
            2028,
            488
          )}
          <Grid item xs={12}>
            <ModifyBy
              username={organization ? organization.modifyByName : ''}
            />
            <ModifyDate
              date={
                organization
                  ? DateFormatUtils.formatDate(organization.modifyDate, 'full')
                  : ''
              }
            />
          </Grid>
          {renderFormButtons()}
        </Grid>
      </DraggableToolbox>
    </>
  );
};

export default OrganizationsDialogBox;
