import { useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import ItemSelect from 'domain/AppComponents/dropdowns/ItemSelect';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

type ExtensionDetailConfigForm = {
  detailId: number;
  item: number;
  quantity: number;
  notes: string;
  modifyDate: string;
};

type ExtensionDetailConfigProps = {
  title: string;
  onSave: (form: ExtensionDetailConfigForm) => void;
  onCancel: () => void;
  detail?: {
    extensionDetailId: number;
    itemDescription: string;
    itemId: string;
    modifyDate: string;
    notes: string;
    quantity: string;
  };
};

const ExtensionDetailConfig = ({
  title,
  onSave,
  onCancel,
  detail = {
    extensionDetailId: undefined,
    itemDescription: undefined,
    itemId: undefined,
    modifyDate: undefined,
    notes: undefined,
    quantity: undefined,
  },
}: ExtensionDetailConfigProps) => {
  const classes = {
    root: {
      display: 'flex',
      width: '100%',
    },
    rightAlign: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };

  const formMethods = useForm<ExtensionDetailConfigForm>({
    mode: 'onBlur',
    defaultValues: {
      detailId: detail.extensionDetailId,
      item: detail.itemId ? Number(detail.itemId) : undefined,
      quantity: detail.quantity ? Number(detail.quantity) : undefined,
      notes: detail.notes,
      modifyDate: detail.modifyDate,
    },
  });

  const detailId: () => JSX.Element = () => {
    if (detail.extensionDetailId !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="detailId"
            labelText="Detail ID"
            valueText={detail.extensionDetailId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const itemPicker: () => JSX.Element = () => (
    <Grid item xs={12}>
      <ItemSelect
        name="item"
        label="Item"
        fullWidth
        rules={{ required: 'An item is required!' }}
      />
    </Grid>
  );

  const modifyDate: () => JSX.Element = () => {
    if (detail.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyDate"
            labelText="Modify Date"
            valueText={detail.modifyDate}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const quantity: () => JSX.Element = () => (
    <Grid item xs={12}>
      <FormTextField
        name="quantity"
        fullWidth
        type="number"
        translationKey="cables.quantity"
        rules={{ required: 'A quantity is required!' }}
      />
    </Grid>
  );

  const notes: () => JSX.Element = () => (
    <Grid item xs={12}>
      <FormTextField
        name="notes"
        fullWidth
        translationKey="cables.notes"
        multiline
        minRows={3}
        maxRows={6}
      />
    </Grid>
  );

  const renderButtons: () => JSX.Element = () => (
    <>
      <CancelButton onClick={onCancel} />
      <SaveButton type="submit" />
    </>
  );

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={onSave} formMethods={formMethods}>
          <Grid container spacing={1} sx={classes.root}>
            {detailId()}
            {itemPicker()}
            {quantity()}
            {notes()}
            {modifyDate()}
          </Grid>
          <Grid item sx={classes.rightAlign}>
            {renderButtons()}
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ExtensionDetailConfig;
