import { Component } from 'react';
import { alpha, InputBase } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';

import { Search } from '@onc/icons';
import { IconButton } from 'base-components';

const styles = (theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.black, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
      },
      width: '100%',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        width: '50%',
        margin: '0 auto',
        left: '50%',
        maxWidth: '750px',
        transform: 'translateX(-50%)',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 1),
      height: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '100',
    },
    inputRoot: {
      color: 'inherit',
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      width: '100%',
    },
  });

type SearchFieldProps = {
  classes: {
    inputInput: string;
    inputRoot: string;
    search: string;
    searchIcon: string;
  };
  handleSearch: (value: any) => void;
  handleTextField: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textFieldValue?: string;
  placeholderText?: string;
  autoFocus?: boolean;
};

class SearchField extends Component<SearchFieldProps> {
  static defaultProps = {
    placeholderText: 'Search...',
    autoFocus: false,
    textFieldValue: undefined,
  };

  render() {
    const {
      classes,
      handleSearch,
      handleTextField,
      textFieldValue,
      placeholderText,
      autoFocus,
    } = this.props;
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <IconButton
            aria-label="search"
            onClick={handleSearch}
            color="inherit"
          >
            <Search />
          </IconButton>
        </div>
        <InputBase
          placeholder={placeholderText}
          autoFocus={autoFocus}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleTextField}
          value={textFieldValue}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch(textFieldValue);
            }
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SearchField);
