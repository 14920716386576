import { Component } from 'react';
import { withStyles, createStyles } from '@mui/styles';
import {
  ContainedButton,
  OutlinedButton,
  TextButton,
} from '@onc/composite-components';
import { OpenInNew } from '@onc/icons';

const styles = () =>
  createStyles({
    buttonPairContainer: {
      width: '100%',
      textAlign: 'end',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  });

type Props = {
  classes: any;
  onSubmit: (e: object) => void;
  handleReset: (e: object) => void;
  handleSeatubeSearchLink: (e: object) => void;
};
type State = any;
class AnnotationListFilterButtons extends Component<Props, State> {
  render() {
    const { classes, onSubmit, handleReset, handleSeatubeSearchLink } =
      this.props;

    return (
      <div className={classes.buttonPairContainer}>
        <OutlinedButton
          translationKey="seatube.seatubeSearch"
          onClick={handleSeatubeSearchLink}
          endIcon={<OpenInNew />}
        />
        <TextButton
          translationKey="common.buttons.reset"
          onClick={handleReset}
        />
        <ContainedButton
          onClick={onSubmit}
          translationKey="seatube.applyFilter"
        />
      </div>
    );
  }
}

export default withStyles(styles)(AnnotationListFilterButtons);
