/* eslint-disable react/prop-types */
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

export type ExampleWidgetConfigType = {
  sharedConfigValue: string;
  widgetConfigValue: string;
};

const ExampleWidgetConfigContent: ConfigFields<
  ExampleWidgetConfigType
> = () => (
  <>
    <FormTextField
      name="sharedConfigValue"
      translationKey="dashboards.sharedConfigValue"
      fullWidth
    />
    <FormTextField
      name="widgetConfigValue"
      translationKey="dashboards.widgetConfigValue"
      fullWidth
    />
  </>
);

export default ExampleWidgetConfigContent;
