import { ReactNode } from 'react';
import { StyledEngineProvider } from '@mui/material';
import Environment from '@onc/environment';
import { ReactQueryProvider } from '@onc/service';
import { DenseThemeProvider, oncDefaultTheme } from '@onc/theme';
import {
  AdapterMoment,
  LocalizationProvider,
  SnackbarProvider,
} from 'base-components';
import { BrowserRouter } from 'react-router-dom';

type ExportWrapperProps = {
  children: ReactNode;
};

const ExportWrapper = ({ children }: ExportWrapperProps) => {
  // Clear the current application token to ensure it doesn't have a value from the
  // last application that was rendered.
  Environment.setCurrentApplicationToken(undefined);

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider injectFirst>
          <DenseThemeProvider theme={oncDefaultTheme}>
            <ReactQueryProvider>
              <SnackbarProvider>{children}</SnackbarProvider>
            </ReactQueryProvider>
          </DenseThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default ExportWrapper;
