// Import react and the SeaTubeSearchResult component
import * as React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Divider, TableColumn, useSnackbars } from 'base-components';
import AnnotationTable from 'domain/AppComponents/sea-tube/annotation-table/AnnotationTable';
import TableAnnotation from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import { ServiceAnnotationV3 } from 'domain/services/AnnotationService';
import Panel from 'library/CompositeComponents/panel/Panel';
import SearchResultsActionTabs from './SearchResultsActionTabs';
import SeaTubeSearchResultsTableActions from './SeaTubeSearchResultsTableActions';

interface Props {
  searchResults: ServiceAnnotationV3[];
  titleContent?: string;
  playlists?: any;
  onPlaylistAdd?: any;
  isLoggedIn?: boolean;
  filter?: any;
  diveIdString?: string;
  deckLogIdString?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(),
    height: '100%',
  },
  table: {
    height: '65%',
    overflow: 'auto',
  },
  actions: {
    height: '35%',
    overflow: 'auto',
  },
  panel: {
    height: '100%',
  },
}));

const additionalColumns: TableColumn[] = [
  { name: 'cruiseName', title: 'Expedition', dataType: 'String' },
  { name: 'logName', title: 'Log', dataType: 'String' },
  { name: 'numTotalReviews', title: 'Total Reviews', dataType: 'Number' },
  {
    name: 'numPositiveReviews',
    title: 'Positive Reviews',
    dataType: 'Number',
  },
];

const SeaTubeSearchResultsPanel: React.FC<Props> = ({
  searchResults,
  titleContent = 'Search Results',
  deckLogIdString = '',
  playlists = [],
  onPlaylistAdd = undefined,
  isLoggedIn = false,
  filter = undefined,
  diveIdString = undefined,
}) => {
  const classes = useStyles();
  const { onError, onInfo } = useSnackbars();
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <div className={classes.panel}>
          <Panel title={titleContent}>
            <AnnotationTable
              rows={searchResults.map(
                (annotation) => new TableAnnotation(annotation)
              )}
              ActionFormatter={SeaTubeSearchResultsTableActions}
              storageKey="seatube-search-results-table"
              onError={onError}
              onInfo={onInfo}
              expeditionId={undefined}
              onRefresh={undefined}
              onEdit={undefined}
              hideFilter
              showNumberOfRecords
              additionalColumns={additionalColumns}
            />
          </Panel>
        </div>
      </div>
      <Divider />
      <div className={classes.actions}>
        <div className={classes.panel}>
          <Panel>
            <SearchResultsActionTabs
              playlists={playlists}
              onError={onError}
              onInfo={onInfo}
              onPlaylistAdd={onPlaylistAdd}
              isLoggedIn={isLoggedIn}
              filter={filter}
              diveIdString={diveIdString}
              deckLogIdString={deckLogIdString}
              searchResults={searchResults}
            />
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default SeaTubeSearchResultsPanel;
