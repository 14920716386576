import { useCallback } from 'react';
import { Loading } from '@onc/composite-components';
import {
  useLayout,
  useUpdateLayout,
} from 'domain/AppComponents/Dashboard/LayoutService';
import {
  useCreateWidget,
  useDashboardWidgets,
  useRemoveWidget,
  useUpdateWidget,
  WidgetServiceCreateParameters,
} from 'domain/AppComponents/Dashboard/WidgetService';
import AudioPlayerWidget from 'domain/Widgets/AudioPlayerWidget';
import DashboardVideoWidget from 'domain/Widgets/DashboardVideoWidget';
import DataPreviewWidget from 'domain/Widgets/DataPreviewWidget';
import DummyWidget from 'domain/Widgets/DummyWidget';
import ExampleWidget from 'domain/Widgets/ExampleWidget';
import ImageWidget from 'domain/Widgets/ImageWidget';
import LatestReadingsWidget from 'domain/Widgets/LatestReadingsWidget';
import TextWidget from 'domain/Widgets/TextWidget';
import TimeSeriesScalarDataChartWidget from 'domain/Widgets/TimeSeriesScalarDataChartWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import Dashboard from 'library/CompositeComponents/dashboard/Dashboard';
import {
  OncLayouts,
  WidgetOption,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import UserDefinedDashboardTitle from './UserDefinedDashboardTitle';

interface DashboardsParams {
  layoutId?: number;
}
type Props = {
  match: {
    isExact: boolean;
    params: DashboardsParams;
    path: string;
    url: string;
  };
};

const widgetOptions: WidgetOption[] = [
  { label: 'Example Widget', Component: ExampleWidget, multiple: true },
  {
    Component: TimeSeriesScalarDataChartWidget,
    label: 'Chart Widget',
    multiple: true,
  },
  { label: 'Dummy Widget', Component: DummyWidget, multiple: true },
  {
    label: 'Audio Player Widget',
    Component: AudioPlayerWidget,
    multiple: true,
  },
  { label: 'Video Widget', Component: DashboardVideoWidget, multiple: true },
  {
    label: 'Data Preview Widget',
    Component: DataPreviewWidget,
    multiple: true,
  },
  { label: 'Image Widget', Component: ImageWidget, multiple: true },
  {
    label: 'Latest Readings Widget',
    Component: LatestReadingsWidget,
    multiple: true,
  },
  {
    label: 'Text Widget',
    Component: TextWidget,
    multiple: true,
  },
];
export const UDWidgetLibrary: WidgetLibrary = new WidgetLibrary(widgetOptions);

const UserDefinedDashboard = (props: Props) => {
  const { match } = props;
  const { layoutId } = match.params;

  const { data: layoutData, invalidateQuery: refetchLayout } = useLayout({
    layoutId,
  });
  const layout: OncLayouts = layoutData?.layout
    ? JSON.parse(layoutData.layout.widgetLayout)
    : {
        lg: [],
        md: [],
        sm: [],
        xs: [],
      };
  const defaultWidgets = useDashboardWidgets(layoutId, layout);

  const initialTitle = layoutData?.layout?.layoutName;

  const { mutateAsync: updateLayout } = useUpdateLayout();
  const { mutateAsync: onWidgetCreate } = useCreateWidget();
  const { mutate: onWidgetChange } = useUpdateWidget();
  const { mutate: onWidgetRemove } = useRemoveWidget();

  const onWidgetAdd = useCallback(
    async (newWidget: WidgetServiceCreateParameters) => {
      try {
        const response = await onWidgetCreate(newWidget);

        return response.widget.widgetId;
      } catch (e) {
        return undefined;
      }
    },
    [onWidgetCreate]
  );

  const isReadWrite = layoutData?.layout.permission === 'RW';
  return layoutData && defaultWidgets ? (
    <Dashboard
      title=""
      id="user-defined-dashboard"
      layoutId={layoutData.layout.layoutId}
      widgetLibrary={UDWidgetLibrary}
      defaultLayout={layout}
      defaultWidgets={defaultWidgets || []}
      onDashboardDataChange={(dashboardData) => {
        updateLayout({
          ...layoutData.layout,
          widgetLayout: JSON.stringify(dashboardData.layouts),
        });
      }}
      onWidgetConfigChange={(widgetId: number, config) => {
        onWidgetChange({ widgetId, widgetConfig: JSON.stringify(config) });
      }}
      onWidgetCreate={onWidgetAdd}
      onWidgetRemove={(widgetId: number) => {
        onWidgetRemove({
          widgetId,
        });
      }}
      titleComponents={
        <UserDefinedDashboardTitle
          initialTitle={initialTitle}
          isEditable={isReadWrite}
          key={initialTitle}
          onTitleSave={async (layoutName: string) => {
            await updateLayout({ ...layoutData.layout, layoutName });
            refetchLayout();
          }}
        />
      }
    />
  ) : (
    <Loading />
  );
};

export default UserDefinedDashboard;
