import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import oncDefaultTheme from './oncDefaultTheme';

type DenseThemeProviderProps = {
  theme?: any;
  children?: React.ReactNode;
};

const DenseThemeProvider: React.FC<DenseThemeProviderProps> = ({
  children = <></>,
  theme = oncDefaultTheme,
}: DenseThemeProviderProps) => {
  const isDense = useMediaQuery(theme.breakpoints.up('md'));
  const components = {};

  if (isDense) {
    const defaultPropsMapping = {
      MuiFormControl: { defaultProps: { margin: 'dense' } },
      MuiFormHelperText: { defaultProps: { margin: 'dense' } },
      MuiIconButton: { defaultProps: { size: 'small' } },
      MuiInputBase: { defaultProps: { margin: 'dense' } },
      MuiInputLabel: { defaultProps: { margin: 'dense' } },
      MuiOutlinedInput: { defaultProps: { margin: 'dense' } },
      MuiFab: { defaultProps: { size: 'small' } },
      MuiChip: { defaultProps: { size: 'small' } },
      MuiTable: { defaultProps: { size: 'small' } },
      MuiTextField: { defaultProps: { size: 'small' } },
      MuiToolbar: { defaultProps: { variant: 'dense' } },
      MuiList: { defaultProps: { dense: true } },
      MuiListItem: { defaultProps: { dense: true } },
      MuiMenuItem: { defaultProps: { dense: true } },
      MuiToggleButtonGroup: { defaultProps: { size: 'small' } },
      Pager: { styleOverrides: { root: { padding: '0px' } } },
    };

    // Merge defaultProps into components
    Object.entries(defaultPropsMapping).forEach(([component, props]) => {
      components[component] = {
        ...components[component],
        ...props,
      };
    });
  }

  const modifiedTheme = createTheme({
    ...theme,
    components: {
      ...(theme.components || {}),
      ...components,
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={modifiedTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DenseThemeProvider;
