import { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ContainedButton } from '@onc/composite-components';
import { Divider, Grid, Typography } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { handleOpenDeviceDetails } from 'domain/AppComponents/link/DeviceDetailsLink';
import DataRatingService from 'domain/services/DataRatingService';

import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import DateUtils from 'util/DateUtils';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(1),
  },
});

const defaultState = {
  dateFrom: undefined,
  deviceName: undefined,
  sensorName: undefined,
  samplePeriod: undefined,
  sampleSize: undefined,
  comment: undefined,
  modifyByName: undefined,
  modifyDate: undefined,
};

class DataRatingRO extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
      roDivider: PropTypes.string,
    }).isRequired,
    dataRatingId: PropTypes.number,
    deviceId: PropTypes.number,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dataRatingId: undefined,
    deviceId: undefined,
  };

  renderSensorName = (name) => {
    if (name) {
      return (
        <MarginedReadOnlyField
          labelText="Sensor Name"
          valueText={name}
          title="sensorNameField"
        />
      );
    }
    return null;
  };

  constructor(props) {
    super(props);
    const { dataRatingId } = this.props;
    this.state = {
      dataRatingId,
      ...defaultState,
    };
  }

  componentDidMount() {
    const { dataRatingId } = this.state;

    if (dataRatingId) {
      this.getDataRatingService();
    }
  }

  getDataRatingService = () => {
    const { onError } = this.props;
    const { dataRatingId } = this.state;

    if (!dataRatingId || isNaN(dataRatingId)) return;

    DataRatingService.getOne(dataRatingId)
      .then((payload) => {
        const { dateFrom, ...rest } = payload;
        this.setState({
          dateFrom: DateUtils.formatDateAsString(new Date(dateFrom)),
          ...rest,
        });
      })
      .catch((e) => {
        onError(e.message);
        this.setState(defaultState);
      });
  };

  render() {
    const { classes, deviceId } = this.props;
    const {
      dataRatingId,
      deviceName,
      sensorName,
      dateFrom,
      samplePeriod,
      sampleSize,
      comment,
      modifyByName,
      modifyDate,
    } = this.state;

    let dataRatingText = dataRatingId;
    let color;
    if (isNaN(dataRatingId)) {
      color = 'textSecondary';
      dataRatingText = 'New';
    }
    return (
      // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
      <div>
        <Panel
          title={
            <>
              <Typography variant="body1">Data Rating Entry ID:</Typography>
              <Typography variant="body1" color={color}>
                {dataRatingText}
              </Typography>
            </>
          }
        >
          <Grid
            container
            direction="row"
            className={classes.entryFormContainer}
          >
            <Grid item xs={6}>
              <MarginedReadOnlyField
                labelText="Date From"
                valueText={dateFrom}
                title="dateFromField"
              />
              <MarginedReadOnlyField
                labelText="Device Name"
                valueText={deviceName}
                title="deviceNameTextField"
              />
              {this.renderSensorName(sensorName)}
              <MarginedReadOnlyField
                labelText="Sample Period"
                valueText={samplePeriod}
                title="samplePeriodField"
              />
              <MarginedReadOnlyField
                labelText="Sample Size"
                valueText={sampleSize}
                title="sampleSizeField"
              />
              <MarginedReadOnlyField
                labelText="Comment"
                valueText={comment}
                title="commentTextField"
              />
              <MarginedReadOnlyField
                labelText="Modified By"
                valueText={modifyByName}
                title="modifyByNameField"
              />
              <MarginedReadOnlyField
                labelText="Modified Date"
                valueText={DateUtils.formatDateAsString(new Date(modifyDate))}
                title="modifyDateField"
              />
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <ContainedButton
              translationKey="common.buttons.done"
              onClick={handleOpenDeviceDetails(deviceId)}
            />
          </div>
          <Divider variant="middle" className={classes.roDivider} />
        </Panel>
      </div>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(DataRatingRO));
