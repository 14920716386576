import { useMediaQuery, useTheme } from '@mui/material';
import { DevExpressTable as DXTable } from 'base-components';

const TaskTableStyledCell = ({ ...props }) => {
  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.up('md'))) {
    return (
      <DXTable.Cell
        {...props}
        isDense
        style={{
          height: theme.spacing(4),
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      />
    );
  }
  return (
    <DXTable.Cell
      {...props}
      isDense={false}
      style={{
        height: theme.spacing(6),
        paddingTop: '0px',
        paddingBottom: '0px',
      }}
    />
  );
};

export default TaskTableStyledCell;
