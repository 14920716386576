import { Component } from 'react';
import { MenuList, MenuItem, InputBase, alpha } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { FloatRightButtonStyles, TextButton } from '@onc/composite-components';
import { UserDetailsService } from '@onc/domain-services';
import Environment from '@onc/environment';
import { Add, Fullscreen } from '@onc/icons';
import { AnchoredHiddenMenu, IconButton } from 'base-components';
import EmbedDialog from 'domain/AppComponents/Dashboard/dashboard-embedding/EmbedDialog';
import DashboardAppBar from 'domain/AppComponents/Dashboard/DashboardAppBar';
import DashboardLayout from 'domain/AppComponents/Dashboard/DashboardLayout';
import LayoutService from 'domain/AppComponents/Dashboard/LayoutService';
import WidgetGenerator from 'domain/AppComponents/Dashboard/WidgetGenerator';
import WidgetService from 'domain/AppComponents/Dashboard/WidgetService';
import {
  VIDEO_WIDGET_TYPE_ID,
  DATA_SOURCE_SELECTION_WIDGET_TYPE_ID,
  DATA_PREVIEW_WIDGET_TYPE_ID,
  LATEST_READINGS_WIDGET_TYPE_ID,
  AUDIO_PLAYER_WIDGET_TYPE_ID,
  TEXT_WIDGET_TYPE_ID,
  IMAGE_WIDGET_TYPE_ID,
  DATA_PLAYER_WIDGET_TYPE_ID,
  CHART_WIDGET_TYPE_ID,
} from 'domain/AppComponents/Dashboard/WidgetTypeConstants';
import { EmbedUrlIconButton } from 'domain/AppComponents/IconButtons';

import { READ_ONLY, READ_WRITE } from 'util/PermissionsUtils';

import { PageTitle } from './Titles';

const FIVE_MINUTES = '5';
// locations requires a nodeId of -1 due to the ChartWidget now requiring a nodeId of some sort to work properly. It will be overwritten on editing the datasource in chart config
const locations = `[{
  "nodeId": "-1",
  "archived": "Y",
  "datasetRef": "Cambridge Bay",
  "deviceCategoryCode": "CTD",
  "deviceCategoryName": "Conductivity Temperature Depth",
  "deviceId": 23355,
  "els": [],
  "id": 13663,
  "name": "Temperature",
  "sensorName": "Temperature",
  "traceName": "Temperature",
  "traceColour": "#CD3333",
  "nodeId": "3459",
  "ns": "Y",
  "pathName": ["Arctic", "Cambridge Bay", "Underwater Network", "Conductivity Temperature Depth", "Sea-Bird SeaCAT SBE19plus V2 7518", "Temperature"],
  "searchable": "Y",
  "sensorCodeName": "temperature",
  "sensorTypeCode": "seawatertemperature",
  "sensortypeid": 8,
  "siteDeviceList": [{"deviceId": 23355, "siteDeviceId": 1191162},{"deviceId": 23491, "siteDeviceId": 1192522},{"deviceId": 23355, "siteDeviceId": 1194846},{"deviceId": 23491, "siteDeviceId": 1197305},{"deviceId": 23355, "siteDeviceId": 1200591},{"deviceId": 23028, "siteDeviceId": 1203544}],
  "sitedeviceid": 1191162,
  "stationCode": "CBYIP",
  "type": 102
  }]`;
const styles = (theme) => ({
  layoutHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: theme.spacing(2),
    background: theme.palette.grey[300], // Oceans 3 nav bar colour
    borderRadius: 4,
    height: theme.spacing(7),
  },
  title: {
    // Positioning 0 px from left aligns the title with the
    // left edge of the grid
    position: 'relative',
    marginLeft: theme.spacing(0),
    width: '100%',
  },
  titleField: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      position: 'relative',
      float: 'left',
      paddingLeft: '1%',
      verticalAlign: 'baseline',
    },
  },
  oceansNextContainer: {
    background: theme.palette.grey[100],
    height: '100%',
  },
  layoutDiv: {},
  fullscreenDiv: {
    overflowY: 'auto !important',
    background: theme.palette.grey[100],
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  addWidgetButton: FloatRightButtonStyles,
});

class DashboardEdit extends Component {
  static propTypes = {
    dashboardId: PropTypes.number.isRequired,
    classes: PropTypes.shape({
      actionButton: PropTypes.string,
      addWidgetButton: PropTypes.string,
      formHelperText: PropTypes.string,
      fullscreenDiv: PropTypes.string,
      layoutDiv: PropTypes.string,
      layoutHeader: PropTypes.string,
      oceansNextContainer: PropTypes.string,
      title: PropTypes.string,
      titleField: PropTypes.string,
    }).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    showTitleBar: PropTypes.bool.isRequired,
  };

  findNextVerticalSpace = (layout, newWidget) => {
    // This is understood to be redundant - in case algorithm changes for placement
    const widget = { ...newWidget };
    // A really big number so it always places it at the bottom of other widgets
    widget.props.dataGrid.y = 99999999;
    return widget;
  };

  getDefaultConfig = (widgetTypeId) => {
    switch (Number(widgetTypeId)) {
      case VIDEO_WIDGET_TYPE_ID:
        return '{"dataSource":"deviceId","showTitle":true,"title":"Sample Video","device":{"deviceId":23959,"name":"SubC Imaging 1Cam Mk5 SN 15551","deviceCode":"SUBC1CAMMK5_15551","deviceCategoryCode":"VIDEOCAM","dataRating":[]},"startDate":"2019-07-01T04:00:00.000Z","endDate":"2019-07-01T04:11:00.000Z","timeSource":"latestClip","locations":[]}';
      case DATA_PREVIEW_WIDGET_TYPE_ID:
        return `{"previewUrl":"${Environment.getDmasUrl()}/DataPreviewService?operation=5&searchTreeNodeId=15&deviceCategoryId=5&sensorCodeId=492&timeConfigId=2&dataProductFormatId=3&plotNumber=1","showTitle":true,"title":"Sample Preview Plot"}`;
      case LATEST_READINGS_WIDGET_TYPE_ID:
        return '{"sensorIds":"22209,22219","autoRefresh":true,"showTitle":true,"title":"Sample Latest Readings"}';
      case CHART_WIDGET_TYPE_ID:
        return `{"showWidgetTitle":true,"showChartTitle":true,"autoRefreshEnabled":false, "isMinMaxEnabled":false, "isRawCleanEnabled":false, "alwaysShowModebar":false, "widgetTitle":"Sample Chart Widget Title","chartTitle":"Sample Chart Title","dataSourceSelection":"location","datePreset":"day","dateSelection":"dateRange","startDate":"2020-08-30T00:00:00.000Z","endDate":"2020-09-30T00:00:00.000Z", "locationsLeftAxis": ${locations}, "displayQaqc":false}`;
      case AUDIO_PLAYER_WIDGET_TYPE_ID:
        return '{"showTitle":true,"loopPlayback":false,"clipSelectorValue":"latestClip","isBroadcasting":false,"startDate":"2017-08-31T00:00:00.330Z","endDate":"2017-08-31T00:05:00.000Z","device":{"deviceId":28559,"name":"Ocean Sonics icListen AF Hydrophone 2556","deviceCode":"ICLISTENAF2556","deviceCategoryCode":"HYDROPHONE"},"title":"Sample Audio","dataSourceType":"device","locations":[],"audioFormat":"flac"}';
      case TEXT_WIDGET_TYPE_ID:
        return '{"title":"Sample Text","showTitle":false,"htmlValue":"<h1 style=\\"text-align:left;\\"><span style=\\"color: rgb(41,105,176);font-family: Verdana;\\">Evidence-Based Decision Making</span></h1>\\n<p style=\\"text-align:left;\\"><span style=\\"font-family: Verdana;\\">The state of the ocean is an important indicator of the overall health of the planet. The ocean off the coasts of Canada, including the Arctic, comprises some of the richest and most diverse ecosystems on Earth. This makes Ocean Networks Canada data relevant to global users.</span></p>\\n<p style=\\"text-align:left;\\"><span style=\\"font-family: Verdana;\\">http://www.oceannetworks.ca</span></p>\\n"}';
      case IMAGE_WIDGET_TYPE_ID:
        return '{ "imageUrl": "https://farm6.staticflickr.com/5829/22337703739_373e6db366_z.jpg", "title": "Sample Image", "showTitle": true }';
      case DATA_PLAYER_WIDGET_TYPE_ID:
        return `{"title":"Sample Data Player","showTitle":true,"isBroadcasting":false,"startDate":"2018-10-12T15:20:00.000Z","dataSourceType":"deviceCategory", "locations":[], "dateSelectorValue":"latest","deviceId":28559,"deviceCategoryCode":"HYDROPHONE","timeRange":"${FIVE_MINUTES}","device":{"deviceId":28559,"name":"Ocean Sonics icListen AF Hydrophone 2556","deviceCategoryCode":"HYDROPHONE","deviceCode":"ICLISTENAF2556"}}`;
      case DATA_SOURCE_SELECTION_WIDGET_TYPE_ID:
        return `{"title":"Data Source Selection Widget"}`;
      default:
        // return image config until we have them defined
        return '{ "imageUrl": "https://farm6.staticflickr.com/5829/22337703739_373e6db366_z.jpg", "title": "Sample Image", "showTitle": true }';
    }
  };

  getWidgetNameById = (widgetTypeId) => {
    switch (Number(widgetTypeId)) {
      case VIDEO_WIDGET_TYPE_ID:
        return 'Video';
      case DATA_PREVIEW_WIDGET_TYPE_ID:
        return 'Data Preview';
      case LATEST_READINGS_WIDGET_TYPE_ID:
        return 'Latest Readings';
      case CHART_WIDGET_TYPE_ID:
        return 'Chart';
      case AUDIO_PLAYER_WIDGET_TYPE_ID:
        return 'Audio Player';
      case TEXT_WIDGET_TYPE_ID:
        return 'Text';
      case IMAGE_WIDGET_TYPE_ID:
        return 'Image';
      case DATA_PLAYER_WIDGET_TYPE_ID:
        return 'Data Player';
      case DATA_SOURCE_SELECTION_WIDGET_TYPE_ID:
        return 'Data Source Selection';
      default:
        return 'None';
    }
  };

  state = {
    layout: {},
    widgets: [],
    name: '',
    isPublic: false,
    permission: READ_ONLY,
    isFullscreen: false,
    showEmbedDialog: false,
    isLoggedIn: undefined,
    error: undefined,
    appToken: undefined,
  };

  componentDidMount() {
    this.getLayoutAndWidgets();
    document.addEventListener('fullscreenchange', this.resetFullscreen);
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.resetFullscreen);
  }

  resetFullscreen = () => {
    const { prevPermission, isFullscreen } = this.state;
    if (
      document.fullscreenElement &&
      document.fullscreenElement.attributes &&
      document.fullscreenElement.attributes[3] &&
      document.fullscreenElement.attributes[3].value.includes('Video Player')
    ) {
      return; // When the JW Player goes fullscreen it triggers this reset too early
    }
    if (isFullscreen && document.fullscreenElement === null) {
      this.setState({ isFullscreen: false, permission: prevPermission });
    }
    this.fetchWidgets();
  };

  checkAuthenticated = async () => {
    const isLoggedIn = await UserDetailsService.isLoggedIn();
    this.setState({ isLoggedIn });
  };

  handleFullscreen = async () => {
    const { permission, isFullscreen } = this.state;
    const { onError } = this.props;
    // this may cure the symptoms of a larger underlying problem
    await this.handleTitleOffFocus(); // the layout was not being saved when you add a new widget
    await this.getLayoutAndWidgets(); // the widgets and layout were not being updated

    const elem = document.getElementById('dashboardLayout');
    if (document.fullscreenElement === null && !isFullscreen) {
      const prevPermission = permission;
      elem
        .requestFullscreen()
        .then(() => {
          this.setState({
            isFullscreen: true,
            prevPermission,
            permission: READ_ONLY,
          });
        })
        .then(() => {
          this.updateWidgets();
        })
        .catch((error) => {
          onError(error.message);
        });
    }
  };

  getLayoutAndWidgets = async () => {
    const { dashboardId } = this.props;
    await this.checkAuthenticated();
    await this.fetchLayout(dashboardId);
    await this.fetchWidgets();
  };

  fetchLayout = async (dashboardId) => {
    const { onError } = this.props;

    await LayoutService.get({
      operation: 4,
      layoutId: dashboardId,
    })
      .then((payload) => {
        const { widgetLayout, layoutName, permission, isPublic } =
          payload.layout;
        this.setState({
          name: layoutName,
          layout: JSON.parse(widgetLayout),
          prevPermission: permission,
          permission,
          isPublic,
          appToken: payload.layout.token,
        });
        Environment.setCurrentApplicationToken(payload.layout.token);
      })
      .catch((error) => {
        this.setState({ error: error.response });
        onError(
          `Failed to get saved layout for dashboard ${dashboardId}. ${error.response.statusText}`
        );
      });
  };

  fetchWidgets = () => {
    const { dashboardId, onError } = this.props;

    return WidgetService.get({
      operation: 100,
      layoutId: dashboardId,
    })
      .then((payload) => {
        this.setState({ widgetData: payload.widgets }, this.updateWidgets);
      })
      .catch((error) => {
        onError(
          `Failed to get user's widgets for dashboard ${dashboardId}. ${error}`
        );
      });
  };

  updateWidgets = () => {
    const { widgetData, permission, isFullscreen, isLoggedIn } = this.state;
    const newWidgets = widgetData.map((widget) => ({
      widgetid: widget.widgetId,
      widgettypeid: widget.widgetTypeId,
      widgetconfig: widget.widgetConfig,
      onRemove: this.handleRemoveClick,
      permission: isFullscreen ? READ_ONLY : permission,
      isLoggedIn,
      onClone: this.cloneWidget,
    }));
    this.setState({
      widgets: newWidgets.map((widget) => WidgetGenerator.build(widget)),
    });
  };

  handleTitleChange = (e) => {
    if (e.target.value.length < 255) {
      this.setState({ name: e.target.value, helperText: undefined });
    } else {
      this.setState({
        helperText: 'Titles must contain less than 255 characters!',
      });
    }
  };

  handleRemoveClick = (widgetId) => {
    this.setState((prevState) => ({
      widgets: prevState.widgets.filter((child) => child.key !== widgetId),
    }));
  };

  createWidget = async (typeId) => {
    const { dashboardId, onError, onInfo } = this.props;
    const { permission, layout, isLoggedIn } = this.state;
    const defaultConfig = this.getDefaultConfig(typeId);
    let payload;
    try {
      payload = await WidgetService.post({
        operation: 1,
        widgetTypeId: typeId,
        layoutId: dashboardId,
        widgetConfig: defaultConfig,
      });
    } catch (error) {
      onError(
        `Failed to create widget of type ${typeId} for dashboard ${dashboardId}. ${error}`
      );
      return undefined;
    }
    const { widgetId, widgetTypeId, widgetConfig } = payload.widget;
    const newlyCreatedWidget = {
      widgetid: widgetId,
      widgettypeid: widgetTypeId,
      widgetconfig: widgetConfig,
      onRemove: this.handleRemoveClick,
      permission,
      isLoggedIn,
      onClone: this.cloneWidget,
    };
    const newWidget = WidgetGenerator.build(newlyCreatedWidget);
    const widgetWithPosition = this.findNextVerticalSpace(layout, newWidget);
    this.setState((prevState) => ({
      widgets: [...prevState.widgets, widgetWithPosition],
    }));
    this.fetchWidgets();
    return onInfo(`New Widget Added: ${this.getWidgetNameById(typeId)}`);
  };

  cloneWidget = async (widgetIdOld) => {
    const { dashboardId, onError, onInfo } = this.props;
    const { permission, layout, isLoggedIn } = this.state;
    let clonedWidget;
    try {
      // get cloned widget config
      clonedWidget = await WidgetService.get({
        operation: 4,
        widgetId: widgetIdOld,
      });
    } catch (error) {
      onError(`Failed to clone widget for dashboard ${dashboardId}. ${error}`);
      return undefined;
    }
    const { widgetTypeId, widgetConfig: widgetConfigOld } = clonedWidget.widget;
    // create new widget with cloned widget's config
    let clone;
    try {
      clone = await WidgetService.post({
        operation: 1,
        widgetTypeId,
        layoutId: dashboardId,
        widgetConfig: widgetConfigOld,
      });
    } catch (error) {
      onError(`Failed to clone widget for dashboard ${dashboardId}. ${error}`);
      return undefined;
    }

    const { widgetId: widgetIdNew, widgetConfig } = clone.widget;
    const newlyCreatedWidget = {
      widgetid: widgetIdNew,
      widgettypeid: widgetTypeId,
      widgetconfig: widgetConfig,
      onRemove: this.handleRemoveClick,
      permission,
      isLoggedIn,
      onClone: this.cloneWidget,
    };
    const newWidget = WidgetGenerator.build(newlyCreatedWidget);
    const widgetWithPosition = this.findNextVerticalSpace(layout, newWidget);
    this.setState((prevState) => ({
      widgets: [...prevState.widgets, widgetWithPosition],
    }));
    this.fetchWidgets();
    return onInfo(`Widget Cloned Successfully`);
  };

  handleTitleOffFocus = () => {
    const { dashboardId, onError } = this.props;
    const { name, isPublic, layout } = this.state;
    return LayoutService.post({
      operation: 2,
      layoutId: dashboardId,
      widgetLayout: layout,
      layoutName: name,
      isPublic,
    })
      .then((payload) => {
        const { widgetLayout, layoutName: dashboardName } = payload.layout;
        this.setState({
          currentLayouts: widgetLayout,
          dashboardName,
        });
      })
      .catch((error) => {
        onError(`Failed to update dashboard title. ${error}`);
      });
  };

  handleCancelEmbedDialog = () => {
    this.setState({ showEmbedDialog: false });
  };

  handleOpenEmbedDialog = () => {
    this.setState({ showEmbedDialog: true });
  };

  handleAddWidget = async (event) => {
    await this.createWidget(event.target.id);
  };

  handleUpdateLayout = (newLayout) => {
    this.setState({ layout: newLayout });
  };

  renderTitleBar = (
    titleField,
    addWidgetButton,
    fullscreenButton,
    embedDashboardButton
  ) => {
    const { classes, showTitleBar } = this.props;
    if (showTitleBar) {
      return (
        <DashboardAppBar className={classes.layoutHeader}>
          {titleField}
          {addWidgetButton}
          {embedDashboardButton}
          {fullscreenButton}
        </DashboardAppBar>
      );
    }
    return null;
  };

  render() {
    const {
      layout,
      name,
      widgets,
      permission,
      isPublic,
      helperText,
      isFullscreen,
      showEmbedDialog,
      error,
      appToken,
    } = this.state;
    // Set the current active token to the dashboard's token
    Environment.setCurrentApplicationToken(appToken);

    const { classes, dashboardId } = this.props;
    const useInitialLayouts = false;
    let layoutComponent = null;
    let titleField = (
      <PageTitle titleText={name} classes={{ root: classes.title }} />
    );

    let addWidgetButton = null;
    let helperTextError = false;
    if (helperText) {
      helperTextError = true;
    }
    const fullscreenButton = (
      <IconButton
        onClick={this.handleFullscreen}
        className={classes.actionButton}
        color="inherit"
        disabled={widgets.length <= 0}
        aria-label="Fullscreen"
      >
        <Fullscreen />
      </IconButton>
    );
    if (permission === READ_WRITE && !isFullscreen) {
      titleField = (
        <InputBase
          className={(classes.formHelperText, classes.titleField)}
          title="title"
          value={name}
          onChange={this.handleTitleChange}
          onBlur={this.handleTitleOffFocus}
          helperText={helperText}
          FormHelperTextProps={{ error: helperTextError }}
          InputProps={{
            className: classes.titleField,
          }}
        />
      );

      addWidgetButton = (
        <AnchoredHiddenMenu
          variant="custom"
          customAnchor={
            <TextButton
              translationKey="dashboards.addWidget"
              startIcon={<Add />}
              color="inherit"
              className={classes.addWidgetButton}
            />
          }
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList>
            <MenuItem
              id={AUDIO_PLAYER_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Audio Widget
            </MenuItem>
            <MenuItem
              id={CHART_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Chart Widget
            </MenuItem>
            <MenuItem
              id={DATA_PLAYER_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Data Player Widget
            </MenuItem>
            <MenuItem
              id={DATA_PREVIEW_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Data Preview Widget
            </MenuItem>
            {Environment.isEnvironmentProd() ? null : (
              <MenuItem
                id={DATA_SOURCE_SELECTION_WIDGET_TYPE_ID.toString()}
                onClick={this.handleAddWidget}
              >
                Data Source Selection Widget
              </MenuItem>
            )}
            <MenuItem
              id={IMAGE_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Image Widget
            </MenuItem>
            <MenuItem
              id={LATEST_READINGS_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Latest Readings Widget
            </MenuItem>
            <MenuItem
              id={TEXT_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Text Widget
            </MenuItem>
            <MenuItem
              id={VIDEO_WIDGET_TYPE_ID.toString()}
              onClick={this.handleAddWidget}
            >
              Video Widget
            </MenuItem>
          </MenuList>
        </AnchoredHiddenMenu>
      );
    }
    if (widgets.length > 0 && error === undefined) {
      layoutComponent = (
        <DashboardLayout
          classes={{ layoutContainer: classes.oceansNextContainer }}
          onLayoutChange={this.handleUpdateLayout}
          layoutId={dashboardId}
          layoutName={name}
          layouts={layout}
          isStatic={permission === READ_ONLY}
          isPublic={isPublic}
          useInitialLayouts={useInitialLayouts}
        >
          {widgets}
        </DashboardLayout>
      );
    }
    const embedDashboardButton = () => {
      const { isLoggedIn } = this.state;
      if (isLoggedIn === undefined) {
        this.checkAuthenticated();
      }
      if (isLoggedIn) {
        return (
          <EmbedUrlIconButton
            onClick={this.handleOpenEmbedDialog}
            color="inherit"
            className={classes.actionButton}
          />
        );
      }
      return null;
    };
    const embedDashboardDialog = () => {
      if (showEmbedDialog) {
        const dashboardEmbedUrl = `${Environment.getDmasUrl()}/Dashboards/embed/id/${dashboardId}`;
        const code = `<iframe src="${dashboardEmbedUrl}" style="overflow:hidden;height:100%;width:100%" name="${name} iFrame" allowfullscreen />`;
        return (
          <EmbedDialog
            open={showEmbedDialog}
            onCancel={this.handleCancelEmbedDialog}
            embedCode={code}
            fieldLabel="common.textfields.variable"
            fieldOptions={{ variableName: name }}
            dialogTitle="Embed Dashboard"
          />
        );
      }
      return null;
    };
    const layoutDiv = isFullscreen ? classes.fullscreenDiv : classes.layoutDiv;
    return (
      <div className={classes.oceansNextContainer}>
        {this.renderTitleBar(
          titleField,
          addWidgetButton,
          fullscreenButton,
          embedDashboardButton()
        )}
        {embedDashboardDialog()}
        <div id="dashboardLayout" className={layoutDiv}>
          {layoutComponent}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(DashboardEdit);
