/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';

import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, createStyles } from '@mui/styles';

import { ClassNameMap } from '@mui/styles/withStyles';
import _ from 'lodash';
import { TextButton } from '@onc/composite-components';
import { Add, ExpandMore } from '@onc/icons';
import {
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  NestedItem,
  Typography,
} from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { Attribute } from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import NestedMenuAutocomplete from 'library/CompositeComponents/select/nested-menu-autocomplete/NestedMenuAutocomplete';
import AttributeValueField from '../sea-tube/manual-entry/AttributeValueField';
import { ManualEntryErrors } from '../sea-tube/manual-entry/ManualEntryForm';

type Props = {
  value: EntryAttributesSection;
  attributeOptions: any[];
  classes: ClassNameMap;
  onChange: any;
  errors: ManualEntryErrors;
};

export interface AttributeOption {
  attributeId: number;
  groupName: string;
  selectable?: true;
  dataType: string;
  groupId: number;
  name: string;
  attributeValues?: { label: string; value: number }[];
  canBeUpdated?: boolean;
}

export interface AttributeLine {
  attr?: Attribute;
  val: any;
  touched: boolean;
}

export interface EntryAttributesSection {
  attributes: AttributeLine[];
}

const STYLES = (theme: Theme) =>
  createStyles({
    flexContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
    },
    summaryContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    chipContainer: {
      marginLeft: 'auto',
      marginRight: theme.spacing(),
      display: 'inline-flex',
      flexWrap: 'wrap',
      gap: theme.spacing(),
    },
    attributeValue: {
      marginRight: theme.spacing(),
      flex: '3 1 0',
    },
    attributeAutocomplete: {
      marginRight: theme.spacing(),
      flex: '4 1 0',
    },
    chip: {
      margin: 0,
    },
    fullWidth: {
      textAlign: 'center',
      width: '100%',
    },
    removeIcon: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  });

const ManualEntryAttributes: React.FC<Props> = ({
  classes,
  value,
  attributeOptions,
  onChange,
  errors,
}: Props) => {
  const { attributes } = value;
  const [attributeLines, setAttributeLines] = useState<AttributeLine[]>(
    attributes || []
  );

  const { attributeErrors } = errors;
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => setExpanded(!expanded);

  useEffect(() => {
    if (!_.isEqual(attributes, attributeLines)) {
      setAttributeLines(attributes);
    }
    if (attributes && attributes.length) {
      setExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  useEffect(() => {
    if (attributeLines) {
      onChange({ attributes: attributeLines });
    }
  }, [onChange, attributeLines]);

  const formatOptions = () => {
    const attributesByGroup: NestedItem[] = [];
    if (!attributeOptions) {
      return attributesByGroup;
    }
    attributeOptions.forEach((attr) => {
      const existingGroup = attributesByGroup.find(
        (group) => group.value === attr.groupId
      );
      if (existingGroup && existingGroup.children) {
        existingGroup.children.push(
          new NestedItem({
            label: attr.name,
            value: attr,
            key: attr.attributeId.toString(),
          })
        );
      } else {
        attributesByGroup.push(
          new NestedItem({
            label: attr.groupName,
            value: attr.groupId,
            key: attr.groupName,
            children: [
              {
                label: attr.name,
                value: attr,
                key: attr.attributeId.toString(),
              },
            ],
          })
        );
      }
    });
    return attributesByGroup;
  };

  const handleAttributeChange = (index: number, val: any) => {
    const updatedAttributeList = [...attributeLines];
    const updatedAttribute = updatedAttributeList[index];
    const attribute = val.value;
    if (updatedAttribute) {
      updatedAttribute.touched = true;
      updatedAttribute.attr = attribute;
      updatedAttribute.val = '';
      if (attribute && attribute.attributeValues) {
        updatedAttribute.val = attribute.attributeValues[0];
      }
      if (attribute && attribute.dataType === 'Boolean') {
        updatedAttribute.val = 'True';
      }
    }
    setAttributeLines(updatedAttributeList);
  };

  const handleValueChange = (index: number, val: any) => {
    const updatedAttributeList = [...attributeLines];
    const updatedAttribute = updatedAttributeList[index];
    if (updatedAttribute) {
      updatedAttribute.val = val;
      updatedAttribute.touched = true;
    }
    setAttributeLines(updatedAttributeList);
  };

  const handleDeleteLine = (e: any, index: number) => {
    e.stopPropagation();
    const updatedAttributeList = [...attributeLines];
    updatedAttributeList.splice(index, 1);
    setAttributeLines(updatedAttributeList);
  };

  const handleAddLine = (e: any) => {
    e.stopPropagation();
    const updatedAttributeList = _.cloneDeep(attributeLines);
    updatedAttributeList.push({ attr: undefined, val: '', touched: false });
    setAttributeLines(updatedAttributeList);
  };

  const renderAttributeChips = () =>
    attributeLines.map((line) => {
      const { val } = line;
      let displayValue = val;
      if (typeof val === 'object') {
        displayValue = line.val.label;
      }
      if (line && line.attr) {
        return (
          <Chip
            className={classes.chip}
            variant="outlined"
            label={
              <>
                <b>{line.attr.name}</b>
                {`: ${displayValue}`}
              </>
            }
          />
        );
      }
      return <></>;
    });

  const getOptions = (attributeId: number) => {
    if (!attributeOptions) {
      return [];
    }
    const found = attributeOptions.find(
      (attr) => attr.attributeId === attributeId
    );
    if (found) {
      return found.attributeValues || [];
    }
    return [];
  };

  const renderAttributeLine = (index: number, { attr, val }: AttributeLine) => (
    <div className={classes.flexContainer} key={index}>
      <div className={classes.attributeAutocomplete}>
        <NestedMenuAutocomplete
          id={`attribute-select-${index}`}
          label={attr ? `Attribute / ${attr.groupName}` : 'Attribute'}
          onChange={(newAttr) => handleAttributeChange(index, newAttr)}
          menuItems={formatOptions()}
          value={
            attr
              ? {
                  value: attr.attributeId,
                  label: attr.name,
                  key: attr.attributeId.toString(),
                }
              : undefined
          }
        />
      </div>
      <div className={classes.attributeValue} data-test="attribute-value-field">
        <AttributeValueField
          id={`attribute-value-${index}`}
          value={val}
          disabled={!attr}
          error={attr && attributeErrors?.[attr.attributeId]}
          helperText={attr && attributeErrors?.[attr.attributeId]}
          dataType={attr && attr.dataType ? attr.dataType : 'String'}
          onChange={(newVal) => handleValueChange(index, newVal)}
          options={attr ? getOptions(attr.attributeId) : []}
        />
      </div>
      <div className={classes.removeIcon}>
        <DeleteIconButton onClick={(e) => handleDeleteLine(e, index)} />
      </div>
    </div>
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      role="region"
      aria-label="Attributes"
    >
      <AccordionSummary
        data-test="attributes-accordion"
        expandIcon={<ExpandMore data-test="expand" name="expand" />}
      >
        <div className={classes.summaryContainer}>
          <Typography style={{ marginRight: '16px' }}>Attributes</Typography>
          <div className={classes.chipContainer}>
            {expanded ? <></> : renderAttributeChips()}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ flexWrap: 'wrap' }}>
        {attributeLines.map((attr, index) => renderAttributeLine(index, attr))}
        <span className={classes.fullWidth}>
          <TextButton
            translationKey="common.buttons.addAttribute"
            startIcon={<Add />}
            onClick={handleAddLine}
          />
        </span>
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(STYLES)(ManualEntryAttributes);
