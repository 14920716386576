import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ContainedButton } from '@onc/composite-components';
import { Divider, Grid, Typography } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import DeviceIpService from 'domain/services/DeviceIpService';

import Panel from 'library/CompositeComponents/panel/Panel';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateUtils from 'util/DateUtils';
import Environment from 'util/Environment';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(1),
  },
  panelDiv: {
    margin: theme.spacing(1),
  },
});

const defaultState = {
  dateFrom: undefined,
  deviceName: undefined,
  dnsName: undefined,
  ipPort: undefined,
  ipType: undefined,
  ipAddress: undefined,
  macAddress: undefined,
  dateTo: undefined,
  modifyByName: undefined,
  modifyDate: undefined,
};

class IpMaintenanceRO extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
      panelDiv: PropTypes.string,
      roDivider: PropTypes.string,
    }).isRequired,
    ipId: PropTypes.number,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ipId: undefined,
  };

  redirectPage = () => {
    let redirectURL;
    window.open(`${Environment.getDmasUrl()}${redirectURL}`, '_self');
  };

  constructor(props) {
    super(props);
    const { ipId } = this.props;
    this.state = {
      ...defaultState,
      ipId,
    };
  }

  componentDidMount() {
    const { ipId } = this.state;

    if (ipId) {
      this.getDeviceIpService();
    }
  }

  getDeviceIpService = () => {
    const { onError } = this.props;
    const { ipId } = this.state;

    if (!ipId || isNaN(ipId)) return;

    DeviceIpService.getOne(ipId)
      .then((payload) => {
        const { ip, dmasUser, ipType, device } = payload;
        const { dateFrom, dateTo, ...rest } = ip;
        this.setState({
          dateFrom: DateUtils.formatDateAsString(new Date(dateFrom)),
          dateTo:
            dateTo !== undefined && dateTo !== ''
              ? DateUtils.formatDateAsString(new Date(dateTo))
              : undefined,
          modifyByName: `${dmasUser.firstname} ${dmasUser.lastname}`,
          ipType: ipType.ipTypeName,
          deviceName: device.deviceName,
          ...rest,
        });
      })
      .catch((e) => {
        onError(e.message);
        this.setState(defaultState);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      ipId,
      deviceName,
      dnsName,
      ipPort,
      ipType,
      ipAddress,
      macAddress,
      dateFrom,
      dateTo,
      modifyByName,
      modifyDate,
    } = this.state;

    let ipText = ipId;
    let color;
    if (isNaN(ipId)) {
      color = 'textSecondary';
      ipText = 'New';
    }
    return (
      // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
      <div className={classes.panelDiv}>
        <Panel
          title={
            <>
              <Typography variant="body1">IP ID:</Typography>
              <Typography variant="body1" color={color}>
                {ipText}
              </Typography>
            </>
          }
        >
          <Grid
            container
            direction="row"
            className={classes.entryFormContainer}
          >
            <Grid item xs={6}>
              <MarginedReadOnlyField
                labelText="DNS Name"
                valueText={dnsName}
                title="dnsNameField "
              />
              <MarginedReadOnlyField
                labelText="Ip Port"
                valueText={ipPort}
                title="ipPortField"
              />
              <MarginedReadOnlyField
                labelText="Ip Type"
                valueText={ipType}
                title="ipTypeField"
              />
              <MarginedReadOnlyField
                labelText="Ip Address"
                valueText={ipAddress}
                title="ipAddressField"
              />
              <MarginedReadOnlyField
                labelText="MAC Address"
                valueText={macAddress}
                title="macAddressField"
              />
              <MarginedReadOnlyField
                labelText="Start Time (UTC)"
                valueText={dateFrom}
                title="dateFromField"
              />
              <MarginedReadOnlyField
                labelText="End Time (UTC)"
                valueText={dateTo}
                title="dateToField"
              />
              <MarginedReadOnlyField
                labelText="Instrument Name"
                valueText={deviceName}
                title="deviceNameField"
              />
              <MarginedReadOnlyField
                labelText="Modify By"
                valueText={modifyByName}
                title="modifyByNameField"
              />
              <MarginedReadOnlyField
                labelText="Modify Date"
                valueText={DateUtils.formatDateAsString(new Date(modifyDate))}
                title="modifyDateField"
              />
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <ContainedButton
              translationKey="common.buttons.done"
              onClick={this.redirectPage}
            />
          </div>
          <Divider variant="middle" className={classes.roDivider} />
        </Panel>
      </div>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(IpMaintenanceRO));
