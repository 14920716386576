import { DataSourceType } from 'domain/AppComponents/charts/types/TimeSeriesScalarDataChart.types';
import Sensor from 'domain/AppComponents/Dashboard/chart-widget/types/Sensor';
import {
  DeviceCategory,
  Device as DeviceValue,
} from 'domain/AppComponents/Dashboard/data-source-widget/DataSourceSelectionWidgetFilters';
import { DeploymentResponse } from 'domain/hooks/useFilteredDeployments';

type GenericDataSource = {
  dataSourceType?: DataSourceType;
  name: string;
  nodeId: string;
  deviceId: number;
  deviceCategoryCode: string;
  deviceCategoryName: string;
  sensorTypeCode: string;
  sensorCodeName: string;
  traceName?: string;
  sensorName?: string;
  traceColour?: string;
};

export type DeploymentDataSource = GenericDataSource & {
  device?: DeviceValue;
  sensor?: Sensor;
  deviceCategory?: DeviceCategory;
  deployment?: DeploymentResponse;
  dateFrom?: string;
  dateTo?: string;
  deviceCode: string;
  sensorCategoryCode: string;
  dataSourceType: DataSourceType.Deployment;
};

export type LocationDataSource = GenericDataSource & {
  id;
  pathName: string[];
  dataSourceType: DataSourceType.Location;
  stationCode: string;
  datasetRef: string;
  siteDeviceList: Array<{ deviceId: number; siteDeviceId: number }>;
};

export type DataSource = DeploymentDataSource | LocationDataSource;

export const isDeploymentDataSource = (
  source: DataSource
): source is DeploymentDataSource =>
  source.dataSourceType === DataSourceType.Deployment;

export const isLocationDataSource = (
  source: DataSource
): source is LocationDataSource =>
  !source.dataSourceType || source.dataSourceType === DataSourceType.Location;
