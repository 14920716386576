/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';

import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import {
  ContainedButton,
  FloatRightButtonLowStyles,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  DeleteIconButton,
  EditIconButton,
  RefreshButton,
} from 'domain/AppComponents/IconButtons';
import TaxonResourceConfig from 'domain/Apps/taxon-resource/TaxonResourceConfig';
import TaxonResourceService from 'domain/services/TaxonResourceService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)})`,
      minWidth: '400px',
    },
    actionContent: {
      ...FloatRightButtonLowStyles,
      position: 'absolute',
      zIndex: 4,
    },
  })
);

type TaxonResourceTableProps = {
  resourceTypeId?: number;
  resourceTypeName?: string;
  resourceId?: number;
  onInfo: (message: string) => void;
  onError: (error: Error | string) => void;
};

interface TaxonResource {
  taxonResourceId: number;
  taxonomy: {
    taxonomyId: number;
    taxonomyName: string;
  };
  taxon: {
    taxonId: number;
    commonName: string;
  };
  resourceType: {
    resourceTypeId: number;
    resourceTypeName: string;
  };
  resource: {
    resourceId: number;
    resourceName: string;
  };
  modifyDate: Date;
  modifyBy: { firstName: string; lastName: string };
}

interface TaxonResourceRow {
  taxonResourceId: number;
  taxonomy: {
    taxonomyId: number;
    taxonomyName: string;
  };
  taxon: {
    taxonId: number;
    commonName: string;
  };
  resourceType: {
    resourceTypeId: number;
    resourceTypeName: string;
  };
  resource: {
    resourceId: number;
    resourceName: string;
  };
  taxonomyName: string;
  taxonName: string;
  resourceTypeName: string;
  resourceName: string;
  modifyDate: string;
  modifyBy: string;
  icons?: React.ReactElement | null;
}

interface TaxonResourcePromise {
  data: TaxonResource[];
}

const tableColumnExtensions = [
  {
    columnName: 'taxonResourceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'taxonomyName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'taxonName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'resourceTypeName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'resourceName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'icons',
    width: ColumnInfo.small,
    align: 'left',
    sortingEnabled: false,
  },
];

const TaxonResourceTable: React.VFC<TaxonResourceTableProps> = (
  // eslint-disable-next-line react/require-default-props
  props: TaxonResourceTableProps
): React.ReactElement => {
  const {
    resourceTypeId: resourceTypeIdProp,
    resourceTypeName: resourceTypeNameProp,
    resourceId: resourceIdProp,
    onInfo,
    onError,
  } = props;

  let variant;
  if (resourceTypeIdProp && resourceIdProp) {
    variant = 'staticResourceId';
  } else if (resourceTypeIdProp) {
    variant = 'staticResourceTypeId';
  }

  const classes = useStyles();
  const permission = Environment.getDmasUserPrivilege();

  const [tableRows, setTableRows] = useState<TaxonResourceRow[] | null>([]);
  const [rowToBeEdited, setRowToBeEdited] = useState<
    TaxonResourceRow | undefined
  >(undefined);
  const [rowToBeDeleted, setRowToBeDeleted] = useState<
    TaxonResourceRow | undefined
  >(undefined);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const icons = (row: TaxonResourceRow): React.ReactElement | null => {
    if (permission !== 'RW') {
      return null;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            setOpenCreateDialog(true);
            setOpenDeleteDialog(false);
            setRowToBeEdited(row);
            setRowToBeDeleted(undefined);
          }}
        />
        <DeleteIconButton
          onClick={() => {
            setOpenCreateDialog(false);
            setOpenDeleteDialog(true);
            setRowToBeEdited(undefined);
            setRowToBeDeleted(row);
          }}
        />
      </>
    );
  };

  const buildTableRows = (
    txrList: TaxonResource[]
  ): TaxonResourceRow[] | null => {
    const rows = txrList.map((txr: TaxonResource): TaxonResourceRow => {
      const { taxonomy, taxon, resourceType, resource, modifyDate, modifyBy } =
        txr;

      const txrRow: TaxonResourceRow | null = {
        ...txr,
        taxonomyName:
          taxonomy && taxonomy.taxonomyName ? taxonomy.taxonomyName : '',
        taxonName: taxon && taxon.commonName ? taxon.commonName : '',
        resourceTypeName:
          resourceType && resourceType.resourceTypeName
            ? resourceType.resourceTypeName
            : '',
        resourceName:
          resource && resource.resourceName ? resource.resourceName : '',
        modifyDate: DateFormatUtils.formatDate(modifyDate, 'full') || '',
        modifyBy: `${modifyBy.firstName} ${modifyBy.lastName}`,
      };
      txrRow.icons = icons(txrRow);
      return txrRow;
    });
    return rows;
  };

  const refreshTableData = async () => {
    let promise;
    if (variant === 'staticResourceTypeId') {
      promise = TaxonResourceService.getAll({
        resourceTypeId: resourceTypeIdProp,
      });
    } else if (variant === 'staticResourceId') {
      promise = TaxonResourceService.getAll({
        resourceTypeId: resourceTypeIdProp,
        resourceId: resourceIdProp,
      });
    } else {
      promise = TaxonResourceService.getAll({});
    }
    await promise
      .then((result) => {
        setTableRows(buildTableRows(result));
      })
      .catch((error) => {
        onError(error);
      });
  };

  useEffect(
    () => {
      refreshTableData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceIdProp, resourceTypeIdProp]
  );

  const refresh = async () => {
    await refreshTableData();
    onInfo('Table Refreshed');
  };

  const selectHeaders = () => {
    let headers = [
      { title: 'ID', name: 'taxonResourceId' },
      { title: 'Taxonomy', name: 'taxonomyName' },
      { title: 'Taxon', name: 'taxonName' },
    ];

    const resourceTypeHeader = {
      title: 'Resource Type',
      name: 'resourceTypeName',
    };
    const resourceHeader = { title: 'Resource', name: 'resourceName' };

    if (!variant) {
      headers = headers.concat([resourceTypeHeader, resourceHeader]);
    } else if (variant === 'staticResourceTypeId') {
      headers = headers.concat([resourceHeader]);
    }

    headers = headers.concat([
      { title: 'Modified By', name: 'modifyBy' },
      { title: 'Modified Date', name: 'modifyDate' },
      { title: ' ', name: 'icons' },
    ]);

    return headers;
  };

  const titleContent = (): React.ReactElement => {
    const mainTitle = 'Taxon Resource List';

    let titleText;
    if (resourceTypeNameProp && resourceIdProp) {
      titleText = `${mainTitle} (${resourceTypeNameProp} Id: ${resourceIdProp})`;
    } else if (resourceTypeNameProp) {
      titleText = `${resourceTypeNameProp} ${mainTitle}`;
    } else {
      titleText = mainTitle;
    }
    return <Typography variant="h6">{titleText}</Typography>;
  };

  const actionContent = (): React.ReactElement => {
    const RefreshTaxonResourcesButton = () => (
      <RefreshButton onClick={refresh} aria-label="refresh" />
    );
    const AddTaxonResourceButton = () => (
      <ContainedButton
        translationKey="taxonomy.addTaxonResource"
        startIcon={<Add />}
        className={classes.actionContent}
        onClick={() => {
          setOpenCreateDialog(true);
          setOpenDeleteDialog(false);
          setRowToBeEdited(undefined);
          setRowToBeDeleted(undefined);
        }}
        aria-label="add taxon resource"
      />
    );
    if (permission !== 'RW') {
      return <RefreshTaxonResourcesButton />;
    }
    return (
      <>
        <RefreshTaxonResourcesButton />
        <AddTaxonResourceButton />
      </>
    );
  };

  const handleCancel = () => {
    setOpenCreateDialog(false);
    setOpenDeleteDialog(false);
    setRowToBeEdited(undefined);
    setRowToBeDeleted(undefined);
  };

  const handleDelete = async () => {
    if (!rowToBeDeleted) return;
    await TaxonResourceService.delete(rowToBeDeleted.taxonResourceId)
      .then(() => {
        onInfo('Deleted Successfully');
        handleCancel();
        refresh();
      })
      .catch((e) => {
        onError(e.message);
        handleCancel();
      });
  };

  const handleSavePost = (promise: Promise<TaxonResourcePromise>) => {
    promise
      .then(async () => {
        onInfo('Saved Successfully');
        handleCancel();
        refresh();
      })
      .catch((e) => {
        onError(e.message);
      });
  };

  const handleSave = async (taxonResource) => {
    const { taxonResourceId, taxonomyId, taxonId, resourceTypeId, resource } =
      taxonResource;

    if (!taxonId || !resource) {
      const message =
        (!taxonId ? 'Taxon value required!' : '') +
        (!taxonId && !resource ? ' / ' : '') +
        (!resource ? 'Resource value required!' : '');
      onError(message);
      return;
    }

    if (taxonResourceId) {
      // update
      await handleSavePost(
        TaxonResourceService.update(
          taxonResourceId,
          taxonomyId,
          taxonId,
          resourceTypeId,
          resource.id
        )
      );
    } else {
      // create
      await handleSavePost(
        TaxonResourceService.create(
          taxonomyId,
          taxonId,
          resourceTypeId,
          resource.id
        )
      );
    }
  };

  const addEventDialogue = () => {
    if (!openCreateDialog) {
      return null;
    }

    let resourceType;
    if (variant && resourceTypeIdProp) {
      resourceType = {
        resourceTypeId: resourceTypeIdProp,
        resourceTypeName: '',
      };
    } else if (rowToBeEdited) {
      ({ resourceType } = rowToBeEdited);
    }

    let resource;
    if (variant === 'staticResourceId' && resourceIdProp) {
      resource = { id: resourceIdProp, name: '' };
    } else if (rowToBeEdited && rowToBeEdited.resource) {
      resource = {
        id: rowToBeEdited.resource.resourceId,
        name: rowToBeEdited.resource.resourceName,
      };
    }

    return (
      <TaxonResourceConfig
        title={
          rowToBeEdited
            ? `Taxon Resource ${rowToBeEdited.taxonResourceId}`
            : 'Add New Taxon Resource'
        }
        variant={variant}
        taxonResourceId={
          rowToBeEdited ? rowToBeEdited.taxonResourceId : undefined
        }
        taxonomy={rowToBeEdited ? rowToBeEdited.taxonomy : undefined}
        taxon={rowToBeEdited ? rowToBeEdited.taxon : undefined}
        resourceType={resourceType}
        resource={resource}
        onSave={handleSave}
        onCancel={handleCancel}
        onError={onError}
      />
    );
  };

  const deleteEventDialogue = () => {
    if (!openDeleteDialog || !rowToBeDeleted) {
      return null;
    }

    return (
      <ConfirmationDialog
        open
        title={`Delete Taxon Resource ${rowToBeDeleted.taxonResourceId}`}
        content={`Are you sure you want to delete taxon resource ${rowToBeDeleted.taxonResourceId}?`}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    );
  };

  return (
    <>
      {addEventDialogue()}
      {deleteEventDialogue()}
      <Panel
        title={titleContent()}
        actionContent={actionContent()}
        className={classes.root}
      >
        <SortableTable
          rows={tableRows}
          columns={selectHeaders()}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          disabledSort={tableColumnExtensions}
          pageSize={15}
          searchable
          searchBarMoveable
          stripedRows
        />
      </Panel>
    </>
  );
};

export default withSnackbars(TaxonResourceTable);
