import { get } from 'util/WebRequest';

const OPERATION_GETFILES = 1;
const OPERATION_GETLISTDEVICES = 2;
const OPERATION_GETLATESTFILEFORDEVICEBYDPFLIST = 5;
const OPERATION_GETDATAFILES_FORQUERY_BYDATEINCLUSIVE = 8;
const VIDEO_DATAPRODUCT_FORMAT_IDS = '15,20,26,50,51,69,153,217';
const AUDIO_DATAPRODUCT_FORMAT_IDS = '46';
const DATAFILESERVICE = 'DataFileService';

class DataFileService {
  static getVideoFileList(deviceId, startDate, endDate) {
    const params = {
      operationtype: OPERATION_GETDATAFILES_FORQUERY_BYDATEINCLUSIVE,
      dataProductFormatId: VIDEO_DATAPRODUCT_FORMAT_IDS,
      deviceid: deviceId,
      startdate: startDate,
      enddate: endDate,
    };
    return get(DATAFILESERVICE, params);
  }

  static getLatestVideoFile(deviceId) {
    const params = {
      operationtype: OPERATION_GETLATESTFILEFORDEVICEBYDPFLIST,
      dataproductformatid: VIDEO_DATAPRODUCT_FORMAT_IDS,
      deviceid: deviceId,
    };
    return get(DATAFILESERVICE, params);
  }

  static getAudioFileList(deviceId, startDate, endDate) {
    const params = {
      operationtype: OPERATION_GETDATAFILES_FORQUERY_BYDATEINCLUSIVE,
      dataProductFormatId: AUDIO_DATAPRODUCT_FORMAT_IDS,
      deviceid: deviceId,
      startdate: startDate,
      enddate: endDate,
    };
    return get(DATAFILESERVICE, params);
  }

  static getLatestAudioFile(deviceId) {
    const params = {
      operationtype: OPERATION_GETLATESTFILEFORDEVICEBYDPFLIST,
      dataproductformatid: AUDIO_DATAPRODUCT_FORMAT_IDS,
      deviceid: deviceId,
    };
    return get(DATAFILESERVICE, params);
  }

  static getListDevices() {
    const params = {
      operationtype: OPERATION_GETLISTDEVICES,
    };
    return get(DATAFILESERVICE, params);
  }

  static getFilesForDevice(
    deviceid,
    startdate,
    enddate,
    filename,
    startIndex,
    numberofrecords,
    fileState = ''
  ) {
    const params = {
      operationtype: OPERATION_GETFILES,
      deviceid,
      startIndex,
      numberofrecords,
      filename,
      startdate,
      enddate,
    };

    if (fileState && fileState.trim() !== '') {
      params.fileState = fileState;
    }

    return get(DATAFILESERVICE, params);
  }
}

export default DataFileService;
