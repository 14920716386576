import { useContext, useState } from 'react';
import { Info } from '@onc/icons';
import { IconButton } from 'base-components';
import { GenericInfoDialog } from 'domain/AppComponents/dialogs/Dialogs';
import EarthquakeFilterWidget from 'domain/AppComponents/earthquake-catalog/EarthquakeFilterWidget';
import EarthquakeMapWidget from 'domain/AppComponents/earthquake-catalog/EarthquakeMapWidget';
import EarthquakeSummaryWidget from 'domain/AppComponents/earthquake-catalog/EarthquakeSummaryWidget';
import EarthquakeTableWidget from 'domain/AppComponents/earthquake-catalog/EarthquakeTableWidget';
import SeaTubeDashboard from 'domain/AppComponents/sea-tube/SeaTubeDashboard';
import UserDetailsContext from 'domain/AppComponents/sea-tube/UserDetailsContext';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';

import { defaultLayout, defaultWidgets } from './layouts';

const EarthquakeCatalogDashboard: React.VFC = () => {
  const { userId } = useContext(UserDetailsContext);

  const isLoggedIn = userId !== undefined && userId !== -1;
  const userType = isLoggedIn ? 'User' : 'Anonymous';

  const widgetLibrary = new WidgetLibrary([
    {
      Component: EarthquakeTableWidget,
      label: 'Earthquake Table',
      multiple: false,
    },
    {
      Component: EarthquakeMapWidget,
      label: 'Earthquake Map',
      multiple: false,
    },
    {
      Component: EarthquakeFilterWidget,
      label: 'Earthquake Filter',
      multiple: false,
    },
    {
      Component: EarthquakeSummaryWidget,
      label: 'Earthquake Summary',
      multiple: false,
    },
  ]);

  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>();

  return (
    <SeaTubeDashboard
      title="Earthquake Catalog"
      id={crypto.getRandomValues(new Uint32Array(1)).toString()}
      layoutKey={`${userType}-Earthquake-Catalog`}
      actionComponents={[
        <IconButton
          Icon={Info}
          onClick={() => setOpenInfoDialog(true)}
          aria-label="Page Information"
        />,
        <GenericInfoDialog
          open={openInfoDialog}
          onClose={() => setOpenInfoDialog(false)}
          title="Page Information"
          message="This page displays earthquakes detected by ONC, NRCan, and USGS in the detection area. (See the red bounding box on the Earthquake Map, normally between 46.0 and 52.2 degrees north and 122 to 131.75 degrees west, but may change operationally). The purpose of the page is to compare ONC earthquake detections with detections from other organizations and to view intermediate results from the ONC earthquake algorithms. Clicking on an ID entry in the earthquake table will take you to a new page with more detailed information about the selected earthquake."
        />,
      ]}
      widgetLibrary={widgetLibrary}
      defaultLayout={defaultLayout}
      defaultWidgets={defaultWidgets}
    />
  );
};

export default EarthquakeCatalogDashboard;
