import * as React from 'react';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { Typography } from 'base-components';

interface Props {
  rows: any[];
}

const pluginDependencies = [{ name: 'Toolbar' }];

const ToolbarFilter: React.VFC<Props> = ({ rows }: Props) => (
  <Plugin dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <Typography
        variant="caption"
        style={{ whiteSpace: 'noWrap', marginRight: '8px' }}
      >
        Returned {rows.length} results.
      </Typography>
    </Template>
  </Plugin>
);

export default ToolbarFilter;
