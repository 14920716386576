import Environment from '@onc/environment';
import axios, {
  type AxiosResponseHeaders,
  type CreateAxiosDefaults,
  type InternalAxiosRequestConfig,
} from 'axios';
import json5 from 'json5';
import moment from 'moment';
import qs from 'qs';

/**
 * The `withCredentials` prop is only used on cross-origin requests to a
 * non-Dmas URL.
 *
 * @param {InternalAxiosRequestConfig} config
 * @returns {InternalAxiosRequestConfig} The modified axios request
 *   configuration object with the `withCredentials` property set if request is
 *   not to a Dmas URL.
 */
const setWithCredentials = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const withCredentials =
    !config.url.startsWith('http') ||
    config.url.startsWith(Environment.getDmasUrl());
  return {
    ...config,
    withCredentials,
  };
};

/**
 * Adds authentication tokens to the request parameters if the request is
 * directed at the DMAS API. This interceptor ensures that every API request
 * includes necessary authentication tokens, simplifying the inclusion of
 * security credentials on each call.
 *
 * @param {InternalAxiosRequestConfig} config
 * @returns {InternalAxiosRequestConfig} The modified axios request
 *   configuration object with the `token` and `appToken` properties set if the
 *   request is to an /api/ endpoint.
 */
const setTokens = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { params } = config;
  if (!params) return config;
  if (
    config.url.startsWith('api/') ||
    config.url.startsWith(`${Environment.getDmasUrl()}/api/`)
  ) {
    params.token = Environment.getDmasUserToken();
    params.appToken = Environment.getCurrentApplicationToken();
  }
  return config;
};

/**
 * Encodes the query parameters for a request to the Dmas API by flattening any
 * nested objects into stringified JSON. This is necessary for parsing the form
 * parameters in services extending `DmasAPIService.java`.
 *
 * @param params
 * @returns
 */
const encodeParams = (params: Record<string, unknown>): string => {
  if (!params) {
    return undefined;
  }

  const clone = {};
  for (const prop in params) {
    if (params[prop] instanceof Object && params[prop] instanceof moment) {
      clone[prop] = moment(params[prop]).toDate().toISOString();
    } else if (
      params[prop] instanceof Object &&
      !(params[prop] instanceof Date)
    ) {
      clone[prop] = JSON.stringify(params[prop]);
    } else {
      clone[prop] = params[prop];
    }
  }
  return qs.stringify(clone);
};

type JSONTransformer = {
  (
    data: string,
    headers: AxiosResponseHeaders & { 'Content-Type': 'application/json' }
  ): Record<string, unknown>;
  (data: string, headers: AxiosResponseHeaders): unknown;
};
/**
 * JSON serialization from responses from services extending
 * `DmasAPIService.java` serializes non-numerical Double values (e.g. NaN, Inf)
 * as a number (i.e. without quotation marks, so `{ value: NaN }` rather than `{
 * value: "NaN" }`). The default Axios JSON parser {@function JSON.parse()} will
 * fail with an exception when parsing `NaN` values.
 *
 * @param data
 * @param headers
 * @returns
 */
const jsonTransformer: JSONTransformer = (data, headers) => {
  const normalizedHeaders = headers;
  if (normalizedHeaders['Content-Type']) {
    normalizedHeaders['content-type'] = headers['Content-Type'];
  }
  if (
    normalizedHeaders['content-type'] &&
    !normalizedHeaders['content-type']
      .toLowerCase()
      .includes('application/json')
  )
    return data;

  return json5.parse(data, (_, value) => {
    if (typeof value === 'number' && isNaN(value)) {
      return null;
    }
    return value;
  });
};

const commonConfig: CreateAxiosDefaults = {
  transformResponse: jsonTransformer,
  baseURL: Environment.getDmasUrl(),
};

const createDmasAxiosInstance = (config: CreateAxiosDefaults) => {
  const instance = axios.create(config);
  if (instance) {
    instance.interceptors.request.use(setWithCredentials, null, {
      synchronous: true,
    });
    instance.interceptors.request.use(setTokens, null, {
      synchronous: true,
    });
  }

  return instance;
};

export const createDmasRestAxiosInstance = () =>
  createDmasAxiosInstance(commonConfig);

// See https://axios-http.com/docs/instance and https://axios-http.com/docs/req_config.
/**
 * Used for requests to services extending `DmasAPIService.java`. This adds
 * headers to ensure any non-GET requests are sent as
 * `application/x-www-form-urlencoded` and GET requests are sent as
 * `application/json` so they can be parsed into `formData` in the backend.
 * Parameters also need to be encoded since the same backend parser is unable to
 * parse nested objects.
 *
 * @returns An axios instance configured for requests to services extending
 *   `DmasAPIService.java`.
 */
export const createDmasAPIAxiosInstance = () =>
  createDmasAxiosInstance({
    ...commonConfig,
    paramsSerializer: encodeParams,
    headers: {
      get: { 'Content-Type': 'application/json' },
      common: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  });
