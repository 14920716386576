/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import { TableFilterLine, TableFilterLogic } from 'base-components';
import { GetAnnotationsPayload } from 'domain/services/AnnotationService';
import { DiveJSON } from 'domain/services/DiveListingService';
import {
  SeaTubePermissionObj,
  SeaTubePermissionJSON,
  SeaTubeAnnotationPermissions,
} from 'domain/services/SeaTubePermissionsService';
import TableAnnotation, { Attribute } from './TableAnnotation';

class AnnotationTableLogic {
  static filterFn = (row: any, filterLine: TableFilterLine) => {
    const filteredAttribute = filterLine.data;
    const { operator, value } = filterLine;

    if (!filterLine.data) {
      return TableFilterLogic.defaultFilter(row, filterLine);
    }
    let match = false;
    row.attributes.forEach((attr: Attribute) => {
      if (attr.attributeId === filteredAttribute.attributeId) {
        switch (filterLine.dataType) {
          case 'String':
            TableFilterLogic.matchesStringFilter(attr.value, operator, value)
              ? (match = true)
              : undefined;
            break;

          case 'Number':
            TableFilterLogic.matchesNumberFilter(attr.value, operator, value)
              ? (match = true)
              : undefined;
            break;

          case 'Boolean':
            TableFilterLogic.matchesBooleanFilter(attr.value, operator, value)
              ? (match = true)
              : undefined;
            break;

          case 'Select':
            this.matchesSelectFilter(attr.value, operator, value)
              ? (match = true)
              : undefined;
            break;

          case 'Date':
            TableFilterLogic.matchesDateFilter(attr.value, operator, value)
              ? (match = true)
              : undefined;
            break;

          default:
            TableFilterLogic.matchesStringFilter(attr.value, operator, value)
              ? (match = true)
              : undefined;
        }
      }
    });
    return match;
  };

  static matchesSelectFilter = (
    attr: string,
    operator: string | null | undefined,
    value: string | string[] | undefined
  ) => {
    let match = false;
    if (operator === 'isOneOf' && value && Array.isArray(value)) {
      value.forEach((val) => {
        if (val === attr) {
          match = true;
        }
      });
    }
    if (operator === 'isNotOneOf' && value && Array.isArray(value)) {
      match = true;
      value.forEach((val) => {
        if (val === attr) {
          match = false;
        }
      });
    }
    if (operator === 'isNull') {
      match = TableFilterLogic.isNull(attr);
    }
    if (operator === 'isNotNull') {
      match = !TableFilterLogic.isNull(attr);
    }
    return match;
  };

  static getUniqueAttributes = (rows: TableAnnotation[]) =>
    rows
      .map((row: TableAnnotation) => row.attributes)
      .flat()
      .filter(
        (item1, index, self) =>
          index ===
          self.findIndex((item2) => item1.attributeId === item2.attributeId)
      );

  static getUniqueGroups = (attributes: Attribute[]) =>
    attributes
      .map((attr) => ({
        groupId: attr.groupId,
        groupName: attr.groupName,
      }))
      .flat()
      .filter(
        (item1, index, self) =>
          index === self.findIndex((item2) => item1.groupId === item2.groupId)
      );

  static getValuesForAttribute = (
    attributeId: number,
    rows: TableAnnotation[]
  ) => {
    const values: any[] = [];
    rows.forEach((row) => {
      row.attributes.forEach((attribute) => {
        if (attribute.attributeId === attributeId) {
          values.push(attribute.value);
        }
      });
    });
    return values.flat();
  };

  static formatDives = (payload: { dives: DiveJSON[] }) => payload.dives;

  static formatRows = (payload: GetAnnotationsPayload) =>
    payload.annotations.map((anno) => new TableAnnotation(anno));
}

export default AnnotationTableLogic;
