import { Component } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ContainedButton, TextButton } from '@onc/composite-components';
import {
  DateTimePicker,
  Tooltip,
  Dropdown,
  Grid,
  Typography,
  TextField,
} from 'base-components';
import FileManagementDeviceSelect from 'domain/Apps/file-management/FileManagementDeviceSelect.jsx';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  abcd: {
    margin: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
});

const fileStateOptions = [
  { label: 'All', value: '' },
  { label: 'Processed (T)', value: 'T' },
  { label: 'Ready to Process (P)', value: 'P' },
  { label: 'CF Cast (C)', value: 'C' },
  { label: 'Excluded (E)', value: 'E' },
];

const timeRangeArray = [
  { label: 'Last 10 Minutes', value: 0 },
  { label: 'Last 2 hours', value: 1 },
  { label: 'Last 24 Hours', value: 2 },
  { label: 'Last 7 Days', value: 3 },
  { label: 'All Available', value: 4 },
  { label: 'Custom', value: 5 },
];

class FileManagementParameters extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      spaceBetween: PropTypes.string,
      root: PropTypes.string,
      field: PropTypes.string,
      marginBottom: PropTypes.string,
    }).isRequired,
    timeRangeValue: PropTypes.number.isRequired,
    onDeviceDropDownChange: PropTypes.func,
    onStartDateSelectorChange: PropTypes.func,
    onEndDateSelectorChange: PropTypes.func,
    onTimeRangeChange: PropTypes.func,
    onFileSearchChange: PropTypes.func,
    fileSearchValue: PropTypes.string,
    onFileNameAndSelectDeviceReset: PropTypes.func,
    onSearchButtonClick: PropTypes.func,
    onEndDateTimeReset: PropTypes.func,
    onFileStateChange: PropTypes.func, // New Prop for File State Selection
    fileState: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    dateFrom: PropTypes.objectOf(PropTypes.any).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    dateTo: PropTypes.objectOf(PropTypes.any).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    device: PropTypes.objectOf(PropTypes.any).isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onDeviceDropDownChange: () => {},
    onStartDateSelectorChange: () => {},
    onEndDateSelectorChange: () => {},
    onFileSearchChange: () => {},
    onFileNameAndSelectDeviceReset: () => {},
    onSearchButtonClick: () => {},
    onEndDateTimeReset: () => {},
    onTimeRangeChange: () => {},
    onFileStateChange: () => {},
    fileSearchValue: '',
    fileState: '',
    disabled: false,
  };

  renderSearchButton = () => {
    const { onSearchButtonClick, disabled } = this.props;
    if (disabled) {
      return (
        <Tooltip title="A Device must be selected">
          <span>
            <ContainedButton translationKey="common.search" disabled />
          </span>
        </Tooltip>
      );
    }
    return (
      <ContainedButton
        translationKey="common.search"
        onClick={onSearchButtonClick}
      />
    );
  };

  render() {
    const {
      classes,
      onDeviceDropDownChange,
      onFileNameAndSelectDeviceReset,
      onFileSearchChange,
      onFileStateChange,
      fileSearchValue,
      fileState,
      onEndDateTimeReset,
      dateTo,
      dateFrom,
      timeRangeValue,
      onTimeRangeChange,
      onStartDateSelectorChange,
      onEndDateSelectorChange,
      device,
    } = this.props;
    return (
      <Grid container justifyContent="center" direction="column" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.field}>
            Select Device
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <FileManagementDeviceSelect
            device={device}
            onChange={onDeviceDropDownChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className={classes.field}>
            File Search
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} md={5}>
            <TextField
              translationKey="common.textfields.fileName"
              fullWidth
              onChange={onFileSearchChange}
              value={fileSearchValue}
            />
          </Grid>

          {/* New File State Dropdown for filtering by file state */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">File State</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Dropdown
              options={fileStateOptions}
              label="File State"
              value={fileState}
              onChange={onFileStateChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={1}>
            <TextButton
              translationKey="common.buttons.reset"
              onClick={onFileNameAndSelectDeviceReset}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" className={classes.field}>
            Time Range
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} md={5}>
            <DateTimePicker
              translationKey="common.datepickers.startDate"
              value={moment.utc(dateFrom)}
              onChange={(date) => onStartDateSelectorChange(date.toDate())}
              fullWidth
              disableFuture
            />
          </Grid>
          <Grid item xs={6} md={5}>
            <DateTimePicker
              translationKey="common.datepickers.endDate"
              value={moment.utc(dateTo)}
              className={classes.marginBottom}
              onChange={(date) => onEndDateSelectorChange(date.toDate())}
              fullWidth
              disableFuture
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Dropdown
            options={timeRangeArray}
            label="Time Range"
            value={timeRangeValue}
            onChange={onTimeRangeChange}
            fullWidth
          />
        </Grid>

        <Grid container justifyContent="flex-start">
          {this.renderSearchButton()}
          <ContainedButton
            translationKey="fileManagement.resetTimeFields"
            onClick={onEndDateTimeReset}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbars(FileManagementParameters));
