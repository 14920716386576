import { get, parseDmasAPIResponse } from 'util/WebRequest';

export type Site = {
  siteid: string;
  sitename: string;
};

class SiteService {
  static getAllSites() {
    return get('SiteService', { operation: undefined }).then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}

export default SiteService;
