import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextButton } from '@onc/composite-components';
import { Add, ChevronRight, Close, DragHandle } from '@onc/icons';
import {
  Divider,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from 'base-components';
import DraggableList from 'library/CompositeComponents/list-container/DraggableList';
import { ManualEntryFormType } from './ManualEntryForm';

type AnnotationDrawerProps = {
  onAdd: () => void;
  onDelete: (index: number) => void;
  currentIndex: number;
  onChange: (index: number) => void;
  annotations: ManualEntryFormType[];
  onOrderChange: (updatedFormList: ManualEntryFormType[]) => void;
};

type DraggableAnnotationLine = {
  annotation: ManualEntryFormType;
  title: string;
  index: number;
  id: string;
  selected: boolean;
  disabled: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
  },
  expandButton: {
    margin: theme.spacing(0.5),
  },
  expandControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textWrap: 'nowrap',
  },
  title: {
    paddingLeft: theme.spacing(2),
  },
  list: {
    width: '100%',
  },
  collapseWrapper: {
    width: '100%',
  },
  overflowY: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const AnnotationDrawer: React.FC<AnnotationDrawerProps> = ({
  annotations,
  onDelete,
  onAdd,
  currentIndex,
  onChange,
  onOrderChange,
}: AnnotationDrawerProps) => {
  const [open, setOpen] = useState(true);
  const [draggableAnnotationLines, setDraggableAnnotationLines] = useState([]);
  const classes = useStyles();

  const formatTitle = (annotation) => {
    let title =
      annotation.generalSection.taxonName ||
      annotation.generalSection?.taxon?.commonName ||
      annotation.generalSection?.taxon?.label ||
      annotation.generalSection?.comment ||
      'New Annotation';
    if (annotation.generalSection?.annotationId) {
      title = `* ${title}`;
    }
    if (title.length > 30) {
      title = `${title.substring(0, 30)}...`;
    }
    return title;
  };

  useEffect(() => {
    setDraggableAnnotationLines(
      annotations.map((annotation, index) => ({
        annotation,
        title: formatTitle(annotation),
        index,
        id: index.toString(),
        selected: currentIndex === index,
        disabled: false, // To be used while web service is running
      }))
    );
  }, [annotations, currentIndex]);

  const renderListItem = (annotationLine: DraggableAnnotationLine) => (
    <>
      <ListItem
        aria-label={annotationLine.title}
        button
        // eslint-disable-next-line react/no-array-index-key
        key={annotationLine.id}
        onClick={() => onChange(annotationLine.index)}
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary={annotationLine.title}
          sx={{ marginRight: '32px' }}
        />
        <ListItemSecondaryAction sx={{ right: 4 }}>
          <IconButton
            aria-label="Delete"
            onClick={(e) => {
              onDelete(annotationLine.index);
              e.stopPropagation();
            }}
            disabled={annotations.length === 1}
          >
            <Close fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );

  const updateOrder = (newOrderArray: DraggableAnnotationLine[]) => {
    const updatedOrderFormList = [];
    for (const obj of newOrderArray) {
      if (obj.selected) {
        onChange(newOrderArray.indexOf(obj));
      }
      updatedOrderFormList.push(obj.annotation);
    }
    onOrderChange(updatedOrderFormList);
  };

  const handleReorderLines = (
    newAnnotationLines: DraggableAnnotationLine[]
  ) => {
    setDraggableAnnotationLines(newAnnotationLines);
    updateOrder(newAnnotationLines);
  };

  const DragNDropHandle = (
    <DragHandle color="action" sx={{ margin: '8px' }} fontSize="small" />
  );

  return (
    <div className={classes.container}>
      <div className={classes.expandControl}>
        <IconButton
          aria-label={open ? 'Close Drafts' : 'Open Drafts'}
          onClick={() => setOpen(!open)}
          className={classes.expandButton}
        >
          <ChevronRight
            style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </IconButton>

        <Collapse orientation="horizontal" in={open}>
          <Typography variant="body1" className={classes.title} noWrap>
            Drafts
          </Typography>
        </Collapse>
      </div>
      <Divider />
      <Collapse
        orientation="horizontal"
        in={open}
        classes={{
          root: classes.overflowY,
          wrapperInner: classes.collapseWrapper,
        }}
      >
        <List className={classes.list} aria-label="Draft Annotations">
          <DraggableList
            items={draggableAnnotationLines}
            onChange={handleReorderLines}
            renderItem={renderListItem}
            dragHandle={DragNDropHandle}
          />
        </List>
        <TextButton
          startIcon={<Add />}
          onClick={() => onAdd()}
          translationKey="seatube.newAnnotation"
          className={classes.addButton}
          aria-label="Create New Annotation"
        />
      </Collapse>
    </div>
  );
};

export default AnnotationDrawer;
