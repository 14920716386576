import { FC, ComponentType, useState, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { ContainedButton, OutlinedButton } from '@onc/composite-components';
import { DownloadIcon, Delete } from '@onc/icons';
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  StatelessTable,
  TableColumn,
  Typography,
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
  DevExpressTableHeaderRow as TableHeaderRow,
} from 'base-components';
import dataProductDeliveryRequest from 'domain/Apps/geospatial-search/community-fisher/CastDataProductDeliveryRequest';
import SiteDeviceSubsetRowsContext from 'domain/Apps/geospatial-search/SiteDeviceSubsetRowContext';
import DraggableDialog, {
  DraggableDialogProps,
} from 'library/CompositeComponents/dialog/DraggableDialog';
import LinearProgressWithPercentage from 'library/CompositeComponents/progress/LinearProgressWithPercentage';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { get } from 'util/WebRequest';
import {
  SiteDeviceSubsetRow,
  ProductNames,
  SiteDeviceSubsetProduct,
} from '../../definitions/GeospatialSearchTypes';

const RESTRICTED_DATAPRODUCT_ERROR_CODE = 71;

const columns: TableColumn[] = [
  { name: 'search', title: 'Search', dataType: 'String' },
  { name: 'startDate', title: 'Start Date', dataType: 'Date' },
  { name: 'endDate', title: 'End Date', dataType: 'Date' },
  { name: 'progress', title: 'Progress', dataType: 'String' },
  { name: 'actions', title: '', dataType: 'String' },
];

const columnExtensions: any[] = [
  { columnName: 'search', width: 350, align: 'left' },
  { columnName: 'startDate', width: 200, align: 'left' },
  { columnName: 'endDate', width: 200, align: 'left' },
  { columnName: 'progress', width: 150, align: 'left' },
  { columnName: 'actions', width: 140, align: 'right' },
];

const customHeaderRow: ComponentType<any> = ({ column, ...rest }) => {
  if (column.name === 'actions')
    return (
      <TableHeaderRow.Cell column={column} {...rest}>
        <></>
      </TableHeaderRow.Cell>
    );

  return <TableHeaderRow.Cell column={column} {...rest} />;
};

const DataProductCart: FC<Omit<DraggableDialogProps, 'title'>> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();
  const { onError } = useSnackbars();

  const { siteDeviceSubsetRows, updateSiteDeviceSubsetRows } = useContext(
    SiteDeviceSubsetRowsContext
  );

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const defaultWidths = columnExtensions.map(
    ({ align, ...rest }) => rest as TableColumnWidthInfo
  );
  const [columnWidths, setColumnWidths] =
    useState<TableColumnWidthInfo[]>(defaultWidths);

  const downloadFiles = (products) => {
    const requestId = products[0].dpRequestId;
    const runIds = products.map((p) => p.dpRunId).toString();

    const zipFileUrl = `${Environment.getDmasUrl()}/SearchResultService?searchHdrId=${requestId}&searchIds=${runIds}&skipSubfolders=true`;

    const a = document.createElement('a');
    a.href = zipFileUrl;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const updateStatusForCastProducts = (
    cast: SiteDeviceSubsetRow,
    productId: number,
    status: string,
    dpRunId: number,
    dpRequestId: number
  ): void => {
    updateSiteDeviceSubsetRows((prevRows) =>
      prevRows.map((row) => {
        if (row.siteDeviceSubsetId === cast.siteDeviceSubsetId) {
          const updatedProducts = row.productsForCast.map((product) => {
            if (product.productId === productId) {
              return { ...product, status, dpRunId, dpRequestId };
            }
            return product;
          });

          if (status === 'Error') {
            return {
              ...row,
              status: 'Error',
              productsForCast: updatedProducts,
            };
          }

          if (status === 'Restricted') {
            return {
              ...row,
              status: 'Warning',
              productsForCast: updatedProducts,
            };
          }

          return { ...row, productsForCast: updatedProducts };
        }
        return row;
      })
    );
  };

  /**
   * Checks status of data product.
   *
   * @param {object} cast
   * @param {number} productId
   * @param {ProductNames} dataProduct
   * @param {number} dpRequestId
   * @param {number} dpRunId
   * @param {string} extension
   */
  const checkStatus = (
    cast: SiteDeviceSubsetRow,
    productId: number,
    dataProduct: ProductNames,
    dpRequestId: number,
    dpRunId: number,
    extension: string
  ) =>
    get(
      'apiproxy/dataProductDelivery',
      {
        method: 'status',
        dpRequestId,
      },
      {}
    )
      .then((response) => {
        const { searchHdrStatus, newSearches } = response.data;
        const { taskStatus } = newSearches[0];
        if (searchHdrStatus !== 'COMPLETED') {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(
                checkStatus(
                  cast,
                  productId,
                  dataProduct,
                  dpRequestId,
                  dpRunId,
                  extension
                )
              );
            }, 5000);
          });
        }

        updateStatusForCastProducts(
          cast,
          productId,
          'Completed',
          dpRunId,
          dpRequestId
        );

        if (taskStatus !== 'Completed') {
          // task does not successfully complete (completed with errors, cancelled, ...)
          const completionMessage = `Generation of ${dataProduct} finished for ${cast.siteDeviceSubsetName} with the following status: ${taskStatus}.`;
          onError(completionMessage);
        }
        // downloadFile(dpRequestId, dpRunId);
        return { dpRequestId, dpRunId };
        // return 12321;

        // return dpRunId;
      })
      .catch((error) => {
        const errorMessage = Environment.isUserLoggedIn()
          ? error.message
          : 'Please try logging in to access the requested data products.';
        onError(errorMessage);
        updateStatusForCastProducts(cast, productId, 'Error', -1, -1);
      });

  /**
   * Runs data product
   *
   * @param {object} cast
   * @param {number} productId
   * @param {ProductNames} dataProduct
   * @param {number} dpRequestId
   * @param {string} extension
   */
  const runDataProduct = (
    cast: SiteDeviceSubsetRow,
    productId: number,
    dataProduct: ProductNames,
    dpRequestId: number,
    extension: string
  ) =>
    get(
      'apiproxy/dataProductDelivery',
      {
        method: 'run',
        dpRequestId,
      },
      {}
    )
      .then((response) => {
        const { dpRunId } = response.data[0];
        updateStatusForCastProducts(
          cast,
          productId,
          'Running',
          dpRunId,
          dpRequestId
        );
        return checkStatus(
          cast,
          productId,
          dataProduct,
          dpRequestId,
          dpRunId,
          extension
        );
      })
      .catch((error) => {
        onError(error.message);
        updateStatusForCastProducts(cast, productId, 'Error', -1, -1);
      });

  const requestDataProduct = (
    cast: SiteDeviceSubsetRow,
    productId: number,
    dataProduct: ProductNames,
    extension: string
  ) => {
    updateStatusForCastProducts(cast, productId, 'Requesting', -1, -1);
    return dataProductDeliveryRequest({
      ...cast,
      dataProduct,
      extension,
    })
      .then((response) => {
        const { dpRequestId } = response.data;
        updateStatusForCastProducts(
          cast,
          productId,
          'Requested',
          -1,
          dpRequestId
        );
        return runDataProduct(
          cast,
          productId,
          dataProduct,
          dpRequestId,
          extension
        );
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          error.response.data.errors.forEach((dataError) => {
            onError(`${dataError.errorMessage}: ${dataError.parameter}`);
            // pick out errors caused by restricted dataproducts to provide a specific error message
            dataError.errorCode === RESTRICTED_DATAPRODUCT_ERROR_CODE
              ? updateStatusForCastProducts(
                  cast,
                  productId,
                  'Restricted',
                  -1,
                  -1
                )
              : updateStatusForCastProducts(cast, productId, 'Error', -1, -1);
          });
        } else {
          onError(error.message);
          updateStatusForCastProducts(cast, productId, 'Error', -1, -1);
        }
      });
  };

  const generateProducts = async (products) => {
    const requests = [];

    for (const product of products) {
      if (product.status === 'Completed') {
        requests.push({
          dpRequestId: product.dpRequestId,
          dpRunId: product.dpRunId,
        });
      } else if (product.status === '') {
        const cast = siteDeviceSubsetRows.find((row) =>
          row.productsForCast.some(
            (rowProduct) => rowProduct.productId === product.productId
          )
        );

        if (cast) {
          // Store the promise in the array
          requests.push(
            requestDataProduct(
              cast,
              product.productId,
              product.productName,
              product.extension
            ).then((generatedProduct) => {
              if (generatedProduct) {
                return generatedProduct;
              }
              return undefined;
            })
          );
        }
      }

      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts.filter((pId) => pId !== product.productId)
      );
    }

    // Wait for all requests to complete
    const generatedProducts = (await Promise.all(requests)).filter(
      (prod) => prod !== undefined
    );

    if (generatedProducts.length === 0) {
      onError('Could Not Generate Any Products');
      return undefined;
    }

    return generatedProducts;
  };

  const downloadAllSelected = async () => {
    const products = [];

    for (const productId of selectedProducts) {
      const cast = siteDeviceSubsetRows.find((row) =>
        row.productsForCast.some((product) => product.productId === productId)
      );

      if (cast) {
        const product = cast.productsForCast.find(
          (p) => p.productId === productId
        );

        if (product) {
          products.push(product);
        }
      }
    }

    setSelectedRows([]);
    const generatedResult = await generateProducts(products);

    if (generatedResult) {
      downloadFiles(generatedResult);
    } else {
      onError('Error generating products. Please try again.');
    }
  };

  const renderProgressCell = (
    castStatus: string,
    noneCompleted: boolean,
    numDownloadedCastDataProducts: number,
    totalCastDataProducts: number
  ) =>
    castStatus !== 'Error' ? (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {castStatus !== 'Warning' ? (
          <LinearProgressWithPercentage
            value={
              (numDownloadedCastDataProducts / totalCastDataProducts) * 100
            }
            isLoading={noneCompleted}
            showPercentage={false}
          />
        ) : (
          <Typography variant="body2" color={theme.palette.warning.main}>
            Warning
          </Typography>
        )}
        <Typography variant="body2">
          {`${numDownloadedCastDataProducts}/${totalCastDataProducts}`}
        </Typography>
      </Box>
    ) : (
      <Typography variant="body2" color={theme.palette.error.main}>
        Error
      </Typography>
    );

  const deleteTableRow = (siteDeviceSubsetId) => {
    updateSiteDeviceSubsetRows((prevRows) =>
      prevRows.filter((row) => row.siteDeviceSubsetId !== siteDeviceSubsetId)
    );

    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.filter((id) => id !== siteDeviceSubsetId.toString())
    );
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter(
        (productId) =>
          !siteDeviceSubsetRows
            .find((row) => row.siteDeviceSubsetId === siteDeviceSubsetId)
            .productsForCast.some((product) => product.productId === productId)
      )
    );
  };

  const downloadRowProducts = async (rowId) => {
    const cast = siteDeviceSubsetRows.find(
      (r) => r.siteDeviceSubsetId === rowId
    );
    if (cast) {
      const generatedResult = await generateProducts(cast.productsForCast);

      if (generatedResult) {
        downloadFiles(generatedResult);
      } else {
        onError('Error generating products. Please try again.');
      }
    }
  };

  const isAllRowProductsCompleted = (rowId) => {
    const cast = siteDeviceSubsetRows.find(
      (r) => r.siteDeviceSubsetId === rowId
    );

    return cast
      ? cast.productsForCast.every((product) => product.status === 'Completed')
      : false;
  };

  const renderActionButtons = (rowStatus, rowId) => (
    <>
      <IconButton
        aria-label="download-all"
        disabled={rowStatus !== '' && rowStatus !== 'Warning'}
        onClick={() => downloadRowProducts(rowId)}
        color={(() => {
          if (rowStatus === 'Warning') return 'warning';
          if (isAllRowProductsCompleted(rowId)) return 'success';
          return 'inherit';
        })()}
      >
        <DownloadIcon />
      </IconButton>
      <IconButton
        aria-label="delete"
        color="inherit"
        onClick={() => deleteTableRow(rowId)}
      >
        <Delete />
      </IconButton>
    </>
  );

  const rows = siteDeviceSubsetRows.map((row) => ({
    key: row.siteDeviceSubsetId,
    search: row.siteDeviceSubsetName,
    startDate: row.startDate.toString(),
    endDate: row.endDate.toString(),
    progress: renderProgressCell(
      row.status,
      row.productsForCast.every(
        (product) =>
          product.status === 'Requesting' || product.status === 'Running'
      ),
      row.productsForCast.filter((product) => product.status === 'Completed')
        .length,
      row.productsForCast.length
    ),
    actions: renderActionButtons(row.status, row.siteDeviceSubsetId),
    products: row.productsForCast,
  }));

  const handleRowSelection = (selected) => {
    const filteredSelected = selected.filter((selectedKey) => {
      const row = rows.find((r) => r.key === Number(selectedKey));

      return !row.products.every(
        (product) => product.status !== '' && product.status !== 'Completed'
      );
    });

    setSelectedRows(filteredSelected);

    const newSelectedProducts = [...selectedProducts];

    rows.forEach((row) => {
      if (filteredSelected.includes(row.key.toString())) {
        // Add products of the selected row if not already included
        row.products.forEach((product) => {
          if (
            !newSelectedProducts.includes(product.productId) &&
            (product.status === '' || product.status === 'Completed')
          ) {
            newSelectedProducts.push(product.productId);
          }
        });
      } else {
        // Remove products of the unselected row
        row.products
          .map((product) => product.productId)
          .forEach((productId) => {
            const index = newSelectedProducts.indexOf(productId);
            if (index > -1) {
              newSelectedProducts.splice(index, 1);
            }
          });
      }
    });

    setSelectedProducts(newSelectedProducts);
  };

  const handleDownloadProduct = async (
    castId: number,
    product: SiteDeviceSubsetProduct
  ) => {
    // Find the cast in the siteDeviceSubsetRows using the castId
    const cast = siteDeviceSubsetRows.find(
      (row) => row.siteDeviceSubsetId === castId
    );

    // Ensure that cast is defined before attempting to request a data product
    if (cast) {
      const generatedResult = await generateProducts([product]);

      if (generatedResult) {
        downloadFiles(generatedResult);
      } else {
        onError('Error generating products. Please try again.');
      }
    } else {
      onError('Cast not found or product status is not valid for download.');
    }
  };

  const handleClearAll = () => {
    updateSiteDeviceSubsetRows([]);
    setSelectedRows([]);
    setSelectedProducts([]);
  };

  const handleProductSelection = (
    checkedStatus: boolean,
    product: any,
    row: any
  ) => {
    if (checkedStatus) {
      setSelectedProducts((prevSelectedProducts) => [
        ...prevSelectedProducts,
        product.productId,
      ]);

      if (!selectedRows.includes(row.key.toString())) {
        setSelectedRows((prevSelectedRows) => [
          ...prevSelectedRows,
          row.key.toString(),
        ]);
      }
    } else {
      setSelectedProducts((prevSelectedProducts) => {
        const updatedSelectedProducts = prevSelectedProducts.filter(
          (p) => p !== product.productId
        );

        const noneSelected = row.products.every(
          (p) => !updatedSelectedProducts.includes(p.productId)
        );

        if (noneSelected) {
          setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((r) => r !== row.key.toString())
          );
        }

        return updatedSelectedProducts;
      });
    }
  };

  // Future TODO: Extract into seperate component
  const renderRowDetail = ({ row }) => (
    <List>
      {row.products.map((product) => {
        const labelId = `${row.key}-${product}`;
        const isChecked = selectedProducts.includes(product.productId);
        let statusColor = 'inherit';

        if (product.status === 'Restricted')
          statusColor = theme.palette.warning.main;
        else if (product.status === 'Error')
          statusColor = theme.palette.error.main;
        else if (product.status === 'Completed')
          statusColor = theme.palette.success.main;

        return (
          <ListItem
            key={product}
            secondaryAction={
              <IconButton
                aria-label="download"
                disabled={
                  product.status !== '' && product.status !== 'Completed'
                }
                edge="end"
                color={product.status === 'Completed' ? 'success' : 'inherit'}
                onClick={() => handleDownloadProduct(row.key, product)}
              >
                <DownloadIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                inputProps={{ 'aria-labelledby': labelId }}
                disabled={
                  product.status !== '' && product.status !== 'Completed'
                }
                checked={
                  isChecked &&
                  (product.status === '' || product.status === 'Completed')
                }
                onChange={(e) =>
                  handleProductSelection(e.target.checked, product, row)
                }
              />
            </ListItemIcon>

            <ListItemText
              id={labelId}
              primary={product.productType}
              secondary={
                product.status !== '' ? (
                  <span
                    style={{
                      color: statusColor,
                    }}
                  >
                    {product.status}
                  </span>
                ) : null
              }
            />
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <DraggableDialog open={open} title="Data Product Cart" onClose={onClose}>
      <DialogContent>
        <StatelessTable
          columns={columns}
          columnExtensions={columnExtensions}
          resize={{
            columnWidths,
            handleColumnWidths: () => setColumnWidths,
          }}
          rows={rows}
          headerCellComponent={customHeaderRow}
          selection={{
            selectByRowClick: false,
            highlightRow: false,
            showSelectionColumn: true,
            selection: selectedRows,
            onChange: handleRowSelection,
            showSelectAll: true,
          }}
          expandRow={{
            expandedRows,
            handleExpandRowChange: (expandedRowIds) =>
              setExpandedRows(expandedRowIds),
            RowDetail: renderRowDetail,
          }}
          getRowId={(row) => row.key.toString()}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          onClick={handleClearAll}
          translationKey="communityFishers.clearAllCasts"
          disabled={siteDeviceSubsetRows.length === 0}
        />
        <ContainedButton
          disabled={selectedProducts.length === 0}
          translationKey="common.buttons.download"
          onClick={downloadAllSelected}
        />
      </DialogActions>
    </DraggableDialog>
  );
};

export default DataProductCart;
