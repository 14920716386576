import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import Form from 'library/CompositeComponents/form/Form';
import FormDatePicker from 'library/CompositeComponents/form/FormDatePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  leftJustify: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  leftAlign: {
    marginLeft: theme.spacing(1),
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type ResourceRoleConfigForm = {
  resourceTypeId: string;
  resourceId: string;
  resourceRoleId: string;
  dateFrom: Moment;
  modifyDate: string;
  roleComment: string;
};

type ResourceRoleConfigProps = {
  title: string;
  onSave: () => void;
  onCancel: () => void;
  resourceRole?: {
    dateFrom: string;
    modifyDate: string;
    resourceId: number;
    resourceRoleId: number;
    resourceTypeId: number;
    roleComment: string;
  };
};

const ResourceRoleConfig = ({
  title,
  onSave,
  onCancel,
  resourceRole = {
    dateFrom: undefined,
    modifyDate: undefined,
    resourceId: undefined,
    resourceRoleId: undefined,
    resourceTypeId: undefined,
    roleComment: undefined,
  },
}: ResourceRoleConfigProps) => {
  const classes = useStyles();

  const formMethods = useForm<ResourceRoleConfigForm>({
    mode: 'onBlur',
    defaultValues: {
      resourceTypeId: resourceRole.resourceTypeId?.toString(),
      resourceId: resourceRole.resourceId?.toString(),
      resourceRoleId: resourceRole.resourceRoleId?.toString(),
      dateFrom: moment(resourceRole.dateFrom).utc().startOf('day'),
      modifyDate: resourceRole.modifyDate,
      roleComment: resourceRole.roleComment,
    },
  });

  const role = () => {
    const options = [
      { label: 'Device Owner', value: '3', key: '3' },
      { label: 'Principal Investigator', value: '8', key: '8' },
    ];
    return (
      <Grid item xs={12}>
        <FormDropdown
          name="resourceRoleId"
          label="Select a Role"
          fullWidth
          options={options}
          rules={{ required: 'A role is required!' }}
        />
      </Grid>
    );
  };

  const commentField = () => (
    <Grid item xs={12}>
      <FormTextField
        fullWidth
        name="roleComment"
        translationKey="common.textfields.comment"
        multiline
        minRows={9}
      />
    </Grid>
  );

  const modifyDate = () => {
    if (resourceRole.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyDate"
            labelText="modifyDate"
            valueText={resourceRole.modifyDate}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const datePicker = () => (
    <Grid item xs={12}>
      <FormDatePicker
        translationKey="common.datepickers.dateFrom"
        fullWidth
        name="dateFrom"
      />
    </Grid>
  );

  const resourceId = () => (
    <Grid item xs={12}>
      <FormTextField
        name="resourceId"
        translationKey="resource-role.partyId"
        fullWidth
        helperText="Enter Oceans 3.0 user ID, or Organization ID"
      />
    </Grid>
  );

  const resourceType = () => {
    const options = [
      { label: 'Organization', value: '15', key: '15' },
      { label: 'Oceans 3.0 User', value: '14', key: '14' },
    ];
    return (
      <Grid item xs={12}>
        <FormDropdown
          name="resourceTypeId"
          label="Party Type"
          options={options}
          fullWidth
        />
      </Grid>
    );
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={onSave} formMethods={formMethods}>
          <Grid container spacing={1} className={classes.root}>
            {resourceType()}
            {resourceId()}
            {role()}
            {datePicker()}
            {modifyDate()}
            {commentField()}
          </Grid>
          <Grid item className={classes.rightAlign}>
            <CancelButton onClick={onCancel} />
            <SaveButton type="submit" />
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ResourceRoleConfig;
