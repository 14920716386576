import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Loading,
  ClearButton,
  ContainedButton,
} from '@onc/composite-components';
import { ErrorPage, Grid, Typography } from 'base-components';
import { RePostprocessRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import SiteDeviceSubsetSelector from 'domain/AppComponents/site-device-maintenance/SiteDeviceSubsetSelector';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import SiteDeviceSubsetTable from './SiteDeviceSubsetTable';

const classes = {
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    marginBottom: 1,
  },
  entryFormFields: {
    marginBottom: 1,
  },
  gridPadding: {
    paddingLeft: 3,
    paddingTop: 1,
    paddingRight: 3,
  },
};

type AllSiteDeviceSubsetsPayload = {
  records: [
    {
      castReviewed: boolean;
      deviceId: number;
      endDate: string;
      generationType: number;
      generationTypeId: number;
      modifyBy: string;
      modifyDate: string;
      referenceDepth: number;
      referenceLat: number;
      referenceLon: number;
      siteDeviceId: number;
      siteDeviceSubsetId: number;
      siteDeviceSubsetName: string;
      siteDeviceSubsetType: number;
      siteDeviceSubsetTypeId: number;
      startDate: string;
      stationCode: string;
      wasRefLatLonModified: boolean;
    },
  ];
};

type CastSiteDeviceSubsetTypesPayload = Array<number>;

const SiteDeviceSubsetRePostProcess = () => {
  const [selectedSiteDeviceSubsets, setSelectedSiteDeviceSubsets] = useState(
    []
  );
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [radioValue, setRadioValue] = useState<string>(undefined);
  const { onError, onInfo } = useSnackbars();

  const subsetPayload = useGet<
    AllSiteDeviceSubsetsPayload,
    { expanded: boolean }
  >('SiteDeviceSubsetService', 5, {
    expanded: true,
  });

  const castSubsetTypes = useGet<CastSiteDeviceSubsetTypesPayload>(
    'SiteDeviceSubsetService',
    12
  );

  const { mutate: reprocessCastRefLatLon } = usePost<{
    siteDeviceSubsetId: number;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        onInfo('subset was submitted for re-post-process');
      },
      onError: () => {
        onError('Failed to submit subset for re-post-process');
      },
    },
    2
  );

  const { mutate: reprocessCast } = usePost<{
    siteDeviceSubsetId: number;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        onInfo('subset was submitted for re-post-process');
      },
      onError: () => {
        onError('Failed to submit subset for re-post-process');
      },
    },
    2
  );

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: { comment: '' },
  });

  if (subsetPayload.isLoading || castSubsetTypes.isLoading) {
    return <Loading />;
  }

  if (subsetPayload.error || castSubsetTypes.error) {
    return <ErrorPage />;
  }

  const siteDeviceSubsets = subsetPayload.data?.records.filter((subset) =>
    castSubsetTypes.data?.includes(subset.siteDeviceSubsetType)
  );

  const onSubsetChange = (subsets) => {
    setSelectedSiteDeviceSubsets(selectedSiteDeviceSubsets.concat(subsets));
  };

  const onRadioGroupChange = (e) => {
    setDisableSave(false);
    setRadioValue(e.target.value);
  };

  const handleSubsetDeletedInTable = (siteDeviceSubsetId) => {
    setSelectedSiteDeviceSubsets(
      selectedSiteDeviceSubsets.filter(
        (subset) => subset.siteDeviceSubsetId !== siteDeviceSubsetId
      )
    );
  };

  const onClearClick = () => {
    setSelectedSiteDeviceSubsets([]);
    setDisableSave(true);
    setRadioValue(undefined);
    formMethods.reset();
  };

  const handleSave = (values) => {
    if (selectedSiteDeviceSubsets.length === 0) {
      onError('Please select Site Device Subsets to re-post-process');
      return;
    }
    selectedSiteDeviceSubsets.forEach(async (subset) => {
      const rePostProcessWithRefLatLon = radioValue === 'no';
      if (rePostProcessWithRefLatLon) {
        reprocessCastRefLatLon({
          siteDeviceSubsetId: subset.siteDeviceSubsetId,
          comment: values.comment,
          method: 'reprocessRefLatLon',
        });
      } else {
        reprocessCast({
          siteDeviceSubsetId: subset.siteDeviceSubsetId,
          comment: values.comment,
          method: 'reprocess',
        });
      }
    });
    onClearClick();
  };

  return (
    <>
      <SiteDeviceSubsetSelector
        siteDeviceSubsets={siteDeviceSubsets}
        selectedSubsets={selectedSiteDeviceSubsets}
        selectionCallBack={onSubsetChange}
      />
      <Form onSubmit={handleSave} formMethods={formMethods}>
        <Grid container>
          <Grid item xs={3} sx={classes.gridPadding} />
          <Grid item xs={12}>
            <SiteDeviceSubsetTable
              onInfo={onInfo}
              onError={onError}
              siteDeviceSubsetRows={selectedSiteDeviceSubsets}
              pageSize={10}
              customDeleteActionOnChange={handleSubsetDeletedInTable}
              renderAddButton={false}
            />
          </Grid>
          <Grid item xs={12} sx={classes.gridPadding}>
            <Typography>
              Re-Post-Process Data for Unaltered Cast Using Scalar Latitude and
              Longitude
            </Typography>
            <RePostprocessRadioGroup
              title="rePostProcess"
              name="rePostProcess"
              onChange={onRadioGroupChange}
              value={radioValue}
            />
          </Grid>
          <Grid item xs={12} sx={classes.gridPadding}>
            <FormTextField
              name="comment"
              translationKey="common.textfields.comment"
              helperText="Please enter a comment"
              rules={{ required: 'Please enter a comment.' }}
              fullWidth
              multiline
              minRows={9}
              maxRows={15}
            />
          </Grid>
          <Grid item xs={12} sx={classes.formButtons}>
            <ContainedButton
              translationKey="device.rePostProcess"
              type="submit"
              disabled={disableSave}
            />
            <ClearButton onClick={onClearClick} />
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default SiteDeviceSubsetRePostProcess;
