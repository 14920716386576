import React, { useState } from 'react';

import {
  StatelessTable,
  TableColumn,
  DevExpressSortingDirection,
} from 'base-components';
import UserOrganizationActionFormatter from 'domain/AppComponents/user-organization-listing/formatters/UserOrganizationActionFormatter';
import { UserOrganizationRow } from 'domain/AppComponents/user-organization-listing/UserOrganizationTableLogic';

type UserOrganizationProps = {
  rows: UserOrganizationRow[];
  permission: string;
  onDelete: (row: UserOrganizationRow) => void;
  onCreate: () => void;
};

const UserOrganizationTable: React.FC<UserOrganizationProps> = ({
  rows,
  permission,
  onDelete,
  onCreate,
}: UserOrganizationProps) => {
  const defaultConfig = {
    grouping: [],
    sorting: [
      {
        columnName: 'lastname',
        direction: 'asc' as DevExpressSortingDirection,
      },
    ],
  };

  const [sorting, setSorting] = useState(defaultConfig.sorting);

  const columns: TableColumn[] = [
    { title: 'Last Name', name: 'lastname', dataType: 'String' },
    { title: 'First Name', name: 'firstname', dataType: 'String' },
    { title: 'Email', name: 'email', dataType: 'String' },
    {
      title: 'Organization Name',
      name: 'organizationName',
      dataType: 'String',
    },
    { title: 'Modified By', name: 'modifyBy', dataType: 'String' },
    { title: 'Modified Date', name: 'modifyDate', dataType: 'String' },
    { title: 'Actions', name: 'actions', dataType: 'Other' },
  ];

  const getDisabledColumnNames = () => {
    if (permission !== 'RW') {
      return ['actions'];
    }
    return [];
  };

  return (
    <StatelessTable
      rows={rows}
      columns={columns}
      getRowId={(row) => row.userId}
      searchable
      columnFormatProviders={[
        {
          name: 'ActionFormatter',
          for: ['actions'],
          formatterComponent: ({ row }) =>
            UserOrganizationActionFormatter({ row, onDelete }),
        },
      ]}
      sort={{
        sorting,
        customSorting: [
          {
            columnName: 'firstname',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            columnName: 'lastname',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
        ],
        handleSortingChange: setSorting,
      }}
      paging={{ pageSize: 15, pageSizes: [15, 30, 60, 300] }}
      fixed={{
        leftColumns: [],
        rightColumns: ['actions'],
      }}
      virtual={{
        virtualized: true,
      }}
      visible={{
        hiddenColumnNames: getDisabledColumnNames(),
        disableColumnNames: getDisabledColumnNames(),
      }}
      permission={permission}
      onCreate={onCreate}
    />
  );
};

export default UserOrganizationTable;
