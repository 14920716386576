import { WebRequest } from '@onc/service';

class ExtensionService {
  static getMany() {
    return ExtensionService.get({ operation: 5 });
  }

  static getOne(extensionId) {
    return ExtensionService.get({ extensionId });
  }

  static get(params) {
    return WebRequest.get('CableExtensionService', {
      ...params,
    }).then((response) => WebRequest.parseDmasAPIResponse(response));
  }

  static create(params) {
    return WebRequest.post('CableExtensionService', {
      operation: 1,
      ...params,
    }).then((response) => WebRequest.parseDmasAPIResponse(response));
  }

  static update(params) {
    return WebRequest.post('CableExtensionService', {
      operation: 2,
      ...params,
    }).then((response) => WebRequest.parseDmasAPIResponse(response));
  }

  static delete = (params) =>
    WebRequest.post('CableExtensionService', {
      operation: 3,
      ...params,
    }).then((response) => WebRequest.parseDmasAPIResponse(response));
}
export default ExtensionService;
