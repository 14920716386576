import { useCallback, useMemo } from 'react';
import { BarChart } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ShowChart } from '@onc/icons';
import ChartUtils from './ChartUtils';
import { ChartQuality } from './types/TimeSeriesScalarDataChart.types';

type LegendTraceVisualizationProps = {
  colour: string;
  chartQuality: ChartQuality;
  isMinMaxEnabled: boolean;
  isResampled: boolean;
  sourceIdentifier?: string;
  toggleSource?: (
    dataSourceId: string,
    traceType: 'average' | 'minMax'
  ) => void;
  isAverageTraceEnabled?: boolean;
  isMinMaxTraceDisabled?: boolean;
};

const createBoxStyle = (isEnabled: boolean) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '4px',
  },
  padding: '4px',
  opacity: isEnabled ? 1 : 0.5,
});

const TraceColourCell = ({
  colour,
  chartQuality,
  isMinMaxEnabled,
  isResampled,
  sourceIdentifier = '',
  toggleSource = () => {},
  isAverageTraceEnabled = true,
  isMinMaxTraceDisabled = false,
}: LegendTraceVisualizationProps) => {
  const displayColour = colour || '#000000';
  const capitalizedQuality =
    chartQuality.charAt(0).toUpperCase() + chartQuality.slice(1);

  const handleAverageTraceClick = useCallback(() => {
    toggleSource(sourceIdentifier, 'average');
  }, [sourceIdentifier, toggleSource]);

  const handleMinMaxTraceClick = useCallback(() => {
    toggleSource(sourceIdentifier, 'minMax');
  }, [sourceIdentifier, toggleSource]);

  const averageBoxStyles = useMemo(
    () => createBoxStyle(isAverageTraceEnabled),
    [isAverageTraceEnabled]
  );
  const minMaxBoxStyles = useMemo(
    () => createBoxStyle(isMinMaxTraceDisabled),
    [isMinMaxTraceDisabled]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box sx={averageBoxStyles} onClick={handleAverageTraceClick}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ShowChart
            sx={{ mr: 1, color: displayColour }}
            data-cy="average-icon"
          />
          <Typography>
            {isResampled ? 'Clean average' : `${capitalizedQuality} value`}
          </Typography>
        </Box>
      </Box>

      {(isMinMaxEnabled || chartQuality === 'raw') && (
        <Box sx={minMaxBoxStyles} onClick={handleMinMaxTraceClick}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BarChart
              sx={{
                mr: 1,
                color: ChartUtils.setColorOpacity(displayColour, 40),
              }}
            />
            <Typography>{capitalizedQuality} min/max</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TraceColourCell;
