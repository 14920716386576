import { Suspense, useEffect, useState } from 'react';
import * as React from 'react';
import { StyledEngineProvider, Theme, alpha } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Route, BrowserRouter } from 'react-router-dom';
import { Loading, SearchField } from '@onc/composite-components';

import {
  oceans3ThemeDark,
  oceans2Theme,
  oceans3Theme,
  oncDefaultTheme,
  DenseThemeProvider,
} from '@onc/theme';

import {
  AdapterMoment,
  Drawer,
  LocalizationProvider,
  Typography,
} from 'base-components';
import AppMenuList from 'domain/Apps/menu/AppMenuList';
import RouteDestination from 'domain/Apps/menu/RouteDestination';
import CablesPageRoute from 'domain/Apps/menu/routes/CablesPageRoute';
import CommunityFishersDevicePackgeInfoRoute from 'domain/Apps/menu/routes/CommunityFishersDevicePackageInfoRoute';
import DAFTestingRoute from 'domain/Apps/menu/routes/DAFTestingRoute';
import DashboardsRoute from 'domain/Apps/menu/routes/DashbordsRoute';
import DataPlayerContainerRoute from 'domain/Apps/menu/routes/DataPlayerContainerRoute';
import DatasetLandingPageContainerRoute from 'domain/Apps/menu/routes/DatasetLandingPageContainerRoute';
import DeviceCategoryRoute from 'domain/Apps/menu/routes/DeviceCategoryRoute';
import DeviceRoute from 'domain/Apps/menu/routes/DeviceRoute';
import DeviceTypeRoute from 'domain/Apps/menu/routes/DeviceTypeRoute';
import DigitalFisherRoute from 'domain/Apps/menu/routes/DigitalFisherRoute';
import EarthquakeCatalogRoute from 'domain/Apps/menu/routes/EarthquakeCatalogRoute';
import EarthquakeDetailRoute from 'domain/Apps/menu/routes/EarthquakeDetailRoute';
import EEWNotificationRegistrationRoute from 'domain/Apps/menu/routes/EEWNotificationRegistrationRoute';
import EndeavourEarthquakeCatalogRoute from 'domain/Apps/menu/routes/EndeavourEarthquakeCatalogRoute';
import FileManagementRoute from 'domain/Apps/menu/routes/FileManagementRoute';
import FileUploadRoute from 'domain/Apps/menu/routes/FileUploadRoute';
import FormFieldRoute from 'domain/Apps/menu/routes/FormFieldRoute';
import GeospatialSearchRoute from 'domain/Apps/menu/routes/GeospatialSearchRoute';
import LoginRoute from 'domain/Apps/menu/routes/LoginRoute';
import MultiQueueRoute from 'domain/Apps/menu/routes/MultiQueueRoute';
import OpenApiRoute from 'domain/Apps/menu/routes/OpenApiRoute';
import OrganizationRoute from 'domain/Apps/menu/routes/OrganizationRoute';
import QaqcRoute from 'domain/Apps/menu/routes/QaqcRoute';
import RequestSupportRoute from 'domain/Apps/menu/routes/RequestSupportRoute';
import SeatubeRoute from 'domain/Apps/menu/routes/SeatubeRoute';
import SensorTypeRoute from 'domain/Apps/menu/routes/SensorTypeRoute';
import SystemConsolePageRoute from 'domain/Apps/menu/routes/SystemConsolePageRoute';
import TaskManagementRoute from 'domain/Apps/menu/routes/TaskManagementRoute';
import TaskOutputTableRoute from 'domain/Apps/menu/routes/TaskOutputTableRoute';
import Environment from 'util/Environment';
import SnackbarProvider from 'util/hooks/useSnackbars';
import { useLocalStorage, useSessionStorage } from 'util/hooks/useStorage';
// import SensorCategoryRoute from 'domain/Apps/menu/routes/SensorCategoryRoute';
import AppMenuBar from './AppMenuBar';
import AgreementManagementRoute from './routes/AgreementManagementRoute';
import AnnotationRoute from './routes/AnnotationRoute';
import AutomationTestingRoute from './routes/AutomationTestingRoute';
import CableDetailsRoute from './routes/CableDetailsRoute';
import CableManagementPageRoute from './routes/CableManagementPageRoute';
import CompromisedInstrumentsRoute from './routes/CompromisedInstrumentsRoute';
import DataPreviewAnnotationDisplayRoute from './routes/DataPreviewAnnotationDisplayRoute';
import DataPreviewManagementRoute from './routes/DataPreviewManagementRoute';
import DataViewerRoute from './routes/DataViewerRoute';
import DeviceBulkUpdatePageRoute from './routes/DeviceBulkUpdatePageRoute';
import DevicePresentationRoute from './routes/DevicePresentationRoute';
import DeviceWorkflowBulkUpdateRoute from './routes/DeviceWorkflowBulkUpdatePageRoute';
import GeospatialSearchv2Route from './routes/GeospatialSearchv2Route';
import LandingPageRoute from './routes/LandingPageRoute';
import LicenceRoute from './routes/LicenceRoute';
import LocalContextsRoute from './routes/LocalContextsRoute';
import SearchHistoryPageRoute from './routes/SearchHistoryPageRoute';
import SiteDeviceSubsetRePostProcessRoute from './routes/SiteDeviceSubsetRePostProcessRoute';
import SynchronizerManagementRoute from './routes/SynchronizerManagementRoute';
import TaxonResourceRoute from './routes/TaxonResourceRoute';
import UserDefinedDashboardRoute from './routes/UserDefinedDashboardRoute';
import UserOrganizationListingRoute from './routes/UserOrganizationListingRoute';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const ROUTES = [
  LandingPageRoute,
  AnnotationRoute,
  AutomationTestingRoute,
  CableDetailsRoute,
  CablesPageRoute,
  CableManagementPageRoute,
  CommunityFishersDevicePackgeInfoRoute,
  DataPlayerContainerRoute,
  DatasetLandingPageContainerRoute,
  DashboardsRoute,
  DeviceCategoryRoute,
  FormFieldRoute,
  RequestSupportRoute,
  DeviceRoute,
  DigitalFisherRoute,
  FileUploadRoute,
  FileManagementRoute,
  DataPreviewAnnotationDisplayRoute,
  DataPreviewManagementRoute,
  DeviceBulkUpdatePageRoute,
  DeviceWorkflowBulkUpdateRoute,
  GeospatialSearchRoute,
  DAFTestingRoute,
  QaqcRoute,
  SeatubeRoute,
  SystemConsolePageRoute,
  TaskManagementRoute,
  TaskOutputTableRoute,
  MultiQueueRoute,
  OrganizationRoute,
  OpenApiRoute,
  DeviceTypeRoute,
  TaxonResourceRoute,
  EarthquakeCatalogRoute,
  EEWNotificationRegistrationRoute,
  EarthquakeDetailRoute,
  EndeavourEarthquakeCatalogRoute,
  LicenceRoute,
  DevicePresentationRoute,
  SearchHistoryPageRoute,
  SiteDeviceSubsetRePostProcessRoute,
  AgreementManagementRoute,
  SensorTypeRoute,
  LocalContextsRoute,
  // SensorCategoryRoute,
  SynchronizerManagementRoute,
  LoginRoute,
  GeospatialSearchv2Route,
  UserOrganizationListingRoute,
  DataViewerRoute,
  UserDefinedDashboardRoute,
  CompromisedInstrumentsRoute,
];
const styles = (theme) =>
  createStyles({
    appContainer: {
      ...theme.mixins.toolbar,
      [theme.breakpoints.down('lg')]: {
        height: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
      },
      [theme.breakpoints.up('md')]: {
        // This is the 'dense' variant supplied by the toolbar mixin usually only used for landscape on phones and tablets
        // We also use it for medium or greater resolutions
        height: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:0px)']['@media (orientation: landscape)'].minHeight}px)`,
      },
    },
    drawer: {
      position: 'relative',
      zIndex: theme.zIndex.drawer + 1,
      minWidth: '350px',
    },
    header: {
      margin: theme.spacing(),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.black, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
      },
      width: '100%',
    },
    searchContainer: {
      padding: theme.spacing(),
    },
  });

const useStyles = makeStyles((theme: Theme) => styles(theme));

const ThemeMap = new Map<string, Theme>([
  ['Default', oncDefaultTheme],
  ['Oceans 2', oceans2Theme],
  ['Oceans 3', oceans3Theme],
  ['Oceans 3 (Dark)', oceans3ThemeDark],
]);

const AppMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Dev Playground');
  const [savedTheme, setSavedTheme] = useLocalStorage(
    'dev-playground-theme',
    'Default'
  );
  const [searchValue, setSearchValue] = useSessionStorage('AppMenuSearch', '');

  const classes = useStyles();

  useEffect(() => {
    // Clear the current application token to ensure it doesn't have a value from the
    // last application that was rendered.
    Environment.setCurrentApplicationToken(undefined);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const renderRoutes = (routes) =>
    routes.map(
      (route: {
        children?: any;
        path: any;
        exact?: any;
        name: any;
        component?: React.VFC;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
      }) => renderRoute(route)
    );

  const renderRoute = (route: {
    children?: any;
    path: any;
    exact?: any;
    name: any;
    component?: React.VFC;
  }) => {
    if (route.children) {
      return renderRoutes(route.children);
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        render={(props) => (
          <RouteDestination
            route={route}
            routerProps={props}
            setTitle={() => setTitle(route.name)}
          />
        )}
      />
    );
  };

  const circularProgress = () => <Loading />;

  const searchRoute = (route) => {
    const filteredChildren =
      route.children?.map(searchRoute).filter(Boolean) || [];

    const isRouteMatch = route.name
      .toLowerCase()
      .includes(searchValue.toLowerCase());

    if (isRouteMatch || filteredChildren.length > 0) {
      return {
        ...route,
        expanded: !!searchValue,
        children: isRouteMatch ? route.children : filteredChildren,
      };
    }
    return null;
  };

  const SORTED_ROUTES = ROUTES.map(searchRoute)
    .filter((route) => route !== null && route.name !== 'Sign In')
    .map((route) => {
      // Sort the children of the route, if they exist
      if (route.children && Array.isArray(route.children)) {
        route.children.sort((a, b) => a.name.localeCompare(b.name));
      }
      return route;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <StyledEngineProvider injectFirst>
      <DenseThemeProvider
        theme={ThemeMap.get(savedTheme) || ThemeMap.get('Default')}
      >
        <BrowserRouter>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <AppMenuBar
                title={title}
                handleDrawerOpen={handleDrawerOpen}
                themeMap={ThemeMap}
                theme={savedTheme}
                onChangeTheme={setSavedTheme}
              />
              <Drawer
                open={open}
                onClose={handleDrawerClose}
                className={classes.drawer}
              >
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.header}
                >
                  Dev Playground
                </Typography>
                <div id="app-drawer" className={classes.drawer}>
                  <AppMenuList
                    routes={[LoginRoute]}
                    onClick={handleDrawerClose}
                    searchValue={searchValue}
                  />
                </div>
                <div className={classes.searchContainer}>
                  <SearchField
                    textFieldValue={searchValue}
                    handleSearch={() => {}}
                    handleTextField={(e) => setSearchValue(e.target.value)}
                    classes={{ search: classes.search }}
                    autoFocus
                  />
                </div>
                <div id="app-drawer" className={classes.drawer}>
                  <AppMenuList
                    routes={SORTED_ROUTES}
                    onClick={handleDrawerClose}
                    searchValue={searchValue}
                  />
                </div>
              </Drawer>

              <div className={classes.appContainer}>
                <Suspense fallback={circularProgress()}>
                  {renderRoutes(ROUTES)}
                </Suspense>
              </div>
            </LocalizationProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </DenseThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppMenu;
