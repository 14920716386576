import React, { useState } from 'react';
import { CancelButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
} from 'base-components';
import PlaylistService, { Playlist } from 'domain/services/PlaylistService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import ObjectUtils from 'util/ObjectUtils';
import {
  validateCollectionName,
  validatePlaylistAutocomplete,
} from './CollectionUtil';
import AddCollectionForm, {
  CollectionForm,
  CollectionFormErrors,
} from '../forms/AddCollectionForm';

const EMPTY_FORM = {
  groupName: '',
  playlistsToAdd: [],
};

type AddCollectionDialogProps = {
  open: boolean;
  groups: string[];
  playlists: Playlist[];
  onClose: () => void;
  actionAfterAddGroup: () => void;
};

const AddCollectionDialog: React.FC<AddCollectionDialogProps> = ({
  open,
  groups,
  playlists,
  onClose,
  actionAfterAddGroup,
}) => {
  const [, isSaving, createPlaylistGroup] = useWebService({
    method: PlaylistService.updateGroupNameMultiplePlaylists,
  });
  const { onError, onInfo } = useSnackbars();
  const [value, setValue] = useState<CollectionForm>(EMPTY_FORM);
  const [errors, setErrors] = useState<CollectionFormErrors>({});

  const handleClose = () => {
    setValue(EMPTY_FORM);
    setErrors({});
    onClose();
  };

  const handleSubmit = () => {
    const { groupName, playlistsToAdd } = value;
    const validationErrors: CollectionFormErrors = {
      groupName: validateCollectionName(groupName, groups),
      playlistsToAdd: validatePlaylistAutocomplete(playlistsToAdd),
    };
    if (validationErrors?.groupName || validationErrors?.playlistsToAdd) {
      onError('Please fix the errors in the form');
      return setErrors(validationErrors);
    }
    return createPlaylistGroup({
      groupName,
      playlistHdrIds: playlistsToAdd.map((playlist) => playlist.value),
    })
      .then((response) => {
        if (response) {
          onInfo('New collection created successfully');
          actionAfterAddGroup();
          handleClose();
        }
      })
      .catch((err) => onError(err));
  };

  const handleChange = (key, val) => {
    if (key === 'groupName') {
      setErrors((prev) => ({
        ...prev,
        groupName: validateCollectionName(val, groups),
      }));
    }
    if (key === 'playlistsToAdd') {
      setErrors((prev) => ({
        ...prev,
        playlistsToAdd: validatePlaylistAutocomplete(val),
      }));
    }
    setValue((prev) => ({ ...prev, [key]: val }));
  };

  const getPlaylistOptions = () => {
    const ungroupedPlaylists = playlists
      .filter((playlist) => !playlist?.groupName)
      .map((playlist) => ({
        label: playlist.name,
        value: playlist.playlistHdrId,
      }))
      .filter(
        (playlist) =>
          !value.playlistsToAdd
            .map((groupOption) => groupOption.value)
            .includes(playlist.value)
      );

    ungroupedPlaylists.sort((a, b) => (a.label > b.label ? 1 : -1));
    return ungroupedPlaylists;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create Collection</DialogTitle>
      <DialogContent>
        <AddCollectionForm
          errors={errors}
          value={value}
          groupOptions={getPlaylistOptions()}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} />
        <LoadingButton
          loading={isSaving}
          variant="contained"
          translationKey="common.buttons.save"
          disabled={
            ObjectUtils.isEqual(EMPTY_FORM, value) ||
            Object.keys(errors).some((key) => !!errors[key])
          }
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddCollectionDialog;
