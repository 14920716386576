import { FC, FormEvent, MouseEvent, ReactNode } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ContainedButton, TextButton } from '@onc/composite-components';
import { TranslationType } from '@onc/i18n';
import { Close } from '@onc/icons';
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from 'base-components';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
  dialogTitle: {
    paddingBottom: theme.spacing(1),
  },
  dialogContent: {
    paddingTop: theme.spacing(0.5),
  },
}));

type FullScreenDialogProps = {
  title?: string;
  onClose: () => void;
  onConfirm?: (event: FormEvent<HTMLFormElement> | MouseEvent) => void;
  confirmButtonTranslationKey?: TranslationType;
  fullScreen: boolean;
};
/**
 * Fullscreen dialog meant for occupying the entire screen on a mobile device
 *
 * @param {any} props
 */
export const FullScreenDialog: FC<FullScreenDialogProps> = ({
  title = undefined,
  onClose,
  onConfirm = undefined,
  confirmButtonTranslationKey = 'common.buttons.confirm',
  fullScreen,
}) => {
  const classes = useStyles();

  if (fullScreen) {
    return (
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <TextButton
            translationKey={confirmButtonTranslationKey}
            className={classes.button}
            color="inherit"
            type="submit"
            onClick={onConfirm}
          />
        </Toolbar>
      </AppBar>
    );
  }

  return null;
};

type FormDialogProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit?: (event: FormEvent<HTMLFormElement> | MouseEvent) => void;
  confirmButtonTranslationKey?: TranslationType;
  cancelButtonTranslationKey?: TranslationType;
  maxWidth?: Breakpoint;
  canSave?: boolean;
  fullWidth?: boolean;
  container?: Element;
  children?: ReactNode;
};

/**
 * Fully responsive dialogue meant for user input such as Forms
 *
 * @param {any} props
 */
export const FormDialog: FC<FormDialogProps> = ({
  title,
  open,
  onClose,
  onSubmit = undefined,
  children = undefined,
  cancelButtonTranslationKey = 'common.buttons.cancel',
  confirmButtonTranslationKey = 'common.buttons.save',
  maxWidth = 'lg',
  canSave = true,
  fullWidth = false,
  container = undefined,
}: FormDialogProps) => {
  const theme = useTheme();
  const classes = useStyles();
  let fullScreen = false;

  if (useMediaQuery(theme.breakpoints.down('md'))) {
    fullScreen = true;
  }

  const renderDialogActions = () => {
    if (!fullScreen) {
      return (
        <DialogActions>
          <TextButton
            translationKey={cancelButtonTranslationKey}
            onClick={onClose}
          />
          <ContainedButton
            translationKey={confirmButtonTranslationKey}
            type="submit"
            disabled={!canSave}
          />
        </DialogActions>
      );
    }

    return null;
  };

  const renderTitle = () => {
    if (!fullScreen) {
      return (
        <DialogTitle className={classes.dialogTitle} id={title}>
          {title}
        </DialogTitle>
      );
    }

    return null;
  };

  const handleSubmit = (event) => {
    onSubmit(event);
    event.preventDefault();
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      aria-label={title}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      container={container}
    >
      <form onSubmit={handleSubmit}>
        <FullScreenDialog
          fullScreen={fullScreen}
          title={title}
          onClose={onClose}
          onConfirm={onSubmit}
          confirmButtonTranslationKey={confirmButtonTranslationKey}
        />
        {renderTitle()}
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
        {renderDialogActions()}
      </form>
    </Dialog>
  );
};
