import { ConnectorType } from '@onc/domain-services';

export interface ConnectorTypeRow {
  connectorTypeId: number;
  connectorTypeName: string;
  description: string;
  modifyBy: string;
  modifyDate: string;
}

const formatModifyDate = (modifyDate: string) => {
  const strArr = modifyDate.split('T');
  strArr[1] = strArr[1].substring(0, 8);
  return `${strArr[0]} ${strArr[1]}`;
};

export const transformConnectorTypeRows = (
  connectorTypes: ConnectorType[]
): ConnectorTypeRow[] =>
  connectorTypes.map((connectorType) => ({
    connectorTypeId: connectorType.connectorTypeId,
    connectorTypeName: connectorType.name,
    description: connectorType.description,
    modifyBy: `${connectorType.modifyBy.firstname} ${connectorType.modifyBy.lastname}`,
    modifyDate: formatModifyDate(connectorType.modifyDate),
  }));

export const transformRowToSyncForm = (
  row: ConnectorTypeRow
): ConnectorType => ({
  connectorTypeId: row.connectorTypeId,
  name: row.connectorTypeName,
  description: row.description,
});
