import { get, post, deleteImpl } from 'util/WebRequest';

const USER_SERVICE = '/internal/users';
const ORGANIZATION_SERVICE = '/internal/organizations';

class UserOrganizationService {
  static createUserOrganization(userId: number, organizationId: number) {
    return post(`${USER_SERVICE}/${userId}/organizations`, {
      organizationId,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteUserOrganization(userId: number, organizationId: number) {
    return deleteImpl(
      `${USER_SERVICE}/${userId}/organizations/${organizationId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getOrganizationsForUser(userId: number) {
    return get(`${USER_SERVICE}/${userId}/organizations`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getUsersForOrganization(orgId: number) {
    return get(`${ORGANIZATION_SERVICE}/${orgId}/users`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default UserOrganizationService;
