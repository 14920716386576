import { useCallback, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { TextButton } from '@onc/composite-components';
import { ExpandMore, Add } from '@onc/icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TypographyWithTranslation,
  Typography,
} from 'base-components';
import ChartUtils from 'domain/AppComponents/charts/ChartUtils';
import { DataSourceType } from 'domain/AppComponents/charts/types/TimeSeriesScalarDataChart.types';
import {
  isDeploymentDataSource,
  isLocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import DataSourceSelector from 'domain/AppComponents/data-source/DataSourceSelector';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import DataSourceList from './DataSourceList';
import DeploymentDataSourceDialog from './DeploymentDataSourceDialog';
import { getDeploymentIdentifier } from './util/ChartWidgetUtil';
import type {
  DataSource,
  DeploymentDataSource,
  LocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';

type ConfigDataSourceSelectorProps = {
  title: string;
  onLocationsChange: (newLocations: any) => void;
  axis?: 'left' | 'right' | '';
  locations?: DataSource[];
};

const useStyles = makeStyles(() =>
  createStyles({
    details: {
      padding: 0,
    },
    addSourceButton: {
      padding: 0,
    },
  })
);

const isSourceDuplicate = (existingSource, newSource) => {
  if (
    isDeploymentDataSource(existingSource) &&
    isDeploymentDataSource(newSource)
  ) {
    return (
      getDeploymentIdentifier(existingSource) ===
      getDeploymentIdentifier(newSource)
    );
  }

  // Otherwise, compare node IDs
  return existingSource.id === newSource.id;
};

const ConfigDataSourceSelector: React.FC<ConfigDataSourceSelectorProps> = ({
  title,
  onLocationsChange,
  axis = '',
  locations = [],
}: ConfigDataSourceSelectorProps) => {
  const [dataSourceForDialog, setDataSourceForDialog] = useState<{
    source?: DataSource;
    type?: 'deployment' | 'location';
    show: boolean;
  }>({ show: false });

  const classes = useStyles();
  const { control } = useFormContext();
  const isleftOverride = useWatch({ control, name: 'isleftOverride' });
  const isrightOverride = useWatch({ control, name: 'isrightOverride' });
  const isLeftOrRightDisabled =
    axis === 'left' ? !isleftOverride : !isrightOverride;

  const addDataSource = useCallback(
    (sources: Omit<DataSource, 'traceColour'>[]) => {
      let newSources: DataSource[] = [...locations];

      // remove dataSourceForDialog.source from the list
      if (dataSourceForDialog.source) {
        newSources = newSources.filter(
          (item) => item.nodeId !== dataSourceForDialog.source?.nodeId
        );
      }

      const propertyColours = ChartUtils.getPropertyColors(
        sources.map((source) => source.sensorTypeCode)
      );

      for (const [i, source] of sources.entries()) {
        const isDuplicate = newSources.some((existingSource) =>
          isSourceDuplicate(existingSource, source)
        );

        if (!isDuplicate) {
          const newSource = { ...source } as DataSource;
          newSource.traceName = source.name;
          newSource.sensorName = source.name;
          newSource.traceColour = propertyColours[i];

          if (!isDeploymentDataSource(newSource)) {
            newSource.dataSourceType = DataSourceType.Location;
          }
          newSources.push(newSource);
        }
      }

      onLocationsChange(newSources);
    },
    [dataSourceForDialog.source, locations, onLocationsChange]
  );

  const removeDataSource = useCallback(
    (source: DataSource) => {
      const newArray = locations.filter(
        (item) => !isSourceDuplicate(item, source)
      );

      onLocationsChange(newArray);
    },
    [locations, onLocationsChange]
  );

  const putDataSource = useCallback(
    (source: DataSource) => {
      const newArray = [...locations];
      let index = -1;
      if (isLocationDataSource(source)) {
        index = newArray.findIndex((item) => item.nodeId === source.nodeId);
      } else {
        index = newArray.findIndex((item) =>
          isLocationDataSource(item)
            ? false
            : getDeploymentIdentifier(item) === getDeploymentIdentifier(source)
        );
      }
      if (index !== -1) {
        newArray[index] = source;
      } else {
        newArray.push(source);
      }

      onLocationsChange(newArray);
    },
    [locations, onLocationsChange]
  );

  const editDataSource = useCallback(
    (source) =>
      setDataSourceForDialog({
        source,
        type: isLocationDataSource(source) ? 'location' : 'deployment',
        show: true,
      }),
    []
  );

  return (
    <Grid container>
      <DataSourceSelector
        onCloseDialog={() => setDataSourceForDialog({ show: false })}
        showDialog={
          dataSourceForDialog.type === 'location' && dataSourceForDialog.show
        }
        treeTypePreset="propertiesByLocation"
        propertyPreset={[]}
        editData={dataSourceForDialog.source as LocationDataSource}
        onSelectNodes={addDataSource}
        title="Select Data Source"
        multiple
        disableDateRange
      />
      <DeploymentDataSourceDialog
        open={
          dataSourceForDialog.type === 'deployment' && dataSourceForDialog.show
        }
        source={dataSourceForDialog.source as DeploymentDataSource}
        onClose={() => setDataSourceForDialog({ show: false })}
        onSubmit={(source) => addDataSource([source])}
      />
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <TypographyWithTranslation
              variant="subtitle1"
              translationKey="common.textfields.variable"
              translationOptions={{ variableName: title }}
            />
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Grid container columns={16} direction="row-reverse">
              <Grid item xs={8}>
                <TextButton
                  translationKey="dashboards.addLocationDataSource"
                  startIcon={<Add />}
                  onClick={() =>
                    setDataSourceForDialog({ show: true, type: 'location' })
                  }
                  className={classes.addSourceButton}
                />
              </Grid>
              <Grid item xs={7}>
                <TextButton
                  className={classes.addSourceButton}
                  translationKey="dashboards.addDeploymentDataSource"
                  startIcon={<Add />}
                  onClick={() =>
                    setDataSourceForDialog({ show: true, type: 'deployment' })
                  }
                />
              </Grid>
              <Grid container columns={16}>
                <Grid item xs={12}>
                  <Typography
                    fullWidth
                    variant="subtitle2"
                    style={{ marginLeft: '25px' }}
                    gutterTop
                  >
                    Y-Axis Range
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`${axis}min`}
                    translationKey="dashboards.minimumRange"
                    type="number"
                    size="small"
                    style={{ marginLeft: '20px' }}
                    disabledTextField={isLeftOrRightDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`${axis}max`}
                    translationKey="dashboards.maximumRange"
                    type="number"
                    size="small"
                    style={{ marginLeft: '10px' }}
                    disabledTextField={isLeftOrRightDisabled}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name={`is${axis}Override`}
                    label="Override Range"
                    sx={{ marginLeft: 2 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={16}>
                <DataSourceList
                  sources={locations}
                  removeDataSource={removeDataSource}
                  editDataSource={editDataSource}
                  putDataSource={putDataSource}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default ConfigDataSourceSelector;
