import { TableColumnDataTypes } from '@onc/base-components';
import DmasError from 'util/DmasError';
import useGet from 'util/hooks/useDmasAPI/useGet';

const SERVICE_URL = 'internal/devices/compromised';

export type CompromisedAnnotation = {
  annotationHdrId: number;
  resourceTypeId: number;
  resourceTypeName: string;
  resourceId: number;
  resourceName: string;
  startDate: string;
  endDate?: string;
  comment: string;
  createdBy: {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  modifiedBy: {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  createdDate: string;
  modifiedDate: string;
  toBeReviewed: boolean;
  taxons?: {
    taxonId: number;
    taxonomyId: number;
    associatedAnnotationLineId: number;
    attributes?: {
      attributeId: number;
      dataType: TableColumnDataTypes;
      groupId: number;
      groupName: string;
      name: string;
      value: string;
    }[];
    attributeMap: object;
    taxonUrl: string;
    taxonomyCode: string;
    displayText: string;
  }[];
};

export const useGetAllCompromisedAnnotations = () =>
  useGet(SERVICE_URL, {
    operation: undefined,
    transform: (response: {
      data: CompromisedAnnotation[];
      status: number;
      statusText: string;
    }) => {
      if (response.status !== 200) {
        throw new DmasError(response.statusText, response.status);
      }
      return response.data;
    },
  });
