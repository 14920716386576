import { useEffect, useState, FC } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Draggable from 'react-draggable';
import { ContainedButton } from '@onc/composite-components';
import { Close, ExpandMore } from '@onc/icons';
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  TypographyWithTranslation,
} from 'base-components';
import LocationsTree from 'domain/AppComponents/Dashboard/data-source-widget/LocationsTree';
import LocationsWebServiceWithToken from 'domain/services/LocationsWebServiceWithToken';
import { DEFAULT_LOCATION } from './DataSourceWidgetConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(),
      width: 'auto',
      minWidth: '550px',
      maxWidth: '550px',
      position: 'absolute',
      zIndex: 3,
    },
    header: {
      margin: 0,
      cursor: 'pointer',
      height: '15px',
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    minimized: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      marginTop: '-15px',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expanded: {
      transform: 'rotate(180deg)',
      marginLeft: 'auto',
      marginTop: '-15px',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    title: {
      marginTop: '-15px',
    },
    closeButton: {
      marginLeft: theme.spacing(),
      marginTop: '-15px',
    },
    selectButton: {
      marginLeft: '335px',
      marginTop: theme.spacing(),
    },
    treeContainer: {
      height: '100%',
      padding: theme.spacing(),
    },
    content: {
      height: '580px',
      marginBottom: '-35px',
    },
    divider: {
      marginTop: theme.spacing(),
    },
  })
);

type Props = {
  isInitiallyExpanded: boolean;
  onClose: (event: object) => void;
  onSelectNodes: (event: object) => void;
  title: string;
  dateFrom?: Date;
  dateTo?: Date;
  device?: any;
  deviceCategory?: any;
  property?: any;
};

const DraggableLocationTree: FC<Props> = ({
  isInitiallyExpanded,
  onClose,
  onSelectNodes,
  title,
  dateFrom = undefined,
  dateTo = undefined,
  device = undefined,
  deviceCategory = undefined,
  property = undefined,
}) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
  const [selectedNodes, setSelectedNodes] = useState(DEFAULT_LOCATION);
  const [data, setData] = useState(undefined);
  const classes = useStyles();
  const expandClass = isExpanded ? classes.expanded : classes.minimized;
  const bounds = {
    top: 0,
    left: -Infinity,
    right: Infinity,
    bottom: Infinity,
  };

  useEffect(() => {
    const fetchTree = () => {
      LocationsWebServiceWithToken.getTree({
        method: 'get',
        deviceCategoryCode: deviceCategory,
        propertyCode: property,
        deviceCode: device,
        dateFrom,
        dateTo,
      }).then((resources) => {
        setData(resources.data[0].children);
      });
    };
    fetchTree();
  }, [dateFrom, dateTo, device, deviceCategory, property]);

  const onClickSelect = () => {
    onSelectNodes([selectedNodes]);
  };

  return (
    <Draggable
      bounds={bounds}
      handle="#popup-handle"
      positionOffset={{ x: '40vw', y: -58 }}
    >
      <div className={classes.root}>
        <Card>
          <CardContent className={classes.header} id="popup-handle">
            <CardActions disableSpacing>
              <TypographyWithTranslation
                variant="h6"
                className={classes.title}
                translationKey="common.textfields.variable"
                translationOptions={{ variableName: title }}
              />
              <IconButton
                className={expandClass}
                onClick={() => setIsExpanded(!isExpanded)}
                aria-expanded={isExpanded}
                color="inherit"
                id="expand"
                aria-label={isExpanded ? 'Show Less' : 'Show More'}
              >
                <ExpandMore />
              </IconButton>
              <IconButton
                className={classes.closeButton}
                onClick={onClose}
                color="inherit"
                aria-label="Close"
              >
                <Close fontSize="small" />
              </IconButton>
            </CardActions>
          </CardContent>
          <Collapse in={isExpanded} timeout="auto">
            <CardContent className={classes.content}>
              <Grid
                container
                direction="column"
                spacing={3}
                className={classes.treeContainer}
                wrap="nowrap"
              >
                <LocationsTree
                  data={data}
                  setSelectedNodes={setSelectedNodes}
                />
                <Divider className={classes.divider} />
                <CardActions disableSpacing>
                  <ContainedButton
                    className={classes.selectButton}
                    onClick={() => onClickSelect()}
                    disabled={selectedNodes?.hasDeviceData !== 'true'}
                    data-test="data-source-select-button"
                    translationKey="dashboards.selectLocation"
                  />
                </CardActions>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </Draggable>
  );
};

export default DraggableLocationTree;
