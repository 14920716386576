import React, { useEffect, useState } from 'react';
import { Dialog } from 'base-components';
import UserManagementService from 'domain/services/UserManagementService';
import UserOrganizationService from 'domain/services/UserOrganizationService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import UserOrganizationForm from './UserOrganizationForm';
import {
  UserOrganizationRow,
  UserOrganizationSaveValues,
} from './UserOrganizationTableLogic';

type Props = {
  initialValue: Partial<UserOrganizationRow> | null;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  selectedOrganization: { organizationId: number; organizationName: string };
};

const EMPTY_FORM: UserOrganizationSaveValues = {
  user: { name: '', userId: 0 },
  organization: { organizationName: '', organizationId: 0 },
};

const UserOrganizationDialog: React.FC<Props> = ({
  initialValue,
  open,
  onClose,
  onSuccess,
  selectedOrganization,
}) => {
  const [userOrganizationForm, setUserOrganizationForm] =
    useState<UserOrganizationSaveValues>(EMPTY_FORM);
  const { onInfo, onError } = useSnackbars();
  const [isCreate, setIsCreate] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    if (!initialValue) {
      setIsCreate(true);
      setUserOrganizationForm({
        ...EMPTY_FORM,
        organization: {
          organizationName: selectedOrganization.organizationName,
          organizationId: selectedOrganization.organizationId,
        },
      });
      return;
    }
    setIsCreate(false);
    setUserOrganizationForm({
      user: {
        name: `${initialValue.firstname} ${initialValue.lastname}`,
        userId: initialValue.userId,
      },
      organization: {
        organizationName: initialValue.organizationName,
        organizationId: initialValue.organizationId,
      },
    });
  }, [initialValue, selectedOrganization]);

  useEffect(() => {
    setLoadingUsers(true);
    UserManagementService.getUsersInGroup(8)
      .then((response) => {
        const usersMapped = response.map((user) => ({
          userId: user.dmasUserId,
          name: `${user.firstname} ${user.lastname}`,
        }));
        setUsers(usersMapped);
        setLoadingUsers(false);
      })
      .catch(() => {
        onError('Failed to fetch users');
        setLoadingUsers(false);
      });
  }, [onError]);

  const handleSubmit = (formData: UserOrganizationSaveValues) => {
    const { userId } = formData.user;
    const { organizationId } = selectedOrganization;

    if (!userId || !organizationId) {
      onError('Both User and Organization are required');
      return;
    }

    UserOrganizationService.createUserOrganization(userId, organizationId)
      .then(() => {
        onInfo('User Organization saved successfully!');
        onSuccess();
      })
      .catch(() => {
        onError('Error while saving user organization');
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <UserOrganizationForm
        users={users}
        organizations={[]}
        initialValue={userOrganizationForm}
        loadingUsers={loadingUsers}
        onClose={onClose}
        onSubmit={handleSubmit}
        isCreate={isCreate}
      />
    </Dialog>
  );
};

export default UserOrganizationDialog;
