import { useState } from 'react';
import { UseQueryResult } from '@onc/service';
import { TraceData } from 'domain/AppComponents/charts/TimeSeriesChart';
import useDeepCompareEffect from 'domain/hooks/useDeepCompareEffect';
import { TraceControlMap } from '../types/TimeSeriesScalarDataChart.types';

const useTraceList = (
  traceResults: UseQueryResult<TraceData, Error>[],
  devicesResult: UseQueryResult[],
  isLoading: boolean,
  traceControlMap: TraceControlMap = new Map()
) => {
  const [traceList, setTraceList] = useState<TraceData[]>(() => []);

  useDeepCompareEffect(() => {
    if (!isLoading) {
      setTraceList(
        traceResults
          .filter((traceResult) => traceResult.isSuccess)
          .map((traceResult) => traceResult.data as TraceData)
          .map((trace) => {
            const { isAverageEnabled, isMinMaxEnabled } = traceControlMap?.get(
              trace.sourceIdentifier
            ) ?? {
              isAverageEnabled: true,
              isMinMaxEnabled: true,
            };

            return {
              ...trace,
              isAverageEnabled,
              isMinMaxEnabled,
            };
          })
      );
    } else {
      setTraceList([]);
    }
  }, [isLoading, devicesResult, traceResults, traceControlMap]);

  return traceList;
};

export default useTraceList;
