import { useEffect } from 'react';
import { ClosedCaption } from '@onc/icons';
import { NestedMenuItem } from 'base-components';

type CaptionsMenuItemProps = {
  options: TextTrack[];
  currentTrack: TextTrack;
  onClick: (val: TextTrack) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
};

const CaptionsMenuItem = ({
  options,
  currentTrack,
  containerRef = undefined,
  onClick,
}: CaptionsMenuItemProps) => {
  useEffect(() => {
    // set all options to have mode = 'hidden'
    options.forEach((option) => {
      // eslint-disable-next-line no-param-reassign
      option.mode = 'hidden';
    });
    // set the current track to have mode = 'showing'
    if (currentTrack) {
      const track = options.find((option) => option.id === currentTrack.id);
      if (track) {
        track.mode = 'showing';
      }
    }
  }, [currentTrack, options]);

  const menuOptions = [
    {
      value: null,
      label: 'Off',
      key: 'off',
      selected: currentTrack === null,
    },
    ...options.map((track) => ({
      value: track,
      label: track.label,
      key: track.id,
      selected: track.id === currentTrack?.id,
    })),
  ];
  return (
    <NestedMenuItem
      value="captions"
      label="Captions"
      name="captions"
      container={containerRef}
      IconComponent={<ClosedCaption />}
      onClick={(e) => {
        onClick(e.target.value);
      }}
      childrenItems={menuOptions}
    />
  );
};

// Used by AnchoredHiddenMenu to override the onClick
CaptionsMenuItem.displayName = 'MenuItem';

export default CaptionsMenuItem;
