import { makeStyles } from '@mui/styles';

import { useForm } from 'react-hook-form';
import {
  SaveButton,
  CancelButton,
  ContainedButton,
} from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import ItemTypeDropdown from 'domain/AppComponents/dropdowns/ItemTypeDropdown';
import OrganizationDropdown from 'domain/AppComponents/dropdowns/OrganizationDropdown';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import Environment from 'util/Environment';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', width: '100%' },
  leftJustify: { display: 'flex', justifyContent: 'flex-start' },
  leftAlign: { marginLeft: theme.spacing(1) },
  rightAlign: { display: 'flex', justifyContent: 'flex-end' },
}));

type ItemConfigProps = {
  title: string;
  onSave: () => void;
  onCancel: () => void;
  item?: {
    itemId: number;
    modifyBy: string;
    modifyDate: string;
    name: string;
    supplier: string;
    supplierId: number;
    type: string;
    typeId: number;
  };
};

type itemConfigForm = {
  itemId: number;
  itemType: { label: string; value?: number };
  supplier: { label: string; value?: number };
  itemName: string;
  modifyDate: string;
};

const ItemConfig = ({
  title,
  onSave,
  onCancel,
  item = {
    name: undefined,
    supplier: undefined,
    supplierId: undefined,
    type: undefined,
    itemId: undefined,
    typeId: undefined,
    modifyBy: undefined,
    modifyDate: undefined,
  },
}: ItemConfigProps) => {
  const classes = useStyles();

  const type: () => JSX.Element = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="itemType"
            labelText="Item Type"
            valueText={item.type}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <ItemTypeDropdown
          fullWidth
          translationKey="cables.itemType"
          name="itemType"
        />
      </Grid>
    );
  };

  const itemId: () => JSX.Element = () => {
    if (item.itemId !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="itemId"
            labelText="Item ID"
            valueText={item.itemId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const supplier: () => JSX.Element = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="supplier"
            labelText="Supplier"
            valueText={item.supplier}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <OrganizationDropdown
          translationKey="cables.supplier"
          name="supplier"
          fullWidth
          useNone
        />
      </Grid>
    );
  };

  const itemName: () => JSX.Element = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="itemName"
            labelText="Item Name"
            valueText={item.name}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <FormTextField
          name="itemName"
          translationKey="common.textfields.name"
          fullWidth
          defaultValue={item.name}
        />
      </Grid>
    );
  };

  const modifyDate: () => JSX.Element = () => {
    if (item.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyDate"
            labelText="Modify Date"
            valueText={item.modifyDate}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const renderButtons: () => JSX.Element = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <ContainedButton
          translationKey="common.buttons.done"
          onClick={onCancel}
        />
      );
    }
    return (
      <>
        <CancelButton onClick={onCancel} />
        <SaveButton type="submit" />
      </>
    );
  };

  const formMethods = useForm<itemConfigForm>({
    defaultValues: {
      itemId: item.itemId,
      itemType: { label: item.type || '', value: item.typeId },
      supplier: {
        label: item.supplier || '',
        value: item.supplierId,
      },
      itemName: item.name,
      modifyDate: item.modifyDate,
    },
    mode: 'onBlur',
  });

  return (
    <Dialog open fullWidth>
      <DialogTitle>
        {Environment.getDmasUserPrivilege() === 'RO' ? 'View Item' : title}
      </DialogTitle>
      <DialogContent>
        <Form formMethods={formMethods} onSubmit={onSave}>
          <Grid container spacing={1} className={classes.root}>
            {itemId()}
            {type()}
            {supplier()}
            {itemName()}
            {modifyDate()}
          </Grid>
          <Grid item className={classes.rightAlign}>
            {renderButtons()}
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ItemConfig;
