import DateFormatUtils from 'util/DateFormatUtils';
import DomUtil from 'util/DomUtils';
import {
  generateLocationString,
  getAnnotationContentWidth,
} from './AnnotationLine';

const ANNOTATION_PADDING_WIDTH = 16;
const SMALL_TEXT_EXPECTED_LINE_HEIGHT = 17;
const SMALL_TEXT_ACTUAL_LINE_HEIGHT = 24;
const SCROLLBAR_WIDTH = 17;

const primaryTextStyle = {
  fontSize: '0.875rem',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: '400',
  lineHeight: '1.43',
  letterSpacing: '0.01071em',
};

const secondaryTextStyle = {
  fontSize: '0.74rem',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: '400',
  lineHeight: '1.5',
  letterSpacing: '0.03333em',
};

const calculatePrimaryTextHeight = (text, width) =>
  DomUtil.calculateTextHeight(text, width, {
    style: primaryTextStyle,
    id: 'primary-text-height-div',
    modifier: undefined,
  });

const calculateSecondaryTextHeight = (text, width) =>
  DomUtil.calculateTextHeight(text, width, {
    style: secondaryTextStyle,
    id: 'secondary-text-height-div',
    modifier: SMALL_TEXT_ACTUAL_LINE_HEIGHT / SMALL_TEXT_EXPECTED_LINE_HEIGHT,
  });

const getAnnotationWidth = (width, annotation, options) => {
  const {
    displayFullTimestamp,
    displayCopyLink,
    displayEditIcon,
    displayDeleteIcon,
    displayVoteIcons,
  } = options;

  let currWidth = width;
  // Outer left padding
  currWidth -= ANNOTATION_PADDING_WIDTH;
  // Annotation width
  currWidth -= getAnnotationContentWidth(
    [
      displayCopyLink?.checked,
      displayEditIcon?.checked,
      displayDeleteIcon?.checked,
    ],
    displayFullTimestamp?.checked,
    displayVoteIcons?.checked &&
      annotation.taxons &&
      annotation.taxons[0].taxonomyCode === 'WoRMS'
  );
  // Outer right padding
  currWidth -= ANNOTATION_PADDING_WIDTH;
  // Assumes it has scrollbar, if no scroll bar needed spacing might be wonkey
  currWidth -= SCROLLBAR_WIDTH;
  return currWidth;
};

const decideAnnotationRowHeight = (item, width, options) => {
  const {
    displayCreatedBy,
    displayModifiedBy,
    displayPositionalData,
    displayTaxonAttributes,
    displayFullTimestamp,
  } = options;

  const annotation = { ...item };
  // Top/Bottom padding (8/8)
  let height = ANNOTATION_PADDING_WIDTH;
  const currWidth = getAnnotationWidth(width, annotation, options);

  // Calculate height needed for comment if enabled and exist
  if (annotation.comment === '') {
    if (!annotation.taxons)
      height += calculatePrimaryTextHeight('-', currWidth);
  } else {
    height += calculatePrimaryTextHeight(annotation.comment, currWidth);
  }

  // Calculate height needed for positional data if enabled and exist
  if (
    displayPositionalData?.checked &&
    annotation.lat !== 0 &&
    annotation.lon !== 0
  ) {
    const positionString = generateLocationString(
      annotation.lat,
      annotation.lon,
      annotation.heading,
      annotation.depth
    );
    height += calculateSecondaryTextHeight(positionString, currWidth);
  }

  // Calculate height needed for taxons if enabled and exist
  if (annotation.taxons && annotation.taxons[0].displayText) {
    height += calculatePrimaryTextHeight(
      `${annotation.taxons[0].displayText} [${annotation.taxons[0].taxonomyCode}]`,
      currWidth
    );
  }

  // Calculate height needed for attributes if enabled and exist
  if (displayTaxonAttributes?.checked) {
    if (annotation.taxons && annotation.taxons[0].attributes) {
      annotation.taxons[0].attributes.forEach((attribute) => {
        const attributeText = `${attribute.name}: ${attribute.value}`;
        height += calculatePrimaryTextHeight(attributeText, currWidth);
      });
    }
  }
  let displayCreatedOrModifyBy = false;
  // Calculate height needed for created by if enabled and exist
  if (displayCreatedBy?.checked) {
    displayCreatedOrModifyBy = true;
    const createdByText = `Created By: ${annotation.createdBy.firstName} ${
      annotation.createdBy.lastName
    } (${DateFormatUtils.formatDate(annotation.createdDate, 'full')})`;
    height += calculateSecondaryTextHeight(createdByText, currWidth);
  }

  // Calculate height needed for modified by if enabled and exist
  if (
    displayModifiedBy?.checked &&
    annotation.createdDate !== annotation.modifiedDate
  ) {
    displayCreatedOrModifyBy = true;
    const modifiedByText = `Modified By: ${annotation.modifiedBy.firstName} ${
      annotation.modifiedBy.lastName
    } (${DateFormatUtils.formatDate(annotation.modifiedDate, 'full')})`;
    height += calculateSecondaryTextHeight(modifiedByText, currWidth);
  }
  if (displayFullTimestamp?.checked && !displayCreatedOrModifyBy) {
    // The fulltimestamp often cuts off some of the comment so the height needs to be expanded.
    height += 6;
  }
  // Border width
  height += 1;

  return height;
};

const decideRowHeight = ({ index }, width, filteredListItems, options) => {
  const item = filteredListItems[index];
  if (!!options && item.annotationId) {
    return decideAnnotationRowHeight(item, width, options);
  }
  return 0;
};

export default decideRowHeight;
