import { DmasUser } from '@onc/domain-services';
import { get } from 'util/WebRequest';

export interface UnitOfMeasure {
  unitOfMeasureId: number;
  unitOfMeasureName: string;
  name?: string;
  conversionFormula?: string;
  comments?: string;
  scaleReference?: string;
  reverseConversionFormula?: string;
  oncstdUnitOfMeasureId?: number;
  cfEquivalentName?: string;
  erddapScaleFactor?: string;
  erddapAddOffset?: string;
  modifyBy?: DmasUser;
  modifyDate?: string;
}

class UnitOfMeasureService {
  static getAll = async (): Promise<UnitOfMeasure[]> => {
    const response = await get('/internal/sensors/units');
    return response.data;
  };
}

export default UnitOfMeasureService;
