import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Route } from 'react-router-dom';
import SiteDeviceMaintenance from './SiteDeviceMaintenance';

class SiteDeviceMaintenanceContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const params = this.parseURLParams();
    this.state = {
      siteDeviceId: Number(params.siteDeviceId),
      deviceId: Number(params.deviceId),
      siteId: Number(params.siteId),
      isFromSite: String(params.isFromSite),
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => this.updatePage());
  }

  parseURLParams = () => {
    const { history } = this.props;

    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  };

  updatePage() {
    const params = this.parseURLParams();
    this.setState({
      siteDeviceId: Number(params.siteDeviceId),
      deviceId: Number(params.deviceId),
      siteId: Number(params.siteId),
      isFromSite: String(params.isFromSite),
    });
  }

  renderSiteDevicePage = () => {
    const {
      siteId,
      siteDeviceId,
      deviceId,
      isFromSite: fromSiteParam,
    } = this.state;
    const { history } = this.props;
    let isFromSite;
    if (fromSiteParam === 'Y') {
      isFromSite = true;
    } else {
      // default prop
      isFromSite = false;
    }

    return (
      <SiteDeviceMaintenance
        siteId={siteId}
        siteDeviceId={siteDeviceId}
        deviceId={deviceId}
        isFromSite={isFromSite}
        history={history}
      />
    );
  };

  render() {
    const { match } = this.props;

    return (
      <Route exact path={`${match.path}`} render={this.renderSiteDevicePage} />
    );
  }
}

export default SiteDeviceMaintenanceContainer;
