import { VFC } from 'react';
import { MenuList, Theme } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import {
  Brush,
  MenuIcon,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@onc/icons';
import {
  AnchoredHiddenMenu,
  AppBar,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
} from 'base-components';
import Logout from './Logout';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuItem: {
    paddingRight: theme.spacing(2),
  },
});

interface AppMenuBarProps extends WithStyles<typeof styles> {
  title: string;
  handleDrawerOpen: () => void;
  onChangeTheme: (theme: string) => void;
  theme: string;
  themeMap: Map<string, Theme>;
}

const AppMenuBar: VFC<AppMenuBarProps> = ({
  title,
  handleDrawerOpen,
  onChangeTheme,
  theme,
  themeMap,
  classes,
}: AppMenuBarProps) => (
  <div className={classes.root}>
    <AppBar position="static">
      <Toolbar>
        <IconButton
          color="inherit"
          id="open-drawer"
          className={classes.menuButton}
          onClick={handleDrawerOpen}
          aria-label="Open Menu"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.title}>
          {title}
        </Typography>
        <AnchoredHiddenMenu
          variant="custom"
          customAnchor={
            <IconButton
              color="inherit"
              id="open-theme-menu"
              className={classes.menuButton}
              onClick={handleDrawerOpen}
              aria-label="Change Theme"
            >
              <Brush />
            </IconButton>
          }
          getContentAnchorEl={undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuList>
            {Array.from(themeMap.entries()).map(([name]) => (
              <MenuItem key={name} onClick={() => onChangeTheme(name)}>
                <ListItemIcon>
                  {name === theme ? (
                    <RadioButtonChecked color="secondary" />
                  ) : (
                    <RadioButtonUnchecked />
                  )}
                </ListItemIcon>
                <ListItemText className={classes.menuItem}>{name}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </AnchoredHiddenMenu>
        <Logout />
      </Toolbar>
    </AppBar>
  </div>
);

export default withStyles(styles)(AppMenuBar);
