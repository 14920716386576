import { PlaylistLineInfo } from 'domain/services/PlaylistService';
import DateUtils from 'util/DateUtils';
import * as WebRequest from 'util/WebRequest';

export interface PlaylistLine {
  startDate?: string;
  endDate?: string;
  dateFrom?: string;
  dateTo?: string;
  playlistLineId: number;
  playlistLineName: string;
  playlistHdrId: number;
  resourceTypeId: number;
  resourceId: number;
  sequence: number;
  quality: string;
  description?: string;
}

/** Class PlaylistLineService which holds the web services for PlaylistLine. */
class PlaylistLineService {
  /**
   * Creates PlaylistLines for a given PlaylistHdr
   *
   * @param {number} playlistHdrId
   * @param {object} playlistLines - An object with the properties needed for
   *   playlist lines.
   * @param {string} [playlistLines.playlistLineName] - Optional name of the
   *   playlist line.
   * @param {number} playlistLines.resourceId - ID of the resource.
   * @param {number} playlistLines.resourceTypeId - Type ID of the resource.
   * @param {string} playlistLines.startDate - Start date of the usage period.
   * @param {string} playlistLines.endDate - End date of the usage period.
   * @param {string} [playlistLines.quality] - Optional quality description.
   * @returns {Promise}
   * @throws {Error}
   */
  static async create(
    playlistHdrId: number,
    playlistLines: PlaylistLine[]
  ): Promise<{ playlistLines: PlaylistLine[]; doAllClipsHaveVideo: boolean }> {
    let playlistLinesParam = [];
    if (playlistLines) {
      try {
        playlistLinesParam = playlistLines.map((playlistLine) => ({
          playlistLineName: playlistLine.playlistLineName,
          description: playlistLine.description,
          resourceId: playlistLine.resourceId.toString(),
          resourceTypeId: playlistLine.resourceTypeId.toString(),
          startDate: playlistLine.dateFrom || playlistLine.startDate,
          endDate: playlistLine.dateTo || playlistLine.endDate,
          quality: playlistLine.quality,
        }));
      } catch (error) {
        throw new Error('Invalid playlist segment information.');
      }
    }

    const params = {
      playlistLines: JSON.stringify(playlistLinesParam),
    };
    const response = await WebRequest.post(
      `internal/playlists/${playlistHdrId}/clips`,
      params,
      { headers: { 'content-type': 'application/json' } }
    );

    return response.data;
  }

  /**
   * Updates a playlist line by sending a POST request with playlist details.
   *
   * @param {object} params - The parameters for updating the playlist line.
   * @param {string} params.playlistLineId - The ID of the playlist line
   * @param {string} [params.startDate] - The starting date of the playlist
   *   line's active period (optional).
   * @param {string} [params.endDate] - The ending date of the playlist line's
   *   active period (optional).
   * @param {string} [params.playlistLineName] - The name of the playlist line
   *   (optional).
   * @param {string} [params.description] - A description of the playlist line
   *   (optional).
   * @param {string} [params.quality] - The quality standard of the playlist
   *   line (optional).
   * @returns {Promise} A promise that resolves when the update is completed.
   */
  static async update(params: {
    playlistLineId: number;
    startDate?: string;
    endDate?: string;
    playlistLineName?: string;
    description?: string;
    quality?: string;
  }): Promise<PlaylistLine> {
    const response = await WebRequest.patch(
      `internal/playlists/clips/${params.playlistLineId}`,
      params,
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  /** Reorders playlist lines in a playlist */
  static async reorder(
    playlistHdrId: number,
    playlistLineIds: number[]
  ): Promise<{ wasChanged: boolean; playlistLines: PlaylistLine[] }> {
    const response = await WebRequest.patch(
      `internal/playlists/${playlistHdrId}/clips`,
      { playlistLineIds: playlistLineIds.toString() },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  /**
   * Get all PlaylistLines for a given PlaylistHdr
   *
   * @param {number} playlistHdrId
   */
  static async getMany(playlistHdrId: number): Promise<PlaylistLine[]> {
    const response = await WebRequest.get(
      `internal/playlists/${playlistHdrId}/clips`
    );
    return response.data;
  }

  /**
   * Get additional metadata for a given playlistLine
   *
   * @param {number} playlistLineId
   * @returns Cruise and dive info or fixed camera info
   */
  static async getMetadata(playlistLineId: number): Promise<{
    organizationId?: number;
    expeditionName?: string;
    diveName?: string;
    diveId?: number;
    resourceTypeId?: number;
    siteLocation?: string;
    siteName?: string;
  }> {
    const response = await WebRequest.get(
      `internal/playlists/clips/${playlistLineId}`
    );
    return response.data;
  }

  /**
   * Deletes multiple PlaylistLines
   *
   * @param {number} playlistHdrId
   * @param {number} playlistLineIds
   * @returns {Promise}
   */
  static async deletePlaylistLines(
    playlistHdrId: number,
    playlistLineIds: number[]
  ): Promise<PlaylistLine[]> {
    const response = await WebRequest.deleteImpl(
      `internal/playlists/${playlistHdrId}/clips`,
      { playlistLineIds: playlistLineIds.toString() },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static playlistLinesToPlaylistLineInfo(
    playlistLines: PlaylistLine[]
  ): PlaylistLineInfo[] {
    return playlistLines.map((playlistLine) => ({
      playlistLineId: playlistLine.playlistLineId,
      playlistLineName: playlistLine.playlistLineName,
      playlistHdrId: playlistLine.playlistHdrId,
      dateFrom: playlistLine.dateFrom,
      dateTo: playlistLine.dateTo,
      duration: DateUtils.getDurationInSeconds(
        playlistLine.dateFrom,
        playlistLine.dateTo
      ),
      resource: {
        name: 'Unknown',
        resourceId: playlistLine.resourceId,
        resourceTypeId: playlistLine.resourceTypeId,
      },
    }));
  }

  static getValidClipId = (
    lines: PlaylistLine[],
    playlistLineId: number,
    onError
  ) => {
    if (lines === undefined || lines === null || isNaN(playlistLineId)) {
      return undefined;
    }
    const validClip = lines.find(
      (playlistLine) => playlistLine.playlistLineId === playlistLineId
    );

    if (!isNaN(playlistLineId) && validClip === undefined) {
      onError(
        `ClipId ${playlistLineId} not found in playlist. Playing first clip.`
      );
      return undefined;
    }
    return validClip.playlistLineId;
  };
}

export default PlaylistLineService;
