import { useState, useEffect } from 'react';
import { Badge } from '@mui/material';
import { withStyles, withTheme } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  CancelButton,
  ContainedButton,
  TextButton,
} from '@onc/composite-components';
import { FilterList } from '@onc/icons';
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from 'base-components';
import DeviceService from 'domain/AppComponents/Dashboard/DeviceService';
import DeviceFilterService from 'domain/services/DeviceFilterService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DataSourceFilters from './DataSourceFilters';
import DataSourceTree from './DataSourceTree';
import PropertyMap from './SearchTreePropertyMap';
import DataAvailabilityChartV2 from '../charts/DataAvailabilityChartV2';

const styles = (theme) => ({
  dialog: {
    height: '90vh',
  },
  fullHeight: {
    height: '100%',
  },
  filterButton: {
    margin: '0',
    paddingLeft: '0',
    paddingTop: theme.spacing(1),
  },
  flexGrow: {
    flex: '1 1 auto',
  },
});

const DataSourceSelector = ({
  classes,
  editData,
  multiple = false,
  onCloseDialog,
  onError,
  onSelectNodes,
  propertyPreset,
  showDialog,
  title,
  treeTypePreset,
  theme,
}) => {
  DataSourceSelector.propTypes = {
    classes: PropTypes.shape({
      dialog: PropTypes.string,
      flexGrow: PropTypes.string,
      fullHeight: PropTypes.string,
    }).isRequired,
    editData: PropTypes.shape({}).isRequired,
    multiple: PropTypes.bool,
    onCloseDialog: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onSelectNodes: PropTypes.func.isRequired,
    propertyPreset: PropTypes.arrayOf(PropTypes.string).isRequired,
    showDialog: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    treeTypePreset: PropTypes.string.isRequired,
    theme: PropTypes.shape().isRequired,
  };

  const getFilteredSensorTypes = (properties) => {
    const newSensorTypes = [];
    properties.forEach((property) => {
      const propertyObj = PropertyMap.find((item) => item.value === property);
      newSensorTypes.push(...propertyObj.sc);
    });
    return newSensorTypes;
  };

  const getFilteredDeviceCategories = (properties) => {
    const newDeviceCategories = [];

    properties.forEach((property) => {
      const propertyObj = PropertyMap.find((item) => item.value === property);
      newDeviceCategories.push(...propertyObj.dc);
    });
    return newDeviceCategories;
  };

  // State Variables
  const [treeType, setTreeType] = useState(treeTypePreset);
  const [properties, setProperties] = useState(propertyPreset);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [deviceMap, setDeviceMap] = useState([]);
  const [depthFrom, setDepthFrom] = useState(-500);
  const [depthTo, setDepthTo] = useState(2400);
  const [dateSelection, setDateSelection] = useState('all');
  const [siteDevices, setSiteDevices] = useState(undefined);
  const [fixedDepthMap, setFixedDepthMap] = useState([]);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [datePreset, setDatePreset] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [showFilters, setShowFilters] = useState(false);
  const [sensorTypes, setSensorTypes] = useState(
    getFilteredSensorTypes(properties)
  );
  const [deviceCategories, setDeviceCategories] = useState(
    getFilteredDeviceCategories(properties)
  );

  const selectNode = (id) => {
    const newSelection = [...selectedNodes];
    if (selectedNodes.includes(id)) {
      newSelection.splice(
        newSelection.findIndex((element) => element === id),
        1
      );
      setSelectedNodes(newSelection);
    } else if (multiple) {
      setSelectedNodes([...newSelection, id]);
    } else {
      setSelectedNodes([id]);
    }
  };

  const onDatePresetChange = (event) => {
    const dateTo = new Date();
    let dateFrom = moment(dateTo);
    switch (event.target.value) {
      case 'day':
        dateFrom = dateFrom.subtract(1, 'Day');
        break;
      case 'week':
        dateFrom = dateFrom.subtract(1, 'Week');
        break;
      case 'month':
        dateFrom = dateFrom.subtract(1, 'Month');
        break;
      case 'year':
        dateFrom = dateFrom.subtract(1, 'Year');
        break;
      default:
        break;
    }
    setStartDate(dateFrom.toDate());
    setEndDate(dateTo);
    setDatePreset(event.target.value);
  };

  const renderFilters = () => (
    <DataSourceFilters
      propertyOptions={PropertyMap.map((item) => item.value)}
      onStartDateChange={setStartDate}
      onEndDateChange={setEndDate}
      onDatePresetChange={onDatePresetChange}
      onChangeTreeType={setTreeType}
      onPropertiesChange={setProperties}
      onMinDepthFieldChange={setDepthFrom}
      onMaxDepthFieldChange={setDepthTo}
      onDateSelectionChange={setDateSelection}
      treeTypePreset={treeTypePreset}
      propertyPreset={propertyPreset}
      startDate={startDate}
      endDate={endDate}
      datePreset={datePreset}
      treeType={treeType}
      properties={properties}
      depthFrom={depthFrom}
      depthTo={depthTo}
      dateSelection={dateSelection}
    />
  );

  const renderTree = () => (
    <DataSourceTree
      data={data}
      editData={editData}
      selectedNodes={selectedNodes.map((node) => node.nodeId)}
      selectNode={selectNode}
      treeType={treeType}
      startDate={startDate}
      endDate={endDate}
      deviceCategories={deviceCategories}
      sensorTypes={sensorTypes}
      siteDevices={siteDevices}
      className={classes.fullHeight}
    />
  );

  // Gets the tree data on first load
  useEffect(() => {
    const getTree = () => {
      DeviceService.getSearchTree()
        .then((resources) => {
          setData(resources[0].els);
        })
        .catch((error) => {
          onError(error);
        });
    };
    if (showDialog && !data) {
      getTree();
    }
  }, [showDialog, onError, data]);

  // Gets the depth map for fixed site devices
  useEffect(() => {
    const getFixedDepthMap = () => {
      DeviceFilterService.getFixedDepthMap()
        .then((response) => {
          if (typeof response.data === 'string') {
            setFixedDepthMap(JSON.parse(response.data).depths);
          } else {
            setFixedDepthMap(response.data.depths);
          }
        })
        .catch((error) => {
          onError(error);
        });
    };
    getFixedDepthMap();
  }, [onError]);

  // Resets the date filter and sets it to the current date if "Currently Deployed" is selected
  useEffect(() => {
    setStartDate(undefined);
    setEndDate(undefined);
    setDatePreset(undefined);
    if (dateSelection === 'current') {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }, [dateSelection]);

  // Gets Site Devices matching date / depth filters
  useEffect(() => {
    const getValidSiteDevicesFromDepthMap = () => {
      const validSiteDeviceIds = [];
      fixedDepthMap.forEach((siteDevice) => {
        if (siteDevice.depth < depthTo && siteDevice.depth >= depthFrom) {
          validSiteDeviceIds.push(siteDevice.siteDeviceId);
        }
      });
      return validSiteDeviceIds;
    };

    const getSiteDevices = () => {
      let matchingSiteDevices = getValidSiteDevicesFromDepthMap();
      DeviceFilterService.getFilteredSiteDevices(
        depthFrom,
        depthTo,
        startDate ? startDate.toISOString() : undefined,
        endDate ? endDate.toISOString() : undefined
      )
        .then((response) => {
          const { mobileSiteDeviceIds, timeSiteDeviceIds } = response.data;
          if (mobileSiteDeviceIds) {
            matchingSiteDevices =
              matchingSiteDevices.concat(mobileSiteDeviceIds);
          }
          if (timeSiteDeviceIds) {
            matchingSiteDevices = timeSiteDeviceIds.filter((element) =>
              matchingSiteDevices.includes(element)
            );
          }
          return setSiteDevices(matchingSiteDevices);
        })
        .catch((error) => onError(error));
    };
    if (fixedDepthMap.length > 0 && (depthTo <= 2000 || depthFrom >= 0)) {
      getSiteDevices();
    }
  }, [depthTo, depthFrom, startDate, endDate, onError, fixedDepthMap]);

  // Updates the Device Categories / Sensor Types based on the Properties Filter
  useEffect(() => {
    setDeviceCategories(getFilteredDeviceCategories(properties));
    setSensorTypes(getFilteredSensorTypes(properties));
  }, [properties]);

  useEffect(() => {
    const newDeviceMap = [];
    if (treeType === 'instrumentsByLocation') {
      selectedNodes.forEach((node) => {
        node.els.forEach((siteDevice) => {
          newDeviceMap.push({
            dataSourceType: 'location',
            dataProductCode: properties[0] === 'Audio' ? 'AD' : undefined,
            extensions:
              properties[0] === 'Video'
                ? ['mov', 'avi', 'mp4', 'ogg', 'mpg', 'mp2']
                : undefined,
            deviceCategoryCode: siteDevice.deviceCategoryCode,
            locationCode: siteDevice.stationCode,
          });
        });
      });
    }
    if (treeType === 'propertiesByLocation') {
      selectedNodes.forEach((node) => {
        newDeviceMap.push({
          dataSourceType: 'location',
          dataProductCode: 'LF',
          extensions: ['txt'],
          deviceCategoryCode: node.deviceCategoryCode,
          locationCode: node.stationCode,
        });
      });
    }
    setDeviceMap(newDeviceMap);
  }, [properties, selectedNodes, treeType]);

  const onClickSelect = () => {
    onSelectNodes(selectedNodes);
    closeDialog();
  };

  const closeDialog = () => {
    setSelectedNodes([]);
    onCloseDialog();
  };

  const getActiveFilters = () => {
    let numActiveFilters = 0;
    if (
      (!propertyPreset || propertyPreset.length === 0) &&
      properties.length > 0
    ) {
      numActiveFilters += 1;
    }
    if (dateSelection !== 'all') {
      numActiveFilters += 1;
    }
    if (depthFrom >= 0 || depthTo <= 2000) {
      numActiveFilters += 1;
    }

    return numActiveFilters;
  };

  const renderFilterButton = () => (
    <Badge
      badgeContent={getActiveFilters()}
      color="secondary"
      data-test="filter-badge"
    >
      <TextButton
        translationKey="common.buttons.filter"
        startIcon={
          <FilterList
            style={
              showFilters
                ? { transform: 'rotate(180deg)' }
                : { transform: 'rotate(0deg)' }
            }
          />
        }
        onClick={() => setShowFilters(!showFilters)}
      />
    </Badge>
  );

  const renderAvailability = () => (
    <Collapse in={selectedNodes.length > 0}>
      <DataAvailabilityChartV2 dataSources={deviceMap} />
    </Collapse>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={showDialog}
      onClose={closeDialog}
      classes={{ paperScrollPaper: classes.dialog }}
    >
      <DialogTitle id="data-source-selection">{title}</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={3}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <Grid item style={{ paddingTop: theme.spacing(2) }}>
            {renderFilterButton()}
            <Collapse in={showFilters}>{renderFilters()}</Collapse>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item className={classes.flexGrow}>
            {renderTree()}
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>{renderAvailability()}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={closeDialog} />
        <ContainedButton
          onClick={() => onClickSelect()}
          disabled={selectedNodes.length === 0}
          data-test="data-source-select-button"
          translationKey="dashboards.dataSourceSelect"
          translationOptions={{
            nodesText: multiple ? ` (${selectedNodes.length})` : '',
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withTheme(withStyles(styles)(withSnackbars(DataSourceSelector)));
