// A functional component that accepts a list of playlists and renders them in a list.

import { useContext, useEffect, useState } from 'react';

import * as React from 'react';
import { LinearProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { ContainedButton, OutlinedButton } from '@onc/composite-components';
import { Copy, GetApp } from '@onc/icons';
import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  Typography,
} from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import { EditIconButton } from 'domain/AppComponents/IconButtons';
import Thumbnail from 'domain/Apps/playlist-management/Thumbnail';
import PlaylistLineService, {
  PlaylistLine,
} from 'domain/services/PlaylistLineService';
import { Playlist } from 'domain/services/PlaylistService';
import DraggableList from 'library/CompositeComponents/list-container/DraggableList';
import DateUtils from 'util/DateUtils';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import PlaylistContext from './PlaylistContext';
import SeaTubeDeleteButton from '../SeaTubeDeleteButton';
import UserDetailsContext from '../UserDetailsContext';

const useStyles = makeStyles((theme: Theme) => ({
  highlightedListItem: {
    '&$selected': {
      backgroundColor: theme.palette.secondary.light, // e.g., 'red' or '#ff4444'
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.highlight.main,
    },
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 99,
  },
  thumbnail: {
    marginRight: theme.spacing(2),
  },
  selected: {}, // Empty on purpose. Needed to increase specificity.
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    position: 'sticky',
    bottom: 0,
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  },
  flexButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    whiteSpace: 'nowrap',
  },
  description: {
    overflow: 'hidden',
    display: '-webkit-box',

    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'pre-line',
  },
  dragHandle: {
    margin: theme.spacing(),
  },
  errorContainer: {
    height: 'inherit',
    marginTop: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
}));

type Props = {
  playlist: Playlist;
  playlistLines: PlaylistLine[];
  selectedPlaylistLineId: number;
  onClick: (playlistLineId: number) => void;
  onEditClip: (playlistLine: PlaylistLine) => void;
  onDownloadRequest: (checkedClips: PlaylistLine[]) => void;
  onCopyToPlaylistRequest: (checkedClips: PlaylistLine[]) => void;
};

type DraggablePlaylistLine = PlaylistLine & { id: string };

const PlaylistList: React.FC<Props> = ({
  playlist,
  playlistLines,
  selectedPlaylistLineId,
  onClick,
  onEditClip,
  onDownloadRequest,
  onCopyToPlaylistRequest,
}: Props) => {
  const classes = useStyles();
  const { userId } = useContext(UserDetailsContext);
  const { onError } = useSnackbars();
  const [selectedClips, setSelectedClips] = useState<PlaylistLine[]>([]);
  const [showDeleteClipsDialog, setShowDeleteClipsDialog] = useState(false);
  const [draggablePlaylistLines, setDraggablePlaylistLines] = useState<
    DraggablePlaylistLine[]
  >([]);
  const [, loadingReorder, reorderPlaylistLines] = useWebService({
    method: PlaylistLineService.reorder,
  });

  const [, , deletePlaylistLines] = useWebService({
    method: PlaylistLineService.deletePlaylistLines,
  });

  const { refreshPlaylistLines } = useContext(PlaylistContext);

  const handleSelectAll = () => {
    selectedClips.length === playlistLines.length
      ? setSelectedClips([])
      : setSelectedClips(playlistLines);
  };

  const handleToggleClip = (
    e: React.ChangeEvent<HTMLInputElement>,
    currentClip: PlaylistLine
  ) => {
    let updatedSelectedClips = _.cloneDeep(selectedClips);
    if (
      selectedClips.find(
        (clip) => clip.playlistLineId === currentClip.playlistLineId
      )
    ) {
      updatedSelectedClips = updatedSelectedClips.filter(
        (clip) => clip.playlistLineId !== currentClip.playlistLineId
      );
    } else {
      updatedSelectedClips.push(currentClip);
    }
    setSelectedClips(updatedSelectedClips);
  };

  const updateSelectedPlaylistLine = () => {
    if (
      !playlistLines.find(
        (lineId) => lineId.playlistLineId === selectedPlaylistLineId
      )
    ) {
      return playlistLines[0].playlistLineId;
    }
    return selectedPlaylistLineId;
  };

  const handleDeleteClips = () => {
    const uniqueHdrIds = Array.from(
      new Set(selectedClips.map((clip) => clip.playlistHdrId))
    );
    uniqueHdrIds.map((hdrId) =>
      deletePlaylistLines(
        hdrId,
        selectedClips
          .filter((clip) => clip.playlistHdrId === hdrId)
          .map((clip) => clip.playlistLineId)
      ).then(() => {
        refreshPlaylistLines(playlist.playlistHdrId);
      })
    );
    setShowDeleteClipsDialog(false);
    setSelectedClips([]);
  };

  useEffect(() => {
    setDraggablePlaylistLines(
      playlistLines.map((playlistLine) => ({
        ...playlistLine,
        id: playlistLine.playlistLineId.toString(),
        selected: selectedPlaylistLineId === playlistLine.playlistLineId,
        disabled: false, // To be used while web service is running
      }))
    );
  }, [playlistLines, selectedPlaylistLineId]);

  const renderPlaylistLineText = (playlistLine: PlaylistLine) => (
    <ListItemText
      primary={playlistLine.playlistLineName}
      secondary={
        <>
          <Typography
            component="span"
            variant="body2"
            color="action"
            className={classes.description}
          >
            {playlistLine.description}
          </Typography>

          <Typography component="span" variant="body2" color="action">
            {`${DateUtils.getDurationBetweenDates(
              playlistLine.dateFrom,
              playlistLine.dateTo
            )} - ${DateUtils.formatISOString(playlistLine.dateFrom)}`}
          </Typography>
        </>
      }
    />
  );

  const renderEditButton = (playlistLine: PlaylistLine) => {
    if (userId !== playlist.createdBy || playlist.locked) return null;
    return (
      <ListItemSecondaryAction>
        <EditIconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onEditClip(playlistLine);
          }}
        />
      </ListItemSecondaryAction>
    );
  };

  const handleReorderClips = (newPlaylistLines: DraggablePlaylistLine[]) => {
    const oldPlaylistLines = _.cloneDeep(draggablePlaylistLines);
    setDraggablePlaylistLines(
      newPlaylistLines.map((line) => ({ ...line, disabled: true }))
    );
    reorderPlaylistLines(
      playlist.playlistHdrId,
      newPlaylistLines.map((line) => line.playlistLineId)
    )
      .then((response) => {
        if (!response.wasChanged) {
          return;
        }
        setDraggablePlaylistLines(newPlaylistLines);
        refreshPlaylistLines(playlist.playlistHdrId);
      })
      .catch(() => {
        // Display error and revert to old order
        onError('Failed to reorder playlist lines');
        setDraggablePlaylistLines(oldPlaylistLines);
      });
  };

  const renderListItem = (playlistLine: DraggablePlaylistLine) => (
    <>
      <ListItem
        button={!!onClick}
        onClick={() => onClick && onClick(playlistLine.playlistLineId)}
        selected={updateSelectedPlaylistLine() === playlistLine.playlistLineId}
        key={playlistLine.playlistLineId}
        classes={{
          root: classes.highlightedListItem,
          selected: classes.selected,
        }}
      >
        <ListItemIcon>
          <Checkbox
            name={`select-clip-${playlistLine.playlistLineId}`}
            checked={
              !!selectedClips.find(
                (clip) => clip.playlistLineId === playlistLine.playlistLineId
              )
            }
            disableRipple
            onClick={(e) => e.stopPropagation()}
            inputProps={{
              'aria-labelledby': `${playlistLine.playlistLineId}`,
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleToggleClip(e, playlistLine)
            }
          />
        </ListItemIcon>
        <Thumbnail
          deviceId={playlistLine.resourceId}
          thumbnailDate={DateUtils.findMidpointDate(
            playlistLine.dateFrom,
            playlistLine.dateTo
          )}
          quality={playlistLine.quality}
          width={100}
          className={classes.thumbnail}
        />
        {renderPlaylistLineText(playlistLine)}
        {renderEditButton(playlistLine)}
      </ListItem>
    </>
  );

  const renderPlaylistLineList = () => {
    if (userId === playlist.createdBy && !playlist.locked) {
      return (
        <DraggableList
          items={draggablePlaylistLines}
          onChange={handleReorderClips}
          renderItem={renderListItem}
        />
      );
    }
    return draggablePlaylistLines.map((playlistLine) =>
      renderListItem(playlistLine)
    );
  };

  if (playlistLines.length === 0) {
    return (
      <div className={classes.errorContainer}>
        <Typography>No clips in playlist </Typography>
      </div>
    );
  }

  return (
    <>
      <List>
        <ListSubheader
          className={classes.listSubheader}
          component="div"
          key="select-all"
        >
          <ListItemIcon>
            <Checkbox
              name="select-all-clips"
              checked={selectedClips.length === playlistLines.length}
              indeterminate={
                selectedClips.length > 0 &&
                selectedClips.length < playlistLines.length
              }
              disableRipple
              onChange={handleSelectAll}
              inputProps={{ 'aria-labelledby': 'select-all' }}
            />
          </ListItemIcon>
          <span>Select All</span>
        </ListSubheader>
        <Divider />
        {loadingReorder && <LinearProgress />}
        {renderPlaylistLineList()}
      </List>
      <Divider />
      <div className={classes.buttonContainer}>
        <OutlinedButton
          translationKey="seatube.copyToPlaylist"
          disabled={userId === -1 || selectedClips.length < 1}
          onClick={() => onCopyToPlaylistRequest(selectedClips)}
          startIcon={<Copy />}
        />
        <ContainedButton
          translationKey="common.buttons.download"
          startIcon={<GetApp />}
          id="download-clips-button"
          disabled={userId === -1 || selectedClips.length < 1}
          onClick={() => onDownloadRequest(selectedClips)}
        />
        {userId === playlist.createdBy && !playlist.locked ? (
          <div className={classes.flexButton}>
            <SeaTubeDeleteButton
              id="delete-clips-button"
              disabled={selectedClips.length < 1}
              onClick={() => setShowDeleteClipsDialog(true)}
            />
          </div>
        ) : undefined}
      </div>
      <DeleteDialog
        open={showDeleteClipsDialog}
        onCancel={() => setShowDeleteClipsDialog(false)}
        onDelete={handleDeleteClips}
        title={
          selectedClips.length === 1
            ? 'Delete Selected Clip?'
            : `Delete ${selectedClips.length} Selected Clips?`
        }
        message={`Are you sure you want to delete  ${
          selectedClips.length === 1
            ? 'the selected clip'
            : `the ${selectedClips.length} selected clips`
        }?`}
      />
    </>
  );
};

export default PlaylistList;
