import { useState } from 'react';
import { StatelessTable, TableColumn } from 'base-components';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import useWebService from 'util/hooks/useWebService';
import SystemStatusService from './SystemStatusService';

// Sorting order as defined in DMAS-82633
const sortOrder = new Map(
  [
    'DATACENTRE',
    'TASK',
    'TASK_USER_DEFINED',
    'ARCHIVER',
    'SHORESTATION',
    'CORRELATOR',
    'DRIVERS',
  ].map((item, index) => [item, index])
);

const renderHeaders: TableColumn[] = [
  { title: 'Location', name: 'MQLocation', dataType: 'String' },
  { title: 'Host', name: 'hostname', dataType: 'String' },
  { title: 'Role', name: 'systemRole', dataType: 'String' },
  { title: 'Version', name: 'version', dataType: 'String' },
  { title: 'Build Date', name: 'buildDate', dataType: 'String' },
  { title: 'Uptime', name: 'uptime', dataType: 'String' },
  { title: 'Dequeue Status', name: 'dequeuingStatus', dataType: 'String' },
  { title: 'Log Level', name: 'logLevel', dataType: 'String' },
  { title: 'Services', name: 'services', dataType: 'String' },
];

const tableColumnExtensions = [
  { columnName: 'MQLocation', wordWrapEnabled: true },
  { columnName: 'hostname', wordWrapEnabled: true },
  { columnName: 'systemRole', wordWrapEnabled: true },
  { columnName: 'version', wordWrapEnabled: true },
  { columnName: 'buildDate', wordWrapEnabled: true },
  { columnName: 'uptime', wordWrapEnabled: true },
  { columnName: 'dequeuingStatus', wordWrapEnabled: true },
  { columnName: 'logLevel', wordWrapEnabled: true },
  { columnName: 'services', wordWrapEnabled: true },
];

const columnSizeInfo = [
  { columnName: 'MQLocation', width: ColumnInfo.medium },
  { columnName: 'hostname', width: ColumnInfo.medium },
  { columnName: 'systemRole', width: ColumnInfo.medium },
  { columnName: 'version', width: ColumnInfo.medium },
  { columnName: 'buildDate', width: ColumnInfo.medium },
  { columnName: 'uptime', width: ColumnInfo.medium },
  { columnName: 'dequeuingStatus', width: ColumnInfo.medium },
  { columnName: 'logLevel', width: ColumnInfo.medium },
  { columnName: 'services', width: ColumnInfo.jumbo },
];

type SystemStatusType = {
  version: string;
  MQLocation: string;
  buildDate: string;
  date: string;
  hostname: string;
  logLevel: string;
  mqDomainLocation: string;
  serviceTypeId: string;
  startDate: string;
  stats: {
    statusDate: string;
    startDate: string;
  };
  statusInterval: number;
  systemRole: string;
  timeToLive: number;
  rowId: string;
  services: string;
  dequeuingStatus: string;
  uptime: string;
}[];

type SystemConsoleTableProps = {
  refreshMessage: () => void;
  onSelectionChange: (selections) => void;
  destinations: string[];
  showSelections: boolean;
};

const SystemConsoleTable: React.FC<SystemConsoleTableProps> = ({
  refreshMessage,
  onSelectionChange,
  destinations,
  showSelections,
}: SystemConsoleTableProps) => {
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);
  const [sorting, handleSortingChange] = useState(undefined);
  const [grouping, handleGroupingChange] = useState([]);
  const [filter, setFilter] = useState(undefined);

  const sortRoles = (role) => {
    const roleArray = role.split(',').map((r) => r.trim());
    return roleArray.sort(
      (a, b) => (sortOrder.get(a) ?? 0) - (sortOrder.get(b) ?? 0)
    );
  };

  const [rows, , getRows] = useWebService({
    method: SystemStatusService.get,
    parser: (result: SystemStatusType) =>
      result.map((row) => ({
        ...row,
        systemRole: sortRoles(row.systemRole).join(', '),
      })),
  });

  if (!renderedOnce) {
    getRows();
    setRenderedOnce(true);
  }

  return (
    <StatelessTable
      rows={rows || []}
      columns={renderHeaders}
      getRowId={(row) => row.rowId}
      columnExtensions={tableColumnExtensions}
      virtual={{
        virtualized: true,
      }}
      visible={{
        hiddenColumnNames: [],
      }}
      selection={{
        selectByRowClick: false,
        highlightRow: false,
        showSelectionColumn: showSelections,
        selection: destinations,
        onChange: onSelectionChange,
        showSelectAll: true,
      }}
      sort={{
        sorting,
        handleSortingChange,
      }}
      group={{
        grouping,
        columnExtensions: [
          {
            columnName: 'hostname',
            groupingEnabled: false,
          },
          {
            columnName: 'uptime',
            groupingEnabled: false,
          },
          {
            columnName: 'services',
            groupingEnabled: false,
          },
        ],
        handleGroupingChange,
      }}
      onRefresh={() => {
        refreshMessage();
        getRows();
      }}
      filter={{
        filterValue: filter,
        onChange: setFilter,
        hidden: false,
      }}
      resize={{ columnWidths: columnSizeInfo }}
    />
  );
};

export default SystemConsoleTable;
