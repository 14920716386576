import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { SaveButton, CancelButton } from '@onc/composite-components';
import { Grid } from 'base-components';
import DraggableToolbox from 'domain/AppComponents/annotations/entry/DraggableToolbox';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateFormatUtils from 'util/DateFormatUtils';
import DeviceCategory from './DeviceCategory';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)}px)`,
    minWidth: theme.spacing(50),
  },
});

type DeviceCategoryDialogBoxProps = {
  onError: (message: any, callback?: any) => void;
  onClose: () => void;
  onSubmit: (deviceCategory: DeviceCategory) => void;
  label: string;
  deviceCategory: DeviceCategory;
};

const validate = (
  deviceCategory: DeviceCategory | undefined,
  fieldName: keyof DeviceCategory
) =>
  deviceCategory && deviceCategory[fieldName]
    ? String(deviceCategory[fieldName])
    : '';

const createTextField = (
  value,
  setValue,
  label,
  isRequired,
  attemptedSubmit
) => (
  <Grid item xs={12}>
    {isRequired ? (
      <TextField
        fullWidth
        value={value}
        onChange={(event) => setValue(event.target.value)}
        label={label}
        error={attemptedSubmit && !value}
        helperText={attemptedSubmit && !value ? 'Required field' : ''}
        required
      />
    ) : (
      <TextField
        fullWidth
        value={value}
        onChange={(event) => setValue(event.target.value)}
        label={label}
      />
    )}
  </Grid>
);

const DeviceCategoryDialogBox = ({
  onError,
  onClose,
  onSubmit,
  label,
  deviceCategory,
}: DeviceCategoryDialogBoxProps) => {
  const [name, setName] = useState<string>(validate(deviceCategory, 'name'));
  const [deviceCategoryCode, setDeviceCategoryCode] = useState<string>(
    validate(deviceCategory, 'deviceCategoryCode')
  );
  const [description, setDescription] = useState<string>(
    validate(deviceCategory, 'description')
  );
  const [longDescription, setLongDescription] = useState<string>(
    validate(deviceCategory, 'longDescription')
  );
  const [additionalInfo, setAdditionalInfo] = useState<string>(
    validate(deviceCategory, 'additionalInfo')
  );
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const handleOnSubmit = () => {
    setAttemptedSubmit(true);
    if (!name) {
      return;
    }
    if (
      !name.trim() ||
      !deviceCategoryCode.trim() ||
      !description.trim() ||
      !longDescription.trim()
    ) {
      onError('Name, Code, Description and Long Description cannot be blank');
    } else {
      onSubmit({
        id: deviceCategory ? deviceCategory.id : undefined,
        name,
        deviceCategoryCode,
        description,
        longDescription,
        additionalInfo,
      });
    }
  };

  const renderFormButtons = () => (
    <Grid item xs={12}>
      <SaveButton onClick={() => handleOnSubmit()} />
      <CancelButton
        onClick={() => {
          onClose();
        }}
      />
    </Grid>
  );

  useEffect(() => {
    if (deviceCategory) {
      setName(deviceCategory?.name || '');
      setDeviceCategoryCode(deviceCategory?.deviceCategoryCode || '');
      setDescription(deviceCategory?.description || '');
      setLongDescription(deviceCategory?.longDescription || '');
      setAdditionalInfo(deviceCategory?.additionalInfo || '');
      setAttemptedSubmit(false);
    }
  }, [deviceCategory]);

  return (
    <>
      <DraggableToolbox
        title={label}
        initiallyExpanded
        onClose={() => onClose()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              value={validate(deviceCategory, 'id')}
              label="Device Category ID"
              fullWidth
              disabled
            />
          </Grid>
          {createTextField(
            name,
            setName,
            'Device Category Name',
            true,
            attemptedSubmit
          )}
          {createTextField(
            deviceCategoryCode,
            setDeviceCategoryCode,
            'Device Category Code',
            true,
            attemptedSubmit
          )}
          {createTextField(
            description,
            setDescription,
            'Description',
            true,
            attemptedSubmit
          )}
          {createTextField(
            longDescription,
            setLongDescription,
            'Long Description',
            true,
            attemptedSubmit
          )}
          {createTextField(
            additionalInfo,
            setAdditionalInfo,
            'Additional Information',
            false,
            attemptedSubmit
          )}
          <Grid item xs={12}>
            <ModifyBy
              username={deviceCategory ? deviceCategory.modifyByName : ''}
            />
            <ModifyDate
              date={
                deviceCategory
                  ? DateFormatUtils.formatDate(
                      deviceCategory.modifyDate,
                      'full'
                    )
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            {renderFormButtons()}
          </Grid>
        </Grid>
      </DraggableToolbox>
    </>
  );
};

export default withStyles(styles)(withSnackbars(DeviceCategoryDialogBox));
