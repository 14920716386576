import { lazy } from 'react';

const LiveExpeditionContainer = lazy(
  () => import('domain/Apps/sea-tube/LiveExpeditionContainer')
);

const PlaylistPlaybackApp = lazy(
  () => import('domain/Apps/sea-tube/PlaylistPlaybackApp')
);

const PlaylistsApp = lazy(() => import('domain/Apps/sea-tube/PlaylistsApp'));

const SeaTubeContainer = lazy(
  () => import('domain/Apps/seatube/SeaTubeContainer')
);

const SeaTubeConfigurationPage = lazy(
  () => import('domain/Apps/seatube/seatube-config/SeaTubeConfigurationPage')
);

const SeaTubeIngestion = lazy(
  () => import('domain/Apps/seatube/SeaTubeIngestion')
);

const SeaTubeSearch = lazy(() => import('domain/Apps/seatube/SeaTubeSearch'));

const ExpeditionLandingPage = lazy(
  () => import('domain/Apps/expedition-management/ExpeditionLandingPage')
);

const TaxonButtonSetConfigPage = lazy(
  () => import('domain/Apps/taxonomy/TaxonButtonSetConfigPage')
);
const TaxonomyApp = lazy(() => import('domain/Apps/taxonomy/TaxonomyApp'));

const TaxonomyAttributeManagementPage = lazy(
  () =>
    import(
      'domain/Apps/taxonomy-attribute-management/TaxonomyAttributeManagementPage'
    )
);

const ChatLogIngestionContainer = lazy(
  () => import('domain/Apps/seatube/ChatLogIngestionContainer')
);

const DeckLogApp = lazy(() => import('domain/Apps/sea-tube/DeckLogApp'));

const DiveLogApp = lazy(
  () => import('domain/Apps/sea-tube/dive-log/DiveLogApp')
);

const FixedCameraLocationApp = lazy(
  () => import('domain/Apps/sea-tube/fixed-cameras/FixedCameraLocationApp')
);

const FixedCameraPlaybackApp = lazy(
  () => import('domain/Apps/sea-tube/fixed-cameras/FixedCameraPlaybackApp')
);

const SeaTubeSearchApp = lazy(
  () => import('domain/Apps/sea-tube/search/SeaTubeSearchApp')
);

const SeatubeRoute = {
  name: 'SeaTube',
  children: [
    {
      component: ExpeditionLandingPage,
      path: '/ExpeditionManagement',
      name: 'Expedition Management',
    },
    {
      component: PlaylistsApp,
      path: '/app/playlists',
      name: 'Playlists',
      exact: true,
    },
    {
      component: SeaTubeContainer,
      path: '/SeaTubeV3',
      name: 'SeaTube',
    },
    {
      component: SeaTubeConfigurationPage,
      path: '/SeaTubeConfiguration',
      name: 'SeaTube Configuration',
    },
    {
      component: SeaTubeIngestion,
      path: '/SeaTubeIngestion',
      name: 'SeaTube Ingestion',
    },
    {
      component: ChatLogIngestionContainer,
      path: '/ChatLogIngestion',
      name: 'Chat Log Ingestion',
    },
    {
      component: SeaTubeSearch,
      path: '/SeaTubeSearch',
      name: 'SeaTube Search',
    },
    {
      component: TaxonButtonSetConfigPage,
      path: '/TaxonButtonSetConfig',
      name: 'Taxon Button Set Configuration',
      resource: 'Taxon Button Set Config',
    },
    {
      component: TaxonomyAttributeManagementPage,
      path: '/TaxonomyAttributeManagement',
      name: 'Taxonomy Attribute Management',
      resource: 'Attribute Management',
    },
    {
      component: TaxonomyApp,
      path: '/Taxonomy',
      name: 'Taxonomy',
      resource: 'Taxonomy',
    },
    {
      component: DeckLogApp,
      path: '/app/expedition-logs/:cruiseId',
      name: 'Deck Log',
      resource: 'SeaTube',
    },
    {
      component: DiveLogApp,
      path: '/app/dive-logs/:diveId/:isLiveMode?',
      name: 'Dive Log',
      resource: 'SeaTube',
    },
    {
      component: PlaylistPlaybackApp,
      path: '/app/playlists/:playlistHdrId',
      name: 'Playlist Playback',
      resource: 'Playlists',
    },
    {
      component: LiveExpeditionContainer,
      path: '/embed/live-expedition/:cruiseId',
      name: 'Live Expedition Embed',
      resource: 'Live Expedition',
    },
    {
      component: FixedCameraLocationApp,
      path: '/app/fixed-camera-locations',
      name: 'Fixed Video Camera Locations',
      resource: 'SeaTube',
      exact: true,
    },
    {
      component: FixedCameraPlaybackApp,
      path: '/app/fixed-camera-locations/:searchTreeNodeId',
      name: 'Fixed Video Camera Playback',
      resource: 'SeaTube',
    },
    {
      component: SeaTubeSearchApp,
      path: '/app/seatube-search',
      name: 'SeaTube Search Beta',
      resource: 'SeaTube Search',
    },
  ].sort((a, b) => a.name.localeCompare(b.name)),
};
export default SeatubeRoute;
