import * as React from 'react';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { Add } from '@onc/icons';
import IconButton from '../../icon-button/IconButton';

interface Props {
  onCreate: () => void;
}

const pluginDependencies = [{ name: 'Toolbar' }];

const AddButtonPlugin: React.VFC<Props> = ({ onCreate }: Props) => (
  <Plugin dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <TemplateConnector>
        {() => (
          <IconButton
            aria-label="Add"
            onClick={onCreate}
            style={{ width: '48px' }}
          >
            <Add />
          </IconButton>
        )}
      </TemplateConnector>
    </Template>
  </Plugin>
);

export default AddButtonPlugin;
