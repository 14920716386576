export type UserOrganization = {
  userId: number;
  firstname: string;
  lastname: string;
  email: string;
  organizationId: number;
  organizationName: string;
  modifyBy: string;
  modifyDate: string;
};

export type UserOrganizationRow = {
  userId: number;
  firstname: string;
  lastname: string;
  email: string;
  organizationId: number;
  organizationName: string;
  modifyBy: string;
  modifyDate: string;
};

export type UserOrganizationSaveValues = {
  user: { name: string; userId: number };
  organization: { organizationName: string; organizationId: number };
};

export const transformUserOrganizationRows = (
  userOrganizations: UserOrganization[]
): UserOrganizationRow[] =>
  userOrganizations.map((item) => ({
    userId: item.userId,
    firstname: item.firstname,
    lastname: item.lastname,
    email: item.email,
    organizationId: item.organizationId,
    organizationName: item.organizationName,
    modifyBy: item.modifyBy,
    modifyDate: item.modifyDate,
  }));
