import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ContainedButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import { TableOld as Table, Typography } from 'base-components';
import {
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import {
  handleRedirectToSiteDeviceSubset,
  getUrl,
} from 'domain/AppComponents/link/SiteDeviceSubsetLink';
import SiteDeviceSubsetDeleteDialog from 'domain/AppComponents/site-device-maintenance/SiteDeviceSubsetDeleteDialog';
import SiteDeviceSubsetService from 'domain/services/SiteDeviceSubsetService';
import PanelHeader from 'library/CompositeComponents/panel/PanelHeader';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import Environment from 'util/Environment';

const STYLES = (theme) => ({
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(3),
  },
  tableStyle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
});

const SiteDeviceSubsetTable = ({
  siteDeviceId = undefined,
  classes = undefined,
  siteDeviceSubsetRows = undefined,
  pageSize = 5,
  onInfo,
  onError,
  renderActionItems = true,
  customDeleteActionOnChange = undefined,
  renderAddButton = true,
  showReviewStatus = false,
}) => {
  SiteDeviceSubsetTable.propTypes = {
    siteDeviceId: PropTypes.number,
    classes: PropTypes.shape({
      formButtons: PropTypes.string,
      tableStyle: PropTypes.string,
    }),
    siteDeviceSubsetRows: PropTypes.arrayOf(PropTypes.shape({})),
    pageSize: PropTypes.number,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    renderActionItems: PropTypes.bool,
    customDeleteActionOnChange: PropTypes.func,
    renderAddButton: PropTypes.bool,
    showReviewStatus: PropTypes.bool,
  };

  const [subsetRows, setSubsetRows] = useState(siteDeviceSubsetRows || []);
  const [siteDeviceSubsetTypes, setSiteDeviceSubsetTypes] = useState([]);
  const [generationTypes, setGenerationTypes] = useState([]);
  const [renderDeleteAlertBox, setRenderDeleteAlertBox] = useState(false);
  const [siteDeviceSubsetToDelete, setSiteDeviceSubsetToDelete] =
    useState(undefined);

  useEffect(() => {
    getTypes(10);
    getTypes(9);

    if (!siteDeviceSubsetRows) {
      getSiteDeviceSubsets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubsetRows(siteDeviceSubsetRows);
  }, [siteDeviceSubsetRows]);

  /**
   * Gets a list of siteDeviceSubsets for the selected siteDevice and store them
   * in the state
   */
  const getSiteDeviceSubsets = async () => {
    if (!siteDeviceId || isNaN(siteDeviceId)) return;

    try {
      const payload =
        await SiteDeviceSubsetService.getSiteDeviceSubsetsForSiteDevice(
          siteDeviceId
        );
      setSubsetRows(payload.records);
    } catch (error) {
      onError(`Failed to get site device subsets`);
    }
  };

  const getTypes = async (operation) => {
    try {
      let payload;
      if (operation === 9) {
        payload = await SiteDeviceSubsetService.getSiteDeviceSubsetTypes();
      } else {
        payload = await SiteDeviceSubsetService.getGenerationTypes();
      }
      const keys = Object.keys(payload);
      const values = Object.values(payload);
      const types = [];
      for (let i = 0; i < keys.length; i += 1) {
        types[i] = {
          label: values[i],
          value: keys[i],
        };
      }
      if (operation === 10) setGenerationTypes(types);
      else setSiteDeviceSubsetTypes(types);
    } catch (error) {
      onError(
        `Failed to get ${operation === 9 ? 'subset types' : 'generation types'}`
      );
    }
  };

  const getPrettyValue = (typeValue) => {
    let array;
    if (typeValue === 1000 || typeValue === 1100) {
      array = generationTypes;
      return search(typeValue, array);
    }
    array = siteDeviceSubsetTypes;
    return search(typeValue, array);
  };

  const getReviewStatus = (typeValue) => {
    if (typeValue) {
      return 'Reviewed';
    }
    return 'In Progress';
  };

  const search = (typeValue, array) => {
    for (let i = 0; i < array.length; i += 1) {
      if (parseInt(array[i].value, 10) === typeValue) {
        return array[i].label;
      }
    }
    return undefined;
  };

  const compareSiteDeviceSubsetId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  const siteDeviceSubsetIdLink = (siteDeviceSubset) => (
    <a
      href={getUrl(siteDeviceSubset)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`${siteDeviceSubset.siteDeviceSubsetId}`}
    </a>
  );

  const deleteCustomAction = (siteDSId) => () =>
    customDeleteActionOnChange(siteDSId);

  const deleteIconOnClick = (siteDeviceSubset) => {
    setSiteDeviceSubsetToDelete(siteDeviceSubset);
    setRenderDeleteAlertBox(true);
  };

  /**
   * Returns a function that removes a specific row from the table and makes a
   * call to the service to delete it from the database.
   *
   * @param {object} siteDS Site Device Subset to delete
   */
  const deleteAction = async (siteDS) => {
    try {
      await SiteDeviceSubsetService.deleteSiteDeviceSubset(
        siteDS.siteDeviceSubsetType,
        siteDS.siteDeviceSubsetId
      );
      const newSiteDeviceSubsetRows = subsetRows.filter(
        ({ siteDeviceSubsetId }) =>
          siteDeviceSubsetId !== siteDS.siteDeviceSubsetId
      );
      setSubsetRows(newSiteDeviceSubsetRows);
      setRenderDeleteAlertBox(false);
      onInfo(`Site Device Subset ${siteDS.siteDeviceSubsetId} deleted!`);
    } catch (error) {
      onError(`Site Device Subset ${siteDS.siteDeviceSubsetId} not deleted!`);
    }
  };

  /** Displays the edit and delete icon in every row in the siteDeviceSubsetTable */
  const renderActionIcons = (siteDeviceSubset) => ({
    ...siteDeviceSubset,
    actions: (
      <>
        <DeleteIconButton onClick={() => deleteIconOnClick(siteDeviceSubset)} />
        <EditIconButton
          onClick={handleRedirectToSiteDeviceSubset({
            siteDeviceId: siteDeviceSubset.siteDeviceId,
            siteDeviceSubsetId: siteDeviceSubset.siteDeviceSubsetId,
          })}
        />
      </>
    ),
  });

  const renderCustomDeleteButton = (siteDeviceSubset) => ({
    ...siteDeviceSubset,
    actions: (
      <DeleteIconButton
        aria-label="Remove Row"
        onClick={deleteCustomAction(siteDeviceSubset.siteDeviceSubsetId)}
      />
    ),
  });

  const renderAddSiteDeviceSubsetButton = () => {
    const rwPermission =
      Environment.getDmasUserPrivilege() === 'RW' && renderActionItems;
    if (rwPermission && renderActionItems && renderAddButton) {
      return (
        <div className={classes.formButtons}>
          <ContainedButton
            translationKey="device.addSiteDeviceSubset"
            startIcon={<Add />}
            onClick={handleRedirectToSiteDeviceSubset({
              siteDeviceId,
              classes,
              siteDeviceSubsetRows,
              pageSize,
              onInfo,
              onError,
              renderActionItems,
              customDeleteActionOnChange,
              renderAddButton,
              showReviewStatus,
            })}
            disabled={!siteDeviceId}
          />
        </div>
      );
    }
    return null;
  };

  const renderDeleteConfirmation = () => {
    if (renderDeleteAlertBox) {
      return (
        <SiteDeviceSubsetDeleteDialog
          onCancel={() => setRenderDeleteAlertBox(false)}
          onDelete={() => deleteAction(siteDeviceSubsetToDelete)}
          siteDeviceSubsetId={siteDeviceSubsetToDelete.siteDeviceSubsetId}
        />
      );
    }
    return <> </>;
  };

  const getColumns = () => {
    const permissions =
      Environment.getDmasUserPrivilege() === 'RW' && renderActionItems;
    const columns = [
      {
        name: 'siteDeviceSubsetId',
        title: 'ID',
      },
      {
        name: 'siteDeviceSubsetType',
        title: 'Subset Type',
      },
      {
        name: 'generationType',
        title: 'Generation Type',
      },
      {
        name: 'siteDeviceSubsetName',
        title: 'Subset Name',
      },
      {
        name: 'startDate',
        title: 'Start Date',
      },
      {
        name: 'endDate',
        title: 'End Date',
      },
      {
        name: 'modifyBy',
        title: 'Modified By',
      },
    ];
    if (permissions) {
      columns.push({
        name: 'actions',
        title: 'Actions',
      });
    }

    if (showReviewStatus) {
      columns.splice(6, 0, {
        name: 'castReviewed',
        title: 'Review Status',
      });
    }
    return columns;
  };
  const getRenderedSiteDSRows = () => {
    const permissions =
      Environment.getDmasUserPrivilege() === 'RW' && renderActionItems;

    let renderedSiteDSRows = [];
    if (Array.isArray(subsetRows) && customDeleteActionOnChange) {
      // use custom delete button
      renderedSiteDSRows = subsetRows.map(renderCustomDeleteButton);
    } else if (Array.isArray(subsetRows) && permissions) {
      renderedSiteDSRows = subsetRows.map(renderActionIcons);
    } else if (Array.isArray(subsetRows)) {
      renderedSiteDSRows = subsetRows;
    }
    renderedSiteDSRows = renderedSiteDSRows.map((siteDeviceSubset) => ({
      ...siteDeviceSubset,
      siteDeviceSubsetId: siteDeviceSubsetIdLink(siteDeviceSubset),
      siteDeviceSubsetType: getPrettyValue(
        siteDeviceSubset.siteDeviceSubsetType
      ),
      generationType: getPrettyValue(siteDeviceSubset.generationType),
      castReviewed: getReviewStatus(siteDeviceSubset.castReviewed),
    }));
    return renderedSiteDSRows;
  };

  return (
    <>
      <PanelHeader
        title={
          <Typography variant="body1">Site Device Subsets List</Typography>
        }
      />
      {renderAddSiteDeviceSubsetButton()}
      <div className={classes.tableStyle}>
        <Table
          sortable
          columns={getColumns()}
          rows={getRenderedSiteDSRows()}
          pageSize={pageSize}
          sortExtensions={[
            {
              columnName: 'siteDeviceSubsetId',
              compare: compareSiteDeviceSubsetId,
            },
          ]}
        />
        {renderDeleteConfirmation()}
      </div>
    </>
  );
};

export default withStyles(STYLES)(withSnackbars(SiteDeviceSubsetTable));
