/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState } from 'react';

import * as React from 'react';
import SeaTubeDashboard from 'domain/AppComponents/sea-tube/SeaTubeDashboard';
import TaxonomyDetailWidget from 'domain/Widgets/TaxonomyDetailWidget';
import TaxonomyTreeWidget, {
  TaxonItem,
  TaxonomyItem,
} from 'domain/Widgets/TaxonomyTreeWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import Environment from 'util/Environment';
import { defaultLayout, defaultWidgets } from './layouts';

type Props = {
  match: {
    isExact: boolean;
    path: string;
    url: string;
  };
};

export const UserPrivilegeContext = createContext(null);

export const TaxonomyContext = createContext(null);

const TaxonomyApp: React.VFC<Props> = (props: Props) => {
  const widgetLibrary = new WidgetLibrary([
    {
      Component: TaxonomyTreeWidget,
      label: 'Taxonomy Tree',
      multiple: false,
    },
    {
      Component: TaxonomyDetailWidget,
      label: 'Taxonomy Details',
      multiple: false,
    },
  ]);
  const [taxon, setTaxon] = useState<TaxonItem>(null);
  const [taxonomy, setTaxonomy] = useState<TaxonomyItem>(null);
  const [refreshTaxonomies, setRefreshTaxonomies] = useState<boolean>(false);
  const [matrixId, setMatrixId] = useState<number>(0);
  const [selectedTaxonomyMatrixId, setSelectedTaxonomyMatrixId] =
    useState<number>(0);

  const clickTaxonomyItem = (taxonomyMatrixId, txy, txn) => {
    setSelectedTaxonomyMatrixId(taxonomyMatrixId);
    setTaxonomy(txy);
    setTaxon(txn);
  };

  return (
    <UserPrivilegeContext.Provider value={Environment.getDmasUserPrivilege()}>
      <TaxonomyContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          taxon,
          setTaxon,
          taxonomy,
          setTaxonomy,
          matrixId,
          setMatrixId,
          selectedTaxonomyMatrixId,
          clickTaxonomyItem,
          refreshTaxonomies,
          setRefreshTaxonomies,
        }}
      >
        <SeaTubeDashboard
          title=""
          // Using a uuid here will prevent broadcasting to other tabs / windows
          id={crypto.getRandomValues(new Uint32Array(1)).toString()}
          isStatic
          layoutKey="Taxonomy"
          widgetLibrary={widgetLibrary}
          defaultLayout={defaultLayout}
          defaultWidgets={defaultWidgets}
          {...props}
        />
      </TaxonomyContext.Provider>
    </UserPrivilegeContext.Provider>
  );
};

export default TaxonomyApp;
