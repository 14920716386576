import { cloneElement, Component } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { Info } from '@onc/icons';
import { Chip } from 'base-components';

const styles = () => ({
  chip: {
    margin: '0',
  },
});

type BaseDialogChipProps = {
  label: string;
  type?: string;
  theme: {
    palette: {
      error?: { main: string };
      grey?: Record<string, unknown>;
      secondary?: { main: string };
      primary?: { main: string };
    };
  };
  classes: {
    chip: string;
  };
  dialog?: any;
};

type DialogChipProps = BaseDialogChipProps &
  WithStyles<typeof styles> & { theme: any };

type DialogChipState = {
  showDialog: boolean;
};

class DialogChip extends Component<DialogChipProps, DialogChipState> {
  static defaultProps = {
    type: 'primary',
    dialog: undefined,
  };

  state = {
    showDialog: false,
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
  };

  renderDialog = () => {
    const { dialog } = this.props;
    const { showDialog } = this.state;
    if (dialog) {
      return cloneElement(dialog, {
        open: showDialog,
        onClose: this.closeDialog,
      });
    }
    return null;
  };

  getColor: any = () => {
    const { type, theme } = this.props;
    switch (type) {
      case 'error':
        return theme.palette.error.main;
      case 'info':
        return theme.palette.grey[500];
      case 'secondary':
        return theme.palette.secondary.main;
      default:
        return theme.palette.primary.main;
    }
  };

  render() {
    const { label, dialog, classes } = this.props;
    return (
      <>
        <Chip
          className={classes.chip}
          label={label}
          color="primary"
          style={{ backgroundColor: this.getColor() }}
          icon={dialog ? <Info /> : null}
          onClick={
            dialog ? () => this.setState({ showDialog: true }) : undefined
          }
        />
        {this.renderDialog()}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DialogChip);
