import { useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import moment from 'moment';

import { withSnackbars } from '@onc/composite-components';
import Environment from '@onc/environment';
import { Copy, ArrowLeft, ArrowRight } from '@onc/icons';
import {
  CellComponent,
  IconButton,
  Grid,
  StatelessTable,
  DevExpressSorting as Sorting,
} from 'base-components';
import {
  Option,
  COLUMNS,
  SEARCH_DETAIL_COLUMNS,
} from 'domain/AppComponents/search-history/search-history-util/SearchHistoryPageUtils';
import FormField from 'domain/Apps/form-field/FormField';
import FormFieldService from 'domain/services/FormFieldService';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import CopyCitationDialog from './CopyCitationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionsTableCell: {
      whiteSpace: 'normal',
      display: 'flex',
      '& button': {
        borderRadius: 0,
        margin: theme.spacing(1),
      },
    },
    optionsTable: {
      padding: '4px 0',
      margin: '4px',
      '& tr': {
        boxShadow: '0 0 4px #ccc',
      },
      '& td': {
        paddingLeft: '4px',
      },
    },
    searches: {
      '& div[class*="TableContainer"]': {
        overflow: 'inherit',
      },
      '& div[class*="TableHeaderCell"]': {
        fontWeight: 'bold',
      },
      // tweak margin for the options table and copy citation cells
      '& div[aria-label="options table"] > div': {
        margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
      },
      // adds space below the sub table rows
      '& div[aria-label="search details"]': {
        marginBottom: theme.spacing(1),
      },
      // fixes button shape and location
      '& div[aria-label="options table"]': {},
      '& div[aria-label="options table no options"] > p': {
        marginLeft: theme.spacing(6),
      },
    },
    pagingOnTop: {
      '& div[class^="RootBase"]': {
        flexDirection: 'column-reverse',
      },
    },
  })
);

const searchDetailColumnExtensions = [
  {
    columnName: 'copyCitation',
    wordWrapEnabled: false,
    width: ColumnInfo.medium,
  },
  {
    columnName: 'queryPid',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
  {
    columnName: 'deviceName',
    wordWrapEnabled: true,
    width: ColumnInfo.large,
  },
];

const searchHistoryColumnExtensions = [
  {
    columnName: 'path',
    wordWrapEnabled: true,
    width: ColumnInfo.large,
  },
  {
    columnName: 'searchId',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },

  {
    columnName: 'dataProduct',
    wordWrapEnabled: true,
    width: ColumnInfo.medium,
  },
  {
    columnName: 'optionsTable',
    wordWrapEnabled: true,
    width: ColumnInfo.large,
  },
];

interface SearchHistoryTableProps {
  rows: Search[];
  pagingOnTop: boolean;
  onInfo: (info: string) => void;
  paging: {
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    page: number;
    setPage: (page: number) => void;
    count: number;
  };
  sorting: {
    setSortOrder: (sortOrder: Sorting[]) => void;
    sortOrder: Sorting[];
  };
}

interface Search {
  [key: string]: any;
  dataProduct: string;
  dateCreated: string;
  dateFrom: string;
  dateTo: string;
  options: Option[];
  path?: string;
  searchDtls: {
    [key: string]: any;
    citation: string;
    dateFrom: string;
    dateTo: string;
    deviceId: number;
    deviceName: string;
    doiDataset: string;
    modifyBy: string;
    modifyDate: string;
    queryPid: number;
    siteDeviceId: number;
    siteId: number;
  }[];
  searchId: number;
}

const SearchHistoryTable: React.VFC<SearchHistoryTableProps> = ({
  rows,
  onInfo,
  paging,
  sorting,
  pagingOnTop,
}: SearchHistoryTableProps) => {
  const [rowsState, setRowsState] = useState<Search[]>([]);
  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([]);
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState<boolean>(false);

  const [isOptionTableExanded, setIsOptionTableExanded] =
    useState<boolean>(false);
  const [citationValue, setCitationValue] = useState<string | undefined>(
    undefined
  );
  // a list of rows whose options table should be showing
  const [optionsExpanded, setOptionsExpanded] = useState<number[]>([]);
  const [formFields, setFormFields] = useState<any | null>(null);
  const [environmentPrefix] = useState(Environment.getDmasUrl());
  const classes = useStyles();

  useEffect(() => {
    const refreshTableData = async () => {
      const payload: FormField[] = await FormFieldService.getAllFormFields();
      const stuff = new Map(
        payload.map((field) => [field.fieldLabel, field.formFieldDocumentation])
      );
      setFormFields(stuff);
    };
    refreshTableData();
  }, []);

  // pulls the data information out of the options
  const getOptionsColumns = (rowOptions: any) => {
    const rowList: { label: string; displayValue: string }[] = [];
    let valuesString = '';
    for (const opt in rowOptions) {
      if (rowOptions[opt] !== undefined) {
        rowList.push({
          label: rowOptions[opt].label,
          displayValue: rowOptions[opt].displayValue,
        });
        valuesString += `${rowOptions[opt].displayValue}, `;
      }
    }
    return { rowList, valuesString };
  };

  const optionsTable = useCallback(
    (row: Search) => {
      if (!row.options || row.options.length <= 0) {
        return (
          <div aria-label="options table no options">
            <p>No options found</p>
          </div>
        );
      }
      const { rowList, valuesString } = getOptionsColumns(row.options);
      if (optionsExpanded.includes(row.id)) {
        const tableRows = () => {
          const rowHTML = [];
          for (let i = 0; i < rowList.length; i += 1) {
            const link = formFields.get(rowList[i].label);
            rowHTML.push(
              <tr>
                <td>
                  {/* conditionally render links if an option has documentation */}
                  {link !== undefined ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${link}`}
                    >
                      {`${rowList[i].label}`}
                    </a>
                  ) : (
                    `${rowList[i].label}`
                  )}
                </td>
                <td>{rowList[i].displayValue}</td>
              </tr>
            );
          }
          return rowHTML;
        };
        return (
          <div aria-label="options table" className={classes.optionsTableCell}>
            <IconButton
              onClick={() => {
                setOptionsExpanded((prevState) => {
                  const previous = prevState;
                  previous.splice(previous.indexOf(row.id), 1);
                  return previous;
                });
                setIsOptionTableExanded((prevState) => !prevState);
              }}
              aria-label="Expand"
            >
              <ArrowLeft />
            </IconButton>
            <table className={classes.optionsTable}>{tableRows()}</table>
          </div>
        );
      }
      return (
        <div aria-label="options table" className={classes.optionsTableCell}>
          <IconButton
            onClick={() => {
              setOptionsExpanded((prevState) => {
                const previous = prevState;
                previous.push(row.id);
                return previous;
              });
              setIsOptionTableExanded((prevState) => !prevState);
            }}
            aria-label="Collapse"
          >
            <ArrowRight />
          </IconButton>
          <p>{valuesString.substring(0, valuesString.length - 2)}</p>
        </div>
      );
    },
    [
      classes.optionsTable,
      classes.optionsTableCell,
      optionsExpanded,
      formFields,
    ]
  );

  const formatDate = (date: Date | string) => {
    const formattedDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
    if (formattedDate && formattedDate !== 'Invalid date') {
      return formattedDate.toString();
    }
    return '';
  };

  // cleaner way to add links to cells
  const makeExternalLink = (
    value: number | string,
    url: string,
    option?: number | string
  ) => (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`${environmentPrefix}${url}${option || value}`}
    >
      {value}
    </a>
  );

  const handleCopyCitationClick = (citation) => {
    setCitationValue(citation);
    setIsCopyDialogOpen(true);
  };

  // need to add id to each row. Add icon button, and links to cells as well.
  const formatRows = useCallback(
    (rowsList: Search[]) => {
      const formattedRows = rowsList.map((row) => {
        const tableRow: Search = row;
        tableRow.id = row.searchId;
        tableRow.optionsTable = optionsTable(row);
        tableRow.dateCreated = formatDate(row.dateCreated);
        tableRow.dateFrom = formatDate(row.dateFrom);
        tableRow.dateTo = formatDate(row.dateTo);
        const searchDetails = row.searchDtls;
        if (searchDetails.length > 0) {
          searchDetails.map((dtlRow) => {
            const detailRow = dtlRow;
            detailRow.id = dtlRow.queryPid;
            detailRow.copyCitation = (
              <div aria-label="options table">
                <IconButton
                  aria-label="Copy Citation"
                  onClick={() => handleCopyCitationClick(dtlRow.citation)}
                >
                  <Copy />
                </IconButton>
              </div>
            );
            detailRow.doiDatasetLink = makeExternalLink(
              dtlRow.doiDataset,
              `/DatasetLandingPage?doidataset=`
            );
            detailRow.queryPidLink = makeExternalLink(
              dtlRow.queryPid,
              `/DatasetLandingPage?doidataset=`
            );
            detailRow.deviceNameLink = makeExternalLink(
              dtlRow.deviceName,
              `/DeviceListing?DeviceId=`,
              dtlRow.deviceId
            );
            detailRow.siteIdLink = makeExternalLink(
              dtlRow.siteId,
              `/Sites?siteId=`
            );
            detailRow.dateFrom = formatDate(dtlRow.dateFrom);
            detailRow.dateTo = formatDate(dtlRow.dateTo);

            return detailRow;
          });
        }
        return tableRow;
      });
      return formattedRows;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsTable]
  );

  useEffect(() => {
    if (rows && rows.length >= 0) {
      setRowsState(formatRows(rows));
    }
  }, [rows, isOptionTableExanded, formatRows]);

  // used to clear selected row id and close copy citation dialog
  const closeRowDetails = () => {
    setIsCopyDialogOpen(false);
  };

  // used for copying the citation to the user's clipboard
  const onCopy = (data: { copyCitation: string }) => {
    navigator.clipboard.writeText(data.copyCitation);
    onInfo('Citation Copied to Clipboard');
    // close dialog when you copy, or should user have to click cancel?
    closeRowDetails();
  };

  // handles making the sub-rows and the data options table
  // eslint-disable-next-line react/no-unstable-nested-components
  const RowDetail = (parentRow: Search) => (
    <Grid container spacing={1}>
      <Grid item xs={12} aria-label="search details">
        <StatelessTable
          columns={SEARCH_DETAIL_COLUMNS}
          // eslint-disable-next-line react/destructuring-assignment
          rows={parentRow.row.searchDtls}
          columnExtensions={searchDetailColumnExtensions}
        />
      </Grid>
    </Grid>
  );
  let className = classes.searches;
  if (pagingOnTop) {
    className += ` ${classes.pagingOnTop}`;
  }

  return (
    <div className={className}>
      <CopyCitationDialog
        onCopy={onCopy}
        open={isCopyDialogOpen}
        onClose={closeRowDetails}
        citationValue={citationValue}
      />
      <StatelessTable
        cellComponent={CellComponent}
        columns={COLUMNS}
        rows={rowsState}
        paging={{
          totalCount: paging.count,
          currentPage: paging.page,
          onCurrentPageChange: paging.setPage,
          pageSize: paging.pageSize,
          onPageSizeChange: paging.setPageSize,
          pageSizes: [30, 50, 100],
        }}
        expandRow={{
          expandedRows,
          RowDetail,
          handleExpandRowChange: (rowValue) => {
            setExpandedRows(rowValue);
          },
        }}
        columnExtensions={searchHistoryColumnExtensions}
        sort={{
          sorting: sorting !== undefined ? sorting.sortOrder : undefined,
          columnExtensions: [
            { columnName: 'optionsTable', sortingEnabled: false },
            { columnName: 'path', sortingEnabled: false },
            { columnName: 'dataProduct', sortingEnabled: false },
            { columnName: 'searchSource', sortingEnabled: false },
          ],
          handleSortingChange:
            sorting !== undefined ? sorting.setSortOrder : undefined,
        }}
      />
    </div>
  );
};
export default withSnackbars(SearchHistoryTable);
