import { TableColumnDataTypes } from 'base-components';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';

export type Attribute = {
  taxonomyAttributeLineId?: number;
  attributeId: number;
  dataType: TableColumnDataTypes;
  name: string;
  selectable?: boolean;
  value?: any;
  groupId: number;
  attributeValues?: { label: string; value: number | string }[];
  canBeUpdated?: boolean;
  groupName: string;
};

export type TableAnnotationProps = {
  id: number;
  startDate: string;
  dateFrom?: string;
  createdBy: string;
  modifiedBy: string;
  createdDate: string;
  modifiedDate: string;
  taxon: string;
  taxonomy: string;
  attributes: Attribute[];
  comment: string;
  toBeReviewed: boolean;
  numPositiveReviews: number;
  numTotalReviews: number;
  lat: number;
  lon: number;
  depth: number;
  heading: number;
  cruiseName: string;
  logName: string;
};

export default class TableAnnotation {
  id: number;

  startDate: string;

  dateFrom?: string;

  createdBy: string;

  createdUserId: number;

  modifiedBy: string;

  createdDate: string;

  modifiedDate: string;

  taxon: string;

  taxonomy: string;

  attributes: Attribute[];

  comment: string;

  toBeReviewed: boolean;

  numPositiveReviews: number;

  numTotalReviews: number;

  lat: number;

  lon: number;

  depth: number;

  heading: number;

  resourceId: number;

  resourceTypeId: number;

  taxonomyId: number;

  taxonId: number;

  videoResourceId: number;

  videoResourceTypeId: number;

  cruiseName: string;

  logName: string;

  constructor(annotation: any) {
    const {
      annotationId,
      startDate,
      dateFrom,
      createdBy,
      modifiedBy,
      createdDate,
      modifiedDate,
      comment,
      toBeReviewed,
      numPositiveReviews,
      numTotalReviews,
      lat,
      lon,
      depth,
      heading,
      resourceId,
      resourceTypeId,
      videoResourceId,
      videoResourceTypeId,
      cruiseName,
      logName,
    } = annotation;
    const taxon = annotation.taxons ? annotation.taxons[0] : undefined;
    const attributes = taxon ? taxon.attributes : [];
    this.id = annotationId;
    this.startDate = startDate || dateFrom;
    this.createdBy = `${createdBy.firstName} ${createdBy.lastName}`;
    this.createdUserId = createdBy.userId;
    this.modifiedBy = `${modifiedBy.firstName} ${modifiedBy.lastName}`;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
    this.taxon = taxon ? taxon.displayText : '';
    this.taxonomy = taxon ? taxon.taxonomyCode : '';
    this.attributes = attributes.sort((a: Attribute, b: Attribute) =>
      a.name > b.name ? 1 : -1
    );
    this.comment = comment;
    this.toBeReviewed = toBeReviewed;
    this.numPositiveReviews = numPositiveReviews;
    this.numTotalReviews = numTotalReviews;
    this.lat = lat;
    this.lon = lon;
    this.depth = depth;
    this.heading = heading;
    this.resourceId = resourceId;
    this.resourceTypeId = resourceTypeId;
    this.taxonomyId = taxon ? taxon.taxonomyId : '';
    this.taxonId = taxon ? taxon.taxonId : '';
    this.videoResourceId = videoResourceId || 0;
    this.videoResourceTypeId = videoResourceTypeId || 0;
    this.cruiseName = cruiseName || '';
    this.logName = logName || '';
  }

  // Convert to ListAnnotation type
  toListAnnotation = (): ListAnnotation => ({
    // Core fields
    annotationId: this.id,
    comment: this.comment,
    startDate: this.startDate,
    toBeReviewed: this.toBeReviewed,
    resourceId: this.resourceId,
    resourceTypeId: this.resourceTypeId,

    // Location data
    lat: this.lat,
    lon: this.lon,
    depth: this.depth,
    heading: this.heading,

    // User data
    createdBy: {
      userCode: this.createdUserId,
      firstName: this.createdBy.split(' ')[0],
      lastName: this.createdBy.split(' ')[1] || '',
    },
    createdDate: this.createdDate,
    modifiedBy: {
      userCode: this.createdUserId, // Assuming the same user ID for created and modified
      firstName: this.modifiedBy.split(' ')[0],
      lastName: this.modifiedBy.split(' ')[1] || '',
    },
    modifiedDate: this.modifiedDate,

    // Taxonomy data
    taxons: this.taxon
      ? [
          {
            displayText: this.taxon,
            taxonId: this.taxonId,
            taxonomyId: this.taxonomyId,
            taxonomyCode: this.taxonomy,
            attributes: this.attributes.map((attr) => ({
              attributeId: attr.attributeId,
              dataType: attr.dataType,
              groupId: attr.groupId,
              groupName: attr.groupName,
              name: attr.name,
              value: attr.value || '',
            })),
          },
        ]
      : [],
  });
}
