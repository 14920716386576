import * as React from 'react';
import { Theme } from '@mui/material';
import { createStyles, withStyles, ClassNameMap } from '@mui/styles';
import { SplitScreen } from '@onc/icons';
import { AppBar, IconButton, Toolbar, Typography } from 'base-components';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import AddWidgetButton from './AddWidgetButton';
import { WidgetOption } from './DashboardTypes';
import { WindowController } from './useWindowController';

type Props = {
  title: string;
  actionComponents?: React.ReactNode[];
  widgetOptions: WidgetOption[];
  onAddWidget: (widgetOption: WidgetOption) => void;
  classes: ClassNameMap;
  windowController?: WindowController;
  isStatic?: boolean;
  titleComponents?: React.ReactNode;
};

const STYLES = (theme: Theme) =>
  createStyles({
    secondaryAppBar: {
      backgroundColor: theme.palette.primary.light,
      position: 'relative',
      overflow: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    title: {
      flexGrow: 0,
    },
    titleComponents: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    },
  });

const DashboardToolbar: React.FC<Props> = ({
  classes,
  title,
  actionComponents = [],
  widgetOptions,
  windowController = undefined,
  isStatic = false,
  titleComponents = undefined,
  onAddWidget,
}: Props) => {
  const { dashboardState } = useDashboardState();
  return (
    <AppBar position="relative">
      <Toolbar className={classes.secondaryAppBar}>
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.titleComponents}>{titleComponents}</div>
        {actionComponents}
        {windowController ? (
          <IconButton
            color="inherit"
            onClick={() => windowController.openNewWindow(dashboardState)}
            aria-label="Open new window"
          >
            <SplitScreen fontSize="small" />
          </IconButton>
        ) : undefined}
        {isStatic ? undefined : (
          <AddWidgetButton
            widgetOptions={widgetOptions}
            onAddWidget={onAddWidget}
            disabled={!widgetOptions || widgetOptions.length === 0}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(STYLES)(DashboardToolbar);
