import { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import Environment from '@onc/environment';
import {
  getEmptyFilter,
  StatelessTable,
  TableColumn,
  TableFilter,
  TableFilterLogic,
} from 'base-components';
import { useLocalStorage } from 'util/hooks/useStorage';
import { BulkUpdateRow } from './DeviceWorkflowBulkUpdateTableLogic';

interface ResultsProps {
  rows: BulkUpdateRow[];
  selection: number[];
  setSelection: (selectedRows: number[]) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      height: '650px',
    },
  })
);

const DeviceWorkflowBulkUpdateResultsTab = ({
  rows,
  selection,
  setSelection,
}: ResultsProps) => {
  const classes = useStyles();
  const permission = Environment.getDmasUserPrivilege();

  const storageKey = 'device-workflow-bulk-update-results-table';
  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'deviceId', direction: 'asc' }],
  };

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  // Filtering
  const initialFilter = config.filter;
  const [filter, setFilter] = useState<TableFilter>(
    initialFilter ? _.cloneDeep(config.filter) : _.cloneDeep(getEmptyFilter())
  );

  const handleSelectRow = (selectedRows: number[]) => {
    setSelection(selectedRows);
  };

  const columns: TableColumn[] = [
    {
      name: 'device',
      title: 'Device',
      dataType: 'String',
    },
    {
      name: 'processGroup',
      title: 'Process Group',
      dataType: 'String',
    },
    {
      name: 'process',
      title: 'Process',
      dataType: 'String',
    },
    {
      name: 'phase',
      title: 'Phase',
      dataType: 'String',
    },
    {
      name: 'phaseTask',
      title: 'Phase Task',
      dataType: 'String',
    },
    {
      name: 'areaOfResponsibility',
      title: 'Area of Responsibility',
      dataType: 'String',
    },
    {
      name: 'jiraIssueId',
      title: 'Jira Id',
      dataType: 'String',
    },
    {
      name: 'comment',
      title: 'Comment',
      dataType: 'String',
    },
    {
      name: 'status',
      title: 'Status',
      dataType: 'String',
    },
    {
      name: 'modifyBy',
      title: 'Modify By',
      dataType: 'String',
    },
    {
      name: 'modifyDate',
      title: 'Modify Date',
      dataType: 'String',
    },
  ];

  return (
    <StatelessTable
      className={classes.table}
      rows={rows === null ? [] : rows}
      columns={columns}
      getRowId={(row) => row.rowId}
      permission={permission}
      searchable
      visible={{ hiddenColumnNames: [] }}
      sort={{ sorting, customSorting: [], handleSortingChange: setSorting }}
      filter={{
        filterValue: filter,
        onChange: setFilter,
        filterFn: TableFilterLogic.defaultFilter,
        hidden: false,
      }}
      selection={{
        selection,
        onChange: handleSelectRow,
        selectByRowClick: false,
        highlightRow: true,
        showSelectionColumn: true,
        showSelectAll: true,
      }}
    />
  );
};

export default DeviceWorkflowBulkUpdateResultsTab;
