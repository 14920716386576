import { Children, cloneElement, useCallback } from 'react';
import MaterialAutocomplete from '@mui/material/Autocomplete';
import { TextField } from 'base-components';
import useFilteredLocations from 'domain/hooks/useFilteredLocations';
import { ListIconButton } from '../IconButtons';

type LocationsAutocompleteProps = {
  onChange: (event: any, value: any) => void;
  onClick: () => void;
  deviceCategoryCode?: string;
  propertyCode?: string;
  deviceCode?: string;
  showTree: boolean;
  value: object;
};

const LocationsAutocomplete = ({
  onChange,
  onClick,
  deviceCategoryCode = undefined,
  propertyCode = undefined,
  deviceCode = undefined,
  showTree,
  value,
}: LocationsAutocompleteProps) => {
  const addListIconToEndAdornment = useCallback(
    (endAdornment) => {
      const children = Children.toArray(endAdornment.props.children);
      // Adds ListIconButton between the autocomplete clear and dropdown actions
      children.splice(
        1,
        0,
        <ListIconButton
          size="small"
          title={showTree ? 'Hide Location Tree' : 'Show Location Tree'}
          onClick={onClick}
          aria-label={showTree ? 'Hide Location Tree' : 'Show Location Tree'}
        />
      );
      return cloneElement(endAdornment, {}, children);
    },
    [onClick, showTree]
  );

  const locations = useFilteredLocations(
    deviceCategoryCode,
    propertyCode,
    deviceCode
  );

  return (
    <MaterialAutocomplete
      multiple={false}
      options={locations}
      onChange={onChange}
      value={value}
      getOptionLabel={(location) => `${location.name}`}
      noOptionsText="No Location Data Available"
      renderInput={(params) => (
        <TextField
          {...params}
          translationKey="dataSource.location"
          name="locationsAutocomplete"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div>
                {addListIconToEndAdornment(params.InputProps.endAdornment)}
              </div>
            ),
          }}
        />
      )}
    />
  );
};

export default LocationsAutocomplete;
