import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ContainedButton } from '@onc/composite-components';
import { Divider, Grid, Typography } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import DeviceActionService from 'domain/services/DeviceActionService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import DateUtils from 'util/DateUtils';
import { handleRedirect } from './RedirectLink';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(1),
  },
});

const defaultState = {
  deviceCategory: undefined,
  actionName: undefined,
  cruiseName: undefined,
  datePerformed: DateUtils.formatDateAsString(new Date()),
  cost: undefined,
  description: undefined,
  modifyBy: undefined,
  modifyDate: undefined,
  deviceName: undefined,
  deviceId: undefined,
  deviceCategoryname: undefined,
};

class DeviceActionMaintenanceRO extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
      roDivider: PropTypes.string,
    }).isRequired,
    deviceActionId: PropTypes.number,
  };

  static defaultProps = {
    deviceActionId: undefined,
  };

  constructor(props) {
    super(props);
    const { deviceActionId } = this.props;
    this.state = {
      deviceActionId,
      ...defaultState,
    };
  }

  componentDidMount() {
    const { deviceActionId } = this.state;

    if (deviceActionId) {
      this.getDeviceActionService();
    }
  }

  getDeviceActionService = () => {
    const onError = this.props;
    const { deviceActionId } = this.state;

    if (!deviceActionId || isNaN(deviceActionId)) return;
    DeviceActionService.getOne(deviceActionId)
      .then((payload) => {
        const { actionName, cruiseName, datePerformed, ...rest } = payload[0];
        this.setState({
          datePerformed: DateUtils.formatDateAsString(
            new Date(payload[0].datePerformed)
          ),
          actionName: payload[0].actionName,
          cruiseName: payload[0].cruiseName,
          modifyBy: payload[0].modifyByName,
          deviceName: payload[0].deviceName,
          deviceCategoryName: payload[0].deviceCategoryName,
          deviceId: payload[0].deviceId,
          ...rest,
        });
      })
      .catch((e) => {
        onError(e.message);
        this.setState(defaultState);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      deviceActionId,
      deviceId,
      deviceName,
      deviceCategoryName,
      actionName,
      cruiseName,
      datePerformed,
      cost,
      modifyBy,
      modifyDate,
      description,
    } = this.state;

    let deviceActionText = deviceActionId;
    let color;
    if (isNaN(deviceActionId)) {
      color = 'textSecondary';
      deviceActionText = 'New';
    }
    return (
      // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
      <div>
        <Panel
          title={
            <>
              <Typography variant="body1">Device Action Entry ID:</Typography>
              <Typography variant="body1" color={color}>
                {deviceActionText}
              </Typography>
            </>
          }
        >
          <Grid
            container
            direction="row"
            className={classes.entryFormContainer}
          >
            <Grid item xs={6}>
              <MarginedReadOnlyField
                labelText="Device Category"
                valueText={deviceCategoryName}
                title="deviceCategoryTextField"
              />
              <MarginedReadOnlyField
                labelText="Device Name"
                valueText={deviceName}
                title="deviceNameTextField"
              />
              <MarginedReadOnlyField
                labelText="Action Name"
                valueText={actionName}
                title="siteNameTextField"
              />
              <MarginedReadOnlyField
                labelText="Cruise Name"
                valueText={cruiseName}
                title="siteNameTextField"
              />
              <MarginedReadOnlyField
                labelText="Date Performed"
                valueText={datePerformed}
                title="startDateTextField"
              />
              <MarginedReadOnlyField
                labelText="Cost"
                valueText={cost}
                title="siteNameTextField"
              />
              <MarginedReadOnlyField
                labelText="Description"
                valueText={description}
                title="descriptionTextField"
              />
              <MarginedReadOnlyField
                labelText="Modify By"
                valueText={modifyBy}
                title="modifyByField"
              />
              <MarginedReadOnlyField
                labelText="Modify Date"
                valueText={modifyDate}
                title="modifyDateField"
              />
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <ContainedButton
              translationKey="common.buttons.done"
              onClick={handleRedirect({ ...this.props, deviceId })}
            />
          </div>
          <Divider variant="middle" className={classes.roDivider} />
        </Panel>
      </div>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(DeviceActionMaintenanceRO));
