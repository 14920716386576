import { useMutation, useQueryClient } from '@onc/service';
import {
  BATCH_TASK_QUERY_NAME,
  TOP_LEVEL_CHILD_TASKS_QUERY_NAME,
} from 'domain/AppComponents/batch/hooks/BatchTaskQueryHooks';
import TaskDefinitionService from 'domain/AppComponents/batch/service/TaskDefinitionService';
import TaskHistoryService from 'domain/AppComponents/batch/service/TaskHistoryService';
import TMTaskService from 'domain/AppComponents/batch/service/TMTaskService';
import { PAM_TASK_DEFINITION_ID } from 'domain/AppComponents/batch/util/BatchUtils';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { TaskInput } from '../types/TaskInput';

const parseTaskDetailsForService = (taskDetails: any[]) => {
  const taskDetailsOutput = {};
  taskDetails.forEach((taskDetail) => {
    taskDetailsOutput[taskDetail.key] = taskDetail.value;
  });
  return taskDetailsOutput;
};

export const useCreateNewBatchMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      taskType,
      name,
      reason,
      taskDetails,
    }: {
      taskType: string;
      name: string;
      reason: string;
      taskDetails: any[];
    }) => {
      const input = JSON.stringify({ batchName: name, reason });
      if (taskType === 'PAMBatch') {
        const taskDetailsOutput = parseTaskDetailsForService(taskDetails);
        return TMTaskService.createPamguardBatchTask(
          PAM_TASK_DEFINITION_ID,
          input,
          JSON.stringify(taskDetailsOutput)
        );
      }
      return TMTaskService.createBatch(input);
    },
    onSuccess: async (data, variables) => {
      onInfo(`${variables.name} saved with id ${data[0]?.taskId}.`);
      await queryClient.refetchQueries({
        queryKey: [BATCH_TASK_QUERY_NAME],
      });
    },
    onError: async (data, variables) => {
      onError(`Error saving task ${variables.name}`);
    },
  });

  return mutation;
};

export const useUpdateBatchMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      taskId,
      name,
      reason,
    }: {
      taskId: number;
      name: string;
      reason: string;
    }) => {
      const input = JSON.stringify({ batchName: name, reason });
      return TMTaskService.updateTaskInput(taskId, input);
    },
    onSuccess: async (data, variables) => {
      onInfo(`Successfully updated batch ${variables.name}`);
      await queryClient.refetchQueries({
        queryKey: [BATCH_TASK_QUERY_NAME],
      });
    },
    onError: (data, variables) => {
      onError(`Failed to update batch ${variables.name}`);
    },
  });
  return mutation;
};

export const useCancelBatchMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (taskId: number) =>
      TaskHistoryService.cancelBatchTask(`[${taskId}]`),
    onSuccess: async (data, taskId) => {
      onInfo(`Succesfully cancelled task with id ${taskId}`);
      await queryClient.invalidateQueries({
        queryKey: [BATCH_TASK_QUERY_NAME],
      });
      await queryClient.invalidateQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
    },
    onError: (data, taskId) => {
      onError(`Failed to cancel task wtih id ${taskId}`);
    },
  });
  return mutation;
};

export const useDetachTaskMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (taskId: number) => TMTaskService.detachTask(taskId),
    onSuccess: async (data, taskId) => {
      onInfo(`Succesfully detached task with id ${taskId}`);
      await queryClient.invalidateQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
      await queryClient.invalidateQueries({
        queryKey: [BATCH_TASK_QUERY_NAME],
      });
    },
    onError: (data, taskId) => {
      onError(`Failed to detach task with id ${taskId}`);
    },
  });
  return mutation;
};

export const useDeleteTaskMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (taskId: number) => TMTaskService.delete(taskId),
    onSuccess: async (data, taskId) => {
      onInfo(`Succesfully deleted task with id ${taskId}`);
      await queryClient.invalidateQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
      await queryClient.invalidateQueries({
        queryKey: [BATCH_TASK_QUERY_NAME],
      });
    },
    onError: (data, taskId) => {
      onError(`Failed to delete task with id ${taskId}`);
    },
  });
  return mutation;
};

export const useCreateTaskForBatchMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      selectedBatchId,
      taskDefinitionId,
      taskDetails,
    }: {
      selectedBatchId: number;
      taskDefinitionId: number;
      taskDetails: any[];
    }) =>
      TMTaskService.createTaskForBatch(
        selectedBatchId,
        taskDefinitionId,
        JSON.stringify(parseTaskDetailsForService(taskDetails))
      ),
    onSuccess: async (data, variables) => {
      onInfo(
        `Succesfully created batch task for batch id ${variables.selectedBatchId}`
      );
      await queryClient.invalidateQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
    },
    onError: (data, variables) => {
      onError(
        `Failed to create batch task for batch id ${variables.selectedBatchId}}`
      );
    },
  });
  return mutation;
};

export const useUpdateBatchDetailsMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      taskId,
      taskDetails,
    }: {
      taskId: number;
      taskDetails: any[];
    }) => {
      const input = JSON.stringify(parseTaskDetailsForService(taskDetails));
      return TMTaskService.updateTaskInput(taskId, input);
    },
    onSuccess: async (data, variables) => {
      onInfo(`Succesfully updated batch task id ${variables.taskId}`);
      await queryClient.refetchQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
    },
    onError: (data, variables) => {
      onError(`Failed to update batch task id ${variables.taskId}`);
    },
  });
  return mutation;
};

export const useUpdateTaskInputMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      taskId,
      taskInput,
    }: {
      taskId: number;
      taskInput: TaskInput;
    }) => TMTaskService.updateTaskInput(taskId, JSON.stringify(taskInput)),
    onSuccess: async (data, variables) => {
      onInfo(`Succesfully updated batch task id ${variables.taskId}`);
      await queryClient.refetchQueries({
        queryKey: [BATCH_TASK_QUERY_NAME],
      });
    },
    onError: (data, variables) => {
      onError(`Failed to update batch task id ${variables.taskId}`);
    },
  });
  return mutation;
};

export const useChangeBatchTaskSequenceNumberMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      taskId,
      newSequenceNumber,
    }: {
      taskId: number;
      newSequenceNumber: number;
    }) => TMTaskService.updateTaskSequenceNumber(taskId, newSequenceNumber),
    onSuccess: async (data, variables) => {
      onInfo(
        `Succesfully changed sequence number for batch task id ${variables.taskId}`
      );
      await queryClient.refetchQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
    },
    onError: (data, variables) => {
      onError(
        `Failed to change sequence number for batch task id ${variables.taskId}`
      );
    },
  });
  return mutation;
};

export const useRunTaskMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (taskIdToRun: number) =>
      TaskDefinitionService.runTaskNow(taskIdToRun),
    onSuccess: async (data, taskIdToRun) => {
      if (data.data.statusCode === 105) {
        onError(data.data.message);
        return;
      }
      onInfo(`Succesfully ran batch task with id ${taskIdToRun}`);
      await queryClient.invalidateQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
    },
    onError: (data, taskIdToRun) => {
      onError(`Failed to run batch task with id ${taskIdToRun}`);
    },
  });
  return mutation;
};

export const useChangeBatchTaskAutorunMutation = () => {
  const { onInfo, onError } = useSnackbars();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      taskId,
      autorun,
    }: {
      taskId: number;
      autorun: boolean;
    }) => TMTaskService.updateTaskAutorun(taskId, autorun),
    onSuccess: async (data, req) => {
      onInfo(`Succesfully changed autorun for batch task id ${req.taskId}`);
      await queryClient.refetchQueries({
        queryKey: [TOP_LEVEL_CHILD_TASKS_QUERY_NAME],
      });
    },
    onError: (data, req) => {
      onError(`Failed to change autorun for batch task id ${req.taskId}`);
    },
  });
  return mutation;
};
