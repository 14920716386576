import {
  useQuery,
  useQueryClient,
  type QueryObserverOptions as ReactQueryOptions,
  type UseQueryResult as ReactQueryResult,
} from '@tanstack/react-query';
import {
  type AxiosPromise,
  type AxiosResponse,
  type AxiosError,
  type AxiosRequestConfig,
} from 'axios';
import {
  parseDmasAPIResponse,
  type DmasResponseData,
  type UnknownValueRecord,
} from './DmasAPIResponse';
import DmasError from '../DmasError';
import { get } from '../WebRequest';

/**
 * Type for query result, extending from React Query's result with error
 * specifics.
 */
type UseQueryResult<TData> = ReactQueryResult<TData, DmasError | AxiosError> & {
  invalidateQuery: () => Promise<void>;
};

type QueryConfig<TPayload> = {
  operation: number;
  axiosConfig?: AxiosRequestConfig;
  options?: ReactQueryOptions<TPayload, AxiosError | DmasError>;
};

type UseGet = {
  <TPayload, TQueryParams extends UnknownValueRecord = undefined>(
    servicePath: string,
    operationOrConfig: number | QueryConfig<TPayload>,
    params?: TQueryParams
  ): UseQueryResult<TPayload>;
  <
    TPayload,
    TQueryParams extends UnknownValueRecord = undefined,
    TData = unknown,
  >(
    servicePath: string,
    operationOrConfig:
      | number
      | (QueryConfig<TPayload> & {
          transform: (response: AxiosResponse<TData>) => TPayload;
        }),
    params?: TQueryParams
  ): UseQueryResult<TPayload>;
};

const useGet: UseGet = <
  TPayload,
  TQueryParams extends UnknownValueRecord = undefined,
  TData = DmasResponseData<TPayload>,
>(
  servicePath: string,
  operationOrConfig:
    | number
    | (QueryConfig<TPayload> & {
        transform?: (response: AxiosResponse<TData>) => TPayload;
      }),
  queryParams: TQueryParams
): UseQueryResult<TPayload> => {
  const {
    operation,
    axiosConfig = {},
    transform = undefined,
    options = {},
  } = typeof operationOrConfig === 'number'
    ? { operation: operationOrConfig }
    : operationOrConfig;
  const params = { operation, ...(queryParams || {}) };

  const queryKey = [servicePath, params];
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);

  return {
    invalidateQuery,
    ...useQuery({
      queryKey,
      ...options,
      queryFn: ({ signal }): Promise<TPayload> =>
        (
          get(servicePath, params, {
            ...axiosConfig,
            signal,
          }) as AxiosPromise
        ).then((response) =>
          transform ? transform(response) : parseDmasAPIResponse(response)
        ),
    }),
  };
};

export default useGet;
