import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import { Device } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsDeviceDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    deviceCategoryId: PropTypes.number,
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    deviceCategoryId: 0,
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    devices: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getDevices();
  }

  componentDidUpdate(prevProps) {
    const { deviceCategoryId } = this.props;
    if (prevProps.deviceCategoryId !== deviceCategoryId) {
      this.getDevices();
    }
  }

  getDevice = () => {
    const { value } = this.props;
    const { devices } = this.state;
    const currDevice = devices.find((device) => device.value === value);

    if (!currDevice || value === 0) return '';
    return currDevice.label;
  };

  getDevices = () => {
    const { deviceCategoryId } = this.props;

    QaqcTestDetailsService.getQAQCTDDevices(deviceCategoryId)
      .then((results) => {
        const devices = results.map((device) => ({
          label: `${device.deviceName} (${device.deviceId})`,
          value: device.deviceId,
        }));
        this.setState({
          devices: [{ label: 'Select', value: 0 }, ...devices],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      value,
      onChange,
      ...rest
    } = this.props;
    const { devices } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Device"
          options={devices}
          value={value}
          onChange={onChange}
          {...rest}
        />
      );
    }

    return (
      <Device
        classes={{ root: classes.roFieldRoot }}
        device={this.getDevice()}
      />
    );
  }
}
export default QaqcDetailsDeviceDropdown;
