import { useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { ContainedButton, TextButton } from '@onc/composite-components';
import { Grid } from 'base-components';
import LayoutService from 'domain/AppComponents/Dashboard/LayoutService';
import MultiEmailField from 'domain/AppComponents/Form/Fields/MultiEmailField';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import Form from 'library/CompositeComponents/form/Form';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import SortableTable from 'library/CompositeComponents/table/SortableTable';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: { width: '98%' },
    entryFieldsContainer: {
      alignItems: 'flex-start',
    },
    entryFields: {},
    emailEntryField: {
      marginLeft: theme.spacing(1.75),
      width: '98%',
    },
    formButtons: {},
    deleteIconButton: { root: { color: 'secondary' } },
    shareButton: {
      marginTop: theme.spacing(1),
    },
  })
);

const COLUMNS = [
  { name: 'userName', title: 'User' },
  { name: 'permission', title: 'Permission' },
  { name: 'remove', title: 'Remove' },
  { name: 'accountId', title: 'Account Id', hidden: true },
];

type ShareSimpleForm = {
  email: string;
  sharePermission: string;
};

type ShareAdvancedProps = {
  onCancel: () => void;
  sharedWith:
    | {
        accountName: string;
        accountId: number;
        accountType: string;
      }[]
    | number[];
  dashboardId: number;
  onShare: (values: any) => Promise<void>;
  onPermissionChange: (id, close) => void;
  onError: (message) => void;
  classes: {
    entryFieldsContainer: string;
    formButtons: string;
  };
  layout?: {
    adminAccounts: {
      accountName: string;
      accountId: number;
    }[];
  };
  onLayoutChange: () => Promise<void>;
};

const ShareAdvanced = ({
  onCancel,
  sharedWith,
  dashboardId,
  onShare,
  onPermissionChange,
  onError,
  classes,
  layout = {
    adminAccounts: [{ accountName: undefined, accountId: undefined }],
  },
  onLayoutChange,
}: ShareAdvancedProps) => {
  const allClasses = { ...useStyles(), ...classes };

  const [emails, setEmails] = useState([]);
  const [rows, setRows] = useState([]);

  const handleRemovePermission = useCallback(
    (id, permissionLevel) => async () => {
      const rowsAfterRemoval = rows.filter((row) => row.accountId !== id);
      setRows(rowsAfterRemoval);
      let removeFn;
      if (permissionLevel === 'RW') {
        removeFn = LayoutService.removeRWPermission;
      } else {
        removeFn = LayoutService.removeROPermission;
      }
      let updatedLayoutId;
      await removeFn(dashboardId, 'User', id)
        .then((response) => {
          updatedLayoutId = response.data.payload.layout.layoutId;
        })
        .catch((error) =>
          onError(
            `Failed to remove permissions for ${id} on dashboard ${dashboardId}. ${error}`
          )
        );
      onPermissionChange(updatedLayoutId, false);
    },
    [dashboardId, onError, onPermissionChange, rows]
  );

  const deleteIconButton = useCallback(
    (accountId, permission) => (
      <DeleteIconButton
        className={allClasses.deleteIconButton}
        onClick={handleRemovePermission(accountId, permission)}
      />
    ),
    [allClasses.deleteIconButton, handleRemovePermission]
  );

  const createShareAdvancedTableRows = useCallback(() => {
    const newRows = sharedWith.map((sharedAccount) => ({
      userName: sharedAccount.accountName,
      permission: 'Can View',
      remove: deleteIconButton(sharedAccount.accountId, 'RO'),
      accountId: sharedAccount.accountId,
    }));
    layout.adminAccounts.map((adminAccount) =>
      newRows.push({
        userName: adminAccount.accountName,
        permission: 'Can Edit',
        remove: deleteIconButton(adminAccount.accountId, 'RW'),
        accountId: adminAccount.accountId,
      })
    );
    setRows(newRows);
  }, [deleteIconButton, layout.adminAccounts, sharedWith]);

  useEffect(() => {
    createShareAdvancedTableRows();
  }, [createShareAdvancedTableRows]);

  const handleShareAdvanced = async (permission) => {
    for (let i = 0; i < emails.length; i += 1) {
      if (emails[i]) {
        onShare({
          accountName: emails[i],
          sharePermission: permission.sharePermission,
        });
      }
    }
    // after sharing update layout information
    await onLayoutChange();
    createShareAdvancedTableRows();
    setEmails([]);
  };

  const formMethods = useForm<ShareSimpleForm>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      sharePermission: 'RO',
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item sm className={allClasses.table}>
        <SortableTable
          elevation={0}
          rows={rows}
          columns={COLUMNS}
          hiddenColumns={['accountId']}
          height={250}
        />
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={12}>
          <Form onSubmit={handleShareAdvanced} formMethods={formMethods}>
            <Grid
              container
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
              className={allClasses.entryFieldsContainer}
            >
              <Grid item sm={7} className={allClasses.emailEntryField}>
                <MultiEmailField
                  handleChange={setEmails}
                  emailItems={emails || []}
                />
              </Grid>
              <Grid item sm>
                <FormDropdown
                  name="sharePermission"
                  label="Permission"
                  options={[
                    { key: '1', value: 'RO', label: 'Can View' },
                    { key: '2', value: 'RW', label: 'Can Edit' },
                  ]}
                />
              </Grid>
              <Grid item sm className={allClasses.shareButton}>
                <ContainedButton
                  translationKey="common.buttons.share"
                  type="submit"
                />
              </Grid>
            </Grid>
            <div className={allClasses.formButtons}>
              <TextButton
                translationKey="common.buttons.close"
                onClick={onCancel}
              />
            </div>
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShareAdvanced;
