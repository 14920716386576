import _ from 'lodash';
import moment, { Moment } from 'moment';
import { TableColumnDataTypes } from 'base-components';
import { TaxonOption } from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import {
  AttributeLine,
  EntryAttributesSection,
} from 'domain/AppComponents/manual-entry/ManualEntryAttributes';
import { EntryGeneralSection } from 'domain/AppComponents/manual-entry/ManualEntryGeneral';
import { EntryResourceSection } from 'domain/AppComponents/manual-entry/ManualEntryResource';
import { Attribute } from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import { ServiceAnnotation } from 'domain/services/AnnotationService';
import { DiveDetailsJSON } from 'domain/services/DiveService';
import { TaxonMatrixAttributes } from 'domain/services/TaxonAttributeService';
import { TaxonomyAttributeJson } from 'domain/services/TaxonomyAttributeService';

import {
  ManualEntryFormType,
  ManualEntryErrors,
} from '../sea-tube/manual-entry/ManualEntryForm';
import { SeaTubeManualEntryFormType } from '../sea-tube/manual-entry/SeaTubeManualEntryForm';

const COMMENT = 4;
const RESOURCE = 44;
const RESOURCE_TYPE = 43;
const IGNORED_ATTRIBUTES = [RESOURCE_TYPE, RESOURCE, COMMENT];

export type AnnotationFormData = {
  annotationId?: number;
  resourceType: {
    resourceTypeId?: number;
    resourceTypeName: string;
  };
  resource: {
    resourceId?: number;
  };
  startDate: string | Moment;
  endDate: string | Moment | null;
  flagged: boolean;
  annotationContents: any;
  annotationSource: {
    annotationSource: string;
    annotationSourceId: number;
  };
  published: boolean;
  levelIndex: number;
};

export default class ManualEntryLogic {
  static convertFormToServiceData = (
    form: ManualEntryFormType,
    annotationSource?: {
      annotationSource: string;
      annotationSourceId: number;
    }
  ): ServiceAnnotation => {
    const { generalSection, attributesSection, resourceSection } = form;
    const { attributes } = attributesSection;
    const { resourceTypeId, resourceId } = resourceSection;
    const {
      date,
      annotationId,
      toBeReviewed,
      taxon,
      taxonomyId,
      comment,
      shared = true,
      flagged = false,
      startDate = null,
      endDate = null,
    } = generalSection;

    const getResourceTypeName = () => {
      switch (Number(resourceTypeId)) {
        case SeaTubeResourceTypes.DEVICE_DATA:
          return 'Device Data';
        case SeaTubeResourceTypes.DIVE:
          return 'Dive';
        case SeaTubeResourceTypes.EXPEDITION:
          return 'Expedition';
        case SeaTubeResourceTypes.DEVICE:
          return 'Device';
        default:
          return '(none)';
      }
    };

    const createAttributeArray = () => {
      const attributeArray = attributes.map(({ attr, val }: AttributeLine) => {
        if (attr) {
          if (attr.dataType === 'Select') {
            return {
              attributeId: attr.attributeId,
              name: attr.name,
              taxonomyAttributeLineId: val ? val.value : undefined,
            };
          }
          return {
            attributeId: attr.attributeId,
            name: attr.name,
            value: val,
          };
        }
        return undefined;
      });
      return attributeArray.length ? attributeArray : null;
    };

    const createContents = () => {
      const annotationContents: any[] = [];
      if (comment && comment.length > 0) {
        annotationContents.push({
          annotation: comment,
          formField: { formFieldId: 4 },
          taxonId: 0,
          taxonomyId: 0,
        });
      }
      if (taxon) {
        annotationContents.push({
          annotation: 'taxon',
          formField: { formFieldId: 150 },
          annotationAttributes: createAttributeArray(),
          taxonId: taxon.taxonId,
          taxonomyId,
        });
      }
      return annotationContents;
    };

    return {
      annotationId,
      resourceType: {
        resourceTypeId,
        resourceTypeName: getResourceTypeName(),
      },
      resource: {
        resourceId,
        resourceName: '',
      },
      startDate: date || startDate || null,
      endDate: date || endDate || null,
      flagged: toBeReviewed || flagged,
      annotationContents: createContents(),

      annotationSource: annotationSource || {
        annotationSource: 'SeaScribe',
        annotationSourceId: 6,
      },
      published: shared,
      levelIndex: 0,
    };
  };

  static attributeSort = (a: AttributeLine, b: AttributeLine) => {
    if (a.attr && b.attr) {
      return a.attr.name.toLowerCase() < b.attr.name.toLowerCase() ? -1 : 1;
    }
    return -1;
  };

  static convertAttributesToForm = (
    attributes: Attribute[],
    attributeOptions: any[]
  ) => {
    const attributeLines: AttributeLine[] = [];
    attributes.forEach((attr) => {
      if (!IGNORED_ATTRIBUTES.includes(attr.attributeId) && attributeOptions) {
        const attributeOption = attributeOptions.find(
          (option) => option.attributeId === attr.attributeId
        );

        if (attributeOption) {
          attributeLines.push({
            touched: false,
            attr: attributeOption,
            val:
              attr.taxonomyAttributeLineId && attr.taxonomyAttributeLineId > 0
                ? ManualEntryLogic.findAttributeSelectValue(
                    attr.taxonomyAttributeLineId,
                    attributeOption.attributeValues
                  )
                : attr.value || '',
          });
        }
      }
    });
    return attributeLines.sort(ManualEntryLogic.attributeSort);
  };

  static getResourceTypeFromAttributes = (
    attributes: Attribute[]
  ): number | undefined => {
    const resourceTypeAttribute = attributes.find(
      (attr) => attr.attributeId === RESOURCE_TYPE
    );
    if (resourceTypeAttribute) {
      switch (resourceTypeAttribute.value) {
        case 'Dive':
          return SeaTubeResourceTypes.DIVE;
        case 'Device Data':
          return SeaTubeResourceTypes.DEVICE_DATA;
        case 'Device':
          return SeaTubeResourceTypes.DEVICE;
        case 'Expedition':
          return SeaTubeResourceTypes.EXPEDITION;
        default:
          return undefined;
      }
    }
    return undefined;
  };

  static getCommentFromAttributes = (
    attributes: Attribute[],
    initialComment?: string
  ) => {
    const commentAttribute = attributes.find(
      (attr) => attr.attributeId === COMMENT
    );
    if (commentAttribute) {
      return commentAttribute.value || '';
    }
    return initialComment || '';
  };

  static parseTaxonOptions = (response, callBack) => {
    const returnOptions: any[] = [];
    response.forEach((option) => {
      let label = option.parentName
        ? `${option.parentName} / ${option.commonName}`
        : option.commonName;
      if (option.englishNames) {
        label += ` (${option.englishNames.join(', ')})`;
      }
      const value = option.taxonId;
      const { taxonId } = option;
      returnOptions.push({
        label,
        value,
        taxonId,
      });
    });
    callBack(returnOptions);
  };

  static getDefaultResource = (
    config: any,
    taxonomyId: number | undefined,
    cruiseId: number,
    dive: DiveDetailsJSON = null
  ) => {
    if (config) {
      switch (taxonomyId) {
        // WoRMS
        case 1:
          return ManualEntryLogic.getDefaultResourceForTaxonomy(
            config.worms,
            cruiseId,
            dive
          );
        // CMECS
        case 3:
          return ManualEntryLogic.getDefaultResourceForTaxonomy(
            config.cmecs,
            cruiseId,
            dive
          );
        // no taxonomy
        case undefined:
          return ManualEntryLogic.getDefaultResourceForTaxonomy(
            cruiseId > 0 && !dive ? config.deckLog : config.noTaxonomy,
            cruiseId,
            dive
          );
        default:
          return undefined;
      }
    }
    return undefined;
  };

  static getDefaultResourceForTaxonomy = (
    config: any,
    cruiseId: number,
    dive: DiveDetailsJSON = null
  ) => {
    let resourceId;
    switch (config.resourceType) {
      case SeaTubeResourceTypes.EXPEDITION:
        resourceId = cruiseId;
        break;
      case SeaTubeResourceTypes.DIVE:
        resourceId = dive?.diveId;
        break;
      case SeaTubeResourceTypes.DEVICE:
      case SeaTubeResourceTypes.DEVICE_DATA:
        resourceId = dive?.defaultDeviceId;
        break;
      default:
        resourceId = undefined;
    }
    return {
      resourceTypeId: config.resourceType,
      resourceId,
    };
  };

  static findAttributeSelectValue = (taxonomyAttributeLineId, options) => {
    if (taxonomyAttributeLineId) {
      return (
        options.find((option) => option.value === taxonomyAttributeLineId) || ''
      );
    }
    return '';
  };

  static formatResources = (payload) => {
    const { InstrumentList } = payload;
    let instrumentOptions = InstrumentList;
    InstrumentList.forEach((option, index) => {
      instrumentOptions[index].label =
        `${option.instrumentName} (${option.instrumentId})`;
      instrumentOptions[index].value = option.instrumentId.toString();
    });
    instrumentOptions = [
      {
        instrumentId: -1,
        instrumentName: 'empty',
        label: '(none)',
        value: '-1',
      },
      ...instrumentOptions,
    ];
    return instrumentOptions;
  };

  static performFormValidation = (
    { resourceSection, attributesSection, generalSection }: ManualEntryFormType,
    startDate: Moment,
    endDate: Moment,
    nullableAttributes: boolean
  ): ManualEntryErrors => {
    const {
      date: dateString,
      startDate: startString,
      endDate: endString,
      taxonomyId,
      taxon,
    } = generalSection;
    const { resourceTypeId, resourceId } = resourceSection;
    const { attributes } = attributesSection;
    const DISPLAY_FORMAT = 'YYYY-MM-DD HH:mm:ss';
    const errors: ManualEntryErrors = {};
    if (!resourceTypeId) {
      errors.resourceTypeError = 'Required';
    }
    if (!resourceId) {
      errors.resourceError = 'Required';
    }
    // start and end date validation
    if ((startString || endString) && !dateString) {
      const startDateMoment = startString ? moment.utc(startString) : undefined;
      const endDateMoment = endString ? moment.utc(endString) : undefined;
      if (!startDateMoment || !startDateMoment.isValid()) {
        errors.startDateError = 'Invalid start date';
      }
      if (moment.isMoment(endDateMoment) && !endDateMoment.isValid()) {
        errors.endDateError = 'Invalid end date';
      }
      if (
        startDateMoment &&
        endDateMoment &&
        startDateMoment.isAfter(endDateMoment)
      ) {
        errors.startDateError = `Cannot be after ${endDateMoment.format(DISPLAY_FORMAT)}`;
      }
      if (
        endDateMoment &&
        startDateMoment &&
        endDateMoment.isBefore(startDateMoment)
      ) {
        errors.endDateError = `Cannot be before ${startDateMoment.format(DISPLAY_FORMAT)}`;
      }
    } else {
      // single date validation
      const date = moment.utc(dateString);
      if (!date || !date.isValid()) {
        errors.dateError = 'Invalid date';
      }
      if (date && date.isBefore(startDate)) {
        errors.dateError = `Cannot be before ${startDate.format(DISPLAY_FORMAT)}`;
      }
      if (date && date.isAfter(endDate)) {
        errors.dateError = `Cannot be after ${endDate.format(DISPLAY_FORMAT)}`;
      }
    }
    if (taxonomyId && !taxon) {
      errors.taxonError = 'A Taxon is required with a Taxonomy';
    }

    if (attributes && attributes.length && !nullableAttributes) {
      let createkey = true;
      attributes.forEach(({ attr, val }) => {
        if (attr && !val) {
          if (createkey) {
            errors.attributeErrors = {};
            createkey = false;
          }
          errors.attributeErrors[attr.attributeId] = 'Required';
        }
      });
    }
    return errors;
  };

  /**
   * Adds new attributes to the oldAttributes array, replacing any existing
   * attributes with the same attributeId
   *
   * @param oldAttributes - The current attributes
   * @param newAttributes - The new attributes
   * @returns - The updated attributes
   */
  static addAttributes = (
    oldAttributes: AttributeLine[],
    newAttributes: AttributeLine[]
  ): AttributeLine[] => {
    const attributeMap = new Map<number, AttributeLine>();
    oldAttributes.forEach((line) => {
      attributeMap.set(line.attr?.attributeId, line);
    });
    newAttributes.forEach((line) => {
      attributeMap.set(line.attr?.attributeId, line);
    });
    return Array.from(attributeMap.values()).sort(
      ManualEntryLogic.attributeSort
    );
  };

  /**
   * Adds or replaces attributes in the oldAttributes array with the
   * newAttributes array, unless the attribute is already present and touched in
   * the oldAttributes array
   *
   * @param oldAttributes - The current attributes
   * @param newAttributes - The new attributes
   * @returns The updated attributes
   */
  static replaceAttributes = (
    oldAttributes: AttributeLine[],
    newAttributes: AttributeLine[]
  ): AttributeLine[] => {
    const attributeMap = new Map<number, AttributeLine>();

    // Only add oldAttributes if they are touched
    oldAttributes.forEach((line) => {
      if (line.touched) {
        attributeMap.set(line.attr?.attributeId, line);
      }
    });

    // Add or overwrite with newAttributes if not already added as touched
    newAttributes.forEach((line) => {
      if (!attributeMap.has(line.attr?.attributeId) || line.val) {
        attributeMap.set(line.attr?.attributeId, line);
      }
    });

    // Convert Map values to an array and sort
    return Array.from(attributeMap.values()).sort(
      ManualEntryLogic.attributeSort
    );
  };

  static convertListAnnotationToForm = (
    annotation: ListAnnotation
  ): SeaTubeManualEntryFormType => {
    const {
      annotationId,
      resourceTypeId,
      resourceId,
      startDate,
      comment,
      taxons: annotationTaxons,
      isPublic: published,
    } = annotation;

    const taxons = annotationTaxons || [];

    return {
      annotationId,
      resourceTypeId,
      resourceId,
      comment,
      published,
      date: startDate ? moment.utc(startDate) : null,
      taxonomy: taxons[0]?.taxonomyId,
      taxon: taxons[0]?.taxonId
        ? {
            taxonId: taxons[0]?.taxonId,
            value: taxons[0]?.taxonId,
            label: '',
            commonName: '',
          }
        : undefined,

      attributes:
        taxons[0]?.attributes
          ?.map((attr) => ({
            attr: {
              attributeId: attr.attributeId,
              name: attr.name,
              dataType: attr.dataType as TableColumnDataTypes,
              groupId: attr.groupId,
              groupName: attr.groupName,
              attributeValues: [],
            },
            val: attr.value,
            touched: true,
          }))
          .filter((attr) => !attr.attr.name.includes('OBIS')) || [],
    };
  };

  static convertFormToServiceParams = (
    form: SeaTubeManualEntryFormType
  ): ServiceAnnotation => {
    const {
      resourceTypeId,
      resourceId,
      date,
      annotationId,
      toBeReviewed,
      taxon,
      taxonomy: taxonomyId,
      comment,
      attributes,
      published,
    } = form;

    const getResourceTypeName = () => {
      switch (Number(resourceTypeId)) {
        case SeaTubeResourceTypes.DEVICE_DATA:
          return 'Device Data';
        case SeaTubeResourceTypes.DIVE:
          return 'Dive';
        case SeaTubeResourceTypes.EXPEDITION:
          return 'Expedition';
        case SeaTubeResourceTypes.DEVICE:
          return 'Device';
        default:
          return '(none)';
      }
    };

    const createAttributeArray = () => {
      const attributeArray = attributes.map(({ attr, val }: AttributeLine) => {
        if (attr) {
          if (attr.dataType === 'Select') {
            return {
              attributeId: attr.attributeId,
              name: attr.name,
              taxonomyAttributeLineId: val ? val.value : undefined,
            };
          }
          return {
            attributeId: attr.attributeId,
            name: attr.name,
            value: val,
          };
        }
        return undefined;
      });
      return attributeArray.length ? attributeArray : null;
    };

    const createContents = () => {
      const annotationContents: any[] = [];
      if (comment && comment.length > 0) {
        annotationContents.push({
          annotation: comment,
          formField: { formFieldId: 4 },
          taxonId: 0,
          taxonomyId: 0,
        });
      }
      if (taxon) {
        annotationContents.push({
          annotation: 'taxon',
          formField: { formFieldId: 150 },
          annotationAttributes: createAttributeArray(),
          taxonId: taxon.taxonId,
          taxonomyId,
        });
      }
      return annotationContents;
    };

    return {
      annotationId,
      resourceType: {
        resourceTypeId,
        resourceTypeName: getResourceTypeName(),
      },
      resource: {
        resourceId,
        resourceName: '',
      },
      startDate: date?.toISOString() || '',
      endDate: date?.toISOString() || '',
      flagged: toBeReviewed,
      annotationContents: createContents(),

      // Hard coded
      annotationSource: {
        annotationSource: 'SeaScribe',
        annotationSourceId: 6,
      },
      published,
      levelIndex: 0,
    };
  };

  /**
   * Updates the form state of the provided section of ManualEntryForm
   *
   * @param formState - The current form state to be update
   * @param key - The name of the section being updated from
   *   {@link ManualEntryFormType}
   * @param updatedSection - The section to be updated
   * @returns The updated form state
   */
  static handleFormSectionChange = (
    formState: ManualEntryFormType,
    key: string,
    updatedSection:
      | EntryGeneralSection
      | EntryResourceSection
      | EntryAttributesSection
  ): ManualEntryFormType => ({
    ...formState,
    [key]: updatedSection,
  });

  /**
   * Retrieves and sets the default attributes for the selected taxon, and
   * updates the form state accordingly
   *
   * @param taxon - The selected taxon, or null if the taxon has been cleared
   * @param formState - The form's current state
   * @param fetchAttributes - TaxonomyMatrixAttributeService call to fetch
   *   default attributes for the selected taxon
   * @param attributeOptions - The attribute options that are available
   * @param dive - The dive to use in cases where the Resource Type attribute is
   *   set to Dive
   * @returns The updated form state with taxon and default attributes
   */
  static handleTaxonFormChange = async (
    taxon: TaxonOption | null,
    formState: ManualEntryFormType,
    fetchAttributes: (
      taxonomyId: number,
      taxonId: number
    ) => Promise<TaxonMatrixAttributes>,
    attributeOptions: TaxonomyAttributeJson[],
    dive?: DiveDetailsJSON
  ): Promise<ManualEntryFormType> => {
    const updatedForm = _.cloneDeep(formState);
    const { resourceSection, generalSection, attributesSection } = updatedForm;
    generalSection.taxon = taxon
      ? {
          taxonId: taxon.taxonId,
          value: taxon.taxonId,
          label: taxon.commonName,
        }
      : null;
    if (taxon) {
      const attributeData: TaxonMatrixAttributes = await fetchAttributes(
        generalSection.taxonomyId,
        taxon.taxonId
      );
      const validMatrixAttributes = attributeData.getCurrentAttributes();
      const attributes = validMatrixAttributes.map((matrixAttr) =>
        matrixAttr.toAttribute()
      );
      const attributeLines = ManualEntryLogic.convertAttributesToForm(
        attributes,
        attributeOptions
      );

      const newResourceType =
        ManualEntryLogic.getResourceTypeFromAttributes(attributes);
      if (newResourceType) {
        if (newResourceType === SeaTubeResourceTypes.DIVE) {
          resourceSection.resourceId = dive?.diveId;
        }
        resourceSection.resourceTypeId = newResourceType;
      }
      generalSection.comment = ManualEntryLogic.getCommentFromAttributes(
        attributes,
        generalSection.comment
      );

      // If there are default attributes, set them, otherwise leave the existing attributes alone
      if (attributeLines.length > 0) {
        attributesSection.attributes = ManualEntryLogic.replaceAttributes(
          attributesSection.attributes,
          attributeLines
        );
      }
    }
    return updatedForm;
  };
}
