import ChartWidgetDisplay from './ChartWidgetDisplay';
import ChartWidgetConfig from './config/ChartWidgetConfig';
import { type DataSource } from './types/ChartWidgetConfig.types';

type ChartWidgetProps = {
  onSave: () => void;
  onConfigFinish: () => void;
  showDisplay: boolean;
  showChartTitle: boolean;
  showWidgetTitle: boolean;
  widgetTitle: string;
  chartTitle: string;
  datePreset: string;
  dateSelection: 'dateRange' | 'latest';
  startDate: Date;
  endDate: Date;
  autoRefreshEnabled: boolean;
  isMinMaxEnabled: boolean;
  isRawCleanEnabled: boolean;
  alwaysShowModebar: boolean;
  locationsLeftAxis: DataSource[];
  locationsRightAxis?: DataSource[];
  displayQaqc: boolean;
  enableTraceLegend?: boolean;
};

const ChartWidget: React.FC<ChartWidgetProps> = ({
  ...props
}: ChartWidgetProps) => {
  const renderWidgetMode = () => {
    const {
      showDisplay,
      onConfigFinish,
      onSave,
      enableTraceLegend = false,
      ...rest
    } = props;
    if (showDisplay) {
      return (
        <ChartWidgetDisplay {...rest} enableTraceLegend={enableTraceLegend} />
      );
    }
    return (
      <ChartWidgetConfig
        onSave={onSave}
        onCancel={onConfigFinish}
        open
        onConfigFinish={onConfigFinish}
        {...rest}
      />
    );
  };

  return <>{renderWidgetMode()}</>;
};

export default ChartWidget;
