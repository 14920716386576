import { PlaylistAnnotation } from 'domain/Apps/seatube/util/AnnotationUtil';
import { PublicAnnotation } from '../../AnnotationService';

export type AnnotationsAPIResponse = {
  annotations: PublicAnnotation[];
};

const mockUnlistedAnnotationData: AnnotationsAPIResponse = {
  annotations: [
    {
      annotationId: 15888200,
      deviceCode: 'ICE_CREAM_123',
      dateFrom: '2016-04-13T03:00:00.000Z',
      dateTo: '2016-04-13T00:03:00.000Z',
      createdDate: '2016-04-16T00:00:00.000Z',
      createdBy: {
        userCode: '43759',
        firstName: 'Josh',
        lastName: 'Stelting',
      },
      modifiedDate: '2014-04-20T00:00:00.000Z',
      modifiedBy: {
        userCode: '43759',
        firstName: 'Josh',
        lastName: 'Stelting',
      },
      isPublic: true,
      toBeReviewed: false,
      comment: 'Served ice cream!',
      taxons: [
        {
          taxonomy: 'ONC',
          taxonomyId: 100,
          taxonomyCode: 'DS01',
          taxon: 'Connection',
          taxonUrl: 'https://www.example.com/taxon/557290',
          commonNames: ['A', 'B'],
          taxonId: 557290,
          referenceId: '12345',
          attributes: [
            {
              attributeId: 370,
              group: 'ONC DS',
              groupId: 10,
              name: 'Select',
              value: 'Marine Ops',
              dataType: 'Select',
            },
            {
              attributeId: 310,
              group: 'ONC DS',
              groupId: 10,
              name: 'Port Label',
              value: 'aaa',
              dataType: 'String',
            },
          ],
        },
      ],
      numPositiveReviews: 41,
      numTotalReviews: 42,
    },
    {
      annotationId: 12346,
      deviceCode: 'ICE_CREAM_123',
      dateFrom: '1970-01-01T00:05:00.000Z',
      dateTo: '1970-01-01T00:05:00.000Z',
      createdDate: '1970-01-01T00:05:00.000Z',
      createdBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      modifiedDate: '1970-01-01T00:10:00.000Z',
      modifiedBy: {
        userCode: 'subjective-heroic-tuna',
        firstName: 'Jane',
        lastName: 'ONC',
      },
      isPublic: true,
      toBeReviewed: false,
      comment: 'Already out of ice cream. Must bring more next time!',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
    {
      annotationId: 12347,
      deviceCode: 'ICE_CREAM_123',
      dateFrom: '1970-01-01T00:10:00.000Z',
      dateTo: '1970-01-01T00:10:00.000Z',
      createdDate: '1970-01-01T00:10:00.000Z',
      createdBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      modifiedDate: '1970-01-01T00:10:00.000Z',
      modifiedBy: {
        userCode: 'subjective-heroic-tuna',
        firstName: 'Jane',
        lastName: 'ONC',
      },
      isPublic: false,
      toBeReviewed: false,
      comment: 'No more ice cream. How dare they not have enough!',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
    {
      annotationId: 12348,
      deviceCode: 'ICE_CREAM_123',
      dateFrom: '1970-01-01T00:15:00.000Z',
      dateTo: '1970-01-01T00:15:00.000Z',
      createdDate: '1970-01-01T00:05:00.000Z',
      createdBy: {
        userCode: '1234',
        userId: 43759,
        firstName: 'Josh',
        lastName: 'Stelting',
      },
      modifiedDate: '1970-01-01T00:10:00.000Z',
      modifiedBy: {
        userCode: '1234',
        userId: 12345,
        firstName: 'Yan',
        lastName: 'Chen',
      },
      isPublic: true,
      toBeReviewed: false,
      comment: 'Josh Public Annotation',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
    {
      annotationId: 15896900,
      deviceCode: 'ICE_CREAM_123',
      dateFrom: '1970-01-01T00:20:00.000Z',
      dateTo: '1970-01-01T00:20:00.000Z',
      createdDate: '1970-01-01T00:05:00.000Z',
      createdBy: {
        userCode: '43759',
        userId: 43759,
        firstName: 'Josh',
        lastName: 'Stelting',
      },
      modifiedDate: '1970-01-01T00:10:00.000Z',
      modifiedBy: {
        userCode: '43759',
        userId: 43759,
        firstName: 'Josh',
        lastName: 'Stelting',
      },
      isPublic: false,
      toBeReviewed: false,
      comment: 'Josh Unlisted Annotation',
    },
    {
      annotationId: 12378,
      deviceCode: 'AXISCAMACCC8ED935D1',
      dateFrom: '2016-04-14T15:03:00.000Z',
      dateTo: '2016-04-14T15:03:00.000Z',
      createdDate: '2016-04-19T07:04:00.000Z',
      createdBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      modifiedDate: '2016-04-19T12:04:00.000Z',
      modifiedBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      isPublic: false,
      toBeReviewed: false,
      comment: 'Camera tilted down',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
    {
      annotationId: 12379,
      deviceCode: 'ICE_CREAM_123',
      dateFrom: '2016-04-13T17:03:00.000Z',
      dateTo: '2016-04-13T17:03:00.000Z',
      createdDate: '2016-04-19T07:04:00.000Z',
      createdBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      modifiedDate: '2016-04-19T12:04:00.000Z',
      modifiedBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      isPublic: false,
      toBeReviewed: false,
      comment: 'Cypress test annotation to click',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
    {
      annotationId: 12389,
      deviceCode: 'AXISCAMACCC8ED935D1',
      dateFrom: '2016-04-14T14:00:00.000Z',
      dateTo: '2016-04-14T14:00:00.000Z',
      createdDate: '2016-04-17T07:04:00.000Z',
      createdBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      modifiedDate: '2016-04-19T12:04:00.000Z',
      modifiedBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      isPublic: false,
      toBeReviewed: false,
      comment: 'This is a test annotation for an interval with no video',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
    {
      annotationId: 12457,
      deviceCode: 'ICE_CREAM_123',
      dateFrom: '2016-04-14T21:03:00.000Z',
      dateTo: '2016-04-14T21:03:00.000Z',
      createdDate: '2016-04-19T07:16:00.000Z',
      createdBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      modifiedDate: '2016-04-19T12:04:00.000Z',
      modifiedBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      isPublic: false,
      toBeReviewed: false,
      comment: 'Floating bits',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
    {
      annotationId: 12567,
      deviceCode: 'AXISCAMACCC8ED935D1',
      dateFrom: '2016-04-14T22:05:05.000Z',
      dateTo: '2016-04-14T22:05:05.000Z',
      createdDate: '2016-04-19T07:16:00.000Z',
      createdBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      modifiedDate: '2016-04-19T12:04:00.000Z',
      modifiedBy: {
        userCode: '1234',
        firstName: 'John',
        lastName: 'DMAS',
      },
      isPublic: false,
      toBeReviewed: false,
      comment: 'It goes green!',
      taxons: null,
      numPositiveReviews: 0,
      numTotalReviews: 0,
    },
  ],
};

const mockPlaylistAnnotations: PlaylistAnnotation[] = [
  {
    annotationId: 12378,
    deviceCode: 'AXISCAMACCC8ED935D1',
    deviceId: 45940,
    dateFrom: '2016-04-14T15:03:00.000Z',
    dateTo: '2016-04-14T15:03:00.000Z',
    createdDate: '2016-04-19T07:04:00.000Z',
    createdBy: {
      userCode: '1234',
      firstName: 'John',
      lastName: 'DMAS',
    },
    modifiedDate: '2016-04-19T12:04:00.000Z',
    modifiedBy: {
      userCode: '1234',
      firstName: 'John',
      lastName: 'DMAS',
    },
    isPublic: false,
    toBeReviewed: false,
    comment: 'Camera tilted down',
    taxons: null,
    numPositiveReviews: 0,
    numTotalReviews: 0,
  },
  {
    annotationId: 12379,
    deviceCode: 'ICE_CREAM_123',
    deviceId: 40000,
    dateFrom: '2016-04-13T17:03:00.000Z',
    dateTo: '2016-04-13T17:03:00.000Z',
    createdDate: '2016-04-19T07:04:00.000Z',
    createdBy: {
      userCode: '1234',
      firstName: 'John',
      lastName: 'DMAS',
    },
    modifiedDate: '2016-04-19T12:04:00.000Z',
    modifiedBy: {
      userCode: '1234',
      firstName: 'John',
      lastName: 'DMAS',
    },
    isPublic: false,
    toBeReviewed: false,
    comment: 'Cypress test annotation to click',
    taxons: null,
    numPositiveReviews: 0,
    numTotalReviews: 0,
  },
  {
    annotationId: 12389,
    deviceCode: 'AXISCAMACCC8ED935D1',
    deviceId: 45940,
    dateFrom: '2016-04-14T14:00:00.000Z',
    dateTo: '2016-04-14T14:00:00.000Z',
    createdDate: '2016-04-17T07:04:00.000Z',
    createdBy: {
      userCode: '1234',
      firstName: 'John',
      lastName: 'DMAS',
    },
    modifiedDate: '2016-04-19T12:04:00.000Z',
    modifiedBy: {
      userCode: '1234',
      firstName: 'John',
      lastName: 'DMAS',
    },
    isPublic: false,
    toBeReviewed: false,
    comment: 'This is a test annotation for an interval with no video',
    taxons: null,
    numPositiveReviews: 0,
    numTotalReviews: 0,
  },
];

export default mockUnlistedAnnotationData;
export { mockPlaylistAnnotations };
