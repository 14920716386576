import { Component } from 'react';

import { Menu } from 'base-components';

type TreeMenuProps = {
  children?: import('react').ReactNode;
};

type TreeMenuState = {
  treePath: string;
};
class TreeMenu extends Component<TreeMenuProps, TreeMenuState> {
  static defaultProps = {
    children: undefined,
  };

  render() {
    const { children } = this.props;
    return <Menu menuList>{children}</Menu>;
  }
}

export default TreeMenu;
