import React from 'react';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { CancelButton } from '@onc/composite-components';
import { Grid, LoadingButton, Paper, Typography } from 'base-components';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const useStyles = makeStyles(() => ({
  style: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-8px',
  },
}));

type EditableDomainColumn = {
  name: string;
  label: string;
  fullWidth?: boolean;
  rules?: object;
};

type EditableDomainFormProps = {
  isCreate?: boolean;
  formFields: object;
  formName: string;
  columns: EditableDomainColumn[];
  onClose: () => void;
  onSubmit: (any) => void;
  values: any;
};

const EditableDomainForm: React.FC<EditableDomainFormProps> = ({
  isCreate = false,
  formFields,
  formName,
  columns,
  onClose,
  onSubmit,
  values,
}: EditableDomainFormProps) => {
  const classes = useStyles();
  const formMethods = useForm<typeof formFields>(values);
  const {
    formState: { isSubmitting },
  } = formMethods;

  const renderFields = () =>
    columns.map((column) => (
      <>
        <FormTextField
          name={`${column.name}`}
          translationKey="common.textfields.variable"
          translationOptions={{ variableName: column.label }}
          fullWidth={column.fullWidth === undefined ? false : column.fullWidth}
          rules={column.rules === undefined ? [] : column.rules}
        />
      </>
    ));

  return (
    <Paper
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
        maxWidth: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Typography variant="h6" gutterBottom>
        {isCreate ? `Create ${formName}` : `Edit ${formName}`}
      </Typography>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Grid container rowSpacing={0} columnSpacing={1}>
          <Grid item xs={6}>
            {renderFields()}
          </Grid>
        </Grid>
        <div className={classes.style}>
          <CancelButton onClick={onClose} />
          <LoadingButton
            variant="contained"
            translationKey="common.buttons.save"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </Form>
    </Paper>
  );
};

export default EditableDomainForm;
