import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const defaultUserLayout: OncLayouts = {
  lg: [
    { w: 3, h: 4, x: 0, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 3, h: 9, x: 0, y: 4, i: 'fixed-camera-clip-list-widget' },
    { w: 3, h: 24, x: 9, y: 0, i: 'fixed-camera-annotation-list-widget' },
    { w: 6, h: 13, x: 3, y: 0, i: 'fixed-camera-video' },
    { w: 9, h: 11, x: 0, y: 13, i: 'manual-entry' },
  ],
  md: [
    { w: 8, h: 2, x: 2, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 2, h: 15, x: 0, y: 0, i: 'fixed-camera-clip-list-widget' },
    { w: 10, h: 9, x: 0, y: 27, i: 'fixed-camera-annotation-list-widget' },
    { w: 8, h: 13, x: 2, y: 2, i: 'fixed-camera-video' },
    { w: 10, h: 12, x: 0, y: 15, i: 'manual-entry' },
  ],
  sm: [
    { w: 6, h: 4, x: 2, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 2, h: 15, x: 0, y: 0, i: 'fixed-camera-clip-list-widget' },
    { w: 8, h: 5, x: 0, y: 24, i: 'fixed-camera-annotation-list-widget' },
    { w: 6, h: 11, x: 2, y: 4, i: 'fixed-camera-video' },
    { w: 8, h: 9, x: 0, y: 15, i: 'manual-entry' },
  ],
  xs: [
    { w: 6, h: 4, x: 0, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 6, h: 15, x: 0, y: 29, i: 'fixed-camera-clip-list-widget' },
    { w: 6, h: 5, x: 0, y: 24, i: 'fixed-camera-annotation-list-widget' },
    { w: 6, h: 11, x: 0, y: 4, i: 'fixed-camera-video' },
    { w: 6, h: 9, x: 0, y: 15, i: 'manual-entry' },
  ],
};

const defaultAnonymousLayout: OncLayouts = {
  lg: [
    { w: 3, h: 4, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 3, h: 20, x: 0, y: 4, i: 'fixed-camera-clip-list-widget-1' },
    { w: 3, h: 24, x: 9, y: 0, i: 'fixed-camera-annotation-list-widget-1' },
    { w: 6, h: 24, x: 3, y: 0, i: 'fixed-camera-video-1' },
  ],
  md: [
    { w: 3, h: 3, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 3, h: 10, x: 0, y: 3, i: 'fixed-camera-clip-list-widget-1' },
    { w: 10, h: 11, x: 0, y: 13, i: 'fixed-camera-annotation-list-widget-1' },
    { w: 6, h: 13, x: 3, y: 0, i: 'fixed-camera-video-1' },
  ],
  sm: [
    { w: 8, h: 3, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 2, h: 13, x: 0, y: 3, i: 'fixed-camera-clip-list-widget-1' },
    { w: 8, h: 8, x: 0, y: 16, i: 'fixed-camera-annotation-list-widget-1' },
    { w: 6, h: 13, x: 2, y: 3, i: 'fixed-camera-video-1' },
  ],
  xs: [
    { w: 6, h: 3, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 6, h: 11, x: 0, y: 27, i: 'fixed-camera-clip-list-widget-1' },
    { w: 6, h: 11, x: 0, y: 16, i: 'fixed-camera-annotation-list-widget-1' },
    { w: 6, h: 13, x: 0, y: 3, i: 'fixed-camera-video-1' },
  ],
};

const anonymousVideocentric: OncLayouts = {
  lg: [
    { w: 12, h: 4, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 3, h: 20, x: 0, y: 4, i: 'fixed-camera-clip-list-widget-1' },
    { w: 9, h: 20, x: 3, y: 4, i: 'fixed-camera-video-1' },
  ],
  md: [
    { w: 10, h: 4, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 3, h: 20, x: 0, y: 4, i: 'fixed-camera-clip-list-widget-1' },
    { w: 7, h: 20, x: 3, y: 4, i: 'fixed-camera-video-1' },
  ],
  sm: [
    { w: 8, h: 3, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 8, h: 7, x: 0, y: 17, i: 'fixed-camera-clip-list-widget-1' },
    { w: 8, h: 14, x: 0, y: 3, i: 'fixed-camera-video-1' },
  ],
  xs: [
    { w: 6, h: 3, x: 0, y: 0, i: 'fixed-camera-date-picker-widget-1' },
    { w: 6, h: 8, x: 0, y: 16, i: 'fixed-camera-clip-list-widget-1' },
    { w: 6, h: 13, x: 0, y: 3, i: 'fixed-camera-video-1' },
  ],
};

const userLayoutPlusButtonset: OncLayouts = {
  lg: [
    { w: 12, h: 4, x: 0, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 2, h: 9, x: 0, y: 4, i: 'fixed-camera-clip-list-widget' },
    { w: 2, h: 11, x: 0, y: 13, i: 'fixed-camera-annotation-list-widget' },
    { w: 5, h: 13, x: 2, y: 4, i: 'fixed-camera-video' },
    { w: 10, h: 7, x: 2, y: 17, i: 'manual-entry' },
    { w: 5, h: 13, x: 7, y: 4, i: 'button-set-1' },
  ],
  md: [
    { w: 10, h: 3, x: 0, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 3, h: 13, x: 0, y: 3, i: 'fixed-camera-clip-list-widget' },
    { w: 10, h: 13, x: 0, y: 36, i: 'fixed-camera-annotation-list-widget' },
    { w: 7, h: 13, x: 3, y: 3, i: 'fixed-camera-video' },
    { w: 10, h: 12, x: 0, y: 24, i: 'manual-entry' },
    { w: 10, h: 8, x: 0, y: 16, i: 'button-set-1' },
  ],
  sm: [
    { w: 6, h: 4, x: 2, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 2, h: 15, x: 0, y: 0, i: 'fixed-camera-clip-list-widget' },
    { w: 8, h: 5, x: 0, y: 33, i: 'fixed-camera-annotation-list-widget' },
    { w: 6, h: 11, x: 2, y: 4, i: 'fixed-camera-video' },
    { w: 8, h: 9, x: 0, y: 24, i: 'manual-entry' },
    { w: 8, h: 9, x: 0, y: 15, i: 'button-set-1' },
  ],
  xs: [
    { w: 6, h: 4, x: 0, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 6, h: 15, x: 0, y: 38, i: 'fixed-camera-clip-list-widget' },
    { w: 6, h: 5, x: 0, y: 33, i: 'fixed-camera-annotation-list-widget' },
    { w: 6, h: 11, x: 0, y: 4, i: 'fixed-camera-video' },
    { w: 6, h: 9, x: 0, y: 24, i: 'manual-entry' },
    { w: 6, h: 9, x: 0, y: 15, i: 'button-set-1' },
  ],
};

export {
  defaultAnonymousLayout,
  defaultUserLayout,
  anonymousVideocentric,
  userLayoutPlusButtonset,
};
