import { get, getAbortable, getControlledAbortable } from 'util/WebRequest';

// These are the resample period that are supported by the API (https://data.oceannetworks.ca/OpenAPI#get-/scalardata/location)
// however, the classes that use this call are not strongly typed, this cannot be used until they are
// type ResamplePeriod =
//   | 1
//   | 5
//   | 10
//   | 15
//   | 30
//   | 60
//   | 300
//   | 600
//   | 900
//   | 1800
//   | 3600
//   | 7200
//   | 21600
//   | 43200
//   | 86400
//   | 172800
//   | 259200
//   | 604800
//   | 1209600
//   | 2592000;

const SERVICE = 'api/scalardata';

class ScalarDataService {
  static getByLocation(
    locationCode,
    deviceCategoryCode,
    propertyCode,
    dateFrom,
    dateTo,
    qualityControl,
    resampleType,
    resamplePeriod,
    rowLimit,
    fillGaps,
    byDeployment?,
    sensorCategoryCodes?,
    getLatest = true
  ) {
    const params = {
      method: 'getByLocation',
      locationCode,
      deviceCategoryCode,
      propertyCode,
      dateFrom,
      dateTo,
      qualityControl,
      resampleType,
      resamplePeriod,
      rowLimit,
      fillGaps,
      byDeployment,
      sensorCategoryCodes,
      getLatest,
    };
    return get(SERVICE, params);
  }

  static getByLocationCancellable(
    locationCode,
    deviceCategoryCode,
    propertyCode,
    dateFrom,
    dateTo,
    qualityControl,
    resampleType,
    resamplePeriod,
    fillGaps,
    byDeployment?,
    getLatest = true
  ) {
    const params = {
      method: 'getByLocation',
      locationCode,
      deviceCategoryCode,
      propertyCode,
      dateFrom,
      dateTo,
      qualityControl,
      resampleType,
      resamplePeriod,
      fillGaps,
      byDeployment,
      getLatest,
    };
    const cancellable = getAbortable(SERVICE, params);

    return {
      request: cancellable.request.then((response) => response.data.sensorData),
      cancel: cancellable.cancel,
    };
  }

  static getByLocationControlledAbortable(
    locationCode,
    deviceCategoryCode,
    propertyCode,
    dateFrom,
    dateTo,
    qualityControl,
    resampleType,
    resamplePeriod,
    fillGaps,
    signal,
    byDeployment?,
    getLatest = true
  ) {
    const params = {
      method: 'getByLocation',
      locationCode,
      deviceCategoryCode,
      propertyCode,
      dateFrom,
      dateTo,
      qualityControl,
      resampleType,
      resamplePeriod,
      fillGaps,
      byDeployment,
      getLatest,
    };
    const cancellable = getControlledAbortable(SERVICE, signal, params);

    return {
      request: cancellable.request.then((response) => response.data.sensorData),
    };
  }

  static getByDeviceAndSensor(
    deviceCode: string,
    signal: AbortSignal,
    sensorCategoryCodes?: string,
    dateFrom?: Date,
    dateTo?: Date,
    rowLimit?: number,
    outputFormat?: 'Array' | 'Object',
    returnOptions?: 'excludeScalarData',
    getLatest?: boolean,
    qualityControl?: 'raw' | 'clean' | undefined,
    resampleType?: string,
    resamplePeriod?: number,
    fillGaps?: boolean,
    sensorsToInclude?: 'original' | 'externallyDerived',
    byDeployment?
  ) {
    const params = {
      method: 'getByDevice',
      deviceCode,
      sensorCategoryCodes,
      dateFrom,
      dateTo,
      rowLimit,
      outputFormat,
      returnOptions,
      getLatest,
      qualityControl,
      resampleType,
      resamplePeriod,
      fillGaps,
      sensorsToInclude,
      byDeployment,
    };
    const cancellable = getControlledAbortable(SERVICE, signal, params);

    return {
      request: cancellable.request.then((response) => response.data.sensorData),
    };
  }
}

export default ScalarDataService;
