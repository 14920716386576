/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';

import * as React from 'react';
import qs from 'qs';
import { ErrorAlert } from '@onc/composite-components';
import { UserDetailsService } from '@onc/domain-services';
import { CircularProgress } from 'base-components';
import PlaylistContext, {
  PlaylistContextInterface,
} from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistContext';
import PlaylistPlaybackDashboard from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistPlaybackDashboard';

import UserDetailsContext, {
  UserContext,
} from 'domain/AppComponents/sea-tube/UserDetailsContext';
import PlaylistLineService from 'domain/services/PlaylistLineService';
import PlaylistService, { Playlist } from 'domain/services/PlaylistService';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';

interface PlaylistPlaybackParams {
  playlistHdrId: string;
}

type Props = {
  match: {
    isExact: boolean;
    params: PlaylistPlaybackParams;
    path: string;
    url: string;
  };
};

const PlaylistPlaybackApp: React.VFC<Props> = (props: Props) => {
  const { match } = props;
  const { playlistHdrId } = match.params;
  const { onError } = useSnackbars();

  const [playlist, , fetchPlaylist] = useWebService({
    method: PlaylistService.getPlaylist,
    onError,
  });

  const [playlistLines, , fetchPlaylistLines] = useWebService({
    method: PlaylistLineService.getMany,
    onError,
  });

  const [groups, , fetchGroups] = useWebService({
    method: PlaylistService.getPlaylists,
    parser: (data: Playlist[]) => [
      ...new Set(
        data
          .map((item) => item.groupName)
          .filter((item) => typeof item === 'string')
      ),
    ],
    onError,
  });

  const handleUserData = (data: string): UserContext => ({
    userId: Number(data),
  });

  const [userId, , fetchUserId] = useWebService({
    method: UserDetailsService.getUserId,
    parser: handleUserData,
    onError,
  });

  // Handle Query parameters
  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const clipId = parseInt(params.clipId as string, 10);

  const handleError = (errorMsg: string) => {
    onError(errorMsg);
  };

  const context: PlaylistContextInterface = useMemo(
    () => ({
      playlist,
      playlistLines,
      groups,
      clipId: PlaylistLineService.getValidClipId(
        playlistLines,
        clipId,
        handleError
      ),
      refreshPlaylistLines: fetchPlaylistLines,
      refreshPlaylist: fetchPlaylist,
    }),
    [playlist, playlistLines, groups]
  );

  useEffect(() => {
    fetchUserId();
    fetchGroups();
    fetchPlaylist(Number(playlistHdrId));
    fetchPlaylistLines(Number(playlistHdrId));
  }, []);

  const renderContent = () => {
    if (playlist === null) {
      return (
        <ErrorAlert title="Error">
          There was a problem getting playlist id: {playlistHdrId}
        </ErrorAlert>
      );
    }

    if (
      userId === undefined ||
      playlist === undefined ||
      playlistLines === undefined ||
      groups === undefined
    ) {
      return (
        <CircularProgress
          size={120}
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
          }}
        />
      );
    }

    return (
      <UserDetailsContext.Provider value={userId}>
        <PlaylistContext.Provider value={context}>
          <PlaylistPlaybackDashboard />
        </PlaylistContext.Provider>
      </UserDetailsContext.Provider>
    );
  };

  return (
    <div
      style={{
        backgroundColor: '#EEE',
        height: '100%',
      }}
    >
      {renderContent()}
    </div>
  );
};

export default withSnackbars(PlaylistPlaybackApp);
