/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';

import * as React from 'react';
import { Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { TextButton } from '@onc/composite-components';
import { ExpandMore, Update } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DateTimePicker,
  Grid,
  Tooltip,
  TextField,
  Typography,
  Autocomplete,
} from 'base-components';
import TaxonAsyncAutocomplete, {
  TaxonOption,
} from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import TaxonomyService from 'domain/services/TaxonomyService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import useWebService from 'util/hooks/useWebService';
import { ManualEntryErrors } from '../sea-tube/manual-entry/ManualEntryForm';

type Props = {
  classes: any;
  value: EntryGeneralSection;
  onChange: (section: EntryGeneralSection) => void;
  onTaxonChange: (value: TaxonOption | null) => void;
  onErrorChange: React.Dispatch<React.SetStateAction<ManualEntryErrors>>;
  errors: ManualEntryErrors;
  minDate: Moment;
  maxDate: Moment;
  currentTimestamp: Moment;
  onError: (message: string) => void;
  startAndEndDates: boolean;
};

type Option = {
  label: string;
  value: number;
  key: string;
};

export interface EntryGeneralSection {
  annotationId?: number;
  date?: string | null;
  taxonomyId?: number;
  taxon?: {
    taxonId: number;
    value: number;
    label: string;
    commonName?: string;
  } | null;
  taxonName?: any;
  comment?: string;
  toBeReviewed?: boolean;
  shared?: boolean;
  flagged?: boolean;
  startDate?: string | null;
  endDate?: string | null;
}

const STYLES = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
    },
    flexContainerCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    flexGrow: {
      flexGrow: 1,
    },
    marginLeft: {
      marginLeft: theme.spacing(),
    },
    noShrink: {
      flexShrink: 0,
    },
    datePicker: {
      maxWidth: '280px',
    },
    firstColumn: {
      width: '230px',
      marginRight: theme.spacing(),
      flexShrink: 0,
    },
  });

// TODO: update with actual CI Taxonomy when established
const CI_TAXONOMY: Option = {
  label: 'Compromised Instruments',
  value: 1100,
  key: '1100',
};

const ManualEntryGeneral: React.FC<Props> = ({
  value,
  onChange,
  onTaxonChange,
  classes,
  errors,
  onError,
  onErrorChange,
  minDate,
  maxDate,
  currentTimestamp,
  startAndEndDates,
}: Props) => {
  const { comment, date, taxonomyId, taxon, startDate, endDate } = value;

  const [taxonomyOptions, setTaxonomyOptions] = useState<Option[]>([]);

  // User Defined if not WoRMS or CMECS
  const userDefined = !(taxonomyId === 1 || taxonomyId === 3);

  const [taxonomies, , fetchTaxonomies] = useWebService({
    method: TaxonomyService.getAll,
    onError,
  });

  const [expanded, setExpanded] = useState(true);
  const handleExpand = () => setExpanded(!expanded);

  useEffect(() => {
    !startAndEndDates ? fetchTaxonomies() : setTaxonomyOptions([CI_TAXONOMY]);
  }, []);

  useEffect(() => {
    if (errors.dateError || errors.taxonError) {
      setExpanded(true);
    }
  }, [errors]);

  useEffect(() => {
    if (taxonomies) {
      setTaxonomyOptions(
        taxonomies.map((item) => ({
          label: item.taxonomyName,
          value: item.taxonomyId,
          key: item.taxonomyCode,
        }))
      );
    }
  }, [taxonomies]);

  const handleChange = (val: any, property: keyof EntryGeneralSection) => {
    const updatedSection = _.cloneDeep(value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updatedSection[property] = val;
    onChange(updatedSection);
  };

  const handleCaptureTime = useCallback(() => {
    const updatedSection = _.cloneDeep(value);
    updatedSection.date = moment.utc().startOf('second').toISOString();
    if (currentTimestamp) {
      updatedSection.date = moment(currentTimestamp)
        .startOf('second')
        .toISOString();
    }
    onChange(updatedSection);
  }, [value, currentTimestamp, onChange]);

  const handleTaxonChange = (e, val: TaxonOption) => {
    onTaxonChange(val);
  };

  const handleDateError = (error: string | null, key: string) => {
    onErrorChange((prev) => ({ ...prev, [key]: error }));
  };

  const handleTaxonomyChange = useCallback(
    (e, val: Option) => {
      handleChange(val ? val.value : null, 'taxonomyId');
    },
    [taxonomyOptions, value]
  );

  const renderDateFields = () => {
    if (startAndEndDates) {
      return (
        <div className={classes.flexContainerCenter}>
          <DateTimePicker
            translationKey="common.datepickers.startDate"
            onChange={(val) => handleChange(val, 'startDate')}
            onError={(e) => handleDateError(e, 'startDateError')}
            value={startDate ? moment.utc(startDate) : null}
            maxDateTime={endDate ? moment.utc(endDate) : null}
            helperText={errors.startDateError}
            error={!!errors.startDateError}
            className={`${classes.datePicker} ${classes.firstColumn}`}
          />
          <DateTimePicker
            translationKey="common.datepickers.endDate"
            onChange={(val) => handleChange(val, 'endDate')}
            onError={(e) => handleDateError(e, 'endDateError')}
            value={endDate ? moment.utc(endDate) : null}
            minDateTime={startDate ? moment.utc(startDate) : null}
            helperText={errors.endDateError}
            error={!!errors.endDateError}
            className={classes.datePicker}
            disabled={!startDate || !moment.utc(startDate).isValid()}
          />
        </div>
      );
    }

    return (
      <div className={classes.flexContainerCenter}>
        <DateTimePicker
          translationKey="common.datepickers.date"
          onChange={(val) => handleChange(val, 'date')}
          onError={(e) => handleDateError(e, 'dateError')}
          value={date ? moment.utc(date) : null}
          minDateTime={minDate}
          maxDateTime={maxDate}
          helperText={errors.dateError}
          error={!!errors.dateError}
          className={classes.datePicker}
        />
        <Tooltip title="Capture Time">
          <span>
            <TextButton
              translationKey="seatube.captureTime"
              translationOptions={{ historical: '' }}
              className={[classes.marginLeft, classes.noShrink].toString()}
              onClick={handleCaptureTime}
              startIcon={<Update />}
            />
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleExpand}>
        <AccordionSummary
          data-test="general-accordion"
          expandIcon={<ExpandMore data-test="expand" name="expand" />}
        >
          <Typography>General</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              {renderDateFields()}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.flexContainer}>
                <Autocomplete
                  className={classes.firstColumn}
                  name="taxonomy-select"
                  translationKey="taxonomy.taxonomy"
                  onChange={handleTaxonomyChange}
                  value={
                    taxonomyOptions.find(
                      (option) => option.value === taxonomyId
                    ) || null
                  }
                  isOptionEqualToValue={(option, val) =>
                    option.value === val.value
                  }
                  getOptionLabel={(option) => option.label}
                  options={taxonomyOptions}
                  disabled={startAndEndDates && !!taxonomyId}
                />
                <div className={classes.flexGrow}>
                  <TaxonAsyncAutocomplete
                    taxonomyId={taxonomyId}
                    onChange={handleTaxonChange}
                    taxonId={taxon?.taxonId || null}
                    searchLettersRequired={userDefined ? 1 : 3}
                    error={!!errors.taxonError}
                    errorText={errors.taxonError}
                  />
                </div>
              </div>
            </Grid>
            <TextField
              translationKey="common.textfields.comment"
              fullWidth
              multiline
              rows={3}
              name="comment-text-area2"
              onChange={(e) => handleChange(e.target.value, 'comment')}
              value={comment}
              InputLabelProps={{ shrink: !!comment }}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default withStyles(STYLES)(withSnackbars(ManualEntryGeneral));
