import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '@onc/composite-components';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import Form from 'library/CompositeComponents/form/Form';
import FormDatePicker from 'library/CompositeComponents/form/FormDatePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import { ATTRIBUTE_LABEL_CODE } from './QaqcDetailsAttributesConstants';

const classes = {
  root: {
    display: 'flex',
    width: '100%',
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type QaqcAttributesConfigForm = {
  sensorAttributeId?: number;
  resourceAttributeId: number;
  attributeId: number;
  attributeValue: string;
  dateFrom: Moment;
  attributeComment: string;
  modifyBy: string;
  modifyDate: string;
};

type QaqcAttributesConfigProps = {
  onSave: (form: object) => void;
  onCancel: () => void;
  sensorAttribute?: {
    qaqcId: number;
    resourceAttributeId: number;
    sensorAttributeId: number;
    dateFrom: string;
    attributeValue: string;
    effectiveDateFrom: string;
    attributeComment: string;
    modifyBy: string;
    modifyDate: string;
    attributeId: number;
    modifyByString: string;
  };
  formulaAttributes: Array<string>;
};

const QaqcAttributesConfig = ({
  onSave,
  onCancel,
  sensorAttribute = {
    qaqcId: undefined,
    resourceAttributeId: undefined,
    sensorAttributeId: undefined,
    dateFrom: undefined,
    attributeValue: undefined,
    effectiveDateFrom: undefined,
    attributeComment: undefined,
    modifyBy: undefined,
    modifyDate: undefined,
    attributeId: undefined,
    modifyByString: undefined,
  },
  formulaAttributes,
}: QaqcAttributesConfigProps) => {
  const formMethods = useForm<QaqcAttributesConfigForm>({
    mode: 'onBlur',
    defaultValues: {
      attributeId: sensorAttribute?.attributeId,
      attributeValue: sensorAttribute?.attributeValue || '0',
      dateFrom: moment.utc(sensorAttribute?.dateFrom).startOf('day'),
      attributeComment: sensorAttribute?.attributeComment || '',
      modifyBy: sensorAttribute?.modifyBy || undefined,
      modifyDate: sensorAttribute?.modifyDate || undefined,
    },
  });
  const sensorAttributeId = () => {
    if (sensorAttribute?.sensorAttributeId !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="sensorAttributeId"
            labelText="Sensor Attribute ID"
            valueText={sensorAttribute.sensorAttributeId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const resourceAttributeId = () => {
    if (sensorAttribute?.resourceAttributeId !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="resourceAttributeId"
            labelText="Resource Attribute ID"
            valueText={sensorAttribute.resourceAttributeId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const categoryPicker = () => (
    <Grid item xs={12}>
      <FormDropdown
        label="Attribute ID"
        name="attributeId"
        options={formulaAttributes.map((attr) => ({
          label: ATTRIBUTE_LABEL_CODE[attr] + attr,
          value: Number(attr.slice(1)),
          key: attr.slice(1),
        }))}
        fullWidth
        rules={{ required: 'An attribute ID is required' }}
        disabled={sensorAttribute?.attributeId !== undefined}
      />
    </Grid>
  );

  const attributeValue = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.attributeValue"
        name="attributeValue"
        type="number"
        rules={{ required: 'An attribute value is required' }}
        fullWidth
      />
    </Grid>
  );

  const effectiveDateFrom = () => (
    <Grid item xs={12}>
      <FormDatePicker
        translationKey="common.datepickers.startDate"
        fullWidth
        name="dateFrom"
      />
    </Grid>
  );

  const attributeComment = () => (
    <Grid item xs={12}>
      <FormTextField
        name="attributeComment"
        translationKey="device.attributeComment"
        fullWidth
      />
    </Grid>
  );

  const modifyBy = () => {
    if (sensorAttribute?.modifyBy !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyBy"
            labelText="Modified By"
            valueText={
              sensorAttribute.modifyByString || sensorAttribute.modifyBy
            }
          />
        </Grid>
      );
    }
    return <></>;
  };

  const modifyDate = () => {
    if (sensorAttribute?.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyDate"
            labelText="Modify Date"
            valueText={sensorAttribute.modifyDate}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const handleSave = (values: QaqcAttributesConfigForm) => {
    onSave({
      attributeComment: values.attributeComment,
      attributeId: values.attributeId || sensorAttribute?.attributeId,
      attributeValue: Number(values.attributeValue),
      dateFrom: values.dateFrom,
      modifyBy: sensorAttribute?.modifyByString || sensorAttribute?.modifyBy,
      modifyDate: sensorAttribute?.modifyDate,
      sensorAttributeId: sensorAttribute?.sensorAttributeId,
      resourceAttributeId: sensorAttribute?.resourceAttributeId,
    });
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Add Attribute</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSave} formMethods={formMethods}>
          <Grid container spacing={1} sx={classes.root}>
            {sensorAttributeId()}
            {resourceAttributeId()}
            {categoryPicker()}
            {attributeValue()}
            {effectiveDateFrom()}
            {attributeComment()}
            {modifyBy()}
            {modifyDate()}
          </Grid>
          <Grid item sx={classes.rightAlign}>
            <CancelButton onClick={onCancel} />
            <SaveButton type="submit" />
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default QaqcAttributesConfig;
