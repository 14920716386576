/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import _ from 'lodash';
import { Box } from 'base-components';
import {
  getDefaultConfig,
  transformWidgetKeyToWidgetTypeId,
  WidgetServiceCreateParameters,
} from 'domain/AppComponents/Dashboard/WidgetService';
import DashboardStateProvider from './context/DashboardStateProvider';
import DashboardConfigAccordion from './DashboardConfigAccordion';
import DashboardLayout from './DashboardLayout';
import DashboardLogic from './DashboardLogic';
import DashboardToolbar from './DashboardToolbar';
import {
  GridItem,
  OncLayouts,
  WidgetOption,
  DashboardConfigComponent,
  WidgetConfig,
} from './DashboardTypes';
import { WindowController } from './useWindowController';
import WidgetLibrary from '../../../domain/Widgets/WidgetLibrary';

export type DashboardProps<TConfig = any> = {
  id: string;
  title: string;
  appName?: string;
  widgetLibrary: WidgetLibrary;
  actionComponents?: React.ReactNode[];
  dashboardData?: DashboardData;
  defaultLayout?: OncLayouts;
  defaultWidgets?: WidgetConfig[];
  isStatic?: boolean;
  layoutId?: number;
  predefined?: boolean;
  showToolbar?: boolean;
  titleComponents?: React.ReactNode;
  widgetOptions?: WidgetOption[];
  windowController?: WindowController;
  onDashboardDataChange?: (dashboardData: DashboardData) => void;
  onWidgetConfigChange?: (widgetId: number, config: any) => void;
  onWidgetRemove?: (widgetId: number) => void;
  onWidgetCreate?: (
    widget: WidgetServiceCreateParameters
  ) => Promise<number | undefined>;
  ConfigComponent?: DashboardConfigComponent<TConfig>;
  initialDashboardState?: TConfig;
};

export type DashboardData = {
  layouts: OncLayouts;
  widgets: GridItem[];
};

const emptyLayout = { xl: [], lg: [], md: [], sm: [], xs: [] };

const Dashboard = <TConfig = any,>({
  id,
  title,
  widgetLibrary,
  appName = 'Dashboard',
  actionComponents = [],
  dashboardData = undefined,
  defaultLayout = emptyLayout,
  defaultWidgets = [],
  isStatic = false,
  layoutId = undefined,
  predefined = false,
  showToolbar = true,
  titleComponents = undefined,
  widgetOptions = undefined,
  windowController = undefined,
  onDashboardDataChange = undefined,
  onWidgetConfigChange = undefined,
  onWidgetCreate = undefined,
  onWidgetRemove = undefined,
  ConfigComponent = undefined,
  initialDashboardState = {} as TConfig,
}: DashboardProps<TConfig>) => {
  const [dashboardDataState, setDashboardData] = useState<DashboardData>(
    dashboardData || {
      layouts: defaultLayout,
      widgets: DashboardLogic.buildInitialWidgets(
        defaultWidgets,
        widgetLibrary
      ),
    }
  );

  const { layouts, widgets } = dashboardData || dashboardDataState;
  const [widgetFocusMap, setWidgetFocusMap] = useState<Map<string, string>>(
    new Map()
  );
  const [currentWidgetFocus, setCurrentWidgetFocus] = useState<
    string | undefined
  >(undefined);

  const dashboardLayoutRef = useRef<HTMLDivElement>(null);
  const [calculatedRowHeight, setCalculatedRowHeight] = useState(75); // Default row height

  useEffect(() => {
    const updateRowHeight = () => {
      if (dashboardLayoutRef.current) {
        const containerHeight = dashboardLayoutRef.current.offsetHeight;
        const numberOfRows = 24; // Define number of rows on a single screen

        // Calculate row height (need to take into account the margin (10) between rows)
        const rowHeight =
          (containerHeight - (numberOfRows + 1) * 10) / numberOfRows;

        setCalculatedRowHeight(rowHeight);
      }
    };
    // Calculate on mount and resize
    updateRowHeight();
    window.addEventListener('resize', updateRowHeight);

    return () => window.removeEventListener('resize', updateRowHeight);
  }, []);

  const handleFocusWidget = (widgetId: string, widgetType: string) => {
    setCurrentWidgetFocus(widgetId);
    if (widgetFocusMap) {
      setWidgetFocusMap((oldMap) => {
        if (oldMap.get(widgetType) !== widgetId) {
          return new Map([...oldMap]).set(widgetType, widgetId);
        }
        return oldMap;
      });
    }
  };

  const setDefaultFocus = (currentWidgets: GridItem[]) => {
    const newFocusMap = _.cloneDeep(widgetFocusMap);
    currentWidgets.forEach((widget) => {
      if (!newFocusMap.has(widget.widgetKey)) {
        newFocusMap.set(widget.widgetKey, widget.i);
      }
    });
    setWidgetFocusMap(newFocusMap);
  };

  useEffect(() => {
    setDefaultFocus(widgets);
  }, [widgets]);

  /**
   * Make a call to the callback function if it exists when the dashboard data
   * changes
   */
  useEffect(() => {
    if (onDashboardDataChange) {
      onDashboardDataChange(dashboardDataState);
    }
  }, [dashboardDataState]);

  const handleAddItem = async (widgetOption: WidgetOption) => {
    const { Component } = widgetOption;
    const typeIndex = DashboardLogic.getUniqueIndex(
      widgets,
      Component.widgetKey
    );
    const uniqueKey = `${Component.widgetKey}-${typeIndex}`;
    const uniqueTitle =
      typeIndex > 1
        ? `${Component.widgetTitle} ${typeIndex}`
        : Component.widgetTitle;
    handleFocusWidget(uniqueKey, Component.widgetKey);

    const widgetTypeId = transformWidgetKeyToWidgetTypeId(Component.widgetKey);
    const defaultConfig = getDefaultConfig(widgetTypeId);
    const widgetId = await onWidgetCreate?.({
      widgetTypeId,
      layoutId,
      widgetConfig: JSON.stringify(defaultConfig),
    });

    setDashboardData({
      layouts,
      widgets: [
        ...widgets,
        {
          ...Component.defaultDataGrid,
          i: widgetId || uniqueKey,
          x: 0,
          y: 99999,
          widgetKey: Component.widgetKey,
          title: uniqueTitle,
          ariaLabel: uniqueTitle,
        },
      ],
    });
  };

  const handleRemoveItem = (key) => {
    onWidgetRemove?.(key);
    setDashboardData((prevState) => {
      const removedWidget = prevState.widgets.find(
        (widget) => widget.i === key
      );
      const updatedWidgets = [
        ...prevState.widgets.filter((widget) => widget.i !== key),
      ];

      if (widgetFocusMap.get(removedWidget.widgetKey) !== key) {
        return prevState;
      }

      const newFocusedWidget = updatedWidgets.find(
        (widget) => removedWidget.widgetKey === widget.widgetKey
      );
      if (newFocusedWidget) {
        handleFocusWidget(newFocusedWidget.i, newFocusedWidget.widgetKey);
      }

      return { layouts: prevState.layouts, widgets: updatedWidgets };
    });
  };
  const renderItems = () =>
    DashboardLogic.cleanupWidgets(widgets, widgetLibrary).map((widget) => {
      const WidgetComponent = widgetLibrary.getWidgetByKey(widget.widgetKey);
      if (WidgetComponent) {
        return (
          <WidgetComponent
            key={widget.i}
            appName={appName}
            widgetKey={widget.widgetKey}
            dashboardId={`${id}${windowController?.parentWindowId || ''}`}
            id={widget.i}
            dataGrid={widget}
            onRemove={handleRemoveItem}
            title={widget.title}
            predefined={predefined}
            isStatic={isStatic}
            initialConfig={widget.config}
            hasPrimaryFocus={currentWidgetFocus === widget.i}
            hasFocus={
              widgetFocusMap.get(WidgetComponent.widgetKey) === widget.i
            }
            onInteract={handleFocusWidget}
          />
        );
      }
      return undefined;
    });

  if (windowController?.numWindows === 0) {
    return null;
  }

  return (
    <DashboardStateProvider
      defaultWidgets={defaultWidgets}
      initialState={initialDashboardState}
      windowController={windowController}
      onWidgetConfigChange={onWidgetConfigChange}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showToolbar && (
          <DashboardToolbar
            title={title}
            titleComponents={titleComponents}
            actionComponents={actionComponents}
            widgetOptions={
              widgetOptions ||
              DashboardLogic.filterWidgetOptions(widgets, widgetLibrary)
            }
            onAddWidget={handleAddItem}
            windowController={windowController}
            isStatic={isStatic}
          />
        )}
        {ConfigComponent && (
          <DashboardConfigAccordion
            title={title}
            ConfigComponent={ConfigComponent}
          />
        )}
        <Box
          sx={{ flex: 1, position: 'relative', overflowY: 'auto' }}
          ref={dashboardLayoutRef}
        >
          <DashboardLayout
            layouts={DashboardLogic.cleanupLayouts(layouts, widgets)}
            onLayoutChange={(cur, all) =>
              setDashboardData({ widgets, layouts: all })
            }
            rowHeight={calculatedRowHeight}
            isStatic={isStatic}
          >
            {renderItems()}
          </DashboardLayout>
        </Box>
      </Box>
    </DashboardStateProvider>
  );
};

export default Dashboard;
