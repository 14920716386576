import { useState } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  Loading,
  CancelButton,
  DeleteButton,
  SaveButton,
} from '@onc/composite-components';
import { Divider, ErrorPage, Grid, Typography } from 'base-components';

import Form from 'library/CompositeComponents/form/Form';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

import Panel from 'library/CompositeComponents/panel/Panel';
import DateUtils from 'util/DateUtils';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import DeleteDialog from '../dialogs/DeleteDialog';
import ProtocolSelect from '../dropdowns/ProtocolSelect';
import {
  MarginedReadOnlyField,
  ModifyBy,
  ModifyDate,
} from '../Form/Fields/ReadOnlyFields';
import {
  handleOpenDeviceDetails,
  openDeviceDetails,
} from '../link/DeviceDetailsLink';

type DevicePayload = {
  devices: {
    device: {
      name: string;
    };
  };
};

type DevicePortPayload = Array<{
  breakerNumber: number;
  deviceName: string;
  devicePortId: number;
  devicePortLabel: string;
  direction: string;
  modifyBy: string;
  modifyDate: string;
  multipleConnection: boolean;
  piggybackInPort: boolean;
  power: number;
  protocol: string;
  protocolId: number;
  switchPortNumber: number;
  voltage: number;
  devicePortCode: string;
}>;

type DevicePortPostProps = {
  deviceid: number;
  portCode: string;
  direction: string;
  protocolId: number;
  portLabel: string;
  power: number;
  voltage: number;
  breakerNumber: number;
  switchPort: number;
  piggyback: boolean;
  multipleConnection: boolean;
  portid?: number;
};

type DevicePortForm = {
  direction: string;
  protocol: number;
  portLabel: string;
  portCode: string;
  power: number;
  voltage: number;
  breakerNumber: number;
  switchPort: number;
  piggyback: boolean;
  multipleConnection: boolean;
};

type DevicePortRWProps = {
  portId?: number;
  deviceId: number;
};

const DevicePortRW = ({ portId = undefined, deviceId }: DevicePortRWProps) => {
  const { onInfo, onError } = useSnackbars();
  const classes = {
    entryFormContainer: {
      paddingLeft: 3,
    },
    formButtons: {
      flexDirection: 'row-reverse',
      display: 'flex',
      paddingRight: 2,
    },
  };
  const [showDeleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [rendered, setRendered] = useState<boolean>(false);

  const portIdDefined = portId !== undefined && !isNaN(portId);
  const deviceIdDefined = deviceId !== undefined && !isNaN(deviceId);
  const callingPortService = portIdDefined && deviceIdDefined;
  const callingDeviceService = !portIdDefined && deviceIdDefined;

  const devicePortData = useGet<
    DevicePortPayload,
    { portid: number; deviceid: number }
  >(
    'DevicePortService',
    {
      operation: 4,
      options: {
        enabled: callingPortService,
        retry: 1,
      },
    },
    {
      portid: portId,
      deviceid: deviceId,
    }
  );

  const deviceData = useGet<
    DevicePayload,
    { deviceid: number; operationtype: number; network: number }
  >(
    'DeviceService',
    {
      operation: 2,
      transform: (response) => {
        const { data } = response;
        if (!data.payload) {
          throw new Error(data);
        }
        return data.payload;
      },
      options: {
        enabled: callingDeviceService,
        retry: 1,
      },
    },
    {
      deviceid: deviceId,
      operationtype: 2,
      network: -1,
    }
  );

  const { mutate: saveDevicePortData } = usePost<DevicePortPostProps>(
    'DevicePortService',
    {
      onSuccess: () => {
        onInfo('Device Port saved!');
        openDeviceDetails(deviceId, 'port_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    1
  );

  const { mutate: updateDevicePortData } = usePost<DevicePortPostProps>(
    'DevicePortService',
    {
      onSuccess: () => {
        onInfo('Device Port updated!');
        openDeviceDetails(deviceId, 'port_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    2
  );

  const { mutate: deleteDevicePortData } = usePost<{ portId: number }>(
    'DevicePortService',
    {
      onSuccess: () => {
        onInfo(`Port ${portId} deleted!`);
        openDeviceDetails(deviceId, 'port_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    3
  );

  const formMethods = useForm<DevicePortForm>({
    mode: 'onBlur',
  });

  if (
    (callingPortService && devicePortData.isLoading) ||
    (callingDeviceService && deviceData.isLoading)
  ) {
    return <Loading />;
  }

  if (
    (callingPortService && devicePortData.error) ||
    (callingDeviceService && deviceData.error) ||
    (callingDeviceService && deviceData.data?.devices?.device === null) ||
    (callingPortService && devicePortData.data?.length <= 0)
  ) {
    return <ErrorPage />;
  }

  const parametersForPostReq = (values: DevicePortForm) => {
    const params = {
      deviceid: deviceId,
      direction: values.direction,
      protocolId: values.protocol,
      portLabel: values.portLabel,
      portCode: values.portCode,
      power: values.power,
      voltage: values.voltage,
      breakerNumber: values.breakerNumber,
      switchPort: values.switchPort,
      piggyback: values.piggyback,
      multipleConnection: values.multipleConnection,
      ...(portId && !isNaN(portId) ? { portid: portId } : {}),
    };
    return params;
  };

  // Buttons cannot take the mutate from usePost as a param
  const handleDelete = () => {
    deleteDevicePortData({ portId });
  };

  const handleSubmit = (values: DevicePortForm) => {
    const props = parametersForPostReq(values);
    if (!portId || isNaN(portId)) {
      saveDevicePortData(props);
    } else {
      updateDevicePortData(props);
    }
  };

  if (!rendered) {
    formMethods.reset({
      direction: devicePortData?.data?.[0]?.direction || 'Out',
      protocol: devicePortData?.data?.[0]?.protocolId,
      portLabel: devicePortData?.data?.[0]?.devicePortLabel,
      portCode: devicePortData?.data?.[0]?.devicePortCode,
      power: devicePortData?.data?.[0]?.power,
      voltage: devicePortData?.data?.[0]?.voltage,
      breakerNumber: devicePortData?.data?.[0]?.breakerNumber,
      switchPort: devicePortData?.data?.[0]?.switchPortNumber,
      piggyback: devicePortData?.data?.[0]?.piggybackInPort || false,
      multipleConnection:
        devicePortData?.data?.[0]?.multipleConnection || false,
    });
    setRendered(true);
  }

  return (
    // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
    <div>
      <Panel
        title={
          <>
            <Typography variant="body1">Device Port ID:</Typography>
            <Typography variant="body1">
              {portId && !isNaN(portId) ? portId : 'New'}
            </Typography>
          </>
        }
      >
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid container direction="row" sx={classes.entryFormContainer}>
            <Grid item xs={6}>
              <FormDropdown
                name="direction"
                label="Direction"
                fullWidth
                options={[
                  { label: 'Out', value: 'Out', key: 'Out' },
                  { label: 'In', value: 'In', key: 'In' },
                ]}
              />
              <MarginedReadOnlyField
                labelText="Device"
                valueText={deviceData?.data?.devices?.device?.name}
                title="deviceField"
              />
              <ProtocolSelect name="protocol" label="Protocol" fullWidth />
              <FormTextField
                name="portLabel"
                translationKey="device.portLabel"
                fullWidth
                rules={{ required: 'A Port Label is required!' }}
              />
              <FormTextField
                name="portCode"
                translationKey="device.portCode"
                fullWidth
              />
              <FormTextField
                name="power"
                translationKey="device.power"
                fullWidth
                type="number"
              />
              <FormTextField
                name="voltage"
                translationKey="device.voltage"
                fullWidth
                type="number"
              />
              <FormTextField
                name="breakerNumber"
                translationKey="device.breakerNumber"
                fullWidth
                type="number"
              />
              <FormTextField
                name="switchPort"
                translationKey="device.switchPort"
                fullWidth
                type="number"
              />
              <FormCheckbox name="piggyback" label="Piggyback" />
              <FormCheckbox
                name="multipleConnection"
                label="Multiple Connection"
              />
              <ModifyBy username={devicePortData?.data?.[0].modifyBy} />
              <ModifyDate
                date={
                  devicePortData.data
                    ? DateUtils.formatDateAsString(
                        new Date(devicePortData.data[0].modifyDate)
                      )
                    : undefined
                }
              />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <SaveButton type="submit" />
            <CancelButton
              onClick={handleOpenDeviceDetails(deviceId, 'port_tab')}
            />
            <DeleteButton
              onClick={() => {
                setDeleteDialog(true);
              }}
              disabled={isNaN(portId)}
            />
            <DeleteDialog
              open={showDeleteDialog}
              onCancel={() => {
                setDeleteDialog(false);
              }}
              onDelete={handleDelete}
              title="Delete Device Port?"
              message="Are you sure you want to delete this device port?"
            />
          </Box>
        </Form>
        <Divider variant="middle" />
      </Panel>
    </div>
  );
};

export default DevicePortRW;
