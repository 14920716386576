import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  ContainedButton,
  FloatRightButtonLowStyles,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import DeviceNamePlateService from 'domain/services/DeviceNamePlateService';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const styles = {
  button: FloatRightButtonLowStyles,
};

const headers = [
  { title: 'Nameplate ID', name: 'namePlateId' },
  { title: 'Serial #', name: 'serialnr' },
  { title: 'Serial #2', name: 'serialnr2' },
  { title: 'Model #', name: 'modelnr' },
  { title: 'Producer', name: 'producerName' },
  { title: 'Supplier', name: 'supplierName' },
  { title: 'Description', name: 'namePlateDescription' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Nameplate ID For Search', name: 'namePlateIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'namePlateId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'serialnr',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'serialnr2',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'modelnr',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'producerName',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'supplierName',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'namePlateDescription',
    width: ColumnInfo.xLarge,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'namePlateIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class NamePlateTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }).isRequired,
  };

  compareNamePlateId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId } = this.props;
    this.setState({ isLoading: true });
    DeviceNamePlateService.getMany(deviceId)
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  actionContent = () => {
    const { deviceId, permission, classes } = this.props;
    const { tableRows } = this.state;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceNamePlateRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW' || tableRows.length > 0) {
      return <DeviceNamePlateRefreshButton />;
    }
    return (
      <>
        <DeviceNamePlateRefreshButton />
        <a href={`${Environment.getDmasUrl()}/Nameplate?deviceId=${deviceId}`}>
          <ContainedButton
            translationKey="device.addNameplate"
            startIcon={<Add />}
            className={classes.button}
          />
        </a>
      </>
    );
  };

  namePlateLink = (id) => {
    const { deviceId } = this.props;
    return (
      <a
        href={`${Environment.getDmasUrl()}/Nameplate?namePlateId=${id}&deviceId=${deviceId}`}
      >
        {id}
      </a>
    );
  };

  buildTableRows = (entries) => {
    this.setState({
      tableRows: entries.map((entry) => {
        const { namePlate, dmasUser, producer, supplier } = entry;
        const namePlateFor = {
          ...namePlate,
          namePlateId: this.namePlateLink(namePlate.namePlateId),
          modifyDate: DateFormatUtils.formatDate(namePlate.modifyDate, 'full'),
          supplierName: supplier ? supplier.organizationName : undefined,
          producerName: producer.organizationName,
          modifyBy: `${dmasUser.firstname} ${dmasUser.lastname}`,
          namePlateIdForSearch: namePlate.namePlateId,
        };
        return namePlateFor;
      }),
    });
  };

  render() {
    const { permission } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <Panel
        title={<Typography variant="h6">Nameplate List</Typography>}
        actionContent={this.actionContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={10}
          rows={tableRows}
          searchable
          searchBarMoveable={permission === 'RW'}
          sortExtensions={[
            { columnName: 'namePlateId', compare: this.compareNamePlateId },
          ]}
          stripedRows
          hiddenColumns={['namePlateIdForSearch']}
          isLoading={isLoading}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(NamePlateTable);
