import { useState, useEffect } from 'react';
import * as React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { ContainedButton } from '@onc/composite-components';
import { CloudUpload, Edit, Delete, Add } from '@onc/icons';
import { IconButton, StatelessTable, TableColumn } from 'base-components';
import DOIDatasetService from 'domain/AppComponents/doi-util/DOIDatasetService';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import useWebService from 'util/hooks/useWebService';
import { DataciteStatus } from './DataciteStatus';
import RelatedIdentifierDialog from './RelatedIdentifierDialog';
import RelatedIdentifierStatusIcon from './RelatedIdentifierStatusIcon';
import type { RelatedIdentifier } from './RelatedIdentifier';

interface RelatedIdentifierRow extends RelatedIdentifier {
  id?: number;
  status?: React.JSX.Element;
  actions?: React.JSX.Element[];
}

interface RelatedIdentifiersTableProps {
  relatedIdentifiers: RelatedIdentifier[] | RelatedIdentifier;
}
interface RelatedIdentifiersTableAdminProps {
  doidataset: string;
  onError: (message: any, callback?: any) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    addIdentifierButtonStyle: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    relatedIdentifersTable: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

const getDataciteActionButtons = (
  relatedIdentifier: RelatedIdentifier,
  onEdit: (identifier: RelatedIdentifier) => void,
  onDelete: (relatedIdentifierId: number) => void,
  onResync: () => void
): React.JSX.Element[] => {
  const actions: React.JSX.Element[] = [
    <IconButton
      Icon={Edit}
      aria-label="Edit"
      onClick={() => onEdit(relatedIdentifier)}
    />,
    <IconButton
      Icon={Delete}
      aria-label="Delete"
      onClick={() => onDelete(relatedIdentifier.relatedIdentifierId)}
    />,
  ];
  switch (relatedIdentifier.dataciteStatusId) {
    case DataciteStatus.NOT_SENT:
    case DataciteStatus.REQUEST_SENT:
    case DataciteStatus.ERROR:
      actions.push(
        <IconButton
          Icon={CloudUpload}
          aria-label="Resync with datacite"
          onClick={() => {
            onResync();
          }}
        />
      );
      break;
    case DataciteStatus.UPLOADED:
    default:
      break;
  }
  return actions;
};

const getColumns = (isAdmin: boolean): [TableColumn[], any] => {
  const baseColumns = [
    { title: 'Related Identifier Type', name: 'relatedIdentifierType' },
    { title: 'Relation Type', name: 'relationType' },
    { title: 'Resource Type', name: 'resourceTypeGeneral' },
    { title: 'Related Identifier', name: 'content' },
  ];
  const tableColumnExtensions = [
    {
      columnName: 'relatedIdentifierType',
      wordWrapEnabled: true,
      width: ColumnInfo.medium,
    },
    {
      columnName: 'relationType',
      wordWrapEnabled: true,
      width: ColumnInfo.small,
    },
    {
      columnName: 'resourceTypeGeneral',
      wordWrapEnabled: true,
      width: ColumnInfo.small,
    },
    {
      columnName: 'content',
      wordWrapEnabled: true,
      width: ColumnInfo.medium,
    },
  ];

  if (isAdmin) {
    baseColumns.push({ title: 'Status', name: 'status' });
    tableColumnExtensions.push({
      columnName: 'status',
      wordWrapEnabled: true,
      width: ColumnInfo.small,
    });
    baseColumns.push({ title: 'Actions', name: 'actions' });
    tableColumnExtensions.push({
      columnName: 'actions',
      wordWrapEnabled: true,
      width: ColumnInfo.medium,
    });
  }

  return [baseColumns, tableColumnExtensions];
};

const prepareRelatedIdentifiersForAdminView = (
  relatedIdentifiers: RelatedIdentifier[],
  onEdit: (identifier: RelatedIdentifier) => void,
  onDelete: (relatedIdentifierId: number) => void,
  onResync: () => void,
  isLoading: boolean
): RelatedIdentifierRow[] =>
  relatedIdentifiers.map((relatedIdentifier, i) => {
    const newIdentifier: RelatedIdentifierRow = { ...relatedIdentifier };
    newIdentifier.id = relatedIdentifier.relatedIdentifierId
      ? relatedIdentifier.relatedIdentifierId
      : i;
    newIdentifier.content = relatedIdentifier.relatedIdentifierValue;
    newIdentifier.status = (
      <RelatedIdentifierStatusIcon
        dataciteStatus={newIdentifier.dataciteStatusId}
        isLoading={isLoading}
      />
    );
    newIdentifier.actions = getDataciteActionButtons(
      newIdentifier,
      onEdit,
      onDelete,
      onResync
    );
    return newIdentifier;
  });

const filterRelatedIdentifierRows = (
  relatedIdentifiers: RelatedIdentifier | RelatedIdentifier[]
): RelatedIdentifierRow[] => {
  const relatedIdentifiersArray = Array.isArray(relatedIdentifiers)
    ? relatedIdentifiers
    : [relatedIdentifiers];
  const relatedIdentifierRows: RelatedIdentifierRow[] =
    relatedIdentifiersArray.filter(
      (identifier) =>
        !(
          (identifier &&
            'IsNewVersionOf'.localeCompare(identifier.relationType) === 0) ||
          'IsPreviousVersionOf'.localeCompare(identifier.relationType) === 0
        )
    );

  return relatedIdentifierRows;
};

const RelatedIdentifiersTableAdmin: React.VFC<
  RelatedIdentifiersTableAdminProps
> = ({ doidataset, onError }: RelatedIdentifiersTableAdminProps) => {
  const classes = useStyles();
  const [relatedIdentifierForDialog, setRelatedIdentifier] =
    useState<RelatedIdentifier | null>(null);
  const [showRelatedIdentifierDialog, setShowRelatedIdentifierDialog] =
    useState<boolean>(false);
  const [relatedIdentifierRequestSent, setRelatedIdentifierRequestSent] =
    useState(false);
  const [relatedIdentifiers, , fetchRelatedIdentifiers] = useWebService({
    method: DOIDatasetService.getRelatedIdentifiersForDOIDataset,
  });

  useEffect(() => {
    fetchRelatedIdentifiers(doidataset);
  }, [doidataset, fetchRelatedIdentifiers]);

  if (!relatedIdentifiers) {
    return <></>;
  }

  const [columns, columnExtensions] = getColumns(true);

  const relatedIdentifierRows: RelatedIdentifierRow[] =
    prepareRelatedIdentifiersForAdminView(
      filterRelatedIdentifierRows(relatedIdentifiers),
      (identifier) => {
        setRelatedIdentifier(identifier);
        setShowRelatedIdentifierDialog(true);
      },
      async (relatedIdentifierId) => {
        await DOIDatasetService.deleteRelatedIdentifier(
          doidataset,
          relatedIdentifierId
        );
        fetchRelatedIdentifiers(doidataset);
      },
      async () => {
        setRelatedIdentifierRequestSent(true);
        await DOIDatasetService.resyncDOIWithDatacite(doidataset);
        fetchRelatedIdentifiers(doidataset);
        setRelatedIdentifierRequestSent(false);
      },
      relatedIdentifierRequestSent
    );

  const addRelatedIdentifierButton = (
    <ContainedButton
      translationKey="doi.addRelatedIdentifier"
      startIcon={<Add />}
      className={classes.addIdentifierButtonStyle}
      onClick={() => {
        setShowRelatedIdentifierDialog(true);
      }}
    />
  );

  const relatedIdentiferDialog = showRelatedIdentifierDialog ? (
    <RelatedIdentifierDialog
      relatedIdentifier={
        relatedIdentifierForDialog === null
          ? undefined
          : relatedIdentifierForDialog
      }
      doidataset={doidataset}
      onClose={() => {
        setRelatedIdentifier(null);
        setShowRelatedIdentifierDialog(false);
      }}
      refreshRelatedIdentifiers={fetchRelatedIdentifiers}
      onError={onError}
    />
  ) : null;

  return (
    <div className={classes.relatedIdentifersTable}>
      {relatedIdentiferDialog}
      {addRelatedIdentifierButton}
      <StatelessTable
        columns={columns}
        columnExtensions={columnExtensions}
        rows={relatedIdentifierRows}
        showNumberOfRecords={false}
      />
    </div>
  );
};

const RelatedIdentifiersTable: React.VFC<RelatedIdentifiersTableProps> = ({
  relatedIdentifiers,
}: RelatedIdentifiersTableProps) => {
  const classes = useStyles();
  const [columns, columnExtensions] = getColumns(false);
  const relatedIdentifierRows: RelatedIdentifierRow[] =
    filterRelatedIdentifierRows(relatedIdentifiers);

  return (
    <div className={classes.relatedIdentifersTable}>
      <StatelessTable
        columns={columns}
        columnExtensions={columnExtensions}
        rows={relatedIdentifierRows}
        showNumberOfRecords={false}
      />
    </div>
  );
};

export { RelatedIdentifiersTable, RelatedIdentifiersTableAdmin };
