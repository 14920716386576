/* eslint-disable react/prop-types */
import { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { flushSync } from 'react-dom';
import * as yup from 'yup';
import {
  ClearButton,
  ContainedButton,
  OutlinedButton,
  TextButton,
} from '@onc/composite-components';
import {
  Checkbox,
  Dropdown,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from 'base-components';
import AttributeSelect from 'domain/AppComponents/dropdowns/AttributeSelect';
import {
  SearchTreeNodeResourceTypeSelect,
  ResourceTypeSelect,
  AttributeValueSelect,
} from 'domain/AppComponents/dropdowns/Dropdowns';
import ReadOnlyResourceSelect from 'domain/AppComponents/dropdowns/ReadOnlyResourceSelect';
import ResourceSelect from 'domain/AppComponents/dropdowns/ResourceSelect';
import TaxonAutoComplete from 'domain/AppComponents/dropdowns/TaxonAutoComplete';
import TaxonomySelect from 'domain/AppComponents/dropdowns/TaxonomySelect';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { NOAA_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import AnnotationService from 'domain/services/AnnotationService';
import TaxonAttributeService from 'domain/services/TaxonAttributeService';
import TaxonService from 'domain/services/TaxonService';
import Form from 'library/CompositeComponents/Form';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import CompareUtils from 'util/CompareUtils';
import ObjectUtils from 'util/ObjectUtils';
import { parseDmasAPIResponse, get } from 'util/WebRequest';
import CaptureTimeInput from './manual-entry-helpers/CaptureTimeInput';
import TaxonomyAttributeService from '../../../services/TaxonomyAttributeService';
import HistoricalConfirmationDialog from '../dialogs/HistoricalConfirmationDialog';
import SeaTubeResourceTypes from '../util/SeaTubeResourceTypes';
import TaxonomyUtil from '../util/TaxonomyUtil';

const REMOVE_ICON_PIXELS = 50;
const IMPORTED_TAXONOMIES = ['1', '3']; // WoRMS and CMECS
const OVERRIDEABLE_ATTRIBUTES = ['Comment', 'Resource Type', 'Resource'];
const EXCLUDED_ATTRIBUTES = [
  'Comment',
  'OBIS Count',
  'OBIS Test Date',
  'Resource',
  'Resource Type',
];

const styles = (theme) => ({
  gridItem: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(),
    },
  },
  buttonContainer: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'sticky',
    willChange: 'transform',
    bottom: '2px',
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
  },
  toBeReviewedCheckbox: {
    order: 3,
  },
  clearButton: {
    order: 2,
    marginLeft: theme.spacing(1),
  },
  saveKeepTimeButton: {
    order: 1,
    marginLeft: theme.spacing(1),
  },
  saveButton: {
    order: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  taxonAttributeSelectDropdown: {
    width: `calc(50% - ${REMOVE_ICON_PIXELS / 2}px)`,
  },
  attributeNotSelected: {
    width: `calc(100% - ${REMOVE_ICON_PIXELS}px)`,
  },
  attributeTextField: {
    width: `calc(50% - ${REMOVE_ICON_PIXELS / 2}px)`,
    marginLeft: '4px',
  },
  attributeAddButton: {
    display: 'block',
  },
  attributeRemoveButton: {
    marginTop: theme.spacing(2),
  },
  historicalButtonText: {
    color: theme.palette.error.main,
  },
  historicalOutline: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  historicalSave: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  liveButtonText: {
    color: theme.palette.success.main,
  },
  liveOutline: {
    border: `1px solid ${theme.palette.success.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.success.main}`,
    },
  },
  liveSave: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
});

const OBIS_COUNT = 37;
const OBIS_TEST_DATE = 38;
const OBIS_ATTRIBUTE_IDS = [OBIS_COUNT, OBIS_TEST_DATE];

class ManualEntry extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      attributeAddButton: PropTypes.string,
      attributeButton: PropTypes.string,
      attributeNotSelected: PropTypes.string,
      attributeRemoveButton: PropTypes.string,
      attributeTextField: PropTypes.string,
      buttonContainer: PropTypes.string,
      captureTimeButton: PropTypes.string,
      captureTimeInput: PropTypes.string,
      clearButton: PropTypes.string,
      commentContainer: PropTypes.string,
      gridItem: PropTypes.string,
      resourceSelectDropdown: PropTypes.string,
      resourceTypeSelect: PropTypes.string,
      saveButton: PropTypes.string,
      saveKeepTimeButton: PropTypes.string,
      taxonAttributeSelectDropdown: PropTypes.string,
      taxonSelect: PropTypes.string,
      toBeReviewedCheckbox: PropTypes.string,
    }).isRequired,
    captureTime: PropTypes.bool,
    showAttributes: PropTypes.bool,
    autoSave: PropTypes.bool,
    onSave: PropTypes.func,
    cruiseId: PropTypes.number,
    currentTimestamp: PropTypes.instanceOf(Date),
    currentButton: PropTypes.oneOfType([
      PropTypes.shape({
        colour: PropTypes.string,
        label: PropTypes.string,
        modifyBy: PropTypes.shape({}),
        modifyDate: PropTypes.string,
        sequenceNumber: PropTypes.number,
        taxonButtonSetHdrId: PropTypes.number,
        taxonButtonSetLineId: PropTypes.number,
        taxonId: PropTypes.number,
        taxonName: PropTypes.string,
        taxonomyId: PropTypes.number,
        taxonomyName: PropTypes.string,
      }),
      PropTypes.shape({
        attributeValue: PropTypes.string,
        colour: PropTypes.string,
        label: PropTypes.string,
        modifyBy: PropTypes.shape({}),
        modifyDate: PropTypes.string,
        sequenceNumber: PropTypes.number,
        taxonButtonSetHdrId: PropTypes.number,
        taxonButtonSetLineId: PropTypes.number,
        taxonomyAttributeId: PropTypes.number,
      }),
    ]),
    editAnnotation: PropTypes.shape({
      annotationId: PropTypes.number,
      comment: PropTypes.string,
      endDate: PropTypes.string,
      resourceId: PropTypes.number,
      resourceTypeId: PropTypes.number,
      taxons: PropTypes.arrayOf(PropTypes.shape({})),
      toBeReviewed: PropTypes.bool,
    }),
    attributeToggle: PropTypes.func,
    captureTimeToggle: PropTypes.func,
    onInfo: PropTypes.func,
    onClear: PropTypes.func,
    diveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    searchTreeNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultDeviceId: PropTypes.number,
    onQuickButtonSave: PropTypes.func,
    nullableAttributes: PropTypes.bool,
    diveDateFrom: PropTypes.instanceOf(Date),
    diveDateTo: PropTypes.instanceOf(Date),
    organizationId: PropTypes.number,
    isDiveActive: PropTypes.bool,
    isLive: PropTypes.bool,
    seatubeConfig: PropTypes.shape({
      annotationEntry: PropTypes.shape({
        cmecs: PropTypes.shape({
          resource: PropTypes.string,
          resourceType: PropTypes.number,
        }),
        noTaxonomy: PropTypes.shape({
          resource: PropTypes.string,
          resourceType: PropTypes.number,
        }),
        worms: PropTypes.shape({
          resource: PropTypes.string,
          resourceType: PropTypes.number,
        }),
        nullableAttributes: PropTypes.bool,
      }),
    }),
  };

  static defaultProps = {
    onSave: undefined,
    captureTime: false,
    showAttributes: false,
    autoSave: false,
    cruiseId: 560,
    currentTimestamp: undefined,
    currentButton: undefined,
    editAnnotation: undefined,
    attributeToggle: undefined,
    captureTimeToggle: undefined,
    onInfo: undefined,
    onClear: undefined,
    diveId: undefined,
    searchTreeNodeId: undefined,
    defaultDeviceId: undefined,
    onQuickButtonSave: undefined,
    nullableAttributes: false,
    diveDateFrom: undefined,
    diveDateTo: undefined,
    organizationId: undefined,
    isDiveActive: false,
    isLive: false,
    seatubeConfig: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      annotationId: null,
      attributeOptions: [],
      attributes: [],
      currentTime: null,
      taxon: null,
      taxonInput: '',
      taxonomyId: null,
      updateValues: null,
      keepTime: false,
      toBeReviewed: false,
      editing: false,
      willPublish: false,
      keepTaxonomy: props.organizationId === NOAA_DATA.organizationId,
      historicalDialogOpen: false,
      doNotShow: false,
      captureDateError: false,
      comment: '',
    };
  }

  componentDidMount() {
    this.getAttributeOptions();
    this.getForcedPublish();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { searchTreeNodeId } = this.props;

    // If the index (position) of the marker changes trigger a re-render
    if (
      (!searchTreeNodeId &&
        !CompareUtils.shallowEqual(this.props, nextProps, [
          'currentTimestamp',
        ])) ||
      !CompareUtils.shallowEqual(this.state, nextState)
    ) {
      return true;
    }
    if (
      (searchTreeNodeId && !CompareUtils.shallowEqual(this.props, nextProps)) ||
      !CompareUtils.shallowEqual(this.state, nextState)
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentButton,
      editAnnotation,
      autoSave,
      seatubeConfig,
      isLive,
      organizationId,
      diveDateFrom,
      diveDateTo,
    } = this.props;
    const { taxonomyId, currentTime } = this.state;
    const pastButton = prevProps.currentButton;
    const pastEdit = prevProps.editAnnotation;
    const pastSeatubeConfig = prevProps.seatubeConfig;
    const pastTaxonomyId = prevState.taxonomyId;
    const pastOrganizationId = prevProps.organizationId;
    const pastCurrentTime = prevState.currentTime;
    const pastDiveDateFrom = prevProps.diveDateFrom;
    const pastDiveDateTo = prevProps.diveDateTo;
    // Check if there is a new button incoming
    if (currentButton && currentButton !== pastButton) {
      // clear the form only if it is a taxonomy button
      if (currentButton.taxonomyId) {
        this.handleClear(true);
      }
      this.handleQuickButtonClicked(currentButton, autoSave);
    }
    if (editAnnotation && pastEdit !== editAnnotation) {
      this.handleAnnotationEdit(editAnnotation);
    }
    if (
      (seatubeConfig && seatubeConfig !== pastSeatubeConfig) ||
      pastTaxonomyId !== taxonomyId
    ) {
      this.reloadSeatubeConfig();
    }
    if (isLive) {
      this.resetDoNotShow();
    }
    if (pastOrganizationId !== organizationId) {
      this.handleOrganizationChange();
    }
    if (
      currentTime !== pastCurrentTime ||
      diveDateFrom !== pastDiveDateFrom ||
      diveDateTo !== pastDiveDateTo
    ) {
      this.validateCaptureDate();
    }
  }

  handleOrganizationChange = () => {
    const { organizationId } = this.props;
    this.setState({
      keepTaxonomy: organizationId === NOAA_DATA.organizationId,
    });
  };

  resetDoNotShow = () => this.setState({ doNotShow: false });

  /*
    We need to get attribute options because the quick buttons
    only give the attributeId when an attribute button is clicked,
    but we also need the name
  */
  getAttributeOptions = () => {
    const { onError } = this.props;
    return TaxonomyAttributeService.getAllSelectable()
      .then((response) => {
        const options = response;
        options.forEach((option, index) => {
          options[index].label = option.name;
          options[index].value = { id: option.attributeId, name: option.name };
        });
        this.setState({ attributeOptions: options });
      })
      .catch((error) => {
        onError(error);
      });
  };

  /*
    This method takes in an annotation object after the edit icon
    is clicked on the annotation list. It resets the manual entry form,
    and then maps every value from the annotation into updateValues state
    which is passed into the form to populate all of the fields
  */
  handleAnnotationEdit = (annotation) => {
    const { showAttributes, attributeToggle, captureTime, captureTimeToggle } =
      this.props;
    // Make sure a user sees the time
    if (!captureTime) captureTimeToggle();

    const {
      comment,
      resourceTypeId,
      resourceId,
      taxons,
      taxonPaths,
      annotationId,
      endDate,
      toBeReviewed,
    } = annotation;
    const updateValues = [];
    const tempAttributes = [];
    let newTaxonomyId = null;
    // Update the resource (type)
    updateValues.push({
      title: 'resourceTypeSelect',
      value: resourceTypeId,
    });
    updateValues.push({
      title: 'resourceSelect',
      value: resourceId,
    });
    let taxon = null;
    let userDefined = false;
    // If taxons are included in the annotation, add them to updateValues
    if (taxons) {
      newTaxonomyId = taxons[0].taxonomyId;
      const { taxonId, attributes } = taxons[0];
      const taxonPath = taxonPaths[0];

      // update userDefined
      userDefined = !IMPORTED_TAXONOMIES.includes(newTaxonomyId);

      // Populate the auto complete
      updateValues.push({ title: 'taxonomySelect', value: newTaxonomyId });
      const taxonInput = this.getTaxonLabel(taxons[0].displayText, taxonPath);
      taxon = {
        label: taxonInput,
        value: taxonId,
        taxonId,
      };
      // If attributes are added, make sure attributes are viewable
      // and add them to updateValues
      if (attributes.length > 0) {
        if (!showAttributes) attributeToggle();
        // Loop through every attribute and add them to attributes
        // and updateValues
        attributes.forEach((attribute, index) => {
          const { attributeId, dataType, value, name } = attribute;

          if (!name.includes('OBIS')) {
            const attributeObj = this.findAttributeObjectFromId(attributeId);
            let actualValue = value;
            // If attribute type is 'Select' use the drop down value rather than label
            if (dataType === 'Select') {
              const { attributeValues } = attributeObj;

              // in case the annotation created had nullable attribute privilege,
              // value could be undefined
              if (value) {
                actualValue = attributeValues.find(
                  (element) => element.label === value
                ).value;
              }
            }

            updateValues.push(
              { title: `attribute${index}`, value: attributeId },
              { title: `attributeText${index}`, value: actualValue }
            );

            // Add attributes to state
            tempAttributes.push({
              ...attributeObj,
              value: actualValue,
              index,
            });
          }
        });
      }
    } else {
      updateValues.push({ title: 'taxonomySelect', value: '' });
    }
    this.setState({
      updateValues,
      annotationId,
      taxonomyId: newTaxonomyId,
      attributes: tempAttributes,
      currentTime: new Date(endDate),
      taxon,
      toBeReviewed,
      resourceTypeId,
      userDefined,
      comment,
    });
  };

  // Build a taxon label for the TaxonAutocomplete to show,
  // given its displayText and taxonPath hierarchy
  getTaxonLabel = (displayText, taxonPath) => {
    let parentName = 'Taxonomy Root';

    if (taxonPath.length > 1) {
      parentName = taxonPath[taxonPath.length - 2];
    }

    // for imported taxonomies with a divider, grab only
    // the taxon and common names from the display text
    const dividerIndex = displayText.indexOf(' |');
    let taxonAndCommonNames = displayText;
    if (dividerIndex >= 0) {
      taxonAndCommonNames = displayText.slice(0, dividerIndex);
    }

    return `${parentName} / ${taxonAndCommonNames}`;
  };

  /*
    Once the form is finished updating, we need to set updateValues to
    null, or else the values will be stuck on the updateValues passed
    into the form
  */
  handleFormUpdateFinish = () => this.setState({ updateValues: null });

  /*
    The method takes in a button object which contains info
    about what button was clicked. Based off this info, the code updates
    state.updateValues. This is an array of objects which is then passed
    into the form. The form takes this array and updates the form based
    off the array.
  */
  handleQuickButtonClicked = async (button, autoSave) => {
    let tempUpdateValues = [];

    // when a button is cleared
    if (!button) return;

    // Check if the quick button is a taxon button
    if (button.taxonomyId) {
      // assemble a taxon primarily for the default attribute call
      const taxon = {
        label: button.taxonName,
        value: button.taxonId,
        taxonId: button.taxonId,
        taxonomyId: button.taxonomyId,
      };
      // grab the taxon's default attributes
      this.setState({ taxon, taxonomyId: button.taxonomyId }, async () => {
        const defaultAttributes = await this.handleDefaultAttributes(
          taxon,
          true
        );
        if (autoSave) {
          this.handleTaxonButtonAutosave(button, defaultAttributes, taxon);
        } else {
          this.handleTaxonButtonNoAutosave(button, defaultAttributes, taxon);
        }
      });
    } else if (button.attributes) {
      // Check if it's an attribute button
      const { attributes } = this.state;
      let index = attributes.length;
      const newAttributes = [...attributes];

      const { attributes: buttonAttributes } = button;
      // get attribute names
      const attrsWithNames = buttonAttributes.map((attr) => {
        const name = this.findAttributeNameFromId(attr.taxonomyAttributeId);
        return { ...attr, name };
      });
      // separate out the overridable attributes
      const [regularAttrs, overrideableAttrs] = attrsWithNames.reduce(
        (array, attr) => {
          array[OVERRIDEABLE_ATTRIBUTES.includes(attr.name) ? 1 : 0].push(attr);
          return array;
        },
        [[], []]
      );

      if (overrideableAttrs.length > 0) {
        // format the attributes to match the structure of those
        // used by the taxon default attributes
        const formattedAttrs = overrideableAttrs.map((oa) => {
          const { attributeValue, attributeValues, name, taxonomyAttributeId } =
            oa;
          let value = attributeValue;

          if (attributeValues) {
            const selectValue = attributeValues.find(
              (curr) => curr.value === oa.taxonomyAttributeLineId
            );
            value = selectValue.label;
          }

          return { attributeId: taxonomyAttributeId, name, value };
        });
        tempUpdateValues = this.handleOverrideableAttributes(formattedAttrs);
      }

      regularAttrs.forEach((attribute) => {
        const attributeObj = this.findAttributeObjectFromId(
          attribute.taxonomyAttributeId
        );
        const { attributeId, attributeValues, dataType, name } = attributeObj;
        const value =
          dataType === 'Select'
            ? attribute.taxonomyAttributeLineId
            : attribute.attributeValue;
        // Set attribute in state
        if (!attributes.find((attr) => attr.attributeId === attributeId)) {
          newAttributes.push({
            attributeId,
            attributeValues,
            dataType,
            index,
            name,
            value,
          });
          // Update attribute in form
          const attributeType = {
            title: `attribute${index}`,
            value: attribute.taxonomyAttributeId,
          };
          const attributeValue = {
            title: `attributeText${index}`,
            value,
          };
          tempUpdateValues.push(attributeType, attributeValue);

          index += 1;
        }
      });

      this.setState({
        attributes: newAttributes,
        updateValues: tempUpdateValues,
        comment:
          tempUpdateValues.find((attr) => attr.title === 'comment')?.value ||
          '',
      });
    } else if (button.comment) {
      // Make sure it's just a comment
      if (autoSave) {
        const { resourceTypeId, resourceId } = this.state;
        const annotation = {
          resourceSelect: resourceId,
          resourceTypeSelect: resourceTypeId,
          comment: button.comment,
        };
        this.handleSave(annotation);
      } else {
        this.setState({ comment: button.comment });
      }
    }
  };

  handleTaxonButtonAutosave = (button, attributeData, taxon) => {
    const { nullableAttributes } = this.props;
    const { overrideableAttributes } = attributeData;
    const potentialAttributes = this.processDefaultAttributes(attributeData);
    const actualAttributes = potentialAttributes.filter(
      (attr) => !attr.excluded
    );
    const filteredOverrideables = overrideableAttributes.filter(
      (attr) => !attr.excluded
    );
    let annotation = this.createBaseAnnotation(button);

    // valid autosave cases
    if (actualAttributes.length === 0) {
      if (filteredOverrideables.length > 0) {
        // case 1: only overrideable attributes
        annotation = this.addOverrideableAttributes(
          annotation,
          filteredOverrideables
        );
      }

      // case 2: no default attributes whatsoever
      this.handleSave(annotation, button.taxonId);
    } else if (nullableAttributes) {
      // case 3: for ONC dive loggers, all taxon buttons can be autosaved
      if (filteredOverrideables.length > 0) {
        annotation = this.addOverrideableAttributes(
          annotation,
          filteredOverrideables
        );
      }

      const attributes = [];
      annotation.autoSave = true;
      actualAttributes.forEach((attribute, index) => {
        annotation[`attributeText${index}`] =
          attribute.value === 'null' ? undefined : attribute.value;
        annotation[`attribute${index}`] = attribute.attributeId;
        attributes.push(attribute);
      });
      annotation.attributes = attributes;

      this.handleSave(annotation, button.taxonId);
    } else {
      // create form updates for the button, since autosave is
      // not possible without attribute values
      this.handleTaxonButtonNoAutosave(button, attributeData, taxon);
    }
  };

  createBaseAnnotation = (button) => {
    const { resourceTypeId, resourceId } = this.state;
    return {
      resourceSelect: resourceId,
      resourceTypeSelect: resourceTypeId,
      taxonomySelect: button.taxonomyId,
      comment: button.comment || '',
    };
  };

  addOverrideableAttributes = (annotation, attributes) => {
    const { diveId, defaultDeviceId } = this.props;
    const { resourceTypeId } = this.state;
    const result = { ...annotation };

    const resourceType = attributes.find(
      (attr) => attr.name === 'Resource Type'
    );
    let newResourceTypeId = resourceTypeId;
    if (resourceType) {
      switch (resourceType.value) {
        case 'Dive':
          newResourceTypeId = SeaTubeResourceTypes.DIVE;
          break;
        case 'Device':
          newResourceTypeId = SeaTubeResourceTypes.DEVICE;
          break;
        default:
          newResourceTypeId = SeaTubeResourceTypes.DEVICE_DATA;
          break;
      }
    }

    attributes.forEach((oa) => {
      switch (oa.name) {
        case 'Comment':
          result.comment = oa.value;
          break;
        case 'Resource Type':
          result.resourceTypeSelect = newResourceTypeId;
          break;
        case 'Resource':
          if (oa.value === 'Default') {
            result.resourceSelect =
              newResourceTypeId === SeaTubeResourceTypes.DIVE
                ? diveId
                : defaultDeviceId;
          }
          break;
        default:
          break;
      }
    });

    return result;
  };

  handleTaxonButtonNoAutosave = (button, attributeData, taxon) => {
    const baseUpdateValues = this.createBaseFormUpdate(button);
    const attributeUpdateValues = this.handleAttributeData(attributeData);
    const tempUpdateValues = baseUpdateValues.concat(attributeUpdateValues);

    this.setState({
      updateValues: tempUpdateValues,
      taxonomyId: button.taxonomyId,
      taxon,
      currentTime: this.getCurrentTime(),
      comment: button.comment,
    });
  };

  createBaseFormUpdate = (button) => {
    const tempUpdateValues = [];

    const taxonomyUpdate = {
      title: 'taxonomySelect',
      value: button.taxonomyId,
    };
    if (button.comment) {
      const commentUpdate = {
        title: 'comment',
        value: button.comment,
      };
      tempUpdateValues.push(commentUpdate);
    } else {
      const commentUpdate = {
        title: 'comment',
        value: '',
      };
      tempUpdateValues.push(commentUpdate);
    }
    tempUpdateValues.push(taxonomyUpdate);

    return tempUpdateValues;
  };

  handleTaxonomyChange = (e) => {
    const userDefined = !IMPORTED_TAXONOMIES.includes(e.target.value);
    this.setState(
      { taxonomyId: e.target.value, userDefined, taxon: null },
      this.reloadSeatubeConfig
    );
  };

  handleTaxonomyClear = () => {
    const updateValues = [{ title: 'taxonomySelect', value: '' }];
    this.setState(
      {
        taxonomyId: '',
        userDefined: undefined,
        taxon: null,
        updateValues,
      },
      () => this.handleAutoCompleteChange(null)
    );
  };

  reloadSeatubeConfig = () => {
    const { seatubeConfig } = this.props;
    const { taxonomyId } = this.state;
    const { annotationEntry } = seatubeConfig;
    const { cmecs, noTaxonomy, worms } = annotationEntry;

    switch (taxonomyId) {
      // WoRMS
      case '1':
        this.changeSeatubeConfig(worms);
        break;
      // CMECS
      case '3':
        this.changeSeatubeConfig(cmecs);
        break;
      // no taxonomy
      case null:
        this.changeSeatubeConfig(noTaxonomy);
        break;
      default:
        break;
    }
  };

  changeSeatubeConfig = (config) => {
    if (config) {
      const { diveId, defaultDeviceId } = this.props;
      const { resourceType: resourceTypeId, resource } = config;
      const updateValues = [];
      let resourceId = -1;

      if (resource === 'default') {
        resourceId =
          resourceTypeId === SeaTubeResourceTypes.DIVE
            ? diveId
            : defaultDeviceId;
      }

      updateValues.push({ title: 'resourceTypeSelect', value: resourceTypeId });
      updateValues.push({ title: 'resourceSelect', value: resourceId });

      this.setState({ updateValues, resourceTypeId, resourceId });
    }
  };

  handleTaxonInputChange = (e) => {
    const { taxon } = this.state;

    if (taxon) {
      return;
    }

    this.setState({ taxonInput: e });
  };

  handleAutoChange = (inputValue, callBack) => {
    const { onError } = this.props;
    const { userDefined } = this.state;

    if (
      inputValue.length > TaxonomyUtil.getCharAutocompleteLimit(userDefined)
    ) {
      const { taxonomyId } = this.state;
      return TaxonService.getFilteredTaxonsByTaxonomy(taxonomyId, inputValue)
        .then((options) => {
          const returnOptions = [];
          options.forEach((option) => {
            let label = option.parentName
              ? `${option.parentName} / ${option.commonName}`
              : option.commonName;
            if (option.englishNames) {
              label += ` (${option.englishNames.join(', ')})`;
            }
            const value = option.taxonId;
            const { taxonId } = option;
            returnOptions.push({
              label,
              value,
              taxonId,
            });
          });
          callBack(returnOptions);
        })
        .catch((error) => {
          onError(error);
        });
    }
    return false;
  };

  handleSave = (event, taxonId) => {
    const { onQuickButtonSave } = this.props;
    const { keepTime, resourceTypeId, resourceId, captureDateError, comment } =
      this.state;

    if (captureDateError) {
      return;
    }

    const returnValue = event;
    if (!returnValue.resourceTypeSelect) {
      returnValue.resourceTypeSelect = resourceTypeId;
    }
    if (!returnValue.resourceSelect) {
      returnValue.resourceSelect = resourceId;
    }
    returnValue.resourceTypeId = returnValue.resourceTypeSelect;
    returnValue.resourceId = returnValue.resourceSelect;
    returnValue.date = this.getCurrentTime();
    returnValue.comment = comment;
    const requestFormClear = this.saveAnnotation(
      this.buildAnnotation(returnValue, taxonId)
    );
    if (!keepTime) {
      this.clearCurrentTime();
    }
    // clear the quick button regardless of whether one was clicked
    if (onQuickButtonSave) onQuickButtonSave();

    if (requestFormClear) {
      this.handleClear();
    }
  };

  // as of 2020-07-03, for fixed location camera annotations only
  getForcedPublish = async () => {
    const { onError } = this.props;
    try {
      const payload = await get('seatube/permissions', {
        operation: 3,
      }).then((response) => parseDmasAPIResponse(response));
      this.setState({ willPublish: payload[0].value });
    } catch (error) {
      onError(error);
    }
  };

  getCurrentTime = (ignoreCapturedTime) => {
    const { currentTime } = this.state;
    const { captureTime, currentTimestamp } = this.props;

    if (captureTime && !ignoreCapturedTime && currentTime) return currentTime;

    // currentTimestamp is undefined before the video finishes loading
    return currentTimestamp || new Date();
  };

  saveAnnotation = async (annotation) => {
    const { annotationId } = this.state;
    const { onError, onInfo, onSave, organizationId } = this.props;
    try {
      let response;

      if (annotationId) {
        // the annotation exists if there's already an annotation ID, so update
        response = await AnnotationService.updateAnnotation(
          annotation,
          organizationId
        );
      } else {
        // the annotation doesn't exist yet if there's no annotation ID, so create
        response = await AnnotationService.createAnnotation(
          annotation,
          organizationId
        );
      }

      const payload = parseDmasAPIResponse(response);
      if (payload && onSave) onSave(payload, annotationId);
      if (onInfo) {
        onInfo('Annotation Saved!');
      }
      return true;
    } catch (error) {
      onError(error);
    }
    return false;
  };

  /*
    This method starts the creation of the object our
    annotation service is expecting
  */
  buildAnnotation = (annotation, taxonId) => {
    const { annotationId, toBeReviewed, willPublish } = this.state;
    const { searchTreeNodeId } = this.props;
    const annotationObject = {};
    annotationObject.annotationId = annotationId;
    let resourceTypeName;
    if (Number(annotation.resourceTypeSelect) === 1000) {
      resourceTypeName = 'Device Data';
    } else if (Number(annotation.resourceTypeSelect) === 2) {
      resourceTypeName = 'Device';
    } else {
      resourceTypeName = 'Dive';
    }
    annotationObject.resourceType = {
      resourceTypeId: annotation.resourceTypeSelect,
      resourceTypeName,
    };
    annotationObject.resource = { resourceId: annotation.resourceSelect };
    annotationObject.startDate = annotation.date;
    annotationObject.endDate = annotation.date;
    const attributeArray = this.createAttributeArray(annotation);
    const annotationContents = this.createAnnotationContents(
      annotation,
      attributeArray,
      taxonId
    );
    annotationObject.annotationContents = annotationContents;
    annotationObject.flagged = toBeReviewed;
    annotationObject.annotationSource = {
      annotationSource: 'SeaScribe',
      annotationSourceId: 6,
    };
    annotationObject.published = true;
    if (searchTreeNodeId !== undefined) {
      annotationObject.published = willPublish;
    }
    annotationObject.levelIndex = 0;
    return annotationObject;
  };

  /*
    If attributes are added, the annotation service is expecting them
    as an array connected to the taxonomy. This method creates that said
    array
  */
  createAttributeArray = (annotation) => {
    const { attributes: stateAttributes } = this.state;
    const { autoSave } = annotation;
    let { attributes } = annotation;
    if (!autoSave) {
      attributes = stateAttributes;
    }
    const attributeArray = [];
    for (let i = 0; i < attributes.length; i += 1) {
      if (annotation[`attribute${i}`] > 0) {
        const attributeName = this.findAttributeNameFromId(
          annotation[`attribute${i}`]
        );
        if (attributes[i].dataType === 'Select') {
          attributeArray.push({
            attributeId: annotation[`attribute${i}`],
            name: attributeName,
            taxonomyAttributeLineId: annotation[`attributeText${i}`],
          });
        } else {
          let actualValue = annotation[`attributeText${i}`];
          if (actualValue === '') {
            actualValue = undefined;
          }
          attributeArray.push({
            attributeId: annotation[`attribute${i}`],
            value: actualValue,
            name: attributeName,
          });
        }
      }
    }
    return attributeArray;
  };

  /*
    The annotation object has an object called annotation contents.
    This contains both the comment object, and a taxon object.
    The attribute array is attached to the taxon object if it exists,
    or is displayed as null otherwise
  */
  createAnnotationContents = (annotation, attributeArray, newTaxonId) => {
    const annotationContents = [];
    const { taxon } = this.state;
    const taxonId = taxon ? taxon.taxonId : newTaxonId;
    if (annotation.comment?.length > 0) {
      const commentObject = {};
      commentObject.annotation = annotation.comment;
      commentObject.formField = { formFieldId: 4 };
      commentObject.taxonId = 0;
      commentObject.taxonomyId = 0;
      annotationContents.push(commentObject);
    }
    if (taxonId) {
      const taxonObject = {};
      taxonObject.annotation = 'taxon';
      taxonObject.formField = { formFieldId: 150 };
      if (attributeArray.length > 0) {
        taxonObject.annotationAttributes = attributeArray;
      } else {
        taxonObject.annotationAttributes = null;
      }
      taxonObject.taxonId = taxonId;
      taxonObject.taxonomyId = annotation.taxonomySelect;
      annotationContents.push(taxonObject);
    }
    return annotationContents;
  };

  // Based on an attributeId, return the attribute name
  findAttributeNameFromId = (attributeId) => {
    const { attributeOptions } = this.state;
    if (attributeId === OBIS_COUNT) {
      return 'OBIS Count';
    }
    if (attributeId === OBIS_TEST_DATE) {
      return 'OBIS Test Date';
    }
    const nameHelper = attributeOptions.filter(
      (option) => attributeId === option.attributeId
    );
    return nameHelper[0].name;
  };

  // Based on an attributeId, return the entire attribute object
  findAttributeObjectFromId = (attributeId) => {
    const { attributeOptions } = this.state;
    const nameHelper = attributeOptions.filter(
      (option) => attributeId === option.attributeId
    );
    return nameHelper[0];
  };

  handleAutoCompleteChange = (taxon) => {
    const { onInfo } = this.props;
    const { attributes } = this.state;
    let updateValues = null;

    // remove all existing attributes
    if (attributes.length > 0) {
      updateValues = [];
      attributes.forEach((attr) => {
        updateValues.push({ title: `attribute${attr.index}`, value: null });
        updateValues.push({ title: `attributeText${attr.index}`, value: null });
      });
      onInfo('Attributes removed for previously selected taxon.');
    }

    this.setState({ taxon, attributes: [], updateValues }, () =>
      this.handleDefaultAttributes(taxon)
    );
  };

  handleDefaultAttributes = async (taxon, autoSave) => {
    const { onError } = this.props;
    const { taxonomyId } = this.state;
    let attributeData = {};

    if (taxon !== null) {
      const { taxonId, taxonomyId: taxonTaxonomyId } = taxon;
      const finalTaxonomyId = taxonTaxonomyId || taxonomyId;

      try {
        if (IMPORTED_TAXONOMIES.includes(finalTaxonomyId.toString())) {
          attributeData = this.createImportedAttributes(finalTaxonomyId);
        } else {
          attributeData = await TaxonAttributeService.getAttributes(
            finalTaxonomyId,
            taxonId
          );
        }

        // if it's an autosave, just return the data
        if (autoSave) return attributeData;

        const updateValues = this.handleAttributeData(attributeData);
        this.setState({ updateValues });
      } catch (error) {
        onError(error);
      }
    }

    return {};
  };

  /**
   * Creates resource type and resource "overrideable" attributes for imported
   * taxonomy configurations
   *
   * @param {number} taxonomyId - Id for the taxonomy whose config should be
   *   used
   */
  createImportedAttributes = (taxonomyId) => {
    const { seatubeConfig } = this.props;
    const { cmecs, worms } = seatubeConfig.annotationEntry;
    const resultAttrs = {
      inheritedAttributes: [],
      inheritedOverrideableAttributes: [],
      localAttributes: [],
      overrideableAttributes: [],
    };

    if (taxonomyId === 1) {
      // WoRMS
      resultAttrs.overrideableAttributes = this.makeConfigToAttributes(worms);
    } else if (taxonomyId === 3) {
      // CMECS
      resultAttrs.overrideableAttributes = this.makeConfigToAttributes(cmecs);
    }
    return resultAttrs;
  };

  makeConfigToAttributes = (config) => {
    const { resource, resourceType } = config;
    const overrideableAttributes = [];

    let typeValue;
    switch (resourceType) {
      case SeaTubeResourceTypes.DIVE:
        typeValue = 'Dive';
        break;
      case SeaTubeResourceTypes.DEVICE:
        typeValue = 'Device';
        break;
      case SeaTubeResourceTypes.DEVICE_DATA:
        typeValue = 'Device Data';
        break;
      default:
        break;
    }
    if (typeValue) {
      overrideableAttributes.push({ name: 'Resource Type', value: typeValue });
    }

    const resourceValue = resource === 'default' ? 'Default' : '';
    overrideableAttributes.push({ name: 'Resource', value: resourceValue });

    return overrideableAttributes;
  };

  handleAttributeData = (attributeData) => {
    const { overrideableAttributes } = attributeData;
    let updateValues = [];

    // handle inherited and local attributes
    const potentialAttributes = this.processDefaultAttributes(
      attributeData
    ).sort((a, b) => a.name.localeCompare(b.name));

    if (potentialAttributes.length > 0) {
      this.handleRegularDefaultAttributes(potentialAttributes);
    }

    // handle overrideable attributes
    if (overrideableAttributes.length > 0) {
      updateValues = this.handleOverrideableAttributes(overrideableAttributes);
    }

    return updateValues;
  };

  processDefaultAttributes = (attributeData) => {
    const { inheritedAttributes, localAttributes } = attributeData;
    const localAttributeIds = localAttributes.map((local) => local.attributeId);

    // filter out any local exclusions from inherited attributes
    const filteredInheritedAttributes = inheritedAttributes.filter(
      (inherited) => !localAttributeIds.includes(inherited.attributeId)
    );

    return filteredInheritedAttributes.concat(localAttributes);
  };

  handleRegularDefaultAttributes = (attributes) => {
    const { attributeToggle, showAttributes, onInfo } = this.props;
    const { attributeOptions } = this.state;

    if (!showAttributes) attributeToggle();
    let index = 0;
    attributes.forEach((potential) => {
      if (!potential.excluded) {
        const match = attributeOptions.find(
          (option) => potential.attributeId === option.attributeId
        );

        if (match) {
          const { attributeId, attributeValues, dataType, name } = match;
          const attribute = {
            attributeId,
            attributeValues,
            dataType,
            name,
            index,
          };

          this.handleAddAttribute(attribute);
          index += 1;
        }
      }
    });
    if (index) {
      onInfo('Default attributes added for selected taxon.');
    }
  };

  handleOverrideableAttributes = (attributes) => {
    const { resourceTypeId } = this.state;
    let currResourceTypeId = resourceTypeId;

    const resourceTypeAttribute = attributes.find(
      (attr) => attr.name === 'Resource Type'
    );
    if (resourceTypeAttribute) {
      switch (resourceTypeAttribute.value) {
        case 'Dive':
          currResourceTypeId = SeaTubeResourceTypes.DIVE;
          break;
        case 'Device':
          currResourceTypeId = SeaTubeResourceTypes.DEVICE;
          break;
        case 'Device Data':
          currResourceTypeId = SeaTubeResourceTypes.DEVICE_DATA;
          break;
        default:
          break;
      }
    }

    const updateValues = [];
    attributes.forEach((attribute) => {
      if (!attribute.excluded) {
        switch (attribute.name) {
          case 'Comment':
            updateValues.push({ title: 'comment', value: attribute.value });
            break;
          case 'Resource Type':
            updateValues.push(this.handleResourceTypeAttribute(attribute));
            break;
          case 'Resource':
            updateValues.push(
              this.handleResourceAttribute(attribute, currResourceTypeId)
            );
            break;
          default:
            break;
        }
      }
    });

    return updateValues;
  };

  handleResourceTypeAttribute = (attribute) => {
    let updateValue = '-1';

    switch (attribute.value) {
      case 'Dive':
        updateValue = SeaTubeResourceTypes.DIVE.toString();
        break;
      case 'Device':
        updateValue = SeaTubeResourceTypes.DEVICE.toString();
        break;
      case 'Device Data':
        updateValue = SeaTubeResourceTypes.DEVICE_DATA.toString();
        break;
      default:
        break;
    }

    this.setState({ resourceTypeId: parseInt(updateValue, 10) });
    return { title: 'resourceTypeSelect', value: updateValue };
  };

  handleResourceAttribute = (attribute, resourceTypeId) => {
    const { diveId, defaultDeviceId } = this.props;

    let updateValue = -1;
    if (attribute.value === 'Default') {
      if (resourceTypeId === SeaTubeResourceTypes.DIVE) {
        updateValue = diveId;
      } else {
        updateValue = defaultDeviceId;
      }
    }

    this.setState({ resourceId: updateValue });
    return { title: 'resourceSelect', value: updateValue };
  };

  handleClear = (keepAnnotationId) => {
    const { taxonomyId, keepTaxonomy, annotationId, attributes } = this.state;
    let updateValues = null;

    // clear attributes properly via the form
    if (attributes) {
      updateValues = [];
      attributes.forEach((attr) => {
        updateValues.push({ title: `attribute${attr.index}`, value: null });
        updateValues.push({ title: `attributeText${attr.index}`, value: null });
      });
    }

    this.setState({
      attributes: [],
      taxon: null,
      taxonomyId: keepTaxonomy ? taxonomyId : '',
      annotationId: keepAnnotationId ? annotationId : null,
      taxonInput: '',
      toBeReviewed: false,
      comment: '',
      updateValues,
      annotationToSave: undefined,
    });
    const { onClear, onQuickButtonSave } = this.props;
    if (onClear) onClear();
    // clear the quick button regardless of whether it was clicked or not
    if (onQuickButtonSave) onQuickButtonSave();
    // Repopulate the default fields after a clear
    this.handleDefaultValues();
  };

  clearCurrentTime = () => {
    this.setState({ currentTime: null });
  };

  setKeepTime = (keep) => {
    const { captureTime, captureTimeToggle } = this.props;
    const { currentTime } = this.state;
    if (keep && !captureTime) {
      // Make sure a user sees the time
      captureTimeToggle();
    }
    if (!currentTime) {
      const newCurrentTime = this.getCurrentTime();
      this.setState({ keepTime: keep, currentTime: newCurrentTime });
    } else {
      this.setState({ keepTime: keep });
    }
  };

  handleAddAttribute = (attribute) => {
    const { onError } = this.props;
    const { attributes, taxonomyId, taxon } = this.state;

    // Ensure taxonomy and taxon are populated
    if (!taxonomyId || !taxon) {
      onError(
        'Taxonomy and taxon must be added before attributes are allowed.'
      );
      return;
    }

    if (attribute) {
      const defaultAttribute = attribute.attributeId
        ? attribute
        : { index: attributes.length, dataType: null };

      // Update form
      const updateValues = attribute.attributeId
        ? [
            {
              title: `attribute${attribute.index}`,
              value: attribute.attributeId,
            },
            {
              title: `attributeText${attribute.index}`,
              value: attribute.value,
            },
          ]
        : null;
      flushSync(() => {
        this.setState({
          attributes: [...attributes, defaultAttribute],
          updateValues,
        });
      });
    } else {
      flushSync(() => {
        this.setState({
          attributes: [
            ...attributes,
            { index: attributes.length, dataType: null },
          ],
        });
      });
    }
  };

  removeAttribute = (attribute) => {
    const { attributes } = this.state;

    // Remove attribute
    const tempAttributes = attributes.filter(
      (a) => a.index !== attribute.index
    );

    // Update attribute indexes
    const updatedAttributes = [];
    const updateValues = [];
    tempAttributes.forEach((a, index) => {
      // Update state object
      updatedAttributes.push({ ...a, index });

      // Update form
      updateValues.push(
        {
          title: `attribute${index}`,
          value: a.attributeId,
        },
        {
          title: `attributeText${index}`,
          value: a.value,
        }
      );
    });

    // Clear the last entry in the form so adding a new attribute shows "Select an Attribute"
    updateValues.push(
      {
        title: `attribute${updatedAttributes.length}`,
        value: undefined,
      },
      {
        title: `attributeText${updatedAttributes.length}`,
        value: undefined,
      }
    );

    this.setState({ attributes: updatedAttributes, updateValues });
  };

  captureTimeClicked = () => {
    this.setState({ currentTime: this.getCurrentTime(true) });
  };

  handleTimeChange = (currentTime) => {
    this.setState({ currentTime });
  };

  resetAttribute = (index) => {
    this.setState({
      updateValues: [{ title: `attributeText${index}`, value: '' }],
    });
  };

  renderDeleteIcon = (attribute) => {
    const { classes } = this.props;
    // OBIS attributes can't be deleted by the user
    if (OBIS_ATTRIBUTE_IDS.includes(attribute.attributeId)) {
      return null;
    }
    return (
      <DeleteIconButton
        aria-label={`Delete Attribute ${attribute.value}`}
        onClick={() => this.removeAttribute(attribute)}
        className={classes.attributeRemoveButton}
      />
    );
  };

  renderObisDropdown = (attribute, index) => {
    const obisOption = [
      { label: 'OBIS Count', value: OBIS_COUNT },
      { label: 'OBIS Test Date', value: OBIS_TEST_DATE },
    ];
    const { attributes } = this.state;
    const { classes } = this.props;
    return (
      <Dropdown
        title={`attribute${attribute.index}`}
        className={
          attributes[index].dataType
            ? classes.taxonAttributeSelectDropdown
            : classes.attributeNotSelected
        }
        options={obisOption}
        helperText=" "
        label="Select an Attribute"
        disabled
      />
    );
  };

  /*
    Attribute select has a special case with OBIS Count and
    OBIS test date. These two selects should be disabled,
    and they don't come up in the getAttributes service call.
    So if the attributeId is 37 (OBIS Count) or 38 (Test Date)
    I need to create a custom dropdown to handle it
  */
  renderAttributeSelect = (attribute, index) => {
    const { classes } = this.props;
    const { attributes } = this.state;
    if (OBIS_ATTRIBUTE_IDS.includes(attribute.attributeId)) {
      return this.renderObisDropdown(attribute, index);
    }
    return (
      <AttributeSelect
        title={`attribute${attribute.index}`}
        className={
          attributes[index].dataType
            ? classes.taxonAttributeSelectDropdown
            : classes.attributeNotSelected
        }
        validation={yup.string().required('Required for an attribute')}
        onChange={(e) => this.handleAttributeChange(e, index)}
        value={attribute.attributeId}
        excluded={attributes
          .map((attr) => attr.name)
          .concat(EXCLUDED_ATTRIBUTES)}
      />
    );
  };

  /*
    Attributes have two main cases, being a string/number
    or a select. As of now, we consider string/number the same
    until we have further discussions on some requirements with the
    data team.
    If the dataType is a string/number, the attribute field should be a
    text field
    If the dataType is a select, the attribute field should be a
    dropdown
    If the attribute has an attributeId, check if its 37 or 38. There are obis attributes
    that should not be able to be edited or deleted
  */
  renderAttributeField = (attribute) => {
    const { classes, nullableAttributes } = this.props;
    const disabled =
      attribute.attributeId === OBIS_COUNT ||
      attribute.attributeId === OBIS_TEST_DATE;
    const validation = nullableAttributes
      ? undefined
      : yup.string().required('If adding an attribute, this is required');
    if (attribute.dataType === 'String')
      return (
        <TextField
          translationKey="common.textfields.value"
          title={`attributeText${attribute.index}`}
          validation={validation}
          className={classes.attributeTextField}
          disabled={disabled}
          onChange={(e) => this.handleAttributeValueChange(e, attribute.index)}
        />
      );
    if (attribute.dataType === 'Select') {
      return (
        <AttributeValueSelect
          title={`attributeText${attribute.index}`}
          options={attribute.attributeValues}
          validation={validation}
          className={classes.attributeTextField}
          disabled={disabled}
          onChange={(e) => this.handleAttributeValueChange(e, attribute.index)}
        />
      );
    }
    if (attribute.dataType === 'Number')
      return (
        <TextField
          translationKey="common.textfields.value"
          title={`attributeText${attribute.index}`}
          validation={
            nullableAttributes
              ? undefined
              : yup
                  .number()
                  .typeError('You must specify a number')
                  .required('If adding an attribute, this is required')
          }
          className={classes.attributeTextField}
          disabled={disabled}
          onChange={(e) => this.handleAttributeValueChange(e, attribute.index)}
        />
      );
    if (attribute.dataType === 'Integer')
      return (
        <TextField
          translationKey="common.textfields.value"
          title={`attributeText${attribute.index}`}
          validation={
            nullableAttributes
              ? undefined
              : yup
                  .number()
                  .typeError('You must specify a integer')
                  .integer('You must specify an integer')
                  .required('If adding an attribute, this is required')
          }
          className={classes.attributeTextField}
          disabled={disabled}
          onChange={(e) => this.handleAttributeValueChange(e, attribute.index)}
        />
      );
    if (attribute.dataType === 'Boolean')
      return (
        <AttributeValueSelect
          title={`attributeText${attribute.index}`}
          options={[
            { label: 'True', value: 'True' },
            { label: 'False', value: 'False' },
          ]}
          validation={
            nullableAttributes
              ? undefined
              : yup
                  .string()
                  .required('If adding an attribute, this is required')
          }
          className={classes.attributeTextField}
          disabled={disabled}
          onChange={(e) => this.handleAttributeValueChange(e, attribute.index)}
        />
      );
    return <></>;
  };

  handleAttributeChange = (e, index) => {
    this.resetAttribute(index);
    const { value } = e.target;
    const { attributes } = this.state;
    const newAttributes = ObjectUtils.deepClone(attributes);
    const attribute = this.findAttributeObjectFromId(value);
    newAttributes[index].attributeId = attribute.attributeId;
    newAttributes[index].attributeValues = attribute.attributeValues;
    newAttributes[index].dataType = attribute.dataType;
    newAttributes[index].name = attribute.name;
    this.setState({ attributes: newAttributes });
  };

  handleAttributeValueChange = (e, index) => {
    const { attributes } = this.state;
    const newAttributes = ObjectUtils.deepClone(attributes);
    newAttributes[index].value = e.target.value;
    this.setState({ attributes: newAttributes });
  };

  handleResourceTypeChange = (e) => {
    const { diveId, defaultDeviceId } = this.props;
    let { resourceId } = this.state;
    const { resourceTypeId } = this.state;
    if (e.target.value === SeaTubeResourceTypes.DIVE.toString()) {
      resourceId = parseInt(diveId, 10);
    } else if (
      !resourceTypeId ||
      resourceTypeId.toString() === SeaTubeResourceTypes.DIVE.toString()
    ) {
      resourceId = defaultDeviceId;
    }
    this.setState({
      updateValues: [{ title: 'resourceSelect', value: resourceId }],
      resourceTypeId: parseInt(e.target.value, 10),
      resourceId,
    });
  };

  handleResourceChange = (e) => {
    const { searchTreeNodeId } = this.props;
    if (searchTreeNodeId) {
      this.setState({ resourceId: parseInt(e, 10) });
    } else {
      this.setState({ resourceId: parseInt(e.target.value, 10) });
    }
  };

  handleDefaultValues = () => {
    const { defaultDeviceId } = this.props;
    const { resourceTypeId, resourceId, taxonomyId, keepTaxonomy } = this.state;
    const resourceTypeValue =
      resourceTypeId || SeaTubeResourceTypes.DEVICE_DATA;
    const resourceValue = resourceId || defaultDeviceId;
    const updateValues = [
      { title: 'resourceTypeSelect', value: resourceTypeValue.toString() },
      { title: 'resourceSelect', value: resourceValue },
      { title: 'comment', value: '' },
    ];

    updateValues.push({
      title: 'taxonomySelect',
      value: keepTaxonomy ? taxonomyId : '',
    });

    this.setState({
      updateValues,
      resourceId: resourceValue,
      resourceTypeId: resourceTypeValue,
    });
  };

  handleToBeReviewedToggle = () => {
    this.setState((prevState) => ({ toBeReviewed: !prevState.toBeReviewed }));
  };

  handleEditTime = (editing) => {
    this.setState({ editing });
  };

  handleClearButtonClicked = () => {
    this.clearCurrentTime();
    this.handleClear();
    this.reloadSeatubeConfig();
  };

  validateCaptureDate = () => {
    const { diveDateFrom, diveDateTo } = this.props;
    const { currentTime } = this.state;

    const dateFrom = diveDateFrom || new Date(0);
    const dateTo = diveDateTo || new Date();
    return this.setState({
      captureDateError:
        currentTime && (currentTime < dateFrom || currentTime > dateTo),
    });
  };

  isValidTaxon = () => {
    const { taxon, taxonomyId } = this.state;
    return !taxonomyId || taxon;
  };

  isValidResourceType = () => {
    const { resourceTypeId } = this.state;
    return Number(resourceTypeId) > 0;
  };

  isValidResource = () => {
    const { resourceId } = this.state;
    return Number(resourceId) > 0;
  };

  determineButtonColor = (buttonType, existingClass) => {
    const { isDiveActive, isLive, classes } = this.props;

    if (isDiveActive && buttonType) {
      switch (buttonType) {
        case 'low':
          if (isLive) {
            return `${existingClass} ${classes.liveButtonText}`;
          }
          return `${existingClass} ${classes.historicalButtonText}`;
        case 'outlined':
          if (isLive) {
            return `${existingClass} ${classes.liveButtonText} ${classes.liveOutline}`;
          }
          return `${existingClass} ${classes.historicalButtonText} ${classes.historicalOutline}`;
        case 'high':
          if (isLive) {
            return `${existingClass} ${classes.liveSave}`;
          }
          return `${existingClass} ${classes.historicalSave}`;
        default:
          break;
      }
    }

    return existingClass;
  };

  toggleHistoricalDialogOpen = (event) => {
    const { historicalDialogOpen } = this.state;
    const annotationToSave = event.resourceTypeSelect ? event : undefined;
    this.setState({
      historicalDialogOpen: !historicalDialogOpen,
      annotationToSave,
    });
  };

  handleHistoricalDialogConfirm = (doNotShow) => {
    const { annotationToSave, historicalDialogOpen } = this.state;
    this.setState(
      { historicalDialogOpen: !historicalDialogOpen, doNotShow },
      () => this.handleSave(annotationToSave)
    );
  };

  render() {
    const {
      classes,
      captureTime,
      showAttributes,
      cruiseId,
      diveId,
      searchTreeNodeId,
      currentTimestamp,
      isDiveActive,
      isLive,
    } = this.props;
    let { attributes } = this.state;
    const {
      taxonomyId,
      updateValues,
      taxonInput,
      taxon,
      toBeReviewed,
      resourceTypeId,
      userDefined,
      currentTime,
      historicalDialogOpen,
      doNotShow,
      captureDateError,
      comment,
    } = this.state;
    if (!showAttributes) {
      attributes = [];
    }

    const historicalTextForButton =
      isDiveActive && !isLive ? 'Historical ' : '';
    return (
      <>
        <HistoricalConfirmationDialog
          open={historicalDialogOpen}
          onCancel={this.toggleHistoricalDialogOpen}
          onConfirm={this.handleHistoricalDialogConfirm}
        />
        <Form
          onSubmit={
            isDiveActive && !isLive && !doNotShow
              ? this.toggleHistoricalDialogOpen
              : this.handleSave
          }
          buttonBarStyle={{ float: 'right' }}
          clearAfterSave={false} // Note this is handled by requesting a clear after a successful save
          updateValues={updateValues}
          onUpdateFinish={this.handleFormUpdateFinish}
        >
          <Grid container>
            {/* Wrote capture time twice because any gird further than this depth doesn't work properly */}
            {captureTime ? (
              <Grid item xs={12} md={4} className={classes.gridItem}>
                <CaptureTimeInput
                  onEditTime={this.handleEditTime}
                  value={currentTime}
                  handleChange={this.handleTimeChange}
                  validation={yup
                    .boolean()
                    .test(
                      'dateValidation',
                      'Date must be during the dive',
                      () => !captureDateError
                    )}
                />
                {captureDateError ? (
                  <FormHelperText error>
                    Date must be during the dive
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <></>
            )}
            {captureTime ? (
              <Grid item xs={12} md={8} className={classes.gridItem}>
                <TextButton
                  translationKey="seatube.captureTime"
                  translationOptions={{ historical: historicalTextForButton }}
                  className={this.determineButtonColor('low')}
                  onClick={this.captureTimeClicked}
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} md={4} className={classes.gridItem}>
              <TaxonomySelect
                title="taxonomySelect"
                value={taxonomyId}
                validation={yup
                  .string()
                  .test({
                    name: 'attributes and taxonomies',
                    exclusive: true,
                    params: { attributes },
                    message: 'A taxonomy is required with attributes',
                    test: (val) =>
                      attributes.length < 1 ||
                      (attributes.length > 0 &&
                        val !== '' &&
                        val !== undefined),
                  })
                  .nullable()}
                onChange={this.handleTaxonomyChange}
                onClear={this.handleTaxonomyClear}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              <TaxonAutoComplete
                disabled={!taxonomyId}
                loadOptions={this.handleAutoChange}
                onChange={this.handleAutoCompleteChange}
                inputValue={taxonInput}
                onInputChange={this.handleTaxonInputChange}
                value={taxon}
                userDefined={userDefined}
                validation={yup
                  .boolean()
                  .test(
                    'isTaxonValid',
                    'A Taxon is required with a Taxonomy',
                    this.isValidTaxon
                  )}
              />
              {this.isValidTaxon() ? (
                <></>
              ) : (
                <FormHelperText error>
                  A Taxon is required with a Taxonomy
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              <TextField
                translationKey="common.textfields.comment"
                fullWidth
                multiline
                rows={2}
                value={comment}
                onChange={(e) => this.setState({ comment: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              {showAttributes ? (
                <TextButton
                  translationKey="seatube.addAttribute"
                  onClick={this.handleAddAttribute}
                  className={this.determineButtonColor(
                    'low',
                    classes.attributeAddButton
                  )}
                />
              ) : (
                <></>
              )}
              {attributes.map((attribute, index) => (
                <Fragment key={attribute}>
                  {this.renderAttributeSelect(attribute, index)}
                  {this.renderAttributeField(attribute, index)}
                  {this.renderDeleteIcon(attribute)}
                </Fragment>
              ))}
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              {searchTreeNodeId ? (
                <SearchTreeNodeResourceTypeSelect
                  title="resourceTypeSelect"
                  validation={yup
                    .string()
                    .required('Required!')
                    .test(
                      'isResourceTypeValid',
                      'A Resource Type is required',
                      this.isValidResourceType
                    )}
                  className={classes.resourceTypeSelect}
                  onChange={this.handleResourceTypeChange}
                  fullWidth
                />
              ) : (
                <ResourceTypeSelect
                  title="resourceTypeSelect"
                  validation={yup
                    .string()
                    .required('Required!')
                    .test(
                      'isResourceTypeValid',
                      'A Resource Type is required',
                      this.isValidResourceType
                    )}
                  className={classes.resourceTypeSelect}
                  onChange={this.handleResourceTypeChange}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              {searchTreeNodeId ? (
                <ReadOnlyResourceSelect
                  onChange={this.handleResourceChange}
                  timestamp={currentTimestamp}
                  searchTreeNodeId={searchTreeNodeId}
                  deviceCategoryId={14}
                />
              ) : (
                <ResourceSelect
                  title="resourceSelect"
                  className={classes.resourceSelectDropdown}
                  validation={yup
                    .string()
                    .required('Required!')
                    .test(
                      'isResourceValid',
                      'A Resource is required',
                      this.isValidResource
                    )}
                  cruiseId={cruiseId}
                  diveId={diveId}
                  resourceTypeId={resourceTypeId}
                  onOptionsLoad={this.handleDefaultValues}
                  onChange={this.handleResourceChange}
                  fullWidth
                />
              )}
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormControlLabel
              className={classes.toBeReviewedCheckbox}
              control={
                <Checkbox
                  checked={toBeReviewed}
                  onChange={this.handleToBeReviewedToggle}
                />
              }
              label="To Be Reviewed"
            />
            <ClearButton
              className={this.determineButtonColor('low', classes.clearButton)}
              onClick={this.handleClearButtonClicked}
            />
            <OutlinedButton
              translationKey="seatube.saveAndKeepTime"
              type="submit"
              className={this.determineButtonColor(
                'outlined',
                classes.saveKeepTimeButton
              )}
              onClick={() => this.setKeepTime(true)}
            />

            <ContainedButton
              translationKey="seatube.historicalSave"
              translationOptions={{ historical: historicalTextForButton }}
              className={this.determineButtonColor('high', classes.saveButton)}
              onClick={() => this.setKeepTime(false)}
              type="submit"
            />
          </div>
        </Form>
      </>
    );
  }
}
export default withStyles(styles)(withSnackbars(ManualEntry));
