import { useEffect } from 'react';
import qs from 'qs';
import { UserDetailsService } from '@onc/domain-services';
import { CircularProgress } from 'base-components';
import UserDetailsContext, {
  UserContext,
} from 'domain/AppComponents/sea-tube/UserDetailsContext';
import SynchronizerDeploymentsDashboard from 'domain/AppComponents/synchronizer-management/SynchronizerDeploymentsDashboard';

import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import QueryParameterContext from '../menu/QueryParameterContext';

type Props = {
  location: {
    search: string;
  };
};

const SynchronizerManagementApp: React.VFC<Props> = (props: Props) => {
  const { location } = props;
  const { onError } = useSnackbars();

  // Handle Query parameters
  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const handleUserData = (data: string): UserContext => ({
    userId: Number(data),
  });

  const [userId, loadingUserId, fetchUserId] = useWebService({
    method: UserDetailsService.getUserId,
    parser: handleUserData,
    onError,
  });

  useEffect(() => {
    fetchUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (loadingUserId || userId === undefined) {
      return (
        <CircularProgress
          size={120}
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
          }}
        />
      );
    }
    return (
      <QueryParameterContext.Provider value={params}>
        <UserDetailsContext.Provider value={userId}>
          <SynchronizerDeploymentsDashboard />
        </UserDetailsContext.Provider>
      </QueryParameterContext.Provider>
    );
  };

  return (
    <div
      style={{
        backgroundColor: '#EEE',
        height: '100%',
      }}
    >
      {renderContent()}
    </div>
  );
};

export default SynchronizerManagementApp;
