import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import {
  AbsoluteRightButtonStyle,
  ContainedButton,
} from '@onc/composite-components';
import { Add } from '@onc/icons';
import {
  ConfirmationDialog,
  GenericInfoDialog,
} from 'domain/AppComponents/dialogs/Dialogs';
import {
  RefreshButton,
  EditIconButton,
  DeleteIconButton,
} from 'domain/AppComponents/IconButtons';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';

import QaqcAttributesConfig from './QaqcAttributesConfig';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  addAttributeButton: { ...AbsoluteRightButtonStyle(theme), top: '44px' },
});

const headers = [
  { title: 'Attribute ID', name: 'attributeId' },
  { title: 'Value', name: 'attributeValue' },
  { title: 'Effective Date From (UTC)', name: 'effectiveDateFrom' },
  { title: 'Comment', name: 'attributeComment' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: ' ', name: 'icons' },
];

const tableColumnExtensions = [
  {
    columnName: 'icons',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'attributeId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'attributeValue',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'effectiveDateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'attributeComment',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
];

class QaqcAttributesTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    formulaAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
    classes: PropTypes.shape({
      addAttributeButton: PropTypes.string,
    }).isRequired,
    qaqcId: PropTypes.number,
  };

  static defaultProps = {
    qaqcId: 0,
  };

  columnSizeInfo = [
    { columnName: 'icons', width: ColumnInfo.mini },
    { columnName: 'attributeId', width: ColumnInfo.mini },
    { columnName: 'attributeValue', width: ColumnInfo.mini },
    { columnName: 'effectiveDateFrom', width: ColumnInfo.medium },
    { columnName: 'attributeComment', width: ColumnInfo.medium },
    { columnName: 'modifyBy', width: ColumnInfo.medium },
    { columnName: 'modifyDate', width: ColumnInfo.medium },
  ];

  disabledSort = [{ columnName: 'icons', sortingEnabled: false }];

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      rowToBeEdited: undefined,
      openCreateDialog: false,
      openDeleteDialog: false,
      openDataFormatWarning: false,
      dateFormatWarningMessage: '',
      addEventConfirmationDialog: false,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  actionContent = () => {
    const { permission, formulaAttributes, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components, react/no-this-in-sfc
    const ActionRefreshButton = () => <RefreshButton onClick={this.refresh} />;
    if (permission !== 'RW') {
      return <ActionRefreshButton />;
    }
    return (
      <>
        <ActionRefreshButton />
        <ContainedButton
          translationKey="common.buttons.addAttribute"
          startIcon={<Add />}
          className={classes.addAttributeButton}
          onClick={this.handleAddRole}
          disabled={!formulaAttributes}
        />
      </>
    );
  };

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, qaqcId } = this.props;
    QaqcTestDetailsService.getQAQCTD(qaqcId)
      .then((response) => {
        this.buildTableRows(response.testAttributes);
      })
      .catch(() => {
        onError('Invalid QAQC ID, please provide a valid ID');
      });
  };

  setupIcons = (row) => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return null;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            this.setState({
              openCreateDialog: true,
              rowToBeEdited: row,
            });
          }}
          aria-label="Edit Attribute"
          size="small"
        />
        <DeleteIconButton
          onClick={() => {
            this.setState({
              openDeleteDialog: true,
              rowToBeDeleted: row,
            });
          }}
          aria-label="Delete Attribute"
          size="small"
        />
      </>
    );
  };

  addEventDialog = () => {
    const { openCreateDialog, rowToBeEdited } = this.state;
    const { permission, formulaAttributes } = this.props;

    if (!openCreateDialog) {
      return null;
    }
    return (
      <QaqcAttributesConfig
        permission={permission}
        formulaAttributes={formulaAttributes}
        onSave={this.handleConfirmationDialog}
        onCancel={this.handleCancel}
        sensorAttribute={rowToBeEdited}
      />
    );
  };

  handleAcceptImproperDataFormatWarningDialog = () => {
    this.setState({
      openCreateDialog: true,
      openDataFormatWarning: false,
      dateFormatWarningMessage: '',
    });
  };

  handleConfirmationDialog = (form) => {
    this.setState({
      openConfirmationDialog: true,
      openCreateDialog: false,
      formData: form,
    });
  };

  // open dialog to add new resource role
  handleAddRole = () =>
    this.setState({ openCreateDialog: true, rowToBeEdited: undefined });

  // cancel add/edit and close dialog
  handleCancel = () =>
    this.setState({
      openConfirmationDialog: false,
      openCreateDialog: false,
      openDeleteDialog: false,
      openDataFormatWarning: false,
      dataFormatWarningMessage: '',
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
      formData: undefined,
    });

  // confirmation dialog after edit or create
  addEventConfirmationDialog = () => {
    const { openConfirmationDialog, rowToBeEdited } = this.state;
    if (!openConfirmationDialog) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title={rowToBeEdited ? 'Edit Attribute' : 'Add New Attribute'}
        content={
          rowToBeEdited
            ? `Are you sure you want to edit attribute ${rowToBeEdited.attributeId}?`
            : `Are you sure you want to add this attribute?`
        }
        onCancel={this.handleCancel}
        onConfirm={this.handleSave}
      />
    );
  };

  ImproperDataFormatWarning = () => {
    const { openDataFormatWarning, dateFormatWarningMessage } = this.state;
    if (!openDataFormatWarning) {
      return null;
    }
    return (
      <GenericInfoDialog
        title="Warning"
        message={`Failed to save for following reason: ${dateFormatWarningMessage}`}
        open={openDataFormatWarning}
        onClose={this.handleAcceptImproperDataFormatWarningDialog}
      />
    );
  };

  deleteEventDialog = () => {
    const { openDeleteDialog, rowToBeDeleted } = this.state;
    if (!openDeleteDialog) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Attribute"
        content={`Are you sure you want to delete attribute ${rowToBeDeleted.attributeId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.handleDelete}
      />
    );
  };

  handleDelete = (form) => {
    const { onError, onInfo } = this.props;
    const { rowToBeDeleted } = this.state;

    const updatedInfo = form;

    updatedInfo.sensorAttributeId = rowToBeDeleted.sensorAttributeId;
    updatedInfo.resourceAttributeId = rowToBeDeleted.resourceAttributeId;
    QaqcTestDetailsService.deleteAttribute(updatedInfo)
      .then((response) => {
        this.setState({ openCreateDialog: false, openDeleteDialog: false });
        this.handleCancel();
        if (response.data.statusCode === 0) {
          onInfo('Deleted Successfully');
        } else {
          onError(
            `Failed to delete with error code: ${response.data.statusCode}`
          );
        }
      })
      .then(() => {
        this.refresh();
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  handleSave = () => {
    const { onError, onInfo, qaqcId } = this.props;
    const { rowToBeEdited, formData, tableRows } = this.state;
    const updatedInfo = formData;

    const currentAllEffectiveDates = tableRows.map((x) => {
      const y = {};
      y.attributeId = x.attributeId;
      y.effectiveDateFrom = x.effectiveDateFrom;
      return y;
    });

    // add all row data that is missing from form back into the post
    updatedInfo.qaqcId = qaqcId;

    // cannot just set UTC hours because we want to preserve the time if it exists
    const tempNewDateCheck = {
      attributeId: updatedInfo.attributeId,
      effectiveDateFrom: new Date(updatedInfo.dateFrom),
    };

    let alreadyExists = false;
    for (let i = 0; i < currentAllEffectiveDates.length; i += 1) {
      if (
        currentAllEffectiveDates[i].attributeId ===
          tempNewDateCheck.attributeId &&
        currentAllEffectiveDates[i].effectiveDateFrom ===
          tempNewDateCheck.effectiveDateFrom &&
        (rowToBeEdited
          ? rowToBeEdited.effectiveDateFrom !==
            tempNewDateCheck.effectiveDateFrom
          : true) // checks if we already possessed this date...
      ) {
        alreadyExists = true;
      }
    }

    if (alreadyExists) {
      this.setState({
        openConfirmationDialog: false,
        openDataFormatWarning: true,
        dateFormatWarningMessage:
          'Cannot have the same effective Date-From as an existing attribute with same attribute ID',
      });
    } else if (rowToBeEdited) {
      updatedInfo.sensorAttributeId = rowToBeEdited.sensorAttributeId;
      updatedInfo.resourceAttributeId = rowToBeEdited.resourceAttributeId;

      QaqcTestDetailsService.updateAttribute(updatedInfo)
        .then((response) => {
          this.handleCancel();
          if (response.data.statusCode === 0) {
            onInfo('Saved Successfully');
          } else if (response.data.statusCode === 9001) {
            onError(response.data.message);
          } else if (response.data.statusCode === 101) {
            onError(`Attribute ID doesn't exist in database`);
          } else {
            onError(
              `Failed to save with error code: ${response.data.statusCode}`
            );
          }
        })
        .then(() => {
          this.refresh();
        })
        .catch((response) => {
          onError(response.message);
        });
    } else {
      QaqcTestDetailsService.createAttribute(updatedInfo)
        .then((response) => {
          this.handleCancel();
          if (response.data.statusCode === 0) {
            onInfo('Saved Successfully');
          } else if (response.data.statusCode === 9001) {
            onError(response.data.message);
          } else if (response.data.statusCode === 101) {
            onError(`Attribute ID doesn't exist in database`);
          } else {
            onError(
              `Failed to save with error code: ${response.data.statusCode}`
            );
          }
        })
        .then(() => {
          this.refresh();
        })
        .catch((response) => {
          onError(response.message);
        });
    }
  };

  buildTableRows(sensorAttributes) {
    this.setState({
      tableRows: sensorAttributes.map((sensorAttribute) => {
        const sensorAttributesWithIcons = {
          ...sensorAttribute,
        };
        sensorAttributesWithIcons.modifyDate = DateFormatUtils.formatDate(
          sensorAttribute.modifyDate,
          'full'
        );
        sensorAttributesWithIcons.effectiveDateFrom =
          DateFormatUtils.formatDate(sensorAttribute.dateFrom, 'date');
        sensorAttributesWithIcons.icons = this.setupIcons(
          sensorAttributesWithIcons
        );
        sensorAttributesWithIcons.modifyBy =
          sensorAttribute.modifyByString || sensorAttribute.modifyBy;
        return sensorAttributesWithIcons;
      }),
    });
  }

  render() {
    const { permission } = this.props;

    const { tableRows } = this.state;
    if (!tableRows) return null;

    return (
      <>
        {this.addEventDialog()}
        {this.deleteEventDialog()}
        {this.addEventConfirmationDialog()}
        {this.ImproperDataFormatWarning()}
        <Panel actionContent={this.actionContent()}>
          <SortableTable
            searchBarMoveable={permission === 'RW'}
            pageSize={15}
            searchable
            columnSizes={this.columnSizeInfo}
            columnExtensions={tableColumnExtensions}
            elevation={0}
            stripedRows
            columns={headers}
            rows={tableRows}
            disabledSort={this.disabledSort}
          />
        </Panel>
      </>
    );
  }
}
export default withStyles(styles)(QaqcAttributesTable);
