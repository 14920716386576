import { CancelButton, SaveButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import {
  Layout,
  LAYOUT_RESOURCE_TYPE_ID,
  useUpdateLayout,
} from 'domain/AppComponents/Dashboard/LayoutService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { OncLayouts } from './DashboardTypes';

type SaveWidgetLayoutDialogProps = {
  applicationId: number;
  currentLayout: OncLayouts;
  layout?: Layout;
  onClose: () => void;
  onSave: () => void;
};

const OverwriteWidgetLayoutDialog = ({
  applicationId,
  currentLayout,
  layout = null,
  onClose,
  onSave,
}: SaveWidgetLayoutDialogProps) => {
  const { onInfo, onError } = useSnackbars();

  const { mutate: updateLayout } = useUpdateLayout(
    () => {
      onInfo('Layout saved');
      onSave();
    },
    () => onError('Error saving layout')
  );

  const handleSubmit = () => {
    updateLayout({
      resourceTypeId: LAYOUT_RESOURCE_TYPE_ID,
      resourceId: applicationId,
      layoutName: layout.layoutName,
      layoutId: layout.layoutId,
      widgetLayout: JSON.stringify(currentLayout),
      isPublic: false,
      ownerId: layout.ownerId,
    });
  };

  return (
    <Dialog open={!!layout} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Overwrite Layout?</DialogTitle>
      <DialogContent>
        {`Are you sure you wish to update the layout ${layout?.layoutName}?`}
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default OverwriteWidgetLayoutDialog;
