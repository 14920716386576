import useMediaQuery from '@mui/material/useMediaQuery';
import { withStyles, withTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { ContainedButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import {
  AnchoredHiddenMenu,
  Avatar,
  CardOld as Card,
  FloatingActionButton as Fab,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  MenuItem,
  RouterLink,
  Typography,
} from 'base-components';

import SortableTable from 'library/CompositeComponents/table/SortableTable';
import Environment from 'util/Environment';

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  fabContainer: {
    position: 'fixed',
  },
  button: {
    float: 'right',
  },
});

const DFManagementLandingPage = ({
  editPath = '',
  theme,
  campaignList,
  classes = undefined,
  statisticsPath = '',
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const columns = [
    { name: 'dfCampaignId', title: 'ID' },
    { name: 'active', title: 'Active' },
    { name: 'campaignName', title: 'Name' },
    { name: 'startDate', title: 'Start Date' },
    { name: 'endDate', title: 'End Date' },
    { name: 'modifiedBy', title: 'Modified By' },
    { name: 'modifiedDate', title: 'Modified Date' },
    { name: 'icons', title: ' ' },
  ];

  const onPreviewClick = (e, campaign) => {
    window.location.assign(
      `${Environment.getDmasUrl()}/DigitalFishers?campaignId=${
        campaign.dfCampaignId
      }`
    );

    // Both of these are necessary to prevent the click triggering the edit screen first
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  const renderListCampaigns = () => {
    const listItems = [];
    for (const campaign of campaignList) {
      listItems.push(
        <ListItem
          key={campaign.dfCampaignId}
          button
          component={RouterLink}
          to={`${editPath}/${campaign.dfCampaignId}`}
          params={{ ...campaign }}
        >
          <ListItemAvatar>
            <Avatar>
              <img src={campaign.campaignImage} alt=" " />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={campaign.missionTitle}
            secondary={campaign.campaignName}
          />
          <AnchoredHiddenMenu>
            <MenuItem onClick={(event) => onPreviewClick(event, campaign)}>
              Preview
            </MenuItem>
            <MenuItem
              button
              component={RouterLink}
              to={`${statisticsPath}/${campaign.dfCampaignId}`}
              params={{ ...campaign }}
            >
              Statistics
            </MenuItem>
          </AnchoredHiddenMenu>
        </ListItem>
      );
    }

    return listItems;
  };

  if (isMobile) {
    return (
      <>
        <List>{renderListCampaigns()}</List>
        <Fab
          containerClasses={classes.fab}
          color="primary"
          component={RouterLink}
          to={`${editPath}/0`}
          aria-label="Add Campaign"
        >
          <Add />
        </Fab>
      </>
    );
  }

  return (
    <Card
      title={
        <>
          <ContainedButton
            translationKey="digitalFishers.addCampaign"
            startIcon={<Add />}
            className={classes.button}
            component={RouterLink}
            to={`${editPath}/0`}
          />
          <Typography align="left" variant="h5" gutterBottom>
            Campaign List
          </Typography>
        </>
      }
      supportingContent={
        <SortableTable columns={columns} rows={campaignList} pageSize={15} />
      }
    />
  );
};

DFManagementLandingPage.propTypes = {
  theme: PropTypes.shape().isRequired,
  campaignList: PropTypes.arrayOf(
    PropTypes.shape({
      dfCampaignId: PropTypes.number,
      active: PropTypes.string,
      missionTitle: PropTypes.string,
      campaignName: PropTypes.string,
      startData: PropTypes.string,
      endDate: PropTypes.string,
      modifiedBy: PropTypes.number,
      modifiedDate: PropTypes.string,
      icons: PropTypes.node,
    })
  ).isRequired,
  editPath: PropTypes.string,
  statisticsPath: PropTypes.string,
  classes: PropTypes.shape({
    fab: PropTypes.string,
  }),
};

export default withTheme(withStyles(styles)(DFManagementLandingPage));
