/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ContainedButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import {
  Checkbox,
  FormControlLabel,
  LabelledCheckbox,
  Grid,
  Typography,
} from 'base-components';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import Panel from 'library/CompositeComponents/panel/Panel';
import CookieUtils from 'util/CookieUtils';
import TaxonButtonConfig from './TaxonButtonConfig';
import TaxonButtonSetService from '../../services/TaxonButtonSetService';
import ButtonSet from '../seatube/quick-entry/ButtonSet';

const INDEX_COOKIE = 'show-button-set-indexes';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
    width: `calc(100% - ${theme.spacing(2)})`,
  },
});

const TaxonButtonSetDetails = ({
  onError,
  onInfo,
  selectedConfig = undefined,
  attributeList,
  taxonomyList,
  reloadConfigList,
  groupList,
  classes,
}) => {
  TaxonButtonSetDetails.propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    attributeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    taxonomyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    groupList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedConfig: PropTypes.shape({
      deletePermission: PropTypes.bool,
      buttons: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    reloadConfigList: PropTypes.func.isRequired,
    classes: PropTypes.shape({ root: PropTypes.string }).isRequired,
  };

  const [configState, setConfigState] = useState(selectedConfig);
  const [selectedButton, setSelectedButton] = useState(undefined);
  const [triggerSave, setTriggerSave] = useState(false);
  const [currentButtonIndex, setCurrentButtonIndex] = useState(undefined);
  const [buttonType, setButtonType] = useState('Attribute');
  const [hasPermission, setHasPermission] = useState(false);
  const [showIndexes, setShowIndexes] = useState(true);

  const emptyButton = {
    colour: '#FFFFFF',
    label: '',
    taxonomyId: null,
    taxonomyName: null,
    taxonId: null,
    taxonName: null,
    enabled: false,
    comment: null,
  };

  useEffect(() => {
    const showIndexCookie = CookieUtils.getCookie(INDEX_COOKIE);
    if (showIndexCookie !== null) {
      setShowIndexes(showIndexCookie);
    }
  }, []);

  useEffect(() => {
    setConfigState(selectedConfig);
    setHasPermission(selectedConfig.deletePermission);
    setSelectedButton(undefined);
    if (selectedButton) {
      selectedButton.attributes
        ? setButtonType('Attribute')
        : setButtonType('Taxonomy');
    }
  }, [selectedConfig]);

  useEffect(() => {
    if (triggerSave) {
      setTriggerSave(false);
      if (selectedButton.label) {
        if (selectedButton.attributes && selectedButton.attributes.length > 0) {
          // if it is now a taxonomy button, delete each attribute
          if (selectedButton.comment || selectedButton.taxonId) {
            selectedButton.attributes.forEach((attr) =>
              attr.taxonButtonSetLineId
                ? TaxonButtonSetService.deleteButton(attr.taxonButtonSetLineId)
                : undefined
            );
            selectedButton.attributes = undefined;
            saveTaxonomyButton();
          } else {
            // otherwise, it's a regular attribute button update
            const updateParams = createAttributeUpdateParams();
            TaxonButtonSetService.updateAttributeButton(
              updateParams[0],
              updateParams[1]
            )
              .then((response) => {
                reloadConfigList(response.taxonButtonSetHdrId).then(() => {
                  setConfigState(response);
                  setSelectedButton(
                    response.buttons[selectedButton.sequenceNumber - 1]
                  );
                  onInfo('Updated Button');
                });
              })
              .catch((error) => onError(error));
          }
        } else {
          saveTaxonomyButton();
        }
      }
    }
  }, [selectedButton, onInfo, onError, triggerSave]);

  const createAttributeUpdateParams = () => {
    const { attributes, colour, comment, label, sequenceNumber } =
      selectedButton;
    const { taxonButtonSetHdrId } = configState;

    // construct and return a set of "buttons"
    return [
      taxonButtonSetHdrId,
      attributes.map((attribute) => ({
        ...attribute,
        colour,
        comment,
        label,
        sequenceNumber,
        taxonButtonSetHdrId,
      })),
    ];
  };

  const saveTaxonomyButton = () =>
    TaxonButtonSetService.update(configState.taxonButtonSetHdrId, [
      selectedButton,
    ])
      .then((response) => {
        reloadConfigList(response.taxonButtonSetHdrId).then(() => {
          setConfigState(response);
          setSelectedButton(
            response.buttons[selectedButton.sequenceNumber - 1]
          );
          onInfo('Updated Button');
        });
      })
      .catch((error) => onError(error.response?.data?.message));

  const handleQuickButtonClick = (button) => {
    // reset any unsaved changes
    updateConfigState({ buttons: selectedConfig.buttons });
    setSelectedButton(button);
    setCurrentButtonIndex(button.sequenceNumber);
    button.attributes ? setButtonType('Attribute') : setButtonType('Taxonomy');
  };

  const updateConfig = (name, enabled) => {
    const params = {
      name,
      enabled,
      taxonButtonSetHdrId: configState.taxonButtonSetHdrId,
    };
    TaxonButtonSetService.updateConfig(params)
      .then(() => {
        onInfo('Updated Button Set');
        reloadConfigList(configState.taxonButtonSetHdrId);
      })
      .catch((error) => onError(error.response?.data?.message));
  };

  const moveButton = (updatedButton) => {
    const updatedButtonList = _.cloneDeep(configState.buttons);
    // Add empty buttons if there is a gap in indexes
    let i = 0;
    while (i < updatedButton.sequenceNumber) {
      if (!updatedButtonList[i]) {
        const newButton = { ...emptyButton };
        newButton.sequenceNumber = i + 1;
        updatedButtonList[i] = newButton;
      }
      i += 1;
    }
    // Make the old button an empty button
    const newEmptyButton = emptyButton;
    newEmptyButton.modifyBy = updatedButton.modifyBy;
    newEmptyButton.sequenceNumber = currentButtonIndex;
    updatedButtonList[currentButtonIndex - 1] = emptyButton;
    // Update button to desired index and set as currently selected
    updatedButtonList[updatedButton.sequenceNumber - 1] = updatedButton;
    setCurrentButtonIndex(updatedButton.sequenceNumber);

    // Trim any empty buttons hanging off the end
    let j = updatedButtonList.length - 1;
    while (
      (updatedButtonList[j] === undefined ||
        updatedButtonList[j].label === '') &&
      j >= 0
    ) {
      updatedButtonList.splice(j);
      j -= 1;
    }
    updateConfigState({ buttons: updatedButtonList });
  };

  const updateButtonData = (newProps, shouldTriggerSave) => {
    const updatedButton = _.cloneDeep(selectedButton);
    const {
      index,
      newAttribute,
      deleteAttribute,
      taxonomyToAttribute,
      groupChange,
      attributeToTaxonomy,
      ...rest
    } = newProps;

    if (updatedButton.attributes) {
      // if an index was provided, then a specific attribute is affected
      if (index >= 0) {
        // if a delete indicator was provided, splice that attribute from the UI
        if (deleteAttribute) {
          updatedButton.attributes.splice(index, 1);
        } else {
          // otherwise, it's an update on an existing attribute
          // if it's a group change, clear the currently selected attribute
          if (groupChange) {
            updatedButton.attributes[index].taxonomyAttributeId = undefined;
            updatedButton.attributes[index].taxonomyAttributeLineId = undefined;
            updatedButton.attributes[index].attributeValue = undefined;
          }

          const updatedAttribute = {
            ...updatedButton.attributes[index],
            ...rest,
          };
          updatedButton.attributes[index] = updatedAttribute;
        }
        setSelectedButton(updatedButton);
      } else if (attributeToTaxonomy) {
        setSelectedButton(updatedButton);
      } else if (newAttribute) {
        // a new empty attribute is being created
        const emptyAttribute = { attributeGroupId: -1 };
        updatedButton.attributes.push(emptyAttribute);
        setSelectedButton(updatedButton);
      } else {
        // else, it's an attribute button's common fields
        setSelectedButton(Object.assign(updatedButton, rest));
      }
    } else if (taxonomyToAttribute) {
      // if it's a taxonomy to attribute button switch, add an empty attribute
      const attributes = [
        {
          attributeGroupId: -1,
          taxonButtonSetLineId: updatedButton.taxonButtonSetLineId,
        },
      ];
      updatedButton.attributes = attributes;

      // clear taxonomy button-related items
      updatedButton.taxonomyId = undefined;
      updatedButton.taxonName = undefined;
      updatedButton.taxonId = undefined;
      updatedButton.comment = undefined;
      setSelectedButton(updatedButton);
    } else {
      // otherwise, treat it as a regular button update
      setSelectedButton(Object.assign(updatedButton, newProps));
    }

    if (updatedButton.sequenceNumber !== currentButtonIndex) {
      moveButton(updatedButton);
    } else {
      const updatedButtonList = _.cloneDeep(configState.buttons);
      updatedButtonList[updatedButton.sequenceNumber - 1] = updatedButton;
      updateConfigState({ buttons: updatedButtonList });
      setTriggerSave(shouldTriggerSave);
    }
  };

  const updateConfigState = (newProps) => {
    const updatedConfigState = _.cloneDeep(configState);
    setConfigState(Object.assign(updatedConfigState, newProps));
  };

  const indexExists = (index) => {
    const buttonList = [...configState.buttons];
    if (!index) {
      return false;
    }

    return (
      buttonList.filter(
        (item) => item.sequenceNumber === index && item.label !== ''
      ).length > 0
    );
  };

  const handleAddButton = () => {
    let buttonList = [];
    if (configState && configState.buttons && configState.buttons.length) {
      buttonList = [...configState.buttons];
    }
    let i = 1;
    while (indexExists(i)) {
      i += 1;
    }
    const newEmptyButton = emptyButton;
    newEmptyButton.sequenceNumber = i;
    newEmptyButton.label = 'New Button';
    newEmptyButton.name = configState.name;
    newEmptyButton.taxonButtonSetHdrId = configState.taxonButtonSetHdrId;
    newEmptyButton.colour = '#b3b3b3';
    newEmptyButton.attributes =
      buttonType === 'Attribute' ? [{ attributeGroupId: -1 }] : undefined;
    buttonList[i - 1] = newEmptyButton;
    setCurrentButtonIndex(i);
    setSelectedButton(newEmptyButton);
    updateConfigState({ buttons: buttonList });
  };

  const handleDeleteButton = (deleteType, lineId) => {
    if (selectedButton.taxonButtonSetLineId && !lineId) {
      // taxonomy button delete
      TaxonButtonSetService.deleteButton(
        selectedButton.taxonButtonSetLineId
      ).then((response) => {
        setConfigState(response);
        reloadConfigList(configState.taxonButtonSetHdrId);
        onInfo('Successfully deleted button');
      });

      setCurrentButtonIndex(undefined);
      setSelectedButton(undefined);
    } else if (selectedButton.attributes && !lineId) {
      if (deleteType && !deleteType.startsWith('New Attribute')) {
        // attribute button delete
        TaxonButtonSetService.deleteAttributeButton(
          configState.taxonButtonSetHdrId,
          selectedButton.sequenceNumber
        ).then((response) => {
          setConfigState(response);
          reloadConfigList(configState.taxonButtonSetHdrId);
          onInfo('Successfully deleted button');
        });

        setCurrentButtonIndex(undefined);
        setSelectedButton(undefined);
      } else {
        // delete an unsaved attribute line (handled in updateButtonData)
        onInfo('Attribute removed');
      }
    } else if (lineId) {
      // attribute line delete
      TaxonButtonSetService.deleteButton(lineId).then((response) => {
        setConfigState(response);
        reloadConfigList(configState.taxonButtonSetHdrId);
        onInfo('Successfully deleted attribute');
      });
    }
  };

  const handleShowIndexes = () => {
    CookieUtils.saveCookie(INDEX_COOKIE, !showIndexes);
    setShowIndexes(!showIndexes);
  };

  const handleCancelButton = () => {
    updateConfigState({ buttons: selectedConfig.buttons });
    setSelectedButton(undefined);
    setCurrentButtonIndex(undefined);
  };

  return (
    <Panel
      title="Taxon Button Set"
      actionContent={
        hasPermission ? (
          <ContainedButton
            translationKey="taxonomy.addButton"
            startIcon={<Add />}
            onClick={handleAddButton}
          />
        ) : undefined
      }
    >
      <Grid container className={classes.root}>
        <Grid item xs={12} md={8} lg={6} xl={4}>
          <RORWTextField
            name="name"
            translationKey="common.textfields.name"
            fullWidth
            permission={hasPermission ? 'RW' : 'RO'}
            value={configState.name}
            onChange={(e) => updateConfigState({ name: e.target.value })}
            onBlur={() => updateConfig(configState.name, configState.enabled)}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hasPermission}
                checked={configState.enabled}
              />
            }
            label="Active"
            onChange={() => {
              const isEnabled = !configState.enabled;
              updateConfig(configState.name, isEnabled);
              updateConfigState({ enabled: isEnabled });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Button Set Preview</Typography>
        </Grid>
        <Grid item xs={12}>
          <LabelledCheckbox
            name="showIndexes"
            label="Show Button Indexes"
            onChange={handleShowIndexes}
            value={showIndexes}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonSet
            onButtonClick={handleQuickButtonClick}
            currentButtonSet={configState.buttons}
            currentButton={selectedButton}
            disableEmptyButtons={false}
            showIndexes={showIndexes}
          />
        </Grid>
        <Grid item xs={12}>
          <TaxonButtonConfig
            attributeList={attributeList}
            taxonomyList={taxonomyList}
            currentButton={selectedButton}
            currentButtonIndex={currentButtonIndex}
            buttonType={buttonType}
            setButtonType={setButtonType}
            updateButtonData={updateButtonData}
            indexExists={indexExists}
            handleDelete={handleDeleteButton}
            handleCancel={handleCancelButton}
            hasPermission={hasPermission}
            groupList={groupList}
          />
        </Grid>
      </Grid>
    </Panel>
  );
};

export default withStyles(styles)(TaxonButtonSetDetails);
