import { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useSnackbars } from 'base-components';
import SearchResultsActionTabs from 'domain/AppComponents/seatube/search/SearchResultsActionTabs';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import useDevices from 'domain/services/DevicesService';
import PlaylistService from 'domain/services/PlaylistService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import useWebService from 'util/hooks/useWebService';

const useStyles = makeStyles(() => createStyles({}));

const SearchExportWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id } = props;
  const { onError, onInfo } = useSnackbars();
  const { dashboardState: config } = useDashboardState();
  const classes = useStyles();

  const {
    searchResults,
    filter,
    searchTreeNodeIds,
    locationCode: location,
  } = config;

  const [playlistOptions, setPlaylistOptions] = useState([]);

  const [playlists, isLoading, fetchPlaylists] = useWebService({
    method: PlaylistService.getPlaylists,
    onError,
  });

  const {
    data: devices,
    isLoading: isDevicesLoading,
    refetch: fetchDevices,
  } = useDevices({ locationCode: location, deviceCategoryCode: 'VIDEOCAM' });

  const getExistingPlaylists = () => {
    if (!playlists) return;
    setPlaylistOptions(
      playlists
        .map((playlist) => ({
          label: playlist.name,
          value: playlist.playlistHdrId,
          locked: playlist.locked,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    );
  };

  useEffect(() => {
    if (searchResults?.length > 0) {
      fetchDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (searchResults?.length > 0) {
      fetchPlaylists();
    }
    getExistingPlaylists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  const resultsWithDeviceId =
    searchResults?.length > 0 && devices
      ? AnnotationUtil.addDeviceIdsToAnnotations(devices, searchResults)
      : [];

  return (
    <Widget
      title="Export"
      id={id}
      loading={
        isLoading ||
        isDevicesLoading ||
        !resultsWithDeviceId ||
        !playlistOptions
      }
      {...props}
    >
      <SearchResultsActionTabs
        diveIdString="0"
        deckLogIdString="0"
        searchResults={resultsWithDeviceId}
        filter={filter}
        classes={classes}
        isLoggedIn
        playlists={playlistOptions}
        onError={onError}
        onInfo={onInfo}
        onPlaylistAdd={getExistingPlaylists}
        searchTreeNodeIdString={searchTreeNodeIds.toString()}
        buildAnnotationFilterFn={
          AnnotationUtil.buildPublicAnnotationExportFilter
        }
      />
    </Widget>
  );
};

SearchExportWidget.widgetKey = 'seatube-search-export';
SearchExportWidget.widgetTitle = 'Export Results';
SearchExportWidget.defaultDataGrid = {
  i: 'seatube-search-export',
  x: 5,
  y: 0,
  w: 8,
  h: 12,
};

export default SearchExportWidget;
