/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import * as React from 'react';
import qs from 'qs';

import { ErrorAlert } from '@onc/composite-components';
import { CircularProgress } from 'base-components';
import DeckLogDashboard from 'domain/AppComponents/sea-tube/deck-log/DeckLogDashboard';
import SeaTubeConfigContext from 'domain/AppComponents/sea-tube/SeaTubeConfigContext';
import SeaTubeLogContext, {
  SeaTubeLogContextProps,
  SeaTubeLogType,
} from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import SeaTubePermissionContext from 'domain/AppComponents/sea-tube/SeaTubePermissionContext';
import CruiseService from 'domain/services/CruiseService';
import SeaTubeConfigurationService from 'domain/services/SeaTubeConfigurationService';
import SeaTubePermissionsService from 'domain/services/SeaTubePermissionsService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import useWebService from 'util/hooks/useWebService';
import QueryParameterContext from '../menu/QueryParameterContext';

interface DeckLogParams {
  cruiseId: string;
}

type Props = {
  onError: (message: any, callback?: any) => void;
  location: {
    search: string;
  };
  match: {
    isExact: boolean;
    params: DeckLogParams;
    path: string;
    url: string;
  };
};

const DeckLogApp: React.VFC<Props> = (props: Props) => {
  const { location, match, onError } = props;

  const { cruiseId } = match.params;

  // Handle Query parameters
  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [primaryWindowId, setPrimaryWindowId] = useState<string>(
    params.primaryWindowId as string
  );

  const [logContext, setLogContext] =
    useState<SeaTubeLogContextProps>(undefined);

  const [cruise, loadingCruise, fetchCruise] = useWebService({
    method: CruiseService.getCruisePublic,
  });

  const [seaTubeConfig, loadingConfig, fetchSeaTubeConfig] = useWebService({
    method: SeaTubeConfigurationService.getOrganizationConfig,
    onError,
  });

  const [annotationPermissions, loadingPermissions, fetchPermissions] =
    useWebService({
      method: SeaTubePermissionsService.getUserPermissions,
      onError,
      parser: SeaTubePermissionsService.buildDiveLogPermissions, // Deck / Dive log have the same permission logic
    });

  useEffect(() => {
    fetchCruise(Number(cruiseId)).then((result) => {
      setLogContext({
        startDate: result.startDate,
        endDate: result.endDate,
        cruiseId: result.cruiseId,
        logType: SeaTubeLogType.EXPEDITION,
        cruise: result,
      });
    });
    fetchPermissions({ cruiseId: Number(cruiseId) });
    if (!primaryWindowId) {
      setPrimaryWindowId(crypto.getRandomValues(new Uint32Array(1)).toString());
    }
  }, []);

  useEffect(() => {
    if (!cruise) return;
    fetchSeaTubeConfig(cruise.organizationId);
  }, [cruise]);

  const renderContent = () => {
    if (cruise === null) {
      return (
        <ErrorAlert title="Error">
          There was a problem getting cruise id: {cruiseId}
        </ErrorAlert>
      );
    }

    if (
      cruise === undefined ||
      loadingCruise ||
      loadingPermissions ||
      loadingConfig
    ) {
      return (
        <CircularProgress
          size={120}
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
          }}
        />
      );
    }

    return (
      <QueryParameterContext.Provider value={params}>
        <SeaTubeLogContext.Provider value={logContext}>
          <SeaTubePermissionContext.Provider value={annotationPermissions}>
            <SeaTubeConfigContext.Provider value={seaTubeConfig}>
              <DeckLogDashboard primaryWindowId={primaryWindowId} />
            </SeaTubeConfigContext.Provider>
          </SeaTubePermissionContext.Provider>
        </SeaTubeLogContext.Provider>
      </QueryParameterContext.Provider>
    );
  };

  return (
    <div
      style={{
        backgroundColor: '#EEE',
        height: '100%',
      }}
    >
      {renderContent()}
    </div>
  );
};

export default withSnackbars(DeckLogApp);
