import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';

import {
  CancelButton,
  DeleteButton,
  SaveButton,
} from '@onc/composite-components';
import DeviceNamePlateService from 'domain/services/DeviceNamePlateService';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import DateUtils from 'util/DateUtils';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { parseDmasAPIResponse } from 'util/WebRequest';
import OrganizationDropdown from '../dropdowns/OrganizationDropdown';
import {
  MarginedReadOnlyField,
  ModifyBy,
  ModifyDate,
} from '../Form/Fields/ReadOnlyFields';

import {
  handleOpenDeviceDetails,
  openDeviceDetails,
} from '../link/DeviceDetailsLink';

const useStyles = makeStyles((theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  modifyRoot: {
    marginTop: theme.spacing(1),
  },
}));

type defaultValuesProps = {
  serialnr: string;
  serialnr2: string;
  modelnr: string;
  supplier: { label: string; value?: number };
  producer: { label: string; value?: number };
  deviceId: number;
  manufactureDate: Moment;
  firmwareRevision: any;
  softwareRevision: string;
  contactInfo: string;
  namePlateDescription: string;
  deviceName: string;
  modifyBy: string;
  modifyDate: string;
};

type DeviceNamePlatePayload = {
  namePlate: {
    manufactureDate?: string;
    device: {
      deviceName: string;
      [key: string]: unknown;
    };
    [key: string]: unknown;
  };
  producer: {
    organizationName: string;
    organizationId: number;
  };
  supplier?: {
    organizationName: string;
    organizationId: number;
  };
  dmasUser: {
    firstname: string;
    lastname: string;
  };
};

type DevicePayload = {
  devices: {
    device: {
      name: string;
      [key: string]: unknown;
    };
    [key: string]: unknown;
  };
  [key: string]: unknown;
};

type NamePlateFormProps = {
  namePlateId: number;
  deviceId: number;
  namePlateData: DeviceNamePlatePayload;
  deviceData: DevicePayload;
};

const NamePlateForm = ({
  namePlateId,
  deviceId,
  namePlateData,
  deviceData,
}: NamePlateFormProps) => {
  const classes = useStyles();

  const { onError, onInfo } = useSnackbars();

  const handleSubmit: (any) => void = (values) => {
    const formattedValues = {
      contactInfo: values.contactInfo,
      namePlateDescription: values.namePlateDescription,
      serialnr: values.serialnr,
      modelnr: values.modelnr,
      organizationId1: values.producer.value,
      organizationId2: values.supplier.value,
      deviceId,
      serialnr2: values.serialnr2,
      manufactureDate: values.manufactureDate?.toISOString(),
      firmwareRevision: values.firmwareRevision,
      softwareRevision: values.softwareRevision,
      ...(!namePlateId || isNaN(namePlateId) ? {} : { namePlateId }),
    };

    const promise =
      !namePlateId || isNaN(namePlateId)
        ? DeviceNamePlateService.create(formattedValues)
        : DeviceNamePlateService.update(formattedValues);
    promise
      .then(parseDmasAPIResponse)
      .then(() => {
        onInfo('Device Nameplate Saved!');
        openDeviceDetails(deviceId, 'nameplate_tab');
      })
      .catch(() => onError('Device Nameplate not saved!'));
  };

  const handleDelete: () => void = () => {
    const params = {
      namePlateId,
    };
    const promise = DeviceNamePlateService.delete(params);
    promise
      .then(parseDmasAPIResponse)
      .then(() => {
        onInfo('Nameplate Deleted!');
        openDeviceDetails(deviceId, 'nameplate_tab');
      })
      .catch(() => onError('Nameplate not deleted!'));
  };

  const defaultValues: defaultValuesProps = {
    serialnr: undefined,
    serialnr2: undefined,
    modelnr: undefined,
    supplier: { label: undefined, value: undefined },
    producer: { label: undefined, value: undefined },
    deviceId: undefined,
    manufactureDate: undefined,
    firmwareRevision: undefined,
    softwareRevision: undefined,
    contactInfo: undefined,
    namePlateDescription: undefined,
    deviceName: undefined,
    modifyBy: undefined,
    modifyDate: DateUtils.formatDateAsString(new Date()),
  };

  if (deviceData) {
    defaultValues.deviceName = deviceData.devices.device.name;
  } else {
    const { namePlate, producer, supplier, dmasUser } = namePlateData;
    const { manufactureDate, ...rest } = namePlate;
    Object.assign(defaultValues, rest);
    defaultValues.manufactureDate = manufactureDate
      ? moment(manufactureDate)
      : undefined;
    defaultValues.producer = {
      label: producer.organizationName,
      value: producer.organizationId,
    };
    defaultValues.supplier = supplier
      ? { label: supplier.organizationName, value: supplier.organizationId }
      : undefined;
    defaultValues.modifyBy = `${dmasUser.firstname} ${dmasUser.lastname}`;
    defaultValues.deviceName = namePlate.device.deviceName;
  }

  const formMethods = useForm<defaultValuesProps>({
    defaultValues: {
      serialnr: defaultValues.serialnr,
      serialnr2: defaultValues.serialnr2,
      modelnr: defaultValues.modelnr,
      producer: defaultValues.producer
        ? defaultValues.producer
        : { label: '', value: undefined },
      supplier: defaultValues.supplier
        ? defaultValues.supplier
        : { label: '', value: undefined },
      manufactureDate: defaultValues.manufactureDate,
      firmwareRevision: defaultValues.firmwareRevision,
      softwareRevision: defaultValues.softwareRevision,
      contactInfo: defaultValues.contactInfo,
      namePlateDescription: defaultValues.namePlateDescription,
      deviceName: defaultValues.deviceName,
      modifyBy: defaultValues.modifyBy,
      modifyDate: defaultValues.modifyDate,
    },
    mode: 'onBlur',
  });

  return (
    <Form onSubmit={handleSubmit} formMethods={formMethods}>
      <Grid container direction="row" className={classes.entryFormContainer}>
        <Grid item xs={6}>
          <FormTextField
            name="serialnr"
            translationKey="device.serialNumber"
            className={classes.textField}
            fullWidth
            rules={{ required: 'A serial number is required!' }}
          />
          <FormTextField
            name="serialnr2"
            translationKey="device.serialNumber2"
            className={classes.textField}
            fullWidth
          />
          <FormTextField
            name="modelnr"
            translationKey="device.modelNumber"
            className={classes.textField}
            fullWidth
            rules={{ required: 'A model number is required!' }}
          />
          <OrganizationDropdown
            name="producer"
            translationKey="device.manufacturer"
            className={classes.textField}
            fullWidth
            useNone={false}
            rules={{ required: 'A manufacturer is required!' }}
          />
          <OrganizationDropdown
            name="supplier"
            translationKey="cables.supplier"
            className={classes.textField}
            fullWidth
            useNone={false}
          />
          <FormDateTimePicker
            name="manufactureDate"
            translationKey="device.manufacturedDate"
            className={classes.textField}
            fullWidth
          />
          <FormTextField
            name="firmwareRevision"
            translationKey="device.firmwareRevision"
            fullWidth
            className={classes.textField}
          />
          <FormTextField
            name="softwareRevision"
            translationKey="device.softwareRevision"
            fullWidth
            className={classes.textField}
          />
          <FormTextField
            name="contactInfo"
            translationKey="device.contactInfo"
            fullWidth
            multiline
            minRows={2}
            className={classes.textField}
          />
          <FormTextField
            name="namePlateDescription"
            translationKey="common.textfields.description"
            fullWidth
            multiline
            minRows={2}
            className={classes.textField}
          />
          <MarginedReadOnlyField
            labelText="Device"
            valueText={defaultValues.deviceName}
            title="deviceField"
          />
          <ModifyBy
            username={defaultValues.modifyBy}
            classes={{ root: classes.modifyRoot }}
          />
          <ModifyDate
            date={
              defaultValues.modifyDate
                ? DateUtils.formatDateAsString(
                    new Date(defaultValues.modifyDate)
                  )
                : ''
            }
            classes={{ root: classes.modifyRoot }}
          />
        </Grid>
      </Grid>
      <div className={classes.formButtons}>
        <SaveButton type="submit" />
        <CancelButton
          onClick={handleOpenDeviceDetails(deviceId, 'nameplate_tab')}
        />
        <DeleteButton onClick={handleDelete} disabled={isNaN(namePlateId)} />
      </div>
    </Form>
  );
};

export default NamePlateForm;
