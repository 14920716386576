import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ClearButton } from '@onc/composite-components';
import { Autocomplete, Grid } from 'base-components';
import SiteDeviceSubsetTable from 'domain/Apps/site-device-maintenance/SiteDeviceSubsetTable';
import SiteDeviceSubsetService from 'domain/services/SiteDeviceSubsetService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const STYLES = (theme) => ({
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    marginBottom: theme.spacing(),
  },
  gridPadding: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(3),
  },
});

class SiteDeviceSubsetSearch extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      formButtons: PropTypes.string,
      gridPadding: PropTypes.string,
    }),
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      siteDeviceSubsets: [],
      siteDeviceSubsetSelectValue: undefined,
      selectedSiteDeviceSubsets: [],
    };
  }

  componentDidMount() {
    this.getSiteDeviceSubsets();
  }

  getSiteDeviceSubsets = async () => {
    const { onError } = this.props;
    try {
      // GET_MANY operation;
      const subsetPayload =
        await SiteDeviceSubsetService.getAllSiteDeviceSubsets();
      const siteDeviceSubsets = subsetPayload.records;
      // get all cast siteDeviceSubset types
      const castSubsetTypes =
        await SiteDeviceSubsetService.getCastSiteDeviceSubsetTypes();
      this.setState({
        siteDeviceSubsets: siteDeviceSubsets.filter((subset) =>
          castSubsetTypes.includes(subset.siteDeviceSubsetType)
        ),
      });
    } catch (error) {
      onError('Could not get siteDeviceSubsets');
    }
  };

  onSubsetChange = (event, value) => {
    const { selectedSiteDeviceSubsets, siteDeviceSubsets } = this.state;
    if (!value) {
      return;
    }
    this.setState({
      selectedSiteDeviceSubsets: [value].concat(selectedSiteDeviceSubsets),
      siteDeviceSubsets: siteDeviceSubsets.filter(
        (subset) => subset.siteDeviceSubsetId !== value.siteDeviceSubsetId
      ),
    });
  };

  onClearClick = () => {
    this.setState({
      siteDeviceSubsets: [],
      selectedSiteDeviceSubsets: [],
      siteDeviceSubsetSelectValue: undefined,
    });
    this.getSiteDeviceSubsets();
  };

  render() {
    const { classes, onInfo, onError } = this.props;
    const {
      siteDeviceSubsets,
      siteDeviceSubsetSelectValue,
      selectedSiteDeviceSubsets,
    } = this.state;
    return (
      <Grid container>
        <Grid item xs={3} className={classes.gridPadding}>
          <Autocomplete
            virtualized
            getOptionLabel={(siteDeviceSubset) => {
              if (siteDeviceSubset) {
                return `${siteDeviceSubset.siteDeviceSubsetName} (${siteDeviceSubset.siteDeviceSubsetId})`;
              }
              return '';
            }}
            name="SiteDeviceSubsetListAutocomplete"
            onChange={this.onSubsetChange}
            options={siteDeviceSubsets}
            value={siteDeviceSubsetSelectValue}
            translationKey="device.siteDeviceSubsetSelection"
          />
        </Grid>
        <Grid item xs={12}>
          <SiteDeviceSubsetTable
            onInfo={onInfo}
            onError={onError}
            siteDeviceSubsetRows={selectedSiteDeviceSubsets}
            pageSize={10}
            renderAddButton={false}
          />
        </Grid>
        <Grid item xs={12} className={classes.formButtons}>
          <ClearButton onClick={this.onClearClick} />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(SiteDeviceSubsetSearch));
