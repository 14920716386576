/* eslint-disable react/jsx-no-constructed-context-values */
import * as React from 'react';
import ChatLogIngestionContext from 'domain/AppComponents/seatube/wizards/chat-log-ingestion/ChatLogIngestionContext';
import ChatLogIngestionStepper from 'domain/AppComponents/seatube/wizards/chat-log-ingestion/ChatLogIngestionStepper';
import CruiseService from 'domain/services/CruiseService';
import DiveListingService from 'domain/services/DiveListingService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

type Props = { onError: (message: any, callback?: any) => void };

const ChatLogIngestionContainer: React.VFC<Props> = ({ onError }) => {
  const getDives = async (date: string, cruiseId: number) =>
    DiveListingService.getDives({ date, cruiseId })
      .then((response) => response.dives)
      .catch((error) => {
        onError(error);
      });

  const getCruises = async (date: string) =>
    CruiseService.getCruises(date)
      .then((response) => response.cruises)
      .catch((error) => {
        onError(error.message);
      });

  return (
    <ChatLogIngestionContext.Provider value={{ getDives, getCruises }}>
      <ChatLogIngestionStepper />
    </ChatLogIngestionContext.Provider>
  );
};

export default withSnackbars(ChatLogIngestionContainer);
