import { createStyles, makeStyles } from '@mui/styles';
import {
  ContainedButton,
  OutlinedButton,
  TextButton,
} from '@onc/composite-components';
import { Tooltip } from 'base-components';

const useStyles = makeStyles(() =>
  createStyles({
    buttonPairContainer: {
      textAlign: 'end',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
  })
);

type SeaTubeSearchFilterButtonsProps = {
  filter: any;
  isLoggedIn: boolean;
  onSubmit: (e: object) => void;
  onReset: (e: object) => void;
  onImageExportClick?: (e: object) => void;
};

const SeaTubeSearchFilterButtons = ({
  filter,
  isLoggedIn,
  onSubmit,
  onReset,
  onImageExportClick = undefined,
}: SeaTubeSearchFilterButtonsProps) => {
  const classes = useStyles();

  const searchDisabled =
    (!filter?.cruises || filter.cruises.length === 0) &&
    (!filter?.searchTreeNodeIds || filter?.searchTreeNodeIds.value === -1);

  const getImageExportTooltipText = () => {
    if (!isLoggedIn) {
      return 'Please log in to export images';
    }
    if (searchDisabled) {
      return 'Please select at least one expedition';
    }
    return '';
  };

  const renderImageExportButton = () => {
    if (onImageExportClick) {
      return (
        <Tooltip title={getImageExportTooltipText()} placement="top">
          <span>
            <OutlinedButton
              translationKey="seatube.imageExport"
              onClick={onImageExportClick}
              disabled={searchDisabled || !isLoggedIn}
            />
          </span>
        </Tooltip>
      );
    }
    return undefined;
  };
  const searchButtonTooltipMsg = filter?.cruises
    ? 'Please select at least one expedition'
    : 'Please select a location';
  return (
    <div className={classes.buttonPairContainer}>
      <TextButton translationKey="common.buttons.reset" onClick={onReset} />
      {renderImageExportButton()}
      <Tooltip
        title={searchDisabled ? searchButtonTooltipMsg : ''}
        placement="top"
      >
        <span>
          <ContainedButton
            onClick={onSubmit}
            disabled={searchDisabled}
            aria-label="Search"
            translationKey="common.search"
          />
        </span>
      </Tooltip>
    </div>
  );
};

export default SeaTubeSearchFilterButtons;
