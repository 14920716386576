import { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import propTypes from 'prop-types';
import { SaveButton, TextButton } from '@onc/composite-components';
import { Typography } from 'base-components';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import ObisConfigurationService from 'domain/services/ObisConfigurationService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const HTTPS_STATUS_FORBIDDEN = 403;

const styles = (theme) => ({
  header: {
    marginBottom: theme.spacing(4),
  },
  inputFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: theme.spacing(1),
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const ObisConfiguration = (props) => {
  const { classes, onError, onInfo } = props;
  const [apiUrl, setApiUrl] = useState('');
  const [contextPath, setContextPath] = useState('');
  const [distanceOffset, setDistanceOffset] = useState();
  const [depthOffset, setDepthOffset] = useState();
  const [savedConfig, setSavedConfig] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    ObisConfigurationService.getObisSettings()
      .then((response) => {
        setSavedConfig(response.obisSettings);
        setApiUrl(response.obisSettings.apiUrl);
        setContextPath(response.obisSettings.contextPath);
        setDistanceOffset(response.obisSettings.distanceOffset);
        setDepthOffset(response.obisSettings.depthOffset);
        setSavedConfig(response.obisSettings);
        setIsEditable(response.isDmasAdmin);
      })
      .catch((error) => {
        const errorMsg =
          error.statusCode === HTTPS_STATUS_FORBIDDEN
            ? 'Invalid user permission.'
            : error;
        onError(errorMsg);
      });
  }, [onError]);

  const permissionLevel = isEditable ? 'RW' : 'RO';

  const handleReset = () => {
    setApiUrl(savedConfig.apiUrl);
    setContextPath(savedConfig.contextPath);
    setDistanceOffset(savedConfig.distanceOffset);
    setDepthOffset(savedConfig.depthOffset);
    setHasChanges(false);
  };

  const handleSubmit = () => {
    ObisConfigurationService.saveObisSettings({
      apiUrl,
      contextPath,
      distanceOffset,
      depthOffset,
    })
      .then((response) => {
        if (response.statusCode === 0) {
          setSavedConfig({
            apiUrl,
            contextPath,
            distanceOffset,
            depthOffset,
          });
          onInfo('Configuration saved successfully.');
        }
      })
      .catch((error) => {
        onError(error);
      });
    setHasChanges(false);
  };

  const handleChange = (event, name) => {
    switch (name) {
      case 'apiUrl':
        setApiUrl(event);
        break;
      case 'contextPath':
        setContextPath(event);
        break;
      case 'distanceOffset':
        setDistanceOffset(event);
        break;
      case 'depthOffset':
        setDepthOffset(event);
        break;
      default:
        throw new Error(`${name} field is not yet implemented`);
    }
    setHasChanges(true);
  };

  const renderFormButtons = () => (
    <div className={classes.flexContainer}>
      <TextButton
        translationKey="common.buttons.reset"
        onClick={handleReset}
        disabled={!hasChanges}
      />
      <SaveButton onClick={handleSubmit} disabled={!hasChanges} />
    </div>
  );

  return (
    <div>
      <Typography className={classes.header} variant="h6">
        OBIS Test Configuration
      </Typography>
      <div className={classes.inputFieldContainer}>
        <RORWTextField
          name="apiUrl"
          translationKey="seatube.apiUrl"
          value={apiUrl}
          onChange={(event) => handleChange(event.target.value, 'apiUrl')}
          permission={permissionLevel}
        />
        <RORWTextField
          name="contextPath"
          translationKey="seatube.contextPath"
          multiline
          rows={2}
          value={contextPath}
          permission={permissionLevel}
          onChange={(event) => handleChange(event.target.value, 'contextPath')}
        />
        <RORWTextField
          name="distanceOffset"
          translationKey="seatube.distanceOffset"
          type="number"
          value={distanceOffset}
          permission={permissionLevel}
          onChange={(event) =>
            handleChange(event.target.value, 'distanceOffset')
          }
        />
        <RORWTextField
          name="depthOffset"
          translationKey="seatube.depthOffset"
          type="number"
          value={depthOffset}
          permission={permissionLevel}
          onChange={(event) => handleChange(event.target.value, 'depthOffset')}
        />
        {isEditable ? renderFormButtons() : undefined}
      </div>
    </div>
  );
};

ObisConfiguration.propTypes = {
  classes: propTypes.shape().isRequired,
  onError: propTypes.func.isRequired,
  onInfo: propTypes.func.isRequired,
};

export default withStyles(styles)(withSnackbars(ObisConfiguration));
