import { Box, Grid, Typography } from 'base-components';

import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import DummyWidgetConfigContent, {
  type DummyConfigType,
} from './Config/DummyWidgetConfigContent';

const defaultConfig = {
  showWidgetHeader: true,
  widgetTitle: 'Sample Widget Title',
};

const DummyWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, initialConfig = {} } = props;
  const { config, setConfig } = useWidgetConfig<DummyConfigType>(id, {
    ...defaultConfig,
    ...initialConfig,
  });

  return (
    <Widget
      ariaLabel="dummyWidget"
      id={id}
      titleComponents={
        <Typography variant="subtitle1">{config.widgetTitle}</Typography>
      }
      ConfigFieldsComponent={DummyWidgetConfigContent}
      config={config}
      onChangeConfig={setConfig}
      collapseHeader={config.showWidgetHeader}
      closeMenuItems
      {...props}
    >
      <Grid container sx={{ height: '100%', paddingX: 2 }}>
        <Grid item aria-label="widgetContent" xs={12} sx={{ height: '99%' }}>
          <Box sx={{ bgcolor: '#e7f0d5', height: '98%' }}>
            Sample Dummy Widget Text
          </Box>
        </Grid>
      </Grid>
    </Widget>
  );
};

DummyWidget.widgetKey = 'dummy-widget';
DummyWidget.widgetTitle = '';
DummyWidget.defaultDataGrid = {
  i: 'example-widget',
  x: 0,
  y: 0,
  w: 9999,
  h: 5,
};

export default DummyWidget;
