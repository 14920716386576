import { useState } from 'react';
import Environment from '@onc/environment';
import {
  DevExpressTableColumnExtension,
  StatelessTable,
  useSnackbars,
} from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import {
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import { type LicenceRow } from 'domain/AppComponents/licence/Licence.types';
import LicenceFormConfig from 'domain/AppComponents/licence/LicenceFormConfig';
import * as LicenceService from 'domain/services/LicenceService';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import DateFormatUtils from 'util/DateFormatUtils';

const licenceColumnExtensions: DevExpressTableColumnExtension[] = [
  {
    columnName: 'licenceId',
    align: 'left',
    width: ColumnInfo.mini,
    wordWrapEnabled: true,
  },
  {
    columnName: 'licenceName',
    align: 'left',
    wordWrapEnabled: true,
  },
  {
    columnName: 'licenceAbbreviation',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'licenceProvider',
    align: 'left',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
  },
  {
    columnName: 'licenceProviderUrl',
    align: 'left',
    width: ColumnInfo.medium,
  },
  {
    columnName: 'licenceUrl',
    align: 'left',
    wordWrapEnabled: true,
  },
  {
    columnName: 'licenceConstraint',
    align: 'left',
    wordWrapEnabled: true,
  },
  {
    columnName: 'modifiedBy',
    align: 'left',
    width: ColumnInfo.small,
    wordWrapEnabled: true,
  },
  {
    columnName: 'modifiedDate',
    align: 'left',
    width: ColumnInfo.small,
    wordWrapEnabled: true,
  },
  {
    columnName: 'icons',
    align: 'right',
    width: ColumnInfo.small,
  },
];

const iconHeader = { title: ' ', name: 'icons' };

const headers = [
  { title: 'ID', name: 'licenceId' },
  { title: 'Licence Name', name: 'licenceName' },
  { title: 'Abbreviation', name: 'licenceAbbreviation' },
  { title: 'Provider', name: 'licenceProvider' },
  { title: 'Provider URL', name: 'licenceProviderUrl' },
  { title: 'Licence URL', name: 'licenceUrl' },
  { title: 'Constraint', name: 'licenceConstraint' },
  { title: 'Modified By', name: 'modifiedBy' },
  { title: 'Modified Date', name: 'modifiedDate' },
];

const licenceHeaders = (permission: string) =>
  permission !== 'RW' ? headers : [...headers, iconHeader];

const icons = (
  row: LicenceRow,
  permission: string,
  setRowToBeDeleted: (id: number) => void,
  setRowToBeEdited: (row: LicenceRow) => void
) => {
  if (permission !== 'RW') {
    return null;
  }
  return (
    <>
      <EditIconButton onClick={() => setRowToBeEdited(row)} aria-label="edit" />
      <DeleteIconButton
        onClick={() => setRowToBeDeleted(row.licenceId)}
        size="small"
        aria-label="delete"
      />
    </>
  );
};

const LicenceTable: React.FC = () => {
  const [isCreatingLicence, setCreatingLicence] = useState(false);
  const [rowToBeEdited, setRowToBeEdited] = useState<LicenceRow | undefined>();
  const [rowToBeDeleted, setRowToBeDeleted] = useState<number | undefined>();

  const { onInfo, onError } = useSnackbars();

  const { data: licences, refetch: refetchLicences } =
    LicenceService.useFetchAll();

  const { mutate: deleteLicence } = LicenceService.useDelete({
    onSuccess: () => {
      onInfo(`Licence (ID ${rowToBeDeleted}) deleted`);
      setRowToBeDeleted(undefined);
      refetchLicences();
    },
    onError: (e) => {
      onError(e.message);
    },
  });

  const permission = Environment.getDmasUserPrivilege();

  const rows: LicenceRow[] = (licences ?? []).map(
    ({ modifyBy, modifyDate, ...rest }) => ({
      ...rest,
      modifiedBy: `${modifyBy.firstname} ${modifyBy.lastname}`,
      modifiedDate: DateFormatUtils.formatDate(modifyDate, 'date'),
    })
  );

  const onCancelCreate = (licenceCreated?: boolean) => {
    setCreatingLicence(false);
    if (licenceCreated) refetchLicences();
  };
  const onCancelEdit = (licenceUpdated?: boolean) => {
    setRowToBeEdited(undefined);
    if (licenceUpdated) refetchLicences();
  };
  const onCancelDelete = () => setRowToBeDeleted(undefined);

  return (
    <>
      {isCreatingLicence && (
        <LicenceFormConfig
          licenceId={undefined}
          originalLicence={undefined}
          open
          onClose={onCancelCreate}
        />
      )}
      {rowToBeEdited && (
        <LicenceFormConfig
          licenceId={rowToBeEdited?.licenceId}
          originalLicence={rowToBeEdited}
          open
          onClose={onCancelEdit}
        />
      )}
      {rowToBeDeleted && (
        <DeleteDialog
          title={`Delete Licence ${rowToBeDeleted}?`}
          open={Boolean(rowToBeDeleted)}
          onCancel={onCancelDelete}
          onDelete={() => deleteLicence({ licenceId: rowToBeDeleted })}
        />
      )}
      <StatelessTable
        onCreate={() => setCreatingLicence(true)}
        title="Licences"
        permission={permission}
        columns={licenceHeaders(permission)}
        columnExtensions={licenceColumnExtensions}
        getRowId={(row) => row.licenceId}
        onRefresh={refetchLicences}
        rows={(permission !== 'RW'
          ? rows
          : rows.map((row) => ({
              ...row,
              icons: icons(
                row,
                permission,
                setRowToBeDeleted,
                setRowToBeEdited
              ),
            }))
        ).sort((a, b) => a.licenceId - b.licenceId)}
      />
    </>
  );
};

export default LicenceTable;
