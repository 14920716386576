import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const searchLayout: OncLayouts = {
  lg: [
    { w: 4, h: 24, x: 0, y: 0, i: 'seatube-search-form' },
    { w: 8, h: 16, x: 4, y: 0, i: 'seatube-search-results' },
    { w: 8, h: 8, x: 4, y: 16, i: 'seatube-search-export' },
  ],
  md: [
    { w: 4, h: 24, x: 0, y: 0, i: 'seatube-search-form' },
    { w: 6, h: 16, x: 4, y: 0, i: 'seatube-search-results' },
    { w: 6, h: 8, x: 4, y: 16, i: 'seatube-search-export' },
  ],
  sm: [
    { w: 8, h: 12, x: 0, y: 0, i: 'seatube-search-form' },
    { w: 8, h: 12, x: 0, y: 0, i: 'seatube-search-results' },
    { w: 8, h: 12, x: 0, y: 0, i: 'seatube-search-export' },
  ],
  xs: [
    { w: 6, h: 12, x: 0, y: 0, i: 'seatube-search-form' },
    { w: 6, h: 12, x: 0, y: 0, i: 'seatube-search-results' },
    { w: 6, h: 12, x: 0, y: 0, i: 'seatube-search-export' },
  ],
};

export default searchLayout;
