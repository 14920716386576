/* eslint-disable no-console */
import { useEffect, useState, useCallback, useRef } from 'react';
import BroadcastChannelType from 'domain/Widgets/BroadcastChannel';

const ON_LOAD = 'REQUEST_ON_LOAD';

/**
 * Helper to create a safely serializable copy of an object Removes functions,
 * symbols, and other non-serializable properties
 */
const createSerializableCopy = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  // Handle primitive types
  if (typeof obj !== 'object') {
    return typeof obj === 'function' ? null : obj;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map((item) => createSerializableCopy(item));
  }

  // Handle Date objects
  if (obj instanceof Date) {
    return obj.toISOString();
  }

  // Handle plain objects
  const result: Record<string, any> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Skip functions and symbols
      if (typeof obj[key] === 'function' || typeof obj[key] === 'symbol') {
        // eslint-disable-next-line no-continue
        continue;
      }
      result[key] = createSerializableCopy(obj[key]);
    }
  }

  return result;
};

export default function useBroadcast<T>(
  windowId: string,
  broadcastType: BroadcastChannelType,
  initialState: any,
  source?: string,
  validSources?: string[]
): [state: T, setState: (value: T) => void] {
  const channelName = `${windowId}-${broadcastType}`;
  const [state, setStateVal] = useState<T>(initialState);

  // Use useRef to keep the BroadcastChannel instance persistent across re-renders
  const channelRef = useRef<BroadcastChannel | null>(
    new BroadcastChannel(channelName)
  );

  // Function to send messages via the BroadcastChannel
  const sendMessage = useCallback(
    (value: any) => {
      if (channelRef.current) {
        try {
          const serializedValue = createSerializableCopy(value);
          channelRef.current.postMessage({ value: serializedValue, source });
        } catch (error) {
          console.error('Failed to post message:', error);
        }
      }
    },
    [source] // Re-create sendMessage callback if source changes
  );

  // Request data from other tabs upon component mount
  useEffect(() => {
    sendMessage(ON_LOAD);
  }, [sendMessage]);

  // Handle incoming messages from the BroadcastChannel
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      const { value, source: messageSource } = data;

      // Ignore messages from invalid sources
      if (validSources && !validSources.includes(messageSource)) {
        return;
      }

      // If the message is a load request, respond with the current state
      if (value === ON_LOAD) {
        if (source !== messageSource) {
          sendMessage(state);
        }
        return;
      }

      // Update the state with the received value
      setStateVal(value);
    };

    const currentChannel = channelRef.current;
    // Add event listener for incoming messages
    currentChannel?.addEventListener &&
      currentChannel.addEventListener('message', handleMessage);

    // Cleanup event listener on component unmount
    return () => {
      currentChannel?.removeEventListener &&
        currentChannel?.removeEventListener('message', handleMessage);
    };
  }, [validSources, sendMessage, state, source]);

  // Function to set state and broadcast the new state to other tabs
  const setState = useCallback(
    (valueOrUpdater: T | ((prev: T) => T)) => {
      setStateVal((prev) => {
        const newValue =
          typeof valueOrUpdater === 'function'
            ? (valueOrUpdater as (prev: T) => T)(prev)
            : valueOrUpdater;

        try {
          sendMessage(newValue);
        } catch (error) {
          console.error(error);
        }

        return newValue;
      });
    },
    [sendMessage]
  );

  // Cleanup BroadcastChannel on component unmount
  useEffect(
    () => () => {
      if (channelRef.current) {
        channelRef.current.close();
        channelRef.current = null; // Ensure we don't try to use a closed channel
      }
    },
    []
  );

  return [state, setState];
}
