import { Box, Typography } from 'base-components';

import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import ExampleWidgetConfigContent, {
  type ExampleWidgetConfigType,
} from './Config/ExampleWidgetConfigContent';

export const DEFAULT_WHAT_TO_SAY =
  'This is what I am saying from my default config';

const defaultConfig = {
  sharedConfigValue: DEFAULT_WHAT_TO_SAY,
  widgetConfigValue: DEFAULT_WHAT_TO_SAY,
};

const ExampleWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, initialConfig = {} } = props;
  const { config, setConfig } = useWidgetConfig<ExampleWidgetConfigType>(id, {
    ...defaultConfig,
    ...initialConfig,
  });
  const { sharedConfigValue, widgetConfigValue } = config;

  return (
    <Widget
      key={id}
      title="Example Widget"
      config={config}
      onChangeConfig={setConfig}
      ConfigFieldsComponent={ExampleWidgetConfigContent}
      collapseHeader
      {...props}
    >
      <Box p={2}>
        <Typography variant="h3" gutterBottom>
          This widget sure has alot to say!
        </Typography>
        <Typography variant="h6" gutterBottom>
          My sharedConfigValue config property makes me want to say:
        </Typography>
        <Typography variant="body1">{sharedConfigValue}</Typography>
        <Typography variant="h6" gutterBottom>
          Meanwhile, my widgetConfigValue config property makes me want to say:
        </Typography>
        <Typography variant="body1">{widgetConfigValue}</Typography>
      </Box>
    </Widget>
  );
};

ExampleWidget.widgetKey = 'example-widget';
ExampleWidget.widgetTitle = 'Example Widget';
ExampleWidget.defaultDataGrid = {
  x: 0,
  y: 0,
  w: 9999,
  h: 4,
};

export default ExampleWidget;
