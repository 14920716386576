import { useEffect, useState } from 'react';
import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  StatelessTable,
  TableColumn,
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
  useSnackbars,
} from 'base-components';
import TaskHistoryService from 'domain/AppComponents/batch/service/TaskHistoryService';
import { useLocalStorage } from 'util/hooks/useStorage';

const fileOutputColumns = [
  { title: 'Status', name: 'status' },
  { title: 'Device Id', name: 'deviceId', dataType: 'Number' },
  { title: 'Device Name', name: 'deviceName' },
  { title: 'Processed Date', name: 'processedDate' },
  { title: 'Finished Name', name: 'finishedName' },
  { title: 'Size', name: 'size' },
  { title: 'Original Name', name: 'originalName' },
  { title: 'Original Date', name: 'originalDate' },
  { title: 'Original Location', name: 'originalLocation' },
  { title: 'Error', name: 'error' },
  { title: 'More Info', name: 'moreInfo' },
];

type TaskOutputTableProps = {
  taskId: number;
};

interface TaskOutput {
  [key: string]: string; // Allow any string or number properties
}

interface TaskOutputRow {
  [key: string]: string | React.ReactElement; // Allow any string or number properties
}

const TaskOutputTable: React.VFC<TaskOutputTableProps> = (
  props: TaskOutputTableProps
) => {
  const storageKey = 'task-detail-table';
  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'deviceId', direction: 'asc' }],
  };
  const { taskId: taskIdProp } = props;
  const { onError } = useSnackbars();

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  const [tableRows, setTableRows] = useState<TaskOutputRow[]>([]);
  const [tableHeaders, setTableHeaders] = useState<TableColumn[]>([]);
  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([]);
  const [resizingExtensions, setResizingExtensions] = useState<
    any[] | undefined
  >([]);

  const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState<boolean>(false);
  const [moreInfoData, setMoreInfoData] = useState<string>('{}');

  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<string>('{}');

  const isFileOutput = (taskOutput: TaskOutput) =>
    'size' in taskOutput && 'originalLocation' in taskOutput;

  const moreInfoHeader = [
    {
      title: 'property',
      name: 'property',
    },
    {
      title: 'value',
      name: 'value',
    },
  ];

  const moreInfoClick = (data: string) => {
    setMoreInfoData(data);
    setOpenMoreInfoDialog(true);
  };

  const moreInfoClose = () => {
    setOpenMoreInfoDialog(false);
  };

  const errorClick = (data: string) => {
    setErrorData(data);
    setOpenErrorDialog(true);
  };

  const errorClose = () => {
    setOpenErrorDialog(false);
  };

  const buildMoreInfoRows = () => {
    const jsonObject = JSON.parse(moreInfoData);
    const rows = Object.entries(jsonObject).map(([key, value]) => ({
      property: key,
      value,
    }));
    return rows;
  };

  useEffect(() => {
    const buildRows = (taskOutputList: TaskOutput[]) => {
      const rows = taskOutputList.map(
        (taskOutput: TaskOutput): TaskOutputRow => {
          const { moreInfo, error } = taskOutput;

          const taskOutputRow: TaskOutputRow | null = {
            ...taskOutput,
          };
          if (moreInfo) {
            taskOutputRow.moreInfo = (
              <a onClick={() => moreInfoClick(moreInfo)} href="#">
                more...
              </a>
            );
          }
          if (error) {
            taskOutputRow.error = (
              <a onClick={() => errorClick(error)} href="#">
                error...
              </a>
            );
          }
          return taskOutputRow;
        }
      );
      return rows;
    };

    const refreshTableData = async () => {
      const buildHeaders = (columns: TaskOutput[]) => {
        if (columns.length !== 0 && isFileOutput(columns[0])) {
          return fileOutputColumns;
        }
        const columnHeaders = Array.from(
          new Set(columns.flatMap((column) => Object.keys(column)))
        ).map((header) => ({
          title: header,
          name: header,
        }));
        return columnHeaders;
      };

      const buildResizingExtensions = (columns: TaskOutput[]) => {
        if (columns.length !== 0 && isFileOutput(columns[0])) {
          return fileOutputColumns.map((item) => ({
            columnName: item.name,
            minWidth: 100,
            maxWidth: 700,
          }));
        }
        const columnHeaders = Array.from(
          new Set(columns.flatMap((column) => Object.keys(column)))
        ).map((header) => ({
          columnName: header,
          minWidth: 100,
          maxWidth: 700,
        }));
        return columnHeaders;
      };

      const buildWidths = (columns: TaskOutput[]) => {
        if (columns.length !== 0 && isFileOutput(columns[0])) {
          return fileOutputColumns.map((item) => ({
            columnName: item.name,
            width: 180,
          }));
        }
        const columnHeaders = Array.from(
          new Set(columns.flatMap((column) => Object.keys(column)))
        ).map((header) => ({
          columnName: header,
          width: 180,
        }));
        return columnHeaders;
      };

      await TaskHistoryService.getTaskOutput(taskIdProp)
        .then((result) => {
          if (result.data.payload.records) {
            const data = result.data.payload.records;
            setTableRows(buildRows(data));
            setTableHeaders(buildHeaders(data));
            setResizingExtensions(buildResizingExtensions(data));
            setColumnWidths(buildWidths(data));
          }
        })
        .catch((error) => {
          onError(error);
        });
    };
    refreshTableData();
  }, [taskIdProp, onError]);

  return (
    <>
      <Dialog open={openMoreInfoDialog} onClose={moreInfoClose} fullWidth>
        <DialogTitle>More Information</DialogTitle>
        <DialogContent>
          <StatelessTable rows={buildMoreInfoRows()} columns={moreInfoHeader} />
        </DialogContent>
      </Dialog>
      <Dialog open={openErrorDialog} onClose={errorClose} fullWidth>
        <DialogTitle>Error Message</DialogTitle>
        <DialogContent>
          <pre>{errorData}</pre>
        </DialogContent>
      </Dialog>
      <StatelessTable
        rows={tableRows}
        columns={tableHeaders}
        resize={{
          columnWidths,
          handleColumnWidths: setColumnWidths,
          columnExtensions: resizingExtensions,
        }}
        sort={{
          sorting,
          customSorting: [
            {
              columnName: 'deviceId',
              compare: (a, b) => Number(a) > Number(b),
            },
            {
              columnName: 'deviceName',
              compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
            },
            {
              columnName: 'finishedName',
              compare: (a, b) => {
                const c = a || '';
                const d = b || '';
                return c.toLowerCase().localeCompare(d.toLowerCase());
              },
            },
            {
              columnName: 'originalName',
              compare: (a, b) => {
                const c = a || '';
                const d = b || '';
                return c.toLowerCase().localeCompare(d.toLowerCase());
              },
            },
            {
              columnName: 'originalLocation',
              compare: (a, b) => {
                const c = a || '';
                const d = b || '';
                return c.toLowerCase().localeCompare(d.toLowerCase());
              },
            },
          ],
          handleSortingChange: setSorting,
        }}
        paging={{ pageSize: 15, pageSizes: [15, 30, 60, 300] }}
      />
    </>
  );
};

export default TaskOutputTable;
