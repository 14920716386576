import { DmasUser } from '@onc/domain-services';
import FormField from 'domain/Apps/form-field/FormField';
import DeviceCategories from 'domain/Apps/seatube/util/DeviceCategories';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const ANNOTATION_SERVICE = '/seatubeV3/annotations';
const ANNOTATION_SERVICE_V3 = 'AnnotationServiceV3';
const OPERATION_NUMBER = 1;
const SITE_DEVICE_SUBSET_RESOURCE_TYPE_ID = 2700;
const DMAS_SOURCE_ID = 3;
const GET_ANNOATIONS_FOR_CAST_OPERATION_ID = 7;

type CreateParams = { annotation: string; organizationId?: number };

type DeleteParams = { annotationId: number };

type PublicAnnotationParams = {
  locationCode: string;
  dateFrom?: string;
  dateTo?: string;
  includeIds?: boolean;
};

export const usePublicAnnotation = (params: PublicAnnotationParams) =>
  useGet<PublicAnnotation[], PublicAnnotationParams>(
    `api/annotations/locations/${params.locationCode}`,
    {
      operation: undefined,
      transform: (response) => response.data.payload.payload.annotations,
    },
    params
  );

export const useSeaTubeAnnotations = (params: AnnotationsV3Parameters) =>
  useGet<GetAnnotationsPayload, AnnotationsV3Parameters>(
    ANNOTATION_SERVICE,
    OPERATION_NUMBER,
    params
  );

export const useUserOptions = (params: SearchUsersParameters) =>
  useGet<SearchUsersPayload, SearchUsersParameters>(
    '/seatube/search/user-options',
    1,
    params
  );

export const useCreateAnnotation = (onSuccess, onError) =>
  usePost<CreateParams, SavedAnnotation>(
    ANNOTATION_SERVICE_V3,
    { onSuccess, onError },
    1 // 1 = Create
  );

export const useUpdateAnnotation = (onSuccess, onError) =>
  usePost<CreateParams, SavedAnnotation>(
    ANNOTATION_SERVICE_V3,
    { onSuccess, onError },
    2 // 2 = Update
  );

export const useDeleteAnnotation = (onSuccess, onError) =>
  usePost<DeleteParams, ServiceAnnotationV3>(
    ANNOTATION_SERVICE_V3,
    { onSuccess, onError },
    3 // 3 = Delete
  );

class AnnotationService {
  static getDiveIdAnnotations = async (
    diveIds,
    filter,
    sortAnnotationsByMostRecent,
    onError
  ) => {
    try {
      const payload = await get(
        ANNOTATION_SERVICE,
        {
          diveIds,
          operation: OPERATION_NUMBER,
          filter,
          sortAnnotationsByMostRecent,
        },
        undefined
      ).then((response) => parseDmasAPIResponse(response));

      return payload;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }

    return undefined;
  };

  static getAnnotations = async (
    parameters: AnnotationsV3Parameters
  ): Promise<GetAnnotationsPayload> => {
    const params: any = { ...parameters };
    if (params.cruiseIds) {
      params.cruiseIds = params.cruiseIds.join(',');
    }
    if (params.diveIds) {
      params.diveIds = params.diveIds.join(',');
    }
    const payload = await get(ANNOTATION_SERVICE, {
      operation: OPERATION_NUMBER,
      filter: '{}',
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
    return payload;
  };

  static getSearchTreeNodeAnnotationsWithId = async (
    searchTreeNodeIds,
    filter,
    sortAnnotationsByMostRecent,
    onError
  ) => {
    try {
      const payload = await get(
        ANNOTATION_SERVICE,
        {
          searchTreeNodeIds,
          operation: OPERATION_NUMBER,
          filter,
          sortAnnotationsByMostRecent,
          deviceCategoryIds: DeviceCategories.FIXED_CAMERA,
        },
        undefined
      ).then((response) => parseDmasAPIResponse(response));

      return payload;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }

    return undefined;
  };

  static getSearchTreeNodeAnnotationsWithFilter = async (
    filter,
    sortAnnotationsByMostRecent,
    onError
  ) => {
    try {
      const payload = await get(
        ANNOTATION_SERVICE,
        {
          operation: OPERATION_NUMBER,
          filter,
          sortAnnotationsByMostRecent,
          deviceCategoryIds: DeviceCategories.FIXED_CAMERA,
        },
        undefined
      ).then((response) => parseDmasAPIResponse(response));

      return payload;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }

    return undefined;
  };

  static deleteAnnotationForSeaTube = async (
    resourceTypeId: number,
    resourceId: number,
    annotationHdrId: number
  ) =>
    post(
      ANNOTATION_SERVICE,
      { operation: 3, resourceTypeId, resourceId, annotationHdrId },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getAnnotationsForCast = async (
    siteDeviceSubsetId,
    dateFrom,
    dateTo,
    onError
  ) => {
    try {
      const payload = await get(
        ANNOTATION_SERVICE_V3,
        {
          operation: GET_ANNOATIONS_FOR_CAST_OPERATION_ID,
          resourceTypeId: SITE_DEVICE_SUBSET_RESOURCE_TYPE_ID,
          annotationSources: DMAS_SOURCE_ID,
          resourceId: siteDeviceSubsetId,
          fromDate: dateFrom,
          toDate: dateTo,
        },
        undefined
      ).then((response) => parseDmasAPIResponse(response));

      return payload;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
    return undefined;
  };

  static getAnnotationsFromFilter = async (filter, onError) =>
    get(ANNOTATION_SERVICE_V3, filter, {})
      .then((response) => parseDmasAPIResponse(response))
      .catch((responseError) => {
        if (onError) {
          onError(`Failed to retrieve annotations: ${responseError}`);
        }
      });

  static saveAnnotation = (
    annotation: ServiceAnnotation,
    organizationId?: number
  ) => {
    if (annotation.annotationId) {
      return AnnotationService.updateAnnotation(annotation, organizationId);
    }
    return AnnotationService.createAnnotation(annotation, organizationId);
  };

  static createAnnotation = (
    annotation: ServiceAnnotation,
    organizationId?: number
  ) =>
    post(
      ANNOTATION_SERVICE_V3,
      { operation: 1, annotation: JSON.stringify(annotation), organizationId },
      {}
    );

  static updateAnnotation = (
    annotation: ServiceAnnotation,
    organizationId?: number
  ) =>
    post(
      ANNOTATION_SERVICE_V3,
      { operation: 2, annotation: JSON.stringify(annotation), organizationId },
      {}
    );

  static deleteAnnotation = (annotationId: number) =>
    post(ANNOTATION_SERVICE_V3, { operation: 3, annotationId }, {});

  static getAnnotation = (annotationId: number, onError: any) =>
    get(ANNOTATION_SERVICE_V3, { annotationId }, {})
      .then((response) => parseDmasAPIResponse(response))
      .catch((responseError) => {
        if (onError) {
          onError(
            `Failed to retrieve annotation ${annotationId}: ${responseError}`
          );
        }
      });
}

// Used for /seatubeV3/annotations
export type AnnotationsV3Parameters = {
  cruiseIds?: number[];
  dateFrom?: string;
  dateTo?: string;
  deviceCategoryIds?: number | number[];
  diveIds?: number | number[];
  filter?: string; // Probably a JSON string
  fitlerText?: string;
  includeSensorData?: boolean;
  maxAnnotations?: number;
  searchTreeNodeIds?: number | number[];
  sortAnnotationsByMostRecent?: boolean;
};

export type GetAnnotationsPayload = {
  annotations: ServiceAnnotationV3[];
  total: number;
  unfilteredTotal: number;
};

export interface ServiceAnnotationV3 {
  annotationId: number;
  annotationSource: string;
  comment: string;
  createdBy: {
    email: string;
    firstName: string;
    lastName: string;
    userId: number;
  };
  createdDate: string;
  depth: number;
  deviceId: number;
  diveId: number;
  endDate: string;
  heading: number;
  lat: number;
  lon: number;
  modifiedBy: {
    email: string;
    firstName: string;
    lastName: string;
    userId: number;
  };
  modifiedDate: string;
  numPositiveReviews: number;
  numTotalReviews: number;
  resourceId: number;
  resourceTypeId: number;
  resourceTypeName: string;
  startDate: string;
  toBeReviewed: boolean;
  videoResourceId: number;
  videoResourceTypeId: number;
  taxonPaths?: string[][];
  taxons?: {
    associatedAnnotationLineId: number;
    attributes: {
      attributeId: number;
      dataType: string;
      groupId: number;
      groupName: string;
      name: string;
      value: string;
    }[];
    displayText: string;
    taxonId: number;
    taxonUrl: string;
    taxonomyCode: string;
    taxonomyId: number;
    annotationReview?: {
      annotationHdrId: number;
      annotationReviewId: number;
      reviewValue: string;
      modifyBy: number;
      modifyDate: string;
      taxonId: number;
    };
  }[];
}

// equivalent of AnnotationWithNaive from the backend
export type SavedAnnotation = {
  annotationContents: AnnotationLine[];
  annotationId: number;
  annotationSource: AnnotationSource;
  createdBy: DmasUser;
  createdDate: string;
  endDate: string;
  flagged: boolean;
  levelIndex: number;
  modifiedBy: DmasUser;
  modifiedDate: string;
  naiveAnnotation: NaiveAnnotation;
  permissionToEdit: boolean;
  published: boolean;
  resource: Resource;
  resourceType: ResourceType;
  startDate: string;
};

// I had to guess at a couple of these so change if needed.
export interface ServiceAnnotation {
  annotationId?: number | null;
  resourceType: { resourceTypeId: number; resourceTypeName: string };
  resource: { resourceId: number; resourceName: string | null };
  startDate: string;
  endDate: string | null;
  annotationContents: {
    annotation: string;
    formField: { formFieldId: string };
    annotationAttributes: object[] | null;
    taxonId: number;
    taxonomyId: number;
  }[];
  flagged: boolean;
  annotationSource: AnnotationSource;
  published: boolean;
  levelIndex: number;
  dfCampaignId?: number;
  permissionToEdit?: boolean;
}

export type PublicAnnotation = {
  annotationId: number;
  deviceCode: string;
  dateFrom: string;
  dateTo: string;
  createdDate: string;
  createdBy: User;
  modifiedDate: string;
  modifiedBy: User;
  isPublic: boolean;
  toBeReviewed: boolean;
  comment: string;
  taxons?: Taxon[] | null;
  numPositiveReviews?: number | null;
  numTotalReviews?: number | null;
};

type Taxon = {
  taxonomy: string;
  taxonomyCode: string;
  taxon: string;
  taxonUrl?: string;
  taxonId?: number;
  taxonomyId?: number;
  attributes: Attribute[];
  displayText?: string;
  commonNames?: string[];
  referenceId?: string;
};

type Attribute = {
  name: string;
  value: string;
  group: string;
  groupId?: number;
  attributeId?: number;
  dataType: string;
};

type User = {
  userCode: string;
  firstName: string;
  lastName: string;
  userId?: number; // Temporary Until user codes implemented
};

type SearchUsersParameters = { searchTreeNodeIds: number | number[] };

export type UserOption = { label: string; value: number };
type SearchUsersPayload = { creators: UserOption[]; modifiers: UserOption[] };

type ResourceType = {
  resourceTypeId: number;
  resourceTypeName: string;
  modifyBy: number;
  modifyDate: string;
};

type Resource = {
  resourceId: number;
  resourceName: string;
};

type AnnotationLine = {
  annotation: string;
  annotationAttributes: AnnotationAttribute[];
  annotationContentId: number;
  annotationHdrId: number;
  formField: FormField;
  taxonId?: number;
  taxonomyId?: number;
};

type AnnotationAttribute = {
  annotationLineAttributeId: number;
  annotationLineId: number;
  attributeId: number;
  name: string;
  value: string;
};

type AnnotationSource = {
  annotationSource: string;
  annotationSourceId: number;
};

type NaiveAnnotation = {
  annotationId: number;
  annotationSource: string;
  annotationSummary: string;
  contextualLink: string;
  createdBy: number;
  endDate?: string;
  flagged: boolean;
  modifiedBy: number;
  modifiedDate: string;
  published: boolean;
  resourceId: number;
  resourceName: string;
  resourceTypeId: number;
  resourceTypeName: string;
  startDate: string;
};

export default AnnotationService;
