import { Component } from 'react';
import { MenuItem } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { OutlinedButton } from '@onc/composite-components';
import { Add } from '@onc/icons';
import { Menu } from 'base-components';
import AnnotationFilters from './new-filters/AnnotationFilters';

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      width: '100%',
    },
    menu: {
      width: '100%',
    },
  });

type Props = {
  classes: any;
  filters: any;
  onFilterSelect: (filter: object) => void;
};
type State = {
  showMenu: boolean;
  anchorEl: any;
};

class AddAnnotationFilterButton extends Component<Props, State> {
  state = {
    showMenu: false,
    anchorEl: undefined,
  };

  getRemainingFilters = () => {
    const { filters } = this.props;
    const remainingFilters: any[] = [];
    AnnotationFilters.OPTIONS.forEach((option) => {
      if (!filters.includes(option)) {
        remainingFilters.push(option);
      }
    });
    return remainingFilters;
  };

  handleOpenMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      showMenu: true,
    });
  };

  handleFilterSelect = (option: object) => {
    const { onFilterSelect } = this.props;
    this.setState({ showMenu: false });
    onFilterSelect(option);
  };

  renderMenuItems = () =>
    this.getRemainingFilters().map((option) => (
      <MenuItem
        key={option.name}
        onClick={() => this.handleFilterSelect(option)}
      >
        {option.label}
      </MenuItem>
    ));

  render() {
    const { classes } = this.props;
    const { showMenu, anchorEl } = this.state;
    return (
      <div className={classes.container}>
        <OutlinedButton
          translationKey="common.buttons.addFilter"
          startIcon={<Add />}
          onClick={this.handleOpenMenu}
          fullWidth
          disabled={this.getRemainingFilters().length === 0}
          className={classes.button}
          aria-describedby="add-filter"
        />
        <Menu
          open={showMenu}
          id="add-filter"
          anchorEl={anchorEl}
          className={classes.menu}
          onClose={() => this.setState({ showMenu: false })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {this.renderMenuItems()}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(AddAnnotationFilterButton);
