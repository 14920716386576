import { useEffect, useState } from 'react';
import { ConnectorTypeService, ConnectorType } from '@onc/domain-services';
import { useWebService } from '@onc/service';
import { Dialog, useSnackbars } from 'base-components';
import EditableDomainForm from 'library/CompositeComponents/editable-domain/EditableDomainForm';

type ConnectorTypeDialogProps = {
  initialValue: Partial<ConnectorType>;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const EMPTY_FORM: ConnectorType = {
  name: '',
  description: '',
};

const ConnectorTypeDialog = ({
  initialValue,
  open,
  onClose,
  onSuccess,
}: ConnectorTypeDialogProps) => {
  const [connectorTypeForm, setConnectorTypeForm] =
    useState<ConnectorType>(EMPTY_FORM);
  const { onInfo, onError } = useSnackbars();

  const formFields = {
    connectorTypeName: String,
    description: String,
  };

  const columns = [
    {
      name: 'connectorTypeName',
      label: 'Connector Type Name',
      fullWidth: true,
      rules: { required: 'Connector Type Name Required' },
    },
    {
      name: 'description',
      label: 'Description',
      fullWidth: true,
    },
  ];

  // When true, create a new ConnectorType
  // when false, edit an existing ConnectorType
  const [isCreate, setIsCreate] = useState(false);

  const [, , createConnectorType] = useWebService({
    method: ConnectorTypeService.createConnectorType,
  });

  const [, , updateConnectorType] = useWebService({
    method: ConnectorTypeService.updateConnectorType,
  });

  useEffect(() => {
    if (!initialValue) {
      setIsCreate(true);
      setConnectorTypeForm(EMPTY_FORM);
      return;
    }
    setIsCreate(false);
    setConnectorTypeForm({
      connectorTypeId: initialValue.connectorTypeId,
      name: initialValue.name,
      description: initialValue.description,
    });
  }, [initialValue]);

  const saveConnectorType = (response, connectorTypeName) => {
    if (response.name === connectorTypeName) {
      onInfo('Connector Type saved successfully!');
      onClose();
      onSuccess();
    } else {
      onError('Connector Type failed to save');
    }
  };

  const handleSubmit = ({ connectorTypeName, description }) => {
    if (isCreate) {
      return createConnectorType({
        name: connectorTypeName,
        description,
      })
        .then((response) => {
          saveConnectorType(response, connectorTypeName);
        })
        .catch((err) => {
          onError(err);
        });
    }
    return updateConnectorType({
      connectorTypeId: connectorTypeForm.connectorTypeId,
      name: connectorTypeName,
      description,
    })
      .then((response) => {
        saveConnectorType(response, connectorTypeName);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const connectorTypeName = connectorTypeForm.name;
  const { description } = connectorTypeForm;

  const values = {
    values: { connectorTypeName, description },
  };

  return (
    <Dialog open={open}>
      <EditableDomainForm
        isCreate={isCreate}
        onClose={onClose}
        onSubmit={handleSubmit}
        formFields={formFields}
        formName="Connector Type"
        columns={columns}
        values={values}
      />
    </Dialog>
  );
};

export default ConnectorTypeDialog;
