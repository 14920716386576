import { useContext, useState } from 'react';
import { StatelessTable, Button } from 'base-components';
import EarthquakeDetailLink from 'domain/AppComponents/earthquake-catalog/EarthquakeDetailLink';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import DateFormatUtils from 'util/DateFormatUtils';
import useBroadcast from 'util/hooks/useBroadcast';
import { useLocalStorage } from 'util/hooks/useStorage';
import EarthquakeContext from './EarthquakeContext';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../library/CompositeComponents/dashboard/DashboardTypes';

const headers = [
  { title: 'ID', name: 'earthquakeId' },
  { title: 'Origin Time', name: 'originTime' },
  { title: 'Epicentre', name: 'epicentre' },
  { title: 'Magnitude', name: 'magnitude' },
  { title: 'ONC Detected', name: 'oncDetected' },
  { title: 'NRcan Detected', name: 'nrcanDetected' },
  { title: 'USGS Detected', name: 'usgsDetected' },
  { title: 'Distance to edge', name: 'distanceToDetArea' },
  { title: 'Magnitude Error', name: 'oncUsgsMagDiff' },
  { title: 'Epicentre Error', name: 'oncUsgsEpiDiff' },
  { title: 'Origin Time Error', name: 'oncUsgsOTDiff' },
];

const EarthquakeTableWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { earthquakes, dashboardId } = useContext(EarthquakeContext);

  const [earthquakeList] = useBroadcast<any>(
    dashboardId,
    BroadcastChannel.EarthquakeList,
    earthquakes,
    id
  );

  const storageKey = 'earthquake-catalog-table';

  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'id', direction: 'asc' }],
    hiddenColumnNames: [],
  };
  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  const renderTableRows = () =>
    earthquakeList.map((row) => ({
      id: row.earthquakeId,
      earthquakeId: (
        <EarthquakeDetailLink earthquakeId={row.earthquakeId}>
          {row.earthquakeId}
        </EarthquakeDetailLink>
      ),
      originTime: DateFormatUtils.formatDate(row.originTime, 'full'),
      epicentre: `${row.latitude},${row.longitude}`,
      magnitude: row.magnitude,
      oncDetected: row.onc ? 'True' : 'False',
      nrcanDetected: row.nrcan ? 'True' : 'False',
      usgsDetected: row.usgs ? 'True' : 'False',
      oncUsgsMagDiff: row.onc ? row.onc.magError : 0,
      oncUsgsEpiDiff: row.onc ? row.onc.epiError : 0,
      oncUsgsOTDiff: row.onc ? row.onc.otError : 0,
      distanceToDetArea: row.distanceToDetectionEdge,
    }));

  const exportToCSV = () => {
    const rows = renderTableRows();

    const csvHeader = headers.map((h) => h.title).join(',');

    // Generate CSV data rows (ensure `id` is a number and no unnecessary quotes)
    const csvRows = rows.map((row) =>
      headers
        .map((h) => {
          const value = row[h.name];

          // Ensure `id` is just a number
          if (h.name === 'earthquakeId') {
            return row.id;
          }

          // Convert undefined/null to empty string
          if (value === null || value === undefined) {
            return '';
          }

          return value;
        })
        .join(',')
    );

    // Join all rows into a CSV string
    const csvContent = [csvHeader, ...csvRows].join('\n');
    // Create Blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'earthquakes.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Widget
      key={id}
      title="Earthquake Table"
      {...props}
      actionComponents={[
        <Button
          onClick={exportToCSV}
          translationKey="seatube.export"
          variant="contained"
          aria-label="Export"
        />,
      ]}
    >
      <StatelessTable
        rows={renderTableRows()}
        columns={headers}
        virtual={{ virtualized: true }}
        sort={{
          sorting,
          handleSortingChange: setSorting,
        }}
        visible={{ hiddenColumnNames: [] }}
      />
    </Widget>
  );
};

EarthquakeTableWidget.widgetKey = 'earthquake-table';
EarthquakeTableWidget.widgetTitle = 'Earthquake Table';
EarthquakeTableWidget.defaultDataGrid = {
  i: 'earthquake-table',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EarthquakeTableWidget;
