import { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import FixedCameraPlaybackDashboard from 'domain/AppComponents/sea-tube/fixed-camera-playback/FixedCameraPlaybackDashboard';
import QueryParameterContext from 'domain/Apps/menu/QueryParameterContext';
import { PipProvider } from 'util/hooks/usePipWindow';

interface FixedCameraPlaybackParams {
  searchTreeNodeId: number;
}

type Props = {
  match: {
    isExact: boolean;
    params: FixedCameraPlaybackParams;
    path: string;
    url: string;
  };
};
const FixedCameraPlaybackApp = (props: Props) => {
  const { match } = props;

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const params = useMemo(() => match.params, [match.params]);

  const [primaryWindowId, setPrimaryWindowId] = useState<string>(
    queryParams.primaryWindowId as string
  );

  useEffect(() => {
    if (!primaryWindowId) {
      setPrimaryWindowId(crypto.getRandomValues(new Uint32Array(1)).toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!primaryWindowId) return null;

  return (
    <QueryParameterContext.Provider value={params}>
      <PipProvider>
        <FixedCameraPlaybackDashboard primaryWindowId={primaryWindowId} />
      </PipProvider>
    </QueryParameterContext.Provider>
  );
};

export default FixedCameraPlaybackApp;
