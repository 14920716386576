/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Loading } from '@onc/composite-components';
import DeviceGeneralTabService from 'domain/services/DeviceGeneralTabService';

import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import GeneralTabPanel from './GeneralTabPanel';

type Props = {
  deviceId: string;
  privilege: string;
};

const GeneralTabPage = ({ deviceId, privilege }: Props) => {
  const { onInfo, onError } = useSnackbars();

  const [device, loading, fetchDevice] = useWebService({
    method: DeviceGeneralTabService.getDevice,
    onError,
  });

  useEffect(() => {
    fetchDevice(Number(deviceId));
  }, []);

  if (loading || !device) return <Loading />;

  return (
    <GeneralTabPanel
      deviceId={deviceId}
      device={device}
      permission={Environment.getDmasUserPrivilege()}
      clonePrivilege={privilege}
      fetchDevice={fetchDevice}
      onInfo={onInfo}
      onError={onError}
    />
  );
};

export default GeneralTabPage;
