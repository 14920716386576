import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CancelButton } from '@onc/composite-components';
import { Warning } from '@onc/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
} from 'base-components';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: '#f0bb2b',
  },
  content: {
    whiteSpace: 'pre-line',
  },
}));

type Props = {
  open: boolean;
  title: string;
  message: string;
  isSaving: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

const SaveWarningDialog = ({
  open,
  title,
  message,
  isSaving,
  onCancel,
  onSubmit,
}: Props) => {
  const handleSubmit = () => {
    onSubmit();
  };
  const classes = useStyles();

  return (
    <Dialog maxWidth="xs" open={open} onClose={onCancel}>
      <DialogTitle>
        <div className={classes.headerContainer}>
          <Warning className={classes.icon} />
          {title}
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>{message}</DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel} />
        <LoadingButton
          loading={isSaving}
          variant="contained"
          translationKey="common.buttons.save"
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SaveWarningDialog;
