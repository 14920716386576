import { useState } from 'react';
import _ from 'lodash';
import {
  getEmptyFilter,
  StatelessTable,
  TableColumn,
  TableFilter,
  TableFilterLogic,
  DevExpressTableColumnWidthInfo as TableColumnWidthInfo,
} from 'base-components';
import DeploymentsActionFormatter from 'domain/AppComponents/synchronizer-management/formatters/DeploymentsActionFormatter';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import { useLocalStorage } from 'util/hooks/useStorage';
import { ConnectorTypeRow } from './ConnectorTypeTableLogic';

type ConnectorTypeTableProps = {
  rows: ConnectorTypeRow[];
  permission: string;
  onEdit: (row: ConnectorTypeRow) => void;
  onCreate: () => void;
};
const ConnectorTypeTable: React.FC<ConnectorTypeTableProps> = ({
  rows,
  permission,
  onEdit,
  onCreate,
}: ConnectorTypeTableProps) => {
  const storageKey = 'connector-type-table';
  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'connectorTypeName', direction: 'asc' }],
  };

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  // Filtering
  const initialFilter = config.filter;
  const [filter, setFilter] = useState<TableFilter>(
    initialFilter ? _.cloneDeep(config.filter) : _.cloneDeep(getEmptyFilter())
  );

  const columns: TableColumn[] = [
    {
      title: 'Connector Type Id',
      name: 'connectorTypeId',
      dataType: 'Number',
    },
    {
      title: 'Connector Type Name',
      name: 'connectorTypeName',
      dataType: 'String',
    },
    { title: 'Description', name: 'description', dataType: 'String' },
    { title: 'Modified By', name: 'modifyBy', dataType: 'String' },
    { title: 'Modified Date', name: 'modifyDate', dataType: 'String' },
    { title: 'Actions', name: 'actions', dataType: 'Other' },
  ];

  const columnWidths: TableColumnWidthInfo[] = [
    {
      columnName: 'connectorTypeId',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'connectorTypeName',
      width: ColumnInfo.large,
    },
    {
      columnName: 'description',
      width: ColumnInfo.large,
    },
    {
      columnName: 'modifyBy',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'modifyDate',
      width: ColumnInfo.medium,
    },
    {
      columnName: 'actions',
      width: ColumnInfo.small,
    },
  ];

  const getDisabledColumnNames = () => {
    if (permission !== 'RW') {
      return ['actions'];
    }
    return [];
  };

  return (
    <StatelessTable
      rows={rows ?? []}
      columns={columns}
      getRowId={(row) => row.connectorTypeId}
      permission={permission}
      searchable
      onCreate={onCreate}
      visible={{
        hiddenColumnNames: getDisabledColumnNames(),
        disableColumnNames: getDisabledColumnNames(),
      }}
      resize={{
        columnWidths,
      }}
      sort={{
        sorting,
        customSorting: [
          {
            columnName: 'connectorTypeName',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            columnName: 'description',
            compare: (a, b) => {
              const c = a || '';
              const d = b || '';
              return c.toLowerCase().localeCompare(d.toLowerCase());
            },
          },
        ],
        handleSortingChange: setSorting,
      }}
      columnFormatProviders={[
        {
          name: 'ActionFormatter',
          for: ['actions'],
          formatterComponent: ({ row }) =>
            DeploymentsActionFormatter({ row, onEdit }),
        },
      ]}
      paging={{ pageSize: 15, pageSizes: [15, 30, 60, 300] }}
      fixed={{
        leftColumns: [],
        rightColumns: ['actions'],
      }}
      filter={{
        filterValue: filter,
        onChange: setFilter,
        filterFn: TableFilterLogic.defaultFilter,
        hidden: false,
      }}
    />
  );
};

export default ConnectorTypeTable;
