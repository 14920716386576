/* eslint-disable react/prop-types */
import { createStyles, makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import { TextEditor } from 'base-components';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';

export type TextWidgetFormType = {
  showTitle: boolean;
  htmlValue?: string;
  title?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    toolbarClassName: {
      background: 'rgba(232,232,232,1)',
    },
    textEditorDiv: {
      minHeight: 250,
      border: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(0,0,0,0.09)',
      borderBlockEndColor: 'rgba(118,118,118,1)',
    },
    textEditorClassName: {
      maxHeight: 460,
      minHeight: 250,
      overflow: 'auto',
    },
  })
);

const TextWidgetConfigContent: ConfigFields<TextWidgetFormType> = ({
  formMethods,
}) => {
  const classes = useStyles();

  const { control } = formMethods;

  return (
    <>
      <FormTextField name="title" translationKey="common.textfields.title" />
      <FormToggle name="showTitle" label="Show Title" />
      <div className={classes.textEditorDiv}>
        <Controller
          control={control}
          name="htmlValue"
          render={({ field }) => (
            <TextEditor
              title="htmlValue"
              name="htmlValue"
              htmlValue={field.value}
              setFieldValue={(_, newValue) => field.onChange(newValue)}
              toolbarClassName={classes.toolbarClassName}
              textEditorClassName={classes.textEditorClassName}
            />
          )}
        />
      </div>
    </>
  );
};

export default TextWidgetConfigContent;
