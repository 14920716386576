import Environment from '@onc/environment';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

export type SeaTubePermissionObj = {
  annotationPrivilege: boolean;
  deleteAnnotationPrivilege: boolean;
  selfDeleteAnnotationPrivilege: boolean;
  selfDeletePublicAnnotations: boolean;
  reviewPrivilege: boolean;
};

export type SeaTubeAnnotationPermissions = {
  canCreate: boolean;
  canPublish: boolean;
  canReview: boolean;
  canEdit: (annotation: ListAnnotation) => boolean;
  canDelete: (annotation: ListAnnotation) => boolean;
};

const defaultPermissions: SeaTubeAnnotationPermissions = {
  canCreate: false,
  canPublish: false,
  canReview: false,
  canEdit: () => false,
  canDelete: () => false,
};

export type SeaTubePermissionJSON = {
  name: string;
  value: boolean;
}[];

export const useFixedCameraPermissions = () =>
  useGet<SeaTubePermissionJSON>('/seatube/permissions', 3);

const getPermissionValue = (
  response: SeaTubePermissionJSON,
  permissionName: string
): boolean => {
  const entry = response.find((item) => item.name === permissionName);
  return entry ? entry.value : false;
};

class SeaTubePermissionsService {
  static getUserPermissions = async (props: {
    diveId?: number;
    cruiseId?: number;
  }) => {
    const payload = await get(
      'seatube/permissions',
      { operation: 1, ...props },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

    return payload;
  };

  static getOrganizationPermissions = async () =>
    get('seatube/permissions', {
      operation: 2,
    }).then((response) => parseDmasAPIResponse(response));

  static buildDiveLogPermissions = (response: SeaTubePermissionJSON) => {
    const permissions: SeaTubeAnnotationPermissions = { ...defaultPermissions };

    // Set specific permissions
    permissions.canCreate = getPermissionValue(response, 'AnnotationPrivilege');
    permissions.canReview = getPermissionValue(response, 'reviewPrivilege');

    // If a user can create annotations, they can also edit them
    permissions.canEdit = () => permissions.canCreate;

    // Delete permissions have mutliple checks
    const fullDelete = getPermissionValue(
      response,
      'deleteAnnotationPrivilege'
    );
    const selfDelete = getPermissionValue(
      response,
      'selfDeleteAnnotationPrivilege'
    );

    permissions.canDelete = (ann) =>
      fullDelete ||
      (selfDelete &&
        Number(ann?.createdBy?.userCode) ===
          Number(Environment.getDmasUserId()));
    getPermissionValue(response, 'deleteAnnotationPrivilege');

    return permissions;
  };
}

export default SeaTubePermissionsService;
