import { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Delete } from '@onc/icons';
import { Divider, ExpandableSearchBar } from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import SeaTubeLogContext from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import SeaTubePermissionContext from 'domain/AppComponents/sea-tube/SeaTubePermissionContext';
import VirtualChatLogList from 'domain/AppComponents/seatube/chat-log/VirtualChatLogList';
import SeaTubeChatLogService, {
  ChatLogMessage,
  useChatLog,
} from 'domain/services/SeaTubeChatLogService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItem,
  WidgetMenuItemCheckbox,
} from 'library/CompositeComponents/dashboard/Widget';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useLocalStorage } from 'util/hooks/useStorage';
import BroadcastChannel from './BroadcastChannel';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
    overflow: 'hidden',
  },
}));

const ChatLogWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;
  const classes = useStyles();

  const { dive } = useContext(SeaTubeLogContext);
  const { canDelete } = useContext(SeaTubePermissionContext);
  const { onInfo, onError } = useSnackbars();

  // User must have full delete permissions to delete chat log messages
  // canDelete will return true even if annotation is undefined
  const canDeleteChatLog = canDelete(undefined);

  const [messagesToDelete, setMessagesToDelete] = useState<ChatLogMessage[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [matchIndices, setMatchIndices] = useState([]);

  const [selectedChatMessageId, setSelectedChatMessageId] =
    useState<number>(null);

  const [currentTimestamp, setCurrentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    null,
    id
  );
  const [, setSeekToTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.SeekToTimestamp,
    undefined,
    id
  );

  // TODO: Manage changing the config with setConfig
  const [config, setConfig] = useLocalStorage(`chat-log-config`, {
    displaySystemMessages: true,
    displayNavMessages: true,
    displayDeleteIcon: false,
    coloredUsernames: true,
    fullTimestamp: false,
  });

  const {
    data: messages,
    invalidateQuery: fetchChatLog,
    isFetching,
    isLoading,
  } = useChatLog({
    diveId: dive.diveId,
  });

  const selectClosestAnnotation = () => {
    if (currentTimestamp && messages) {
      const timestamp = moment.utc(currentTimestamp);
      const filteredMessages = messages.filter((msg) =>
        moment.utc(msg.msgDate).isSameOrBefore(timestamp, 'second')
      ); // Filter messages before the current timestamp

      if (filteredMessages.length > 0) {
        const closestMessage = filteredMessages.reduce((prev, curr) =>
          Math.abs(moment.utc(curr.msgDate).diff(timestamp)) <
          Math.abs(moment.utc(prev.msgDate).diff(timestamp))
            ? curr
            : prev
        );
        setSelectedChatMessageId(closestMessage.chatLogMsgId);
      } else {
        setSelectedChatMessageId(null);
      }
    }
  };

  useEffect(() => {
    fetchChatLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(selectClosestAnnotation, [currentTimestamp, messages]);

  const handleDeleteChatMessage = () => {
    if (messagesToDelete.length > 1) {
      SeaTubeChatLogService.deleteAllChatLogMessagesOfDive(dive.diveId)
        .then(() => {
          fetchChatLog();
          setMessagesToDelete([]);
          onInfo('All messages deleted successfully.');
        })
        .catch((error) => onError(error.message));
    } else {
      SeaTubeChatLogService.deleteChatLogMessage(
        messagesToDelete[0].chatLogMsgId
      )
        .then(() => {
          fetchChatLog();
          setMessagesToDelete([]);
          onInfo('Message deleted successfully.');
        })
        .catch((error) => onError(error.message));
    }
  };

  const handleMessageDelete = useCallback((chatMessages: ChatLogMessage[]) => {
    setMessagesToDelete(chatMessages);
  }, []);

  const handleMessageClick = useCallback(
    (message: ChatLogMessage) => {
      setSeekToTimestamp(message.msgDate);
      setCurrentTimestamp(message.msgDate);
      setSelectedChatMessageId(message.chatLogMsgId);
    },
    [setCurrentTimestamp, setSeekToTimestamp]
  );

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    setCurrentMatchIndex(0); // Reset to first match
  };

  const handleNextMatch = () => {
    if (matchIndices.length > 0) {
      setCurrentMatchIndex(
        (prevIndex) => (prevIndex + 1) % matchIndices.length
      );
    }
  };

  const handlePreviousMatch = () => {
    if (matchIndices.length > 0) {
      setCurrentMatchIndex(
        (prevIndex) =>
          (prevIndex - 1 + matchIndices.length) % matchIndices.length
      );
    }
  };

  const FullTimestampCheckbox = (
    <WidgetMenuItemCheckbox
      key="timestamp-checkbox"
      onClick={() => {
        setConfig({
          ...config,
          fullTimestamp: !config.fullTimestamp,
        });
      }}
      label="Full Timestamps"
      checked={config.fullTimestamp}
    />
  );
  const ColouredUsernamesCheckbox = (
    <WidgetMenuItemCheckbox
      key="usernames-checkbox"
      onClick={() => {
        setConfig({
          ...config,
          coloredUsernames: !config.coloredUsernames,
        });
      }}
      label="Coloured Usernames"
      checked={config.coloredUsernames}
    />
  );

  const ShowNavigationCheckbox = (
    <WidgetMenuItemCheckbox
      key="nav-messages-checkbox"
      onClick={() => {
        setConfig({
          ...config,
          displayNavMessages: !config.displayNavMessages,
        });
      }}
      label="Show Navigation Messages"
      checked={config.displayNavMessages}
    />
  );

  const ShowSystemMessagesCheckbox = (
    <WidgetMenuItemCheckbox
      key="system-messages-checkbox"
      onClick={() => {
        setConfig({
          ...config,
          displaySystemMessages: !config.displaySystemMessages,
        });
      }}
      label="Show System Messages"
      checked={config.displaySystemMessages}
    />
  );

  const DisplayDeleteIconCheckbox = (
    <WidgetMenuItemCheckbox
      key="delete-icon-checkbox"
      onClick={() => {
        setConfig({
          ...config,
          displayDeleteIcon: !config.displayDeleteIcon,
        });
      }}
      label="Display Delete Icon"
      checked={config.displayDeleteIcon}
    />
  );

  const DeleteAllMessagesOption = WidgetMenuItem(
    'delete-all-messages',
    () => handleMessageDelete(messages),
    'Delete All',
    Delete,
    false
  );

  const SearchBar = (
    <ExpandableSearchBar
      onValueChange={handleSearchChange}
      onNextMatch={handleNextMatch}
      onPreviousMatch={handlePreviousMatch}
      totalMatches={matchIndices.length}
      searchValue={searchQuery}
      currentMatchIndex={currentMatchIndex}
    />
  );

  const chatLogMenuItems = [
    FullTimestampCheckbox,
    ColouredUsernamesCheckbox,
    ShowNavigationCheckbox,
    ShowSystemMessagesCheckbox,
  ];

  if (canDeleteChatLog) {
    const allDeleteOptions = [
      <Divider />,
      DisplayDeleteIconCheckbox,
      <Divider />,
      DeleteAllMessagesOption,
      <Divider />,
    ];
    chatLogMenuItems.push(...allDeleteOptions);
  }

  return (
    <Widget
      title="Chat Log"
      key={id}
      dashboardId={dashboardId}
      MenuItems={chatLogMenuItems}
      loading={isFetching}
      actionComponents={[SearchBar]}
      {...props}
    >
      <DeleteDialog
        open={!!messagesToDelete.length}
        title={
          messagesToDelete.length === 1 ? 'Delete Message?' : 'Delete Messages?'
        }
        onDelete={handleDeleteChatMessage}
        onCancel={() => {
          setMessagesToDelete([]);
        }}
        message={`Are you sure you want to delete ${messagesToDelete.length > 1 ? 'these messages' : 'this message'}?`}
      />

      <div className={classes.content}>
        <Divider />
        <VirtualChatLogList
          config={config}
          deletePermissions={canDeleteChatLog}
          loading={isLoading}
          messages={messages}
          selectedChatMessageId={selectedChatMessageId}
          searchQuery={searchQuery}
          currentMatchIndex={currentMatchIndex}
          onMatchIndicesUpdate={setMatchIndices}
          onChatDelete={handleMessageDelete}
          onMessageClick={handleMessageClick}
        />
      </div>
    </Widget>
  );
};

ChatLogWidget.widgetKey = 'chat-log';
ChatLogWidget.widgetTitle = 'Chat Log';
ChatLogWidget.defaultDataGrid = {
  i: 'chat-log',
  x: 0,
  y: 6,
  w: 4,
  h: 10,
};

export default ChatLogWidget;
