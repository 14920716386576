import * as React from 'react';
import {
  CopyLinkIconButton,
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import { SeaTubeAnnotationPermissions } from 'domain/services/SeaTubePermissionsService';
import TableAnnotation from '../TableAnnotation';

export type AnnotationActionProps = {
  onCopyLink: (annotation: TableAnnotation) => void;
  onEdit: (...args: any[]) => any;
  onDelete: (annotationId: number) => void;
  row: TableAnnotation;
  permissions: SeaTubeAnnotationPermissions;
};

const ActionFormatter: React.VFC<AnnotationActionProps> = ({
  onCopyLink,
  onEdit,
  onDelete,
  row,
  permissions,
}: AnnotationActionProps) => {
  const { canEdit, canDelete } = permissions;

  return (
    <>
      <CopyLinkIconButton onClick={() => onCopyLink(row)} />
      {canEdit && canEdit(row.toListAnnotation()) ? (
        <EditIconButton onClick={() => onEdit(row)} />
      ) : undefined}
      {canDelete && canDelete(row.toListAnnotation()) ? (
        <DeleteIconButton onClick={() => onDelete(row.id)} />
      ) : undefined}
    </>
  );
};

export default ActionFormatter;
