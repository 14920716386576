import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import { StudyArea } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsStudyAreaDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    studyAreas: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getStudyAreas();
  }

  getStudyArea = () => {
    const { value } = this.props;
    const { studyAreas } = this.state;
    const currStudyArea = studyAreas.find(
      (studyArea) => studyArea.value === value
    );

    if (!currStudyArea || value === 0) return '';
    return currStudyArea.label;
  };

  getStudyAreas = () => {
    QaqcTestDetailsService.getQAQCTDStudyAreas()
      .then((results) => {
        const studyAreas = results.map((studyArea) => ({
          label: `${studyArea.description} (${studyArea.studyAreaId})`,
          value: studyArea.studyAreaId,
        }));
        this.setState({
          studyAreas: [{ label: 'Select', value: 0 }, ...studyAreas],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      value,
      onChange,
      ...rest
    } = this.props;
    const { studyAreas } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Study Area"
          options={studyAreas}
          value={value}
          onChange={onChange}
          {...rest}
        />
      );
    }

    return (
      <StudyArea
        classes={{ root: classes.roFieldRoot }}
        studyArea={this.getStudyArea()}
      />
    );
  }
}
export default QaqcDetailsStudyAreaDropdown;
