// eslint-disable-next-line import/no-extraneous-dependencies
import { UseQueryResult, useQuery } from '@onc/service';
import SearchHistoryService from 'domain/AppComponents/search-history/search-history-util/SearchHistoryService';
import { FilterValue } from 'library/CompositeComponents/filter/Filter';

export type useSearchHistoryServiceType = {
  error: any;
  data: any;
  isLoading: boolean;
  refetch: () => Promise<UseQueryResult>;
};

/**
 * @param filter - A filter object containing the date range to search in
 * @param firstIndex - The index of first record you want to appear at the top
 *   of the page. eg: with a page size of 30, on page 2 the first index will be
 *   31
 * @param numberOfRecords - How many records you want returned (page size)
 * @param columnName - The name of the column you wish to sort on
 * @param direction - Which direction you want to sort. 'asc' or 'desc' only
 */
function useSearchHistoryService(
  filter: FilterValue,
  firstIndex: string,
  numberOfRecords: number,
  columnName: string,
  direction: 'asc' | 'desc'
): useSearchHistoryServiceType {
  const { error, data, isLoading, refetch } = useQuery({
    queryKey: [filter, firstIndex, numberOfRecords, columnName, direction],
    queryFn: () =>
      SearchHistoryService.getSearchHistoryWithDOI(filter, {
        firstIndex,
        numberOfRecords,
        sortOrder: `${columnName} ${direction}`,
      }).then((responseData) => responseData),
  });

  return { error, data, isLoading, refetch };
}

export default useSearchHistoryService;
