import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { UserDetailsService } from '@onc/domain-services';

class RouteDestination extends PureComponent {
  static propTypes = {
    route: PropTypes.shape({
      component: PropTypes.elementType,
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      exact: PropTypes.bool,
      resource: PropTypes.string,
    }).isRequired,
    routerProps: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.oneOf(['', PropTypes.shape({})]),
      }).isRequired,
    }).isRequired,
    setTitle: PropTypes.func.isRequired,
  };

  state = {
    isAuthenticated: undefined,
  };

  async componentDidMount() {
    await this.checkAuth();
  }

  checkAuth = async () => {
    const { route, setTitle } = this.props;

    window.DMAS.Config = await UserDetailsService.getUser(
      route.resource || route.name
    );

    this.setState({ isAuthenticated: window.DMAS.Config.DMAS_USER_ID > 0 });
    if (window.DMAS.Config.DMAS_USER_PRIVILEGE !== 'NONE') {
      setTitle();
    }
  };

  render() {
    const { route, routerProps } = this.props;
    const { isAuthenticated } = this.state;

    const {
      location: { search },
    } = routerProps;

    const params = new URLSearchParams(search);

    const urlParams = {};
    for (const k of params.keys()) {
      const v = params.get(k);
      if (isNaN(v)) {
        urlParams[k] = v;
      } else {
        urlParams[k] = Number(v);
      }
    }

    if (isAuthenticated === undefined) {
      return null;
    }

    if (window.DMAS.Config.DMAS_USER_PRIVILEGE !== 'NONE') {
      return <route.component {...routerProps} {...urlParams} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/Login',
          state: { from: routerProps.location },
        }}
      />
    );
  }
}

export default RouteDestination;
