import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ContainedButton } from '@onc/composite-components';
import { Add, Edit } from '@onc/icons';
import { IconButton, Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import DeviceSyncConfig from './DeviceSyncConfig';
import DeviceSyncService from './DeviceSyncService';

class DeviceSyncTable extends PureComponent {
  columnSizeInfo = [
    { columnName: 'icons', width: ColumnInfo.mini },
    { columnName: 'simpleEventId', width: ColumnInfo.mini },
    { columnName: 'leadDeviceId', width: ColumnInfo.mini },
    { columnName: 'leadDeviceName', width: ColumnInfo.large },
    { columnName: 'followDeviceId', width: ColumnInfo.mini },
    { columnName: 'followDeviceName', width: ColumnInfo.large },
    { columnName: 'triggerDelay', width: ColumnInfo.mini },
    { columnName: 'enabled', width: ColumnInfo.mini },
    { columnName: 'modifyDate', width: ColumnInfo.medium },
    { columnName: 'dateFrom', width: ColumnInfo.medium },
    { columnName: 'modifyByName', width: ColumnInfo.medium },
    { columnName: 'hiddenLeadId', width: ColumnInfo.mini },
    { columnName: 'hiddenFollowId', width: ColumnInfo.mini },
  ];

  static propTypes = {
    stripedRows: PropTypes.bool,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    stripedRows: false,
  };

  renderHeaders = [
    { title: ' ', name: 'icons' },
    { title: 'Event ID', name: 'simpleEventId' },
    { title: 'Lead ID', name: 'leadDeviceId' },
    { title: 'Lead Device Name', name: 'leadDeviceName' },
    { title: 'Follow ID', name: 'followDeviceId' },
    { title: 'Follow Name', name: 'followDeviceName' },
    { title: 'Delay', name: 'triggerDelay' },
    { title: 'Enabled', name: 'enabled' },
    { title: 'Date From', name: 'dateFrom' },
    { title: 'Modified Date', name: 'modifyDate' },
    { title: 'Modified By', name: 'modifyByName' },
    { title: 'Device Lead ID For Search', name: 'hiddenLeadId' },
    { title: 'Device Follow ID For Search', name: 'hiddenFollowId' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      eventListMessage: [],
      newEvent: false,
      row: {
        eventId: undefined,
        leadId: undefined,
        followId: undefined,
        enabled: false,
        delay: undefined,
      },
      update: false,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError } = this.props;
    DeviceSyncService.get()
      .then((result) => {
        this.addDeviceLinks(result);
        this.setState({
          eventListMessage: result.map((eventList) => {
            const eventListWithIcons = {
              leadDeviceId: eventList.masterDeviceId,
              followDeviceId: eventList.slaveDeviceId,
              leadDeviceName: eventList.masterDeviceName,
              followDeviceName: eventList.slaveDeviceName,
              ...eventList,
            };
            eventListWithIcons.icons = this.editIcon(eventList);
            eventListWithIcons.dateFrom = DateFormatUtils.formatDate(
              eventList.dateFrom,
              'milliseconds'
            );
            eventListWithIcons.modifyDate = DateFormatUtils.formatDate(
              eventList.modifyDate,
              'full'
            );
            return eventListWithIcons;
          }),
        });
      })
      .catch((error) => {
        onError(error);
      });
  };

  addDeviceLinks = (result) => {
    for (const row of result) {
      row.hiddenLeadId = row.masterDeviceId;
      row.hiddenFollowId = row.slaveDeviceId;
      const detailsLeadLink = (
        <DeviceDetailsLink deviceId={row.masterDeviceId} />
      );
      const detailsFollowLink = (
        <DeviceDetailsLink deviceId={row.slaveDeviceId} />
      );
      row.masterDeviceId = detailsLeadLink;
      row.slaveDeviceId = detailsFollowLink;
    }
  };

  editIcon = (row) => (
    <>
      {this.isUserRW() ? (
        <IconButton
          onClick={() => {
            this.handleEdit(row);
          }}
          aria-label="Edit Synchronization"
          size="small"
          Icon={Edit}
        />
      ) : (
        <></>
      )}
    </>
  );

  handleSave = (form) => {
    const { onError, onInfo } = this.props;
    const { row } = this.state;
    const updatedInfo = form;
    updatedInfo.dateFrom = row.dateFrom;
    updatedInfo.eventId = row.eventId;
    this.setState({
      row: { leadId: form.leadDeviceId, followId: form.followDeviceId },
    });

    DeviceSyncService.post(updatedInfo)
      .then(() => {
        onInfo('Saved Successfully');
        this.setState({ update: false, newEvent: false });
        this.refresh();
        this.handleCancel();
      })
      .catch((response) => {
        onError(response.message);
        this.setState({ update: false, newEvent: false });
        this.handleCancel();
      });
  };

  addEventDialogue = () => {
    const { update, newEvent, row } = this.state;
    if (newEvent !== update) {
      const title = newEvent
        ? 'Add New Synchronization'
        : 'Edit Synchronization';
      return (
        <DeviceSyncConfig
          title={title}
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          data={row}
        />
      );
    }
    return <></>;
  };

  handleEdit = (row) => {
    const rowId = row.simpleEventId;
    const data = {
      eventId: rowId,
      leadId: row.hiddenLeadId,
      leadName: row.leadDeviceName,
      followId: row.hiddenFollowId,
      followName: row.followDeviceName,
      enabled: row.enabled === 'Y',
      delay: row.triggerDelay,
      dateFrom: row.dateFrom,
    };
    this.setState({ update: true, row: data });
  };

  // add a simple event
  handleAddEvent = () => this.setState({ newEvent: true, row: {} });

  handleCancel = () =>
    this.setState({
      newEvent: false,
      update: false,
      row: {},
    });

  isUserRW = () => Environment.getDmasUserPrivilege() === 'RW';

  actionContent = () => (
    <>
      <ContainedButton
        translationKey="device.addSynchronization"
        startIcon={<Add />}
        onClick={this.handleAddEvent}
      />
      <RefreshButton onClick={this.refresh} />
    </>
  );

  titleContent = () => (
    <Typography variant="h6">Synchronization List</Typography>
  );

  getRowId = (row) => row.simpleEventId;

  render() {
    const { stripedRows } = this.props;

    const { eventListMessage, row } = this.state;
    if (!eventListMessage) return undefined;

    return (
      <>
        {this.addEventDialogue(row)}
        <Panel title={this.titleContent()} actionContent={this.actionContent()}>
          <SortableTable
            pageSize={15}
            stripedRows={stripedRows}
            wordWrapEnabled
            searchable
            elevation={0}
            getRowId={this.getRowId}
            columns={this.renderHeaders}
            rows={eventListMessage}
            columnExtensions={tableColumnExtensions}
            columnSizes={this.columnSizeInfo}
            hiddenColumns={['hiddenLeadId', 'hiddenFollowId']}
          />
        </Panel>
      </>
    );
  }
}
// change alignment to be in the table, and then override the few that should not be
const tableColumnExtensions = [
  {
    columnName: 'simpleEventId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'leadDeviceId',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'leadDeviceName',
    width: ColumnInfo.xLarge,
    align: 'left',
  },
  {
    columnName: 'followDeviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'followDeviceName',
    width: ColumnInfo.xLarge,
    align: 'left',
  },
  {
    columnName: 'triggerDelay',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'branch',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'enabled',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'dateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyByName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'icons',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'hiddenLeadId',
    width: ColumnInfo.mini,
    align: 'center',
  },
  {
    columnName: 'hiddenFollowId',
    width: ColumnInfo.mini,
    align: 'center',
  },
];

export default DeviceSyncTable;
