import { useState } from 'react';
import { ConnectorType } from '@onc/domain-services';
import Environment from '@onc/environment';
import ConnectorTypeDialog from './ConnectorTypeDialog';
import ConnectorTypeTable from './ConnectorTypeTable';
import {
  ConnectorTypeRow,
  transformRowToSyncForm,
} from './ConnectorTypeTableLogic';

type ConnectorTypeProps = {
  connectorTypes: ConnectorTypeRow[];
  handleSuccess: () => void;
};

const ConnectorTypeTab = ({
  connectorTypes,
  handleSuccess,
}: ConnectorTypeProps) => {
  const permission = Environment.getDmasUserPrivilege();
  const [isConnectorTypeDialogOpen, setisConnectorTypeDialogOpen] =
    useState(false);
  const [initialConnectorType, setInitialConnectorType] =
    useState<ConnectorType>(undefined);

  const handleEditClick = (row: ConnectorTypeRow) => {
    setInitialConnectorType(transformRowToSyncForm(row));
    setisConnectorTypeDialogOpen(true);
  };

  const handleCreateClick = () => {
    setInitialConnectorType(undefined);
    setisConnectorTypeDialogOpen(true);
  };

  const handleCloseClick = () => {
    setisConnectorTypeDialogOpen(false);
  };

  return (
    <>
      <ConnectorTypeTable
        rows={connectorTypes}
        permission={permission}
        onEdit={handleEditClick}
        onCreate={handleCreateClick}
      />
      <ConnectorTypeDialog
        initialValue={initialConnectorType}
        open={isConnectorTypeDialogOpen}
        onClose={handleCloseClick}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default ConnectorTypeTab;
