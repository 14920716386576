import moment from 'moment';
import {
  SeatubeSample,
  SeatubeSensorData,
} from 'domain/services/SeatubeSensorDataService';

const CELSIUS = 'C';
const DEGREES = 'deg';
const PERCENT = '%';

/* Helper functions */

const convertO2Unit = (value) => (Number.parseFloat(value) / 0.7).toFixed(6);

const formatNumberForDisplay = (value) => Number.parseFloat(value).toFixed(6);

/* Exported functions */

const getFormattedUnit = (uom: string): string => {
  switch (uom) {
    case CELSIUS:
      return '°C';
    case DEGREES:
      return '°';
    case PERCENT:
      return uom;
    default:
      return ` ${uom}`;
  }
};

/** If the data is for NOAA, convert O2 from ml/L to mg/L by mg/L = (ml/L)/0.7 */
const convertSamples = (payload: SeatubeSensorData[]): SeatubeSensorData[] =>
  payload.map((sample) => ({
    ...sample,
    sensorValues: sample.sensorValues.map((sensorValue) => {
      if (
        sensorValue.sensorName === 'Oxygen Concentration' &&
        sensorValue.uom.toLowerCase() === 'ml/l'
      ) {
        return {
          ...sensorValue,
          uom: 'mg/l',
          value: convertO2Unit(sensorValue.value),
        };
      }
      return { ...sensorValue };
    }),
  }));

/**
 * Calculate a date object for every interval: used for the later Binary Search
 * format numbers for display: our requirements ask for 6 decimals of precision
 */
const formatSamples = (payload: SeatubeSensorData[]): SeatubeSample[] =>
  payload.map((sample) => ({
    ...sample,
    intervalDateObj: moment(sample.intervalDate),
    sensorValues: sample.sensorValues.map((sensorValue) => ({
      ...sensorValue,
      value: formatNumberForDisplay(sensorValue.value),
    })),
  }));

export {
  getFormattedUnit,
  convertSamples,
  formatSamples,
  formatNumberForDisplay,
};
