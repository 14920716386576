import '@onc/i18n';
import 'babel-polyfill';

import {
  BaseBatchContainer,
  BaseTaskOutputTable,
  BaseSearchHistoryPage,
  BaseAction,
  BaseAgreementSiteDeviceTable,
  AnnotationEntryPage,
  BaseAnnotationSearchPage,
  BaseAppliedQaqc,
  BaseCableManagementPage,
  BaseCablesPage,
  BaseCharacteristic,
  CommunityFishersDevicePackageInfoPage,
  CompromisedAnnotationEntryPage,
  CompromisedInstrumentsPage,
  BaseDashboards,
  BaseWidgets,
  BaseDataPlayer,
  DataPreviewAnnotationDisplay,
  DataPreviewManagementPage,
  BaseDataRating,
  BaseDataRatingMaintenance,
  BaseDataViewer,
  BaseDerivedSensorParserDefintion,
  BaseDerivedSensorParserDefinitionMaintenance,
  BaseDeviceAction,
  BaseDeviceActionsPage,
  BaseDeviceActionMaintenance,
  BaseIP,
  BaseDeviceListing,
  BaseNameplate,
  BaseDeviceNameplateMaintenance,
  BaseDevicePort,
  BasePVCSViewPage,
  BaseProcurement,
  BaseDeviceSearch,
  BaseDeviceSensor,
  BaseDeviceStatus,
  BaseDeviceSynchronization,
  BaseDeviceTypePage,
  BaseDeviceWorkflowBulkUpdatePage,
  BaseDeviceBulkUpdatePage,
  BaseDigitalFishers,
  BaseDigitalFishersCampaignManagement,
  BaseDatasetLandingPage,
  BaseDriverSchedulePage,
  EarthquakeCatalogPage,
  EarthquakeDetailPage,
  EarthquakeNotificationRegistrationContainer,
  BaseElectricalRating,
  EndeavourEarthquakeCatalogPage,
  BaseExpeditionManagement,
  FileManagementPage,
  BaseFileUpload,
  FormFieldPage,
  BaseDeviceGeneral,
  BaseGeospatialSearch,
  BaseGeospatialSearchv2,
  BaseIpMaintenance,
  SupportRequestContainer,
  BaseLicencePage,
  BaseDafTest,
  BaseDafTestHistory,
  BaseLocalContextsPage,
  AppMenu,
  BaseMultiQueuePage,
  DeviceCategoryPage,
  OrganizationsPage,
  BaseLandingPage,
  BaseOpenApiPage,
  BasePamguardPSFUploadPage,
  BasePamguardTaskCreatePage,
  BaseParserDefinitionViewPage,
  BasePort,
  BaseQaqcDetailsContainer,
  BaseQaqcFinderPage,
  BaseResourceRole,
  BaseDeckLogApp,
  BaseDiveLogApp,
  BaseFixedCameraLocationsApp,
  BaseFixedCameraPlaybackApp,
  BaseLiveExpeditionContainer,
  BasePlaylistPlayback,
  BasePlaylistsApp,
  BaseSeaTubeSearchApp,
  BaseChatLogIngestion,
  BaseSeaTubeConfigurationPage,
  BaseSeaTube,
  BaseSeaTubeIngestion,
  BaseSeaTubeSearch,
  BaseCalibration,
  BaseSensorCalibrationMaintenance,
  BaseSensorMaintenancePage,
  BaseSensorTypePage,
  BaseCastReviewManagement,
  BaseSiteDeviceMaintenance,
  BaseSiteDeviceSubsetMaintenance,
  BaseSiteDeviceSubsetRePostProcess,
  BaseSiteDeviceSubsetSearch,
  BaseSite,
  RequestSupportPage,
  BaseSynchronizerManagementApp,
  BaseSystemConsole,
  BaseTaxonResourceTable,
  BaseTaxonButtonSetConfig,
  BaseTaxonomyApp,
  TaxonomyAttributeManagementPage,
  BaseUserDefinedDashboard,
  BaseUserOrganizationListingPage,
} from 'oceans-next';
import { Route } from 'react-router-dom';

import ExportWrapper from './components/ExportWrapper';

export {
  BaseBatchContainer,
  BaseTaskOutputTable,
  BaseSearchHistoryPage,
  BaseAction,
  BaseAgreementSiteDeviceTable,
  AnnotationEntryPage,
  BaseAnnotationSearchPage,
  BaseAppliedQaqc,
  BaseCableManagementPage,
  BaseCablesPage,
  BaseCharacteristic,
  CommunityFishersDevicePackageInfoPage,
  CompromisedAnnotationEntryPage,
  CompromisedInstrumentsPage,
  BaseDashboards,
  BaseWidgets,
  BaseDataPlayer,
  DataPreviewAnnotationDisplay,
  DataPreviewManagementPage,
  BaseDataRating,
  BaseDataRatingMaintenance,
  BaseDataViewer,
  BaseDerivedSensorParserDefintion,
  BaseDerivedSensorParserDefinitionMaintenance,
  BaseDeviceAction,
  BaseDeviceActionsPage,
  BaseDeviceActionMaintenance,
  BaseIP,
  BaseDeviceListing,
  BaseNameplate,
  BaseDeviceNameplateMaintenance,
  BaseDevicePort,
  BasePVCSViewPage,
  BaseProcurement,
  BaseDeviceSearch,
  BaseDeviceSensor,
  BaseDeviceStatus,
  BaseDeviceSynchronization,
  BaseDeviceTypePage,
  BaseDeviceWorkflowBulkUpdatePage,
  BaseDeviceBulkUpdatePage,
  BaseDigitalFishers,
  BaseDigitalFishersCampaignManagement,
  BaseDatasetLandingPage,
  BaseDriverSchedulePage,
  EarthquakeCatalogPage,
  EarthquakeDetailPage,
  EarthquakeNotificationRegistrationContainer,
  BaseElectricalRating,
  EndeavourEarthquakeCatalogPage,
  BaseExpeditionManagement,
  FileManagementPage,
  BaseFileUpload,
  FormFieldPage,
  BaseDeviceGeneral,
  BaseGeospatialSearch,
  BaseGeospatialSearchv2,
  BaseIpMaintenance,
  SupportRequestContainer,
  BaseLicencePage,
  BaseDafTest,
  BaseDafTestHistory,
  BaseLocalContextsPage,
  AppMenu,
  BaseMultiQueuePage,
  DeviceCategoryPage,
  OrganizationsPage,
  BaseLandingPage,
  BaseOpenApiPage,
  BasePamguardPSFUploadPage,
  BasePamguardTaskCreatePage,
  BaseParserDefinitionViewPage,
  BasePort,
  BaseQaqcDetailsContainer,
  BaseQaqcFinderPage,
  BaseResourceRole,
  BaseDeckLogApp,
  BaseDiveLogApp,
  BaseFixedCameraLocationsApp,
  BaseFixedCameraPlaybackApp,
  BaseLiveExpeditionContainer,
  BasePlaylistPlayback,
  BasePlaylistsApp,
  BaseSeaTubeSearchApp,
  BaseChatLogIngestion,
  BaseSeaTubeConfigurationPage,
  BaseSeaTube,
  BaseSeaTubeIngestion,
  BaseSeaTubeSearch,
  BaseCalibration,
  BaseSensorCalibrationMaintenance,
  BaseSensorMaintenancePage,
  BaseSensorTypePage,
  BaseCastReviewManagement,
  BaseSiteDeviceMaintenance,
  BaseSiteDeviceSubsetMaintenance,
  BaseSiteDeviceSubsetRePostProcess,
  BaseSiteDeviceSubsetSearch,
  BaseSite,
  RequestSupportPage,
  BaseSynchronizerManagementApp,
  BaseSystemConsole,
  BaseTaxonResourceTable,
  BaseTaxonButtonSetConfig,
  BaseTaxonomyApp,
  TaxonomyAttributeManagementPage,
  BaseUserDefinedDashboard,
  BaseUserOrganizationListingPage,
};

const DataViewer = (props) => (
  <ExportWrapper>
    <BaseDataViewer {...props} />
  </ExportWrapper>
);

const AnnotationSearchPage = (props) => (
  <ExportWrapper>
    <BaseAnnotationSearchPage {...props} />
  </ExportWrapper>
);
const DafTest = (props) => (
  <ExportWrapper>
    <BaseDafTest {...props} />
  </ExportWrapper>
);

const DeviceSynchronization = (props) => (
  <ExportWrapper>
    <BaseDeviceSynchronization {...props} />
  </ExportWrapper>
);

const ResourceRole = (props) => (
  <ExportWrapper>
    <BaseResourceRole {...props} />
  </ExportWrapper>
);

const Action = (props) => (
  <ExportWrapper>
    <BaseAction {...props} />
  </ExportWrapper>
);

const DafTestHistory = (props) => (
  <ExportWrapper>
    <BaseDafTestHistory {...props} />
  </ExportWrapper>
);

const Dashboards = (props) => (
  <ExportWrapper>
    <BaseDashboards {...props} />
  </ExportWrapper>
);

const UserDefinedDashboards = (props) => (
  <ExportWrapper>
    <BaseUserDefinedDashboard {...props} />
  </ExportWrapper>
);

const Widgets = (props) => (
  <ExportWrapper>
    <BaseWidgets {...props} />
  </ExportWrapper>
);

const DigitalFishers = (props) => (
  <ExportWrapper>
    <BaseDigitalFishers {...props} />
  </ExportWrapper>
);

const DigitalFishersCampaignManagement = (props) => (
  <ExportWrapper>
    <BaseDigitalFishersCampaignManagement {...props} />
  </ExportWrapper>
);

const ExpeditionManagement = (props) => (
  <ExportWrapper>
    <BaseExpeditionManagement {...props} />
  </ExportWrapper>
);

const SeaTubeSearch = (props) => (
  <ExportWrapper>
    <BaseSeaTubeSearch {...props} />
  </ExportWrapper>
);

const GeospatialSearch = (props) => (
  <ExportWrapper>
    <BaseGeospatialSearch {...props} />
  </ExportWrapper>
);

const GeospatialSearchv2 = (props) => (
  <ExportWrapper>
    <BaseGeospatialSearchv2 {...props} />
  </ExportWrapper>
);

const PlaylistPlaybackApp = (props) => (
  <ExportWrapper>
    <BasePlaylistPlayback {...props} />
  </ExportWrapper>
);

const SeaTube = (props) => (
  <ExportWrapper>
    <BaseSeaTube {...props} />
  </ExportWrapper>
);

const SystemConsole = (props) => (
  <ExportWrapper>
    <BaseSystemConsole {...props} />
  </ExportWrapper>
);

const SiteDeviceMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseSiteDeviceMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const SiteDeviceSubsetMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseSiteDeviceSubsetMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DatasetLandingPageContainer = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDatasetLandingPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DataPlayerContainer = (props) => (
  <ExportWrapper>
    <BaseDataPlayer {...props} />
  </ExportWrapper>
);

const BatchContainer = (props) => (
  <ExportWrapper>
    <BaseBatchContainer {...props} />
  </ExportWrapper>
);

const TaskOutputTable = (props) => (
  <ExportWrapper>
    <BaseTaskOutputTable {...props} />
  </ExportWrapper>
);

const Site = (props) => (
  <ExportWrapper>
    <BaseSite {...props} />
  </ExportWrapper>
);

const DeviceGeneral = (props) => (
  <ExportWrapper>
    <BaseDeviceGeneral {...props} />
  </ExportWrapper>
);

const ElectricalRating = (props) => (
  <ExportWrapper>
    <BaseElectricalRating {...props} />
  </ExportWrapper>
);

const Port = (props) => (
  <ExportWrapper>
    <BasePort {...props} />
  </ExportWrapper>
);

const DevicePort = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseDevicePort {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const DeviceAction = (props) => (
  <ExportWrapper>
    <BaseDeviceAction {...props} />
  </ExportWrapper>
);

const DeviceActionMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDeviceActionMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DeviceSensor = (props) => (
  <ExportWrapper>
    <BaseDeviceSensor {...props} />
  </ExportWrapper>
);

const DataRating = (props) => (
  <ExportWrapper>
    <BaseDataRating {...props} />
  </ExportWrapper>
);
const PhysicalCharacteristics = (props) => (
  <ExportWrapper>
    <BaseCharacteristic {...props} />
  </ExportWrapper>
);

const DataRatingMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDataRatingMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const Procurement = (props) => (
  <ExportWrapper>
    <BaseProcurement {...props} />
  </ExportWrapper>
);

const Nameplate = (props) => (
  <ExportWrapper>
    <BaseNameplate {...props} />
  </ExportWrapper>
);

const IP = (props) => (
  <ExportWrapper>
    <BaseIP {...props} />
  </ExportWrapper>
);

const IpMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseIpMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const NameplateMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDeviceNameplateMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const FileUpload = (props) => (
  <ExportWrapper>
    <BaseFileUpload {...props} />
  </ExportWrapper>
);

const DeviceStatus = (props) => (
  <ExportWrapper>
    <BaseDeviceStatus {...props} />
  </ExportWrapper>
);

const DeviceListing = (props) => (
  <ExportWrapper>
    <BaseDeviceListing {...props} />
  </ExportWrapper>
);

const QaqcDetailsContainer = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseQaqcDetailsContainer {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const QaqcFinderPage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseQaqcFinderPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const TaxonButtonSetConfig = (props) => (
  <ExportWrapper>
    <BaseTaxonButtonSetConfig {...props} />
  </ExportWrapper>
);

const DeviceSearch = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseDeviceSearch {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const PamguardPSFUploadPage = (props) => (
  <ExportWrapper>
    <BasePamguardPSFUploadPage {...props} />
  </ExportWrapper>
);

const PamguardTaskCreatePage = (props) => (
  <ExportWrapper>
    <BasePamguardTaskCreatePage {...props} />
  </ExportWrapper>
);

const SupportRequest = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <SupportRequestContainer {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const SynchronizerManagement = (props) => (
  <ExportWrapper>
    <BaseSynchronizerManagementApp {...props} />
  </ExportWrapper>
);

const TaxonomyAttributeManagement = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <TaxonomyAttributeManagementPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const TaxonomyApp = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseTaxonomyApp {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const DeviceActions = (props) => (
  <ExportWrapper>
    <BaseDeviceActionsPage {...props} />
  </ExportWrapper>
);

const Cables = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseCablesPage {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const AppliedQaqc = (props) => (
  <ExportWrapper>
    <BaseAppliedQaqc {...props} />
  </ExportWrapper>
);

const Calibration = (props) => (
  <ExportWrapper>
    <BaseCalibration {...props} />
  </ExportWrapper>
);

const DerivedSensorParserDefinition = (props) => (
  <ExportWrapper>
    <BaseDerivedSensorParserDefintion {...props} />
  </ExportWrapper>
);

const AnnotationEntry = (props) => (
  <ExportWrapper>
    <AnnotationEntryPage {...props} />
  </ExportWrapper>
);

const SensorCalibrationMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseSensorCalibrationMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const ParserDefinitionMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDerivedSensorParserDefinitionMaintenance
          {...routerProps}
          {...props}
        />
      )}
    />
  </ExportWrapper>
);

const SensorMaintenance = (props) => (
  <ExportWrapper>
    <BaseSensorMaintenancePage {...props} />
  </ExportWrapper>
);

const SeaTubeConfiguration = (props) => (
  <ExportWrapper>
    <BaseSeaTubeConfigurationPage {...props} />
  </ExportWrapper>
);

const DriverSchedule = (props) => (
  <ExportWrapper>
    <BaseDriverSchedulePage {...props} />
  </ExportWrapper>
);

const MultiQueue = (props) => (
  <ExportWrapper>
    <BaseMultiQueuePage {...props} />
  </ExportWrapper>
);

const OpenApi = (props) => (
  <ExportWrapper>
    <BaseOpenApiPage {...props} />
  </ExportWrapper>
);

const TaxonResource = (props) => (
  <ExportWrapper>
    <BaseTaxonResourceTable {...props} />
  </ExportWrapper>
);

const SeaTubeIngestion = (props) => (
  <ExportWrapper>
    <BaseSeaTubeIngestion {...props} />
  </ExportWrapper>
);

const SiteDeviceSubsetRePostProcess = (props) => (
  <ExportWrapper>
    <BaseSiteDeviceSubsetRePostProcess {...props} />
  </ExportWrapper>
);

const CastReviewManagement = (props) => (
  <ExportWrapper>
    <BaseCastReviewManagement {...props} />
  </ExportWrapper>
);

const EarthquakeCatalog = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EarthquakeCatalogPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const EarthquakeNotificationRegistration = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EarthquakeNotificationRegistrationContainer
          {...routerProps}
          {...props}
        />
      )}
    />
  </ExportWrapper>
);

const EndeavourEarthquakeCatalog = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EndeavourEarthquakeCatalogPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const EarthquakeDetail = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EarthquakeDetailPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const ChatLogIngestion = (props) => (
  <ExportWrapper>
    <BaseChatLogIngestion {...props} />{' '}
  </ExportWrapper>
);
const LandingPage = (props) => (
  <ExportWrapper>
    <BaseLandingPage {...props} />
  </ExportWrapper>
);

const SiteDeviceSubsetSearch = (props) => (
  <ExportWrapper>
    <BaseSiteDeviceSubsetSearch {...props} />
  </ExportWrapper>
);

const FileManagement = (props) => (
  <ExportWrapper>
    <FileManagementPage {...props} />
  </ExportWrapper>
);

const DeviceCategory = (props) => (
  <ExportWrapper>
    <DeviceCategoryPage {...props} />
  </ExportWrapper>
);

const FormField = (props) => (
  <ExportWrapper>
    <FormFieldPage {...props} />
  </ExportWrapper>
);

const RequestSupport = (props) => (
  <ExportWrapper>
    <RequestSupportPage {...props} />
  </ExportWrapper>
);

const Organizations = (props) => (
  <ExportWrapper>
    <OrganizationsPage {...props} />
  </ExportWrapper>
);

const DataPreviewAnnotation = (props) => (
  <ExportWrapper>
    <DataPreviewAnnotationDisplay {...props} />
  </ExportWrapper>
);

const DataPreviewManagement = (props) => (
  <ExportWrapper>
    <DataPreviewManagementPage {...props} />
  </ExportWrapper>
);

const DeviceTypePage = (props) => (
  <ExportWrapper>
    <BaseDeviceTypePage {...props} />
  </ExportWrapper>
);

const SensorTypePage = (props) => (
  <ExportWrapper>
    <BaseSensorTypePage {...props} />
  </ExportWrapper>
);

const LicencePage = (props) => (
  <ExportWrapper>
    <BaseLicencePage {...props} />
  </ExportWrapper>
);

const PVCSViewPage = (props) => (
  <ExportWrapper>
    <BasePVCSViewPage {...props} />
  </ExportWrapper>
);

const ParserDefinitionViewPage = (props) => (
  <ExportWrapper>
    <BaseParserDefinitionViewPage {...props} />
  </ExportWrapper>
);

const DeckLogApp = (props) => (
  <ExportWrapper>
    <BaseDeckLogApp {...props} />
  </ExportWrapper>
);

const LiveExpeditionEmbed = (props) => (
  <ExportWrapper>
    <BaseLiveExpeditionContainer {...props} />
  </ExportWrapper>
);

const SearchHistoryPage = (props) => (
  <ExportWrapper>
    <BaseSearchHistoryPage {...props} />
  </ExportWrapper>
);

const AgreementSiteDeviceTable = (props) => (
  <ExportWrapper>
    <BaseAgreementSiteDeviceTable {...props} />
  </ExportWrapper>
);

const PlaylistsApp = (props) => (
  <ExportWrapper>
    <BasePlaylistsApp {...props} />
  </ExportWrapper>
);

const DiveLogApp = (props) => (
  <ExportWrapper>
    <BaseDiveLogApp {...props} />
  </ExportWrapper>
);

const LocalContextsPage = (props) => (
  <ExportWrapper>
    <BaseLocalContextsPage {...props} />
  </ExportWrapper>
);

const CommunityFishersDevicePackageInfo = (props) => (
  <ExportWrapper>
    <CommunityFishersDevicePackageInfoPage {...props} />
  </ExportWrapper>
);

const CompromisedAnnotationEntry = (props) => (
  <ExportWrapper>
    <CompromisedAnnotationEntryPage {...props} />
  </ExportWrapper>
);

const CompromisedInstruments = (props) => (
  <ExportWrapper>
    <CompromisedInstrumentsPage {...props} />
  </ExportWrapper>
);

const CableManagementPage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseCableManagementPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DeviceWorkflowBulkUpdatePage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDeviceWorkflowBulkUpdatePage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DeviceBulkUpdatePage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDeviceBulkUpdatePage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const UserOrganizationListingPage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseUserOrganizationListingPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const FixedCameraLocations = (props) => (
  <ExportWrapper>
    <BaseFixedCameraLocationsApp {...props} />;
  </ExportWrapper>
);

const FixedCameraPlayback = (props) => (
  <ExportWrapper>
    <BaseFixedCameraPlaybackApp {...props} />
  </ExportWrapper>
);

const SeaTubeSearchApp = (props) => (
  <ExportWrapper>
    <BaseSeaTubeSearchApp {...props} />
  </ExportWrapper>
);

const exports = {
  Action,
  AgreementSiteDeviceTable,
  AnnotationEntry,
  AnnotationSearchPage,
  AppliedQaqc,
  BatchContainer,
  Cables,
  Calibration,
  CastReviewManagement,
  ChatLogIngestion,
  DafTest,
  DafTestHistory,
  Dashboards,
  DataPlayerContainer,
  DataPreviewAnnotation,
  DataPreviewManagement,
  DataRating,
  DataRatingMaintenance,
  DatasetLandingPageContainer,
  DeckLogApp,
  DerivedSensorParserDefinition,
  DeviceAction,
  DeviceActionMaintenance,
  DeviceActions,
  DeviceCategory,
  DeviceListing,
  DevicePort,
  DeviceSearch,
  DeviceSensor,
  DeviceStatus,
  DeviceSynchronization,
  DeviceTypePage,
  DigitalFishers,
  DigitalFishersCampaignManagement,
  DiveLogApp,
  DriverSchedule,
  EarthquakeCatalog,
  EarthquakeNotificationRegistration,
  EarthquakeDetail,
  EndeavourEarthquakeCatalog,
  ElectricalRating,
  ExpeditionManagement,
  FileManagement,
  FileUpload,
  FixedCameraLocations,
  FixedCameraPlayback,
  FormField,
  RequestSupport,
  DeviceGeneral,
  GeospatialSearch,
  GeospatialSearchv2,
  IP,
  IpMaintenance,
  LandingPage,
  LicencePage,
  LiveExpeditionEmbed,
  LocalContextsPage,
  MultiQueue,
  Nameplate,
  NameplateMaintenance,
  OpenApi,
  Organizations,
  PamguardPSFUploadPage,
  PamguardTaskCreatePage,
  ParserDefinitionMaintenance,
  ParserDefinitionViewPage,
  PhysicalCharacteristics,
  PlaylistPlaybackApp,
  Port,
  Procurement,
  PVCSViewPage,
  QaqcDetailsContainer,
  QaqcFinderPage,
  ResourceRole,
  SearchHistoryPage,
  SeaTube,
  SeaTubeConfiguration,
  SeaTubeIngestion,
  SeaTubeSearch,
  SeaTubeSearchApp,
  SensorCalibrationMaintenance,
  SensorMaintenance,
  SensorTypePage,
  Site,
  SiteDeviceMaintenance,
  SiteDeviceSubsetMaintenance,
  SiteDeviceSubsetRePostProcess,
  SiteDeviceSubsetSearch,
  SupportRequest,
  SynchronizerManagement,
  SystemConsole,
  TaskOutputTable,
  TaxonButtonSetConfig,
  TaxonomyAttributeManagement,
  TaxonomyApp,
  TaxonResource,
  Widgets,
  PlaylistsApp,
  CommunityFishersDevicePackageInfo,
  CompromisedAnnotationEntry,
  CompromisedInstruments,
  CableManagementPage,
  DeviceWorkflowBulkUpdatePage,
  DeviceBulkUpdatePage,
  UserOrganizationListingPage,
  DataViewer,
  UserDefinedDashboards,
};

export default exports;
